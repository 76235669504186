/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { FC } from 'react';
import { styled, Box, Stack, Typography } from '@mui/material';
import { theme } from '@/theme';
import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { PrevnextApplications } from '@/types/api/Applications/prevnextApplications';
import { FindApplicationByID } from '@/types/api/Applications/application';
import { MemberById } from '@/types/api/member';
import { format } from 'date-fns';
import { printApplicationsComponent } from '@/utils/printApplications';
import { TitleHead } from './SharedParts/TitleHead';
import { ApplicationBottom } from './SharedParts/ApplicationBottom';
import { ApplicationTable as Table } from './SharedParts/ApplicationTable';
import { ApplicationTableRow as Row } from './SharedParts/ApplicationTableRow';
import { ApplicationButtons } from './SharedParts/ApplicationButtons';
import { PrintApplicationsButton } from './PrintApplicationsButton/PrintApplicationsButton';

const ChangeWrapper = styled(Box)({
  backgroundColor: '#EFFBFC',
  padding: '8px 12px',
  maxWidth: '80px',
});

interface UserProps {
  application: FindApplicationByID;
  userData: MemberById;
  bottomData: PrevnextApplications | undefined;
  applicationId?: string;
  handleApplication: (value: 'APPROVAL' | 'REJECTED', comment?: string) => void;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export const ApplicationsUser: FC<UserProps> = ({
  application,
  userData,
  bottomData,
  applicationId = '',
  isOpen,
  handleApplication,
  setIsOpen,
}) => {
  const beforeBirthDay = userData.birthday
    ? format(new Date(userData.birthday), 'yyyy年MM月dd日')
    : '';
  const afterBirthDay = application.applicationUser?.birthday
    ? format(new Date(application.applicationUser.birthday), 'yyyy年MM月dd日')
    : '';

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.system['background-dark'],
        height: '100%',
      }}
    >
      <Stack
        spacing={4}
        sx={{
          backgroundColor: theme.palette.system.white,
          padding: '40px 160px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <PrintApplicationsButton
            onClick={() =>
              printApplicationsComponent('user_print_content', '会員情報変更')
            }
          />
        </Box>
        <Stack id="user_print_content" spacing={4}>
          <TitleHead
            title="会員情報変更"
            status={application.applicationStatus || ''}
            applicationDate={application.applicationDate || ''}
            applicationId={application.applicationId || ''}
            associationCd={application.associationCd || ''}
            proxyApplicantUserName={application.proxyApplicantUserName ?? ''}
            unitType="user"
            comment={application.rejectComment}
          />
          <Stack spacing={3}>
            <ChangeWrapper>
              <Typography variant="body-sub/medium">変更箇所</Typography>
            </ChangeWrapper>
            <Stack
              spacing={3}
              direction="row"
              sx={{ justifyContent: 'space-between' }}
            >
              <Table title="会員情報(変更前）">
                <Row labels="会員コード" values={userData.memberCd} />
                <Row labels="氏名（漢字）" values={userData.nameKanji} />
                <Row labels="氏名（カナ）" values={userData.nameKana} />
                <Row labels="生年月日" values={beforeBirthDay} />
                <Row
                  labels="住所"
                  values={[
                    `〒${userData.zipcode || ''}`,
                    (userData.address1 || '') +
                      userData.address2 +
                      userData.address3,
                  ]}
                />
                <Row
                  labels="電話番号"
                  values={[
                    userData.telType === 'MOBILE' ? '携帯' : '自宅',
                    userData.tel || '',
                  ]}
                />
                <Row labels="社員コード" values={userData.employeeCode} />
                <Row
                  labels={['会員事業所', 'コード']}
                  values={userData.officeCd}
                  isOptional
                />
                <Row
                  labels={['会員所属コード']}
                  values={userData.affiliationCd}
                  isOptional
                />
              </Table>
              <Table title="会員情報(変更後）" isRed>
                <Row labels="会員コード" values={userData.memberCd} />
                <Row
                  labels="氏名（漢字）"
                  values={application.applicationUser?.nameKanji}
                  isEdited={
                    userData.nameKanji !==
                    application.applicationUser?.nameKanji
                  }
                />
                <Row
                  labels="氏名（カナ）"
                  values={application.applicationUser?.nameKana}
                  isEdited={
                    application.applicationUser?.nameKana !== userData.nameKana
                  }
                />
                <Row
                  labels="生年月日"
                  values={afterBirthDay}
                  isEdited={beforeBirthDay !== afterBirthDay}
                />
                <Row
                  labels="住所"
                  values={[
                    `〒${application.applicationUser?.zipcode || ''}`,
                    (application.applicationUser?.address1 || '') +
                      (application.applicationUser?.address2 || '') +
                      (application.applicationUser?.address3 || ''),
                  ]}
                  isEdited={
                    (application.applicationUser?.zipcode || '') +
                      (application.applicationUser?.address1 || '') +
                      (application.applicationUser?.address2 || '') +
                      (application.applicationUser?.address3 || '') !==
                    (userData.zipcode || '') +
                      userData.address1 +
                      userData.address2 +
                      userData.address3
                  }
                />
                <Row
                  labels="電話番号"
                  values={[
                    application.applicationUser?.telType === 'MOBILE'
                      ? '携帯'
                      : '自宅',
                    application.applicationUser?.tel || '',
                  ]}
                  isEdited={
                    application.applicationUser?.tel !== userData.tel ||
                    application.applicationUser?.telType !== userData.telType
                  }
                />
                <Row
                  labels="社員コード"
                  values={application.applicationUser?.employeeCode}
                  isEdited={
                    userData.employeeCode !==
                    application.applicationUser?.employeeCode
                  }
                />
                <Row
                  labels={['会員事業所', 'コード']}
                  values={application.applicationUser?.officeCd}
                  isOptional
                  isEdited={
                    userData.officeCd !== application.applicationUser?.officeCd
                  }
                />
                <Row
                  labels={['会員所属コード']}
                  values={application.applicationUser?.affiliationCd}
                  isOptional
                  isEdited={
                    userData.affiliationCd !==
                    application.applicationUser?.affiliationCd
                  }
                />
              </Table>
            </Stack>
          </Stack>
        </Stack>
        {application.applicationStatus !== 'COMPLETED' && (
          <Stack direction="row" sx={{ alignItems: 'center' }} spacing={2}>
            <ApplicationButtons
              status={application.applicationStatus || ''}
              handleApplication={handleApplication}
              setIsOpen={setIsOpen}
              isOpen={isOpen}
            />
            {(application.applicationStatus === 'PREPARATION' ||
              application.applicationStatus === 'APPLYING') && (
              <NormalLink
                href={
                  application.applicationId
                    ? `/applications/user/${application.applicationId}/edit`
                    : ''
                }
              >
                申請内容を修正する
              </NormalLink>
            )}
          </Stack>
        )}
      </Stack>
      {bottomData && (
        <Box sx={{ padding: '32px 160px' }}>
          <ApplicationBottom data={bottomData} unitType="user" />
        </Box>
      )}
    </Box>
  );
};
