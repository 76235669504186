import { Box, SxProps } from '@mui/material';
import { theme } from '@/theme';
import { FC, ReactNode } from 'react';

interface Props {
  width: string;
  height: string;
  variant: 'regular' | 'medium' | 'bold';
  bgcolor:
    | 'secondary.normal'
    | 'secondary.bg'
    | 'secondary.light'
    | 'system.white';
  borderRight?: boolean;
  borderBottom?: boolean;
  sxProps?: SxProps;
  children: ReactNode;
}

/**
 * Th: Table Header の略
 */
export const Th: FC<Props> = ({
  width,
  height,
  variant,
  bgcolor,
  borderRight,
  borderBottom,
  sxProps,
  children,
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      width,
      height,
      bgcolor,
      borderRight: borderRight ? '1px solid' : '0',
      borderBottom: borderBottom ? '1px solid' : '0',
      borderColor: 'system.separator-light',
      p: '8px 10px',
      gap: '10px',
      boxSizing: 'border-box',
      whiteSpace: 'nowrap',
    }}
  >
    <Box
      sx={{
        color:
          bgcolor === 'secondary.normal' ? 'system.white' : 'secondary.text',
        ...theme.typography[`body-sub/${variant}`],
        display: 'flex',
        ...sxProps,
      }}
    >
      {children}
    </Box>
  </Box>
);
