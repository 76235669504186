import { FC, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { NewOfficeUserCreate } from '@/components/organisms/OfficeUsers/NewOfficeUserCreate';
import { useRecoilValue } from 'recoil';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { appClient } from '@/services';
import { OfficeUserCreateInputs as Inputs } from '@/types/components/OfficeUser';
import { useSnackbar } from '@/hooks/useSnackbar';

export const OfficeUserCreate: FC = () => {
  const navigate = useNavigate();
  const { associationCd = '' } = useRecoilValue(SelectedAssociationState);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [isError, setIsError] = useState(false);
  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    try {
      await appClient.officeUsers.createOfficeUsers({
        ...data,
        associationCd,
      });
      showSnackbar('担当者を登録しました。', '208px');
      setIsConfirmed(false);
      navigate('/office-users');
    } catch (e) {
      // 例外処理
      setIsConfirmed(false);
      setIsError(true);
    }
  };

  const handleClickCancel = () => {
    if (isConfirmed) {
      setIsConfirmed(false);
      return;
    }
    setIsError(false);
    navigate('/office-users');
  };

  const handleClickConfirm = () => {
    setIsConfirmed(true);
    setIsError(false);
  };

  return (
    <NewOfficeUserCreate
      onSubmit={onSubmit}
      handleClickCancel={handleClickCancel}
      handleClickConfirm={handleClickConfirm}
      isConfirmed={isConfirmed}
      isError={isError}
    />
  );
};
