import { FC } from 'react';
import { NormalLink } from '@/components/atoms/Link/NormalLink';
import {
  styled,
  Box,
  Stack,
  Typography,
  Divider,
  // TextField,
  TextareaAutosize,
} from '@mui/material';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { CancelButton } from '@/components/atoms/Buttons/CancelButton';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { TemporaryContactInputs as Inputs } from '@/types/components/Inputs';
import { BasicModal } from '@/components/molecules/Modal';
import { theme } from '@/theme';

interface TemporaryContactProps {
  isSent?: boolean;
  detail?: string;
  date?: string;
  isConfirm: boolean;
  onClick: () => void;
  onSubmit: SubmitHandler<Inputs>;
  isOpen: boolean;
  onClose: () => void;
}

const MuiBox = styled(Box)({
  display: 'flex',
  gap: '16px',
  borderBottom: '1px solid',
  borderColor: theme.palette.system.placeholder,
  paddingBottom: '16px',
});

const Label = styled(Typography)({
  ...theme.typography['body-main/bold'],
  color: theme.palette.system['text-normal'],
  minWidth: '116px',
  display: 'flex',
  flexDirection: 'column',
});

export const ApplicationsTemporaryContact: FC<TemporaryContactProps> = ({
  isSent = false,
  detail,
  date,
  isConfirm,
  onClick,
  onSubmit,
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate();
  const onClickCancel = () => {
    navigate('/applications/temporary');
  };
  const { control, handleSubmit, getValues, watch } = useForm<Inputs>({
    defaultValues: {
      detail: '',
    },
  });
  return (
    <Box height="100%" width="100%">
      {isSent ? (
        <Stack spacing={4} px={5} py={4}>
          <NormalLink href="/applications/temporary">
            臨時買付一覧に戻る
          </NormalLink>
          <Stack spacing={2}>
            <MuiBox>
              <Label>送信日時</Label>
              <Box>{date}</Box>
            </MuiBox>
            <MuiBox>
              <Label>詳細</Label>
              <Box>{detail}</Box>
            </MuiBox>
          </Stack>
        </Stack>
      ) : (
        <Box>
          {isConfirm ? (
            <>
              <Stack spacing={1} px={5} py={4}>
                <Typography
                  variant="h4"
                  color={theme.palette.system['text-normal']}
                >
                  臨時買付の事前連絡
                </Typography>
                <Typography color={theme.palette.system['text-light']}>
                  以下の内容で送信します。
                </Typography>
              </Stack>
              <Divider
                sx={{
                  backgroundColor: theme.palette.system['separator-light'],
                }}
              />
              <Stack
                spacing={4}
                px={5}
                py={3}
                component="form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <MuiBox>
                  <Label>詳細</Label>
                  <Box>{getValues('detail')}</Box>
                </MuiBox>
                <Box display="flex" gap="16px">
                  <CancelButton onClick={onClick}>入力へ戻る</CancelButton>
                  <PrimaryButton type="submit">送信する</PrimaryButton>
                </Box>
              </Stack>
            </>
          ) : (
            <>
              <Stack spacing={3} px={5} py={4}>
                <NormalLink>臨時買付一覧に戻る</NormalLink>
                <Stack spacing={1}>
                  <Typography variant="h4">臨時買付の事前連絡</Typography>
                  <Typography color={theme.palette.system['text-light']}>
                    臨時の買付が発生する場合は、以下のフォームにてその旨を送信した後、制度グループへ別途ご連絡ください。
                  </Typography>
                </Stack>
              </Stack>
              <Divider
                sx={{
                  backgroundColor: theme.palette.system['separator-light'],
                }}
              />
              <Stack spacing={3} px={5} py={3}>
                <MuiBox sx={{ paddingBottom: '16px' }}>
                  <Label>
                    詳細
                    <Typography
                      variant="caption/regular"
                      color={theme.palette.system['text-light']}
                    >
                      任意
                    </Typography>
                  </Label>

                  <Controller
                    name="detail"
                    control={control}
                    render={({ field }) => (
                      <TextareaAutosize
                        {...field}
                        // variant="outlined"
                        style={{
                          width: '800px',
                          minHeight: '120px',
                          resize: 'vertical',
                          padding: '8px',
                          borderRadius: '4px',
                          border: '1px solid',
                          borderColor: theme.palette.system.placeholder,
                          fontSize: '16px',
                          outline: 0,
                        }}
                        placeholder="例：買付予定日時、補足等"
                      />
                    )}
                  />
                </MuiBox>
                <Box display="flex" gap="16px">
                  <CancelButton onClick={onClickCancel}>
                    キャンセル
                  </CancelButton>
                  <PrimaryButton onClick={onClick} disabled={!watch('detail')}>
                    確認へ進む
                  </PrimaryButton>
                </Box>
              </Stack>
            </>
          )}
          <BasicModal
            open={isOpen}
            handleClose={() => {
              onClose();
              navigate('/applications/temporary');
            }}
            hasScroll={false}
          >
            <Stack py={4} spacing={3} alignItems="center" width="520px">
              <Typography variant="h4">臨時買付の事前連絡</Typography>
              <Stack alignItems="center">
                <Typography>送信しました。</Typography>
                <Typography>
                  制度グループまで別途連絡を行ってください。
                </Typography>
              </Stack>
              <PrimaryButton
                onClick={() => onClickCancel()}
                sx={{ width: '79px' }}
              >
                OK
              </PrimaryButton>
            </Stack>
          </BasicModal>
        </Box>
      )}
    </Box>
  );
};
