import { theme } from '@/theme';
import { Box, Divider, Typography } from '@mui/material';
import { FC } from 'react';

interface TitleWithDividerProps {
  title: string;
}

export const TitleWithDivider: FC<TitleWithDividerProps> = ({ title }) => (
  <Box>
    <Typography variant="h5" mb={1.5}>
      {title}
    </Typography>
    <Divider sx={{ borderColor: theme.palette.primary.normal }} />
  </Box>
);
