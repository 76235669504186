import { FC } from 'react';
import { theme } from '@/theme/theme';
import { Box, Typography, styled } from '@mui/material';

type CalendarEventTypes = 'MONTHLY' | 'BONUS' | 'DIVIDEND' | 'SPECIAL';
type BonusReserveClassification = 'PURCHASE' | 'RESERVE' | 'NONE';

interface CalendarEventDescriptionProps {
  status?: boolean;
  eventType?: CalendarEventTypes;
  subtitleContext?: string;
  bonusReserveClassification?: BonusReserveClassification;
}

export const CalendarEventTypeStatus = {
  Monthly: 'MONTHLY',
  Bonus: 'BONUS',
  Dividend: 'DIVIDEND',
  Special: 'SPECIAL',
} as const;

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  alignItems: 'flex-start',
  justifyContent: 'center',
});

const TitleWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
  height: 22,
  alignItems: 'center',
});

const StatusWrapper = styled(Box)({
  height: 22,
  borderRadius: 4,
  padding: '4px 6px',
});

const StatusText = styled(Typography)({
  ...theme.typography['body-sub/medium'],
  lineHeight: '100%',
});

const Title = styled(Typography)({
  ...theme.typography.h6,
  color: theme.palette.system.table,
});

const SubtitleWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
});

const Subtitle = styled(Typography)({
  ...theme.typography['body-sub/regular'],
  color: theme.palette.system['text-light'],
});

export const getCalendarEventStylesFromStatus = (
  eventType: CalendarEventTypes | undefined
) => {
  switch (eventType) {
    case CalendarEventTypeStatus.Monthly:
      return '月例';
    case CalendarEventTypeStatus.Bonus:
      return '賞与';
    case CalendarEventTypeStatus.Dividend:
      return '配当';
    case CalendarEventTypeStatus.Special:
      return '臨時';
    default:
      return '';
  }
};

const getBonusReserveClassificationText = (
  bonusReserveClassification: BonusReserveClassification | undefined
) => {
  switch (bonusReserveClassification) {
    case 'PURCHASE':
      return '買付';
    case 'RESERVE':
      return '積立';
    case 'NONE':
      return '';
    default:
      return '';
  }
};

const statusText = (
  status: boolean,
  eventType: CalendarEventTypes | undefined,
  bonusReserveClassification: BonusReserveClassification | undefined
) => {
  if (!status) return '締日';

  if (eventType === CalendarEventTypeStatus.Bonus) {
    return getBonusReserveClassificationText(bonusReserveClassification);
  }
  return '買付';
};

export const CalendarEventDescription: FC<CalendarEventDescriptionProps> = ({
  status,
  eventType,
  subtitleContext,
  bonusReserveClassification,
}) => (
  <Wrapper>
    <TitleWrapper>
      {status !== undefined && (
        <StatusWrapper
          sx={{
            backgroundColor: status
              ? 'rgba(4, 149, 18, 0.15)'
              : theme.palette.states['error-bg'],
          }}
        >
          <StatusText
            sx={{
              color: status
                ? theme.palette.states.success
                : theme.palette.states.error,
            }}
          >
            {statusText(status, eventType, bonusReserveClassification)}
          </StatusText>
        </StatusWrapper>
      )}
      <Title>
        {status
          ? getCalendarEventStylesFromStatus(eventType)
          : `${getCalendarEventStylesFromStatus(eventType)} 事務局締日`}
      </Title>
    </TitleWrapper>
    {/**
     *毎月...日はfigmaで消えてたのでコメントアウト
     <SubtitleWrapper>
     <Subtitle>{subtitleContext ?? 'なし'}</Subtitle>
     </SubtitleWrapper>
     */}
  </Wrapper>
);
