export const printApplicationsComponent = (
  componentId: string,
  title: string
) => {
  document.title = title;
  const component = document.getElementById(componentId) as HTMLElement;
  const cloneComponent = component.cloneNode(true);
  document.body.appendChild(cloneComponent);
  window.print();
  document.body.removeChild(cloneComponent);
  document.title = 'NextStocks';
};
