/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Box, Button, SxProps, Theme, Typography } from '@mui/material';
import { FC } from 'react';
import { ReactComponent as Icon } from '@/assets/batsu.svg';
import { ReactComponent as PrintIcon } from '@/assets/print.svg';
import { FindTradereportsByID } from '@/types/api/tradereports';
import { toDateTime } from '@/utils/formatter';
import { printComponent } from '@/utils/print';
import { Row } from './Row';
import { Th } from './Th';
import { TableContainer } from './TableContainer';
import { Td } from './Td';
import { SubmitStatus } from './SubmitStatus';
import { MonthlyTradeReport } from './MonthlyTradeReport';
import { BonusTradeReport } from './BonusTradeReport';
import { DividendTradeReport } from './DividendTradeReport';

interface Props {
  type: string;
  onClose: () => void;
  tradereport: Required<FindTradereportsByID>;
}

const styleTableContainer: SxProps<Theme> = [
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    width: '960px',
    p: '24px',
  },
];

export const TradeReport: FC<Props> = ({ type, onClose, tradereport }) => {
  const renderTitle = () => {
    switch (type) {
      case 'monthly': {
        return '購入(売却)連絡票 (月例)';
      }
      case 'bonus': {
        return '購入(売却)連絡票 (賞与)';
      }
      case 'dividend': {
        return '購入(売却)連絡票 (配当)';
      }
      default: {
        return null;
      }
    }
  };

  const renderComponent = () => {
    switch (type) {
      case 'monthly': {
        return <MonthlyTradeReport report={tradereport} />;
      }
      case 'bonus': {
        return <BonusTradeReport report={tradereport} />;
      }
      case 'dividend': {
        return <DividendTradeReport report={tradereport} />;
      }
      default: {
        return null;
      }
    }
  };

  const renderType = () => {
    switch (type) {
      case 'monthly': {
        return '月例';
      }
      case 'bonus': {
        return '賞与';
      }
      case 'dividend': {
        return '配当';
      }
      default: {
        return '';
      }
    }
  };

  const { associationCd, associationName, eventNo, contributionYm } =
    tradereport;

  return (
    <Box id="tradereport" sx={styleTableContainer}>
      <Box
        sx={{
          width: '912px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '24px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          {type === 'dividend' && <SubmitStatus />}
          <Typography variant="h4" sx={{ color: 'system.text-normal' }}>
            {renderTitle()}
          </Typography>
          <Typography
            variant="body-sub/regular"
            sx={{ color: 'system.text-light' }}
          >
            {`${toDateTime(String(new Date()))} 現在`}
          </Typography>
        </Box>
        <Button
          id="tradereport-close-button"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '9px',
            '&& .MuiTouchRipple-child': {
              bgcolor: 'secondary.normal',
            },
          }}
          onClick={onClose}
        >
          <Icon />
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '100%',
              color: 'secondary.normal',
            }}
          >
            閉じる
          </Typography>
        </Button>
      </Box>
      <Button
        id="tradereport-print-button"
        sx={{
          width: '172px',
          height: '40px',
          whiteSpace: 'nowrap',
          bgcolor: 'secondary.button',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
          p: '8px 16px 8px 12px',
          borderRadius: '4px',
          '&:hover': {
            bgcolor: 'secondary.bg',
          },
          '&& .MuiTouchRipple-child': {
            bgcolor: 'secondary.normal',
          },
        }}
        onClick={() => printComponent('tradereport', onClose)}
      >
        <PrintIcon />
        <Typography
          sx={{
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '100%',
            color: 'secondary.text',
          }}
        >
          画面を印刷する
        </Typography>
      </Button>
      <TableContainer width="912px">
        <Row>
          <Th variant="regular" bgcolor="secondary.bg">
            持株会コード
          </Th>
          <Td width="792px">{`${associationCd} ${associationName}`}</Td>
        </Row>
        <Row>
          <Th variant="regular" bgcolor="secondary.bg">
            イベントNo.
          </Th>
          <Td width="200px" borderRight>
            {eventNo}
          </Td>
          <Td width="592px">{`配分(${renderType()})`}</Td>
        </Row>
        <Row borderBottom={false}>
          <Th variant="regular" bgcolor="secondary.bg">
            拠出月度
          </Th>
          <Td width="792px">
            {contributionYm?.replace(/(.*)-(.*)/, '$1年$2月')}
          </Td>
        </Row>
      </TableContainer>
      {renderComponent()}
    </Box>
  );
};
