import {
  LoginPasswordResetting,
  Inputs,
} from '@/components/organisms/Login/LoginPasswordResetting';
import { Path } from '@/constants/Router/path';
import { appClient } from '@/services';
import { FC, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export const PasswordResetting: FC = () => {
  const navigate = useNavigate();
  const [isError, setIsError] = useState<boolean>(false);

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    try {
      const response = await appClient.authorization.authMailConfirm({
        email: data.email,
      });
      if (response) {
        navigate(Path.LOGIN_PASSWORD_RESETTING_MFA, {
          state: {
            email: data.email,
            mfaSession: response.Session,
          },
        });
      }
    } catch (e) {
      setIsError(true);
    }
  };

  return (
    <LoginPasswordResetting
      title="パスワード再設定"
      onSubmit={onSubmit}
      isError={isError}
    />
  );
};
