/* eslint-disable @typescript-eslint/restrict-template-expressions */
import useSWR from 'swr';
import { useEffect, useMemo, useState } from 'react';
import { FindMember } from '@/types/api/members';
import { appClient } from '..';

const ConvertMember = (data: FindMember) =>
  ({
    memberId: data.memberId || 0,
    userId: data.userId || '',
    email: data.email || '',
    associationCd: data.associationCd || '',
    memberCd: data.memberCd || '',
    userStatus: data.userStatus || 'VARIFIED_MAIL',
    registeredType: data.registeredType || 'WEB',
    companyCd: data.companyCd || '',
    officeCd: data.officeCd || '',
    affiliationCd: data.affiliationCd || '',
    employeeCode: data.employeeCode || '',
    nameKanji: data.nameKanji || '',
    nameKana: data.nameKana || '',
    birthday: data.birthday || '',
    zipcode: data.zipcode || '',
    address1: data.address1 || '',
    address2: data.address2 || '',
    address3: data.address3 || '',
    tel: data.tel || '',
    telType: data.telType || 'MOBILE',
    joinDt: data.joinDt || '',
    withdrawalDt: data.withdrawalDt || '',
    expiryDay: data.expiryDay || '',
  } as FindMember);

export const useMember = () => {
  const [memberId, setMemberId] = useState<number | undefined>();
  const [userData, setUserData] = useState<FindMember>();
  // 【API-C-4002】持株会員取得
  const { data: apiData } = useSWR(`/api/members/${memberId}`, () =>
    appClient.members.findMember(memberId || 0)
  );

  const updateMemberId = (newId: number) => {
    setMemberId(newId);
  };

  useEffect(() => {
    setUserData(apiData);
  }, [apiData]);

  return useMemo(
    () => ({
      data: ConvertMember(userData ?? {}),
      updateMemberId,
    }),
    [userData]
  );
};
