import { FC } from 'react';
import { styled, Stack, Box, Typography } from '@mui/material';
import { theme } from '@/theme';
import { MemberById } from '@/types/api/member';

const Title = styled(Box)({
  ...theme.typography.h5,
  paddingBottom: '8px',
  borderBottom: '1px solid',
  borderColor: theme.palette.system['separator-light'],
  width: '100%',
});

const DataWrapper = styled(Box)({
  display: 'flex',
  width: '100%',
  gap: '20px',
});

const DataCell = styled(Box)({
  ...theme.typography['body-main/regular'],
  display: 'flex',
  gap: '16px',
  paddingBottom: '8px',
  borderBottom: '1px solid',
  borderColor: theme.palette.system['separator-light'],
});

const Label = styled(Box)({
  color: theme.palette.system['text-light'],
  minWidth: '116px',
});

interface UserInfoProps {
  data: MemberById;
}

export const UserInfoTable: FC<UserInfoProps> = ({ data }) => (
  <Stack spacing={2}>
    <Title>会員情報</Title>
    <DataWrapper>
      <Stack spacing={1} width="50%">
        <DataCell>
          <Label>氏名（漢字）</Label>
          <Box>{data.nameKanji}</Box>
        </DataCell>
        <DataCell>
          <Label>氏名（カナ）</Label>
          <Box>{data.nameKana}</Box>
        </DataCell>
        <DataCell>
          <Label>住所</Label>
          <Box>
            〒{data.zipcode}
            <br />
            {data.address1}
            {data.address2}
            {data.address3}
          </Box>
        </DataCell>
      </Stack>
      <Stack spacing={1} width="50%">
        <DataCell>
          <Label>電話番号</Label>
          <Box>{data.tel}</Box>
        </DataCell>
        <DataCell>
          <Label>社員コード</Label>
          <Box>{data.employeeCode}</Box>
        </DataCell>
      </Stack>
    </DataWrapper>
    <Typography variant="body-sub/regular" color="system.text-light">
      本申請はインサイダー情報（未公表の重要事実）を知りながら行うものではありません。
    </Typography>
  </Stack>
);
