import { CancelButton } from '@/components/atoms/Buttons/CancelButton';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { SubmitButton } from '@/components/atoms/Buttons/SubmitButton';
import { AssociationProps } from '@/types/components/Association';
import { Box } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export const AssociationButtons: FC<AssociationProps> = ({
  isConfirmed,
  setIsConfirmed,
  isError,
}) => {
  const navigate = useNavigate();

  return (
    <Box display="flex" mt={4} gap={2}>
      <CancelButton
        onClick={
          isConfirmed
            ? () => setIsConfirmed(false)
            : () => navigate('/association')
        }
      >
        {isConfirmed ? '編集へ戻る' : 'キャンセル'}
      </CancelButton>
      {isConfirmed ? (
        <SubmitButton>登録する</SubmitButton>
      ) : (
        <PrimaryButton disabled={isError} onClick={() => setIsConfirmed(true)}>
          確認する
        </PrimaryButton>
      )}
    </Box>
  );
};
