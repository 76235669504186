import { FC, useState, useCallback, useEffect } from 'react';
import { Stack, Box, Link, styled } from '@mui/material';
import { theme } from '@/theme';
import { useRecoilValue, useRecoilState } from 'recoil';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { EventListState } from '@/recoil/events/events';
import { appClient } from '@/services';
import { Complete } from '@/components/molecules/Modal/WorkReport/Complete';
import { WorkReportInputs as Inputs } from '@/types/components/Inputs';
import { SubmitHandler } from 'react-hook-form';
import { ReactComponent as Renrakuhyou } from '@/assets/renrakuhyou.svg';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { CancelButton } from '@/components/atoms/Buttons/CancelButton';
import { BasicModal } from '@/components/molecules/Modal';
import { TradeReport } from '@/components/molecules/Modal/TradeReport';
import { WorkReport } from '@/components/molecules/Modal/WorkReport';
import { FindTradereportsByID } from '@/types/api/tradereports';
import { FindWorkreportsById } from '@/types/api/workreports';
import { GetAssociation } from '@/types/api/associations';
import { MonthChange } from './SharedParts/MonthChange';
import { IndexTop } from './SharedParts/IndexTop';

const ModalLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  gap: '7px',
  color: theme.palette.secondary.normal,
  cursor: 'pointer',
  textDecorationColor: theme.palette.secondary.normal,
});

interface DividendProps {
  type: string;
  onSubmit: SubmitHandler<Inputs>;
  setModal: (val: string) => void;
  modal: string;
  tradereport: Required<FindTradereportsByID>;
  workreport: Required<FindWorkreportsById>;
  association?: GetAssociation;
  year: number;
  month: number;
  setYear: (value: number) => void;
  setMonth: (value: number) => void;
  openWorkreport: () => void;
  openTradereport: () => void;
}

export const ApplicationsDividendIndex: FC<DividendProps> = ({
  type,
  onSubmit,
  setMonth,
  setYear,
  year,
  month,
  setModal,
  modal,
  tradereport,
  workreport,
  association,
  openWorkreport,
  openTradereport,
}) => {
  const { associationCd = '' } = useRecoilValue(SelectedAssociationState);
  const [workreportApprovalStatus, setWorkreportApprovalStatus] = useState<
    'BEFORESUBMISSION' | 'SUBMISSION' | undefined
  >();
  const [tradereportApprovalStatus, setTradereportApprovalStatus] = useState<
    'AFTERRECEIPT' | 'BEFORERECEIPT' | undefined
  >();
  const [isEvent, setIsEvent] = useState<boolean>(false);
  const [events, setEvents] = useRecoilState(EventListState);

  const getEvents = useCallback(async () => {
    try {
      if (!associationCd) return;
      const res = await appClient.events.listEvents(associationCd, 'DIVIDEND');
      if (res.events) setEvents(res.events);
    } catch (e) {
      // handle getEvents error
    }
  }, [associationCd, setEvents]);

  useEffect(() => {
    void getEvents();
  }, [getEvents]);

  const setEventOfSelectedDate = () => {
    if (events) {
      const event = events
        .filter((e) => e.eventType === '11' || e.eventType === '12')
        .filter(
          (e) =>
            Number(e.eventNo?.slice(0, 4)) === year &&
            Number(e.eventNo?.slice(4, 6)) === month
        );
      setIsEvent(event.length > 0);
      setWorkreportApprovalStatus(event[0]?.workreportApprovalStatus);
      setTradereportApprovalStatus(event[0]?.tradereportsStatus);
    }
  };

  useEffect(() => {
    void setEventOfSelectedDate();
  }, [year, month, getEvents]);

  return (
    <Stack spacing={4}>
      <IndexTop type={type} />
      <Box px={5}>
        <MonthChange
          isPurchaseDate
          setYear={setYear}
          setMonth={setMonth}
          year={year}
          month={month}
          eventType="DIVIDEND"
        />
      </Box>
      {isEvent && (
        <Stack direction="row" spacing={3} px={5}>
          {workreportApprovalStatus === 'SUBMISSION' && (
            <ModalLink onClick={() => openWorkreport()}>
              <Renrakuhyou />
              作業処理連絡票
            </ModalLink>
          )}
          {workreportApprovalStatus === 'BEFORESUBMISSION' && (
            <PrimaryButton onClick={() => openWorkreport()}>
              作業処理連絡票を提出する
            </PrimaryButton>
          )}
          {tradereportApprovalStatus === 'AFTERRECEIPT' && (
            <CancelButton onClick={() => openTradereport()}>
              購入(売却)連絡票
            </CancelButton>
          )}
        </Stack>
      )}
      <BasicModal
        handleClose={() => setModal('')}
        open={modal === 'tradereport'}
      >
        <TradeReport
          type="dividend"
          onClose={() => setModal('')}
          tradereport={tradereport}
        />
      </BasicModal>
      <BasicModal
        handleClose={() => setModal('')}
        open={modal === 'workreport'}
      >
        <WorkReport
          type="dividend"
          onSubmit={onSubmit}
          association={association}
          isSubmit={workreportApprovalStatus}
          workreport={workreport}
          setModal={setModal}
          month={month}
        />
      </BasicModal>
      <BasicModal handleClose={() => setModal('')} open={modal === 'complete'}>
        <Complete onClose={() => setModal('')} />
      </BasicModal>
    </Stack>
  );
};
