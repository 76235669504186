/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { CancelButton } from '@/components/atoms/Buttons/CancelButton';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { theme } from '@/theme';
import {
  Box,
  styled,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { FC, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { AccountEditInputs as Inputs } from '@/types/components/Inputs';
import { useNavigate } from 'react-router-dom';
import { validatePassword } from '@/utils/validation';
import { ReactComponent as VisibilityOff } from '@/assets/visibilityOff.svg';
import { ReactComponent as VisibilityOn } from '@/assets/visibilityOn.svg';

const Wrapper = styled(Box)({});

const HeaderWrapper = styled(Box)({
  padding: '24px 40px',
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  borderWidth: 0,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.system.separator,
});

const Title = styled(Typography)({
  ...theme.typography.h4,
  color: theme.palette.system['text-normal'],
});

const ContentWrapper = styled(Box)({
  padding: '0 40px',
});

const ContentTitle = styled(Typography)({
  ...theme.typography['body-sub/regular'],
  color: theme.palette.system['text-normal'],
  marginTop: 24,
});

const ContentRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  borderWidth: 0,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.system.separator,
  gap: 24,
  padding: '24px 0',
});

const ContentRowLead = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: 144,
});

const ContentRowLeadText = styled(Typography)({
  ...theme.typography['body-main/medium'],
  color: theme.palette.system['text-normal'],
});

const ContentRowTrailing = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: 4,
  justifyContent: 'center',
});

const ContentRowTrailingPart = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 24,
});

const ContentRowTrailingPartText = styled(Typography)({
  ...theme.typography['caption/regular'],
  color: theme.palette.system['text-light'],
});

const ContentControlsWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 16,
  padding: '24px 0',
});

interface Props {
  onSubmit: SubmitHandler<Inputs>;
}

export const AccountPasswordEdit: FC<Props> = ({ onSubmit }) => {
  const navigate = useNavigate();
  const { control, handleSubmit, watch } = useForm<Inputs>({
    defaultValues: {
      oldPassword: '',
      password: '',
      rePassword: '',
    },
  });
  const [showPassword1, setShowPassword1] = useState(false);
  const handleClickShowPassword1 = () => setShowPassword1(!showPassword1);
  const handleMouseDownPassword1 = () => setShowPassword1(!showPassword1);
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);
  const [showPassword3, setShowPassword3] = useState(false);
  const handleClickShowPassword3 = () => setShowPassword3(!showPassword3);
  const handleMouseDownPassword3 = () => setShowPassword3(!showPassword3);

  return (
    <Wrapper>
      <HeaderWrapper>
        <NormalLink href="/account">アカウント設定に戻る</NormalLink>
        <Title>パスワード変更</Title>
      </HeaderWrapper>
      <ContentWrapper component="form" onSubmit={handleSubmit(onSubmit)}>
        <ContentTitle>
          現在のパスワードをお忘れの場合は管理者へ連絡してください。
        </ContentTitle>
        <ContentRow>
          <ContentRowLead>
            <ContentRowLeadText>現在のパスワード</ContentRowLeadText>
          </ContentRowLead>
          <ContentRowTrailing>
            <ContentRowTrailingPart>
              <Controller
                name="oldPassword"
                control={control}
                render={({ field }) => (
                  <TextField
                    value={field.value}
                    placeholder="パスワードを入力"
                    onChange={field.onChange}
                    sx={{ width: '240px' }}
                    type={showPassword1 ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword1}
                            onMouseDown={handleMouseDownPassword1}
                          >
                            {showPassword1 ? (
                              <VisibilityOn />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: { paddingLeft: '8px' },
                    }}
                  />
                )}
              />
            </ContentRowTrailingPart>
          </ContentRowTrailing>
        </ContentRow>
        <ContentRow>
          <ContentRowLead>
            <ContentRowLeadText>新しいパスワード</ContentRowLeadText>
          </ContentRowLead>
          <ContentRowTrailing>
            <ContentRowTrailingPart>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Box display="flex" alignItems="center" gap="8px">
                    <TextField
                      value={field.value}
                      placeholder="パスワードを入力"
                      onChange={field.onChange}
                      error={
                        !!(
                          watch('password') !== '' &&
                          !validatePassword(watch('password'))
                        )
                      }
                      sx={{ width: '240px', height: '40px' }}
                      type={showPassword2 ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword2}
                              onMouseDown={handleMouseDownPassword2}
                            >
                              {showPassword2 ? (
                                <VisibilityOn />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        style: { paddingLeft: '8px' },
                      }}
                    />
                    {watch('password') !== '' &&
                      !validatePassword(watch('password')) && (
                        <Typography
                          variant="caption/regular"
                          color={theme.palette.states.error}
                        >
                          パスワードの形式が正しくありません
                        </Typography>
                      )}
                  </Box>
                )}
              />
            </ContentRowTrailingPart>
            <ContentRowTrailingPart>
              <ContentRowTrailingPartText>
                ※半角英数字8桁以上
                <br />
                ※大文字、小文字、数字、記号をそれぞれ1文字以上使用してください。使用可能な記号は以下の通りです
                <br />
                {
                  '^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ ` = + -'
                }
                <br />
                ※自身の名前、生年月日、電話番号、連番など推測しやすい文字列は使用しないでください
              </ContentRowTrailingPartText>
            </ContentRowTrailingPart>
          </ContentRowTrailing>
        </ContentRow>
        <ContentRow>
          <ContentRowLead>
            <ContentRowLeadText>新しいパスワード再入力</ContentRowLeadText>
          </ContentRowLead>
          <ContentRowTrailing>
            <ContentRowTrailingPart>
              <Controller
                name="rePassword"
                control={control}
                render={({ field }) => (
                  <Box display="flex" alignItems="center" gap="8px">
                    <TextField
                      value={field.value}
                      placeholder="パスワードを入力"
                      onChange={field.onChange}
                      error={
                        !!(
                          watch('rePassword') &&
                          watch('password') !== watch('rePassword')
                        )
                      }
                      sx={{ width: '240px' }}
                      type={showPassword3 ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword3}
                              onMouseDown={handleMouseDownPassword3}
                            >
                              {showPassword3 ? (
                                <VisibilityOn />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        style: { paddingLeft: '8px' },
                      }}
                    />
                    {watch('rePassword') &&
                      watch('password') !== watch('rePassword') && (
                        <Typography
                          variant="caption/regular"
                          color={theme.palette.states.error}
                        >
                          入力されたパスワードが一致しません。
                        </Typography>
                      )}
                  </Box>
                )}
              />
            </ContentRowTrailingPart>
          </ContentRowTrailing>
        </ContentRow>
        <ContentControlsWrapper>
          <CancelButton onClick={() => navigate('/account')}>
            キャンセル
          </CancelButton>
          <PrimaryButton
            type="submit"
            disabled={
              !watch('oldPassword') ||
              !watch('rePassword') ||
              !watch('password') ||
              watch('password') !== watch('rePassword') ||
              watch('password').length < 8 ||
              !validatePassword(watch('password'))
            }
          >
            変更する
          </PrimaryButton>
        </ContentControlsWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};
