/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ApplicationsPartEdit } from '@/components/organisms/ApplicationsEdits/PartEdit';
import {
  useMatch,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { PartEditInputs as Inputs } from '@/types/components/Inputs';
import { FC, useState, useEffect } from 'react';
import { PrevnextApplicationParams } from '@/types/api/Applications/prevnextApplications';
import { FindApplicationByID } from '@/types/api/Applications/application';
import { ErrorResponse } from '@/types/api/error';
import { useMember } from '@/services/customHooks/useMember';
import { usePrevnextApplication } from '@/services/customHooks/usePrevnextApplication';
import { appClient } from '@/services';
import { useSnackbar } from '@/hooks/useSnackbar';
import { MemberAssets, ConvertMemberAssets } from '@/types/api/memberAssets';

export const PartEdit: FC = () => {
  const match = useMatch('/applications/:unitType/:applicationId/part/edit');
  const unitType = match?.params.unitType || '';
  const navigate = useNavigate();
  const applicationId = Number(useParams().applicationId) || 0;
  const [applicationData, setApplicationData] = useState<FindApplicationByID>();
  const { data: userData, updateMemberId } = useMember();
  const [searchParams] = useSearchParams();

  const [query] = useState<PrevnextApplicationParams>(
    searchParams as PrevnextApplicationParams
  );
  const { showSnackbar } = useSnackbar();
  const { data: bottomData } = usePrevnextApplication(applicationId, query);
  const getApplicationData = async () => {
    try {
      const res = await appClient.applications.findApplicationById(
        applicationId
      );
      setApplicationData(res);
    } catch (e) {
      console.log(e);
    }
  };

  const [memberAsset, setMemberAsset] = useState({} as MemberAssets);
  const getMemberAssetData = async () => {
    try {
      if (applicationData?.memberId && applicationData.memberId !== 0) {
        const res = await appClient.members.findMemberAsset(
          applicationData?.memberId
        );
        const convertedData = ConvertMemberAssets(res);
        if (convertedData) setMemberAsset(convertedData);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<Inputs> = async (d: Inputs) => {
    try {
      const res = await appClient.applications.updateApplication(
        applicationId,
        {
          applicationId,
          applicationPart: {
            sharesNum: d.unitNum ? Number(d.unitNum) * 100 : undefined,
            leftSharesNum:
              (applicationData?.applicationPart?.sharesNum || 0) +
              (applicationData?.applicationPart?.leftSharesNum || 0) -
              Number(d.unitNum) * 100,
          },
        }
      );
      if (applicationId)
        navigate(`/applications/${unitType}/${applicationId}/part`);
    } catch (e) {
      // エラー処理
      showSnackbar((e as ErrorResponse).data.message);
    }
  };

  useEffect(() => {
    void getApplicationData();
  }, [applicationId]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (applicationData?.memberId) updateMemberId(applicationData?.memberId);
    void getMemberAssetData();
  }, [applicationData?.memberId]);

  if (applicationData && userData) {
    return (
      <ApplicationsPartEdit
        application={applicationData}
        unitType={unitType}
        userData={userData}
        memberAsset={memberAsset}
        bottomData={bottomData}
        onSubmit={onSubmit}
      />
    );
  }
  return null;
};
