import { FC, useCallback, useEffect, useState } from 'react';
import { styled, Stack, Box, Button, Typography } from '@mui/material';
import { theme } from '@/theme';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { CancelButton } from '@/components/atoms/Buttons/CancelButton';
import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { TextInput } from '@/components/atoms/Input/TextInput';
import { CustomDropdown } from '@/components/molecules/Dropdown/CustomDropdown';
import { ReactComponent as NarrowingIcon } from '@/assets/narrowing.svg';
import { ReactComponent as CircleCross } from '@/assets/circleCross.svg';
import { ReactComponent as Note } from '@/assets/note.svg';

const NarrowingButton = styled(Button)({
  border: '1px solid',
  borderColor: theme.palette.system.separator,
  borderRadius: '4px',
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  color: theme.palette.system['text-normal'],
  height: '38px',
  '&:hover': {
    background: theme.palette.system.white,
  },
});

const Row = styled(Box)({
  display: 'flex',
  gap: '16px',
  alignItems: 'flex-start',
  width: '504px',
});

const LabelWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  minWidth: 64,
  height: '41px',
});

const MuiButton = styled(Button)({
  ...theme.typography['body-main/medium'],
  padding: '8px 16px',
  backgroundColor: theme.palette.system['background-dark'],
  borderRadius: '4px',
  color: theme.palette.system['text-normal'],
  minWidth: '79px',
  '&:hover': {
    background: theme.palette.system.inactive,
  },
});

const FilterBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '9px 8px',
  gap: '6px',
  borderRadius: '4px',
  border: '1px solid',
  borderColor: theme.palette.system['separator-light'],
});

interface Props {
  keywordValue?: string;
  setSelectedFilter?: (filter: Filter) => void;
  onClickSearch: (
    value: string,
    type?:
      | 'MONTHLY_UNIT'
      | 'BONUS_UNIT'
      | 'PART'
      | 'RECESS'
      | 'RESUME'
      | 'WITHDRAWAL',
    status?: 'UNAPPROVED' | 'APPROVED' | 'REJECTED'
  ) => void;
  isDisabledSubmit?: boolean;
  isDisabledSubmitWorkreport?: boolean;
  displayType?: string;
  onClickTemporaryContact?: () => void;
  openWorkreport?: () => void;
  openTradereport?: () => void;
  isEvent?: boolean;
  workreportApprovalStatus?: string;
  tradereportApprovalStatus?: string;
  downloadCsv?: () => void;
  shouldRenderIncentiveDownloadBtn?: boolean;
  downloadIncentiveCsv?: () => void;
}
type Filter = {
  label: string;
  value: string;
};

type ApplicationType =
  | '口数変更(給与)'
  | '口数変更(賞与)'
  | '一部引出'
  | '休止'
  | '再開'
  | '退会'
  | undefined;
type ApplicationTypeReturnEnum =
  | 'MONTHLY_UNIT'
  | 'BONUS_UNIT'
  | 'PART'
  | 'RECESS'
  | 'RESUME'
  | 'WITHDRAWAL'
  | undefined;
type ApplicationStatus = '未承認' | '承認済' | '却下' | undefined;
type ApplicationStatusReturnEnum =
  | 'UNAPPROVED'
  | 'APPROVED'
  | 'REJECTED'
  | undefined;

export const SearchByUserInputs: FC<Props> = ({
  keywordValue = '',
  setSelectedFilter,
  onClickSearch,
  isDisabledSubmit = false,
  isDisabledSubmitWorkreport,
  displayType = '',
  onClickTemporaryContact,
  openWorkreport,
  openTradereport,
  isEvent = false,
  workreportApprovalStatus = '',
  tradereportApprovalStatus = '',
  downloadCsv,
  shouldRenderIncentiveDownloadBtn = false,
  downloadIncentiveCsv,
}) => {
  const [inputValue, setInputValue] = useState<string>(keywordValue);
  const [applicationType, setApplicationType] = useState<ApplicationType>();
  const [applicationStatus, setApplicationStatus] =
    useState<ApplicationStatus>();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const updateFilter = (
    type: 'ADD' | 'REMOVE',
    filterLabel: string,
    filterValue: string
  ) => {
    if (type === 'REMOVE') {
      if (filterLabel === '状況') setApplicationStatus(undefined);
      if (filterLabel === '申請分類') setApplicationType(undefined);
    } else {
      if (filterLabel === '状況')
        setApplicationStatus(filterValue as ApplicationStatus);
      if (filterLabel === '申請分類')
        setApplicationType(filterValue as ApplicationType);
    }
  };

  const getApplicationTypeEnum = (): ApplicationTypeReturnEnum => {
    switch (applicationType) {
      case '口数変更(給与)':
        return 'MONTHLY_UNIT';
      case '口数変更(賞与)':
        return 'BONUS_UNIT';
      case '一部引出':
        return 'PART';
      case '休止':
        return 'RECESS';
      case '再開':
        return 'RESUME';
      case '退会':
        return 'WITHDRAWAL';
      default:
        return undefined;
    }
  };

  const getApplicationStatusEnum = (): ApplicationStatusReturnEnum => {
    switch (applicationStatus) {
      case '未承認':
        return 'UNAPPROVED';
      case '却下':
        return 'REJECTED';
      case '承認済':
        return 'APPROVED';
      default:
        return undefined;
    }
  };

  useEffect(() => {
    onClickSearch(
      inputValue,
      getApplicationTypeEnum(),
      getApplicationStatusEnum()
    );
  }, [applicationType, applicationStatus]);

  /**
   * @params 誰かうまく日本語で説明を書いてほしいです。
   * 簡単に説明するとラベルテキストや表示テキストで比べて、
   * もし選択されてるオプションだったらそれに適したBG色を返す機能です。
   */
  const getBackgroundColor = useCallback(
    (label: string, text: string) => {
      let compareThis;
      if (label === '状況') compareThis = applicationStatus;
      if (label === '申請分類') compareThis = applicationType;

      return compareThis === text
        ? theme.palette.secondary.main
        : theme.palette.system['background-dark'];
    },
    [applicationStatus, applicationType]
  );

  /**
   * @params 誰かうまく日本語で説明を書いてほしいです。
   * 簡単に説明するとラベルテキストや表示テキストで比べて、
   * もし選択されてるオプションだったらそれに適したTextColor色を返す機能です。
   */
  const getTextColor = useCallback(
    (label: string, text: string) => {
      let compareThis;
      if (label === '状況') compareThis = applicationStatus;
      if (label === '申請分類') compareThis = applicationType;

      return compareThis === text
        ? theme.palette.system.white
        : theme.palette.system['text-normal'];
    },
    [applicationStatus, applicationType]
  );

  const optionOnClick = (label: string, text: string) => {
    if (label === '状況') {
      if (applicationStatus === text) updateFilter('REMOVE', label, text);
      else updateFilter('ADD', label, text);
    }
    if (label === '申請分類') {
      if (applicationType === text) updateFilter('REMOVE', label, text);
      else updateFilter('ADD', label, text);
    }
  };

  const renderDropdownRow = (data: { label: string; options?: string[][] }) => (
    <Row key={data.label}>
      <LabelWrapper>
        <Typography variant="body-sub/regular">{data.label}</Typography>
      </LabelWrapper>
      <Stack spacing={1}>
        {data.options?.map((row, index) => (
          <Box
            display="flex"
            flexWrap="wrap"
            gap="8px"
            key={`data_row_${index + 1}`}
          >
            {row.map((val) => (
              <MuiButton
                key={val}
                sx={{
                  backgroundColor: getBackgroundColor(data.label, val),
                  color: getTextColor(data.label, val),
                }}
                onClick={() => optionOnClick(data.label, val)}
              >
                {val}
              </MuiButton>
            ))}
          </Box>
        ))}
      </Stack>
    </Row>
  );

  const renderIncentiveDownloadBtn = (state?: boolean) => {
    if (isEvent && shouldRenderIncentiveDownloadBtn && downloadIncentiveCsv) {
      return (
        <PrimaryButton
          disabled={state}
          onClick={() => downloadIncentiveCsv && downloadIncentiveCsv()}
          sx={{ width: 'auto', height: '38px' }}
        >
          積立・奨励金情報
        </PrimaryButton>
      );
    }
    return null;
  };

  const switchButtons = () => {
    switch (displayType) {
      case 'monthly':
      case 'bonus':
        return (
          <Box display="flex" gap={theme.spacing(1)} alignItems="center">
            <NormalLink
              onClick={() => downloadCsv && downloadCsv()}
              marginRight={1}
              sx={{ textDecoration: 'none' }}
            >
              CSVを出力
            </NormalLink>
            {isEvent && workreportApprovalStatus === 'SUBMISSION' && (
              <NormalLink onClick={() => openWorkreport && openWorkreport()}>
                <Note />
                作業処理連絡票
              </NormalLink>
            )}
            {isEvent && workreportApprovalStatus === 'BEFORESUBMISSION' && (
              <PrimaryButton
                disabled={isDisabledSubmitWorkreport}
                onClick={() => openWorkreport && openWorkreport()}
                sx={{ height: '38px' }}
              >
                作業処理連絡票を提出
              </PrimaryButton>
            )}
            {renderIncentiveDownloadBtn(isDisabledSubmitWorkreport)}
            {!isEvent ||
              (workreportApprovalStatus === '' && (
                <CancelButton sx={{ height: '38px' }} disabled>
                  作業処理連絡票を提出
                </CancelButton>
              ))}
            {tradereportApprovalStatus === 'AFTERRECEIPT' && (
              <CancelButton
                onClick={() => openTradereport && openTradereport()}
                sx={{ height: '38px' }}
              >
                購入（売却）連絡票
              </CancelButton>
            )}
          </Box>
        );
      case 'temporary':
        return (
          <Box>
            <PrimaryButton
              disabled={isDisabledSubmit}
              onClick={onClickTemporaryContact}
              sx={{ height: '38px' }}
            >
              臨時買付の事前連絡
            </PrimaryButton>
          </Box>
        );
      case 'dividend':
        return (
          <Box>
            <PrimaryButton disabled={isDisabledSubmit} sx={{ height: '38px' }}>
              臨時買付の事前連絡
            </PrimaryButton>
          </Box>
        );
      default:
        return undefined;
    }
  };

  return (
    <Stack spacing="8px" width="100%">
      <Box display="flex" justifyContent="space-between">
        <Stack direction="row" spacing={2}>
          <Stack direction="row" gap="4px">
            <TextInput
              type="search"
              placeholder={
                displayType === 'temporary'
                  ? '検索ワードを入力'
                  : '会員コード、会員名、社員コードを入力'
              }
              sx={{
                width: '316px',
                height: '38px',
                borderColor: theme.palette.system.separator,
              }}
              onChange={(e) => setInputValue(e.target.value)}
              onClickDelete={() => setInputValue('')}
              value={inputValue}
            />
            <PrimaryButton
              onClick={() => {
                onClickSearch(
                  inputValue,
                  getApplicationTypeEnum(),
                  getApplicationStatusEnum()
                );
              }}
              sx={{ height: '38px' }}
            >
              検索
            </PrimaryButton>
          </Stack>
          {(displayType === 'user' ||
            displayType === 'monthly' ||
            displayType === 'bonus') && (
            <Box position="relative">
              <NarrowingButton
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <NarrowingIcon />
                絞り込み
              </NarrowingButton>
              {isDropdownOpen && (
                <CustomDropdown
                  onClickAway={() => setIsDropdownOpen(false)}
                  sx={{
                    width: displayType === 'user' ? 368 : 504,
                    left: 0,
                    top: 39,
                  }}
                >
                  {displayType === 'monthly' &&
                    renderDropdownRow({
                      label: '申請分類',
                      options: [
                        [
                          '口数変更(給与)',
                          '口数変更(賞与)',
                          '一部引出',
                          '休止',
                          '再開',
                          '退会',
                        ],
                      ],
                    })}
                  {renderDropdownRow({
                    label: '状況',
                    options: [['未承認', '承認済', '却下']],
                  })}
                </CustomDropdown>
              )}
            </Box>
          )}
        </Stack>
        {switchButtons()}
      </Box>
      <Box display="flex" gap={theme.spacing(1)}>
        {applicationType && (
          <FilterBox marginBottom={theme.spacing(1)}>
            <Typography>申請分類：{applicationType}</Typography>
            <Box
              display="flex"
              alignItems="center"
              sx={{ cursor: 'pointer' }}
              onClick={() =>
                updateFilter('REMOVE', '申請分類', applicationType)
              }
            >
              <CircleCross />
            </Box>
          </FilterBox>
        )}
        {applicationStatus && (
          <FilterBox marginBottom={theme.spacing(1)}>
            <Typography>状況：{applicationStatus}</Typography>
            <Box
              display="flex"
              alignItems="center"
              sx={{ cursor: 'pointer' }}
              onClick={() => updateFilter('REMOVE', '状況', applicationStatus)}
            >
              <CircleCross />
            </Box>
          </FilterBox>
        )}
      </Box>
    </Stack>
  );
};
