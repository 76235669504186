import { TextInput } from '@/components/atoms/Input/TextInput';
import { PrimaryTableBodyTdMulti } from '@/components/atoms/Table/PrimaryTableBodyTdMulti';
import { PrimaryTable } from '@/components/molecules/Table/PrimaryTable';
import { CreateApplication } from '@/types/api/applications';
import { FindMemberAsset } from '@/types/api/members';
import { Box, Typography } from '@mui/material';
import { IsNumeric } from '@/utils/stringProcessor';
import { FC, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

type Props = {
  isConfirmed: boolean;
  stocksNumber: FindMemberAsset['stocksNumber'];
  methods: UseFormReturn<CreateApplication>;
  setIsUnit: (isUnit: boolean) => void;
  investmentDigit?: number;
};

export const MemberProxyApplicationBodyPart: FC<Props> = ({
  isConfirmed,
  stocksNumber,
  methods,
  setIsUnit,
  investmentDigit,
}) => {
  const watchSharesNum = methods.watch('applicationPart.sharesNum') ?? 0;

  useEffect(() => {
    if (watchSharesNum === 0) {
      setIsUnit(false);
    } else {
      setIsUnit(true);
    }
  }, [watchSharesNum]);

  return (
    <PrimaryTable title="引出株数">
      <PrimaryTableBodyTdMulti title="現在の保有株数" row={1}>
        {isConfirmed ? (
          <Typography>
            {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call */}
            {`${(stocksNumber ?? 0).toLocaleString(undefined, {
              maximumFractionDigits: investmentDigit,
            })}株`}
          </Typography>
        ) : (
          <Typography variant="body-main/bold">
            {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call */}
            {`${(stocksNumber ?? 0).toLocaleString(undefined, {
              maximumFractionDigits: investmentDigit,
            })}株`}
          </Typography>
        )}
      </PrimaryTableBodyTdMulti>
      <PrimaryTableBodyTdMulti title="引出株数" row={1}>
        {isConfirmed ? (
          <Typography>{`${(
            Number(watchSharesNum) * 100
          ).toLocaleString()}株`}</Typography>
        ) : (
          <Box display="flex" alignItems="center" gap={1}>
            <TextInput
              name="applicationPart.sharesNum"
              value={watchSharesNum}
              onChange={(event) => {
                let amount = watchSharesNum;
                if (IsNumeric(event.target.value)) {
                  const userMaxAvailableUnits = Number(stocksNumber) / 100;
                  amount = Math.floor(
                    Math.min(Number(event.target.value), userMaxAvailableUnits)
                  );
                } else if (event.target.value === '') {
                  amount = 0;
                }
                methods.setValue(
                  'applicationPart.sharesNum',
                  Number(amount) || 0
                );
              }}
              onKeyDown={(e) => {
                if (e.code === 'Minus') e.preventDefault();
              }}
              sx={{
                width: 80,
                '& .MuiInputBase-root': { '& input': { textAlign: 'right' } },
              }}
            />
            <Typography>00株</Typography>
            <Typography variant="body-sub/regular" color="system.text-light">
              ※100株単位
            </Typography>
          </Box>
        )}
      </PrimaryTableBodyTdMulti>
      <PrimaryTableBodyTdMulti title="残り株数" row={1}>
        {isConfirmed ? (
          <Typography>
            {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call */}
            {`${(
              (stocksNumber ?? 0) -
              Number(watchSharesNum) * 100
            ).toLocaleString(undefined, {
              maximumFractionDigits: investmentDigit,
            })}株`}
          </Typography>
        ) : (
          <Typography fontWeight={500} fontSize={22} lineHeight="150%">
            {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call */}
            {`${(
              (stocksNumber ?? 0) -
              Number(watchSharesNum) * 100
            ).toLocaleString(undefined, {
              maximumFractionDigits: investmentDigit,
            })} 株`}
          </Typography>
        )}
      </PrimaryTableBodyTdMulti>
    </PrimaryTable>
  );
};
