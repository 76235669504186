import { FC } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { theme } from '@/theme/theme';

interface TitleProps {
  title: string;
  subtitle?: string;
}

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const TitleText = styled(Typography)(() => {
  const { typography, palette } = theme;

  return {
    ...typography.h4,
    color: palette.system['text-normal'],
  };
});

const SubtitleText = styled(Typography)(() => {
  const { typography, palette } = theme;

  return {
    ...typography['body-main/regular'],
    color: palette.system['text-light'],
    marginLeft: 8,
  };
});

export const Title: FC<TitleProps> = ({ title, subtitle }) => (
  <Wrapper>
    <TitleText>{title}</TitleText>
    {subtitle && <SubtitleText>{subtitle}</SubtitleText>}
  </Wrapper>
);
