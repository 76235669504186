/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { createContext, useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { format } from 'date-fns';
import { useApplications } from '@/services/customHooks/useApplications';
import { isUnapprovedApplication } from '@/utils/application';

type Context = ReturnType<typeof useProviderBonus>;
const ApplicationsBonusContext = createContext({} as unknown as Context);

const initialState = {
  associationCd: '',
  yearMonth: Number(format(new Date(), 'yyyyMM')),
  eventType: 'BONUS',
  // applicationType: '',
  // applicationStatus: '',
  page: 1,
  perPageSize: 20,
  sortKey: 'APPLICATION_ID_DESC',
};

const useProviderBonus = (associationCd: string) => {
  const now = new Date();
  const formatDateToday = format(now, 'yyyyMM');
  const [dateState, setDateState] = React.useState(formatDateToday);
  const [params, setParams] = React.useState({
    ...initialState,
    associationCd,
  });
  // 赤バッジの表示・非表示
  const [hasUnapprovedBonusApplication, setHasUnapprovedBonusApplication] =
    React.useState(false);

  const {
    data: applicationsData,
    applicationsError,
    applicationsMutate,
    // @ts-ignore
  } = useApplications(params);

  useEffect(() => {
    setParams({
      ...params,
      associationCd,
      yearMonth: Number(dateState),
    });
  }, [associationCd, dateState]);

  // 赤バッジの表示・非表示
  useEffect(() => {
    if (applicationsData) {
      const hasUnapprovedApplications = applicationsData.applications.filter(
        (application) => isUnapprovedApplication(application.applicationStatus)
      );
      setHasUnapprovedBonusApplication(hasUnapprovedApplications.length > 0);
    }
  }, [applicationsData]);

  /**
   * handleDateChange
   * @param date
   */
  const handleDateChange = (date: number | Date) => {
    const formatDate = format(date, 'yyyyMM');
    setDateState(formatDate);
  };

  return {
    applicationsBonusData: applicationsData,
    applicationsBonusError: applicationsError,
    applicationsBonusMutate: applicationsMutate,
    applicationsBonusLoading: !applicationsData && !applicationsError,
    // 赤バッジ用
    hasUnapprovedBonusApplication,
    handleDateChange,
  };
};

export const useApplicationsBonus = () => useContext(ApplicationsBonusContext);

export const ApplicationsBonusProvider: React.FC<{
  associationCd: string;
  children: React.ReactNode;
}> = ({ associationCd, children }) => {
  const applicationsBonus = useProviderBonus(associationCd);
  return (
    <ApplicationsBonusContext.Provider value={applicationsBonus}>
      {children}
    </ApplicationsBonusContext.Provider>
  );
};

export const Bonus = () => <Outlet />;
