import { styled, TextField } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { endOfYear, addYears, subDays } from 'date-fns';
import { ja } from 'date-fns/locale';
import {
  StaticDatePicker,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import './calendar.css';

const Wrapper = styled('div')(({ theme }) => {
  const { palette } = theme;
  return {
    borderRadius: '4px',
    border: '1px solid',
    borderColor: palette.system['separator-light'],
  };
});

type CalendarProps = {
  value?: Date | number | null;
  initEventDates?: number[];
  setSelectedDate: (date: Date) => void;
};

export const Calendar: FC<CalendarProps> = ({
  value = new Date(),
  initEventDates = [],
  setSelectedDate,
}) => {
  const today = new Date(new Date().toDateString());
  const yesterday = subDays(new Date(today), 1);
  today.setDate(today.getDate() + 2);
  // イベント発生日の配列。カレンダー色付けのためにnumber型に変換する必要がある。
  const [eventDates, setEventDates] = useState<number[]>(initEventDates);

  useEffect(() => {
    if (initEventDates.length > 0) {
      setEventDates(initEventDates);
    }
  }, [initEventDates]);

  const renderWeekEndPickerDay = (
    date: Date,
    _selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ) => {
    const switchDayColor = (d: number) => {
      if (eventDates.indexOf(d) !== -1) {
        if (yesterday.getTime() < d) {
          return { backgroundColor: 'primary.light' };
        }
        return { backgroundColor: 'system.background' };
      }
      return {};
    };

    const newPickersDayProps = {
      ...pickersDayProps,
      sx: switchDayColor(date.getTime()),
    };

    return <PickersDay {...newPickersDayProps} />;
  };

  return (
    <Wrapper className="calendarWrapper">
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={ja}
        showMonthAfterYear
        dateFormats={{ monthAndYear: 'yyyy年MM月' }}
      >
        <StaticDatePicker
          maxDate={endOfYear(addYears(new Date(), 1))}
          minDate={new Date('2010-01-01')}
          onChange={(e) => (e ? setSelectedDate(e) : setSelectedDate(today))}
          onMonthChange={(e) => setSelectedDate(e)}
          displayStaticWrapperAs="desktop"
          value={value}
          renderInput={() => <TextField />}
          disableOpenPicker
          renderDay={renderWeekEndPickerDay}
        />
      </LocalizationProvider>
    </Wrapper>
  );
};
