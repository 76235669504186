import { useMemo } from 'react';
import useSWR from 'swr';
import { appClient } from '..';

type OfficeSpecialApplicationRequestParams = {
  associationCd: string;
  keyword?: string;
  page?: number;
  perPageSize?: number;
  sortKey?: 'CREATED_ASC' | 'CREATED_DESC';
};

export const useApplicationsTemporaryEvents = (
  params: OfficeSpecialApplicationRequestParams
) => {
  const useSwrKey = '/api/applications/special';
  const fetcher = async () =>
    appClient.applications.listOfficeSpecialApplication(
      params.associationCd,
      params.keyword === '' ? undefined : params.keyword,
      params.page || 1,
      params.perPageSize,
      params.sortKey
    );
  const { data: apiData, error } = useSWR(
    [
      `${useSwrKey}/page=${params.page || 1}`,
      params.sortKey,
      params.keyword,
      params.perPageSize,
    ],
    fetcher
  );

  return useMemo(
    () => ({
      data: apiData,
      error,
    }),
    [apiData]
  );
};
