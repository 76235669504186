import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AssociationDeadlineOrganism } from '@/components/organisms/Association/AssociationDeadlineOrganism';
import { AssociationRequest } from '@/types/api/associations';
import { appClient } from '@/services';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { snackbarAtom } from '@/atom/snackbarAtom';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { FormProvider, useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import useSWR from 'swr';

export const AssociationDeadline = () => {
  const [isError, setIsError] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const openSnackBar = useSetRecoilState(snackbarAtom);
  const navigate = useNavigate();
  const { associationCd = '' } = useRecoilValue(SelectedAssociationState);
  const { mutate } = useSWR(
    associationCd ? '/api/associations/{associationCd}' : null,
    () => appClient.associations.getAssociation(associationCd)
  );
  const methods = useForm<AssociationRequest>({
    defaultValues: {
      memberDeadlineDays: 1,
    },
  });

  const onSubmit = async (data: AssociationRequest) => {
    try {
      await appClient.associations.updateAssociation(associationCd, data);
      openSnackBar({
        open: true,
        message: '会員締日を変更しました。',
      });
      navigate('/association');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const updateState = async () => {
    const state = await mutate();
    methods.reset(state);
  };

  useEffect(() => {
    void updateState();
  }, [associationCd]);

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
        <AssociationDeadlineOrganism
          isConfirmed={isConfirmed}
          setIsConfirmed={(v) => setIsConfirmed(v)}
          methods={methods}
          isError={isError}
          setIsError={(v) => setIsError(v)}
        />
      </Box>
    </FormProvider>
  );
};
