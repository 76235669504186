/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Box } from '@mui/material';
import { FC } from 'react';
import { WorkReportInputs as Inputs } from '@/types/components/Inputs';
import { Control, FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { FindWorkreportsById } from '@/types/api/workreports';
import { GetAssociation } from '@/types/api/associations';
import { DividendContact } from './DividendContact';

interface Props {
  control: Control<Inputs>;
  isSubmit: 'BEFORESUBMISSION' | 'SUBMISSION' | undefined;
  modalStatus: boolean;
  data: Inputs;
  report: Required<FindWorkreportsById>;
  errors: FieldErrorsImpl<Inputs>;
  register: UseFormRegister<Inputs>;
  association?: GetAssociation;
}
export const DividendWorkReport: FC<Props> = ({
  control,
  isSubmit,
  modalStatus,
  data,
  report,
  errors,
  register,
  association,
}) => (
  <Box sx={{ display: 'flex', gap: '24px' }}>
    <DividendContact
      control={control}
      isSubmit={isSubmit}
      modalStatus={modalStatus}
      data={data}
      report={report}
      register={register}
      errors={errors}
      association={association}
    />
  </Box>
);
