import { AUTH_TOKEN } from '@/constants/Storage/StorageKey';
import { AppClient } from './apiServices';
import { OpenAPI } from './apiServices/core/OpenAPI';

export type { OpenAPIConfig } from './apiServices/core/OpenAPI';
export type { Error } from './apiServices/models/Error';

const authToken = () => {
  const token = localStorage.getItem(AUTH_TOKEN);
  if (token) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const pToken = JSON.parse(token);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return `Bearer ${pToken.IdToken as string}`;
  }
  return 'Bearer dummy';
};
OpenAPI.HEADERS = {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  Authorization: authToken(),
};

const appClient = new AppClient(OpenAPI);
export { appClient, OpenAPI };

// third party services
export { PostcodeJPService } from './postJPService';
