/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { FC, useState, useCallback, useEffect } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { theme } from '@/theme';
import { SubmitHandler } from 'react-hook-form';
import { WorkReportInputs as Inputs } from '@/types/components/Inputs';
import { Complete } from '@/components/molecules/Modal/WorkReport/Complete';
import { BasicTable } from '@/components/molecules/Table/BasicTable';
import { TableHeadRow } from '@/components/atoms/Table/TableHeadRow';
import { TableHeadCell } from '@/components/atoms/Table/TableHeadCell';
import { TableBodyRow } from '@/components/atoms/Table/TableBodyRow';
import { TableBodyCell } from '@/components/atoms/Table/TableBodyCell';
import { BasicModal } from '@/components/molecules/Modal';
import { TradeReport } from '@/components/molecules/Modal/TradeReport';
import { WorkReport } from '@/components/molecules/Modal/WorkReport';
import { StatusBadge } from '@/components/atoms/Badge/StatusBadge';
import { FindTradereportsByID } from '@/types/api/tradereports';
import { FindWorkreportsById } from '@/types/api/workreports';
import { ReactComponent as Oops } from '@/assets/exclamationRed.svg';
import { ApplicationsApplicationItem } from '@/types/api/Applications/applications';
import { format, parseISO } from 'date-fns';
import { ja } from 'date-fns/locale';
import { useRecoilState, useRecoilValue } from 'recoil';
import { EventListState } from '@/recoil/events/events';
import { appClient } from '@/services';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { useUnapprovedApplication } from '@/services/customHooks/useUnapprovedApplications';
import {
  convertApplicationType,
  convertApplicationTypeForUrl,
} from '@/utils/application';
import { MonthChange } from './SharedParts/MonthChange';
import { SearchByUserInputs } from './SharedParts/SearchByUserInputs';
import { IndexTop } from './SharedParts/IndexTop';

type SortKeyType =
  | 'APPLICATION_ID_ASC'
  | 'APPLICATION_ID_DESC'
  | 'APPLICATION_TYPE_ASC'
  | 'APPLICATION_TYPE_DESC'
  | 'MEMBER_CD_ASC'
  | 'MEMBER_CD_DESC'
  | 'MEMBER_NAME_ASC'
  | 'MEMBER_NAME_DESC'
  | 'EMPLOYEE_CD_ASC'
  | 'EMPLOYEE_CD_DESC'
  | 'APPLICATION_DATE_ASC'
  | 'APPLICATION_DATE_DESC'
  | 'APPLICATION_STATUS_ASC'
  | 'APPLICATION_STATUS_DESC';

interface UnitProps {
  type: string;
  data: ApplicationsApplicationItem[];
  unapprovedData: ApplicationsApplicationItem[];
  onClickSearch: (
    value: string,
    type?:
      | 'MONTHLY_UNIT'
      | 'BONUS_UNIT'
      | 'PART'
      | 'RECESS'
      | 'RESUME'
      | 'WITHDRAWAL',
    status?: 'UNAPPROVED' | 'APPROVED' | 'REJECTED'
  ) => void;
  isListed: boolean;
  keywordValue?: string;
  onSubmit: SubmitHandler<Inputs>;
  tradereport: Required<FindTradereportsByID>;
  workreport: Required<FindWorkreportsById>;
  setModal: (value: string) => void;
  modal: string;
  year: number;
  month: number;
  setYear: (value: number) => void;
  setMonth: (value: number) => void;
  openWorkreport: () => void;
  openTradereport: () => void;
  applicationsError?: any;
  query?: string;
  sortKey?: SortKeyType;
  updateSortKey?: (value: SortKeyType) => void;
  downloadCsv: () => void;
  downloadIncentiveCsv: () => void;
}
export const ApplicationsUnitIndex: FC<UnitProps> = ({
  type,
  data,
  unapprovedData,
  keywordValue,
  onClickSearch,
  isListed,
  onSubmit,
  setModal,
  modal,
  setMonth,
  setYear,
  year,
  month,
  tradereport,
  workreport,
  openWorkreport,
  openTradereport,
  applicationsError = undefined,
  query = '',
  sortKey,
  updateSortKey,
  downloadCsv,
  downloadIncentiveCsv,
}) => {
  const {
    isUapprovedAdmission,
    isUapprovedMonthlyApplications,
    unapprovedAdmissionNumber,
  } = useUnapprovedApplication(
    Number(
      String(year) +
        (String(month).length === 1 ? '0' + String(month) : String(month))
    ),
    type
  );

  const { associationCd = '' } = useRecoilValue(SelectedAssociationState);
  const [workreportApprovalStatus, setWorkreportApprovalStatus] = useState<
    'BEFORESUBMISSION' | 'SUBMISSION' | undefined
  >();
  const [tradereportApprovalStatus, setTradereportApprovalStatus] = useState<
    'AFTERRECEIPT' | 'BEFORERECEIPT' | undefined
  >();
  const [isEvent, setIsEvent] = useState<boolean>(false);
  const [events, setEvents] = useRecoilState(EventListState);

  const getEvents = useCallback(async () => {
    try {
      if (!associationCd) return;
      const res = await appClient.events.listEvents(associationCd);
      if (res.events) setEvents(res.events);
    } catch (e) {
      // handle getEvents error
    }
  }, [associationCd, setEvents]);

  useEffect(() => {
    void getEvents();
  }, [getEvents]);

  const setEventOfSelectedDate = () => {
    if (events) {
      if (type === 'monthly') {
        const event = events
          .filter((e) => e.eventType === '01')
          .filter(
            (e) =>
              Number(e.eventNo?.slice(0, 4)) === year &&
              Number(e.eventNo?.slice(4, 6)) === month
          );
        setIsEvent(event.length > 0);
        setWorkreportApprovalStatus(event[0]?.workreportApprovalStatus);
        setTradereportApprovalStatus(event[0]?.tradereportsStatus);
      } else if (type === 'bonus') {
        const event = events
          .filter((e) => e.eventType === '02')
          .filter(
            (e) =>
              Number(e.eventNo?.slice(0, 4)) === year &&
              Number(e.eventNo?.slice(4, 6)) === month
          );
        setIsEvent(event.length > 0);
        setWorkreportApprovalStatus(event[0]?.workreportApprovalStatus);
        setTradereportApprovalStatus(event[0]?.tradereportsStatus);
      }
    }
  };

  const checkUnapprovedApplications = () => {
    const applyingData = data.filter((d) => d.applicationStatus === 'APPLYING');
    return applyingData.length > 0;
  };

  useEffect(() => {
    void setEventOfSelectedDate();
  }, [year, month, events, getEvents]);

  return (
    <Stack spacing={4}>
      <IndexTop type={type} />
      <Stack spacing={4} px={5}>
        <MonthChange
          setYear={setYear}
          setMonth={setMonth}
          year={year}
          month={month}
          eventType={
            type.toUpperCase() as 'MONTHLY' | 'BONUS' | 'DIVIDEND' | 'SPECIAL'
          }
        />
        {/* 承認残がある場合 */}
        {isUapprovedAdmission && (
          <Box display="flex" alignItems="center" gap="6px">
            <Oops />
            <Typography color={theme.palette.states.error}>
              新規会員登録の承認が{unapprovedAdmissionNumber}
              件残っています。承認が完了するまで作業処理連絡票を提出できません。
            </Typography>
          </Box>
        )}
        {/*  */}
        <Stack spacing={1}>
          <SearchByUserInputs
            keywordValue={keywordValue}
            onClickSearch={onClickSearch}
            displayType={type}
            isDisabledSubmitWorkreport={
              isUapprovedAdmission || isUapprovedMonthlyApplications
            }
            openWorkreport={openWorkreport}
            openTradereport={openTradereport}
            isEvent={isEvent}
            workreportApprovalStatus={workreportApprovalStatus}
            tradereportApprovalStatus={tradereportApprovalStatus}
            downloadCsv={downloadCsv}
            shouldRenderIncentiveDownloadBtn={unapprovedData.length === 0}
            downloadIncentiveCsv={downloadIncentiveCsv}
          />
          <BasicTable>
            <TableHeadRow>
              <TableHeadCell
                type={sortKey?.includes('APPLICATION_ID') ? 'sort' : 'normal'}
                sort={() => {}}
                sortType={sortKey?.includes('ASC') ? 'ASC' : 'DESC'}
                width="10%"
              >
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (!updateSortKey) return;
                    if (sortKey === 'APPLICATION_ID_ASC')
                      updateSortKey('APPLICATION_ID_DESC');
                    else updateSortKey('APPLICATION_ID_ASC');
                  }}
                >
                  申請ID
                </Box>
              </TableHeadCell>
              <TableHeadCell
                type={sortKey?.includes('APPLICATION_TYPE') ? 'sort' : 'normal'}
                sort={() => {}}
                sortType={sortKey?.includes('ASC') ? 'ASC' : 'DESC'}
                width="26%"
              >
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (!updateSortKey) return;
                    if (sortKey === 'APPLICATION_TYPE_ASC')
                      updateSortKey('APPLICATION_TYPE_DESC');
                    else updateSortKey('APPLICATION_TYPE_ASC');
                  }}
                >
                  申請分類
                </Box>
              </TableHeadCell>
              <TableHeadCell
                type={sortKey?.includes('MEMBER_CD') ? 'sort' : 'normal'}
                sort={() => {}}
                sortType={sortKey?.includes('ASC') ? 'ASC' : 'DESC'}
                width="9%"
              >
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (!updateSortKey) return;
                    if (sortKey === 'MEMBER_CD_ASC')
                      updateSortKey('MEMBER_CD_DESC');
                    else updateSortKey('MEMBER_CD_ASC');
                  }}
                >
                  会員コード
                </Box>
              </TableHeadCell>
              <TableHeadCell
                type={sortKey?.includes('EMPLOYEE_CD') ? 'sort' : 'normal'}
                sort={() => {}}
                sortType={sortKey?.includes('ASC') ? 'ASC' : 'DESC'}
                width="9%"
              >
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (!updateSortKey) return;
                    if (sortKey === 'EMPLOYEE_CD_ASC')
                      updateSortKey('EMPLOYEE_CD_DESC');
                    else updateSortKey('EMPLOYEE_CD_ASC');
                  }}
                >
                  社員コード
                </Box>
              </TableHeadCell>
              <TableHeadCell
                type={sortKey?.includes('MEMBER_NAME') ? 'sort' : 'normal'}
                sort={() => {}}
                sortType={sortKey?.includes('ASC') ? 'ASC' : 'DESC'}
                width="26%"
              >
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (!updateSortKey) return;
                    if (sortKey === 'MEMBER_NAME_ASC')
                      updateSortKey('MEMBER_NAME_DESC');
                    else updateSortKey('MEMBER_NAME_ASC');
                  }}
                >
                  会員名
                </Box>
              </TableHeadCell>
              <TableHeadCell
                type={sortKey?.includes('APPLICATION_DATE') ? 'sort' : 'normal'}
                sort={() => {}}
                sortType={sortKey?.includes('ASC') ? 'ASC' : 'DESC'}
                width="13%"
              >
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (!updateSortKey) return;
                    if (sortKey === 'APPLICATION_DATE_ASC')
                      updateSortKey('APPLICATION_DATE_DESC');
                    else updateSortKey('APPLICATION_DATE_ASC');
                  }}
                >
                  申請日時
                </Box>
              </TableHeadCell>
              <TableHeadCell
                type={
                  sortKey?.includes('APPLICATION_STATUS') ? 'sort' : 'normal'
                }
                sort={() => {}}
                sortType={sortKey?.includes('ASC') ? 'ASC' : 'DESC'}
                width="7%"
              >
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (!updateSortKey) return;
                    if (sortKey === 'APPLICATION_STATUS_ASC')
                      updateSortKey('APPLICATION_STATUS_DESC');
                    else updateSortKey('APPLICATION_STATUS_ASC');
                  }}
                >
                  状況
                </Box>
              </TableHeadCell>
            </TableHeadRow>
            {applicationsError && <div>データ取得に失敗しました</div>}
            {!applicationsError &&
              data.map((d) => (
                <TableBodyRow
                  key={d.applicationId}
                  url={
                    query !== ''
                      ? `/applications/${type}/${
                          d.applicationId
                        }/${convertApplicationTypeForUrl(
                          d.applicationType
                        )}?keyword=${query}`
                      : `/applications/${type}/${
                          d.applicationId
                        }/${convertApplicationTypeForUrl(d.applicationType)}`
                  }
                  applicationType={d.applicationType}
                >
                  <TableBodyCell value={d.applicationId} />
                  <TableBodyCell
                    value={convertApplicationType(d.applicationType)}
                  />
                  <TableBodyCell value={d.memberCd} />
                  <TableBodyCell value={d.employeeCd} />
                  <TableBodyCell value={d.memberName} />
                  <TableBodyCell
                    value={format(
                      parseISO(d.applicationDate),
                      'yyyy/MM/dd (E) HH:mm',
                      {
                        locale: ja,
                      }
                    )}
                  />
                  <TableBodyCell type="badge">
                    <StatusBadge
                      status={d.applicationStatus}
                      sx={{ height: '22px', maxWidth: '48px' }}
                    />
                  </TableBodyCell>
                </TableBodyRow>
              ))}
          </BasicTable>
        </Stack>
      </Stack>
      <BasicModal
        handleClose={() => setModal('')}
        open={modal === 'tradereport'}
      >
        <TradeReport
          onClose={() => setModal('')}
          tradereport={tradereport}
          type={type}
        />
      </BasicModal>
      <BasicModal
        handleClose={() => setModal('')}
        open={modal === 'workreport'}
      >
        <WorkReport
          type={type}
          isListed={isListed}
          onSubmit={onSubmit}
          isSubmit={workreportApprovalStatus}
          workreport={workreport}
          setModal={setModal}
          month={month}
        />
      </BasicModal>
      <BasicModal handleClose={() => setModal('')} open={modal === 'complete'}>
        <Complete
          onClose={() => {
            void getEvents();
            setModal('');
          }}
        />
      </BasicModal>
    </Stack>
  );
};
