/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EventsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * 【API-B-2010】持株会イベント一覧取得
   * 持株会イベント一覧取得
   *
   * @param associationCd 持株会コード
   *
   * @param eventType イベント種別：未指定の場合、絞り込みなし
   * - MONTHLY: 月例
   * - BONUS: 賞与
   * - DIVIDEND: 配当
   * - SPECIAL: 臨時
   *
   * @param eventTargetDateType 抽出日_対象項目：指定されている場合は、抽出日From/抽出日To必須
   * - PURCHASE_DATE: 買付日
   * - PURCHASE_REGISTRATION_DATE: 買付日登録期日
   * - WORKREPORT_DUE_DATE: 作業処理連絡票期日
   * - YEAR_MONTH: 年月
   *
   * @param dateFrom 抽出日From
   *
   * @param dateTo 抽出日To
   *
   * @returns any Events response
   * @throws ApiError
   */
  public listEvents(
    associationCd: string,
    eventType?: 'MONTHLY' | 'BONUS' | 'DIVIDEND' | 'SPECIAL',
    eventTargetDateType?:
      | 'PURCHASE_DATE'
      | 'PURCHASE_REGISTRATION_DATE'
      | 'WORKREPORT_DUE_DATE'
      | 'YEAR_MONTH',
    dateFrom?: string,
    dateTo?: string
  ): CancelablePromise<{
    events?: Array<{
      /**
       * 持株会コード
       */
      associationCd?: string;
      /**
       * 持株会名称
       */
      associationName?: string;
      /**
       * 上場区分
       * - LISTEDCOMPANY: 上場
       * - UNLISTEDCOMPANY: 非上場
       *
       */
      listedClassification?: 'LISTEDCOMPANY' | 'UNLISTEDCOMPANY';
      /**
       * イベントNo.
       */
      eventNo?: string;
      /**
       * イベントタイプ
       */
      eventType?: string;
      /**
       * 買付日
       */
      purchaseDate?: string;
      /**
       * 買付日入力値
       */
      purchaseDateInputValue?: string;
      /**
       * 積立日入力値
       */
      accumulatedDateInputValue?: string;
      /**
       * 買付日登録ステータス
       * - BEFORREGISTRATION: 登録前
       * - REGITERED: 登録済
       *
       */
      purchaseDateRegistrationStatus?: 'BEFORREGISTRATION' | 'REGITERED';
      /**
       * 買付日登録期日
       */
      purchaseDateRegistrationDate?: string;
      /**
       * 作業処理連絡票ステータス
       * - BEFORESUBMISSION: 提出前
       * - SUBMISSION: 提出済
       *
       */
      workreportApprovalStatus?: 'BEFORESUBMISSION' | 'SUBMISSION';
      /**
       * 作業処理連絡票期日
       */
      workreportDueDate?: string;
      /**
       * 買付予定日
       */
      purchaseScheduleDay?: number;
      /**
       * 購入（売却）連絡票受領ステータス
       * - BEFORERECEIPT: 受領前
       * - AFTERRECEIPT: 受領済
       *
       */
      tradereportsStatus?: 'BEFORERECEIPT' | 'AFTERRECEIPT';
    }>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/events/{associationCd}',
      path: {
        associationCd: associationCd,
      },
      query: {
        eventType: eventType,
        eventTargetDateType: eventTargetDateType,
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-2013】イベント情報を更新する
   * イベント情報を更新する
   *
   * @param associationCd 持株会コード
   *
   * @param eventNo イベントNo.
   * @param requestBody events extension request
   * @returns any application response
   * @throws ApiError
   */
  public updateEventById(
    associationCd: string,
    eventNo: string,
    requestBody: {
      /**
       * 会員受付締日
       */
      memberDeadline?: string;
      /**
       * 事務局受付締日
       */
      officeDeadline?: string;
      /**
       * 買付日入力値
       */
      purchaseDate?: string;
      /**
       * 積立日入力値
       */
      accumulatedDate?: string;
      /**
       * 買付日登録期日
       */
      purchaseDateDeadline?: string;
      /**
       * 買付キャンセル
       */
      cancelParchase?: boolean;
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/events/{associationCd}/{eventNo}',
      path: {
        associationCd: associationCd,
        eventNo: eventNo,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-2014】事務局持株会イベント情報（月例） を取得する
   * 事務局持株会イベント情報（月例） を取得する
   *
   * @param associationCd 持株会コード
   *
   * @param eventYear イベント年
   * @returns any event part response
   * @throws ApiError
   */
  public findEventMonthly(
    associationCd: string,
    eventYear: number
  ): CancelablePromise<{
    /**
     * 持株会コード
     */
    associationCd?: string;
    /**
     * 期日
     */
    associationDeadline?: string;
    /**
     * 月例買付日利用種別
     */
    monthlyPurchaseDateUsageType?: 'WEB' | 'STAR';
    /**
     * 月例買付日
     */
    monthlyPurchase?: {
      /**
       * 1月の買付日
       */
      date1?: string;
      /**
       * 2月の買付日
       */
      date2?: string;
      /**
       * 3月の買付日
       */
      date3?: string;
      /**
       * 4月の買付日
       */
      date4?: string;
      /**
       * 5月の買付日
       */
      date5?: string;
      /**
       * 6月の買付日
       */
      date6?: string;
      /**
       * 7月の買付日
       */
      date7?: string;
      /**
       * 8月の買付日
       */
      date8?: string;
      /**
       * 9月の買付日
       */
      date9?: string;
      /**
       * 10月の買付日
       */
      date10?: string;
      /**
       * 11月の買付日
       */
      date11?: string;
      /**
       * 12月の買付日
       */
      date12?: string;
    };
    /**
     * 会員受付締日
     */
    memberDeadline?: {
      /**
       * 1月の会員受付締日
       */
      date1?: string;
      /**
       * 2月の会員受付締日
       */
      date2?: string;
      /**
       * 3月の会員受付締日
       */
      date3?: string;
      /**
       * 4月の会員受付締日
       */
      date4?: string;
      /**
       * 5月の会員受付締日
       */
      date5?: string;
      /**
       * 6月の会員受付締日
       */
      date6?: string;
      /**
       * 7月の会員受付締日
       */
      date7?: string;
      /**
       * 8月の会員受付締日
       */
      date8?: string;
      /**
       * 9月の会員受付締日
       */
      date9?: string;
      /**
       * 10月の会員受付締日
       */
      date10?: string;
      /**
       * 11月の会員受付締日
       */
      date11?: string;
      /**
       * 12月の会員受付締日
       */
      date12?: string;
    };
    /**
     * 事務局受付締日
     */
    officeDeadline?: {
      /**
       * 1月の事務局受付締日
       */
      date1?: string;
      /**
       * 2月の事務局受付締日
       */
      date2?: string;
      /**
       * 3月の事務局受付締日
       */
      date3?: string;
      /**
       * 4月の事務局受付締日
       */
      date4?: string;
      /**
       * 5月の事務局受付締日
       */
      date5?: string;
      /**
       * 6月の事務局受付締日
       */
      date6?: string;
      /**
       * 7月の事務局受付締日
       */
      date7?: string;
      /**
       * 8月の事務局受付締日
       */
      date8?: string;
      /**
       * 9月の事務局受付締日
       */
      date9?: string;
      /**
       * 10月の事務局受付締日
       */
      date10?: string;
      /**
       * 11月の事務局受付締日
       */
      date11?: string;
      /**
       * 12月の事務局受付締日
       */
      date12?: string;
    };
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/events/{associationCd}/monthly',
      path: {
        associationCd: associationCd,
      },
      query: {
        eventYear: eventYear,
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-2015】事務局持株会イベント情報（月例） を登録する
   * 事務局持株会イベント情報（月例） を登録する
   *
   * @param associationCd 持株会コード
   *
   * @param eventYear イベント年
   * @param requestBody event monthly regist request
   * @returns any event monthly regist response
   * @throws ApiError
   */
  public createEventMonthly(
    associationCd: string,
    eventYear: string,
    requestBody: {
      /**
       * 月例買付日
       */
      monthlyPurchase?: {
        /**
         * 1月の買付日
         */
        date1?: string;
        /**
         * 2月の買付日
         */
        date2?: string;
        /**
         * 3月の買付日
         */
        date3?: string;
        /**
         * 4月の買付日
         */
        date4?: string;
        /**
         * 5月の買付日
         */
        date5?: string;
        /**
         * 6月の買付日
         */
        date6?: string;
        /**
         * 7月の買付日
         */
        date7?: string;
        /**
         * 8月の買付日
         */
        date8?: string;
        /**
         * 9月の買付日
         */
        date9?: string;
        /**
         * 10月の買付日
         */
        date10?: string;
        /**
         * 11月の買付日
         */
        date11?: string;
        /**
         * 12月の買付日
         */
        date12?: string;
      };
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/events/{associationCd}/monthly',
      path: {
        associationCd: associationCd,
      },
      query: {
        eventYear: eventYear,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-2016】持株会イベントを買付日・事務局締日単位に分割した一覧取得
   * 一つのイベントを、買付日・事務局締日ごとに分割して、日付単位にまとめた一覧を返す（TOP画面のカレンダーで利用想定）
   * イベントがない日については空配列を返却。ソートは日付 > 持株会CD > イベントNo
   *
   * @param associationCd 持株会コード
   *
   * @param dateFrom 抽出日From
   *
   * @param dateTo 抽出日To
   *
   * @returns any Events response
   * @throws ApiError
   */
  public listEventsDaily(
    associationCd: string,
    dateFrom: string,
    dateTo: string
  ): CancelablePromise<{
    date?: Array<{
      /**
       * 表示日付
       */
      displayDate?: string;
      events?: Array<{
        /**
         * イベントNo.
         */
        eventNo?: string;
        /**
         * 買付・締日種別
         * - PURCHASE: 買付
         * - CLOSING: 締日
         *
         */
        purchaseClosingType?: 'PURCHASE' | 'CLOSING';
        /**
         * イベント種別
         * - MONTHLY: 月例
         * - BONUS: 賞与
         * - DIVIDEND: 配当
         * - SPECIAL: 臨時
         *
         */
        eventType?: 'MONTHLY' | 'BONUS' | 'DIVIDEND' | 'SPECIAL';
        /**
         * 月例買付予定日
         */
        monthlyPurchaseScheduleDay?: number;
      }>;
    }>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/events/{associationCd}/daily',
      path: {
        associationCd: associationCd,
      },
      query: {
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
      errors: {
        400: `bad request`,
      },
    });
  }
}
