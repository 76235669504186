/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { FindTradereportsByID } from '@/types/api/tradereports';
import { Row } from './Row';
import { TableContainer } from './TableContainer';
import { Td } from './Td';
import { Th } from './Th';

interface Props {
  report: Required<FindTradereportsByID>;
}

export const Withdrawal: FC<Props> = ({ report }) => {
  const {
    withdrawalNum,
    withdrawalCalculateSharesNum,
    additionalPurchaseWithdrawalCalculate,
    additionalPurchaseWithdrawalSharesNum,
    withdrawalCalculate,
  } = report.monthlyBonus as Required<Props['report']['monthlyBonus']>;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '8px',
      }}
    >
      <Typography variant="h5" sx={{ color: 'system.text-normal' }}>
        退会
      </Typography>
      <TableContainer width="444px">
        <Row borderBottom={false}>
          <Box>
            <Th
              variant="medium"
              width="112px"
              bgcolor="secondary.light"
              borderBottom
            >
              退会精算 (H)
            </Th>
            <Th variant="medium" width="112px" bgcolor="secondary.light">
              買増退会 (I)
            </Th>
          </Box>
          <Td width="72px" height="80px" borderRight align="right">
            {`${withdrawalNum.toLocaleString()}件`}
          </Td>
          <Box>
            <Td width="130px" align="right" borderBottom borderRight>
              {`${withdrawalCalculateSharesNum.toLocaleString()}株`}
            </Td>
            <Td width="130px" align="right" borderRight>
              {`${additionalPurchaseWithdrawalSharesNum.toLocaleString()}株`}
            </Td>
          </Box>
          <Box>
            <Td width="130px" align="right" borderBottom>
              {`${withdrawalCalculate.toLocaleString()}円`}
            </Td>
            <Td width="130px" align="right">
              {`${additionalPurchaseWithdrawalCalculate.toLocaleString()}円`}
            </Td>
          </Box>
        </Row>
      </TableContainer>
    </Box>
  );
};
