import { atom } from 'recoil';

export type SnackbarType = {
  open: boolean;
  message: string;
  width?: string;
};

export const SnackbarInit: SnackbarType = {
  open: false,
  message: '',
  width: '',
};

export const snackbarAtom = atom<SnackbarType>({
  key: 'snackbar',
  default: SnackbarInit,
});
