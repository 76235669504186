/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FC, useCallback, useEffect, useState } from 'react';
import { theme } from '@/theme';
import { Box, Typography, styled, SxProps } from '@mui/material';
import { BasicTable } from '@/components/molecules/Table/BasicTable';
import { TableHeadRow } from '@/components/atoms/Table/TableHeadRow';
import { TableHeadCell } from '@/components/atoms/Table/TableHeadCell';
import { TableBodyRow } from '@/components/atoms/Table/TableBodyRow';
import { TableBodyCell } from '@/components/atoms/Table/TableBodyCell';
import { StatusBadge } from '@/components/atoms/Badge/StatusBadge';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { useNavigate, useLocation } from 'react-router-dom';
import { appClient } from '@/services';
import { useRecoilValue, useRecoilState } from 'recoil';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { format, parseISO } from 'date-fns';
import ja from 'date-fns/locale/ja';
import {
  pageState as pageValue,
  totalPageState,
  perPageSizeState,
  totalSizeState,
} from '@/recoil/pagination';
import {
  ListMemberInformations,
  ListMemberInformationsItem,
} from '@/types/api/informations';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

interface NotificationsProps {
  titleDisabled?: boolean;
}

const Wrapper = styled(Box)({});

const TitleWrapper = styled(Box)({
  padding: '32px 40px',
  backgroundColor: theme.palette.system.background,
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  borderWidth: 0,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.system.separator,
});

const Content = styled(Box)({
  padding: '32px 40px',
});

const ImportanceStatusWrapper = styled(Box)({
  ...theme.typography['caption/medium'],
  width: 24,
  height: 22,
  borderRadius: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.system.white,
});

export const Notifications: FC<NotificationsProps> = ({ titleDisabled }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { associationCd = '' } = useRecoilValue(SelectedAssociationState);

  const [informations, setInformations] =
    useState<ListMemberInformations['informations']>();
  const [page, setPage] = useRecoilState(pageValue);
  const [, setTotalPage] = useRecoilState(totalPageState);
  const [, setPerPageSize] = useRecoilState(perPageSizeState);
  const [, setTotalSize] = useRecoilState(totalSizeState);
  useHelmetHandler({
    title: '会員向けお知らせ',
  });

  const getNotificationList = useCallback(
    async (_page?: number) => {
      try {
        const res = await appClient.informations.listMemberInformations(
          associationCd,
          _page || page,
          20,
          undefined
        );
        if (res.informations) setInformations(res.informations);
        setPerPageSize(res.perPageSize || 20);
        setTotalPage(res.totalPage || 0);
        setTotalSize(res.totalSize || 0);
      } catch (e) {
        // handle getNotificationList error
      }
    },
    [page, setPage, setPerPageSize, setTotalPage, setTotalSize]
  );

  const isNotificaitonActive = (status?: 'NORMAL' | 'DROP', to?: string) => {
    if (status === 'DROP') return false;

    const today = new Date().toDateString();
    if (to && Date.parse(today) > Date.parse(to)) {
      return false;
    }

    return true;
  };

  const renderImportanceStatus = (
    type: 'LOW' | 'MIDDLE' | 'HIGH' | undefined,
    isActive: boolean
  ) => {
    switch (type) {
      case 'LOW':
        return (
          <ImportanceStatusWrapper
            sx={{ backgroundColor: isActive ? 'secondary.main' : '#A6BFC0' }}
          >
            低
          </ImportanceStatusWrapper>
        );
      case 'MIDDLE':
        return (
          <ImportanceStatusWrapper
            sx={{
              backgroundColor: isActive
                ? theme.palette.states.deadline
                : '#DCC5B4',
            }}
          >
            中
          </ImportanceStatusWrapper>
        );
      case 'HIGH':
        return (
          <ImportanceStatusWrapper
            sx={{
              backgroundColor: isActive
                ? theme.palette.states.error
                : '#DEAFAF',
            }}
          >
            高
          </ImportanceStatusWrapper>
        );
      default:
        return undefined;
    }
  };

  const getStatusBadeType = (
    status?: 'NORMAL' | 'DROP',
    from?: string,
    to?: string
  ) => {
    if (!status || !from || !to) return '';
    if (status === 'DROP') return 'DROP';

    const today = new Date();
    const d = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0
    );

    const formatDate = format(d, 'yyyy-MM-dd', {
      locale: ja,
    });

    if (Date.parse(formatDate) > Date.parse(to)) {
      return 'DISPLAYED';
    }

    if (
      Date.parse(from) <= Date.parse(formatDate) &&
      Date.parse(formatDate) <= Date.parse(to)
    ) {
      return 'DISPLAYING';
    }
    return 'BEFORE_DISPLAY';
  };

  // データタイプはTypeが作られる時に更新する必要があります。
  const renderTableRow = (data: ListMemberInformationsItem) => {
    const sx: SxProps = {
      backgroundColor: isNotificaitonActive(
        data.informationStatus,
        data.displayPeriodTo
      )
        ? theme.palette.system.white
        : theme.palette.system.background,
      cursor: 'pointer',
    };

    const emptySymbol = '-';
    let periodText = '';

    if (data.displayPeriodFrom && data.displayPeriodTo) {
      periodText = `${format(
        parseISO(data.displayPeriodFrom),
        'yyyy/MM/dd (E)',
        {
          locale: ja,
        }
      )} - ${format(parseISO(data.displayPeriodTo), 'yyyy/MM/dd (E)', {
        locale: ja,
      })}`;
    }

    const date = new Date(data.created || '');
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);

    const registrationDate = date
      ? format(date, 'yyyy/MM/dd (E) HH:mm', {
          locale: ja,
        })
      : emptySymbol;

    return (
      <TableBodyRow
        key={data.memberInformationId ?? Math.random()}
        sx={{ ...sx }}
        url={
          data.memberInformationId
            ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              `/informations/${data.memberInformationId}`
            : ''
        }
      >
        <TableBodyCell value={data.informationTitle} />
        <TableBodyCell>
          {renderImportanceStatus(
            data.priority,
            isNotificaitonActive(data.informationStatus, data.displayPeriodTo)
          )}
        </TableBodyCell>
        <TableBodyCell value={periodText} />
        <TableBodyCell value={registrationDate} />
        <TableBodyCell type="badge">
          {data.informationStatus ? (
            <StatusBadge
              sx={{
                minWidth: 62,
                padding: '2px 6px',
                backgroundColor: theme.palette.system.white,
              }}
              status={getStatusBadeType(
                data.informationStatus,
                data.displayPeriodFrom,
                data.displayPeriodTo
              )}
            />
          ) : (
            emptySymbol
          )}
        </TableBodyCell>
      </TableBodyRow>
    );
  };

  useEffect(() => {
    void getNotificationList();
  }, [getNotificationList]);

  useEffect(() => {
    setPage(1);
    void getNotificationList(1);
  }, []);

  return (
    <Wrapper>
      {!titleDisabled && (
        <TitleWrapper>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h4"
              sx={{ color: theme.palette.system['text-normal'] }}
            >
              持株会員向けお知らせ
            </Typography>
            <Typography
              sx={{
                ...theme.typography['body-main/regular'],
                color: theme.palette.system['text-light'],
              }}
            >
              {format(new Date(), 'yyyy年MM月dd日(eee) HH:mm', {
                locale: ja,
              })}
              現在
            </Typography>
          </Box>
          <Typography
            variant="body-main/regular"
            sx={{ color: theme.palette.system['text-light'] }}
          >
            個別の持株会員への連絡には用いないでください。ここで登録したお知らせは、すべての会員に送られます。
          </Typography>
        </TitleWrapper>
      )}
      <Content>
        <PrimaryButton
          onClick={() => navigate(`${location.pathname}/create`)}
          sx={{ marginBottom: 3 }}
        >
          お知らせを追加する
        </PrimaryButton>
        <BasicTable>
          <TableHeadRow>
            <TableHeadCell title="タイトル" width="50%" />
            <TableHeadCell title="重要度" />
            <TableHeadCell title="表示期間" type="sort" />
            <TableHeadCell title="登録日時" />
            <TableHeadCell title="状況" />
          </TableHeadRow>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
          {informations?.map((d) => renderTableRow(d))}
        </BasicTable>
      </Content>
    </Wrapper>
  );
};
