import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { StatusBadge } from '@/components/atoms/Badge/StatusBadge';
import { CancelButton } from '@/components/atoms/Buttons/CancelButton';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { theme } from '@/theme';
import { Box, Typography, styled, TextField } from '@mui/material';
import {
  Controller,
  useForm,
  SubmitHandler,
  Validate,
  FieldPathValue,
  FieldValues,
  FieldPath,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { SelectBox } from '@/components/atoms/Input/SelectBox';
import { prefectures } from '@/constants/SelectBox/choicesForSelectBox';
import { ReactComponent as UserSvg } from '@/assets/user.svg';
import { ReactComponent as ErrorSvg } from '@/assets/errorOops.svg';
import { SearchAddressButton } from '@/components/atoms/Buttons/SearchAddressButton';
import { useRecoilState, useRecoilValue } from 'recoil';
import { memberInfoState } from '@/recoil/memberInfo/memberInfo';
import { formatDate } from '@/utils/dateFunction';
import { appClient } from '@/services';
import { useSnackbar } from '@/hooks/useSnackbar';
import { telNumberFormatter } from '@/utils/helper';
import { useGetAddressApi } from '@/hooks/useGetAddressApi';
import { validateKatagana, IsAlphabetNumer } from '@/utils/validation';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { FindMember } from '@/types/api/members';
import { convertUserStatusToRegisteredStatus } from '@/utils/application';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const Wrapper = styled(Box)({
  padding: '32px 40px',
});

const HeaderSection = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  borderWidth: 0,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.system.separator,
  paddingBottom: 24,
});

const HeaderUserInfoWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const ContentWrapper = styled(Box)({
  padding: '24px 0',
});

const ErrorMessageWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
  marginBottom: 24,
  alignItems: 'center',
});

const ErrorMessageText = styled(Typography)({
  ...theme.typography['body-main/regular'],
  color: theme.palette.states.error,
});

const FormTitleWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
  alignItems: 'center',
  marginBottom: 8,
});

const FormTitle = styled(Box)({
  ...theme.typography.h4,
  color: theme.palette.system['text-normal'],
});

const FormRow = styled(Box)({
  padding: '16px 0',
  borderWidth: 0,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.system.separator,
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
});

const FormRowLabelWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: 144,
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
});

const FormRowLabelText = styled(Typography)({
  ...theme.typography['body-main/bold'],
  color: theme.palette.system['text-normal'],
});

const FormRowSublabelText = styled(Typography)({
  ...theme.typography['caption/regular'],
  color: theme.palette.system['text-light'],
});

const FormRowContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  flexGrow: 1,
});

const FormRowContentItemWrapper = styled(Box)({
  ...theme.typography['body-main/regular'],
  color: theme.palette.system['text-normal'],
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
});

const FormRowContentItemLabel = styled(Typography)({
  ...theme.typography['body-sub/regular'],
  color: theme.palette.system['text-normal'],
});

const FormRowContentItemSublabel = styled(Typography)({
  ...theme.typography['caption/regular'],
  color: theme.palette.system['text-light'],
});

const FormControlsRow = styled(Box)({
  padding: '24px 0',
  display: 'flex',
  flexDirection: 'row',
  gap: 16,
});

interface FormRowContentItemProps {
  label?: string;
  sublabel?: string;
  children: ReactNode;
}

export const FormRowContentItem: FC<FormRowContentItemProps> = ({
  label,
  sublabel,
  children,
}) => (
  <FormRowContentItemWrapper>
    {label && <FormRowContentItemLabel>{label}</FormRowContentItemLabel>}
    {children}
    {sublabel && (
      <FormRowContentItemSublabel>{sublabel}</FormRowContentItemSublabel>
    )}
  </FormRowContentItemWrapper>
);

type Inputs = {
  nameKanji?: string;
  nameKana?: string;
  birthday?: string;
  zipcode?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  employeeCode?: string;
  officeCd?: string;
  affiliationCd?: string;
};

export const MemberInformationEdit = () => {
  const navigate = useNavigate();
  const association = useRecoilValue(SelectedAssociationState);
  // const { validateKatagana } = Kataganavalidation();
  const NameRegex = /^.{1,18}$/;
  const validateName: Validate<
    FieldPathValue<FieldValues, FieldPath<FieldValues>>
  > = (text: string) => NameRegex.test(text);
  const { memberId } = useParams();
  const [memberInfo, setMemberInfo] = useRecoilState(memberInfoState);
  const { showSnackbar } = useSnackbar();
  const [memberData, setMemberData] = useState<FindMember>();

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      nameKanji: memberInfo?.nameKanji,
      nameKana: memberInfo?.nameKana,
      birthday: memberInfo?.birthday,
      zipcode: memberInfo?.zipcode,
      address1: memberInfo?.address1 || '都道府県選択',
      address2: memberInfo?.address2,
      address3: memberInfo?.address3,
      employeeCode: memberInfo?.employeeCode,
      officeCd: memberInfo?.officeCd,
      affiliationCd: memberInfo?.affiliationCd,
    },
  });
  const { addressData, getAddress } = useGetAddressApi();
  useHelmetHandler({
    title: '持株会会員詳細 会員情報 変更',
  });

  const getTelTypeText = (type?: 'MOBILE' | 'HOME'): string => {
    switch (type) {
      case 'MOBILE':
        return '携帯';
      case 'HOME':
        return '自宅';
      default:
        return '';
    }
  };

  const onSubmit: SubmitHandler<Inputs> = async (submittedData: Inputs) => {
    try {
      if (!memberId || !association) return;
      // const res = await appClient.members.updateMembersMember(
      //   parseInt(memberId, 10),
      //   {
      //     nameKanji: submittedData.nameKanji,
      //     nameKana: submittedData.nameKana,
      //     zipcode: submittedData.zipcode,
      //     address1: submittedData.address1,
      //     address2: submittedData.address2,
      //     address3: submittedData.address3,
      //     employeeCode: submittedData.employeeCode,
      //     officeCd: submittedData.officeCd,
      //     affiliationCd: submittedData.affiliationCd,
      //   }
      // );
      const res = await appClient.applications.createApplication({
        memberId: Number(memberId),
        associationCd: association.associationCd,
        applicationType: 'USER',
        applicationUser: {
          nameKanji: submittedData.nameKanji,
          nameKana: submittedData.nameKana,
          zipcode: submittedData.zipcode,
          address1: submittedData.address1,
          address2: submittedData.address2,
          address3: submittedData.address3,
          employeeCode: submittedData.employeeCode,
          officeCd: submittedData.officeCd,
          affiliationCd: submittedData.affiliationCd,
          birthday: memberData?.birthday,
          tel: memberData?.tel,
          telType: memberData?.telType,
        },
      });
      navigate(`/members/${memberId}/information`);
      showSnackbar('会員情報を変更しました。', '224px');
    } catch (e) {
      // handle updateMemberInfo error
      const {
        data: { message },
      } = e as { data: { message: string } };
      showSnackbar(message);
    }
  };

  const getMemberInfo = useCallback(async () => {
    try {
      if (!memberId) return;
      const res = await appClient.members.findMember(parseInt(memberId, 10));
      setValue('nameKanji', res.nameKanji);
      setValue('nameKana', res.nameKana);
      setValue('birthday', res.birthday);
      setValue('zipcode', res.zipcode);
      setValue('address1', res.address1);
      setValue('address2', res.address2);
      setValue('address3', res.address3);
      setValue('employeeCode', res.employeeCode);
      setValue('officeCd', res.officeCd);
      setValue('affiliationCd', res.affiliationCd);
      setMemberData(res);
      setMemberInfo(res);
    } catch (e) {
      // Handle getMemberInfo error
    }
  }, [memberId]);

  useEffect(() => {
    void getMemberInfo();

    return () => {
      setMemberInfo(undefined);
    };
  }, [getMemberInfo]);

  useEffect(() => {
    if (!addressData || addressData.length === 0) return;
    setValue('address1', addressData[0].pref);
    setValue('address2', `${addressData[0].city}${addressData[0].town}`);
  }, [addressData]);

  return (
    <Wrapper component="form" onSubmit={handleSubmit(onSubmit)}>
      <HeaderSection>
        <NormalLink onClick={() => navigate(-1)}>会員詳細へ戻る</NormalLink>
        <HeaderUserInfoWrapper>
          <StatusBadge
            sx={{ width: '48px', height: '22px' }}
            status={convertUserStatusToRegisteredStatus(
              memberData?.userStatus,
              memberData?.expiryDay
            )}
          />
          <Typography variant="h4" sx={{ color: 'system.text-normal' }}>
            {memberInfo?.nameKanji || memberData?.nameKanji}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <Typography
              variant="body-sub/regular"
              sx={{ color: 'system.text-light' }}
            >
              会員コード
            </Typography>
            <Typography
              variant="body-sub/medium"
              sx={{ color: 'system.text-light' }}
            >
              {memberInfo?.memberCd}
            </Typography>
          </Box>
        </HeaderUserInfoWrapper>
      </HeaderSection>
      <ContentWrapper>
        <ErrorMessageWrapper>
          <ErrorSvg width={24} height={24} />
          <ErrorMessageText>
            代理申請を行うと申請者・申請時間がシステムに記録されます。
          </ErrorMessageText>
        </ErrorMessageWrapper>
        <FormTitleWrapper>
          <UserSvg width={32} height={32} />
          <FormTitle>会員情報変更</FormTitle>
        </FormTitleWrapper>
        <FormRow>
          <FormRowLabelWrapper>
            <FormRowLabelText>氏名（漢字）</FormRowLabelText>
          </FormRowLabelWrapper>
          <FormRowContentWrapper>
            <FormRowContentItem>
              <Controller
                name="nameKanji"
                control={control}
                rules={{
                  validate: validateName,
                }}
                render={({ field }) => (
                  <TextField
                    error={errors.nameKanji !== undefined}
                    {...field}
                    placeholder="田中　太郎"
                    sx={{ width: 240 }}
                    inputProps={{ style: { paddingLeft: '8px' } }}
                  />
                )}
              />
            </FormRowContentItem>
          </FormRowContentWrapper>
        </FormRow>
        <FormRow>
          <FormRowLabelWrapper>
            <FormRowLabelText>氏名（カナ）</FormRowLabelText>
          </FormRowLabelWrapper>
          <FormRowContentWrapper>
            <FormRowContentItem sublabel="※カタカナ">
              <Controller
                name="nameKana"
                control={control}
                rules={{
                  validate: validateKatagana,
                }}
                render={({ field }) => (
                  <TextField
                    error={errors.nameKana !== undefined}
                    {...field}
                    placeholder="タナカ タロウ"
                    sx={{ width: 240 }}
                    inputProps={{ style: { paddingLeft: '8px' } }}
                  />
                )}
              />
            </FormRowContentItem>
          </FormRowContentWrapper>
        </FormRow>
        <FormRow>
          <FormRowLabelWrapper>
            <FormRowLabelText>生年月日</FormRowLabelText>
          </FormRowLabelWrapper>
          <FormRowContentWrapper>
            <FormRowContentItem>
              {getValues().birthday
                ? formatDate(getValues().birthday as string)
                : ''}
            </FormRowContentItem>
          </FormRowContentWrapper>
        </FormRow>
        <FormRow>
          <FormRowLabelWrapper>
            <FormRowLabelText>住所</FormRowLabelText>
          </FormRowLabelWrapper>
          <FormRowContentWrapper>
            <FormRowContentItem label="郵便番号" sublabel="※半角7桁">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <Controller
                  name="zipcode"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="0000000"
                      sx={{ width: 160 }}
                      inputProps={{ style: { paddingLeft: '8px' } }}
                    />
                  )}
                />
                <SearchAddressButton
                  onClick={() => {
                    const zip = getValues().zipcode;
                    if (zip) getAddress(zip);
                  }}
                >
                  住所を自動入力
                </SearchAddressButton>
              </Box>
            </FormRowContentItem>
            <FormRowContentItem label="都道府県">
              <Controller
                name="address1"
                control={control}
                render={({ field }) => (
                  <SelectBox
                    items={[
                      {
                        key: '都道府県選択',
                        value: '都道府県選択',
                        disabled: true,
                      },
                      ...prefectures,
                    ]}
                    {...field}
                    width="160px"
                  />
                )}
              />
            </FormRowContentItem>
            <FormRowContentItem label="市区町村">
              <Controller
                name="address2"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="渋谷区"
                    sx={{ width: 240 }}
                    inputProps={{ style: { paddingLeft: '8px' } }}
                  />
                )}
              />
            </FormRowContentItem>
            <FormRowContentItem label="番地・マンション名など">
              <Controller
                name="address3"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="渋谷1-1-1"
                    sx={{ maxWidth: 1000 }}
                    inputProps={{ style: { paddingLeft: '8px' } }}
                  />
                )}
              />
            </FormRowContentItem>
          </FormRowContentWrapper>
        </FormRow>
        <FormRow>
          <FormRowLabelWrapper>
            <FormRowLabelText>電話番号</FormRowLabelText>
          </FormRowLabelWrapper>
          <FormRowContentWrapper>
            <FormRowContentItem label={getTelTypeText(memberInfo?.telType)}>
              {memberInfo?.tel &&
                telNumberFormatter(memberInfo?.tel, '###-####-####')}
            </FormRowContentItem>
          </FormRowContentWrapper>
        </FormRow>
        <FormRow>
          <FormRowLabelWrapper>
            <FormRowLabelText>社員コード</FormRowLabelText>
          </FormRowLabelWrapper>
          <FormRowContentWrapper>
            <FormRowContentItem>
              <Controller
                name="employeeCode"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="1234567890"
                    sx={{ width: 200 }}
                    inputProps={{ style: { paddingLeft: '8px' } }}
                    onChange={(e) => {
                      if (
                        (e.target.value.length <= 10 &&
                          IsAlphabetNumer(e.target.value)) ||
                        e.target.value === ''
                      ) {
                        setValue(
                          'employeeCode',
                          e.target.value.replaceAll(' ', '')
                        );
                      }
                    }}
                  />
                )}
              />
            </FormRowContentItem>
          </FormRowContentWrapper>
        </FormRow>
        <FormRow>
          <FormRowLabelWrapper>
            <FormRowLabelText>会員事業所コード</FormRowLabelText>
            <FormRowSublabelText>※任意</FormRowSublabelText>
          </FormRowLabelWrapper>
          <FormRowContentWrapper>
            <FormRowContentItem>
              <Controller
                name="officeCd"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="1234567890"
                    sx={{ width: 200 }}
                    inputProps={{ style: { paddingLeft: '8px' } }}
                    onChange={(e) => {
                      if (
                        (e.target.value.length <= 10 &&
                          IsAlphabetNumer(e.target.value)) ||
                        e.target.value === ''
                      ) {
                        setValue(
                          'officeCd',
                          e.target.value.replaceAll(' ', '')
                        );
                      }
                    }}
                  />
                )}
              />
            </FormRowContentItem>
          </FormRowContentWrapper>
        </FormRow>
        <FormRow>
          <FormRowLabelWrapper>
            <FormRowLabelText>会員所属コード</FormRowLabelText>
            <FormRowSublabelText>※任意</FormRowSublabelText>
          </FormRowLabelWrapper>
          <FormRowContentWrapper>
            <FormRowContentItem>
              <Controller
                name="affiliationCd"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="1234567890"
                    sx={{ width: 200 }}
                    inputProps={{ style: { paddingLeft: '8px' } }}
                    onChange={(e) => {
                      if (
                        (e.target.value.length <= 10 &&
                          IsAlphabetNumer(e.target.value)) ||
                        e.target.value === ''
                      ) {
                        setValue(
                          'affiliationCd',
                          e.target.value.replaceAll(' ', '')
                        );
                      }
                    }}
                  />
                )}
              />
            </FormRowContentItem>
          </FormRowContentWrapper>
        </FormRow>

        <FormControlsRow>
          <CancelButton onClick={() => navigate(-1)}>キャンセル</CancelButton>
          <PrimaryButton type="submit">登録する</PrimaryButton>
        </FormControlsRow>
      </ContentWrapper>
    </Wrapper>
  );
};
