import {
  PrimaryTableBodyTdMulti,
  PrimaryTableBodyTdSub,
} from '@/components/atoms/Table/PrimaryTableBodyTdMulti';
import { PrimaryTableBodyTdSingle } from '@/components/atoms/Table/PrimaryTableBodyTdSingle';
import { PrimaryTable } from '@/components/molecules/Table/PrimaryTable';
import { CreateApplication } from '@/types/api/applications';
import { FindMemberContribution } from '@/types/api/members';
import { numberFormat } from '@/utils/numberFormat';
import { IsNumeric } from '@/utils/stringProcessor';
// import { BountyCalculation } from '@/utils/calculation';
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  styled,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { GetAssociationsContribution } from '@/types/api/associations';
import { FC, useEffect, ChangeEvent } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

type Props = {
  isConfirmed: boolean;
  contribution: GetAssociationsContribution & FindMemberContribution;
  methods: UseFormReturn<CreateApplication>;
  setIsUnit: (isError: boolean) => void;
};

const MuiRadioGroup = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'row',
});

export const MemberProxyApplicationBodyBonus: FC<Props> = ({
  isConfirmed,
  contribution,
  methods,
  setIsUnit,
}) => {
  const watchBonusRate = methods.watch('applicationBonusUnit.bonusRate') ?? 0;
  const watchIsBonus = methods.watch('applicationBonusUnit.isBonusUnit') ?? 0;

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let amount = '';
    if (IsNumeric(e.target.value)) {
      amount = Math.floor(
        Math.min(Number(e.target.value), contribution.maxBonusUnit || 0)
      ).toString();
    }
    methods.setValue('applicationBonusUnit.bonusUnit', Number(amount) ?? 0);

    if (Number(amount) === 0 || Number(amount) === contribution.bonusUnit) {
      setIsUnit(false);
    } else {
      setIsUnit(true);
    }
  };

  useEffect(() => {
    setIsUnit(true);
  }, []);

  return (
    <PrimaryTable title="新たな口数設定">
      <PrimaryTableBodyTdMulti title="1口あたり金額" row={1}>
        {isConfirmed ? (
          <Typography>
            {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call */}
            {`${(contribution.unitAmount ?? 0).toLocaleString()}円`}
          </Typography>
        ) : (
          <Typography variant="body-main/bold">
            {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call */}
            {`${(contribution.unitAmount ?? 0).toLocaleString()}円`}
          </Typography>
        )}
      </PrimaryTableBodyTdMulti>
      <PrimaryTableBodyTdMulti title="奨励金率" row={1}>
        {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call */}
        <Typography>{`${(
          contribution.bonusIncentiveRatio ?? 0
        ).toLocaleString()}%`}</Typography>
      </PrimaryTableBodyTdMulti>
      <PrimaryTableBodyTdMulti title="賞与" row={4}>
        {isConfirmed ? (
          <PrimaryTableBodyTdSub title="拠出">
            <Typography>{watchIsBonus ? 'する' : 'しない'}</Typography>
          </PrimaryTableBodyTdSub>
        ) : (
          <PrimaryTableBodyTdSub title="拠出">
            <FormControl>
              <MuiRadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="yes"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  checked={watchIsBonus === true}
                  control={<Radio />}
                  label="する"
                  onClick={() =>
                    methods.setValue('applicationBonusUnit.isBonusUnit', true)
                  }
                />
                <FormControlLabel
                  checked={watchIsBonus === false}
                  control={<Radio />}
                  label="しない"
                  onClick={() =>
                    methods.setValue('applicationBonusUnit.isBonusUnit', false)
                  }
                />
              </MuiRadioGroup>
            </FormControl>
          </PrimaryTableBodyTdSub>
        )}
      </PrimaryTableBodyTdMulti>
      {watchIsBonus && (
        <>
          <PrimaryTableBodyTdSingle>
            <PrimaryTableBodyTdSub title="口数">
              {contribution.bonusMultiplierDesignationType ===
                'UNIT_NUMBER' && (
                <Box>
                  {isConfirmed ? (
                    <Box>
                      <Typography variant="body-sub/regular">
                        {`${String(
                          methods.getValues('applicationBonusUnit.bonusUnit')
                        )}口`}
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Controller
                        name="applicationBonusUnit.bonusUnit"
                        control={methods.control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            sx={{ width: '80px' }}
                            onChange={(e) => handleChange(e)}
                            inputProps={{
                              sx: {
                                padding: '8px',
                                textAlign: 'right',
                              },
                            }}
                          />
                        )}
                      />

                      <Typography
                        sx={{ marginLeft: '8px', marginRight: '30px' }}
                        variant="body-sub/regular"
                      >
                        口
                      </Typography>
                    </Box>
                  )}
                  <Typography
                    variant="body-sub/regular"
                    sx={{ color: 'system.text-light' }}
                  >
                    ※最大{numberFormat(contribution.maxBonusUnit || 0)}口
                  </Typography>
                </Box>
              )}
              {contribution.bonusMultiplierDesignationType ===
                'MAGNIFICATION' && (
                <Box>
                  {isConfirmed ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {`${String(
                        methods.getValues('applicationBonusUnit.bonusRate')
                      )}倍`}
                    </Box>
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <MuiRadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="yes"
                        name="radio-buttons-group"
                      >
                        {(contribution.bonusMultiplierDetails || '')
                          .split(',')
                          .map((i) => (
                            <FormControlLabel
                              value={i}
                              control={<Radio />}
                              checked={Number(i) === watchBonusRate}
                              label={`${i}倍`}
                              onClick={() => {
                                methods.setValue(
                                  'applicationBonusUnit.bonusRate',
                                  Number(i)
                                );
                              }}
                            />
                          ))}
                      </MuiRadioGroup>
                    </Box>
                  )}
                </Box>
              )}
              {contribution.bonusMultiplierDesignationType ===
                'FIXED_MAGNIFICATION' && (
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {(Number(contribution.bonusMultiplierDetails) || 0) *
                      (contribution.monthlyUnit || 0)}
                    <Typography
                      sx={{ marginLeft: '8px', marginRight: '30px' }}
                      variant="body-sub/regular"
                    >
                      口
                    </Typography>
                  </Box>
                  <Typography
                    variant="body-sub/regular"
                    sx={{ color: 'system.text-light' }}
                  >
                    ※給与（毎月の拠出）の
                    {(contribution?.bonusMultiplierDetails || '').split(',')[0]}
                    倍となります
                  </Typography>
                </Box>
              )}
            </PrimaryTableBodyTdSub>
          </PrimaryTableBodyTdSingle>
          <PrimaryTableBodyTdSingle>
            {contribution.bonusMultiplierDesignationType === 'UNIT_NUMBER' && (
              <PrimaryTableBodyTdSub title="拠出金額">
                {isConfirmed ? (
                  <Typography>
                    {`${(
                      (contribution.unitAmount ?? 0) *
                      (!Number.isNaN(
                        Number(methods.watch('applicationBonusUnit.bonusUnit'))
                      )
                        ? Number(
                            methods.watch('applicationBonusUnit.bonusUnit')
                          )
                        : 0)
                    ).toLocaleString()} 円`}
                  </Typography>
                ) : (
                  <Typography
                    fontWeight={500}
                    fontStyle="normal"
                    fontSize={22}
                    lineHeight="150%"
                  >
                    {`${(
                      (contribution.unitAmount ?? 0) *
                      (!Number.isNaN(
                        Number(methods.watch('applicationBonusUnit.bonusUnit'))
                      )
                        ? Number(
                            methods.watch('applicationBonusUnit.bonusUnit')
                          )
                        : 0)
                    ).toLocaleString()} 円`}
                  </Typography>
                )}
              </PrimaryTableBodyTdSub>
            )}
            {contribution.bonusMultiplierDesignationType ===
              'MAGNIFICATION' && (
              <PrimaryTableBodyTdSub title="拠出金額">
                {isConfirmed ? (
                  <Typography>
                    {`${(
                      (contribution.unitAmount ?? 0) *
                      (Number(
                        methods.watch('applicationBonusUnit.bonusRate')
                      ) || 0) *
                      (contribution.monthlyUnit || 0)
                    ).toLocaleString()} 円`}
                  </Typography>
                ) : (
                  <Typography
                    fontWeight={500}
                    fontStyle="normal"
                    fontSize={22}
                    lineHeight="150%"
                  >
                    {`${(
                      (contribution.unitAmount ?? 0) *
                      (Number(
                        methods.watch('applicationBonusUnit.bonusRate')
                      ) || 0) *
                      (contribution.monthlyUnit || 0)
                    ).toLocaleString()} 円`}
                  </Typography>
                )}
              </PrimaryTableBodyTdSub>
            )}
            {contribution.bonusMultiplierDesignationType ===
              'FIXED_MAGNIFICATION' && (
              <PrimaryTableBodyTdSub title="拠出金額">
                {isConfirmed ? (
                  <Typography>
                    {`${(
                      (contribution.unitAmount ?? 0) *
                      (Number(contribution.bonusMultiplierDetails) || 0) *
                      (contribution.monthlyUnit || 0)
                    ).toLocaleString()} 円`}
                  </Typography>
                ) : (
                  <Typography
                    fontWeight={500}
                    fontStyle="normal"
                    fontSize={22}
                    lineHeight="150%"
                  >
                    {`${(
                      (contribution.unitAmount ?? 0) *
                      (Number(contribution.bonusMultiplierDetails) || 0) *
                      (contribution.monthlyUnit || 0)
                    ).toLocaleString()} 円`}
                  </Typography>
                )}
              </PrimaryTableBodyTdSub>
            )}
          </PrimaryTableBodyTdSingle>
          <PrimaryTableBodyTdSingle>
            {contribution.bonusMultiplierDesignationType === 'UNIT_NUMBER' && (
              <PrimaryTableBodyTdSub title="奨励金額">
                {isConfirmed ? (
                  <Typography>
                    {`${(
                      ((contribution.unitAmount ?? 0) *
                        (!Number.isNaN(
                          Number(
                            methods.watch('applicationBonusUnit.bonusUnit')
                          )
                        )
                          ? Number(
                              methods.watch('applicationBonusUnit.bonusUnit')
                            )
                          : 0) *
                        (contribution.bonusIncentiveRatio || 0)) /
                      100
                    ).toLocaleString()} 円`}
                  </Typography>
                ) : (
                  <Typography
                    fontWeight={500}
                    fontStyle="normal"
                    fontSize={22}
                    lineHeight="150%"
                  >
                    {`${(
                      ((contribution.unitAmount ?? 0) *
                        (!Number.isNaN(
                          Number(
                            methods.watch('applicationBonusUnit.bonusUnit')
                          )
                        )
                          ? Number(
                              methods.watch('applicationBonusUnit.bonusUnit')
                            )
                          : 0) *
                        (contribution.bonusIncentiveRatio || 0)) /
                      100
                    ).toLocaleString()} 円`}
                  </Typography>
                )}
              </PrimaryTableBodyTdSub>
            )}
            {contribution.bonusMultiplierDesignationType ===
              'MAGNIFICATION' && (
              <PrimaryTableBodyTdSub title="奨励金額">
                {isConfirmed ? (
                  <Typography>
                    {`${(
                      ((contribution.unitAmount ?? 0) *
                        (Number(
                          methods.watch('applicationBonusUnit.bonusRate')
                        ) || 0) *
                        (contribution.monthlyUnit || 0) *
                        (contribution.bonusIncentiveRatio || 0)) /
                      100
                    ).toLocaleString()} 円`}
                  </Typography>
                ) : (
                  <Typography
                    fontWeight={500}
                    fontStyle="normal"
                    fontSize={22}
                    lineHeight="150%"
                  >
                    {`${
                      ((contribution.unitAmount ?? 0) *
                        (Number(
                          methods.watch('applicationBonusUnit.bonusRate')
                        ) || 0) *
                        (contribution.monthlyUnit || 0) *
                        (contribution.bonusIncentiveRatio || 0)) /
                      100
                    } 円`}
                  </Typography>
                )}
              </PrimaryTableBodyTdSub>
            )}
            {contribution.bonusMultiplierDesignationType ===
              'FIXED_MAGNIFICATION' && (
              <PrimaryTableBodyTdSub title="奨励金額">
                {isConfirmed ? (
                  <Typography>
                    {`${(
                      ((contribution.unitAmount ?? 0) *
                        (Number(contribution.bonusMultiplierDetails) || 0) *
                        (contribution.monthlyUnit || 0) *
                        (contribution.bonusIncentiveRatio ?? 0)) /
                      100
                    ).toLocaleString()} 円`}
                  </Typography>
                ) : (
                  <Typography
                    fontWeight={500}
                    fontStyle="normal"
                    fontSize={22}
                    lineHeight="150%"
                  >
                    {`${
                      ((contribution.unitAmount ?? 0) *
                        (Number(contribution.bonusMultiplierDetails) || 0) *
                        (contribution.monthlyUnit || 0) *
                        (contribution.bonusIncentiveRatio ?? 0)) /
                      100
                    } 円`}
                  </Typography>
                )}
              </PrimaryTableBodyTdSub>
            )}
          </PrimaryTableBodyTdSingle>
        </>
      )}
    </PrimaryTable>
  );
};
