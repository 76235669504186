/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { theme } from '@/theme';
import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { FindApplicationByID } from '@/types/api/Applications/application';
import { PrevnextApplications } from '@/types/api/Applications/prevnextApplications';
import { formatDate } from '@/utils/dateFunction';
import { format } from 'date-fns';
import { GetAssociationsContribution } from '@/types/api/associations';
import { printApplicationsComponent } from '@/utils/printApplications';
import { TitleHead } from './SharedParts/TitleHead';
import { ApplicationBottom } from './SharedParts/ApplicationBottom';
import { ApplicationTable as Table } from './SharedParts/ApplicationTable';
import { ApplicationTableRow as Row } from './SharedParts/ApplicationTableRow';
import { ApplicationButtons } from './SharedParts/ApplicationButtons';
import { PrintApplicationsButton } from './PrintApplicationsButton/PrintApplicationsButton';

interface AdmissionProps {
  application: FindApplicationByID;
  bottomData: PrevnextApplications | undefined;
  associationContribution: GetAssociationsContribution;
  applicationId?: string;
  handleApplication: (value: 'APPROVAL' | 'REJECTED', comment?: string) => void;
  setIsOpen: (alue: boolean) => void;
  isOpen: boolean;
}

export const ApplicationsAdmission: FC<AdmissionProps> = ({
  application,
  bottomData,
  associationContribution,
  handleApplication,
  setIsOpen,
  isOpen,
}) => {
  const renderMultiplyDescription = () => {
    const isCalculate =
      application.applicationNewUser?.bonusRate !== 0 &&
      application.applicationNewUser?.bonusUnit !== 0;
    return (
      <Row labels={isCalculate ? '倍率' : '賞与'}>
        {isCalculate ? (
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{ marginLeft: '8px', marginRight: '30px' }}
                variant="body-sub/bold"
              >
                {application.applicationNewUser?.bonusRate || 0}倍
              </Typography>
            </Box>
            <Typography
              variant="body-sub/regular"
              sx={{ color: 'system.text-light' }}
            >
              ※給与（毎月の拠出）対比
            </Typography>
          </Box>
        ) : (
          <Box>拠出しない</Box>
        )}
      </Row>
    );
  };

  const renderButtons = () => {
    if (application.applicationStatus === 'COMPLETED') {
      return null;
    }
    if (application.eventNo) {
      return (
        <Stack direction="row" sx={{ alignItems: 'center' }} spacing={2}>
          <ApplicationButtons
            status={application.applicationStatus || ''}
            handleApplication={handleApplication}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isAdmission={application.applicationType === 'ADMISSION'}
            eventNo={application.eventNo}
          />
          {(application.applicationStatus === 'PREPARATION' ||
            application.applicationStatus === 'APPLYING') && (
            <NormalLink
              href={
                application.applicationId
                  ? `/applications/admission/${application.applicationId}/edit`
                  : ''
              }
            >
              申請内容を修正する
            </NormalLink>
          )}
        </Stack>
      );
    }
    return null;
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.system['background-dark'],
        height: '100%',
      }}
    >
      <Stack
        spacing={4}
        sx={{
          backgroundColor: theme.palette.system.white,
          padding: '40px 160px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <PrintApplicationsButton
            onClick={() =>
              printApplicationsComponent(
                'admission_print_content',
                '新規会員登録'
              )
            }
          />
        </Box>
        <Stack id="admission_print_content" spacing={4}>
          <TitleHead
            title="新規会員登録"
            status={application.applicationStatus || ''}
            applicationDate={application.applicationDate || ''}
            applicationId={application.applicationId || ''}
            associationCd={application.associationCd || ''}
            proxyApplicantUserName={application.proxyApplicantUserName ?? ''}
            unitType="admission"
            comment={application.rejectComment}
          />
          <Table title="会員情報" isRed>
            <Row
              labels="会員コード"
              values={`
              ${application.applicationNewUser?.memberCd || ''}`}
            />
            <Row
              labels="氏名（漢字)"
              values={`
              ${application.applicationNewUser?.nameKanji || ''}`}
              isEven
            />
            <Row
              labels="氏名（カナ)"
              values={`
              ${application.applicationNewUser?.nameKana || ''}`}
            />
            <Row
              labels="生年月日"
              values={
                application.applicationNewUser?.birthday
                  ? formatDate(
                      format(
                        new Date(application.applicationNewUser.birthday),
                        'yyyy-MM-dd'
                      )
                    )
                  : ''
              }
              isEven
            />
            <Row
              labels="住所"
              values={[
                `〒${application?.applicationNewUser?.zipcode || ''}`,
                (application?.applicationNewUser?.address1 || '') +
                  (application?.applicationNewUser?.address2 || '') +
                  (application.applicationNewUser?.address3 || ''),
              ]}
            />
            <Row
              labels="電話番号"
              values={[
                application.applicationNewUser?.telType === 'MOBILE'
                  ? '携帯'
                  : '自宅',
                application?.applicationNewUser?.tel || '',
              ]}
              isEven
            />

            <Row
              labels="社員コード"
              values={application.applicationNewUser?.employeeCode}
            />
            <Row
              labels="会員事務所コード"
              values={application.applicationNewUser?.officeCd}
              isEven
            />
            <Row
              labels="会員所属コード"
              values={application.applicationNewUser?.affiliationCd}
            />
          </Table>
          <Table title="Web利用規約同意確認" isRed>
            <Row values="Web利用規約に同意する" />
          </Table>
          <Table title="新しい口数設定" isRed>
            <Row
              labels="一口当たり金額"
              values={`${(
                associationContribution.unitAmount || 0
              ).toLocaleString()}円`}
            />
            <Row
              labels="奨励金率"
              values={`${associationContribution.monthlyIncentiveRatio || 0}%`}
              isEven
            />
            <Row
              labels="給与(月例)"
              values={[
                `口数 ${
                  application.applicationNewUser?.monthlyUnit?.toLocaleString() ||
                  0
                }口`,
                `拠出金額 ${(
                  (associationContribution.unitAmount || 0) *
                  (application.applicationNewUser?.monthlyUnit || 0)
                ).toLocaleString()}円`,
                `奨励金額 ${(
                  ((associationContribution.unitAmount || 0) *
                    (application.applicationNewUser?.monthlyUnit || 0) *
                    (associationContribution.monthlyIncentiveRatio || 0)) /
                  100
                ).toLocaleString()}円`,
              ]}
            />
            {associationContribution?.bonusMultiplierDesignationType !==
              'UNIT_NUMBER' && renderMultiplyDescription()}
            {associationContribution?.bonusMultiplierDesignationType ===
              'UNIT_NUMBER' && (
              <Row
                labels="賞与"
                values={[
                  `口数 ${
                    application.applicationNewUser?.bonusUnit?.toLocaleString() ||
                    0
                  }口`,
                  `拠出金額 ${(
                    (associationContribution.unitAmount || 0) *
                    (Number(application?.applicationNewUser?.bonusUnit) || 0)
                  ).toLocaleString()}円`,
                  `奨励金額 ${(
                    ((associationContribution.unitAmount || 0) *
                      (Number(application?.applicationNewUser?.bonusUnit) ||
                        0) *
                      (associationContribution.monthlyIncentiveRatio || 0)) /
                    100
                  ).toLocaleString()}円`,
                ]}
                isEven
              />
            )}
          </Table>
          {renderButtons()}
        </Stack>
      </Stack>
      {bottomData && (
        <Box sx={{ padding: '32px 160px' }}>
          <ApplicationBottom data={bottomData} unitType="admission" />
        </Box>
      )}
    </Box>
  );
};
