/* eslint-disable  @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access  */
import {
  PrevnextApplications,
  PrevnextApplicationParams,
} from '@/types/api/Applications/prevnextApplications';
import { useState } from 'react';
import useSWR from 'swr';
import { appClient, Error } from '..';

export const usePrevnextApplication = (
  applicationId: number,
  params: PrevnextApplicationParams
) => {
  const [query, setQuery] = useState<PrevnextApplicationParams>(params);
  // 【API-C-4106】申請の前後の申請書情報を取得
  const memberId = query.memberId ? String(query.memberId) : undefined;
  const { data, error, mutate } = useSWR<PrevnextApplications, Error>(
    ['/api/applications/{applicationId}/prevnext', applicationId, query],
    // eslint-disable-next-line @typescript-eslint/no-shadow
    () =>
      appClient.applications.findPrevNextApplicationById(
        applicationId,
        query.associationCd || undefined,
        memberId,
        query.keyword || undefined,
        query.yearMonth || undefined,
        query.eventType || undefined,
        query.applicationType || undefined,
        query.applicationStatus || undefined,
        query.sortKey || undefined
      )
  );
  const setQueryParams = (prevnextParams: PrevnextApplicationParams) => {
    setQuery(prevnextParams);
  };
  return {
    data,
    error,
    mutate,
    query,
    setQueryParams,
  };
};
