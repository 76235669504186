import { Typography } from '@mui/material';
import { FC } from 'react';

export const SubmitStatus: FC<{ submit: boolean }> = ({ submit }) => (
  <Typography
    variant="body-main/medium"
    sx={{
      color: submit ? 'states.success' : 'states.error',
      border: '1px solid',
      borderColor: submit ? 'states.success' : 'states.error',
      borderRadius: '4px',
      height: '27px',
      p: '1px 8px 3px',
    }}
  >
    {submit ? '提出済' : '未提出'}
  </Typography>
);
