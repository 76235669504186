import { FC } from 'react';
import { styled, TableContainer, Table, SxProps } from '@mui/material';
import { Paginate } from './Pagination';

interface TableProps {
  children: React.ReactNode;
  containerSx?: SxProps;
  disablePagination?: boolean;
  paginateSx?: SxProps;
}

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

const MuiTableContainer = styled(TableContainer)({
  borderRadius: '4px',
  border: '1px solid rgba(0, 0, 0, 0.1)',
});

const MuiTable = styled(Table)(() => ({
  width: '100%',
}));

export const BasicTable: FC<TableProps> = ({
  children,
  disablePagination,
  paginateSx,
  containerSx,
}) => (
  <Wrapper>
    <MuiTableContainer sx={{ ...containerSx }}>
      <MuiTable aria-label="customized table">{children}</MuiTable>
    </MuiTableContainer>
    {!disablePagination && <Paginate sx={paginateSx} />}
  </Wrapper>
);
