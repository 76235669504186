import { CreateApplication } from '@/types/api/applications';
import {
  GetAssociation,
  GetAssociationsContribution,
} from '@/types/api/associations';
// @ts-ignore
import { ListEvents } from '@/types/api/events';
import {
  FindMember,
  FindMemberAsset,
  FindMemberContribution,
} from '@/types/api/members';
import { MemberSecuritiesAccount } from '@/types/api/memberSecuritiesAccount';
import { styled } from '@mui/material';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { MemberProxyApplicationBodyBottom } from './MemberProxyApplicationBodyBottom';
import { MemberProxyApplicationBodyMiddle } from './MemberProxyApplicationBodyMiddle';
import { MemberProxyApplicationBodyTop } from './MemberProxyApplicationBodyTop';

type Props = {
  isConfirmed: boolean;
  user: FindMember;
  association: GetAssociation;
  contribution: GetAssociationsContribution & FindMemberContribution;
  stocksNumber: FindMemberAsset['stocksNumber'];
  memberSecurityAccount?: MemberSecuritiesAccount;
  events: ListEvents;
  methods: UseFormReturn<CreateApplication>;
  selectedEvent: Exclude<ListEvents, undefined>[number];
  setSelectedEvent: (
    selectedEvent: Exclude<ListEvents, undefined>[number]
  ) => void;
  setIsUnit: (isUnit: boolean) => void;
};

const Wrapper = styled('div')(({ theme }) => {
  const { palette } = theme;
  return {
    width: '100%',
    padding: '24px',
    borderRadius: '12px',
    backgroundColor: palette.system.white,
    display: 'flex',
    gap: '32px',
    flexDirection: 'column',
  };
});

export const MemberProxyApplicationBody: FC<Props> = ({
  isConfirmed,
  user,
  association,
  contribution,
  stocksNumber,
  memberSecurityAccount,
  events,
  methods,
  selectedEvent,
  setSelectedEvent,
  setIsUnit,
}) => (
  <Wrapper>
    <MemberProxyApplicationBodyTop
      user={user}
      association={association}
      isConfirmed={isConfirmed}
    />
    <MemberProxyApplicationBodyMiddle
      isConfirmed={isConfirmed}
      contribution={contribution}
      stocksNumber={stocksNumber}
      memberSecurityAccount={memberSecurityAccount}
      investmentDigit={association.investmentDigit}
    />
    <MemberProxyApplicationBodyBottom
      isConfirmed={isConfirmed}
      contribution={contribution}
      events={events}
      methods={methods}
      selectedEvent={selectedEvent}
      setSelectedEvent={setSelectedEvent}
      stocksNumber={stocksNumber}
      setIsUnit={setIsUnit}
      association={association}
    />
  </Wrapper>
);
