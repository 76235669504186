import { FC } from 'react';
import { theme } from '@/theme/theme';
import { MemberAssets } from '@/types/api/memberAssets';
import { Box, styled } from '@mui/material';
import { CurrentInvestmentStatus } from './CurrentInvestmentStatus';

const Wrapper = styled(Box)(() => {
  const { breakpoints, palette } = theme;
  return {
    width: 1152,
    margin: '0 auto',
    backgroundColor: palette.system.white,

    [breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  };
});

interface Props {
  memberAssets: MemberAssets;
  date: Date;
  investmentDigit?: number;
}

export const BalanceStatement: FC<Props> = ({
  memberAssets,
  date,
  investmentDigit,
}) => (
  <Wrapper>
    <CurrentInvestmentStatus
      memberAssets={memberAssets}
      date={date}
      investmentDigit={investmentDigit}
    />
  </Wrapper>
);
