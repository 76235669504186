import { SelectedAssociationState } from '@/recoil/associations/associations';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { appClient } from '..';

export const useUnapprovedApplication = (yearMonth: number, type: string) => {
  const { associationCd } = useRecoilValue(SelectedAssociationState);

  const [isUapprovedAdmission, setIsUnapprovedAdmission] = useState<boolean>();
  const [isUapprovedMonthlyApplications, setIsUnapprovedMonthlyApplications] =
    useState<boolean>();
  const [unapprovedAdmissionNumber, setUnapprovedAdmissionNumber] =
    useState<number>(0);

  const getApplications = async () => {
    try {
      if (type !== 'bonus') {
        const resAddmission = await appClient.applications.listApplications(
          associationCd,
          undefined,
          undefined,
          yearMonth,
          'ADMISSION',
          'ADMISSION',
          'UNAPPROVED'
        );
        if (resAddmission.totalSize !== 0) {
          setIsUnapprovedAdmission(true);
          setUnapprovedAdmissionNumber(resAddmission.totalSize || 0);
        } else {
          setIsUnapprovedAdmission(false);
          setUnapprovedAdmissionNumber(0);
        }
      }
      const resMonthly = await appClient.applications.listApplications(
        associationCd,
        undefined,
        undefined,
        yearMonth,
        type === 'bonus' ? 'BONUS' : 'MONTHLY',
        undefined,
        'UNAPPROVED'
      );
      if (resMonthly.totalSize !== 0) {
        setIsUnapprovedMonthlyApplications(true);
      } else {
        setIsUnapprovedMonthlyApplications(false);
      }
    } catch (e) {
      // catch error
    }
  };

  useEffect(() => {
    void getApplications();
  }, [yearMonth]);

  return {
    isUapprovedAdmission,
    isUapprovedMonthlyApplications,
    unapprovedAdmissionNumber,
  };
};
