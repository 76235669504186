import { Snackbar } from '@mui/material';
import React, { FC } from 'react';

interface CustomSnackbarProps {
  message: string;
  open: boolean;
  onClose: () => void;
  bgColor?: string;
  color?: string;
  width?: string;
}
export const CustomSnackbar: FC<CustomSnackbarProps> = ({
  message,
  open,
  width,
  onClose,
  bgColor = '#555555',
  color = '#fff',
}) => (
  <Snackbar
    autoHideDuration={5000}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    ContentProps={{
      sx: {
        backgroundColor: bgColor,
        color,
        fontSize: '16px',
        display: 'flex',
        justifyContent: 'center',
      },
    }}
    sx={{
      '&.MuiSnackbar-root': { top: 75, left: '52%', minWidth: '0px', width },
    }}
    message={message}
    open={open}
    onClose={onClose}
  />
);
