import { Box, SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  sxProps?: SxProps;
}

export const TableContainer: FC<Props> = ({ children, sxProps }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-startr',
      width: '424px',
      border: '1px solid',
      borderColor: 'system.separator-light',
      borderRadius: '4px',
      overflow: 'hidden',
      ...sxProps,
    }}
  >
    {children}
  </Box>
);
