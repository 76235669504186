import { ListApplicationsQuery } from '@/types/api/Applications/applications';
import { format } from 'date-fns';
import { atom } from 'recoil';

export const ApplicationsMonthlyQueryState = atom({
  key: 'ApplicationsMonthlyQuery',
  default: {
    associationCd: '',
    yearMonth: Number(format(new Date(), 'yyyyMM')),
    eventType: 'MONTHLY',
    page: 1,
    perPageSize: 20,
    sortKey: 'APPLICATION_ID_DESC',
  } as ListApplicationsQuery,
});

export const ApplicationsMonthlySortKey = atom({
  key: 'ApplicationsMonthlySortKey',
  default: 'APPLICATION_ID_DESC' as ListApplicationsQuery['sortKey'],
});
