import { Helmet } from 'react-helmet';
import { useRecoilValue } from 'recoil';
import { HelmetDescriptionState, HelmetTitleState } from './recoil/helmet';

export const ReactHelmbet = () => {
  const title = useRecoilValue(HelmetTitleState);
  const description = useRecoilValue(HelmetDescriptionState);
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};
