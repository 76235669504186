/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { FC } from 'react';
import { Box, Divider, Paper, Stack, styled, Typography } from '@mui/material';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { Controller, UseFormReturn } from 'react-hook-form';
import { TextInput } from '@/components/atoms/Input/TextInput';
import { AdminFormInput, LoginState } from '@/components/pages/Login';
import { CancelButton } from '@/components/atoms/Buttons/CancelButton';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

interface AdminProps {
  isError: boolean;
  setIsError: (isError: boolean) => void;
  isConfirmed: boolean;
  setIsConfirmed: (isConfirmed: boolean) => void;
  methods: UseFormReturn<AdminFormInput>;
  state: LoginState;
  handleClickButton: (data: AdminFormInput) => void;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.system.white,
  padding: theme.spacing(4),
  borderRadius: '4px',
  width: '780px',
}));

const Row = styled(Box)({
  display: 'flex',
  gap: 1,
});

const LabelWrapper = styled(Stack)({
  width: 144,
});

const Label = styled(Typography)(({ theme }) => ({
  ...theme.typography['body-main/bold'],
}));

export const AdminOrganism: FC<AdminProps> = ({
  isError,
  setIsError,
  isConfirmed,
  setIsConfirmed,
  methods,
  state,
  handleClickButton,
}) => {
  const entryCheckConfirm = () => {
    let returnParam = false;
    if (methods.getValues('firstNameKanji') === '') {
      returnParam = true;
    }
    if (methods.getValues('lastNameKanji') === '') {
      returnParam = true;
    }

    setIsError(returnParam);
  };

  useHelmetHandler({
    title: isConfirmed ? '初回 管理者情報入力 内容確認' : '初回 管理者情報入力',
  });

  return (
    <Box component="form" display="flex" justifyContent="center">
      <StyledPaper elevation={0}>
        <Stack spacing={5}>
          {isConfirmed ? (
            <Stack spacing={2}>
              <Typography variant="h3">担当者情報入力内容確認</Typography>
              <Typography variant="body-main/regular">
                入力内容を確認してください。
              </Typography>
            </Stack>
          ) : (
            <Stack spacing={2}>
              <Typography variant="h3">担当者情報入力</Typography>
              <Typography variant="body-main/regular">
                事務局担当者の氏名を入力してください。
              </Typography>
            </Stack>
          )}
          <Stack spacing={2}>
            <Row>
              <LabelWrapper>
                <Label>氏名</Label>
              </LabelWrapper>
              {isConfirmed ? (
                <Typography variant="body-main/regular">
                  {`${methods.getValues('lastNameKanji') ?? ''} ${
                    methods.getValues('firstNameKanji') ?? ''
                  }`}
                </Typography>
              ) : (
                <Box display="flex" gap={1}>
                  <Stack spacing={0.5}>
                    <Typography variant="body-sub/regular">姓</Typography>
                    <Controller
                      name="lastNameKanji"
                      control={methods.control}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          placeholder="例：田中"
                          onChange={(e) => {
                            methods.setValue('lastNameKanji', e.target.value);
                            entryCheckConfirm();
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack spacing={0.5}>
                    <Typography variant="body-sub/regular">名</Typography>
                    <Controller
                      name="firstNameKanji"
                      control={methods.control}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          placeholder="例：太郎"
                          onChange={(e) => {
                            methods.setValue('firstNameKanji', e.target.value);
                            entryCheckConfirm();
                          }}
                        />
                      )}
                    />
                  </Stack>
                </Box>
              )}
            </Row>
            <Divider />
            <Row>
              <LabelWrapper>
                <Label>権限</Label>
              </LabelWrapper>
              <Typography variant="body-main/regular">管理者</Typography>
            </Row>
            <Divider />
            <Row>
              <LabelWrapper>
                <Label>ログインID</Label>
                <Typography variant="caption/regular" color="system.text-light">
                  ※メールアドレス
                </Typography>
              </LabelWrapper>
              <Typography variant="body-main/regular">
                {state?.loginId}
              </Typography>
            </Row>
            <Divider />
          </Stack>
          {isConfirmed ? (
            <Box display="flex" gap={2}>
              <CancelButton onClick={() => setIsConfirmed(false)}>
                入力に戻る
              </CancelButton>
              <PrimaryButton
                type="submit"
                sx={{ width: 240 }}
                onClick={methods.handleSubmit(handleClickButton)}
              >
                事務局担当者情報を登録する
              </PrimaryButton>
            </Box>
          ) : (
            <PrimaryButton
              sx={{ width: 112 }}
              onClick={(e) => {
                e.preventDefault();
                setIsConfirmed(true);
              }}
              disabled={isError}
            >
              確認へ進む
            </PrimaryButton>
          )}
        </Stack>
      </StyledPaper>
    </Box>
  );
};
