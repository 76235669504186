/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ApplicationsUser } from '@/components/organisms/Applications/UserDetail';
import { FC, useEffect, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { FindApplicationByID } from '@/types/api/Applications/application';
import { PrevnextApplications } from '@/types/api/Applications/prevnextApplications';
import { MemberById } from '@/types/api/member';
import { appClient } from '@/services';
import { useSnackbar } from '@/hooks/useSnackbar';
import { ErrorResponse } from '@/types/api/error';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

export const UserDetail: FC = () => {
  const applicationId = Number(useParams().applicationId);
  const [userData, setUserData] = useState<MemberById>();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  useHelmetHandler({
    title: '会員情報 申請',
  });

  const [applicationData, setApplicationData] = useState<FindApplicationByID>();

  const { showSnackbar } = useSnackbar();
  const getApplicationData = async () => {
    try {
      const res = await appClient.applications.findApplicationById(
        applicationId
      );
      setApplicationData(res);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    void getApplicationData();
  }, [applicationId]);

  const [searchParams] = useSearchParams();
  const [bottomData, setBottomData] = useState<PrevnextApplications>();
  const getBottomData = async () => {
    const res = await appClient.applications.findPrevNextApplicationById(
      applicationId,
      applicationData?.associationCd,
      undefined,
      searchParams.get('keyword') || undefined,
      Number(
        applicationData?.applicationDate?.substring(0, 7).replace('-', '')
      ) || undefined,
      'USER',
      undefined,
      undefined
    );
    console.log(res);
    setBottomData(res);
  };
  const getUserData = async () => {
    try {
      if (applicationData?.memberId && applicationData.memberId !== 0) {
        const res = await appClient.members.findMember(
          applicationData?.memberId
        );
        setUserData(res);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    void getBottomData();
  }, [applicationData]);

  useEffect(() => {
    void getUserData();
  }, [applicationData]);

  const handleApplication = async (
    value: 'APPROVAL' | 'REJECTED',
    comment?: string
  ) => {
    try {
      await appClient.applications.updateApplicationApproval(
        applicationId,
        value,
        {
          comment: comment || '',
          transferAmount: 0,
          remarks: '',
        }
      );
      if (applicationData) navigate(`/applications/user`);
    } catch (e) {
      showSnackbar((e as ErrorResponse).data.message);
    }
  };

  if (applicationData && userData) {
    return (
      <ApplicationsUser
        application={applicationData}
        bottomData={bottomData}
        userData={userData}
        handleApplication={handleApplication}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
