import { Box, Typography, Popover } from '@mui/material';
import { FC, useState } from 'react';
import { ReactComponent as QuestionMarkIcon } from '@/assets/questionPopOverGrey.svg';
import { theme } from '@/theme';

export const PopoverQuestionMark: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <Box
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        display="flex"
        alignItems="center"
        height="30px"
      >
        <QuestionMarkIcon />
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          '.MuiPopover-paper': {
            background: theme.palette.system['background-light'],
            borderRadius: '8px',
            border: `1px solid`,
            borderColor: theme.palette.system.separator,
          },
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableRestoreFocus
        elevation={0}
      >
        <Box sx={{ p: 2, maxWidth: '320px' }}>
          <Typography variant="caption/regular">
            第三者からのアクセスを防ぎ、セキュリティを強化するために必要な認証です。
          </Typography>
        </Box>
      </Popover>
    </>
  );
};
