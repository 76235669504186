import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { ReactComponent as ReadBadge } from '@/assets/readBadge.svg';
import { NavLink } from 'react-router-dom';
import { theme } from '@/theme';
import { useRecoilValue } from 'recoil';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { appClient } from '@/services';

interface ListItemProps {
  link: string;
  title: string;
}
interface AssociationTabProps {
  list: ListItemProps[];
  baseUrl: string;
}

export const AssociationTab: FC<AssociationTabProps> = ({ list, baseUrl }) => {
  const { typography, palette } = theme;
  const { associationCd = '' } = useRecoilValue(SelectedAssociationState);
  const [isReadList, setIsReadList] = useState<boolean[]>([true]);
  const [proxyApplication, setProxyApplication] = useState(false);

  /**
   * 代理申請が可能なユーザーかどうか判定
   */
  const checkProxyApplication = async () => {
    const { officeUserId } = await appClient.users.findUser();
    const { officeUserAssociations } =
      await appClient.officeUsers.officeUserDetail(officeUserId ?? 0);
    const association = officeUserAssociations?.find(
      (o) => o.associationCd === associationCd
    );
    if (association?.proxyApplicationClassification === 'POSSIBLE') {
      setProxyApplication(true);
    }
  };

  useEffect(() => {
    void checkProxyApplication();
  }, [associationCd]);

  return (
    <Box>
      <Box
        height={40}
        display="inline-flex"
        gap={1}
        p={0.5}
        bgcolor="system.background"
        borderRadius="30px"
      >
        {list.map(({ link, title }, index) => {
          if (!proxyApplication && title === '代理申請') {
            return null;
          }
          return (
            <NavLink
              style={({ isActive }) => ({
                ...typography['body-sub/regular'],
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: '6px 16px',
                textDecoration: 'none',
                cursor: 'pointer',
                position: 'relative',
                borderRadius: '30px',
                background: isActive ? palette.primary.gradation : '',
                color: isActive
                  ? palette.system.white
                  : palette.system['text-light'],
              })}
              to={`${baseUrl}/${link}`}
              key={title}
            >
              {title}
              {isReadList[index] && (
                <ReadBadge
                  style={{ position: 'absolute', top: '5px', right: '8px' }}
                />
              )}
            </NavLink>
          );
        })}
      </Box>
    </Box>
  );
};
