import { StatusBadge } from '@/components/atoms/Badge/StatusBadge';
import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { AssociationTab } from '@/components/organisms/Tab/AssociationTab';
import { MemberById } from '@/types/api/member';
import { useParams } from 'react-router-dom';
import { convertUserStatusToRegisteredStatus } from '@/utils/application';

interface MembersHeaderProps {
  memberData?: MemberById;
}

const list = [
  {
    title: '会員情報',
    link: 'information',
  },
  {
    title: '残高明細',
    link: 'balance',
  },
  {
    title: '帳票ダウンロード',
    link: 'download',
  },
  {
    title: '代理申請',
    link: 'proxy',
  },
];

export const MembersHeader: FC<MembersHeaderProps> = ({ memberData }) => {
  const { applicationType } = useParams();
  const baseUrl = `/members/${String(memberData?.memberId)}`;
  const backUrl = applicationType ? `${baseUrl}/proxy` : `/members`;

  return (
    <Stack
      gap={3}
      borderBottom={applicationType ? '1px solid' : '0'}
      borderColor="system.separator-light"
    >
      <NormalLink href={backUrl}>
        {applicationType ? '会員詳細へ戻る' : '会員一覧に戻る'}
      </NormalLink>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <StatusBadge
          sx={{ width: '48px', height: '22px' }}
          status={convertUserStatusToRegisteredStatus(
            memberData?.userStatus,
            memberData?.expiryDay
          )}
        />
        <Typography variant="h4" sx={{ color: 'system.text-normal' }}>
          {memberData?.nameKanji}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <Typography
            variant="body-sub/regular"
            sx={{ color: 'system.text-light' }}
          >
            会員コード
          </Typography>
          <Typography
            variant="body-sub/medium"
            sx={{ color: 'system.text-light' }}
          >
            {memberData?.memberCd}
          </Typography>
        </Box>
      </Box>
      {!applicationType && <AssociationTab baseUrl={baseUrl} list={list} />}
    </Stack>
  );
};
