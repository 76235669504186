import { atom } from 'recoil';

export const EventListState = atom({
  key: 'event_list',
  default: [] as {
    associationCd?: string;
    associationName?: string;
    listedClassification?: 'LISTEDCOMPANY' | 'UNLISTEDCOMPANY';
    eventNo?: string;
    eventType?: string;
    purchaseDate?: string;
    purchaseDateInputValue?: string;
    accumulatedDateInputValue?: string;
    purchaseDateRegistrationStatus?: 'BEFORREGISTRATION' | 'REGITERED';
    purchaseDateRegistrationDate?: string;
    workreportApprovalStatus?: 'BEFORESUBMISSION' | 'SUBMISSION';
    workreportDueDate?: string;
    purchaseScheduleDay?: number;
    tradereportsStatus?: 'AFTERRECEIPT' | 'BEFORERECEIPT';
  }[],
});

export const EventListMonthlyState = atom({
  key: 'event_list_monthly',
  default: {} as {
    associationCd?: string;
    associationDeadline?: string;
    monthlyPurchase?: {
      date1?: string;
      date2?: string;
      date3?: string;
      date4?: string;
      date5?: string;
      date6?: string;
      date7?: string;
      date8?: string;
      date9?: string;
      date10?: string;
      date11?: string;
      date12?: string;
    };
    memberDeadline?: {
      date1?: string;
      date2?: string;
      date3?: string;
      date4?: string;
      date5?: string;
      date6?: string;
      date7?: string;
      date8?: string;
      date9?: string;
      date10?: string;
      date11?: string;
      date12?: string;
    };
    officeDeadline?: {
      date1?: string;
      date2?: string;
      date3?: string;
      date4?: string;
      date5?: string;
      date6?: string;
      date7?: string;
      date8?: string;
      date9?: string;
      date10?: string;
      date11?: string;
      date12?: string;
    };
  },
});
