import { Box, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { ja } from 'date-fns/locale';
import { format } from 'date-fns';
import {
  StaticDatePicker,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface CalendarProps {
  propValue?: string;
  onChange?: (value: string) => void;
  minDate?: Date;
  maxDate?: Date;
}

export const Calendar: FC<CalendarProps> = ({
  onChange,
  propValue,
  minDate,
  maxDate,
}) => {
  const [value, setValue] = useState<Date | number | null>(new Date());
  // 入れているだけの値。要削除
  const today = new Date(new Date().toDateString());
  today.setDate(today.getDate() + 2);
  // イベント発生日の配列。カレンダー色付けのためにnumber型に変換する必要がある。
  const [eventDates, setEventDates] = useState<number[]>([today.getTime()]);

  const renderWeekEndPickerDay = (
    date: Date,
    _selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ) => {
    const switchDayColor = (d: number) => {
      if (eventDates.indexOf(d) !== -1) {
        return { backgroundColor: 'primary.light' };
      }
      return {};
    };

    const newPickersDayProps = {
      ...pickersDayProps,
      sx: switchDayColor(date.getTime()),
    };

    return <PickersDay {...newPickersDayProps} />;
  };

  return (
    <Box
      sx={{
        width: '310px',
        overflow: 'hidden',
      }}
    >
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={ja}
        showMonthAfterYear
        dateFormats={{ monthAndYear: 'yyyy年MM月' }}
      >
        <StaticDatePicker
          onChange={(e) => {
            if (onChange) return onChange(format(e as Date, 'yyyy-MM-dd'));
            return null;
          }}
          minDate={minDate || undefined}
          maxDate={maxDate || undefined}
          displayStaticWrapperAs="desktop"
          value={propValue || value}
          renderInput={() => <TextField />}
          disableOpenPicker
          renderDay={renderWeekEndPickerDay}
        />
      </LocalizationProvider>
    </Box>
  );
};
