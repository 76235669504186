import { TextInput } from '@/components/atoms/Input/TextInput';
import { bankLabels } from '@/constants/Association/tableLabels';
import { theme } from '@/theme';
import {
  AssociationOrganismProps,
  AssociationProps,
} from '@/types/components/Association';
import {
  Box,
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Stack,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { Controller, useController, UseFormReturn } from 'react-hook-form';
import {
  FindAssociationsAccount,
  UpdateAssociationsAccount,
} from '@/types/api/associations';
import { numberRegex } from '@/utils/regEx';
import {
  validateAccountYuchoNumber,
  validateAccountYuchoSymbol,
  validateAccountNumber,
  validateBranchCode,
  validateKatagana,
} from '@/utils/validation';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { AssociationRow as Row } from './AssociationRow';
import { AssociationButtons } from './AssociationButtons';
import { AssociationHeader } from './AssociationHeader';

const StyledRadio: FC<Pick<FormControlLabelProps, 'value' | 'label'>> = ({
  value,
  label,
}) => (
  <FormControlLabel
    value={value}
    control={
      <Radio
        sx={{
          p: 0,
          mr: 1,
          '&.Mui-checked': { color: 'secondary.normal' },
        }}
      />
    }
    componentsProps={{
      typography: {
        color: theme.palette.system['text-normal'],
        variant: 'body-main/regular',
      },
    }}
    label={label}
    sx={{ m: 0 }}
  />
);

const StyledRadioGroup: FC<RadioGroupProps> = ({ name = '', children }) => {
  const {
    field: { ref, ...rest },
  } = useController({ name });

  return (
    <RadioGroup sx={{ gap: 2 }} row ref={ref} {...rest}>
      {children}
    </RadioGroup>
  );
};

const StyledTextInput: FC<TextFieldProps> = ({ name = '', ...props }) => {
  const {
    field: { ref, ...rest },
  } = useController({ name });

  return <TextInput inputRef={ref} {...rest} {...props} />;
};

const renderBankForm = (
  isConfirmed: AssociationProps['isConfirmed'],
  institutionType: FindAssociationsAccount['accountClassification'],
  methods: UseFormReturn<UpdateAssociationsAccount>,
  checkError: () => void
) => {
  const {
    formState: { errors },
  } = methods;

  const accountType = {
    SAVINGS: '普通',
    CURRENT: '当座',
    OTHER: '貯蓄',
  };

  switch (institutionType) {
    case 'YUCHO': {
      return (
        <Row label={bankLabels.symbolNumber}>
          {isConfirmed ? (
            <Typography
              variant="body-main/regular"
              color="system/text-normal"
            >{`${methods.getValues('accountYuchoSymbol') ?? ''}-${
              methods.getValues('accountYuchoNumber') ?? ''
            }`}</Typography>
          ) : (
            <>
              <Stack spacing={0.5}>
                <Typography
                  variant="body-sub/regular"
                  color="system.text-normal"
                >
                  店番
                </Typography>
                <Controller
                  name="accountYuchoSymbol"
                  control={methods.control}
                  rules={{
                    validate: validateAccountYuchoSymbol,
                    pattern: numberRegex,
                  }}
                  render={({ field }) => (
                    <StyledTextInput
                      {...field}
                      error={errors.accountYuchoSymbol !== undefined}
                      inputProps={{ maxLength: 5 }}
                      sx={{ width: 96, maxHeight: 39 }}
                      onChange={(e) => {
                        methods.setValue('accountYuchoSymbol', e.target.value);
                        checkError();
                      }}
                    />
                  )}
                />
              </Stack>
              <Stack pt="25px">
                <Box display="flex" alignItems="center" height="100%">
                  <Typography
                    variant="body-sub/regular"
                    color="system.text-normal"
                  >
                    -
                  </Typography>
                </Box>
              </Stack>
              <Stack spacing={0.5}>
                <Typography
                  variant="body-sub/regular"
                  color="system.text-normal"
                >
                  番号
                </Typography>

                <Controller
                  name="accountYuchoNumber"
                  control={methods.control}
                  rules={{
                    validate: validateAccountYuchoNumber,
                  }}
                  render={({ field }) => (
                    <StyledTextInput
                      {...field}
                      name="accountYuchoNumber"
                      error={errors.accountYuchoNumber !== undefined}
                      sx={{ width: 128, maxHeight: 39 }}
                      inputProps={{ maxLength: 8 }}
                      onChange={(e) => {
                        methods.setValue('accountYuchoNumber', e.target.value);
                        checkError();
                      }}
                    />
                  )}
                />
              </Stack>
            </>
          )}
        </Row>
      );
    }
    case 'OTHER': {
      return (
        <>
          <Row label={bankLabels.institutionName}>
            {isConfirmed ? (
              <Typography
                variant="body-main/regular"
                color="system/text-normal"
              >
                {methods.getValues('institutionName')}
              </Typography>
            ) : (
              <Controller
                name="institutionName"
                control={methods.control}
                rules={{
                  max: 5,
                }}
                render={({ field }) => (
                  <StyledTextInput
                    {...field}
                    error={errors.institutionName !== undefined}
                    sx={{ width: 400, maxHeight: 39 }}
                    inputProps={{ maxLength: 20 }}
                    onChange={(e) => {
                      methods.setValue('institutionName', e.target.value);
                      checkError();
                    }}
                  />
                )}
              />
            )}
          </Row>
          <Row label={bankLabels.branchCd}>
            {isConfirmed ? (
              <Typography
                variant="body-main/regular"
                color="system/text-normal"
              >
                {methods.getValues('branchCd')}
              </Typography>
            ) : (
              <Controller
                name="branchCd"
                control={methods.control}
                rules={{
                  validate: validateBranchCode,
                }}
                render={({ field }) => (
                  <StyledTextInput
                    {...field}
                    error={errors.branchCd !== undefined}
                    name="branchCd"
                    sx={{ width: 160, maxHeight: 39 }}
                    inputProps={{ maxLength: 3 }}
                    onChange={(e) => {
                      methods.setValue('branchCd', e.target.value);
                      checkError();
                    }}
                  />
                )}
              />
            )}
          </Row>
          <Row label={bankLabels.branchName}>
            {isConfirmed ? (
              <Typography
                variant="body-main/regular"
                color="system/text-normal"
              >
                {methods.getValues('branchName')}
              </Typography>
            ) : (
              <StyledTextInput
                name="branchName"
                sx={{ width: 400 }}
                inputProps={{ maxLength: 18 }}
                onChange={(e) => {
                  methods.setValue('branchName', e.target.value);
                  checkError();
                }}
              />
            )}
          </Row>
          <Row label={bankLabels.accountType}>
            {isConfirmed ? (
              <Typography
                variant="body-main/regular"
                color="system/text-normal"
              >
                {
                  accountType[
                    methods.getValues('accountType') as Exclude<
                      UpdateAssociationsAccount['accountType'],
                      undefined
                    >
                  ]
                }
              </Typography>
            ) : (
              <StyledRadioGroup name="accountType">
                <StyledRadio value="SAVINGS" label="普通" />
                <StyledRadio value="CURRENT" label="当座" />
                <StyledRadio value="OTHER" label="貯蓄" />
              </StyledRadioGroup>
            )}
          </Row>
          <Row label={bankLabels.accountNumber}>
            {isConfirmed ? (
              <Typography
                variant="body-main/regular"
                color="system/text-normal"
              >
                {methods.getValues('accountNumber')}
              </Typography>
            ) : (
              <Controller
                name="accountNumber"
                control={methods.control}
                rules={{
                  validate: validateAccountNumber,
                }}
                render={({ field }) => (
                  <StyledTextInput
                    {...field}
                    error={errors.accountNumber !== undefined}
                    name="accountNumber"
                    sx={{ width: 160, maxHeight: 39 }}
                    inputProps={{ maxLength: 7 }}
                    onChange={(e) => {
                      methods.setValue('accountNumber', e.target.value);
                      checkError();
                    }}
                  />
                )}
              />
            )}
          </Row>
        </>
      );
    }
    default: {
      return null;
    }
  }
};

export const AssociationBankOrganism: FC<
  AssociationOrganismProps<UpdateAssociationsAccount>
> = ({ isConfirmed, setIsConfirmed, methods, isError, setIsError }) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const watchBankType = methods.watch('accountClassification');
  const {
    formState: { errors },
  } = methods;
  useHelmetHandler({
    title: isConfirmed
      ? '持株会詳細 賞買増精算振込先口座編集 確認'
      : '持株会詳細 賞買増精算振込先口座編集',
  });

  const checkError = () => {
    setIsError(false);
    if (methods.getValues('accountClassification') === 'YUCHO') {
      if (!validateAccountYuchoSymbol(methods.getValues('accountYuchoSymbol')))
        setIsError(true);
      if (!validateAccountYuchoNumber(methods.getValues('accountYuchoNumber')))
        setIsError(true);
    } else {
      if (!validateBranchCode(methods.getValues('branchCd'))) setIsError(true);
      if (!validateAccountNumber(methods.getValues('accountNumber')))
        setIsError(true);
    }
    if (!validateKatagana(methods.getValues('accountName'))) setIsError(true);
  };

  return (
    <>
      <AssociationHeader type="bank" isConfirmed={isConfirmed} />
      <Stack>
        <Stack p="25px 40px">
          <Stack gap={2}>
            <Row label="金融機関の種類">
              {isConfirmed ? (
                <Typography
                  variant="body-main/regular"
                  color="system/text-normal"
                >
                  {watchBankType === 'YUCHO'
                    ? 'ゆうちょ銀行'
                    : 'ゆうちょ銀行以外'}
                </Typography>
              ) : (
                <StyledRadioGroup name="accountClassification">
                  <StyledRadio value="OTHER" label="ゆうちょ銀行以外" />
                  <StyledRadio value="YUCHO" label="ゆうちょ銀行" />
                </StyledRadioGroup>
              )}
            </Row>
            {renderBankForm(isConfirmed, watchBankType, methods, checkError)}
            <Row label={bankLabels.accountName}>
              {isConfirmed ? (
                <Typography
                  variant="body-main/regular"
                  color="system/text-normal"
                >
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
                  {methods.getValues('accountName')}
                </Typography>
              ) : (
                <Controller
                  name="accountName"
                  control={methods.control}
                  rules={{
                    max: 18,
                    validate: validateKatagana,
                  }}
                  render={({ field }) => (
                    <StyledTextInput
                      {...field}
                      error={errors.accountName !== undefined}
                      name="accountName"
                      sx={{ width: 400, maxHeight: 39 }}
                      inputProps={{ maxLength: 20 }}
                      onChange={(e) => {
                        methods.setValue('accountName', e.target.value);
                        checkError();
                      }}
                    />
                  )}
                />
              )}
            </Row>
          </Stack>
          <AssociationButtons
            isConfirmed={isConfirmed}
            setIsConfirmed={setIsConfirmed}
            isError={isError}
            setIsError={setIsError}
          />
        </Stack>
      </Stack>
    </>
  );
};
