export const informationLeftLabels = {
  officeId: '事務局コード',
  associationName: '持株会名',
  associationCd: '持株会コード',
};

export const informationRightLabels = {
  address: '住所',
};

export const bankLeftLabels = {
  institutionName: '金融機関名',
  branchCd: '支店コード',
  branchName: '支店名',
};

export const bankLeftLabelsYucho = {
  accountNumber: '店番・番号',
};

export const bankRightLabels = {
  accountType: '種別',
  accountNumber: '口座番号',
  accountName: '口座名義',
};

export const bankRightLabelsYucho = {
  accountName: '口座名義',
};

export const urlLabels = {
  kaiin: '会員向け',
  jimukyoku: '事務局向け',
  nyuukai: '入会',
  associationPassword: ['持株会', <br key="br" />, 'パスワード'],
};

export const contactLabels = {
  inquiryRepresentative: '担当者',
  inquiryTelEmail: 'メールアドレス',
  tel: '電話番号',
};

export const deadlineLabels = {
  memberDeadlineDays: '会員申請締日',
};

export const applicationLabels = {
  unitMonth: '口数変更',
  newUserMonth: '入会',
};

export const bonusLabels = {
  bonusReserveClassification: '積立有無区分',
  bonusMultiplierDesignationType: '倍率指定',
  bonusMultiplierDetails: '倍率詳細',
};

export const bankLabels = {
  ...bankLeftLabels,
  ...bankRightLabels,
  accountType: '種別',
  symbolNumber: '店番・番号',
};

export const contactFormLabels = {
  inquiryDepartment: '担当者部署',
  inquiryRepresentative: '担当者名',
  inquiryTelEmail: 'メールアドレス',
  tel: '電話番号',
};
