/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState } from 'react';
import { ApplicationsTemporaryContact } from '@/components/organisms/Applications/TemporaryContact';
import { SubmitHandler } from 'react-hook-form';
import { appClient } from '@/services';
import { TemporaryContactInputs as Inputs } from '@/types/components/Inputs';
import { useRecoilValue } from 'recoil';
import { SelectedAssociationState } from '@/recoil/associations/associations';

export const TemporaryContact = () => {
  const { associationCd = '' } = useRecoilValue(SelectedAssociationState);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const onClick = () => {
    setIsConfirm(!isConfirm);
  };
  const onSubmit: SubmitHandler<Inputs> = async (d: Inputs) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const res = await appClient.applications.createOfficeSpecialApplication({
        associationCd,
        detail: d.detail,
      });
      console.log(res);
    } catch {
      // エラー処理
    }
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };
  return (
    <ApplicationsTemporaryContact
      isConfirm={isConfirm}
      onClick={onClick}
      onSubmit={onSubmit}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};
