/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ApplicationsRecess } from '@/components/organisms/Applications/Recess';
import { FC, useEffect, useState } from 'react';
import {
  useMatch,
  useParams,
  useSearchParams,
  useNavigate,
} from 'react-router-dom';
import { MemberById } from '@/types/api/member';
import { FindApplicationByID } from '@/types/api/Applications/application';
import { PrevnextApplications } from '@/types/api/Applications/prevnextApplications';
import { appClient } from '@/services';
import { useSnackbar } from '@/hooks/useSnackbar';
import { ErrorResponse } from '@/types/api/error';

export const Recess: FC = () => {
  const match = useMatch('/applications/:unitType/:applicationId/recess');
  const unitType = match?.params.unitType || '';
  const applicationId = Number(useParams().applicationId) || 0;
  const [applicationData, setApplicationData] = useState<FindApplicationByID>();
  const [userData, setUserData] = useState<MemberById>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [bottomData, setBottomData] = useState<PrevnextApplications>();
  const { showSnackbar } = useSnackbar();
  const getBottomData = async () => {
    const res = await appClient.applications.findPrevNextApplicationById(
      applicationId,
      applicationData?.associationCd,
      undefined,
      searchParams.get('keyword') || undefined,
      Number(
        applicationData?.applicationDate?.substring(0, 7).replace('-', '')
      ) || undefined,
      unitType === 'monthly' ? 'MONTHLY' : 'BONUS',
      undefined,
      undefined
    );
    setBottomData(res);
    console.log(res);
  };

  const [isOpen, setIsOpen] = useState(false);
  const handleApplication = async (
    value: 'APPROVAL' | 'REJECTED',
    comment?: string
  ) => {
    try {
      await appClient.applications.updateApplicationApproval(
        applicationId,
        value,
        {
          comment: comment || '',
          transferAmount: 0,
          remarks: '',
        }
      );
      if (applicationData) navigate(`/applications/${unitType}`);
    } catch (e) {
      showSnackbar((e as ErrorResponse).data.message);
    }
  };

  const getApplicationData = async () => {
    try {
      const res = await appClient.applications.findApplicationById(
        applicationId
      );
      setApplicationData(res);
    } catch (e) {
      console.log(e);
    }
  };

  const getUserData = async () => {
    try {
      if (applicationData?.memberId && applicationData.memberId !== 0) {
        const res = await appClient.members.findMember(
          applicationData?.memberId
        );
        setUserData(res);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    void getApplicationData();
  }, [applicationId]);

  useEffect(() => {
    void getUserData();
  }, [applicationData]);

  useEffect(() => {
    void getBottomData();
  }, [applicationData]);

  if (applicationData && userData) {
    return (
      <ApplicationsRecess
        application={applicationData}
        userData={userData}
        unitType={unitType}
        bottomData={bottomData}
        handleApplication={handleApplication}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
