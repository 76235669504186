import { styled, SxProps, Box } from '@mui/material';

const Badge = styled(Box)(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['caption/medium'],
    borderRadius: '4px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    border: 'solid 1px',
    maxWidth: '60px',
  };
});

interface Props {
  status: string;
  sx?: SxProps;
}

export const StatusBadge = ({ status, sx }: Props) => {
  const switchColor = () => {
    switch (status) {
      case 'PREPARATION':
      case 'APPLYING':
        return (
          <Badge
            sx={{ ...sx, color: 'states.error', borderColor: 'states.error' }}
          >
            未承認
          </Badge>
        );
      case 'OFFICER_APPROVED':
      case 'BACKOFFICER_APPROVED':
        return (
          <Badge
            sx={{
              ...sx,
              color: 'states.success',
              borderColor: 'states.success',
            }}
          >
            承認済
          </Badge>
        );
      case 'COMPLETED':
        return (
          <Badge
            sx={{ ...sx, color: 'states.done', borderColor: 'states.done' }}
          >
            完了
          </Badge>
        );
      case 'OFFICER_REJECTED':
      case 'BACKOFFICER_REJECTED':
      case 'CANCEL':
        return (
          <Badge
            sx={{
              ...sx,
              color: 'system.text-light',
              borderColor: 'system.text-light',
            }}
          >
            却下
          </Badge>
        );
      // お知らせ表示ステータス（NORMALかDROPしかないので、渡す側で判別が必要）
      case 'DROP':
        return (
          <Badge
            sx={{
              ...sx,
              color: 'system.text-light',
              borderColor: 'separator-light',
            }}
          >
            取り下げ
          </Badge>
        );
      case 'DISPLAYED':
        return (
          <Badge
            sx={{
              ...sx,
              color: 'system.text-light',
              borderColor: 'separator-light',
            }}
          >
            表示終了
          </Badge>
        );
      case 'BEFORE_DISPLAY':
        return (
          <Badge
            sx={{
              ...sx,
              color: 'states.success',
              borderColor: 'states.success',
            }}
          >
            表示前
          </Badge>
        );
      case 'DISPLAYING':
        return (
          <Badge
            sx={{
              ...sx,
              color: 'states.error',
              borderColor: 'states.error',
            }}
          >
            表示中
          </Badge>
        );
      // 仮のstatus、バックエンドが曖昧 作業処理連絡票と買い付け日登録のステータス
      case 'PREREGISTER':
      case 'BEFORREGISTRATION':
        return (
          <Badge
            sx={{
              ...sx,
              color: 'system.text-light',
              borderColor: 'system.separator-light',
              backgroundColor: 'system.background',
            }}
          >
            登録前
          </Badge>
        );
      case 'REGISTERED':
      case 'REGITERED':
        return (
          <Badge
            sx={{
              ...sx,
              color: 'states.done',
              borderColor: 'rgba(4, 81, 160, 0.5)',
            }}
          >
            登録済
          </Badge>
        );
      case 'AFTER_DISPLAY':
        return (
          <Badge
            sx={{ ...sx, color: 'states.error', borderColor: 'states.error' }}
          >
            表示前
          </Badge>
        );
      case 'BEFORERECEIPT':
        return (
          <Badge
            sx={{
              ...sx,
              color: 'system.text-light',
              borderColor: 'system.separator-light',
              backgroundColor: 'system.background',
            }}
          >
            受領前
          </Badge>
        );
      case 'AFTERRECEIPT':
        return (
          <Badge
            sx={{
              ...sx,
              // states.doneの50%透過
              border: '1px solid rgba(4, 81, 160, 0.5)',
              color: 'states.done',
              backgroundColor: 'system.white',
            }}
          >
            受領
          </Badge>
        );
      case 'INSPPROVED':
        return (
          <Badge
            sx={{ ...sx, color: 'states.error', borderColor: 'states.error' }}
          >
            未承認
          </Badge>
        );
      case 'APPROVED':
        return (
          <Badge
            sx={{
              ...sx,
              color: 'states.success',
              borderColor: 'states.success',
            }}
          >
            承認済
          </Badge>
        );

      // ユーザーの登録状況
      // 登録済は上記と一緒
      // APIが見つからない
      case 'UNREGISTERED':
        return (
          <Badge
            sx={{
              ...sx,
              color: 'states.error',
              borderColor: 'states.error',
            }}
          >
            未登録
          </Badge>
        );
      case 'MULTIFACTOR':
        return (
          <Badge
            sx={{
              ...sx,
              color: 'states.error',
              borderColor: 'states.error',
              textAlign: 'center',
            }}
          >
            MFA
            <br />
            未登録
          </Badge>
        );
      case 'REVOCATION':
        return (
          <Badge
            sx={{
              ...sx,
              color: 'system.separator',
              borderColor: 'system.separator',
            }}
          >
            失効
          </Badge>
        );
      // ユーザーの拠出状況(仮)
      case 'ACTIVE':
      case 'OPERATION':
        return (
          <Badge
            sx={{
              ...sx,
              color: 'secondary.text',
              borderColor: 'transparent',
              backgroundColor: 'secondary.light',
            }}
          >
            稼働中
          </Badge>
        );
      case 'STOP':
      case 'RECESS':
        return (
          <Badge
            sx={{
              ...sx,
              color: 'system.white',
              borderColor: 'system.separator-light',
              backgroundColor: 'system.text-light',
            }}
          >
            休止中
          </Badge>
        );
      case 'WITHDRAWN':
        return (
          <Badge
            sx={{
              ...sx,
              color: 'system.text-light',
              borderColor: 'transparent',
              backgroundColor: 'system.background',
            }}
          >
            退会済
          </Badge>
        );
      // TOP画面
      // 未登録、承認済は上記と一緒
      case 'AVAILABLE':
        return (
          <Badge
            sx={{
              ...sx,
              color: 'states.done',
              borderColor: 'states.done',
            }}
          >
            提出可
          </Badge>
        );
      case 'NOT_PRESENTED':
      case 'BEFORESUBMISSION':
        return (
          <Badge
            sx={{
              ...sx,
              color: 'system.text-light',
              borderColor: 'system.separator-light',
              backgroundColor: 'system.background',
            }}
          >
            提出前
          </Badge>
        );
      case 'PRESENTED':
      case 'SUBMISSION':
        return (
          <Badge
            sx={{
              ...sx,
              color: 'states.success',
              borderColor: 'states.success',
              backgroundColor: 'system.white',
            }}
          >
            提出済
          </Badge>
        );
      case 'EXPIRED':
        return (
          <Badge
            sx={{ ...sx, color: 'states.error', borderColor: 'states.error' }}
          >
            期日超過
          </Badge>
        );
      case 'UNOPENED':
        return (
          <Badge
            sx={{
              ...sx,
              backgroundColor: 'states.error',
              color: 'system.white',
              borderColor: 'states.error',
            }}
          >
            未開通
          </Badge>
        );
      default:
        return null;
    }
  };
  return <> {switchColor()}</>;
};
