/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WorkreportsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * 【API-B-2201】作業処理連絡票取得
   * 作業処理連絡票を取得する
   *
   * @param year 年
   *
   * @param month 月
   *
   * @param applicationType 申請種別
   * - MONTHLY: 月例
   * - BONUS: 賞与
   * - DIVIDEND: 配当
   *
   * @param associationCd 持株会コード
   *
   * @returns any seidogroup info response
   * @throws ApiError
   */
  public findWorkreportById(
    year: string,
    month: string,
    applicationType: 'MONTHLY' | 'BONUS' | 'DIVIDEND',
    associationCd: string
  ): CancelablePromise<{
    /**
     * 作業処理連絡票ID
     */
    workreportId?: number;
    /**
     * イベントNO
     */
    eventNo?: string;
    /**
     * 前回会員人数
     */
    oldUsersCount?: number;
    /**
     * 前回口数
     */
    oldUnitCount?: number;
    /**
     * 入会人数
     */
    joinCount?: number;
    /**
     * 退会人数
     */
    withdrawalCount?: number;
    /**
     * 口数変更人数
     */
    unitChangeCount?: number;
    /**
     * 休止人数
     */
    pauseCount?: number;
    /**
     * 再開人数
     */
    resumeCount?: number;
    /**
     * 今回変動した入会口数
     */
    joinUnit?: number;
    /**
     * 今回変動した退会口数
     */
    withdrawalUnit?: number;
    /**
     * 今回変動した口数
     */
    changeUnit?: number;
    /**
     * 今回変動した休止口数
     */
    pauseUnit?: number;
    /**
     * 今回変動した再開口数
     */
    resumeUnit?: number;
    /**
     * 今回会員人数
     */
    usersCount?: number;
    /**
     * 今回口数
     */
    unitCount?: number;
    /**
     * 前回繰越金
     */
    oldCarryoverMoney?: number;
    /**
     * 積立金
     */
    deposit?: number;
    /**
     * 推奨金
     */
    recommendedMoney?: number;
    /**
     * 事務代行手数料
     */
    jimuDaikouCommission?: number;
    /**
     * 振込料
     */
    transferFee?: number;
    /**
     * 利息
     */
    interest?: number;
    /**
     * 市場買付金
     */
    marketPurchase?: number;
    /**
     * 一部引出件数
     */
    partCount?: number;
    /**
     * 一部引出株数
     */
    partSharesNum?: number;
    /**
     * 買付日
     */
    purchaseDate?: string;
    /**
     * 事務局締日
     */
    officeClosingDate?: string;
    /**
     * 提出日時
     */
    submissionDate?: string;
    /**
     * 連絡事項
     */
    info?: string;
    /**
     * 購入株式
     */
    sharesPurchased?: {
      /**
       * 購入日1
       */
      purchaseDate1?: string;
      /**
       * 購入単価1
       */
      purchaseUnitPrice1?: number;
      /**
       * 購入株数1
       */
      purchaseSharesNum1?: number;
      /**
       * 購入日2
       */
      purchaseDate2?: string;
      /**
       * 購入単価2
       */
      purchaseUnitPrice2?: number;
      /**
       * 購入株数2
       */
      purchaseSharesNum2?: number;
      /**
       * 購入日3
       */
      purchaseDate3?: string;
      /**
       * 購入単価3
       */
      purchaseUnitPrice3?: number;
      /**
       * 購入株数3
       */
      purchaseSharesNum3?: number;
    };
    /**
     * 配当金詳細
     */
    dividend?: {
      /**
       * 権利月
       */
      rightMonth?: number;
      /**
       * 権利付株数
       */
      rightMonthStockCount?: number;
      /**
       * 一株あたり配当金
       */
      dividendOneStock?: number;
      /**
       * 受取配当金
       */
      receiptDividend?: number;
    };
    /**
     * 作業処理連絡票有無
     */
    workreportFlg?: boolean;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/workreports/{associationCd}/{applicationType}/{year}/{month}',
      path: {
        year: year,
        month: month,
        applicationType: applicationType,
        associationCd: associationCd,
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-2202】作業処理連絡票を登録する
   * 作業処理連絡票を登録する
   * @param associationCd 持株会コード
   *
   * @param year 年
   *
   * @param month 月
   *
   * @param applicationType 申請種別
   * - MONTHLY: 月例
   * - BONUS: 賞与
   * - DIVIDEND: 配当
   *
   * @param requestBody workreport_detail
   * @returns any seidogroup response
   * @throws ApiError
   */
  public createWorkreportSendById(
    associationCd: string,
    year: string,
    month: string,
    applicationType: 'MONTHLY' | 'BONUS' | 'DIVIDEND',
    requestBody: {
      /**
       * 事務代行手数料
       */
      jimuDaikouCommission?: number;
      /**
       * 振込料
       */
      transferFee?: number;
      /**
       * 利息
       */
      interest?: number;
      /**
       * 連絡事項
       */
      info?: string;
      /**
       * 購入株式
       */
      sharesPurchased?: {
        /**
         * 購入日1
         */
        purchaseDate1?: string;
        /**
         * 購入単価1
         */
        purchaseUnitPrice1?: number;
        /**
         * 購入株数1
         */
        purchaseSharesNum1?: number;
        /**
         * 購入日2
         */
        purchaseDate2?: string;
        /**
         * 購入単価2
         */
        purchaseUnitPrice2?: number;
        /**
         * 購入株数2
         */
        purchaseSharesNum2?: number;
        /**
         * 購入日3
         */
        purchaseDate3?: string;
        /**
         * 購入単価3
         */
        purchaseUnitPrice3?: number;
        /**
         * 購入株数3
         */
        purchaseSharesNum3?: number;
      };
      /**
       * 配当金詳細
       */
      dividend?: {
        /**
         * 権利月
         */
        rightMonth?: number;
        /**
         * 権利付株数
         */
        rightMonthStockCount?: number;
        /**
         * 一株あたり配当金
         */
        dividendOneStock?: number;
        /**
         * 受取配当金
         */
        receiptDividend?: number;
      };
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/workreports/{associationCd}/{applicationType}/{year}/{month}/send',
      path: {
        associationCd: associationCd,
        year: year,
        month: month,
        applicationType: applicationType,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }
}
