import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { ApplicationTemporaryDetail } from '@/components/organisms/Applications/TemporaryDetail';
import { useRecoilValue } from 'recoil';
import useSWR from 'swr';
import { appClient } from '@/services';

export const TemporaryDetail = () => {
  const applicationId = Number(useParams().applicationId) || 0;
  const { associationCd = '' } = useRecoilValue(SelectedAssociationState);
  const { data: applicationTemporary, mutate: mutateApplicationTemporary } =
    useSWR(
      associationCd
        ? '/api/applications/special/{officeSpecialApplicationId}'
        : null,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
      () => appClient.applications.findOfficeSpecialApplication(applicationId)
    );

  useEffect(() => {
    void mutateApplicationTemporary();
  }, [associationCd, mutateApplicationTemporary]);

  return (
    <ApplicationTemporaryDetail application={applicationTemporary || {}} />
  );
};
