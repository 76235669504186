import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  Validate,
} from 'react-hook-form';
import { numberRegex, kataganaRegex, EmailRegex } from './regEx';

type ValidationReturnType = Validate<
  FieldPathValue<FieldValues, FieldPath<FieldValues>>
>;

const PasswordNumberMatch = /[0-9]/g;
const PasswordSmallMatch = /[a-z]/g;
const PasswordBigMatch = /[A-Z]/g;
const PasswordSymbolMatch = /[.?^$*?!@#%&/,><':;|_~`\]})[{(=+-]/g;
const alphabetAndNumber = /^([a-zA-Z0-9]+)$/;

export const validateAccountYuchoSymbol: ValidationReturnType = (
  text: string
) => {
  if (numberRegex.test(text)) return text.length > 0 && text.length <= 5;
  return false;
};

export const validateAccountYuchoNumber: ValidationReturnType = (
  text: string
) => {
  if (numberRegex.test(text)) return text.length > 0 && text.length <= 8;
  return false;
};

export const validateBranchCode: ValidationReturnType = (text: string) => {
  if (numberRegex.test(text)) return text.length === 3;
  return false;
};

export const validateName: ValidationReturnType = (text: string) => {
  if (text.length <= 20) return true;
  return false;
};

export const validateInstitutionCode: ValidationReturnType = (text: string) => {
  if (numberRegex.test(text)) return text.length === 4;
  return false;
};

export const validateAccountNumber: ValidationReturnType = (text: string) => {
  if (numberRegex.test(text)) return text.length === 7;
  return false;
};

export const validateKatagana: ValidationReturnType = (text: string) =>
  kataganaRegex.test(text);

export const validateEmail: ValidationReturnType = (text: string) => {
  if (text.length === 0) return true;
  return EmailRegex.test(text);
};

export const inputRequiredValidateEmail: ValidationReturnType = (
  text: string
) => {
  if (text.length === 0) return false;
  return EmailRegex.test(text);
};

export const validateNumber: ValidationReturnType = (text: string) =>
  numberRegex.test(text);

export const validatePassword: ValidationReturnType = (text: string) => {
  const checkText = text?.replace(
    /[0-9]|[a-z]|[A-Z]|[.?^$*?!@#%&/,><':;|_~`\]})[{(=+-]/g,
    ''
  );
  if (checkText.length > 0) {
    return false;
  }
  return !!(
    text &&
    text.length !== 0 &&
    text.length >= 8 &&
    text.match(PasswordNumberMatch) &&
    text.match(PasswordSmallMatch) &&
    text.match(PasswordBigMatch) &&
    text.match(PasswordSymbolMatch)
  );
};

type ValidationRules = {
  maxLength?: number;
  onlyNumber?: boolean;
};

export const InputStringProcessor = (value?: string): string | undefined => {
  if (value) return value.replaceAll(' ', '');
  return undefined;
};

export const IsNumeric = (number: string): boolean => {
  const value = InputStringProcessor(number);
  if (value && numberRegex.test(value)) {
    return true;
  }
  return false;
};

export const IsAlphabetNumer = (value: string): boolean => {
  const val = InputStringProcessor(value);
  if (val && alphabetAndNumber.test(val)) {
    return true;
  }
  return false;
};

export const InputValidation = (
  input: string,
  rules?: ValidationRules
): boolean => {
  if (input === '') return true; // Only exception when the input field becoming clear
  if (rules?.onlyNumber && !IsNumeric(input)) return false;
  if (rules?.maxLength && input.length > rules?.maxLength) return false;
  return true;
};
