import { styled } from '@mui/material';
import { FC } from 'react';
import { ReactComponent as Logo } from '@/assets/pc.svg';

const MuiHeader = styled('header')({
  width: '100%',
  padding: '21px',
  paddingBottom: '32px',
  display: 'flex',
  justifyContent: 'center',
});

export const LoginHeader: FC = () => (
  <MuiHeader>
    <Logo />
  </MuiHeader>
);
