/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Box, Typography } from '@mui/material';
import { FindWorkreportsById } from '@/types/api/workreports';
import { FC } from 'react';
import { toDate } from '@/utils/formatter';
import { TableContainer } from './TableContainer';
import { Row } from './Row';
import { Td } from './Td';
import { Th } from './Th';

interface Props {
  report: Required<FindWorkreportsById>;
}

export const PurchaseDay: FC<Props> = ({ report }) => {
  const { purchaseDate } = report;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '8px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5" sx={{ color: 'system.text-normal' }}>
          買付日
        </Typography>
        <Typography
          variant="body-sub/regular"
          sx={{ color: 'system.text-light' }}
        >
          （上場会社のみ）
        </Typography>
      </Box>
      <TableContainer>
        <Row borderBottom={false}>
          <Th
            variant="medium"
            bgcolor="secondary.bg"
            width="120px"
            height="48px"
            borderRight
          >
            市場買付日
          </Th>
          <Td width="304px" height="48px" borderRight>
            {purchaseDate ? toDate(purchaseDate) : '-'}
          </Td>
        </Row>
      </TableContainer>
    </Box>
  );
};
