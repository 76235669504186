/* eslint-disable @typescript-eslint/no-unsafe-call */
import { OfficeUserEdit } from '@/components/organisms/OfficeUsers/OfficeUserEdit';
import { useSnackbar } from '@/hooks/useSnackbar';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { appClient } from '@/services';
import { OfficeUserDetail } from '@/types/api/officeUsers';
import { OfficeUserEditInputs as Inputs } from '@/types/components/OfficeUser';
import { FC, useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

export const OfficeUser: FC = () => {
  const { officeUserId = '' } = useParams();
  const { associationCd = '', associationName = '' } = useRecoilValue(
    SelectedAssociationState
  );
  const navigate = useNavigate();
  const [user, setUser] = useState<OfficeUserDetail>();
  const [association, setAssociation] = useState<
    Exclude<OfficeUserDetail['officeUserAssociations'], undefined>[number]
  >({});
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [modal, setModal] = useState('');
  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    try {
      await appClient.officeUserAssociations.createOfficeUserAssociations(
        associationCd,
        [
          {
            officeUserId: Number(officeUserId),
            actionType: 'UPDATE',
            ...data,
          },
        ]
      );
      setIsConfirmed(false);
      showSnackbar('事務局担当者情報を編集しました。', '288px');
      navigate('/office-users');
    } catch (e) {
      // 例外処理
    }
  };
  const onReset = () => {
    // eslint-disable-next-line no-console
    console.log('Reset Password');
    setModal('reset-complete');
  };
  const onDelete = async () => {
    try {
      await appClient.officeUserAssociations.createOfficeUserAssociations(
        associationCd,
        [
          {
            officeUserId: Number(user?.officeUserId),
            authorityClassification: association?.authorityClassification,
            proxyApplicationClassification:
              association?.proxyApplicationClassification,
            actionType: 'DELETE',
          },
        ]
      );
      setModal('delete-complete');
    } catch (e) {
      // Handle error
    }
  };

  const handleClickComplete = () => {
    setModal('');
    navigate('/office-users');
  };

  const handleClickCancel = () => {
    if (isConfirmed) {
      setIsConfirmed(false);
      return;
    }
    setIsConfirmed(false);
    navigate('/office-users');
  };

  const handleClickConfirm = () => {
    setIsConfirmed(true);
  };

  /**
   * HeaderでuseOfficeUserDetailが使われているため、ここではカスタムフックを使わない。
   */
  const setOfficeUserData = async (id: number) => {
    const res = await appClient.officeUsers.officeUserDetail(id);
    if (res !== undefined) {
      setUser(res);

      const associationData = res.officeUserAssociations?.find(
        (item) => item.associationCd === associationCd
      );
      if (typeof associationData !== 'undefined') {
        setAssociation(associationData);
      }
    }
  };

  useEffect(() => {
    if (officeUserId !== '' && associationCd !== '') {
      void setOfficeUserData(Number(officeUserId));
    }
  }, [officeUserId, associationCd]);

  return (
    <OfficeUserEdit
      onSubmit={onSubmit}
      onReset={onReset}
      onDelete={onDelete}
      handleClickCancel={handleClickCancel}
      handleClickConfirm={handleClickConfirm}
      handleClickComplete={handleClickComplete}
      isConfirmed={isConfirmed}
      user={user ?? {}}
      association={association}
      modal={modal}
      setModal={setModal}
      associationName={associationName}
    />
  );
};
