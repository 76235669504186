import { PrimaryTableBodyTdMulti } from '@/components/atoms/Table/PrimaryTableBodyTdMulti';
import { PrimaryTable } from '@/components/molecules/Table/PrimaryTable';
import { CreateApplication } from '@/types/api/applications';
import {
  GetAssociation,
  GetAssociationsContribution,
} from '@/types/api/associations';
import { ListEvents } from '@/types/api/events';
import { FindMemberAsset, FindMemberContribution } from '@/types/api/members';
import { convertEventType, toDate } from '@/utils/formatter';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Stack,
  Typography,
} from '@mui/material';
import { FC, useEffect } from 'react';
import { useController, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { MemberProxyApplicationBodyBonus } from './MemberProxyApplicationBodyBonus';
import { MemberProxyApplicationBodyPart } from './MemberProxyApplicationBodyPart';
import { MemberProxyApplicationBodyPay } from './MemberProxyApplicationBodyPay';
import { MemberProxyApplicationBodyRecess } from './MemberProxyApplicationBodyRecess';
import { MemberProxyApplicationBodyWithdrawal } from './MemberProxyApplicationBodyWithdrawal';

type Props = {
  isConfirmed: boolean;
  contribution: GetAssociationsContribution & FindMemberContribution;
  events: ListEvents;
  methods: UseFormReturn<CreateApplication>;
  selectedEvent: Exclude<ListEvents, undefined>[number];
  setSelectedEvent: (
    selectedEvent: Exclude<ListEvents, undefined>[number]
  ) => void;
  stocksNumber: FindMemberAsset['stocksNumber'];
  setIsUnit: (isUnit: boolean) => void;
  association?: GetAssociation;
};

const StyledRadioGroup: FC<RadioGroupProps> = ({
  name = '',
  onChange,
  children,
}) => {
  const {
    field: { ref, ...rest },
  } = useController({ name });

  return (
    <RadioGroup sx={{ gap: 2 }} ref={ref} {...rest}>
      {children}
    </RadioGroup>
  );
};

export const MemberProxyApplicationBodyBottom: FC<Props> = ({
  isConfirmed,
  contribution,
  events,
  methods,
  selectedEvent,
  setSelectedEvent,
  stocksNumber,
  setIsUnit,
  association,
}) => {
  const { applicationType = '' } = useParams();
  const watchEventNo = methods.watch('eventNo');

  useEffect(() => {
    const foundEvent = events?.find((event) => event.eventNo === watchEventNo);
    if (foundEvent) {
      setSelectedEvent(foundEvent);
    }
  }, [watchEventNo]);

  useEffect(() => {
    if (applicationType === 'resume') {
      setIsUnit(true);
    }
  }, [applicationType]);

  return (
    <Stack spacing={4}>
      {applicationType === 'pay' && (
        <MemberProxyApplicationBodyPay
          isConfirmed={isConfirmed}
          contribution={contribution}
          methods={methods}
          setIsUnit={setIsUnit}
        />
      )}
      {applicationType === 'bonus' && (
        <MemberProxyApplicationBodyBonus
          isConfirmed={isConfirmed}
          contribution={contribution}
          methods={methods}
          setIsUnit={setIsUnit}
        />
      )}
      {applicationType === 'part' && (
        <MemberProxyApplicationBodyPart
          isConfirmed={isConfirmed}
          stocksNumber={stocksNumber}
          methods={methods}
          setIsUnit={setIsUnit}
          investmentDigit={association?.investmentDigit}
        />
      )}
      {applicationType === 'recess' && (
        <MemberProxyApplicationBodyRecess
          isConfirmed={isConfirmed}
          methods={methods}
          setIsUnit={setIsUnit}
        />
      )}
      {applicationType === 'withdrawal' && (
        <MemberProxyApplicationBodyWithdrawal
          isConfirmed={isConfirmed}
          stocksNumber={stocksNumber}
          methods={methods}
          setIsUnit={setIsUnit}
          association={association}
        />
      )}
      <PrimaryTable title="紐付けるイベント">
        <PrimaryTableBodyTdMulti title="イベント" row={1}>
          {isConfirmed ? (
            <Typography>
              {`${convertEventType(selectedEvent.eventType)} (買付日：${
                selectedEvent.purchaseDate
                  ? toDate(selectedEvent.purchaseDate)
                  : ''
              } )`}
            </Typography>
          ) : (
            <StyledRadioGroup name="eventNo">
              {events
                ?.filter(({ eventType }) => eventType !== '11')
                .map((event) => (
                  <FormControlLabel
                    value={event.eventNo}
                    control={<Radio sx={{ p: 0, mr: 1 }} />}
                    componentsProps={{
                      typography: {
                        variant: 'body-main/regular',
                      },
                    }}
                    label={`${convertEventType(event.eventType)} (買付日：${
                      event.purchaseDate ? toDate(event.purchaseDate) : ''
                    } )`}
                    sx={{ m: 0 }}
                  />
                ))}
            </StyledRadioGroup>
          )}
        </PrimaryTableBodyTdMulti>
      </PrimaryTable>
      {(applicationType === 'part' || applicationType === 'withdrawal') && (
        <Stack
          border="1px solid"
          borderColor="states.error"
          borderRadius={2}
          p="8px 16px"
        >
          <Typography variant="body-main/bold" color="states.error">
            売却時の注意事項
          </Typography>
          <Typography variant="body-main/regular" color="states.error">
            会社の株価に重大な影響を与える「重要事実」を知りながら、その重要事実が「公表される前」に、会社の株式の売買等を行うことはできません。売却にあたりましては、必ず事前に会社に許可を得てください。
          </Typography>
        </Stack>
      )}
      {applicationType === 'resume' && (
        <Typography variant="body-main/regular" color="#444444">
          ※休止前の口数で再開されます。再開時口数の変更を行う際は口数変更の申請をお願いします。
        </Typography>
      )}
    </Stack>
  );
};
