import { ApplicationsAdmissionIndex } from '@/components/organisms/Applications/AdmissionIndex';
import { useRecoilState, useRecoilValue } from 'recoil';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { useCallback, useEffect, useState } from 'react';
import { useApplications } from '@/services/customHooks/useApplications';
import { format, getMonth, getYear } from 'date-fns';
import {
  pageState,
  perPageSizeState,
  totalPageState,
  totalSizeState,
} from '@/recoil/pagination';
import { convertParamMonth } from '@/utils/helper';
import {
  ApplicationStatusText,
  convertApplicationStatusTextToCode,
} from '@/utils/application';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { appendToQueryString } from '@/utils/queryStringHelper';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const initialState = {
  associationCd: '',
  yearMonth: Number(format(new Date(), 'yyyyMM')),
  eventType: 'ADMISSION',
  page: 1,
  perPageSize: 20,
  sortKey: 'APPLICATION_ID_DESC',
};

type Filter = {
  label: string;
  value: string;
};

export const AdmissionIndex = () => {
  const today = new Date();
  const navigate = useNavigate();
  const location = useLocation();
  const [keywordValue, setKeywordValue] = useState<string>('');
  const [selectedFilter, setSelectedFilter] = useState<Filter>({} as Filter);
  const [year, setYear] = useState<number>(getYear(today));
  const [month, setMonth] = useState<number>(getMonth(today) + 1);
  const [sortKey, setSortKey] = useState<
    | 'APPLICATION_ID_ASC'
    | 'APPLICATION_ID_DESC'
    | 'APPLICATION_TYPE_ASC'
    | 'APPLICATION_TYPE_DESC'
    | 'MEMBER_CD_ASC'
    | 'MEMBER_CD_DESC'
    | 'MEMBER_NAME_ASC'
    | 'MEMBER_NAME_DESC'
    | 'EMPLOYEE_CD_ASC'
    | 'EMPLOYEE_CD_DESC'
    | 'APPLICATION_DATE_ASC'
    | 'APPLICATION_DATE_DESC'
    | 'APPLICATION_STATUS_ASC'
    | 'APPLICATION_STATUS_DESC'
  >('APPLICATION_ID_DESC');
  const [status, setStatus] = useState<
    | 'OFFICER_APPROVED'
    | 'BACKOFFICER_APPROVED'
    | 'BACKOFFICER_REJECTED'
    | ''
    | undefined
  >();
  const { associationCd = '' } = useRecoilValue(SelectedAssociationState);

  const [applicationsParams, setApplicationsParams] = useState({
    ...initialState,
    associationCd,
  });
  // pages
  const [page, setPage] = useRecoilState(pageState);
  const [, setPerPageSize] = useRecoilState(perPageSizeState);
  const [, setTotalPage] = useRecoilState(totalPageState);
  const [, setTotalSize] = useRecoilState(totalSizeState);
  useHelmetHandler({
    title: '新規会員登録 申請一覧',
  });

  const {
    data: applicationsData,
    applicationsError,
    // @ts-ignore
  } = useApplications(applicationsParams);

  const onClickSearch = (
    value: string,
    _type?:
      | 'MONTHLY_UNIT'
      | 'BONUS_UNIT'
      | 'PART'
      | 'RECESS'
      | 'RESUME'
      | 'WITHDRAWAL',
    _status?:
      | 'OFFICER_APPROVED'
      | 'BACKOFFICER_APPROVED'
      | 'BACKOFFICER_REJECTED'
      | ''
  ) => {
    setKeywordValue(value);
    setStatus(_status);
    const selectedDateValue = Number(
      String(year) + String(convertParamMonth(month))
    );
    // 検索
    const body = {
      associationCd,
      yearMonth: selectedDateValue,
      eventType: 'ADMISSION',
      keyword: value,
      page: 1,
      perPageSize: 20,
      sortKey,
      applicationType: _type,
      applicationStatus: _status,
    };
    setApplicationsParams(body);

    const param = createSearchParams({});

    appendToQueryString(param, 'keyword', value);
    appendToQueryString(param, 'applicationType', _type);
    appendToQueryString(param, 'applicationStatus', _status);
    navigate({
      pathname: location.pathname,
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      search: `?${param}`,
    });
  };

  useEffect(() => {
    if (selectedFilter.value) {
      const newStatus = convertApplicationStatusTextToCode(
        selectedFilter.value as ApplicationStatusText
      );
      const body = {
        ...applicationsParams,
        applicationStatus: newStatus,
      };
      setStatus(newStatus);
      setApplicationsParams(body);
    }
  }, [selectedFilter]);

  useEffect(() => {
    const body = {
      ...applicationsParams,
      yearMonth: Number(String(year) + String(convertParamMonth(month))),
      page: 1,
    };
    setApplicationsParams(body);
  }, [year, month]);

  const handlePageChange = useCallback(() => {
    setApplicationsParams({
      ...applicationsParams,
      page,
    });
  }, [page]);

  useEffect(() => {
    void handlePageChange();
  }, [handlePageChange]);

  useEffect(() => {
    onClickSearch(keywordValue, undefined, status);
  }, [sortKey]);

  useEffect(() => {
    if (applicationsData) {
      setPage(applicationsData.currentPage || 1);
      setPerPageSize(applicationsData.perPageSize || 10);
      setTotalPage(applicationsData.totalPage || 0);
      setTotalSize(applicationsData.totalSize || 0);
    }
  }, [applicationsData]);

  return (
    <ApplicationsAdmissionIndex
      type="admission"
      data={applicationsData ? applicationsData.applications : []}
      keywordValue={keywordValue}
      setSelectedFilter={setSelectedFilter}
      onClickSearch={onClickSearch}
      year={year}
      month={month}
      setYear={setYear}
      setMonth={setMonth}
      applicationsError={applicationsError}
      sortKey={sortKey}
      updateSortKey={(value) => {
        setSortKey(value);
      }}
    />
  );
};
