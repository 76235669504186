/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Box, Typography, TextField } from '@mui/material';
import { FC } from 'react';
import { WorkReportInputs as Inputs } from '@/types/components/Inputs';
import {
  Control,
  Controller,
  FieldErrorsImpl,
  UseFormRegister,
} from 'react-hook-form';
import { GetAssociation } from '@/types/api/associations';
import { SelectBox } from '@/components/atoms/Input/SelectBox';
import { monthsWithSelect } from '@/constants/SelectBox/choicesForSelectBox';
import { FindWorkreportsById } from '@/types/api/workreports';
import { theme } from '@/theme';
import { TableContainer } from './TableContainer';
import { Row } from './Row';
import { Td } from './Td';
import { Th } from './Th';

interface Props {
  control: Control<Inputs>;
  isSubmit: 'BEFORESUBMISSION' | 'SUBMISSION' | undefined;
  modalStatus: boolean;
  data: Inputs;
  report: Required<FindWorkreportsById>;
  errors: FieldErrorsImpl<Inputs>;
  register: UseFormRegister<Inputs>;
  association?: GetAssociation;
}

const priceAdjustment = (price: number): number => {
  let adjustedPrice = price;
  if (adjustedPrice !== 0) {
    adjustedPrice /= 100;
  }
  return adjustedPrice;
};

export const DividendContact: FC<Props> = ({
  control,
  isSubmit,
  modalStatus,
  data,
  report,
  errors,
  register,
  association,
}) => {
  const { dividend } = report;
  const adjustedDividendOneStock = priceAdjustment(
    dividend?.dividendOneStock ?? 0
  );
  const getIsAnyError = () =>
    !!(
      (data.rightedMonthUnit !== '' && !!errors?.rightedMonthUnit?.message) ||
      (data.dividendIncome !== '' && !!errors?.dividendIncome?.message) ||
      (data.dividendPerUnit !== '' && !!errors?.dividendPerUnit?.message)
    );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '8px',
      }}
    >
      <Typography variant="h5" sx={{ color: 'system.text-normal' }}>
        配当金連絡
      </Typography>
      <TableContainer sxProps={{ width: '424px' }}>
        {isSubmit === 'SUBMISSION' ? (
          <>
            <Row>
              <Th
                variant="regular"
                bgcolor="secondary.bg"
                width="184px"
                height="48px"
                borderRight
              >
                権利月
              </Th>
              <Td width="240px" height="48px" align="right">
                {`${dividend?.rightMonth ?? '-'}月`}
              </Td>
            </Row>
            <Row>
              <Th
                variant="regular"
                bgcolor="secondary.bg"
                width="184px"
                height="48px"
                borderRight
              >
                権利付株数
              </Th>
              <Td width="240px" height="48px" align="right">
                {`${dividend?.rightMonthStockCount?.toLocaleString() ?? '-'}株`}
              </Td>
            </Row>
            <Row>
              <Th
                variant="regular"
                bgcolor="secondary.bg"
                width="184px"
                height="48px"
                borderRight
              >
                一株あたり配当金
              </Th>
              <Td width="240px" height="48px" align="right">
                {`${adjustedDividendOneStock.toLocaleString() ?? '-'}円`}
              </Td>
            </Row>
            <Row borderBottom={false}>
              <Th
                variant="regular"
                bgcolor="secondary.bg"
                width="184px"
                height="48px"
                borderRight
              >
                受取配当金(税引後)
              </Th>
              <Td width="240px" height="48px" align="right">
                {`${dividend?.receiptDividend?.toLocaleString() ?? '-'}円`}
              </Td>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Th
                variant="regular"
                bgcolor="secondary.bg"
                width="184px"
                height="48px"
                borderRight
              >
                権利月
                <Typography color="states.error" fontSize="12px">
                  ※必須
                </Typography>
              </Th>
              <Td width="240px" height="48px" align="right">
                {modalStatus ? (
                  <>{data.rightedMonth || ''}月</>
                ) : (
                  <Box display="flex" gap="8px" alignItems="center">
                    <Controller
                      name="rightedMonth"
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          width="72px"
                          {...field}
                          items={monthsWithSelect()}
                        />
                      )}
                    />
                    月
                  </Box>
                )}
              </Td>
            </Row>
            <Row>
              <Th
                variant="regular"
                bgcolor="secondary.bg"
                width="184px"
                height="48px"
                borderRight
              >
                権利付株数
                <Typography color="states.error" fontSize="12px">
                  ※必須
                </Typography>
              </Th>
              <Td width="240px" height="48px" align="right">
                {modalStatus ? (
                  <>{Number(data.rightedMonthUnit).toLocaleString() || ''}株</>
                ) : (
                  <Box display="flex" gap="8px" alignItems="center">
                    <Controller
                      name="rightedMonthUnit"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          {...register('rightedMonthUnit')}
                          sx={{ width: '192px' }}
                          inputProps={{ style: { textAlign: 'right' } }}
                          placeholder="例：1,000.123"
                        />
                      )}
                    />
                    株
                  </Box>
                )}
              </Td>
            </Row>
            <Row>
              <Th
                variant="regular"
                bgcolor="secondary.bg"
                width="184px"
                height="48px"
                borderRight
              >
                一株あたり配当金
                <Typography color="states.error" fontSize="12px">
                  ※必須
                </Typography>
              </Th>
              <Td width="240px" height="48px" align="right">
                {modalStatus ? (
                  <>{Number(data.dividendPerUnit).toLocaleString() || ''}円</>
                ) : (
                  <Box display="flex" gap="8px" alignItems="center">
                    <Controller
                      name="dividendPerUnit"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          {...register('dividendPerUnit')}
                          sx={{ width: '192px' }}
                          inputProps={{ style: { textAlign: 'right' } }}
                          placeholder="例：1,000"
                        />
                      )}
                    />
                    円
                  </Box>
                )}
              </Td>
            </Row>
            <Row borderBottom={false}>
              <Th
                variant="regular"
                bgcolor="secondary.bg"
                width="184px"
                height="48px"
                borderRight
              >
                受取配当金(税引後)
                <Typography color="states.error" fontSize="12px">
                  ※必須
                </Typography>
              </Th>
              <Td width="240px" height="48px" align="right">
                {modalStatus ? (
                  <>{Number(data.dividendIncome).toLocaleString() || ''}円</>
                ) : (
                  <Box display="flex" gap="8px" alignItems="center">
                    <Controller
                      name="dividendIncome"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          {...register('dividendIncome')}
                          sx={{ width: '192px' }}
                          inputProps={{ style: { textAlign: 'right' } }}
                          placeholder="例：1,000"
                        />
                      )}
                    />
                    円
                  </Box>
                )}
              </Td>
            </Row>
          </>
        )}
      </TableContainer>
      {isSubmit === 'BEFORESUBMISSION' && !modalStatus && getIsAnyError() && (
        <Typography sx={{ color: theme.palette.states.error }}>
          *半角数字で入力してください
        </Typography>
      )}
    </Box>
  );
};
