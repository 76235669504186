/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { FindTradereportsByID } from '@/types/api/tradereports';
import { TableContainer } from './TableContainer';
import { Row } from './Row';
import { Td } from './Td';
import { Th } from './Th';

interface Props {
  report: Required<FindTradereportsByID>;
}

export const Dividend: FC<Props> = ({ report }) => {
  const {
    dividendTotal,
    incomeTaxTotal,
    residentTaxTotal,
    excludingTaxDividend,
  } = report.dividend as Required<Props['report']['dividend']>;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '8px',
      }}
    >
      <Typography variant="h5" sx={{ color: 'system.text-normal' }}>
        配当金情報
      </Typography>
      <TableContainer width="444px">
        <Row>
          <Th variant="regular" bgcolor="secondary.bg" width="184px">
            配当金総額
          </Th>
          <Td width="260px" align="right">
            {`${dividendTotal.toLocaleString()}円`}
          </Td>
        </Row>
        <Row>
          <Th variant="regular" bgcolor="secondary.bg" width="184px">
            所得税合計
          </Th>
          <Td width="260px" align="right">
            {`${incomeTaxTotal.toLocaleString()}円`}
          </Td>
        </Row>
        <Row>
          <Th variant="regular" bgcolor="secondary.bg" width="184px">
            住民税合計
          </Th>
          <Td width="260px" align="right">
            {`${residentTaxTotal.toLocaleString()}円`}
          </Td>
        </Row>
        <Row borderBottom={false}>
          <Th variant="regular" bgcolor="secondary.bg" width="184px">
            税引き後配当金
          </Th>
          <Td width="260px" align="right">
            {`${excludingTaxDividend.toLocaleString()}円`}
          </Td>
        </Row>
      </TableContainer>
    </Box>
  );
};
