import { theme } from '@/theme';
import { Box, Typography } from '@mui/material';
import { FC, useState } from 'react';

interface TopMessageProps {
  title: string;
  bg?: string;
  color?: string;
  message: string;
}

export const TopMessage: FC<TopMessageProps> = ({
  title,
  color,
  bg,
  message,
}) => {
  const [isOpenMessage, setIsOpenMessage] = useState(false);

  const handleOpen = () => {
    setIsOpenMessage(!isOpenMessage);
  };
  return (
    <Box sx={{ background: bg, padding: theme.spacing(1, 2) }}>
      <Box display="flex" justifyContent="space-between" color={color}>
        <Typography variant="body-sub/bold" maxWidth="988px">
          {title}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          onClick={handleOpen}
          ml={2}
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          <Box display="flex" alignItems="center">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {!isOpenMessage && (
                <path d="M8 2V14" stroke={color} strokeWidth="2" />
              )}
              <path d="M14 8L2 8" stroke={color} strokeWidth="2" />
            </svg>
          </Box>
          <Typography
            ml={0.5}
            variant="caption/regular"
            sx={{ whiteSpace: 'nowrap' }}
          >
            メッセージを開く
          </Typography>
        </Box>
      </Box>
      {isOpenMessage && (
        <Box mt={1}>
          <Typography
            variant="body-sub/regular"
            sx={{ whiteSpace: 'pre-wrap' }}
          >
            {message}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
