import { CancelButton } from '@/components/atoms/Buttons/CancelButton';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';

interface ResetProps {
  title: string;
  question: string;
  name: string;
  description: ReactNode[];
  code?: string;
  permission?: string;
  handleCancel: () => void;
  handleReset: () => void;
}

const styleContainer: SxProps<Theme> = [
  {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    width: '520px',
    p: '32px',
  },
];

const Label: FC<{ children: string | undefined }> = ({ children }) => (
  <Typography variant="body-main/regular" sx={{ color: 'system.text-normal' }}>
    {children}
  </Typography>
);

const Value: FC<{ children: string | undefined }> = ({ children }) => (
  <Typography variant="body-main/medium" sx={{ color: 'system.text-normal' }}>
    {children}
  </Typography>
);

const ItemWrapper: FC<{ children: ReactNode }> = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '8px',
    }}
  >
    {children}
  </Box>
);

export const Reset: FC<ResetProps> = ({
  title,
  question,
  name,
  description,
  code,
  permission,
  handleCancel,
  handleReset,
}) => (
  <Box sx={styleContainer}>
    <Typography variant="h4" sx={{ color: 'system.text-normal' }}>
      {title}
    </Typography>
    <Typography
      component="p"
      variant="body-main/regular"
      sx={{ color: 'system.text-normal' }}
    >
      {question}
    </Typography>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        p: '16px',
        gap: '16px',
        width: '456px',
        bgcolor: 'system.background',
        borderRadius: '4px',
      }}
    >
      <ItemWrapper>
        <Label>名前</Label>
        <Label>{typeof code === 'string' ? '会員コード' : '権限'}</Label>
      </ItemWrapper>
      <ItemWrapper>
        <Value>{name}</Value>
        <Value>{typeof code === 'string' ? code : permission}</Value>
      </ItemWrapper>
    </Box>
    <Typography
      variant="body-main/regular"
      sx={{ color: 'system.text-normal' }}
    >
      {description.map((v) => v)}
    </Typography>
    <Box sx={{ display: 'flex', gap: '16px' }}>
      <CancelButton
        sx={{
          width: '112px',
          height: '41px',
          whiteSpace: 'nowrap',
        }}
        onClick={handleCancel}
      >
        キャンセル
      </CancelButton>
      <SecondaryButton
        sx={{
          width: '128px',
          height: '41px',
          whiteSpace: 'nowrap',
        }}
        onClick={handleReset}
      >
        リセットする
      </SecondaryButton>
    </Box>
  </Box>
);
