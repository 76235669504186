import { MemberById } from '@/types/api/member';
import { atom } from 'recoil';

export const memberInfoState = atom({
  key: 'memberInfo', // unique ID (with respect to other atoms/selectors)
  default: undefined as MemberById | undefined,
});

export const memberContributionState = atom({
  key: 'memberContribution',
  default: undefined as
    | {
        associationCd?: string;
        memberCd?: string;
        contributionStartDate?: string;
        monthlyUnit?: number;
        monthlyRecessStartDate?: string;
        monthlyRecessEndDate?: string;
        bonusUnit?: number;
        bonusRecessClassification?: string;
        bonusRate?: number;
      }
    | undefined,
});

export const memberSecuritiesAccountState = atom({
  key: 'memberSecuritiesAccount',
  default: undefined as
    | {
        branchCd?: string;
        branchName?: string;
        accountNumber?: string;
      }
    | undefined,
});
