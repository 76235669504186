import { CancelButton } from '@/components/atoms/Buttons/CancelButton';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { SubmitButton } from '@/components/atoms/Buttons/SubmitButton';
import { OfficeUserEditInputs as Inputs } from '@/types/components/OfficeUser';
import { Box, Divider, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FC, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import {
  AuthorityClassification,
  ProxyApplicationClassification,
} from '@/constants/OfficeUser/officeUsersIndex';
import {
  switchAuthorityLabel,
  switchProxyApplicationLabel,
} from '@/utils/officeUser';
import { BasicModal, Reset } from '@/components/molecules/Modal';
import { Ok } from '@/components/molecules/Modal/Ok';
import { DeleteOfficeUser } from '@/components/molecules/Modal/DeleteOfficeUser';
// import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { OfficeUserDetail } from '@/types/api/officeUsers';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { OfficeUserCreateHead } from './OfficeUserCreateHead';
import { Label, StyledStack } from './StyledComponent';

interface NewOfficeUserCreateProps {
  onReset: () => void;
  onSubmit: SubmitHandler<Inputs>;
  onDelete: () => void;
  handleClickCancel: () => void;
  handleClickConfirm: () => void;
  handleClickComplete: () => void;
  isConfirmed: boolean;
  user: OfficeUserDetail;
  association: Exclude<
    OfficeUserDetail['officeUserAssociations'],
    undefined
  >[number];
  modal: string;
  setModal: (modal: string) => void;
  associationName: string;
}

export const OfficeUserEdit: FC<NewOfficeUserCreateProps> = ({
  onReset,
  onSubmit,
  onDelete,
  handleClickCancel,
  handleClickConfirm,
  handleClickComplete,
  isConfirmed,
  user,
  association,
  modal,
  setModal,
  associationName,
}) => {
  const { control, handleSubmit, getValues, reset } = useForm<Inputs>({
    defaultValues: {
      authorityClassification: 'GENERAL',
      proxyApplicationClassification: 'IMPOSSIBLE',
    },
  });
  useHelmetHandler({
    title: isConfirmed
      ? '持株会事務局員情報変更 内容確認'
      : '持株会事務局員情報変更',
  });

  useEffect(() => {
    reset({
      authorityClassification: association.authorityClassification,
      proxyApplicationClassification:
        association.proxyApplicationClassification,
    });
  }, [association]);

  return (
    <>
      <OfficeUserCreateHead
        title="担当者情報を編集"
        isConfirmed={isConfirmed}
        confirmedSubText="以下の内容で登録します。"
        removeButtonText="担当者を持株会から外す"
        setModal={setModal}
      />
      <Divider />
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <StyledStack>
          <Box display="flex" gap={1} pb={2}>
            <Label>氏名</Label>
            {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
            <Typography>{`${user?.lastNameKanji} ${user?.firstNameKanji}`}</Typography>
          </Box>
          <Divider />
          <Box
            py={1}
            display="flex"
            gap={1}
            alignItems="center"
            minHeight="58px"
          >
            <Label>権限</Label>
            {isConfirmed ? (
              <Typography>
                {switchAuthorityLabel(getValues('authorityClassification'))}
              </Typography>
            ) : (
              <Controller
                name="authorityClassification"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} row name="authorityClassification">
                    {Object.values(AuthorityClassification).map((value) => (
                      <FormControlLabel
                        key={value}
                        value={value}
                        control={<Radio />}
                        label={switchAuthorityLabel(value)}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            )}
          </Box>
          <Divider />
          <Box
            py={1}
            display="flex"
            gap={1}
            alignItems="center"
            minHeight="58px"
          >
            <Label>代理申請</Label>
            {isConfirmed ? (
              <Typography>
                {switchProxyApplicationLabel(
                  getValues('proxyApplicationClassification')
                )}
              </Typography>
            ) : (
              <Controller
                name="proxyApplicationClassification"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    name="proxyApplicationClassification"
                  >
                    {Object.values(ProxyApplicationClassification).map(
                      (value) => (
                        <FormControlLabel
                          key={value}
                          value={value}
                          control={<Radio />}
                          label={switchProxyApplicationLabel(value)}
                        />
                      )
                    )}
                  </RadioGroup>
                )}
              />
            )}
          </Box>
          <Divider />
          <Box py={2} display="flex">
            <Label>ログインID</Label>
            <Box>{user.email}</Box>
          </Box>
          <Divider />
          {!isConfirmed && (
            <>
              <Box py={2} display="flex">
                <Label>パスワード</Label>
                ****************
                {/* <NormalLink onClick={() => setModal('reset')}>
                  パスワードをリセット
                </NormalLink> */}
              </Box>
              <Divider />
            </>
          )}
          <Box display="flex" gap={2} mt={4}>
            <CancelButton onClick={handleClickCancel}>
              {isConfirmed ? '編集へ戻る' : 'キャンセル'}
            </CancelButton>
            {isConfirmed ? (
              <SubmitButton type="submit">登録する</SubmitButton>
            ) : (
              <PrimaryButton onClick={() => handleClickConfirm()}>
                確認へ進む
              </PrimaryButton>
            )}
          </Box>
        </StyledStack>
      </Box>
      <BasicModal
        open={modal !== ''}
        handleClose={() => setModal('')}
        hasScroll={false}
      >
        {modal === 'reset' && (
          <Reset
            title="パスワードをリセット"
            question="この担当者のパスワードをリセットしますか？"
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            name={`${user.lastNameKanji ?? ''} ${user.firstNameKanji ?? ''}`}
            permission={switchAuthorityLabel(
              association.authorityClassification
            )}
            description={[
              '登録メールアドレス宛に',
              <br key="br" />,
              'リセット用URLを記載したメールが送られます。',
            ]}
            handleCancel={() => setModal('')}
            handleReset={onReset}
          />
        )}
        {modal === 'delete' && (
          <DeleteOfficeUser
            associationName={associationName}
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            name={`${user.lastNameKanji ?? ''} ${user.firstNameKanji ?? ''}`}
            permission={switchAuthorityLabel(
              association.authorityClassification
            )}
            handleCancel={() => setModal('')}
            handleDelete={onDelete}
          />
        )}
        {modal === 'reset-complete' && (
          <Ok handleClick={handleClickComplete}>
            パスワードをリセットしました。
          </Ok>
        )}
        {modal === 'delete-complete' && (
          <Ok handleClick={handleClickComplete}>
            担当者を持株会から外しました。
          </Ok>
        )}
      </BasicModal>
    </>
  );
};
