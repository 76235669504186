import { TextInput } from '@/components/atoms/Input/TextInput';
import {
  PrimaryTableBodyTdMulti,
  PrimaryTableBodyTdSub,
} from '@/components/atoms/Table/PrimaryTableBodyTdMulti';
import { PrimaryTableBodyTdSingle } from '@/components/atoms/Table/PrimaryTableBodyTdSingle';
import { PrimaryTable } from '@/components/molecules/Table/PrimaryTable';
import { CreateApplication } from '@/types/api/applications';
import { GetAssociationsContribution } from '@/types/api/associations';
import { FindMemberContribution } from '@/types/api/members';
import { BountyCalculation } from '@/utils/calculation';
import { numberFormat } from '@/utils/numberFormat';
import { IsNumeric } from '@/utils/stringProcessor';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { FC, ChangeEvent, useEffect } from 'react';
import { Controller, useController, UseFormReturn } from 'react-hook-form';

type Props = {
  isConfirmed: boolean;
  contribution: GetAssociationsContribution & FindMemberContribution;
  methods: UseFormReturn<CreateApplication>;
  setIsUnit: (isUnit: boolean) => void;
};

const StyledTextInput: FC<TextFieldProps> = ({ name = '', ...props }) => {
  const {
    field: { ref, ...rest },
  } = useController({ name });

  return <TextInput inputRef={ref} {...rest} {...props} />;
};

const MuiRadioGroup = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'row',
});

export const MemberProxyApplicationBodyPay: FC<Props> = ({
  isConfirmed,
  contribution,
  methods,
  setIsUnit,
}) => {
  const watchBonusRate = methods.watch('applicationBonusUnit.bonusRate') ?? 0;
  const watchIsBonus = methods.watch('applicationBonusUnit.isBonusUnit') ?? 0;
  const watchBonusUnit = methods.watch('applicationBonusUnit.bonusUnit') ?? 0;
  const watchMonthlyUnit =
    methods.watch('applicationMonthlyUnit.monthlyUnit') ?? 0;

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    isBonus?: boolean
  ) => {
    let amount = '';
    const maxUnit = isBonus
      ? contribution.maxBonusUnit
      : contribution.maxMonthlyUnit;
    if (IsNumeric(e.target.value)) {
      amount = Math.floor(
        Math.min(Number(e.target.value), maxUnit || 0)
      ).toString();
    }
    if (isBonus) {
      methods.setValue('applicationBonusUnit.bonusUnit', Number(amount) ?? 0);
    } else {
      if (
        contribution.bonusMultiplierDesignationType === 'FIXED_MAGNIFICATION'
      ) {
        const multiplier = Number(contribution.bonusMultiplierDetails) || 0;
        methods.setValue(
          'applicationBonusUnit.bonusUnit',
          Number(amount) * multiplier ?? 0
        );
      }
      if (contribution.bonusMultiplierDesignationType === 'MAGNIFICATION') {
        methods.setValue(
          'applicationBonusUnit.bonusUnit',
          Number(amount) * watchBonusRate ?? 0
        );
      }
      methods.setValue(
        'applicationMonthlyUnit.monthlyUnit',
        Number(amount) ?? 0
      );
    }

    if (
      (isBonus && Number(amount) === 0) ||
      Number(amount) === contribution.bonusUnit
    ) {
      setIsUnit(false);
    } else if (
      Number(amount) === 0 ||
      Number(amount) === contribution.monthlyUnit
    ) {
      setIsUnit(false);
    } else {
      setIsUnit(true);
    }
  };

  useEffect(() => {
    let result = true;
    if (watchIsBonus) {
      switch (contribution.bonusMultiplierDesignationType) {
        case 'UNIT_NUMBER':
          result =
            (Number(watchMonthlyUnit) !== 0 &&
              Number(watchMonthlyUnit) !== contribution.monthlyUnit) ||
            (Number(watchBonusUnit) !== 0 &&
              Number(watchBonusUnit) !== contribution.bonusUnit);
          break;
        case 'FIXED_MAGNIFICATION':
          result =
            Number(watchMonthlyUnit) !== 0 &&
            Number(watchMonthlyUnit) !== contribution.monthlyUnit;
          break;
        case 'MAGNIFICATION':
          result =
            (Number(watchMonthlyUnit) !== 0 &&
              Number(watchMonthlyUnit) !== contribution.monthlyUnit) ||
            (Number(watchBonusUnit) !== 0 &&
              Number(watchBonusUnit) !== Number(contribution.bonusUnit));
          break;
        default:
          break;
      }
    } else {
      result =
        (Number(watchMonthlyUnit) !== 0 &&
          Number(watchMonthlyUnit) !== contribution.monthlyUnit) ||
        watchIsBonus !== (contribution.bonusUnit !== 0);
    }
    setIsUnit(result);
  }, [contribution, watchMonthlyUnit, watchBonusUnit, watchIsBonus]);

  return (
    <PrimaryTable title="新たな口数設定">
      <PrimaryTableBodyTdMulti title="1口あたり金額" row={1}>
        {isConfirmed ? (
          <Typography>
            {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call */}
            {`${(contribution.unitAmount ?? 0).toLocaleString()}円`}
          </Typography>
        ) : (
          <Typography variant="body-main/bold">
            {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call */}
            {`${(contribution.unitAmount ?? 0).toLocaleString()}円`}
          </Typography>
        )}
      </PrimaryTableBodyTdMulti>
      <PrimaryTableBodyTdMulti title="奨励金率" row={1}>
        {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call */}
        <Typography>{`${(
          contribution.monthlyIncentiveRatio ?? 0
        ).toLocaleString()}%`}</Typography>
      </PrimaryTableBodyTdMulti>
      <PrimaryTableBodyTdMulti title="給与" subTitle="※毎月の拠出" row={3}>
        <PrimaryTableBodyTdSub title="口数">
          {isConfirmed ? (
            <Typography>
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              {`${methods.getValues('applicationMonthlyUnit.monthlyUnit')}口`}
            </Typography>
          ) : (
            <Box display="flex" alignItems="center" gap={1}>
              <StyledTextInput
                type="number"
                value={watchMonthlyUnit}
                name="applicationMonthlyUnit.monthlyUnit"
                sx={{ width: 80 }}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <Typography>口</Typography>
              <Typography variant="body-sub/regular" color="system.text-light">
                ※最大
                {numberFormat(contribution.maxMonthlyUnit || 0)}口
              </Typography>
            </Box>
          )}
        </PrimaryTableBodyTdSub>
      </PrimaryTableBodyTdMulti>
      <PrimaryTableBodyTdSingle>
        <PrimaryTableBodyTdSub title="拠出金額">
          {isConfirmed ? (
            <Typography>
              {`${(
                (contribution.unitAmount ?? 0) * watchMonthlyUnit
              ).toLocaleString()} 円`}
            </Typography>
          ) : (
            <Typography
              fontWeight={500}
              fontStyle="normal"
              fontSize={22}
              lineHeight="150%"
            >
              {`${(
                (contribution.unitAmount ?? 0) * watchMonthlyUnit
              ).toLocaleString()} 円`}
            </Typography>
          )}
        </PrimaryTableBodyTdSub>
      </PrimaryTableBodyTdSingle>
      <PrimaryTableBodyTdSingle>
        <PrimaryTableBodyTdSub title="奨励金額">
          {isConfirmed ? (
            <Typography>
              {`${(
                ((contribution.unitAmount ?? 0) *
                  watchMonthlyUnit *
                  (contribution.monthlyIncentiveRatio ?? 0)) /
                100
              ).toLocaleString()} 円`}
            </Typography>
          ) : (
            <Typography
              fontWeight={500}
              fontStyle="normal"
              fontSize={22}
              lineHeight="150%"
            >
              {`${BountyCalculation(
                'MONTHLY',
                contribution,
                watchMonthlyUnit
              )} 円`}
            </Typography>
          )}
        </PrimaryTableBodyTdSub>
      </PrimaryTableBodyTdSingle>
      {contribution.bonusReserveClassification !== 'NONE' && (
        <>
          <PrimaryTableBodyTdMulti title="賞与" row={4}>
            {isConfirmed ? (
              <PrimaryTableBodyTdSub title="拠出">
                <Typography>{watchIsBonus ? 'する' : 'しない'}</Typography>
              </PrimaryTableBodyTdSub>
            ) : (
              <PrimaryTableBodyTdSub title="拠出">
                <FormControl>
                  <MuiRadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="yes"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      checked={watchIsBonus === true}
                      control={<Radio />}
                      label="する"
                      onClick={() => {
                        methods.setValue(
                          'applicationBonusUnit.isBonusUnit',
                          true
                        );
                        if (
                          contribution.bonusMultiplierDesignationType ===
                          'UNIT_NUMBER'
                        )
                          methods.setValue(
                            'applicationBonusUnit.bonusUnit',
                            contribution.bonusUnit
                          );
                        else
                          methods.setValue(
                            'applicationBonusUnit.bonusUnit',
                            watchMonthlyUnit *
                              (Number(contribution.bonusMultiplierDetails) || 1)
                          );
                        methods.setValue(
                          'applicationBonusUnit.bonusRate',
                          contribution.bonusRate
                        );

                        if (
                          contribution.bonusMultiplierDesignationType ===
                          'MAGNIFICATION'
                        ) {
                          const options = (
                            contribution.bonusMultiplierDetails || ''
                          ).split(',');
                          const desiredBonusRate =
                            contribution.bonusRate || Number(options[0]) || 1;
                          methods.setValue(
                            'applicationBonusUnit.bonusRate',
                            desiredBonusRate
                          );
                          methods.setValue(
                            'applicationBonusUnit.bonusUnit',
                            watchMonthlyUnit * desiredBonusRate
                          );
                        }
                      }}
                    />
                    <FormControlLabel
                      checked={watchIsBonus === false}
                      control={<Radio />}
                      label="しない"
                      onClick={() =>
                        methods.setValue(
                          'applicationBonusUnit.isBonusUnit',
                          false
                        )
                      }
                    />
                  </MuiRadioGroup>
                </FormControl>
              </PrimaryTableBodyTdSub>
            )}
          </PrimaryTableBodyTdMulti>
          {watchIsBonus && (
            <>
              <PrimaryTableBodyTdSingle>
                <PrimaryTableBodyTdSub
                  title={
                    contribution.bonusMultiplierDesignationType ===
                    'FIXED_MAGNIFICATION'
                      ? '倍率'
                      : '口数'
                  }
                >
                  {contribution.bonusMultiplierDesignationType ===
                    'UNIT_NUMBER' && (
                    <Box>
                      {isConfirmed ? (
                        <Box>
                          <Typography variant="body-sub/regular">
                            {`${String(
                              methods.getValues(
                                'applicationBonusUnit.bonusUnit'
                              )
                            )}口`}
                          </Typography>
                        </Box>
                      ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Controller
                            name="applicationBonusUnit.bonusUnit"
                            control={methods.control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                sx={{ width: '80px' }}
                                onChange={(e) => handleChange(e, true)}
                                inputProps={{
                                  sx: {
                                    padding: '8px',
                                    textAlign: 'right',
                                  },
                                }}
                              />
                            )}
                          />

                          <Typography
                            sx={{ marginLeft: '8px', marginRight: '30px' }}
                            variant="body-sub/regular"
                          >
                            口
                          </Typography>
                        </Box>
                      )}
                      <Typography
                        variant="body-sub/regular"
                        sx={{ color: 'system.text-light' }}
                      >
                        ※最大{numberFormat(contribution.maxBonusUnit || 0)}口
                      </Typography>
                    </Box>
                  )}
                  {contribution.bonusMultiplierDesignationType ===
                    'MAGNIFICATION' && (
                    <Box>
                      {isConfirmed ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {`${String(
                            methods.getValues('applicationBonusUnit.bonusRate')
                          )}倍`}
                        </Box>
                      ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <MuiRadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="yes"
                            name="radio-buttons-group"
                          >
                            {(contribution.bonusMultiplierDetails || '')
                              .split(',')
                              .map((i) => (
                                <FormControlLabel
                                  value={i}
                                  control={<Radio />}
                                  checked={Number(i) === watchBonusRate}
                                  label={`${i}倍`}
                                  onClick={() => {
                                    methods.setValue(
                                      'applicationBonusUnit.bonusRate',
                                      Number(i)
                                    );
                                    methods.setValue(
                                      'applicationBonusUnit.bonusUnit',
                                      watchMonthlyUnit * Number(i) ?? 0
                                    );
                                  }}
                                />
                              ))}
                          </MuiRadioGroup>
                        </Box>
                      )}
                    </Box>
                  )}
                  {contribution.bonusMultiplierDesignationType ===
                    'FIXED_MAGNIFICATION' && (
                    <Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          sx={{ marginLeft: '8px', marginRight: '16px' }}
                          variant="body-sub/bold"
                        >
                          {Number(contribution.bonusMultiplierDetails)}倍
                        </Typography>
                        <Typography
                          variant="body-sub/regular"
                          sx={{ color: 'system.text-light' }}
                        >
                          ※給与（毎月の拠出）対比
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </PrimaryTableBodyTdSub>
              </PrimaryTableBodyTdSingle>
              <PrimaryTableBodyTdSingle>
                {contribution.bonusMultiplierDesignationType ===
                  'UNIT_NUMBER' && (
                  <PrimaryTableBodyTdSub title="拠出金額">
                    {isConfirmed ? (
                      <Typography>
                        {`${(
                          (contribution.unitAmount ?? 0) *
                          (!Number.isNaN(
                            Number(
                              methods.watch('applicationBonusUnit.bonusUnit')
                            )
                          )
                            ? Number(
                                methods.watch('applicationBonusUnit.bonusUnit')
                              )
                            : 0)
                        ).toLocaleString()} 円`}
                      </Typography>
                    ) : (
                      <Typography
                        fontWeight={500}
                        fontStyle="normal"
                        fontSize={22}
                        lineHeight="150%"
                      >
                        {`${(
                          (contribution.unitAmount ?? 0) *
                          (!Number.isNaN(
                            Number(
                              methods.watch('applicationBonusUnit.bonusUnit')
                            )
                          )
                            ? Number(
                                methods.watch('applicationBonusUnit.bonusUnit')
                              )
                            : 0)
                        ).toLocaleString()} 円`}
                      </Typography>
                    )}
                  </PrimaryTableBodyTdSub>
                )}
                {contribution.bonusMultiplierDesignationType ===
                  'MAGNIFICATION' && (
                  <PrimaryTableBodyTdSub title="拠出金額">
                    {isConfirmed ? (
                      <Typography>
                        {`${(
                          (contribution.unitAmount ?? 0) *
                          (Number(
                            methods.watch('applicationBonusUnit.bonusRate')
                          ) || 0) *
                          (watchMonthlyUnit || 0)
                        ).toLocaleString()} 円`}
                      </Typography>
                    ) : (
                      <Typography
                        fontWeight={500}
                        fontStyle="normal"
                        fontSize={22}
                        lineHeight="150%"
                      >
                        {`${(
                          (contribution.unitAmount ?? 0) *
                          (Number(
                            methods.watch('applicationBonusUnit.bonusRate')
                          ) || 0) *
                          (watchMonthlyUnit || 0)
                        ).toLocaleString()} 円`}
                      </Typography>
                    )}
                  </PrimaryTableBodyTdSub>
                )}
                {contribution.bonusMultiplierDesignationType ===
                  'FIXED_MAGNIFICATION' && (
                  <PrimaryTableBodyTdSub title="拠出金額">
                    {isConfirmed ? (
                      <Typography>
                        {`${(
                          (contribution.unitAmount ?? 0) *
                          (Number(contribution.bonusMultiplierDetails) || 0) *
                          (watchMonthlyUnit || 0)
                        ).toLocaleString()} 円`}
                      </Typography>
                    ) : (
                      <Typography
                        fontWeight={500}
                        fontStyle="normal"
                        fontSize={22}
                        lineHeight="150%"
                      >
                        {`${(
                          (contribution.unitAmount ?? 0) *
                          (Number(contribution.bonusMultiplierDetails) || 0) *
                          (watchMonthlyUnit || 0)
                        ).toLocaleString()} 円`}
                      </Typography>
                    )}
                  </PrimaryTableBodyTdSub>
                )}
              </PrimaryTableBodyTdSingle>
              <PrimaryTableBodyTdSingle>
                {contribution.bonusMultiplierDesignationType ===
                  'UNIT_NUMBER' && (
                  <PrimaryTableBodyTdSub title="奨励金額">
                    {isConfirmed ? (
                      <Typography>
                        {`${(
                          ((contribution.unitAmount ?? 0) *
                            (!Number.isNaN(
                              Number(
                                methods.watch('applicationBonusUnit.bonusUnit')
                              )
                            )
                              ? Number(
                                  methods.watch(
                                    'applicationBonusUnit.bonusUnit'
                                  )
                                )
                              : 0) *
                            (contribution.bonusIncentiveRatio || 0)) /
                          100
                        ).toLocaleString()} 円`}
                      </Typography>
                    ) : (
                      <Typography
                        fontWeight={500}
                        fontStyle="normal"
                        fontSize={22}
                        lineHeight="150%"
                      >
                        {`${(
                          ((contribution.unitAmount ?? 0) *
                            (!Number.isNaN(
                              Number(
                                methods.watch('applicationBonusUnit.bonusUnit')
                              )
                            )
                              ? Number(
                                  methods.watch(
                                    'applicationBonusUnit.bonusUnit'
                                  )
                                )
                              : 0) *
                            (contribution.bonusIncentiveRatio || 0)) /
                          100
                        ).toLocaleString()} 円`}
                      </Typography>
                    )}
                  </PrimaryTableBodyTdSub>
                )}
                {contribution.bonusMultiplierDesignationType ===
                  'MAGNIFICATION' && (
                  <PrimaryTableBodyTdSub title="奨励金額">
                    {isConfirmed ? (
                      <Typography>
                        {`${(
                          ((contribution.unitAmount ?? 0) *
                            (Number(
                              methods.watch('applicationBonusUnit.bonusRate')
                            ) || 0) *
                            (watchMonthlyUnit || 0) *
                            (contribution.bonusIncentiveRatio || 0)) /
                          100
                        ).toLocaleString()} 円`}
                      </Typography>
                    ) : (
                      <Typography
                        fontWeight={500}
                        fontStyle="normal"
                        fontSize={22}
                        lineHeight="150%"
                      >
                        {`${
                          ((contribution.unitAmount ?? 0) *
                            (Number(
                              methods.watch('applicationBonusUnit.bonusRate')
                            ) || 0) *
                            (watchMonthlyUnit || 0) *
                            (contribution.bonusIncentiveRatio || 0)) /
                          100
                        } 円`}
                      </Typography>
                    )}
                  </PrimaryTableBodyTdSub>
                )}
                {contribution.bonusMultiplierDesignationType ===
                  'FIXED_MAGNIFICATION' && (
                  <PrimaryTableBodyTdSub title="奨励金額">
                    {isConfirmed ? (
                      <Typography>
                        {`${(
                          ((contribution.unitAmount ?? 0) *
                            (Number(contribution.bonusMultiplierDetails) || 0) *
                            (watchMonthlyUnit || 0) *
                            (contribution.bonusIncentiveRatio ?? 0)) /
                          100
                        ).toLocaleString()} 円`}
                      </Typography>
                    ) : (
                      <Typography
                        fontWeight={500}
                        fontStyle="normal"
                        fontSize={22}
                        lineHeight="150%"
                      >
                        {`${
                          ((contribution.unitAmount ?? 0) *
                            (Number(contribution.bonusMultiplierDetails) || 0) *
                            (watchMonthlyUnit || 0) *
                            (contribution.bonusIncentiveRatio ?? 0)) /
                          100
                        } 円`}
                      </Typography>
                    )}
                  </PrimaryTableBodyTdSub>
                )}
              </PrimaryTableBodyTdSingle>
            </>
          )}
        </>
      )}
    </PrimaryTable>
  );
};
