/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable unused-imports/no-unused-vars */
import {
  LoginFormType,
  LoginTemplate,
} from '@/components/organisms/Login/LoginTemplate';
import { Path } from '@/constants/Router/path';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { appClient } from '@/services';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const Login: FC = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState<boolean>(false);
  const [lockError, setLockError] = useState<boolean>(false);
  useHelmetHandler({
    title: 'ログイン',
  });

  const handleLogin = async (data: LoginFormType) => {
    try {
      const response = await appClient.authorization.authSignin({
        loginId: data.loginId,
        loginPassword: data.loginPassword,
      });

      // 多要素認証へ
      if (response && response.Session) {
        navigate(Path.LOGIN_MFA, {
          state: {
            loginId: data.loginId,
            loginPassword: data.loginPassword,
            mfaSession: response.Session,
          },
        });
      } else {
        // パスワード認証へ
        navigate(Path.LOGIN_PASSWORD_SETTING, {
          state: {
            loginId: data.loginId,
          },
        });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.status === 408) {
        setLockError(true);
        setIsError(false);
      } else {
        setIsError(true);
        setLockError(false);
      }
    }
  };

  return (
    <LoginTemplate
      title="ログイン"
      isError={isError}
      lockError={lockError}
      handleLogin={handleLogin}
    />
  );
};
