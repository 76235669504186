/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { DownloadButton } from '@/components/atoms/Buttons/DownloadButton';
import {
  useListDividendPaymentCertificate,
  useListEquitySettlementForm,
} from '@/hooks/useReports';
import { useSnackbar } from '@/hooks/useSnackbar';
import { memberInfoState } from '@/recoil/memberInfo/memberInfo';
import { appClient } from '@/services';
import { theme } from '@/theme/theme';
import { Box, styled } from '@mui/material';
import { CSSProperties } from 'react';
import { useRecoilValue } from 'recoil';
import { MembersHeader } from './MembersHeader';

const Wrapper = styled(Box)({
  width: '100%',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: '32px 40px',
  gap: '24px',
});

const ReportWrapper = styled(Box)({
  borderRadius: '4px 4px 0 0',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.system['separator-light'],
  paddingBottom: 16,
});

const ReportInfoRow = styled(Box)({
  ...theme.typography['body-sub/regular'],
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px 16px',
  color: theme.palette.system['text-light'],
});

const ReportInfoRowHeadStyling: CSSProperties = {
  ...theme.typography['body-sub/medium'],
  backgroundColor: theme.palette.system.background,
  alignItems: 'center',
  borderRadius: '4px 4px 0 0',
  borderBottom: '1px solid',
  borderColor: theme.palette.system['separator-light'],
  overflow: 'hidden',
};

const ReportInfoGroup = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 16px',
});

const Border = styled('div')({
  width: '100%',
  margin: '12px 0',
  paddingTop: '1px',
  backgroundColor: 'rgba(0, 0, 0, 0.13)',
});

export const MemberDownload = () => {
  const memberData = useRecoilValue(memberInfoState);
  const { data: listDividendPaymentCertificates } =
    useListDividendPaymentCertificate(memberData?.memberId);
  const { data: listEquitySettlementForm } = useListEquitySettlementForm(
    memberData?.memberId
  );
  const { showSnackbar } = useSnackbar();
  const shouldRenderReports =
    listDividendPaymentCertificates?.reports?.length ||
    listEquitySettlementForm?.reports?.length ||
    memberData?.userStatus === 'WITHDRAWN';

  const downloadEequitySettlementFormReport = async (
    year: string,
    month: string,
    day: string
  ) => {
    const memberId = memberData?.memberId;
    if (memberId) {
      try {
        const res = await appClient.reports.equitySettlementFormReportsDl(
          memberId,
          year,
          month,
          day
        );
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const blob = new Blob([res], {
          type: 'application/pdf; charset=utf-8',
        });
        const url = URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        document.body.appendChild(anchor);
        anchor.download = 'download.pdf';
        anchor.href = url;
        anchor.click();
        anchor.remove();
        URL.revokeObjectURL(url);
      } catch (e) {
        // Handle downloadRequest Error
        showSnackbar('ダウンロードに失敗しました。');
      }
    }
  };

  const downloadDividendPaymentCertificateReport = async (reportId: number) => {
    const memberId = memberData?.memberId;
    if (memberId) {
      try {
        const res = await appClient.reports.dividendPaymentCertificateReportsDl(
          reportId || 0
        );
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const blob = new Blob([res], {
          type: 'application/pdf; charset=utf-8',
        });
        const url = URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        document.body.appendChild(anchor);
        anchor.download = 'download.pdf';
        anchor.href = url;
        anchor.click();
        anchor.remove();
        URL.revokeObjectURL(url);
      } catch (e) {
        // Handle downloadRequest Error
        showSnackbar('ダウンロードに失敗しました。');
      }
    }
  };

  const downloadWithdrawalSettlementFormReport = async (
    type: 'settlement' | 'dividend'
  ) => {
    const memberId = memberData?.memberId;
    if (memberId) {
      try {
        const res = await appClient.reports.withdrawalSettlementFormReportsDl(
          memberId,
          type || 'settlement'
        );

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const blob = new Blob([res], {
          type: 'application/pdf; charset=utf-8',
        });
        const url = URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        document.body.appendChild(anchor);
        anchor.download = 'download.pdf';
        anchor.href = url;
        anchor.click();
        anchor.remove();
        URL.revokeObjectURL(url);
      } catch (e) {
        // Handle downloadRequest Error
        showSnackbar('ダウンロードに失敗しました。');
      }
    }
  };

  const renderReportInfoHeader = (items: string[]) => (
    <ReportInfoRow sx={{ ...ReportInfoRowHeadStyling }}>
      <Box flex={2.5}>{items[0]}</Box>
      <Box flex={6}>{items[1]}</Box>
      <Box flex={1.5}>{items[2]}</Box>
    </ReportInfoRow>
  );

  const renderReportInfoRow = (
    title: string,
    description: string[],
    type: string,
    handleClickDownload: () => void,
    isBorder?: boolean
  ) => (
    <>
      {isBorder && <Border />}
      <ReportInfoRow>
        <Box
          flex={2.5}
          sx={{
            ...theme.typography['body-main/bold'],
            color: theme.palette.system['text-normal'],
          }}
        >
          {title}
        </Box>
        <Box
          flex={6}
          sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <Box>{description?.map((text) => text)}</Box>
        </Box>
        <Box flex={1.5}>
          <DownloadButton onClick={handleClickDownload} />
        </Box>
      </ReportInfoRow>
    </>
  );

  const renderSettlementInfoForms = () => {
    if (
      (listEquitySettlementForm &&
        listEquitySettlementForm.reports &&
        listEquitySettlementForm.reports?.length > 0) ||
      memberData?.userStatus === 'WITHDRAWN'
    ) {
      return (
        <>
          {listEquitySettlementForm &&
            listEquitySettlementForm.reports?.map((report, index) => {
              const date = report.contractYmd
                ? new Date(report.contractYmd)
                : new Date();
              const month = date.getMonth() + 1;
              const day = date.getDate();
              return (
                <>
                  {renderReportInfoRow(
                    `持分精算書(${month}月${day}日)`,
                    ['株式を引出しした際の引出株数、取得単価を確認できます。'],
                    'euitySettlementForm',
                    () =>
                      downloadEequitySettlementFormReport(
                        date.getFullYear().toString(),
                        ('0' + (date.getMonth() + 1)).slice(-2),
                        ('0' + date.getDate()).slice(-2)
                      ),
                    index !== 0
                  )}
                </>
              );
            })}
          {memberData?.userStatus === 'WITHDRAWN' && (
            <>
              {renderReportInfoRow(
                '退会者精算金精算書',
                ['持株会を退会した際に精算金を確認することができます。'],
                'withdrawalSettlementFormSettlement',
                () => downloadWithdrawalSettlementFormReport('settlement'),
                !!(
                  listEquitySettlementForm &&
                  listEquitySettlementForm.reports &&
                  listEquitySettlementForm.reports.length !== 0
                )
              )}
              {renderReportInfoRow(
                '退会者配当金精算書',
                ['持株会を退会した際に精算金を確認することができます。'],
                'withdrawalSettlementFormDividend',
                () => downloadWithdrawalSettlementFormReport('dividend'),
                true
              )}
            </>
          )}
        </>
      );
    }
    return null;
  };

  const renderDividendPaymentCertificates = () => {
    // ! This is going to be enabled in the future
    if (
      listDividendPaymentCertificates &&
      listDividendPaymentCertificates.reports &&
      listDividendPaymentCertificates.reports.length > 0
    ) {
      return listDividendPaymentCertificates.reports.map((report) => (
        <>
          {renderReportInfoRow(
            '配当金支払証明書兼支払通知書',
            [
              '1月1日から12月31日までに受け取った配当金に関する証明書です。確定申告に使用できます。',
              '※配当金支払証明書兼支払通知書は、今年度分が発行されるまでの期間はダウンロード可能です。',
            ],
            'dividendPaymentCertificates',
            () =>
              downloadDividendPaymentCertificateReport(
                // TODO
                // report?.dividendPaymentCertificateReportId ?? 0
                memberData?.memberId || 0
              ),
            !!(
              (listEquitySettlementForm &&
                listEquitySettlementForm.reports &&
                listEquitySettlementForm.reports.length !== 0) ||
              memberData?.userStatus === 'WITHDRAWN'
            )
          )}
        </>
      ));
    }
    return null;
  };

  const renderReports = () => {
    if (!shouldRenderReports)
      return <Box>ダウンロードできる帳票ありません。</Box>;

    return (
      <ReportWrapper>
        {renderReportInfoHeader(['帳票名', '説明', 'ダウンロード'])}
        <ReportInfoGroup>
          <>
            {renderSettlementInfoForms()}
            {renderDividendPaymentCertificates()}
          </>
        </ReportInfoGroup>
      </ReportWrapper>
    );
  };

  return (
    <Wrapper>
      <MembersHeader memberData={memberData} />
      {renderReports()}
    </Wrapper>
  );
};
