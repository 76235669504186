import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { appClient } from '..';

export const useFindMemberAsset = () => {
  const { memberId = '' } = useParams();
  const { data, mutate } = useSWR(
    !Number.isNaN(Number(memberId)) ? '/api/members/{memberId}/assets' : null,
    () => appClient.members.findMemberAsset(Number(memberId))
  );

  useEffect(() => {
    void mutate();
  }, [memberId]);

  return { data };
};
