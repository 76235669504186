import { LoginPasswordChangeComplete } from '@/components/organisms/Login/LoginPasswordChangeComplete';
import { Path } from '@/constants/Router/path';
import { useNavigate } from 'react-router-dom';

export const PasswordChangeComplete = () => {
  const navigate = useNavigate();
  const handleClickButton = () => {
    navigate(Path.LOGIN);
  };
  return <LoginPasswordChangeComplete handleClickButton={handleClickButton} />;
};
