import { FC } from 'react';
import { Button, Typography } from '@mui/material';
import { ReactComponent as PrintIcon } from '@/assets/print.svg';

interface PrintApplicationsProps {
  onClick: () => void;
}

export const PrintApplicationsButton: FC<PrintApplicationsProps> = ({
  onClick,
}) => (
  <Button
    sx={{
      width: '172px',
      height: '40px',
      whiteSpace: 'nowrap',
      bgcolor: 'secondary.button',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      p: '8px 16px 8px 12px',
      borderRadius: '4px',
      '&:hover': {
        bgcolor: 'secondary.button',
      },
      '&& .MuiTouchRipple-child': {
        bgcolor: 'secondary.normal',
      },
    }}
    onClick={onClick}
  >
    <PrintIcon />
    <Typography
      sx={{
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '100%',
        color: 'secondary.text',
      }}
    >
      画面を印刷する
    </Typography>
  </Button>
);
