/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useSnackbar } from '@/hooks/useSnackbar';
import { useSessionTimeOutModal } from '@/hooks/useSessionTimeOutModal';
import { useLogoutModal } from '@/hooks/useLogoutModal';
import { styled } from '@mui/material';
import { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { AUTH_TOKEN } from '@/constants/Storage/StorageKey';
import { CustomSnackbar } from '../molecules/Snackbar/CustomSnackbar';
import { Footer } from '../organisms/Footer';
import { Header } from '../organisms/Header';
import { SideBar } from '../organisms/SideBar';
import { TimeOut, Logout, BasicModal } from '../molecules/Modal';

const Main = styled('main')({
  minHeight: 'calc(100vh - 57px)',
  marginTop: '57px',
  display: 'flex',
});

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: '1',
});

const Content = styled('div')({
  flex: '1',
});

export const Layout: FC = () => {
  const { sessionTimeOutModal, handleClickUserLogout } =
    useSessionTimeOutModal();
  const { logoutModal, handleClickLogout, handleLogoutModalClose } =
    useLogoutModal();
  const { snackbar, hideSnackbar } = useSnackbar();
  const focusHandler = () => {
    const token = localStorage.getItem(AUTH_TOKEN);
    if (token === null) {
      handleClickUserLogout();
    }
  };
  useEffect(() => {
    window.addEventListener('focus', focusHandler);
    return () => {
      window.removeEventListener('focus', focusHandler);
    };
  }, []);

  return (
    <>
      <Header />
      <Main>
        <SideBar />
        <Wrapper>
          <Content>
            <Outlet />
          </Content>
          <Footer />
        </Wrapper>
      </Main>
      <BasicModal
        open={logoutModal}
        hasScroll={false}
        handleClose={handleLogoutModalClose}
      >
        <Logout
          handleClickCancel={handleLogoutModalClose}
          handleClickLogout={handleClickLogout}
        />
      </BasicModal>
      <BasicModal
        open={sessionTimeOutModal}
        hasScroll={false}
        handleClose={() => console.log('no close')}
      >
        <TimeOut handleClickLogout={handleClickUserLogout} />
      </BasicModal>
      <CustomSnackbar
        open={snackbar.open}
        onClose={hideSnackbar}
        message={snackbar.message}
        width={snackbar.width}
      />
    </>
  );
};
