import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { toDate } from '@/utils/formatter';
import { TableContainer } from './TableContainer';
import { Row } from './Row';
import { Td } from './Td';
import { Th } from './Th';

interface Props {
  date: string;
}

export const EventExtension: FC<Props> = ({ date }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '8px',
    }}
  >
    <Typography variant="h5" sx={{ color: 'system.text-normal' }}>
      締日
    </Typography>
    <TableContainer sxProps={{ width: '304px' }}>
      <Row borderBottom={false}>
        <Th
          variant="medium"
          bgcolor="secondary.bg"
          width="120px"
          height="48px"
          borderRight
        >
          事務局締日
        </Th>
        <Td width="184px" height="48px">
          {date ? toDate(date) : '未登録'}
        </Td>
      </Row>
    </TableContainer>
  </Box>
);
