import { FC } from 'react';
import { AccountPasswordEdit } from '@/components/organisms/Account/AccountEdit';
import { SubmitHandler } from 'react-hook-form';
import { AccountEditInputs as Inputs } from '@/types/components/Inputs';
import { useNavigate } from 'react-router-dom';
import { appClient } from '@/services';
import { useSetRecoilState } from 'recoil';
import { snackbarAtom } from '@/atom/snackbarAtom';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

export const AccountEdit: FC = () => {
  const navigate = useNavigate();
  const openSnackBar = useSetRecoilState(snackbarAtom);
  useHelmetHandler({
    title: 'パスワード変更',
  });

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    try {
      await appClient.officeUsers.resetOfficeUserPassword({
        password: data.oldPassword,
        newPassword: data.password,
      });
      openSnackBar({
        open: true,
        message: 'パスワードを変更しました。',
      });
      navigate('/account');
    } catch (e) {
      console.log(e);
    }
  };
  return <AccountPasswordEdit onSubmit={onSubmit} />;
};
