/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { FindTradereportsByID } from '@/types/api/tradereports';
import { TableContainer } from './TableContainer';
import { Row } from './Row';
import { Td } from './Td';
import { Th } from './Th';

interface Props {
  report: Required<FindTradereportsByID>;
}

export const OtherDetail: FC<Props> = ({ report }) => {
  const {
    dividend,
    interest,
    otherPayment,
    officeExpenses,
    otherDepositWithdrawalTotal,
  } = report.monthlyBonus as Required<Props['report']['monthlyBonus']>;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '8px',
      }}
    >
      <Typography variant="h5" sx={{ color: 'system.text-normal' }}>
        その他入出金明細
      </Typography>
      <TableContainer width="444px">
        <Row>
          <Th variant="regular" bgcolor="secondary.bg" width="184px">
            配当金
          </Th>
          <Td width="260px" align="right">
            {`${dividend.toLocaleString()}円`}
          </Td>
        </Row>
        <Row>
          <Th variant="regular" bgcolor="secondary.bg" width="184px">
            利息
          </Th>
          <Td width="260px" align="right">
            {`${interest.toLocaleString()}円`}
          </Td>
        </Row>
        <Row>
          <Th variant="regular" bgcolor="secondary.bg" width="184px">
            その他入金
          </Th>
          <Td width="260px" align="right">
            {`${otherPayment.toLocaleString()}円`}
          </Td>
        </Row>
        <Row>
          <Th variant="regular" bgcolor="secondary.bg" width="184px">
            事務経費徴収額
          </Th>
          <Td width="260px" align="right">
            {`${officeExpenses.toLocaleString()}円`}
          </Td>
        </Row>
        <Row borderBottom={false}>
          <Th variant="medium" width="184px" bgcolor="secondary.light">
            合計 (F)
          </Th>
          <Td width="260px" align="right">
            {`${otherDepositWithdrawalTotal.toLocaleString()}円`}
          </Td>
        </Row>
      </TableContainer>
    </Box>
  );
};
