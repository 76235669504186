import { theme } from '@/theme';
import { GetAssociationsContribution } from '@/types/api/associations';
import { FindMemberAsset, FindMemberContribution } from '@/types/api/members';
import { MemberSecuritiesAccount } from '@/types/api/memberSecuritiesAccount';
import { Typography } from '@mui/material';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Calculations } from '@/utils/calculation';
import { AssociationsContribution } from '@/types/api/Association/associationsContribution';
import { MemberContribution } from '@/types/api/memberContribution';
import {
  LabelAndValue,
  MembersProxyTable,
  MembersProxyTableRow,
} from './MembersProxyTable';

type MemberProxyApplicationBodyMiddleProps = Omit<ContributionProps, 'title'> &
  AccountProps &
  Props;
type ContributionProps = {
  title: string;
  contribution: GetAssociationsContribution & FindMemberContribution;
  stocksNumber?: FindMemberAsset['stocksNumber'];
  width?: string;
  investmentDigit?: number;
};
type AccountProps = {
  memberSecurityAccount?: MemberSecuritiesAccount;
};

type Props = {
  isConfirmed: boolean;
};

const Contribution: FC<ContributionProps> = ({
  title,
  contribution,
  stocksNumber,
  width,
  investmentDigit,
}) => (
  <MembersProxyTable title={title}>
    {stocksNumber !== undefined && (
      <MembersProxyTableRow column={1} underline>
        <LabelAndValue
          label="現在の保有株数"
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call
          value={`${stocksNumber.toLocaleString(undefined, {
            maximumFractionDigits: investmentDigit,
          })}株`}
        />
      </MembersProxyTableRow>
    )}
    <MembersProxyTableRow column={1} underline width={width}>
      <LabelAndValue
        label="1口あたり金額"
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call
        value={`${(contribution.unitAmount ?? 0).toLocaleString()}円`}
      />
    </MembersProxyTableRow>
    <MembersProxyTableRow column={2} pb={0}>
      <LabelAndValue
        label={[
          '給与',
          <br key="a" />,
          <Typography
            key="b"
            variant="caption/regular"
            color="system.text-light"
          >
            ※毎月の拠出
          </Typography>,
        ]}
        value={[
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call
          `口数 ${(contribution.monthlyUnit ?? 0).toLocaleString()}口`,
          <br key="a" />,
          `拠出設定 ${(
            (contribution.unitAmount ?? 0) * (contribution.monthlyUnit ?? 0)
          ).toLocaleString()}円`,
        ]}
      />
      <LabelAndValue
        label="賞与"
        value={[
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call
          `口数 ${
            contribution.bonusMultiplierDesignationType ===
            'FIXED_MAGNIFICATION'
              ? Calculations.bonusUnitAmount(
                  {
                    ...contribution,
                  } as AssociationsContribution,
                  {
                    ...contribution,
                  } as MemberContribution
                ).toLocaleString()
              : (contribution.bonusUnit ?? 0).toLocaleString()
          }口`,
          <br key="a" />,
          `拠出設定 ${(
            (contribution.unitAmount ?? 0) *
            Calculations.bonusUnitAmount(
              {
                ...contribution,
              } as AssociationsContribution,
              {
                ...contribution,
              } as MemberContribution
            )
          ).toLocaleString()}円`,
        ]}
      />
    </MembersProxyTableRow>
  </MembersProxyTable>
);
const Account: FC<AccountProps> = ({ memberSecurityAccount }) => (
  <MembersProxyTable title="証券口座">
    {memberSecurityAccount &&
    memberSecurityAccount?.accountNumber &&
    memberSecurityAccount?.branchCd &&
    memberSecurityAccount?.branchName ? (
      <>
        <MembersProxyTableRow column={2} underline>
          <LabelAndValue label="金融機関名" value="東海東京証券" />
          <LabelAndValue
            label="部店名"
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call
            value={memberSecurityAccount.branchName?.replace(
              memberSecurityAccount.branchName,
              '*'.repeat(memberSecurityAccount.branchName?.length)
            )}
          />
        </MembersProxyTableRow>
        <MembersProxyTableRow column={2} underline>
          <LabelAndValue
            label="部店コード"
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call
            value={memberSecurityAccount.branchCd?.replace(
              memberSecurityAccount.branchCd,
              '*'.repeat(memberSecurityAccount.branchCd?.length)
            )}
          />
          <LabelAndValue
            label="口座番号"
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call
            value={memberSecurityAccount.accountNumber?.replace(
              memberSecurityAccount.accountNumber,
              '*'.repeat(memberSecurityAccount.accountNumber?.length)
            )}
          />
        </MembersProxyTableRow>
      </>
    ) : (
      <Typography
        sx={{
          ...theme.typography['body-sub/regular'],
          color: theme.palette.system['text-normal'],
        }}
      >
        未登録です。
      </Typography>
    )}
    <MembersProxyTableRow column={1} pb={0}>
      <Typography>
        ※特定口座をお持ちの方は、自動的に特定口座へ入庫されます。
      </Typography>
    </MembersProxyTableRow>
  </MembersProxyTable>
);

export const MemberProxyApplicationBodyMiddle: FC<
  MemberProxyApplicationBodyMiddleProps
> = ({
  isConfirmed,
  contribution,
  stocksNumber,
  memberSecurityAccount,
  investmentDigit,
}) => {
  const { applicationType = '' } = useParams();

  switch (applicationType) {
    case 'pay':
    case 'bonus': {
      if (isConfirmed) {
        return null;
      }
      return (
        <Contribution
          title="現在の口数設定"
          contribution={contribution}
          investmentDigit={investmentDigit}
        />
      );
    }
    case 'part':
    case 'withdrawal': {
      if (isConfirmed) {
        return <Account memberSecurityAccount={memberSecurityAccount} />;
      }
      return (
        <>
          <Contribution
            title="現在の積立状況"
            contribution={contribution}
            stocksNumber={stocksNumber}
            width="804px"
            investmentDigit={investmentDigit}
          />
          <Account memberSecurityAccount={memberSecurityAccount} />
        </>
      );
    }
    case 'recess': {
      if (isConfirmed) {
        return null;
      }
      return (
        <Contribution
          title="現在の積立状況"
          contribution={contribution}
          stocksNumber={stocksNumber}
          investmentDigit={investmentDigit}
        />
      );
    }
    case 'resume': {
      return (
        <Contribution
          title="休止前の積立状況"
          contribution={contribution}
          stocksNumber={stocksNumber}
          investmentDigit={investmentDigit}
        />
      );
    }
    default: {
      return null;
    }
  }
};
