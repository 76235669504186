import { FC } from 'react';
import {
  styled,
  Box,
  Stack,
  TextField,
  Radio,
  Divider,
  Typography,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { WithdrawalAccountEditInputs as Inputs } from '@/types/components/Inputs';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { CancelButton } from '@/components/atoms/Buttons/CancelButton';
import { FindAssociationsAccount } from '@/types/api/associations';
import { theme } from '@/theme';

const TableCell = styled(Box)({
  display: 'flex',
  gap: '8px',
  paddingBottom: '16px',
  borderBottom: '1px solid',
  alignItems: 'center',
  borderColor: theme.palette.system['separator-light'],
});

const Label = styled(Typography)({
  ...theme.typography['body-main/bold'],
  minWidth: '144px',
});

const RadioButton = styled(Radio)({
  padding: '0px',
  color: theme.palette.primary.normal,
  '&.Mui-checked': {
    color: theme.palette.primary.normal,
  },
});

const RadioWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

interface WithrawalProps {
  account: FindAssociationsAccount;
  applicationId?: string;
  unitType: string;
  onSubmit: SubmitHandler<Inputs>;
  onClickCancel: () => void;
}

export const ApplicationsWithdrawalAccountEdit: FC<WithrawalProps> = ({
  applicationId = '',
  unitType,
  account,
  onSubmit,
  onClickCancel,
}) => {
  const { control, handleSubmit, watch, setValue } = useForm<Inputs>({
    defaultValues: {
      accountClassification: account.accountClassification,
      institutionCd: account.institutionCd,
      institutionName: account.institutionName,
      branchCd: account.branchCd,
      branchName: account.branchName,
      accountType: account.accountType,
      accountNumber: account.accountNumber,
      accountName: account.accountName,
      accountYuchoSymbol: account.accountYuchoSymbol,
      accountYuchoNumber: account.accountYuchoNumber,
    },
  });

  const setInputClear = () => {
    setValue('accountYuchoNumber', '');
    setValue('accountYuchoSymbol', '');
    setValue('institutionCd', '');
    setValue('institutionName', '');
    setValue('branchCd', '');
    setValue('branchName', '');
    setValue('accountNumber', '');
    setValue('accountName', '');
  };
  return (
    <>
      <Stack spacing={3} px={5} py={4}>
        <NormalLink
          href={`/applications/${unitType}/${applicationId}/withdrawal`}
        >
          申請詳細に戻る
        </NormalLink>
        <Typography variant="h4">買増精算振込先口座を変更</Typography>
        <Typography>
          退会申請時の買増精算にて会員が買増分の金額を振り込む際に使用される口座をご指定ください。
        </Typography>
      </Stack>
      <Divider />
      <Stack
        spacing={4}
        px={5}
        py={4}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack spacing={2}>
          <TableCell>
            <Label>金融機関の種類</Label>
            <Controller
              name="accountClassification"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  name="accountClassification"
                  sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}
                >
                  <RadioWrapper>
                    <RadioButton
                      value="OTHER"
                      name="accountClassification"
                      onChange={() => setInputClear()}
                    />
                    ゆうちょ銀行以外
                  </RadioWrapper>
                  <RadioWrapper>
                    <RadioButton
                      value="YUCHO"
                      name="accountClassification"
                      onChange={() => setInputClear()}
                    />
                    ゆうちょ銀行
                  </RadioWrapper>
                </RadioGroup>
              )}
            />
          </TableCell>
          {watch('accountClassification') === 'YUCHO' ? (
            <>
              <TableCell>
                <Label>店番</Label>
                <Controller
                  name="accountYuchoSymbol"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ width: '116px' }}
                      inputProps={{ style: { paddingLeft: '8px' } }}
                    />
                  )}
                />
              </TableCell>
              <TableCell>
                <Label>番号</Label>
                <Controller
                  name="accountYuchoNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      name="accountYuchoNumber"
                      sx={{ width: '196px' }}
                      inputProps={{ style: { paddingLeft: '8px' } }}
                    />
                  )}
                />
              </TableCell>
            </>
          ) : (
            <>
              <TableCell>
                <Label>金融機関名</Label>
                <Controller
                  name="institutionName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ width: '400px' }}
                      inputProps={{ style: { paddingLeft: '8px' } }}
                    />
                  )}
                />
              </TableCell>

              <TableCell>
                <Label>支店コード</Label>
                <Controller
                  name="branchCd"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ width: '160px' }}
                      inputProps={{ style: { paddingLeft: '8px' } }}
                    />
                  )}
                />
              </TableCell>
              <TableCell>
                <Label>支店名</Label>
                <Controller
                  name="branchName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ width: '400px' }}
                      inputProps={{ style: { paddingLeft: '8px' } }}
                    />
                  )}
                />
              </TableCell>
              <TableCell>
                <Label>種別</Label>
                <Controller
                  name="accountType"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      name="accountType"
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '16px',
                        marginLeft: '8px',
                      }}
                    >
                      <FormControlLabel
                        name="accountType"
                        value="SAVINGS"
                        control={<RadioButton sx={{ paddingRight: '8px' }} />}
                        label="普通"
                      />
                      <FormControlLabel
                        name="accountType"
                        value="CURRENT"
                        control={<RadioButton sx={{ paddingRight: '8px' }} />}
                        label="当座"
                      />
                      <FormControlLabel
                        name="accountType"
                        value="OTHER"
                        control={<RadioButton sx={{ paddingRight: '8px' }} />}
                        label="貯金"
                      />
                    </RadioGroup>
                  )}
                />
              </TableCell>
              <TableCell>
                <Label>口座番号</Label>
                <Controller
                  name="accountNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ width: '160px' }}
                      inputProps={{ style: { paddingLeft: '8px' } }}
                    />
                  )}
                />
              </TableCell>
              <TableCell>
                <Label>口座名義</Label>
                <Controller
                  name="accountName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ width: '400px' }}
                      inputProps={{ style: { paddingLeft: '8px' } }}
                    />
                  )}
                />
              </TableCell>
            </>
          )}
        </Stack>
        <Box display="flex" gap={theme.spacing(1)}>
          <CancelButton onClick={() => onClickCancel()}>
            キャンセル
          </CancelButton>
          <PrimaryButton type="submit">登録する</PrimaryButton>
        </Box>
      </Stack>
    </>
  );
};
