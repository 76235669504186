/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FC, useState } from 'react';
import { CancelButton } from '@/components/atoms/Buttons/CancelButton';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { theme } from '@/theme';
import {
  Box,
  Typography,
  styled,
  SxProps,
  Button,
  TextField,
  SvgIcon,
  Paper,
  IconButton,
  TextareaAutosize,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CloseIcon } from '@/assets/cross.svg';
import { ReactComponent as CalendarIcon } from '@/assets/calendar.svg';
import { Calendar } from '@/components/organisms/Calendar/Calendar';
import {
  BasicModal,
  NotificationFormModal,
} from '@/components/molecules/Modal';
import { formatDateTimeWithSlash } from '@/utils/dateFunction';
import { NotidicationInputs as Inputs } from '@/types/components/Inputs';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { format } from 'date-fns';

interface NotificationFormProps {
  isGeneral: boolean;
  onSubmit: SubmitHandler<Inputs>;
  modal: string;
  setModal: (value: string) => void;
  isConfirming: boolean;
  setIsConfirming: (val: boolean) => void;
  associationName: string;
}

const Wrapper = styled(Box)({
  '& textarea::placeholder': {
    color: theme.palette.system['separator-light'],
  },
});

const HeaderSection = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: 24,
  padding: '32px 40px 24px',
  borderWidth: 0,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.system.separator,
});

const TitleRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});

const TitleWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const Title = styled(Typography)({
  ...theme.typography.h4,
  color: theme.palette.system['text-normal'],
});

const Subtitle = styled(Typography)({
  ...theme.typography['body-main/medium'],
  color: theme.palette.system['text-light'],
});

const FormSection = styled(Box)({
  ...theme.typography['body-main/regular'],
  color: theme.palette.system['text-normal'],
  padding: '8px 40px',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 1152, // It's described as it is on Figma
});

const FormRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  padding: '16px 0',
  gap: 16,
  borderWidth: 0,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.system.separator,
});

const FormRowLabel = styled(Box)({
  ...theme.typography['body-main/bold'],
  color: theme.palette.system['text-normal'],
  minWidth: 116,
});

const FormRowDescription = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: 8,
});

const FormRowDescriptionSubtext = styled(Typography)({
  color: theme.palette.system['text-light'],
});

const ImportanceButtonsWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 16,
});

const ImportanceButton = styled(Button)({
  ...theme.typography['body-main/medium'],
  width: 48,
  minWidth: 48,
  height: 32,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  backgroundColor: theme.palette.system['background-dark'],
  color: theme.palette.system['text-light'],

  '&:hover': {
    opacity: 0.7,
  },
});

const ImportanceButtonActive: SxProps = {
  color: theme.palette.system.white,
};

const ImportanceButtonConfirming: SxProps = {
  width: 28,
  minWidth: 28,
  height: 28,
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.system.white,
};

const FormControlWrapper = styled(Box)({
  padding: '32px 0',
  display: 'flex',
  flexDirection: 'row',
  gap: 16,
});

const DateSelectorWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
  alignItems: 'center',
});

const ConfirmedTextWrapper = styled(Box)({
  backgroundColor: theme.palette.system.background,
  padding: 12,
  width: '100%',
});

const MuiButton = styled(Button)({
  ...theme.typography['body-main/bold'],
  color: theme.palette.system.white,
  background: theme.palette.secondary.normal,
  padding: '8px 16px 9px 16px',
  display: 'flex',
  justifyyContent: 'center',
  boxShadow: 'none',
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: '#0E878C',
    boxShadow: 'none',
  },
});

const ConfirmedText = styled(Typography)({
  ...theme.typography['body-main/regular'],
  color: theme.palette.system['text-normal'],
});

const InputDisabler = styled(Box)({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 2,
  backgroundColor: 'transparent',
});

export const NotificationForm: FC<NotificationFormProps> = ({
  isGeneral,
  onSubmit,
  modal,
  setModal,
  isConfirming,
  setIsConfirming,
  associationName,
}) => {
  const navigate = useNavigate();

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [openCalendarOne, setOpenCalendarOne] = useState(false);
  const [openCalendarTwo, setOpenCalendarTwo] = useState(false);
  const { control, handleSubmit, setValue, getValues, watch } = useForm<Inputs>(
    {
      defaultValues: {
        startDate: format(new Date(), 'yyyy-MM-dd'),
        endDate: format(new Date(), 'yyyy-MM-dd'),
        title: '',
        description: '',
        priority: '',
      },
    }
  );
  const convertedPriority = () => {
    switch (getValues('priority')) {
      case 'MIDDLE':
        return '中';
      case 'HIGH':
        return '高';
      case 'LOW':
      default:
        return '低';
    }
  };

  const getToday = () => {
    const d = new Date();
    return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
  };

  const renderHeaderSection = () => (
    <HeaderSection
      sx={isConfirmed ? { borderBottomWidth: 0, paddingBottom: 0 } : undefined}
    >
      <NormalLink
        onClick={() => {
          !!getValues('title') ||
          !!getValues('endDate') ||
          !!getValues('startDate') ||
          !!getValues('description')
            ? setModal('form')
            : navigate(-1);
        }}
      >
        お知らせ一覧にもどる
      </NormalLink>
      {isConfirmed ? (
        <ConfirmedTextWrapper>
          <ConfirmedText>表示が終了したお知らせです</ConfirmedText>
        </ConfirmedTextWrapper>
      ) : (
        <TitleRow>
          <TitleWrapper>
            <Title>持株会員向けお知らせ</Title>
            {isGeneral && !isConfirming && (
              <Subtitle>
                個別の持株会員への連絡には用いないでください。ここで登録したお知らせは、すべての会員に送られます。
              </Subtitle>
            )}
            {isConfirming && <Subtitle>以下の内容で登録します。</Subtitle>}
          </TitleWrapper>
        </TitleRow>
      )}
    </HeaderSection>
  );

  const renderDateContent = () => {
    if (isConfirming) {
      return (
        <Box>
          {getValues('startDate')}(月) から{getValues('endDate')}(月) まで
        </Box>
      );
    }
    return (
      <DateSelectorWrapper>
        <Box sx={{ position: 'relative', cursor: 'pointer' }}>
          <Controller
            name="startDate"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                placeholder="年/月/日"
                onChange={(e) => setValue('startDate', e.target.value)}
                InputProps={{
                  endAdornment: (
                    <SvgIcon>
                      <CalendarIcon />
                    </SvgIcon>
                  ),
                  style: {
                    paddingLeft: '8px',
                    // sx={{
                    cursor: 'pointer',
                    // }}
                  },
                }}
              />
            )}
          />
          <InputDisabler
            onClick={() => {
              if (openCalendarTwo) setOpenCalendarTwo(false);
              setOpenCalendarOne(true);
            }}
          />
          {openCalendarOne && (
            <Paper sx={{ position: 'absolute', left: 0, top: 40, zIndex: 10 }}>
              <Box
                sx={{
                  display: 'flex',
                  padding: '4px',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <IconButton onClick={() => setOpenCalendarOne(false)}>
                  <SvgIcon>
                    <CloseIcon />
                  </SvgIcon>
                </IconButton>
              </Box>
              <Calendar
                propValue={getValues('startDate')}
                minDate={new Date('2010-01-01')}
                maxDate={new Date(`${new Date().getFullYear() + 1}-12-31`)}
                onChange={(value) => {
                  if (new Date(getToday()) <= new Date(value)) {
                    setValue('startDate', value);
                    setOpenCalendarOne(false);
                  }

                  if (value > getValues('endDate')) {
                    setValue('endDate', value);
                  }
                }}
              />
            </Paper>
          )}
        </Box>

        <Box>から</Box>
        <Box sx={{ position: 'relative', cursor: 'pointer' }}>
          <Controller
            name="endDate"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                placeholder="年/月/日"
                onChange={(e) => setValue('endDate', e.target.value)}
                InputProps={{
                  endAdornment: (
                    <SvgIcon>
                      <CalendarIcon />
                    </SvgIcon>
                  ),
                  style: {
                    paddingLeft: '8px',
                  },
                }}
              />
            )}
          />

          <InputDisabler
            onClick={() => {
              if (openCalendarOne) setOpenCalendarOne(false);
              setOpenCalendarTwo(true);
            }}
          />
          {openCalendarTwo && (
            <Paper sx={{ position: 'absolute', left: 0, top: 40, zIndex: 10 }}>
              <Box
                sx={{
                  display: 'flex',
                  padding: '4px',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <IconButton onClick={() => setOpenCalendarTwo(false)}>
                  <SvgIcon>
                    <CloseIcon />
                  </SvgIcon>
                </IconButton>
              </Box>
              <Calendar
                propValue={getValues('endDate')}
                minDate={new Date('2010-01-01')}
                maxDate={new Date(`${new Date().getFullYear() + 1}-12-31`)}
                onChange={(value) => {
                  if (
                    new Date(getToday()) <= new Date(value) &&
                    new Date(getValues('startDate')) <= new Date(value)
                  ) {
                    setValue('endDate', value);
                    setOpenCalendarTwo(false);
                  }
                }}
              />
            </Paper>
          )}
        </Box>
        <Box>まで</Box>
      </DateSelectorWrapper>
    );
  };

  const renderNotificationTitleContent = () => {
    if (isConfirming) {
      return <Box>{getValues('title')}</Box>;
    }
    return (
      <Controller
        name="title"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            placeholder="タイトルを入力"
            inputProps={{
              style: {
                paddingLeft: '8px',
              },
            }}
            sx={{
              width: '800px',
            }}
          />
        )}
      />
    );
  };

  const renderNotificationDescriptionContent = () => {
    if (isConfirming) {
      return <Box>{getValues('description')}</Box>;
    }
    return (
      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <TextareaAutosize
            {...field}
            placeholder="お知らせ内容を入力"
            style={{
              width: '800px',
              resize: 'vertical',
              padding: '8px',
              minHeight: '120px',
              fontWeight: 500,
              fontSize: '16px',
              borderColor: theme.palette.system.placeholder,
              borderRadius: '4px',
              outline: 0,
            }}
          />
        )}
      />
    );
  };

  const ImportanceButtonBgColor = (type?: string) => {
    switch (type) {
      case 'LOW':
        return theme.palette.secondary.main;
      case 'MIDDLE':
        return theme.palette.states.deadline;
      case 'HIGH':
        return theme.palette.states.error;
      default:
        return theme.palette.states.button;
    }
  };

  const renderImporatance = () => {
    if (isConfirming) {
      return (
        <ImportanceButtonsWrapper>
          <ImportanceButton
            sx={{
              ...ImportanceButtonConfirming,
              backgroundColor: ImportanceButtonBgColor(getValues('priority')),
              '&:hover': {
                backgroundColor: ImportanceButtonBgColor(getValues('priority')),
              },
            }}
          >
            {convertedPriority()}
          </ImportanceButton>
        </ImportanceButtonsWrapper>
      );
    }
    return (
      <Controller
        name="priority"
        control={control}
        render={({ field }) => (
          <ImportanceButtonsWrapper {...field}>
            <ImportanceButton
              name="priority"
              onClick={() => setValue('priority', 'LOW')}
              sx={
                getValues('priority') === 'LOW'
                  ? {
                      ...ImportanceButtonActive,
                      backgroundColor: ImportanceButtonBgColor('LOW'),
                      '&:hover': {
                        backgroundColor: ImportanceButtonBgColor('LOW'),
                      },
                    }
                  : {}
              }
            >
              低
            </ImportanceButton>
            <ImportanceButton
              name="priority"
              onClick={() => setValue('priority', 'MIDDLE')}
              sx={
                getValues('priority') === 'MIDDLE'
                  ? {
                      ...ImportanceButtonActive,
                      backgroundColor: ImportanceButtonBgColor('MIDDLE'),
                      '&:hover': {
                        backgroundColor: ImportanceButtonBgColor('MIDDLE'),
                      },
                    }
                  : {}
              }
            >
              中
            </ImportanceButton>
            <ImportanceButton
              name="priority"
              onClick={() => setValue('priority', 'HIGH')}
              sx={
                getValues('priority') === 'HIGH'
                  ? {
                      ...ImportanceButtonActive,
                      backgroundColor: ImportanceButtonBgColor('HIGH'),
                      '&:hover': {
                        backgroundColor: ImportanceButtonBgColor('HIGH'),
                      },
                    }
                  : {}
              }
            >
              高
            </ImportanceButton>
          </ImportanceButtonsWrapper>
        )}
      />
    );
  };

  const renderModalControls = () => {
    switch (modal) {
      case 'form':
        return (
          <>
            <CancelButton
              onClick={() => {
                setModal('');
              }}
            >
              このページにとどまる
            </CancelButton>
            <PrimaryButton
              onClick={() => {
                setIsConfirming(false);
                setModal('');
                navigate('/informations');
              }}
            >
              このページを離れる
            </PrimaryButton>
          </>
        );
      case 'confirming':
        return (
          <PrimaryButton
            onClick={() => {
              setIsConfirmed(true);
              setModal('');
              navigate('/informations');
            }}
          >
            OK
          </PrimaryButton>
        );
      default:
        return null;
    }
  };

  const getModalHeaderTexts = () => {
    switch (modal) {
      case 'form':
        return {
          title: 'ページを移動します',
          subtitle: '入力内容は保存されません。よろしいですか？',
        };
      case 'confirming':
        return {
          title: '持株会員向けお知らせを登録しました',
        };
      default:
        return {};
    }
  };

  return (
    <Wrapper>
      {renderHeaderSection()}
      <FormSection component="form" onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <FormRowLabel>送付対象</FormRowLabel>
          <FormRowDescription>
            <Box>{associationName} 会員 (全員)</Box>
          </FormRowDescription>
        </FormRow>
        <FormRow>
          <FormRowLabel>表示期間</FormRowLabel>
          <FormRowDescription>
            {renderDateContent()}
            <FormRowDescriptionSubtext>
              期間についての注意書きが入ります
            </FormRowDescriptionSubtext>
          </FormRowDescription>
        </FormRow>
        <FormRow>
          <FormRowLabel>タイトル</FormRowLabel>
          <FormRowDescription>
            {renderNotificationTitleContent()}
          </FormRowDescription>
        </FormRow>
        <FormRow>
          <FormRowLabel>内容</FormRowLabel>
          <FormRowDescription>
            {renderNotificationDescriptionContent()}
          </FormRowDescription>
        </FormRow>
        <FormRow>
          <FormRowLabel>重要度</FormRowLabel>
          {renderImporatance()}
        </FormRow>
        {isConfirming ? (
          <FormControlWrapper sx={{ borderWidth: 0 }}>
            <CancelButton
              onClick={() => {
                setIsConfirming(false);
              }}
            >
              入力へ戻る
            </CancelButton>
            <MuiButton type="submit">登録する</MuiButton>
          </FormControlWrapper>
        ) : (
          <FormControlWrapper sx={{ borderWidth: 0 }}>
            <CancelButton
              onClick={() => {
                !!getValues('title') ||
                !!getValues('endDate') ||
                !!getValues('startDate') ||
                !!getValues('description')
                  ? setModal('form')
                  : navigate('/informations');
              }}
            >
              キャンセル
            </CancelButton>
            <PrimaryButton
              id="1"
              disabled={
                !watch('title') || !watch('description') || !watch('priority')
              }
              onClick={() => {
                setIsConfirming(true);
              }}
            >
              確認へ進む
            </PrimaryButton>
          </FormControlWrapper>
        )}
      </FormSection>
      <BasicModal
        open={modal !== ''}
        handleClose={() => setModal('')}
        hasScroll={false}
      >
        <NotificationFormModal
          headerTexts={getModalHeaderTexts()}
          descriptionTexts={[
            {
              label: 'タイトル',
              description: getValues('title') ? getValues('title') : '',
            },
            {
              label: '表示期間',
              description:
                getValues('startDate') && getValues('endDate')
                  ? `${formatDateTimeWithSlash(
                      getValues('startDate'),
                      true
                    )}から ${formatDateTimeWithSlash(
                      getValues('endDate'),
                      true
                    )}まで`
                  : '',
            },
          ]}
          controlContent={renderModalControls()}
        />
      </BasicModal>
    </Wrapper>
  );
};
