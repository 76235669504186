/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { FindTradereportsByID } from '@/types/api/tradereports';
import { TableContainer } from './TableContainer';
import { Row } from './Row';
import { Td } from './Td';
import { Th } from './Th';

interface Props {
  report: Required<FindTradereportsByID>;
}

export const Balance: FC<Props> = ({ report }) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { unallocatedBalanceSharesNum, unallocatedBalance } =
    report.monthlyBonus as Required<Props['report']['monthlyBonus']>;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '8px',
      }}
    >
      <Typography variant="h5" sx={{ color: 'system.text-normal' }}>
        未配分残高
      </Typography>
      <TableContainer width="444px">
        <Row>
          <Th variant="regular" bgcolor="secondary.bg" width="184px">
            理事長口未配分株数
          </Th>
          <Td width="260px" align="right">
            {`${unallocatedBalanceSharesNum.toLocaleString()}株`}
          </Td>
        </Row>
        <Row borderBottom={false}>
          <Th variant="medium" width="184px" bgcolor="secondary.light">
            理事長口未配分金銭 (G)
          </Th>
          <Td width="260px" align="right">
            {`${unallocatedBalance.toLocaleString()}円`}
          </Td>
        </Row>
      </TableContainer>
    </Box>
  );
};
