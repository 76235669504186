export const toPartial = <T>(data: { [key: string]: unknown }): Partial<T> =>
  data as Partial<T>;

export const toRequired = <T>(data: { [key: string]: unknown }): Required<T> =>
  data as Required<T>;

/**
 * '0000-00-00T00:00:00.825483084+09:00' => '0000年00月00日(日)'
 */
export const toDate = (dateString: string) => {
  const d = new Date(dateString);
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const date = d.getDate();

  const dayMap: { [index: number]: string } = {
    0: '日',
    1: '月',
    2: '火',
    3: '水',
    4: '木',
    5: '金',
    6: '土',
  };
  const day = dayMap[d.getDay()];

  return `${year}年${month}月${date}日(${day})`;
};
/**
 * '0000-00-00T00:00:00.825483084+09:00' => '0000年00月00日(日)00:00'
 */
export const toDateTime = (dateString: string) => {
  const d = new Date(dateString);
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const date = d.getDate();
  const hour = d.getHours();
  let minute = d.getMinutes().toString();
  if (minute.length === 1) {
    minute = `0${minute}`;
  }

  const dayMap: { [index: number]: string } = {
    0: '日',
    1: '月',
    2: '火',
    3: '水',
    4: '木',
    5: '金',
    6: '土',
  };
  const day = dayMap[d.getDay()];

  return `${year}年${month}月${date}日(${day})${hour}:${minute}`;
};
/**
 * '0000000' => '000-0000'
 */
export const toZip = (zip: string) =>
  zip.replace(/([0-9]{3})([0-9]{4})/, '$1-$2');
/**
 * eventTypeを日本語表記に変換
 * @param eventType
 * @returns
 */
export const convertEventType = (eventType: string | undefined) => {
  switch (eventType) {
    case '01': {
      return '月例';
    }
    case '02': {
      return '賞与';
    }
    case '11': {
      return '配当';
    }
    default: {
      return '';
    }
  }
};
