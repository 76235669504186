import { Box, styled, Typography } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  flowOn: number;
  flowTexts: {
    key: number;
    value: string;
  }[];
}

const Stepper = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0',
  gap: '4px',
});

const IconArea = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px 22px',
  gap: '8px',
  height: '28px',
});

const Step = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px',
  gap: '10px',
  width: '28px',
  height: '28px',
  background: theme.palette.secondary.main,
  borderRadius: '20px',
}));

const StepNormal = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px',
  gap: '10px',
  width: '28px',
  height: '28px',
  background: theme.palette.system.inactive,
  borderRadius: '20px',
}));

const StepNumber = styled(Typography)(({ theme }) => ({
  ...theme.typography['body-main/bold'],
  fontSize: '18px',
  color: theme.palette.system.white,
}));

const StepBorder = styled('p')(({ theme }) => ({
  width: '56px',
  height: '1px',
  background: theme.palette.system.inactive,
}));

const TextArea = styled('div')(({ theme }) => ({
  display: 'flex',
  flexdirection: 'row',
  alignItems: 'flex-start',
  padding: '0px',
  height: '22px',
}));

const StepperText = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '10px',
  width: '100px',
  height: '22px',
}));

const Text = styled(Typography)(({ theme }) => ({
  ...theme.typography['body-sub/medium'],
  color: theme.palette.secondary.normal,
  textAlign: 'center',
}));

const TextNormal = styled(Typography)(({ theme }) => ({
  ...theme.typography['body-sub/medium'],
  color: theme.palette.system.inactive,
  textAlign: 'center',
}));

export const TextWithFlowIcon: FC<Props> = ({ flowOn, flowTexts }) => (
  <Stepper>
    <IconArea>
      {flowTexts.map((item, index) => (
        <React.Fragment key={item.key}>
          {flowOn === index ? (
            <Step>
              <StepNumber>{item.key}</StepNumber>
            </Step>
          ) : (
            <StepNormal>
              <StepNumber>{item.key}</StepNumber>
            </StepNormal>
          )}
          {item.key !== flowTexts.length ? <StepBorder /> : ''}
        </React.Fragment>
      ))}
    </IconArea>

    <TextArea>
      {flowTexts.map((item, index) => (
        <StepperText key={item.key}>
          {flowOn === index ? (
            <Text>{item.value}</Text>
          ) : (
            <TextNormal>{item.value}</TextNormal>
          )}
        </StepperText>
      ))}
    </TextArea>
  </Stepper>
);
