import { FC, ReactNode, useState } from 'react';
import { styled, Box, TableCell, Checkbox, SxProps } from '@mui/material';
import { ReactComponent as SortArrow } from '@/assets/sortArrow.svg';
import { TableCellProps } from '@mui/material/TableCell';

interface TableProps {
  title?: ReactNode;
  width?: string;
  sort?: () => void;
  sortType?: 'ASC' | 'DESC';
  type?: string;
  sxProps?: SxProps;
  sxContentContainer?: SxProps;
  children?: ReactNode;
  onCheckBoxClick?: (value: boolean) => void;
}

const MuiTableCell = styled((props: TableCellProps) => (
  <TableCell {...props} />
))({
  borderWidth: '0px',
  padding: '0px',
});

const MuiBox = styled(Box)(({ theme }) => {
  const { typography, palette } = theme;
  return {
    ...typography['caption/regular'],
    color: palette.system['text-light'],
    display: 'flex',
    gap: '7px',
    alignItems: 'center',
    padding: '0 0 0 12px',
    borderColor: theme.palette.system.separator,
  };
});

export const TableHeadCell: FC<TableProps> = ({
  title,
  width,
  sort,
  sortType = 'ASC',
  type = 'normal',
  sxProps,
  sxContentContainer,
  children,
  onCheckBoxClick,
}) => {
  const backgroundColor =
    type === 'sort' ? 'system.background-dark' : 'system.background';
  const sx = { backgroundColor, width, ...sxProps };
  const [checked, setChecked] = useState<boolean>(false);

  return (
    <>
      {type === 'normal' && (
        <MuiTableCell sx={sx}>
          <MuiBox sx={{ ...sxContentContainer }}>{title ?? children}</MuiBox>
        </MuiTableCell>
      )}
      {type === 'sort' && (
        <MuiTableCell sx={sx}>
          <MuiBox sx={{ ...sxContentContainer }}>
            {title ?? children}
            <Box
              onClick={() => {
                if (sort) sort();
              }}
              sx={{
                transform: `rotate(${sortType === 'ASC' ? 180 : 0}deg)`,
              }}
            >
              <SortArrow />
            </Box>
          </MuiBox>
        </MuiTableCell>
      )}
      {type === 'checkbox' && (
        <MuiTableCell sx={sx}>
          <MuiBox sx={{ ...sxContentContainer }}>
            <Checkbox
              sx={{
                color: 'system.separator',
                '&.Mui-checked': {
                  color: 'secondary.normal',
                },
                padding: '0px',
              }}
              checked={checked}
              onChange={() => {
                setChecked(!checked);
                if (onCheckBoxClick) onCheckBoxClick(!checked);
              }}
            />
          </MuiBox>
        </MuiTableCell>
      )}
    </>
  );
};
