export const appendToQueryString = (
  queryString: URLSearchParams,
  name: string,
  value?: string
) => {
  if (value && name) {
    queryString.append(name, value);
  } else {
    queryString.delete(name);
  }
};
