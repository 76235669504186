/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  styled,
  Select,
  MenuItem,
  SvgIconProps,
  SelectProps,
  Box,
  SelectChangeEvent,
} from '@mui/material';
import { ReactComponent as Logo } from '@/assets/pc.svg';
import { ReactComponent as ArrowIcon } from '@/assets/keyboardArrowDownIcon.svg';
import { theme } from '@/theme';
import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  HeaderAssociationsList,
  SelectedAssociationState,
} from '@/recoil/associations/associations';
import { useSessionTimeOutModal } from '@/hooks/useSessionTimeOutModal';
import { useLogoutModal } from '@/hooks/useLogoutModal';
import { useListAssociations } from '@/services/customHooks/useListAssociations';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Path } from '@/constants/Router/path';
import { useGetAssociation } from '@/services/customHooks/useGetAssociation';
import { LoginState } from '@/components/pages/Login';
import { useSWRConfig } from 'swr';
import { useFindUser } from '@/services/customHooks/useFindUser';
import { useOfficeUserDetail } from '@/services/customHooks/useOfficeUserDetail';
import { appClient, OpenAPI } from '@/services';
import { AUTH_TOKEN } from '@/constants/Storage/StorageKey';


const LINKS = [
  { href: '/office-users', label: '担当者一覧' },
  { href: '/account', label: 'アカウント設定' },
];

const CustomSvgIcon: FC<SvgIconProps> = (props) => <ArrowIcon {...props} />;

export const SelectBox: FC<SelectProps> = ({ children, ...props }) => (
  <Select
    IconComponent={CustomSvgIcon}
    sx={{
      padding: '8px',
      width: 230,
      height: 21,
      ...theme.typography['body-sub/medium'],
      color: theme.palette.system['text-light'],

      '&&> .MuiSelect-select.MuiOutlinedInput-input': {
        padding: '0 16px 0 0',
      },
      '&>fieldset': {
        border: '0 !important',
      },
      '&.Mui-disabled': {
        opacity: 0.4,
      },
    }}
    {...props}
  >
    {children}
  </Select>
);

const Wrapper = styled(Box)(({ theme: t }) => {
  const { palette } = t;
  return {
    height: '57px',
    position: 'fixed',
    top: 0,
    backgroundColor: palette.system.white,
    width: '100%',
    zIndex: 10,
    padding: '8px 40px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.13)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };
});

const LeftWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  height: '33px',
});

const RightWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '32px',
  height: '21px',
});

export const Header: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LoginState;
  const { cache } = useSWRConfig();
  const { handleLogoutModalOpen } = useLogoutModal();
  const { handleSessionTimeOutModalOpen, handleSessionTimeOutModalClose } =
    useSessionTimeOutModal();
  const associationsList = useRecoilValue(HeaderAssociationsList);
  const [selectedAssociation, setSelectedAssociation] = useRecoilState(
    SelectedAssociationState
  );
  const [authorityClassification, setAuthorityClassification] =
    useState('GENERAL');

  const { error: errorAssociationList } = useListAssociations();
  const {
    data: getAssociation,
    mutateGetAssociation,
  } = useGetAssociation();
  const { data: findUser } = useFindUser();
  const { association: associationData } = useOfficeUserDetail(
    findUser?.officeUserId,
    getAssociation.associationCd || undefined
  );

  // associationCDをIDトークンに含める
  const getData = async (associationcd_param : string) => {
    try {
        const res = await appClient.authorization.authAssociation(associationcd_param);
        if (res) {
          const token = localStorage.getItem(AUTH_TOKEN);
          if (token) {
            const pToken = JSON.parse(token);
            const idToken = `Bearer ${String(pToken.IdToken)}${String(res.AssociationCd)}`;
            OpenAPI.HEADERS = { Authorization: idToken };
            // api clientのヘッダーにトークンを設定
            appClient.request.config.HEADERS = { Authorization: idToken };
          }

          const res2 = await appClient.associations.getAssociation(associationcd_param);
          if (res2) setSelectedAssociation(res2);
        }
    } catch (e) {
      throw new Error("");
    }
  };

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const foundAssociation = associationsList.find(
      (association) => association.associationCd === event.target.value
    );
    if (typeof foundAssociation !== 'undefined') {
      void getData(foundAssociation.associationCd || "");
    }
  };
  useEffect(() => {
    if (!associationData || Object.keys(associationData).length === 0) {
      return;
    }
    setAuthorityClassification(
      associationData.authorityClassification ?? 'GENERAL'
    );
  }, [associationData]);
  useEffect(() => {
    if (associationsList.length > 0) {
      void getData(associationsList[0].associationCd || "");
      // session timeout modalを閉じる
      handleSessionTimeOutModalClose();
    }
  }, [associationsList]);
  useEffect(() => {
    if (errorAssociationList && errorAssociationList.status === 401) {
      handleSessionTimeOutModalOpen();
    }
  }, [errorAssociationList]);
  useEffect(() => {
    if (Object.keys(getAssociation).length === 0) {
      return;
    }
    if (getAssociation?.associationCd === '') {
      // associationCdが空の場合、最新を取得
      mutateGetAssociation();
      return;
    }
    // データの取得後 FalseのみLOGIN_ASSOCIATIONへ戻す
    if (
      getAssociation?.associationCd !== '' &&
      getAssociation?.enteredFlg === false
    ) {
      navigate(Path.LOGIN_ASSOCIATION, {
        state: {
          ...state,
          association: getAssociation,
        },
      });
      cache.delete('/api/associations/{associationCd}');
    }
  }, [getAssociation]);

  return (
    <Wrapper>
      <LeftWrapper>
        <Logo
          onClick={() => navigate(Path.HOME)}
          style={{ cursor: 'pointer' }}
        />
        <SelectBox
          value={selectedAssociation?.associationCd ?? ''}
          onChange={handleChange}
        >
          {associationsList.length > 0 ? (
            associationsList.map((association) => (
              <MenuItem
                key={association.associationCd}
                value={association.associationCd}
                sx={{
                  ...theme.typography['body-sub/medium'],
                  color: theme.palette.system['text-light'],
                }}
              >
                {association.associationName}
              </MenuItem>
            ))
          ) : (
            <MenuItem
              value=""
              sx={{
                ...theme.typography['body-sub/medium'],
                color: theme.palette.system['text-light'],
              }}
            >
              持株会がありません
            </MenuItem>
          )}
        </SelectBox>
      </LeftWrapper>
      <RightWrapper>
        {LINKS.map((link) => {
          if (
            authorityClassification === 'GENERAL' &&
            link.href === '/office-users'
          ) {
            return null;
          }
          return (
            <NormalLink
              key={link.label}
              href={link.href}
              color="system.text-light"
            >
              {link.label}
            </NormalLink>
          );
        })}
        <NormalLink onClick={handleLogoutModalOpen} color="system.text-light">
          ログアウト
        </NormalLink>
      </RightWrapper>
    </Wrapper>
  );
};
