import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { Path } from '@/constants/Router/path';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { theme } from '@/theme';
import { Box, Divider, Stack, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const StyledStack = styled(Stack)({
  padding: theme.spacing(4, 3),
  width: '580px',
  backgroundColor: theme.palette.system.white,
  borderRadius: '8px',
});

export const AssociationCompleteOrganism = () => {
  const navigate = useNavigate();
  const handleClickButton = () => {
    navigate(Path.HOME);
  };
  useHelmetHandler({
    title: '初回 持株会情報登録 入力完了',
  });

  return (
    <Box width="100%" display="flex" justifyContent="center">
      <StyledStack spacing={4}>
        <Stack spacing={3}>
          <Typography variant="h3">設定が完了しました</Typography>
          <Divider />
          <Typography variant="body-main/regular">
            管理者情報、持株会情報の入力が完了しました。
          </Typography>
        </Stack>
        <Box display="flex" justifyContent="center">
          <PrimaryButton
            sx={{ padding: '12px 24px' }}
            onClick={handleClickButton}
          >
            始める
          </PrimaryButton>
        </Box>
      </StyledStack>
    </Box>
  );
};
