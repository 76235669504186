export const headerItems = {
  bank: {
    title: '買増精算振込先口座を変更',
    description:
      '退会申請時の買増精算にて会員が買増分の金額を振り込む際に使用される口座をご指定ください。',
  },
  contact: {
    title: '持株会員向けの問合せ先を変更',
    description: '',
  },
  deadline: {
    title: '会員申請締日を変更',
    description: [
      '会員からの申請の締め切りを買付日の何日前にするかご指定ください。(事務局締日は買付日の5営業日前です)',
      <br key="br" />,
      '原則、すべての申請は月例に紐付きます。',
    ],
  },
};
