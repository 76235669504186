import { theme } from '@/theme';
import { Box, Stack, styled, Typography } from '@mui/material';

export const StandardFieldWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

export const Label = styled(Typography)(() => ({
  ...theme.typography['body-main/bold'],
  minWidth: '160px',
}));

export const StyledStack = styled(Stack)({
  padding: theme.spacing(4),
  marginBottom: theme.spacing(10),
  width: '780px',
  backgroundColor: theme.palette.system.white,
  borderRadius: '4px',
});
