/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { createContext, useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { format } from 'date-fns';
import { useApplications } from '@/services/customHooks/useApplications';

type Context = ReturnType<typeof useProviderTemporary>;
const ApplicationsTemporaryContext = createContext({} as unknown as Context);

const initialState = {
  associationCd: '',
  yearMonth: Number(format(new Date(), 'yyyyMM')),
  eventType: 'SPECIAL',
  // applicationType: '',
  // applicationStatus: '',
  page: 1,
  perPageSize: 20,
  sortKey: 'APPLICATION_ID_DESC',
};

const useProviderTemporary = (associationCd: string) => {
  const now = new Date();
  const formatDateToday = format(now, 'yyyyMM');
  const [dateState, setDateState] = React.useState(formatDateToday);
  const [params, setParams] = React.useState({
    ...initialState,
    associationCd,
  });
  // 赤バッジ常に非表示
  // https://git.i3design.com/tokaitokyo/nexstocks/nextstocks-manage/-/issues/759
  const hasUnapprovedTemporaryApplication = false;
  const {
    data: applicationsData,
    applicationsError,
    applicationsMutate,
    // @ts-ignore
  } = useApplications(params);

  useEffect(() => {
    setParams({
      ...params,
      associationCd,
      yearMonth: Number(dateState),
    });
  }, [associationCd, dateState]);

  /**
   * handleDateChange
   * @param date
   */
  const handleDateChange = (date: number | Date) => {
    const formatDate = format(date, 'yyyyMM');
    setDateState(formatDate);
  };

  return {
    applicationsTemporaryData: applicationsData,
    applicationsTemporaryError: applicationsError,
    applicationsTemporaryMutate: applicationsMutate,
    applicationsTemporaryLoading: !applicationsData && !applicationsError,
    // 赤バッジ用
    hasUnapprovedTemporaryApplication,
    handleDateChange,
  };
};

/**
 * useApplicationsTemporary
 */
export const useApplicationsTemporary = () =>
  useContext(ApplicationsTemporaryContext);

/**
 * ApplicationsTemporaryProvider
 * @param associationCd
 * @param children
 * @constructor
 */
export const ApplicationsTemporaryProvider: React.FC<{
  associationCd: string;
  children: React.ReactNode;
}> = ({ associationCd, children }) => {
  const applicationsTemporary = useProviderTemporary(associationCd);
  return (
    <ApplicationsTemporaryContext.Provider value={applicationsTemporary}>
      {children}
    </ApplicationsTemporaryContext.Provider>
  );
};

export const Temporary = () => <Outlet />;
