import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { theme } from '@/theme';
import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { ConfirmedSubTitle, StyledStack } from './StyledComponent';

interface OfficeUserCreateHeadProps {
  isConfirmed: boolean;
  title: string;
  subText?: string;
  confirmedSubText?: string;
  removeButtonText?: string;
  setModal?: (modal: string) => void;
}

export const OfficeUserCreateHead: FC<OfficeUserCreateHeadProps> = ({
  isConfirmed,
  title = '',
  subText = '',
  confirmedSubText = '',
  removeButtonText = '',
  setModal,
}) => (
  <StyledStack>
    {isConfirmed ? (
      <>
        <Typography variant="h4" mb={1}>
          {title}
        </Typography>
        <ConfirmedSubTitle variant="h4">{confirmedSubText}</ConfirmedSubTitle>
      </>
    ) : (
      <>
        <NormalLink mb={3} href="/office-users">
          担当一覧に戻る
        </NormalLink>
        <Stack spacing={1}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4">{title}</Typography>
            <NormalLink
              variant="body-sub/medium"
              color="system.text-light"
              sx={{
                textDecorationLine: 'underline',
                '&:hover': { cursor: 'pointer' },
              }}
              onClick={
                typeof setModal === 'function'
                  ? () => setModal('delete')
                  : undefined
              }
            >
              {removeButtonText}
            </NormalLink>
          </Box>
          {subText && (
            <Typography
              variant="body-main/regular"
              color={theme.palette.system['text-light']}
            >
              {subText}
            </Typography>
          )}
        </Stack>
      </>
    )}
  </StyledStack>
);
