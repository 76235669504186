import { OfficeUserDetail } from '@/types/api/officeUsers';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { appClient } from '..';

export const useOfficeUserDetail = (
  officeUserId?: number,
  associationCd?: string
) => {
  const [association, setAssociation] = useState<
    Exclude<OfficeUserDetail['officeUserAssociations'], undefined>[number]
  >({});
  const { data, mutate } = useSWR(
    officeUserId && associationCd ? '/api/officeusers' : null,
    () => appClient.officeUsers.officeUserDetail(Number(officeUserId))
  );

  const setAssociationData = () => {
    if (typeof data?.officeUserAssociations === 'undefined') {
      setAssociation({});
      return;
    }
    const associationData =
      data.officeUserAssociations &&
      data.officeUserAssociations.find(
        (item) => item.associationCd === associationCd
      );
    if (typeof associationData === 'undefined') {
      setAssociation({});
    } else {
      setAssociation(associationData);
    }
  };

  useEffect(() => {
    void mutate();
  }, [officeUserId, associationCd]);

  useEffect(() => {
    if (associationCd) {
      setAssociationData();
    }
  }, [data]);

  return {
    data,
    association,
  };
};
