import { UpdateAssociationsFirst } from '@/types/api/associations';
import { validateNumber } from '@/utils/validation';
import { TextField } from '@mui/material';
import { FC } from 'react';
import {
  Control,
  Controller,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form';

interface StandardTextFieldProps<T extends FieldValues> {
  control: Control<T>;
  placeholder?: string;
  fieldName: keyof T;
  rules?: UseControllerProps['rules'];
}

export const ControlledTextField: FC<
  StandardTextFieldProps<UpdateAssociationsFirst>
> = ({ control, placeholder = '', fieldName, rules }) => {
  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (...e: any[]) => void
  ) => {
    if (event.target.value === '') onChange(event);
    else
      switch (fieldName) {
        case 'accountYuchoSymbol':
          if (
            validateNumber(event.target.value) &&
            event.target.value.length <= 5
          )
            onChange(event);
          break;
        case 'accountYuchoNumber':
          if (
            validateNumber(event.target.value) &&
            event.target.value.length <= 8
          )
            onChange(event);
          break;
        case 'inquiryEmail':
          onChange(event);
          break;
        case 'inquiryTelOutsideLine':
        case 'inquiryTelExtension':
          if (
            validateNumber(event.target.value) &&
            event.target.value.length <= 15
          )
            onChange(event);
          break;
        case 'accountNumber':
          if (
            validateNumber(event.target.value) &&
            event.target.value.length <= 7
          )
            onChange(event);
          break;
        case 'bankBranchCd':
          if (
            validateNumber(event.target.value) &&
            event.target.value.length <= 3
          )
            onChange(event);
          break;
        case 'accountName':
        case 'financialInstitutionName':
        case 'bankBranchName':
          if (event.target.value.length <= 18) onChange(event);
          break;
        default:
          onChange(event);
          break;
      }
  };

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ required: '入力してください', ...rules }}
      render={({ field, fieldState: { invalid, error } }) => (
        <TextField
          {...field}
          fullWidth
          placeholder={placeholder}
          error={invalid}
          helperText={error?.message}
          onChange={(e) => handleOnChange(e, field.onChange)}
          sx={{
            '&.MuiTextField-root input': {
              padding: '8px',
            },
          }}
        />
      )}
    />
  );
};
