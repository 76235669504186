import { FindMember, ListMembersItem } from '@/types/api/members';
import { isBefore } from 'date-fns';

type ApplicationStatus =
  | 'PREPARATION'
  | 'APPLYING'
  | 'OFFICER_APPROVED'
  | 'BACKOFFICER_APPROVED'
  | 'COMPLETED'
  | 'OFFICER_REJECTED'
  | 'BACKOFFICER_REJECTED'
  | 'CANCEL';

type ApplicationType =
  | 'MONTHLY_UNIT'
  | 'BONUS_UNIT'
  | 'PART'
  | 'RECESS'
  | 'RESUME'
  | 'WITHDRAWAL'
  | 'ADMISSION'
  | 'USER'
  | 'TEMPORARY';

export const isUnapprovedApplication = (applicationStatus: ApplicationStatus) =>
  applicationStatus === 'PREPARATION' || applicationStatus === 'APPLYING';

/**
 * convertApplicationType
 * @param applicationType 申請種別
 * - MONTHLY_UNIT: 口数変更(月例)
 * - BONUS_UNIT: 口数変更(賞与)
 * - PART: 一部引出
 * - RECESS: 休会
 * - RESUME: 再開
 * - WITHDRAWAL: 退会
 * - ADMISSION: 新規会員登録
 * - USER: 会員情報変更
 * - TEMPORARY: 臨時
 */
export const convertApplicationType = (applicationType: ApplicationType) => {
  switch (applicationType) {
    case 'MONTHLY_UNIT':
      return '口数変更';
    case 'BONUS_UNIT':
      return '口数変更';
    case 'PART':
      return '一部引出';
    case 'RECESS':
      return '休止';
    case 'RESUME':
      return '再開';
    case 'WITHDRAWAL':
      return '退会';
    case 'ADMISSION':
      return '新規会員登録';
    case 'USER':
      return '会員情報変更';
    case 'TEMPORARY':
      return '臨時';
    default:
      return '';
  }
};

export const convertApplicationTypeForUrl = (
  applicationType: ApplicationType
) => {
  switch (applicationType) {
    case 'MONTHLY_UNIT':
    case 'BONUS_UNIT':
      return 'unit';
    case 'PART':
      return 'part';
    case 'RECESS':
      return 'recess';
    case 'RESUME':
      return 'resume';
    case 'WITHDRAWAL':
      return 'withdrawal';
    case 'ADMISSION':
      return 'admission';
    case 'USER':
      return 'user';
    case 'TEMPORARY':
      return 'temporary';
    default:
      return '';
  }
};

export type ApplicationStatusText = '未承認' | '承認済' | '却下';
/**
 *  @param applicationStatus 申請ステータス
 * - OFFICER_APPROVED: 事務局承認済
 * - BACKOFFICER_APPROVED: 制度G承認済（STARⅣ処理中）
 * - COMPLETED: 完了（システム反映済み）
 * - BACKOFFICER_REJECTED: 却下（制度G）
 *
 */
export const convertApplicationStatusTextToCode = (
  applicationStatusText: ApplicationStatusText
) => {
  switch (applicationStatusText) {
    case '未承認':
      return 'OFFICER_APPROVED';
    case '承認済':
      return 'BACKOFFICER_APPROVED';
    case '却下':
      return 'BACKOFFICER_REJECTED';
    default:
      return '';
  }
};

export const convertApplicationTypeCheck = (
  applicationType: ApplicationType
) => {
  switch (applicationType) {
    case 'BONUS_UNIT':
      return 'bonus';
    case 'MONTHLY_UNIT':
    case 'PART':
    case 'RECESS':
    case 'RESUME':
    case 'WITHDRAWAL':
    case 'ADMISSION':
    case 'USER':
    case 'TEMPORARY':
      return 'monthly';
    default:
      return 'monthly';
  }
};

/**
 * convertUserStatusText
 * 会員ステータス
 */
export const convertUserStatusText = (
  userStatus: FindMember['userStatus']
): string => {
  switch (userStatus) {
    case 'VARIFIED_MAIL':
    case 'PERSONAL_INFORMATION_ENTERED':
    case 'BEING_ENROLLMENT_PROCESSED':
    case 'NORMAL':
    case 'BEING_RECESSMENT_PROCESSED':
    case 'BEING_WITHDRAWAL_PROCESSED':
    case 'DEREGISTRATION':
    case 'REJECT_ENROLLMENT':
    case 'UNREGISTERED':
      return '稼働中';
    case 'RECESS':
    case 'BEING_RESUMEMENT_PROCESSED':
      return '休止中';
    case 'WITHDRAWN':
      return '退会済';
    default:
      return '';
  }
};

/**
 * convertUserStatusDetailText
 * 会員ステータス
 * - VARIFIED_MAIL : メール認証済み
 * - PERSONAL_INFORMATION_ENTERED : 個人情報入力済み
 * - BEING_ENROLLMENT_PROCESSED : 入会申請中
 * - NORMAL : 通常
 * - BEING_RECESSMENT_PROCESSED : 休止申請中
 * - RECESS : 休止中
 * - BEING_RESUMEMENT_PROCESSED : 再開申請中
 * - BEING_WITHDRAWAL_PROCESSED : 退会申請中
 * - WITHDRAWN : 退会済み
 * - DEREGISTRATION : 登録抹消
 * - REJECT_ENROLLMENT : 入会却下
 * - UNREGISTERED : ログイン前
 */
export const convertUserStatusDetailText = (
  userStatus: FindMember['userStatus']
): string => {
  switch (userStatus) {
    case 'VARIFIED_MAIL':
      return 'メール認証済み';
    case 'PERSONAL_INFORMATION_ENTERED':
      return '個人情報入力済み';
    case 'BEING_ENROLLMENT_PROCESSED':
      return '入会申請中';
    case 'BEING_RECESSMENT_PROCESSED':
      return '休止申請中';
    case 'BEING_RESUMEMENT_PROCESSED':
      return '再開申請中';
    case 'BEING_WITHDRAWAL_PROCESSED':
      return '退会申請中';
    case 'DEREGISTRATION':
      return '登録抹消';
    case 'REJECT_ENROLLMENT':
      return '入会却下';
    case 'UNREGISTERED':
      return 'ログイン前';
    default:
      return '';
  }
};

export const convertUserStatusToRegisteredStatus = (
  userStatus: FindMember['userStatus'],
  expiryDay: FindMember['expiryDay']
): Exclude<ListMembersItem['userRegisteredStatus'], undefined> => {
  if (userStatus !== 'UNREGISTERED') {
    return 'REGISTERED';
  }
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth() + 1, now.getDay());
  if (
    userStatus === 'UNREGISTERED' &&
    isBefore(new Date(expiryDay || 0), today)
  ) {
    return 'REVOCATION';
  }
  return 'UNREGISTERED';
};
