import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

interface MemberProxyCompleteOrganismProps {
  id: number;
  date: string;
  event: string;
}

interface LabelAndValueProps {
  label: string;
  value: string | number;
}

const LabelAndValue: FC<LabelAndValueProps> = ({ label, value }) => (
  <Box display="flex" gap={1}>
    <Typography variant="body-main/medium" color="system.text-normal">
      {label}
    </Typography>
    <Typography variant="body-main/regular" color="system.text-normal">
      {value}
    </Typography>
  </Box>
);

export const MemberProxyCompleteOrganism: FC<
  MemberProxyCompleteOrganismProps
> = ({ id, date, event }) => {
  const { applicationType } = useParams();
  let type = '';

  switch (applicationType) {
    case 'pay': {
      type = '口数変更(給与)';
      break;
    }
    case 'bonus': {
      type = '口数変更(賞与)';
      break;
    }
    case 'part': {
      type = '一部引出';
      break;
    }
    case 'recess': {
      type = '休止';
      break;
    }
    case 'resume': {
      type = '再開';
      break;
    }
    case 'withdrawal': {
      type = '退会';
      break;
    }
    default: {
      return null;
    }
  }
  return (
    <Stack alignItems="center" gap={4}>
      <Typography variant="h4" color="system.text-normal">
        申請が完了しました
      </Typography>
      <Stack
        alignItems="center"
        p={2}
        gap={3}
        bgcolor="system.background"
        borderRadius="8px"
      >
        <Box display="flex" gap={3}>
          <LabelAndValue label="申請種別" value={type} />
          <LabelAndValue label="申請ID" value={id} />
          <LabelAndValue label="申請日時" value={date} />
        </Box>
        <LabelAndValue label="イベント" value={event} />
      </Stack>
    </Stack>
  );
};
