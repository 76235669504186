import { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { LoginPaper } from '@/components/organisms/Login/LoginPaper';
import { MainButton } from '@/components/organisms/Login/MainButton';
import { Box } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { LoginErrorMessage } from './LoginErrorMessage';
import { EmailFieldWithLabel } from './EmailFieldWithLabel';

const errorMessage =
  '本人確認ができませんでした。入力内容が正しいかご確認ください。';

export type Inputs = {
  email: string;
};

interface LoginPasswordResettingProps {
  title: string;
  onSubmit: SubmitHandler<Inputs>;
  isError: boolean;
}

const schema = yup
  .object()
  .required()
  .shape({
    email: yup
      .string()
      .email('メールアドレスの形式が正しくありません')
      .required('必須項目です'),
  });

export const LoginPasswordResetting: FC<LoginPasswordResettingProps> = ({
  title,
  onSubmit,
  isError,
}) => {
  const formMethods = useForm<Inputs>({ resolver: yupResolver(schema) });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;
  useHelmetHandler({
    title: 'パスワードを再設定',
  });

  return (
    <LoginPaper
      title={title}
      subTitle="パスワードを再設定するためには本人確認が必要です。以下の項目を入力してください。"
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        {!!isError && (
          <LoginErrorMessage message={errorMessage} sx={{ marginBottom: 3 }} />
        )}
        <Box mb={3}>
          <EmailFieldWithLabel
            label="メールアドレス"
            placeholder="例：abcdef@mail.com"
            error={'email' in errors}
            helperText={errors.email?.message}
            {...register('email')}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <MainButton
            text="送信する"
            type="submit"
            disabled={errors.email?.message !== undefined}
          />
        </Box>
      </Box>
    </LoginPaper>
  );
};
