import { useSnackbar } from '@/hooks/useSnackbar';
import { styled } from '@mui/material';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { CustomSnackbar } from '../molecules/Snackbar/CustomSnackbar';
import { Footer } from '../organisms/Footer/Footer';
import { LoginHeader } from '../organisms/Header';

const Wrapper = styled('div')(({ theme }) => ({
  height: '100%',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.system.background,
}));

const Content = styled('div')({
  flex: '1',
});

export const LoginLayout: FC = () => {
  const { snackbar, hideSnackbar } = useSnackbar();

  return (
    <>
      <Wrapper>
        <LoginHeader />
        <Content>
          <Outlet />
        </Content>
        <Footer />
      </Wrapper>
      <CustomSnackbar
        open={snackbar.open}
        onClose={hideSnackbar}
        message={snackbar.message}
        width={snackbar.width}
      />
    </>
  );
};
