/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ApplicationsUnitEdit } from '@/components/organisms/ApplicationsEdits/UnitEdit';
import { FC, useState, useEffect, useCallback } from 'react';
import {
  useMatch,
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { UnitEditInputs as Inputs } from '@/types/components/Inputs';
import { MemberById } from '@/types/api/member';
import { FindApplicationByID } from '@/types/api/Applications/application';
import { GetAssociationsContribution } from '@/types/api/associations';
import { ErrorResponse } from '@/types/api/error';
import { usePrevnextApplication } from '@/services/customHooks/usePrevnextApplication';
import { ConvertContribution } from '@/services/customHooks/useMemberContribution';
import { useSnackbar } from '@/hooks/useSnackbar';
import { PrevnextApplicationParams } from '@/types/api/Applications/prevnextApplications';
import { appClient } from '@/services';
import { MemberContribution } from '@/types/api/memberContribution';

type ApplicationType = {
  applicationType: string;
};

export const UnitEdit: FC = () => {
  const match = useMatch('/applications/:unitType/:applicationId/unit/edit');
  const unitType = match?.params.unitType || '';
  const applicationId = Number(useParams().applicationId) || 0;
  const [applicationData, setApplicationData] = useState<FindApplicationByID>();
  const [userData, setUserData] = useState<MemberById>();
  const [associationsContribution, setAssociationsContribution] =
    useState<GetAssociationsContribution>();
  const [searchParams] = useSearchParams();
  const [contributionData, setContributionData] =
    useState<MemberContribution>();
  const [query] = useState<PrevnextApplicationParams>(
    searchParams as PrevnextApplicationParams
  );

  const { data: bottomData } = usePrevnextApplication(applicationId, query);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const location = useLocation();
  const state = location.state as ApplicationType;
  const onSubmit: SubmitHandler<Inputs> = async (d: Inputs) => {
    try {
      let bonusUnitData = 0;
      let bonusRate = 0;
      const isBonusUnit = Boolean(d.isBonus);
      const bonusMultiplierDesignationType =
        associationsContribution?.bonusMultiplierDesignationType;
      if (bonusMultiplierDesignationType && isBonusUnit) {
        switch (bonusMultiplierDesignationType) {
          case 'FIXED_MAGNIFICATION':
            bonusUnitData =
              (d.unitNum || 0) *
              Number(associationsContribution.bonusMultiplierDetails || 1);
            bonusRate = Number(
              associationsContribution.bonusMultiplierDetails || 1
            );
            break;
          case 'MAGNIFICATION':
            bonusUnitData = (d.unitNum || 0) * d.unitRate;
            bonusRate = d.unitRate ? d.unitRate : 0;
            break;
          case 'UNIT_NUMBER':
            bonusUnitData = d.unitNum;
            bonusRate = d.unitRate ? d.unitRate : 0;
            break;
          default:
            break;
        }
      }
      await appClient.applications.updateApplication(applicationId, {
        applicationId,
        applicationMonthlyUnit: {
          monthlyUnit: d.unitNum ? Number(d.unitNum) : undefined,
          bonusRate: d.isBonus ? Number(bonusRate) : 0,
          bonusContributionUnit: d.isBonus ? Number(bonusUnitData) : 0,
          isBonusUnit: d.isBonus,
        },
      });
      if (applicationId)
        navigate(`/applications/${unitType}/${applicationId}/unit`, {
          state: { applicationType: state.applicationType },
        });
    } catch (e) {
      // エラー処理
      showSnackbar((e as ErrorResponse).data.message);
    }
  };

  const getApplicationData = async () => {
    try {
      const res = await appClient.applications.findApplicationById(
        applicationId
      );
      setApplicationData(res);
    } catch (e) {
      console.log(e);
    }
  };

  const getUserData = async () => {
    try {
      if (applicationData?.memberId && applicationData.memberId !== 0) {
        const res = await appClient.members.findMember(
          applicationData?.memberId
        );
        setUserData(res);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getAssociationsContribution = async () => {
    try {
      const res = await appClient.associations.getAssociationsContribution(
        applicationData?.associationCd || ''
      );
      setAssociationsContribution(res);
    } catch (e) {
      console.log(e);
    }
  };

  const getContributionData = useCallback(async () => {
    try {
      if (applicationData?.memberId && applicationData?.memberId !== 0) {
        const res = await appClient.members.findMemberContribution(
          applicationData?.memberId
        );
        setContributionData(ConvertContribution(res));
      }
    } catch (e) {
      console.log(e);
    }
  }, [applicationData?.memberId]);

  useEffect(() => {
    void getApplicationData();
  }, [applicationId]);

  useEffect(() => {
    void getUserData();
  }, [applicationData]);

  useEffect(() => {
    if (applicationData?.associationCd) {
      void getAssociationsContribution();
    }
  }, [applicationData]);

  useEffect(() => {
    void getContributionData();
  }, [getContributionData]);

  if (
    applicationData &&
    userData &&
    associationsContribution &&
    contributionData
  ) {
    return (
      <ApplicationsUnitEdit
        application={applicationData}
        unitType={unitType}
        associationContribution={associationsContribution}
        memberContribution={contributionData}
        userData={userData}
        bottomData={bottomData}
        onSubmit={onSubmit}
        applicationType={state.applicationType}
      />
    );
  }
  return null;
};
