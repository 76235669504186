import { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { LoginPaper } from '@/components/organisms/Login/LoginPaper';
import { MainButton } from '@/components/organisms/Login/MainButton';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { EmailFieldWithLabel } from './EmailFieldWithLabel';
import { PasswordFieldWithLabel } from './PasswordFieldWithLabel';
import { LoginErrorMessage } from './LoginErrorMessage';

const errorMessage = 'ログインIDまたはパスワードが違います。';
const lockErrorMessage =
  'ログインに5回失敗したため、一時的にロックされました。\nこのロックは数分後に解除されます。';

export type LoginFormType = {
  loginId: string;
  loginPassword: string;
};

interface LoginTemplateProps {
  title: string;
  isError: boolean;
  lockError: boolean;
  handleLogin: (data: LoginFormType) => void;
}

const schema = yup
  .object()
  .required()
  .shape({
    loginId: yup
      .string()
      .email('メールアドレスの形式が正しくありません')
      .required('必須項目です'),
    loginPassword: yup.string().required('必須項目です'),
  });

export const LoginTemplate: FC<LoginTemplateProps> = ({
  title,
  isError,
  lockError,
  handleLogin,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<LoginFormType>({ resolver: yupResolver(schema) });

  return (
    <LoginPaper title={title}>
      <form onSubmit={handleSubmit(handleLogin)}>
        {!!isError && (
          <LoginErrorMessage message={errorMessage} sx={{ marginBottom: 3 }} />
        )}
        {!!lockError && (
          <LoginErrorMessage
            message={lockErrorMessage}
            sx={{ marginBottom: 3 }}
          />
        )}
        <Box mb={3}>
          <EmailFieldWithLabel
            label="ログインID"
            annotation="※メールアドレス"
            error={'loginId' in errors}
            helperText={errors.loginId?.message}
            {...register('loginId')}
          />
        </Box>
        <Box mb={3}>
          <PasswordFieldWithLabel
            label="パスワード"
            error={'loginPassword' in errors}
            helperText={errors.loginPassword?.message}
            {...register('loginPassword')}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <MainButton text="ログイン" type="submit" />
        </Box>
        <Box mt="42px">
          <NormalLink icon href="password/resetting">
            パスワードを忘れた方はこちら
          </NormalLink>
        </Box>
      </form>
    </LoginPaper>
  );
};
