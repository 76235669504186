import { SelectedAssociationState } from '@/recoil/associations/associations';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import useSWR from 'swr';
import { appClient } from '..';

export const useGetAssociationsContribution = () => {
  const { associationCd = '' } = useRecoilValue(SelectedAssociationState);
  const { data, error, mutate } = useSWR(
    associationCd !== ''
      ? '/api/associations/{associationCd}/contributions'
      : null,
    () => appClient.associations.getAssociationsContribution(associationCd)
  );

  useEffect(() => {
    void mutate();
  }, [associationCd]);

  return { data: data ?? {}, error };
};
