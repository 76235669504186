/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Box, Stack, styled, Typography } from '@mui/material';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { NormalLink } from '@/components/atoms/Link/NormalLink';
import {
  contributionLabels,
  securitiesLabels,
  userLabels,
} from '@/constants/Association/Member/informationLabels';
import { ReactComponent as UserIcon } from '@/assets/user.svg';
import { ReactComponent as CardIcon } from '@/assets/card.svg';
import { ReactComponent as CoinIcon } from '@/assets/coin.svg';
import { useLocation, useParams } from 'react-router-dom';
import { appClient } from '@/services';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  AssociationContributionState,
  SelectedAssociationState,
} from '@/recoil/associations/associations';
import {
  convertUserStatusDetailText,
  convertUserStatusText,
} from '@/utils/application';
import { theme } from '@/theme';
import { FindApplicationByID } from '@/types/api/Applications/application';
import { FindMember, FindMemberContribution } from '@/types/api/members';
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import { Calculations } from '@/utils/calculation';
import { AssociationsContribution } from '@/types/api/Association/associationsContribution';
import { MemberContribution } from '@/types/api/memberContribution';

interface HeaderProps {
  title: '会員情報' | '証券口座情報' | '拠出状況' | 'ログインID';
  icon: ReactNode;
  linkLabel?: string;
  href?: string;
}

interface RowProps {
  label?: ReactNode;
  value: ReactNode;
  underline: boolean;
}

interface BodyProps {
  labelData: { [key: string]: ReactNode };
  valueData?: { [key: string]: ReactNode };
  emptyDataText?: string;
}

const Row: FC<RowProps> = ({ label, value, underline }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      borderBottom: underline ? '1px solid' : '0',
      borderColor: 'system.separator-light',
      width: '100%',
      pb: '9px',
    }}
  >
    <Box sx={{ display: 'flex', gap: '8px' }}>
      {label && (
        <Typography
          variant="body-sub/regular"
          sx={{
            color: 'system.text-light',
            width: '104px',
          }}
        >
          {label}
        </Typography>
      )}
      <Typography
        variant="body-sub/regular"
        sx={{ color: 'system.text-normal' }}
      >
        {value}
      </Typography>
    </Box>
  </Box>
);

const Body: FC<BodyProps> = ({ labelData, valueData, emptyDataText }) => (
  <Box
    sx={{
      width: '90%',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    }}
  >
    {valueData && Object.entries(valueData).length > 0 ? (
      Object.entries(valueData).map(([key, value], index, self) => (
        <Row
          key={key}
          label={labelData[key]}
          value={value}
          underline={self.length - 1 !== index}
        />
      ))
    ) : (
      <Row
        key={emptyDataText}
        label={undefined}
        value={emptyDataText}
        underline={false}
      />
    )}
  </Box>
);

const Header: FC<HeaderProps> = ({ title, icon, linkLabel = '', href }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '35px',
      borderBottom: '1px solid',
      borderColor: 'primary.normal',
      width: '100%',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        height: '26px',
      }}
    >
      {icon}
      <Typography variant="h5" sx={{ color: 'system.text-normal' }}>
        {title}
      </Typography>
    </Box>
    {!!linkLabel && (
      <NormalLink href={href} icon>
        {linkLabel}
      </NormalLink>
    )}
  </Box>
);

const Table = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: '24px',
  gap: '16px',
  backgroundColor: theme.palette.system['background-light'],
  borderRadius: '4px',
});

export const Information = () => {
  const location = useLocation();
  const { memberId } = useParams();
  const selectedAssociation = useRecoilValue(SelectedAssociationState);
  const [memberInfo, setMemberInfo] = useState<FindMember>();
  const [memberContribution, setMemberContribution] =
    useState<FindMemberContribution>();
  const [memberSecuritiesAccount, setMemberSecuritiesAccount] = useState<
    | {
        branchCd?: string;
        branchName?: string;
        accountNumber?: string;
      }
    | undefined
  >();

  const [associationContribution, setAssociationContribution] = useRecoilState(
    AssociationContributionState
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const statusText = convertUserStatusText(memberInfo?.userStatus);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const statusDetailText = convertUserStatusDetailText(memberInfo?.userStatus);

  const getData = useCallback(async () => {
    try {
      if (
        memberInfo &&
        memberContribution &&
        memberSecuritiesAccount &&
        associationContribution
      )
        return;
      if (
        !memberId ||
        !setMemberInfo ||
        !setMemberContribution ||
        !setMemberSecuritiesAccount
      )
        return;
      const memberIdInt = parseInt(memberId, 10);
      const memberInfoRes = await appClient.members.findMember(memberIdInt);
      const memberContributionRes =
        await appClient.members.findMemberContribution(memberIdInt);
      const memberSecuritiesAccountRes =
        await appClient.members.findMemberSecuritiesAccount(memberIdInt);
      if (selectedAssociation?.associationCd) {
        const associationContributionRes =
          await appClient.associations.getAssociationsContribution(
            selectedAssociation.associationCd
          );
        if (associationContributionRes)
          setAssociationContribution(associationContributionRes);
      }

      /** Set Local data */
      if (memberInfoRes) setMemberInfo(memberInfoRes);
      if (memberContributionRes) setMemberContribution(memberContributionRes);
      if (memberSecuritiesAccountRes)
        setMemberSecuritiesAccount(memberSecuritiesAccountRes);
    } catch (e) {
      // Handle getData error
    }
  }, [
    memberInfo,
    memberContribution,
    memberSecuritiesAccount,
    associationContribution,
    memberId,
    setMemberInfo,
    setMemberContribution,
    setMemberSecuritiesAccount,
    selectedAssociation.associationCd,
    setAssociationContribution,
  ]);

  const [userApplication, setUserApplication] = useState<FindApplicationByID>();

  const getApplications = async () => {
    try {
      const res = await appClient.applications.listApplications(
        memberInfo?.associationCd,
        memberId,
        undefined,
        undefined,
        'USER'
      );
      if (res && res.applications) {
        console.log(res.applications);
        // eslint-disable-next-line no-empty, no-plusplus
        for (let i = 0; i < res.applications.length; i++) {
          if (
            (res.applications[i].applicationStatus === 'BACKOFFICER_APPROVED' ||
              res.applications[i].applicationStatus === 'OFFICER_APPROVED') &&
            res.applications[i].proxyApplicantUserName
          ) {
            setUserApplication(res.applications[i]);
          }
        }
      }
    } catch (e) {
      // catch error
    }
  };

  useEffect(() => {
    void getApplications();
  }, [memberInfo]);

  useEffect(() => {
    void getData();
  }, [getData]);

  /**
   * 会員情報
   */
  const userData = {
    name_kanji: memberInfo?.nameKanji,
    name_kana: memberInfo?.nameKana,
    birthday: memberInfo?.birthday,
    address: [
      `〒${memberInfo?.zipcode ?? ''}`,
      <br key="br" />,
      memberInfo?.address1,
      memberInfo?.address2,
      memberInfo?.address3,
    ],
    tel: [
      `${memberInfo?.telType === 'HOME' ? '自宅' : '携帯'}`,
      <br key="br" />,
      memberInfo?.tel,
    ],
    employee_cd: memberInfo?.employeeCode,
    office_cd: memberInfo?.officeCd,
    affiliation_cd: memberInfo?.affiliationCd,
  };

  const getMonthlyIncentiveRatio = (): number => {
    if (associationContribution.monthlyIncentiveRatio === 0)
      return associationContribution.monthlyIncentiveFixedAmount ?? 0;
    return (
      ((memberContribution?.monthlyUnit || 0) *
        (associationContribution.unitAmount || 0) *
        (associationContribution.monthlyIncentiveRatio || 0)) /
      100
    );
  };

  const getBonusIncentiveRatio = (): number => {
    if (associationContribution.bonusIncentiveRatio === 0)
      return associationContribution.bonusIncentiveFixedAmount ?? 0;
    return (
      (Calculations.bonusUnitAmount(
        { ...associationContribution } as AssociationsContribution,
        { ...memberContribution } as MemberContribution
      ) *
        (associationContribution.unitAmount || 0) *
        (associationContribution.bonusIncentiveRatio || 0)) /
      100
    );
  };

  /**
   * 拠出状況
   */
  const calculatedBonusUnitAmount = Calculations.bonusUnitAmount(
    { ...associationContribution } as AssociationsContribution,
    { ...memberContribution } as MemberContribution
  );
  const contributionData = {
    status: `${statusText}${statusDetailText ? `(${statusDetailText})` : ''}`,
    start_date: format(
      new Date(memberContribution?.contributionStartDate || 0),
      'yyyy年MM月d日',
      { locale: ja }
    ),
    bounty_rate: `${associationContribution.monthlyIncentiveRatio}%`,
    monthly_unit: `${memberContribution?.monthlyUnit || 0}口 ${
      (memberContribution?.monthlyUnit || 0) *
      (associationContribution.unitAmount || 0)
    }円 (奨励金${getMonthlyIncentiveRatio()}円)`,
    bonus_unit: `${calculatedBonusUnitAmount}口 ${
      calculatedBonusUnitAmount * (associationContribution.unitAmount || 0)
    }円 (奨励金${getBonusIncentiveRatio()}円)`,
  };

  return (
    <Box sx={{ display: 'flex', gap: '16px', width: '100%' }}>
      <Stack spacing={2} sx={{ flexGrow: 1 }} width="50%">
        <Table>
          <Header
            title="会員情報"
            icon={<UserIcon />}
            linkLabel="会員情報を変更する"
            href={`${location.pathname}/edit`}
          />
          <Body labelData={userLabels} valueData={userData} />
          {userApplication?.applicationId && (
            <Box
              display="flex"
              gap="8px"
              padding={1}
              sx={{ backgroundColor: theme.palette.secondary.button }}
            >
              <Typography
                variant="body-main/regular"
                color={theme.palette.secondary.text}
              >
                代理申請で変更された会員情報です。
              </Typography>
              <Typography
                variant="body-main/regular"
                color={theme.palette.secondary.text}
              >
                代理申請者：{userApplication.proxyApplicantUserName}
              </Typography>
            </Box>
          )}
        </Table>
      </Stack>
      <Stack spacing={2} sx={{ flexGrow: 1 }} width="50%">
        <Table>
          <Header title="証券口座情報" icon={<CardIcon />} />
          {/* For test purposes set valueData as securitiesData or undefined to check both cases */}
          <Body
            labelData={securitiesLabels}
            valueData={
              memberSecuritiesAccount?.branchCd &&
              memberSecuritiesAccount.branchName &&
              memberSecuritiesAccount.accountNumber
                ? {
                    institutionName: '東海東京証券', // TODO: Institution name is missing
                    branch_cd: memberSecuritiesAccount?.branchCd && '***', // TODO: FIX => branchCd is coming as branchCode. API and Swagger.yaml mismatch
                    branch_name:
                      memberSecuritiesAccount?.branchName && '******',
                    account_number:
                      memberSecuritiesAccount?.accountNumber && '******',
                  }
                : {}
            }
            emptyDataText="未登録です。"
          />
        </Table>
        <Table>
          <Header title="拠出状況" icon={<CoinIcon />} />
          <Body labelData={contributionLabels} valueData={contributionData} />
        </Table>
      </Stack>
    </Box>
  );
};
