/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InformationsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * 【API-B-0604】事務局向けお知らせ一覧取得
   * 事務局向けお知らせ一覧取得
   *
   * @param associationCd associationCd
   * @returns any info response
   * @throws ApiError
   */
  public listAssociationInformations(
    associationCd?: string
  ): CancelablePromise<{
    informations?: Array<{
      /**
       * お知らせID
       */
      associationInformationId?: number;
      /**
       * 持株会コード
       */
      associationCd?: string;
      /**
       * お知らせ種別
       * - PRIVATE: 個別
       * - PUBLIC: 全体
       *
       */
      informationType?: 'PRIVATE' | 'PUBLIC';
      /**
       * 表示期間From
       */
      displayPeriodFrom?: string;
      /**
       * 表示期間To
       */
      displayPeriodTo?: string;
      /**
       * お知らせタイトル
       */
      informationTitle?: string;
      /**
       * お知らせ本文
       */
      informationBody?: string;
      /**
       * 重要度
       * - LOW : 低
       * - MIDDLE : 中
       * - HIGH : 高
       *
       */
      priority?: 'LOW' | 'MIDDLE' | 'HIGH';
      /**
       * お知らせステータス
       * - NORMAL : 正常
       * - DROP : 取り下げ
       *
       */
      informationStatus?: 'NORMAL' | 'DROP';
      /**
       * 登録日時
       */
      created?: string;
    }>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/associationinformations',
      query: {
        associationCd: associationCd,
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-0600】会員向けお知らせ一覧取得
   * 会員向けお知らせ一覧取得
   *
   * @param associationCd associationCd
   * @param page 取得ページ番号
   *
   * @param perPageSize 1ページあたり件数
   *
   * @param sortKey ソートキー
   * - INFORMATION_TITLE_ASC: お知らせタイトル昇順
   * - INFORMATION_TITLE_DESC: お知らせタイトル降順
   * - PRIORITY_ASC: 重要度昇順
   * - PRIORITY_DESC: 重要度降順
   * - DISPLAY_PERIOD_FROM_ASC: 表示期間開始昇順
   * - DISPLAY_PERIOD_FROM_DESC: 表示期間開始降順
   *
   * @returns any info response
   * @throws ApiError
   */
  public listMemberInformations(
    associationCd?: string,
    page?: number,
    perPageSize?: number,
    sortKey?:
      | 'INFORMATION_TITLE_ASC'
      | 'INFORMATION_TITLE_DESC'
      | 'PRIORITY_ASC'
      | 'PRIORITY_DESC'
      | 'DISPLAY_PERIOD_FROM_ASC'
      | 'DISPLAY_PERIOD_FROM_DESC'
  ): CancelablePromise<
    {
      informations?: Array<{
        /**
         * お知らせID
         */
        memberInformationId?: number;
        /**
         * 持株会コード
         */
        associationCd?: string;
        /**
         * 表示期間From
         */
        displayPeriodFrom?: string;
        /**
         * 表示期間To
         */
        displayPeriodTo?: string;
        /**
         * お知らせタイトル
         */
        informationTitle?: string;
        /**
         * お知らせ本文
         */
        informationBody?: string;
        /**
         * 重要度
         * - LOW : 低
         * - MIDDLE : 中
         * - HIGH : 高
         *
         */
        priority?: 'LOW' | 'MIDDLE' | 'HIGH';
        /**
         * お知らせステータス
         * - NORMAL : 正常
         * - DROP : 取り下げ
         *
         */
        informationStatus?: 'NORMAL' | 'DROP';
        /**
         * 登録日時
         */
        created?: string;
      }>;
    } & {
      /**
       * 現在ページ番号
       */
      currentPage?: number;
      /**
       * 総ページ数
       */
      totalPage?: number;
      /**
       * １ページあたり件数
       */
      perPageSize?: number;
      /**
       * 総件数
       */
      totalSize?: number;
    }
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/memberinformations',
      query: {
        associationCd: associationCd,
        page: page,
        perPageSize: perPageSize,
        sortKey: sortKey,
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-0602】会員向けお知らせ登録
   * 会員向けお知らせ登録
   *
   * @param requestBody new info resister
   * @returns any info response
   * @throws ApiError
   */
  public createMemberInformation(requestBody: {
    /**
     * 持株会コード
     */
    associationCd?: string;
    /**
     * 表示期間From
     */
    displayPeriodFrom: string;
    /**
     * 表示期間To
     */
    displayPeriodTo: string;
    /**
     * お知らせタイトル
     */
    informationTitle: string;
    /**
     * お知らせ本文
     */
    informationBody: string;
    /**
     * 重要度
     * - LOW : 低
     * - MIDDLE : 中
     * - HIGH : 高
     *
     */
    priority: 'LOW' | 'MIDDLE' | 'HIGH';
  }): CancelablePromise<{
    /**
     * お知らせID
     */
    memberInformationId?: number;
    /**
     * 持株会コード
     */
    associationCd?: string;
    /**
     * 表示期間From
     */
    displayPeriodFrom?: string;
    /**
     * 表示期間To
     */
    displayPeriodTo?: string;
    /**
     * お知らせタイトル
     */
    informationTitle?: string;
    /**
     * お知らせ本文
     */
    informationBody?: string;
    /**
     * 重要度
     * - LOW : 低
     * - MIDDLE : 中
     * - HIGH : 高
     *
     */
    priority?: 'LOW' | 'MIDDLE' | 'HIGH';
    /**
     * お知らせステータス
     * - NORMAL : 正常
     * - DROP : 取り下げ
     *
     */
    informationStatus?: 'NORMAL' | 'DROP';
    /**
     * 登録日時
     */
    created?: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/memberinformations',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-0601】会員向けお知らせ取得
   * 会員向けお知らせ取得
   *
   * @param memberInformationId ID of info to fetch
   * @returns any info response
   * @throws ApiError
   */
  public findMemberInformationById(
    memberInformationId: number
  ): CancelablePromise<{
    /**
     * お知らせID
     */
    memberInformationId?: number;
    /**
     * 持株会コード
     */
    associationCd?: string;
    /**
     * 表示期間From
     */
    displayPeriodFrom?: string;
    /**
     * 表示期間To
     */
    displayPeriodTo?: string;
    /**
     * お知らせタイトル
     */
    informationTitle?: string;
    /**
     * お知らせ本文
     */
    informationBody?: string;
    /**
     * 重要度
     * - LOW : 低
     * - MIDDLE : 中
     * - HIGH : 高
     *
     */
    priority?: 'LOW' | 'MIDDLE' | 'HIGH';
    /**
     * お知らせステータス
     * - NORMAL : 正常
     * - DROP : 取り下げ
     *
     */
    informationStatus?: 'NORMAL' | 'DROP';
    /**
     * 登録日時
     */
    created?: string;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/memberinformations/{memberInformationId}',
      path: {
        memberInformationId: memberInformationId,
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-0603】会員お知らせ更新
   * 会員向けお知らせ更新
   *
   * @param memberInformationId ID of info to fetch
   * @param requestBody new info resister
   * @returns any info response
   * @throws ApiError
   */
  public updateMemberInformation(
    memberInformationId: number,
    requestBody: {
      /**
       * お知らせID
       */
      memberInformationId?: number;
      /**
       * 持株会コード
       */
      associationCd?: string;
      /**
       * 表示期間From
       */
      displayPeriodFrom?: string;
      /**
       * 表示期間To
       */
      displayPeriodTo?: string;
      /**
       * お知らせタイトル
       */
      informationTitle?: string;
      /**
       * お知らせ本文
       */
      informationBody?: string;
      /**
       * 重要度
       * - LOW : 低
       * - MIDDLE : 中
       * - HIGH : 高
       *
       */
      priority?: 'LOW' | 'MIDDLE' | 'HIGH';
      /**
       * お知らせステータス
       * - NORMAL : 正常
       * - DROP : 取り下げ
       *
       */
      informationStatus?: 'NORMAL' | 'DROP';
      /**
       * 登録日時
       */
      created?: string;
    }
  ): CancelablePromise<{
    /**
     * お知らせID
     */
    memberInformationId?: number;
    /**
     * 持株会コード
     */
    associationCd?: string;
    /**
     * 表示期間From
     */
    displayPeriodFrom?: string;
    /**
     * 表示期間To
     */
    displayPeriodTo?: string;
    /**
     * お知らせタイトル
     */
    informationTitle?: string;
    /**
     * お知らせ本文
     */
    informationBody?: string;
    /**
     * 重要度
     * - LOW : 低
     * - MIDDLE : 中
     * - HIGH : 高
     *
     */
    priority?: 'LOW' | 'MIDDLE' | 'HIGH';
    /**
     * お知らせステータス
     * - NORMAL : 正常
     * - DROP : 取り下げ
     *
     */
    informationStatus?: 'NORMAL' | 'DROP';
    /**
     * 登録日時
     */
    created?: string;
  }> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/memberinformations/{memberInformationId}',
      path: {
        memberInformationId: memberInformationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }
}
