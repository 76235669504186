/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { ApplicationsService } from './services/ApplicationsService';
import { AssociationsService } from './services/AssociationsService';
import { AuthorizationService } from './services/AuthorizationService';
import { CsvService } from './services/CsvService';
import { EventsService } from './services/EventsService';
import { HealthCheckService } from './services/HealthCheckService';
import { InformationsService } from './services/InformationsService';
import { MembersService } from './services/MembersService';
import { OfficeUserAssociationsService } from './services/OfficeUserAssociationsService';
import { OfficeUsersService } from './services/OfficeUsersService';
import { ReportsService } from './services/ReportsService';
import { TradereportsService } from './services/TradereportsService';
import { UsersService } from './services/UsersService';
import { WorkreportsService } from './services/WorkreportsService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AppClient {
  public readonly applications: ApplicationsService;
  public readonly associations: AssociationsService;
  public readonly authorization: AuthorizationService;
  public readonly csv: CsvService;
  public readonly events: EventsService;
  public readonly healthCheck: HealthCheckService;
  public readonly informations: InformationsService;
  public readonly members: MembersService;
  public readonly officeUserAssociations: OfficeUserAssociationsService;
  public readonly officeUsers: OfficeUsersService;
  public readonly reports: ReportsService;
  public readonly tradereports: TradereportsService;
  public readonly users: UsersService;
  public readonly workreports: WorkreportsService;

  public readonly request: BaseHttpRequest;

  constructor(
    config?: Partial<OpenAPIConfig>,
    HttpRequest: HttpRequestConstructor = AxiosHttpRequest
  ) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? 'http://localhost:8092',
      VERSION: config?.VERSION ?? '1.0.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.applications = new ApplicationsService(this.request);
    this.associations = new AssociationsService(this.request);
    this.authorization = new AuthorizationService(this.request);
    this.csv = new CsvService(this.request);
    this.events = new EventsService(this.request);
    this.healthCheck = new HealthCheckService(this.request);
    this.informations = new InformationsService(this.request);
    this.members = new MembersService(this.request);
    this.officeUserAssociations = new OfficeUserAssociationsService(
      this.request
    );
    this.officeUsers = new OfficeUsersService(this.request);
    this.reports = new ReportsService(this.request);
    this.tradereports = new TradereportsService(this.request);
    this.users = new UsersService(this.request);
    this.workreports = new WorkreportsService(this.request);
  }
}
