/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { FindTradereportsByID } from '@/types/api/tradereports';
import { Dividend } from './Dividend';
import { Execution } from './Execution';

interface Props {
  report: Required<FindTradereportsByID>;
}

export const DividendTradeReport: FC<Props> = ({ report }) => (
  <>
    <Box sx={{ display: 'flex', gap: '24px' }}>
      <Dividend report={report} />
    </Box>
    <Box sx={{ width: '100%' }}>
      <Execution report={report} type="dividend" />
    </Box>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: '8px',
        gap: '16px',
        width: '912px',
        height: '56px',
        bgcolor: 'system.background',
        border: '1px solid',
        borderColor: 'system.separator',
        borderRadius: '4px',
      }}
    >
      <Typography
        variant="body-main/medium"
        sx={{ color: 'system.text-light' }}
      >
        退会者支払配当金
      </Typography>
      <Typography
        variant="body-main/bold"
        sx={{ color: 'system.text-normal', fontSize: '18px' }}
      >
        {`${report.dividend.withdrawalDividend?.toLocaleString()}円`}
      </Typography>
    </Box>
  </>
);
