import { Box, SvgIcon } from '@mui/material';
import { theme } from '@/theme/theme';
import { ReactComponent as Error } from '@/assets/errorOops.svg';
import { TableBodyRow } from '@/components/atoms/Table/TableBodyRow';
import { TableBodyCell } from '@/components/atoms/Table/TableBodyCell';
import { StatusBadge } from '@/components/atoms/Badge/StatusBadge';
import { FC } from 'react';
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import { ListEventItem } from '@/types/api/events';
import { Tooltip } from '@/components/molecules/Tooltip';

interface HomeTableRowProps {
  data: ListEventItem;
}

export const HomeTableRow: FC<HomeTableRowProps> = ({ data }) => {
  const emptySymbol = '-';
  // https://git.i3design.com/tokaitokyo/nexstocks/nextstocks-manage/-/issues/810
  const isEventTypeMonthly = data.eventType === '01' || data.eventType === '11'; // 月例 or 配当

  const isDeadlinePast = (date?: string) => {
    if (!date) return false;
    return new Date(date) < new Date();
  };

  const switchEventType = () => {
    switch (data.eventType) {
      case '01':
        return '月例';
      case '02':
        return '賞与';
      case '03':
        return '臨時';
      case '11':
        return '配当';
      default:
        return '';
    }
  };

  return (
    <TableBodyRow key={data.eventNo}>
      <TableBodyCell value={switchEventType()} />
      <TableBodyCell
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        {data.purchaseDate
          ? format(new Date(data.purchaseDate || 0), 'yyyy/MM/dd (E)', {
              locale: ja,
            })
          : emptySymbol}
        {/* {data.purchaseDate && (
          <Tooltip text="買付日が登録されていないため、昨年の買付日を表示しています。">
            <Box
              sx={{
                width: 16,
                height: 16,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: theme.palette.system['text-light'],
                marginLeft: 1,
                padding: 0,
                fontSize: 10,
                lineHeight: '100%',
                fontWeight: 700,
                color: theme.palette.system.white,
              }}
            >
              仮
            </Box>
          </Tooltip>
        )} */}
      </TableBodyCell>
      <TableBodyCell type="badge">
        {!isEventTypeMonthly && data.purchaseDateRegistrationStatus ? (
          <StatusBadge status={data.purchaseDateRegistrationStatus} />
        ) : (
          emptySymbol
        )}
      </TableBodyCell>
      <TableBodyCell
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
      >
        {isEventTypeMonthly ? (
          <Box sx={{ color: theme.palette.system['text-normal'] }}>
            {emptySymbol}
          </Box>
        ) : (
          <>
            <Box sx={{ color: theme.palette.system['text-normal'] }}>
              {format(
                new Date(data.purchaseDateRegistrationDate || 0),
                'yyyy/MM/dd (E)',
                {
                  locale: ja,
                }
              ) ?? emptySymbol}
            </Box>
            {data.purchaseDateRegistrationStatus === 'BEFORREGISTRATION' &&
              isDeadlinePast(data.purchaseDateRegistrationDate) && (
                <Tooltip text="期日を超過しています。">
                  <SvgIcon sx={{ width: 16, height: 16 }}>
                    <Error />
                  </SvgIcon>
                </Tooltip>
              )}
          </>
        )}
      </TableBodyCell>
      <TableBodyCell type="badge">
        {data.workreportApprovalStatus ? (
          <StatusBadge
            status={data.workreportApprovalStatus}
            sx={{ width: '60px' }}
          />
        ) : (
          emptySymbol
        )}
      </TableBodyCell>
      <TableBodyCell>
        {data.workreportDueDate
          ? format(new Date(data.workreportDueDate || 0), 'yyyy/MM/dd (E)', {
              locale: ja,
            })
          : emptySymbol}
      </TableBodyCell>
      <TableBodyCell>
        {data.tradereportsStatus ? (
          <StatusBadge
            status={data.tradereportsStatus}
            sx={{ width: '60px' }}
          />
        ) : (
          emptySymbol
        )}
      </TableBodyCell>
    </TableBodyRow>
  );
};
