import { FC } from 'react';
import { Box, Paper, Stack, styled, Typography } from '@mui/material';
import { MainButton } from '@/components/organisms/Login/MainButton';

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.system.white,
  padding: theme.spacing(4, 3),
  borderRadius: '8px',
  width: '440px',
}));

interface LoginPasswordChangeCompleteProps {
  handleClickButton: () => void;
}

export const LoginPasswordChangeComplete: FC<
  LoginPasswordChangeCompleteProps
> = ({ handleClickButton }) => (
  <Box display="flex" justifyContent="center">
    <StyledPaper elevation={0}>
      <Stack spacing={4} display="flex" alignItems="center">
        <Box>
          <Typography variant="h4">パスワード変更完了</Typography>
        </Box>
        <Box width="256px">
          <Typography>
            パスワードの変更が完了しました。ログイン画面へ移動してください。
          </Typography>
        </Box>
        <Box mt={4} width="100%" display="flex" justifyContent="center">
          <MainButton
            text="ログイン画面へ移動する"
            onClick={handleClickButton}
          />
        </Box>
      </Stack>
    </StyledPaper>
  </Box>
);
