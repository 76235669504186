import { SortKey } from '@/constants/OfficeUser/officeUsersIndex';
import { ListOfficeUserAssociations } from '@/types/api/officeUserAssociations';
import { OfficeUsersIndexParams } from '@/types/api/openapi/officeUsers';

import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { appClient, Error } from '..';

export const useOfficeUsersIndex = ({
  associationCd = '',
  fetchType = 'include',
  sortKey = SortKey.OfficeUesrNameDesc,
}: OfficeUsersIndexParams) => {
  const [query, setQuery] = useState<OfficeUsersIndexParams>({
    associationCd,
    fetchType,
    sortKey,
  });
  const {
    data = [],
    mutate,
    error,
  } = useSWR<ListOfficeUserAssociations, Error>(
    query.associationCd ? '/api/officeuser/associations' : null,
    () =>
      appClient.officeUserAssociations.listOfficeUserAssociations(
        query.fetchType === 'include' ? query.associationCd : undefined,
        query.fetchType === 'exclude' ? query.associationCd : undefined,
        query.sortKey
      )
  );

  const setQueryParams = (officeUsersIndexParams: OfficeUsersIndexParams) => {
    setQuery((currentParams) => ({
      ...currentParams,
      ...officeUsersIndexParams,
    }));
  };

  useEffect(() => {
    void mutate();
  }, [query]);

  return {
    data,
    isLoading: !error && !data,
    error,
    setQueryParams,
  };
};
