import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface OkProps {
  handleClick: () => void;
  children: ReactNode;
}

const styleContainer: SxProps<Theme> = [
  {
    width: '520px',
    height: '153px',
    textAlign: 'center',
    p: '32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    borderRadius: '8px',
  },
];

export const Ok: FC<OkProps> = ({ handleClick, children }) => (
  <Box sx={styleContainer}>
    <Typography
      variant="body-main/regular"
      sx={{ color: 'system.text-normal' }}
    >
      {children}
    </Typography>
    <PrimaryButton sx={{ width: '79px', height: '41px' }} onClick={handleClick}>
      OK
    </PrimaryButton>
  </Box>
);
