import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';

interface Props {
  width: string;
  children: ReactNode;
}

export const TableContainer: FC<Props> = ({ width, children }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width,
      border: '1px solid',
      borderColor: 'system.separator-light',
      borderRadius: '4px',
    }}
  >
    {children}
  </Box>
);
