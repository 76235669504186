import { appClient } from '@/services';
import useSWR from 'swr';
import {
  ListEventsDaily,
  OptionalListEventsDaily,
} from '@/types/api/listEventsDaily';
import { useSessionTimeOutModal } from '@/hooks/useSessionTimeOutModal';

type Params = {
  associationCd: string;
  dateFrom?: string;
  dateTo?: string;
};

const ConvertListEventsDaily = (data: OptionalListEventsDaily) =>
  <ListEventsDaily>{
    date:
      data?.date && data?.date.length > 0
        ? data?.date?.map((item) => ({
            displayDate: item.displayDate,
            events:
              item?.events && item?.events.length > 0
                ? item?.events?.map((event) => ({
                    eventNo: event.eventNo,
                    purchaseClosingType: event.purchaseClosingType,
                    eventType: event.eventType,
                    monthlyPurchaseScheduleDay:
                      event.monthlyPurchaseScheduleDay,
                  }))
                : [],
          }))
        : [],
  };

export const useListEventsDaily = ({
  associationCd,
  dateFrom,
  dateTo,
}: Params) => {
  const { handleSessionTimeOutModalOpen } = useSessionTimeOutModal();
  // 【API-B-2016】持株会イベントを買付日・事務局締日単位に分割した一覧取得
  const { data, error } = useSWR<OptionalListEventsDaily, Error>(
    associationCd !== ''
      ? [`/api/events/${associationCd}/daily`, { dateFrom, dateTo }]
      : null,
    () =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
      appClient.events.listEventsDaily(
        associationCd,
        dateFrom ?? '',
        dateTo ?? ''
      )
  );

  // @ts-ignore
  if (error && error.status === 401) {
    handleSessionTimeOutModalOpen();
  }

  return {
    data: data ? ConvertListEventsDaily(data) : undefined,
    error,
  };
};
