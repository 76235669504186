import { TitleWithDivider } from '@/components/molecules/Title/TitleWithDivider';
import { LoginState } from '@/components/pages/Login';
import { GetAssociationsContribution } from '@/types/api/associations';
import { toZip } from '@/utils/formatter';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { InputWithLabelAndDivider } from './InputWithLabelAndDivider';

interface AssociationInfoSectionProps {
  association: Exclude<LoginState['association'], undefined>;
  joinDt: GetAssociationsContribution['joinDt'];
}

export const AssociationInfoSection: FC<AssociationInfoSectionProps> = ({
  association,
  joinDt,
}) => {
  const {
    officeId = 0,
    associationName = '',
    associationCd = '',
    associationZipcode = '',
    deploymentAddress1 = '',
    deploymentAddress2 = '',
    supplementAddress1 = '',
    supplementAddress2 = '',
  } = association;

  return (
    <Box>
      <TitleWithDivider title="持株会情報" />
      <InputWithLabelAndDivider label="事務局名">
        <Typography>{associationName}</Typography>
      </InputWithLabelAndDivider>
      <InputWithLabelAndDivider label="事務局コード">
        <Typography>{officeId}</Typography>
      </InputWithLabelAndDivider>
      <InputWithLabelAndDivider label="持株会名">
        <Typography>{associationName}</Typography>
      </InputWithLabelAndDivider>
      <InputWithLabelAndDivider label="持株会コード">
        <Typography>{associationCd}</Typography>
      </InputWithLabelAndDivider>
      <InputWithLabelAndDivider label="加入日">
        <Typography>{joinDt}</Typography>
      </InputWithLabelAndDivider>
      <InputWithLabelAndDivider label="住所">
        <Box>
          <Typography>{toZip(associationZipcode)}</Typography>
          <Typography>{`${deploymentAddress1}${deploymentAddress2}${supplementAddress1}${supplementAddress2}`}</Typography>
        </Box>
      </InputWithLabelAndDivider>
    </Box>
  );
};
