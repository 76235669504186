/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { createContext, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Path } from '@/constants/Router/path';
import { AUTH_TOKEN } from '@/constants/Storage/StorageKey';
import { OpenAPI, appClient } from '@/services';
import { useSWRConfig } from 'swr';
import { useRecoilState } from 'recoil';
import { sessionToken } from '@/recoil/token';
import { useSessionTimeOutModal } from './useSessionTimeOutModal';

export type Token = {
  AccessToken?: string | undefined;
  ExpiresIn?: number | undefined;
  IdToken?: string | undefined;
  RefreshToken?: string | undefined;
  TokenType?: string | undefined;
};

type Context = ReturnType<typeof useProvideAuth>;

const authContext = createContext({} as Context);

export const useProvideAuth = () => {
  const { cache } = useSWRConfig();
  const navigate = useNavigate();
  const { handleSessionTimeOutModalClose } = useSessionTimeOutModal();
  const [, setToken] = useRecoilState(sessionToken);

  const handleSetToken = (token: Token) => {};

  /**
   * handleLogout
   */
  const handleLogout = (): void => {
    OpenAPI.HEADERS = {};
    setToken({});
    localStorage.removeItem(AUTH_TOKEN);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    appClient.users.userSignOut().finally(() => {
      navigate(Path.LOGIN);
      // logout時にsession timeout modalを閉じる
      handleSessionTimeOutModalClose();
    });
    // componentsがもつcacheをクリア
    cache.delete('/api/associations/{associationCd}');
  };

  useEffect(() => {
    const token = localStorage.getItem(AUTH_TOKEN);
    if (typeof token === 'string') setToken(JSON.parse(token));
  }, []);

  return {
    handleLogout,
    handleSetToken,
  };
};
/**
 * useAuthUser
 */
export const useAuthUser = () => useContext(authContext);
/**
 * AuthUserProvider
 * @param children
 * @constructor
 */
export const AuthUserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};
