/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Box, Typography, TextField } from '@mui/material';
import { FC } from 'react';
import {
  Control,
  Controller,
  FieldErrorsImpl,
  UseFormRegister,
} from 'react-hook-form';
import { theme } from '@/theme';
import { FindWorkreportsById } from '@/types/api/workreports';
import { WorkReportInputs as Inputs } from '@/types/components/Inputs';
import { TableContainer } from './TableContainer';
import { Row } from './Row';
import { Td } from './Td';
import { Th } from './Th';

type Props = {
  control: Control<Inputs>;
  isSubmit: 'BEFORESUBMISSION' | 'SUBMISSION' | undefined;
  type?: string;
  modalStatus: boolean;
  data: Inputs;
  report: Required<FindWorkreportsById>;
  errors: FieldErrorsImpl<Inputs>;
  register: UseFormRegister<Inputs>;
};

export const Purchases: FC<Props> = ({
  control,
  isSubmit,
  type = 'monthly',
  modalStatus,
  data,
  report,
  errors,
  register,
}) => {
  const {
    oldCarryoverMoney,
    deposit,
    recommendedMoney,
    jimuDaikouCommission,
    transferFee,
    interest,
  } = report;

  const getPurchaseSum = () =>
    (
      oldCarryoverMoney +
      deposit +
      recommendedMoney -
      (data.jimuProxyPurchase && !errors?.jimuProxyPurchase?.message
        ? Number(data.jimuProxyPurchase)
        : 0) -
      (data.transferAmount && !errors?.transferAmount?.message
        ? Number(data.transferAmount)
        : 0) +
      (data.interest && !errors?.interest?.message ? Number(data.interest) : 0)
    ).toLocaleString();

  const getIsAnyError = () =>
    !!(
      (data.jimuProxyPurchase !== '' && !!errors?.jimuProxyPurchase?.message) ||
      (data.transferAmount !== '' && !!errors?.transferAmount?.message) ||
      (data.interest !== '' && !!errors?.interest?.message)
    );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '8px',
      }}
    >
      <Typography variant="h5" sx={{ color: 'system.text-normal' }}>
        今回購入資金
      </Typography>
      <TableContainer>
        <Row>
          <Th
            variant="medium"
            bgcolor="secondary.normal"
            width="192px"
            height="37px"
            borderRight
            borderBottom
          >
            内容
          </Th>
          <Th
            variant="medium"
            bgcolor="secondary.normal"
            width="232px"
            height="37px"
            borderBottom
          >
            金額
          </Th>
        </Row>
        <Row>
          <Th
            variant="medium"
            bgcolor="secondary.light"
            width="192px"
            height="37px"
            borderRight
          >
            前回繰越金
          </Th>
          <Td bold width="232px" height="37px" align="right">
            {`${oldCarryoverMoney ? oldCarryoverMoney.toLocaleString() : 0}円`}
          </Td>
        </Row>
        <Row>
          <Th
            variant="regular"
            bgcolor="system.white"
            width="72px"
            height="96px"
            borderRight
          >
            月例
            <br />
            (賞与)
          </Th>
          <Box>
            <Th
              variant="regular"
              bgcolor="secondary.bg"
              width="120px"
              height="48px"
              borderRight
              borderBottom
            >
              積立金
            </Th>
            <Th
              variant="regular"
              bgcolor="secondary.bg"
              width="120px"
              height="48px"
              borderRight
            >
              奨励金
            </Th>
          </Box>
          <Box>
            <Td width="232px" height="48px" align="right" borderBottom>
              {`${deposit ? deposit.toLocaleString() : 0}円`}
            </Td>
            <Td width="232px" height="48px" align="right">
              {`${recommendedMoney ? recommendedMoney.toLocaleString() : 0}円`}
            </Td>
          </Box>
        </Row>
        <Row>
          <Th
            variant="regular"
            bgcolor="system.white"
            width="72px"
            height={type === 'monthly' ? '144px' : '120px'}
            borderRight
          >
            その他
          </Th>
          <Box>
            {type === 'monthly' && (
              <Th
                variant="regular"
                bgcolor="secondary.bg"
                width="120px"
                height="48px"
                borderRight
                borderBottom
                sxProps={{
                  padding: '4px 0',
                  flexDirection: 'column',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                事務代行手数料
                <Typography variant="caption/regular">※任意</Typography>
              </Th>
            )}

            <Th
              variant="regular"
              bgcolor="secondary.bg"
              width="120px"
              height={type === 'monthly' ? '48px' : '60px'}
              borderRight
              borderBottom
            >
              振込料
              <Typography variant="caption/regular">※任意</Typography>
            </Th>
            <Th
              variant="regular"
              bgcolor="secondary.bg"
              width="120px"
              height={type === 'monthly' ? '48px' : '60px'}
              borderRight
            >
              利息
              <Typography variant="caption/regular">※任意</Typography>
            </Th>
          </Box>
          <Box>
            {type === 'monthly' && (
              <Td width="232px" height="48px" align="right" borderBottom>
                {isSubmit === 'SUBMISSION' ? (
                  `${
                    jimuDaikouCommission
                      ? jimuDaikouCommission.toLocaleString()
                      : 0
                  }円`
                ) : (
                  <Box>
                    {modalStatus ? (
                      `${Number(
                        data.jimuProxyPurchase || 0
                      ).toLocaleString()}円`
                    ) : (
                      <Controller
                        name="jimuProxyPurchase"
                        control={control}
                        render={({ field }) => (
                          <Box display="flex" gap="10px" alignItems="center">
                            <TextField
                              {...field}
                              sx={{ width: '180px' }}
                              inputProps={{ style: { textAlign: 'right' } }}
                              placeholder="例:1,000"
                              {...register('jimuProxyPurchase')}
                            />
                            円
                          </Box>
                        )}
                      />
                    )}
                  </Box>
                )}
              </Td>
            )}
            <Td
              width="232px"
              height={type === 'monthly' ? '48px' : '60px'}
              align="right"
              borderBottom
            >
              {isSubmit === 'SUBMISSION' ? (
                `${transferFee ? transferFee.toLocaleString() : 0}円`
              ) : (
                <Box>
                  {modalStatus ? (
                    `${Number(data.transferAmount || 0).toLocaleString()}円`
                  ) : (
                    <Controller
                      name="transferAmount"
                      control={control}
                      render={({ field }) => (
                        <Box display="flex" gap="10px" alignItems="center">
                          <TextField
                            {...field}
                            sx={{ width: '180px' }}
                            inputProps={{
                              style: {
                                textAlign: 'right',
                              },
                            }}
                            {...register('transferAmount')}
                            placeholder="例:1,000"
                          />
                          円
                        </Box>
                      )}
                    />
                  )}
                </Box>
              )}
            </Td>
            <Td
              width="232px"
              height={type === 'monthly' ? '48px' : '60px'}
              align="right"
            >
              {isSubmit === 'SUBMISSION' ? (
                `${interest ? interest.toLocaleString() : 0}円`
              ) : (
                <Box>
                  {modalStatus ? (
                    `${Number(data.interest || 0).toLocaleString()}円`
                  ) : (
                    <Controller
                      name="interest"
                      control={control}
                      render={({ field }) => (
                        <Box display="flex" gap="10px" alignItems="center">
                          <TextField
                            {...field}
                            sx={{ width: '180px' }}
                            inputProps={{ style: { textAlign: 'right' } }}
                            {...register('interest')}
                            placeholder="例:1,000"
                          />
                          円
                        </Box>
                      )}
                    />
                  )}
                </Box>
              )}
            </Td>
          </Box>
        </Row>
        <Row borderBottom={false}>
          <Th
            variant="bold"
            bgcolor="secondary.light"
            width="192px"
            height="37px"
            borderRight
          >
            合計
          </Th>
          {isSubmit === 'SUBMISSION' ? (
            <Td bold width="232px" height="37px" align="right">
              {`${(
                (oldCarryoverMoney || 0) +
                (deposit || 0) +
                (recommendedMoney || 0) -
                (jimuDaikouCommission || 0) -
                (transferFee || 0) +
                (interest || 0)
              ).toLocaleString()}円`}
            </Td>
          ) : (
            <Td bold width="232px" height="37px" align="right">
              {`${getPurchaseSum()}円`}
            </Td>
          )}
        </Row>
      </TableContainer>
      {isSubmit === 'BEFORESUBMISSION' && !modalStatus && getIsAnyError() && (
        <Typography sx={{ color: theme.palette.states.error }}>
          {errors.jimuProxyPurchase?.message ||
            errors.transferAmount?.message ||
            errors.interest?.message}
        </Typography>
      )}
    </Box>
  );
};
