/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { theme } from '@/theme';
import { Box, styled, Typography } from '@mui/material';
import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import {
  switchAuthorityLabel,
  switchProxyApplicationLabel,
} from '@/utils/officeUser';
import { appClient } from '@/services';
import {
  AuthorityClassification,
  ProxyApplicationClassification,
} from '@/constants/OfficeUser/officeUsersIndex';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const Wrapper = styled(Box)({});

const HeaderWrapper = styled(Box)({
  padding: '32px 40px',
  backgroundColor: theme.palette.system.background,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 16,
  borderWidth: 0,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.system.separator,
});

const Title = styled(Typography)({
  ...theme.typography.h4,
  color: theme.palette.system['text-normal'],
});

const SubtitleWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
});

const SubtitleLeadingText = styled(Typography)({
  ...theme.typography['body-sub/regular'],
  color: theme.palette.system['text-light'],
});

const SubtitleTrailingText = styled(Typography)({
  ...theme.typography['body-sub/medium'],
  color: theme.palette.system['text-normal'],
});

const ContentWrapper = styled(Box)({
  padding: '8px 40px',
});

const ContentRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  borderWidth: 0,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.system.separator,
  gap: 24,
  padding: '24px 0',
});

const ContentRowLead = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: 144,
});

const ContentRowLeadText = styled(Typography)({
  ...theme.typography['body-main/medium'],
  color: theme.palette.system['text-normal'],
});

const ContentRowLeadSubtext = styled(Typography)({
  ...theme.typography['caption/regular'],
  color: theme.palette.system['text-light'],
});

const ContentRowTrailing = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: 8,
  justifyContent: 'center',
});

const ContentRowTrailingPart = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 24,
});

const ContentRowTrailingPartText = styled(Typography)({
  ...theme.typography['body-sub/regular'],
  color: theme.palette.system['text-normal'],
});

export const AccountIndex = () => {
  const { associationCd = '' } = useRecoilValue(SelectedAssociationState);
  const [userInfo, setUserInfo] = useState<{
    officeUserId?: number;
    userId?: string;
    email?: string;
    lastNameKanji?: string;
    firstNameKanji?: string;
    authorityClassification?: 'GENERAL' | 'ADMINISTRATOR';
    proxyApplicationClassification?: 'POSSIBLE' | 'IMPOSSIBLE';
  }>();
  const [associations, setAssociations] = useState<
    {
      associationCd?: string;
      associationName?: string;
      authorityClassification?: 'GENERAL' | 'ADMINISTRATOR';
      proxyApplicationClassification?: 'IMPOSSIBLE' | 'POSSIBLE';
    }[]
  >();
  useHelmetHandler({
    title: 'アカウント情報',
  });

  const getUserData = async () => {
    try {
      // ApiRequestHelper need to be updated.
      const res = await appClient.users.findUser();
      setUserInfo({
        officeUserId: res.officeUserId,
        userId: res.userId,
        email: res.email,
        lastNameKanji: res.lastNameKanji,
        firstNameKanji: res.firstNameKanji,
        authorityClassification: AuthorityClassification.General,
        proxyApplicationClassification: ProxyApplicationClassification.Possible,
      });
    } catch (e) {
      // Handle get userData error
    }
  };

  const getUserAssociations = useCallback(async () => {
    try {
      if (!userInfo?.officeUserId) return;
      const res = await appClient.officeUsers.officeUserDetail(
        userInfo?.officeUserId
      );
      if (res.officeUserAssociations && res.officeUserAssociations?.length > 0)
        setAssociations([...res.officeUserAssociations]);
    } catch (e) {
      // Handle getUserAssociations error
    }
  }, [userInfo?.officeUserId]);

  const renderOfficeUserAssociations = (data: {
    associationCd?: string;
    associationName?: string;
    authorityClassification?: 'GENERAL' | 'ADMINISTRATOR';
    proxyApplicationClassification?: 'IMPOSSIBLE' | 'POSSIBLE';
  }) => (
    <ContentRowTrailingPart key={associationCd}>
      <ContentRowTrailingPartText>
        {data.associationName}
      </ContentRowTrailingPartText>
    </ContentRowTrailingPart>
  );

  const getClassifications = () => {
    if (!associationCd || associationCd.length === 0) return undefined;
    const data = associations?.find(
      (item) => item.associationCd === associationCd
    );
    return data;
  };

  const getMaskedId = () => {
    if (userInfo?.email) {
      const mailTop = userInfo?.email.substring(
        0,
        userInfo?.email.indexOf('@')
      );
      const mailArr = mailTop.split('');
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i < mailArr.length - 1; i++) {
        mailArr[i] = '*';
      }
      return (
        mailArr.join('') +
        userInfo?.email.substring(userInfo?.email.indexOf('@'))
      );
    }
    return '';
  };

  useEffect(() => {
    void getUserData();
  }, []);

  useEffect(() => {
    void getUserAssociations();
  }, [getUserAssociations]);

  return (
    <Wrapper>
      <HeaderWrapper>
        <Title>{`${userInfo?.lastNameKanji ?? ''} ${
          userInfo?.firstNameKanji ?? ''
        } さん`}</Title>
        <SubtitleWrapper>
          <SubtitleLeadingText>権限</SubtitleLeadingText>
          <SubtitleTrailingText>
            {switchAuthorityLabel(
              getClassifications()?.authorityClassification
            )}
          </SubtitleTrailingText>
        </SubtitleWrapper>
        <SubtitleWrapper>
          <SubtitleLeadingText>代理申請</SubtitleLeadingText>
          <SubtitleTrailingText>
            {switchProxyApplicationLabel(
              getClassifications()?.proxyApplicationClassification
            )}
          </SubtitleTrailingText>
        </SubtitleWrapper>
      </HeaderWrapper>
      <ContentWrapper>
        <ContentRow>
          <ContentRowLead>
            <ContentRowLeadText>担当持株会</ContentRowLeadText>
          </ContentRowLead>
          <ContentRowTrailing>
            {associations?.map((data) => renderOfficeUserAssociations(data))}
          </ContentRowTrailing>
        </ContentRow>
        <ContentRow>
          <ContentRowLead>
            <ContentRowLeadText>ログインID</ContentRowLeadText>
            <ContentRowLeadSubtext>※メールアドレス</ContentRowLeadSubtext>
          </ContentRowLead>
          <ContentRowTrailing>
            <ContentRowTrailingPart>
              <ContentRowTrailingPartText>
                {getMaskedId()}
              </ContentRowTrailingPartText>
            </ContentRowTrailingPart>
          </ContentRowTrailing>
        </ContentRow>
        <ContentRow>
          <ContentRowLead>
            <ContentRowLeadText>パスワード</ContentRowLeadText>
          </ContentRowLead>
          <ContentRowTrailing>
            <ContentRowTrailingPart>
              <ContentRowTrailingPartText>
                **********
              </ContentRowTrailingPartText>
              <NormalLink href="edit" icon>
                パスワードを変更する
              </NormalLink>
            </ContentRowTrailingPart>
          </ContentRowTrailing>
        </ContentRow>
      </ContentWrapper>
    </Wrapper>
  );
};
