import { TitleWithDivider } from '@/components/molecules/Title/TitleWithDivider';
import { ControlledTextField } from '@/components/organisms/Association/FirstRegistration/ControlledTextField';
import { InputWithLabelAndDivider } from '@/components/organisms/Association/FirstRegistration/InputWithLabelAndDivider';
import { theme } from '@/theme';
import { Box, Stack, Typography, TextField } from '@mui/material';
import { validateEmail } from '@/utils/validation';
import { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { UpdateAssociationsFirst } from '@/types/api/associations';

interface ContactInfoSectionProps {
  isConfirmed: boolean;
}

export const ContactInfoSection: FC<ContactInfoSectionProps> = ({
  isConfirmed,
}) => {
  const { control, getValues, watch, setValue } =
    useFormContext<UpdateAssociationsFirst>();
  return (
    <Box>
      <TitleWithDivider title="持株会員向けの問合せ先" />
      <Box mt={2}>
        <Typography
          variant="body-main/regular"
          sx={{ color: theme.palette.system['text-normal'] }}
        >
          会員から事務局への連絡先として、会員側の画面に表示されます。
        </Typography>
      </Box>
      <InputWithLabelAndDivider label="担当者部署">
        {isConfirmed ? (
          <Typography>{getValues('inquiryDepartment')}</Typography>
        ) : (
          <ControlledTextField
            control={control}
            fieldName="inquiryDepartment"
            placeholder="例：企画営業部 営業課"
          />
        )}
      </InputWithLabelAndDivider>
      <InputWithLabelAndDivider label="担当者名">
        {isConfirmed ? (
          <Typography>{getValues('inquiryRepresentative')}</Typography>
        ) : (
          <ControlledTextField
            control={control}
            fieldName="inquiryRepresentative"
            placeholder="例：田中一郎"
          />
        )}
      </InputWithLabelAndDivider>
      <InputWithLabelAndDivider
        label="メールアドレス"
        subLabel={
          <Typography
            variant="caption/regular"
            sx={{ color: theme.palette.system['text-light'] }}
          >
            任意
          </Typography>
        }
      >
        {isConfirmed ? (
          <Typography>{getValues('inquiryEmail')}</Typography>
        ) : (
          <Stack width="100%">
            <Controller
              name="inquiryEmail"
              control={control}
              rules={{
                validate: validateEmail,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="例：abcdef@mail.com"
                  onChange={(e) => setValue('inquiryEmail', e.target.value)}
                  sx={{
                    '&.MuiTextField-root input': {
                      padding: '8px',
                    },
                  }}
                />
              )}
            />
            {getValues('inquiryEmail') !== '' &&
              !validateEmail(watch('inquiryEmail')) && (
                <Typography
                  variant="body-sub/regular"
                  color={theme.palette.states.error}
                >
                  メールアドレスの形式が正しくありません
                </Typography>
              )}
          </Stack>
        )}
      </InputWithLabelAndDivider>
      <InputWithLabelAndDivider
        label="電話番号"
        labelAlignSelf="flex-start"
        subLabel={
          <Typography
            variant="caption/regular"
            sx={{ color: theme.palette.states.error }}
          >
            いずれか必須
          </Typography>
        }
      >
        {isConfirmed ? (
          <Box>
            {getValues('inquiryTelOutsideLine') ? (
              <Box display="flex" gap={1}>
                <Typography>外線</Typography>
                <Typography>{getValues('inquiryTelOutsideLine')}</Typography>
              </Box>
            ) : (
              ''
            )}
            {getValues('inquiryTelExtension') ? (
              <Box display="flex" gap={1}>
                <Typography>内線</Typography>
                <Typography>{getValues('inquiryTelExtension')}</Typography>
              </Box>
            ) : (
              ''
            )}
          </Box>
        ) : (
          <Stack spacing={1} width="160px">
            <Box>
              <Typography
                variant="body-sub/regular"
                sx={{ color: theme.palette.system['text-normal'] }}
              >
                外線
              </Typography>
              <ControlledTextField
                control={control}
                fieldName="inquiryTelOutsideLine"
                placeholder="例：12345678901"
                rules={{
                  required: {
                    value: !watch('inquiryTelExtension'),
                    message: '入力してください',
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: '半角数字で入力してください',
                  },
                }}
              />
              <Typography
                variant="caption/regular"
                sx={{ color: theme.palette.system['text-light'] }}
              >
                ※半角＋ハイフンなし
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body-sub/regular"
                sx={{ color: theme.palette.system['text-normal'] }}
              >
                内線
              </Typography>
              <ControlledTextField
                control={control}
                fieldName="inquiryTelExtension"
                placeholder="例：12345678901"
                rules={{
                  required: {
                    value: !watch('inquiryTelOutsideLine'),
                    message: '入力してください',
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: '半角数字で入力してください',
                  },
                }}
              />
              <Typography
                variant="caption/regular"
                sx={{ color: theme.palette.system['text-light'] }}
              >
                ※半角＋ハイフンなし
              </Typography>
            </Box>
          </Stack>
        )}
      </InputWithLabelAndDivider>
    </Box>
  );
};
