/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TradereportsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * 【API-B-2212】購入(売買)連絡票取得
   * 購入(売買)連絡票取得
   *
   * @param year 年
   *
   * @param month 月（二桁指定: 01, 02...12）
   *
   * @param tradereportClassification 購入（売却）連絡票区分
   * - MONTHLY: 月例
   * - BONUS: 賞与
   * - DIVIDEND: 配当
   *
   * @param associationCd 持株会コード
   *
   * @returns any tradereport part response
   * @throws ApiError
   */
  public findTradereportsById(
    year: string,
    month: string,
    tradereportClassification: 'MONTHLY' | 'BONUS' | 'DIVIDEND',
    associationCd: string
  ): CancelablePromise<{
    /**
     * 購入（売却）連絡票ID
     */
    tradereportId?: number;
    /**
     * 持株会コード
     */
    associationCd?: string;
    /**
     * 持株会名称
     */
    associationName?: string;
    /**
     * イベントNo.
     */
    eventNo?: string;
    /**
     * 購入（売却）連絡票区分
     * - MONTHLY: 月例
     * - BONUS: 賞与
     * - DIVIDEND: 配当
     *
     */
    tradereportClassification?: 'MONTHLY' | 'BONUS' | 'DIVIDEND';
    /**
     * 拠出月度
     */
    contributionYm?: string;
    /**
     * 事務局連絡ステータス
     * - SENT: 送信済
     * - UNSENT: 未送信
     *
     */
    officeContact?: 'SENT' | 'UNSENT';
    /**
     * 月例賞与
     */
    monthlyBonus?: {
      /**
       * 前回拠出人数 (A-B)
       */
      oldContributionUsersCount?: number;
      /**
       * 前回拠出口数
       */
      oldContributionUnit?: number;
      /**
       * 前回在籍者人数 (A)
       */
      oldUsersCount?: number;
      /**
       * 前回在籍者口数
       */
      oldUnit?: number;
      /**
       * 入会人数
       */
      joinCount?: number;
      /**
       * 入会口数
       */
      joinUnit?: number;
      /**
       * 口数変更人数
       */
      unitChangeCount?: number;
      /**
       * 口数変更口数(増)
       */
      unitChangeUnitPlus?: number;
      /**
       * 口数変更口数(減)
       */
      unitChangeUnitMinus?: number;
      /**
       * 退会人数
       */
      withdrawalCount?: number;
      /**
       * 退会口数
       */
      withdrawalUnit?: number;
      /**
       * 今回在籍者人数 (C)
       */
      usersCount?: number;
      /**
       * 今回在籍者口数
       */
      unit?: number;
      /**
       * 今回拠出人数 (C-D)
       */
      contributionUsersCount?: number;
      /**
       * 今回拠出口数
       */
      contributionUnit?: number;
      /**
       * 前回休止者人数 (B)
       */
      oldRecessCount?: number;
      /**
       * 前回休止者口数
       */
      oldRecessUnit?: number;
      /**
       * 新規休止人数
       */
      newRecessCount?: number;
      /**
       * 新規休止口数
       */
      newRecessUnit?: number;
      /**
       * 休止中口数変更人数
       */
      recessUnitChangeCount?: number;
      /**
       * 休止中口数変更口数(増)
       */
      recessUnitChangeUnitPlus?: number;
      /**
       * 休止中口数変更口数(減)
       */
      recessUnitChangeUnitMinus?: number;
      /**
       * 休止中の退会人数
       */
      recessWithdrawalCount?: number;
      /**
       * 休止中の退会口数
       */
      recessWithdrawalUnit?: number;
      /**
       * 休止再開人数
       */
      resumeCount?: number;
      /**
       * 休止再開口数
       */
      resumeUnit?: number;
      /**
       * 今回休止者人数 (D)
       */
      recessCount?: number;
      /**
       * 今回休止者口数
       */
      recessUnit?: number;
      /**
       * 繰越金
       */
      carryoverMoney?: number;
      /**
       * 拠出金
       */
      contribution?: number;
      /**
       * 奨励金
       */
      incentives?: number;
      /**
       * 追加件数
       */
      plusCount?: number;
      /**
       * 追加拠出金
       */
      plusContribution?: number;
      /**
       * 追加奨励金
       */
      plusIncentives?: number;
      /**
       * 臨時拠出件数
       */
      specialCount?: number;
      /**
       * 臨時拠出拠出金
       */
      specialContribution?: number;
      /**
       * 臨時拠出奨励金
       */
      specialIncentives?: number;
      /**
       * 拠出金合計 (E)
       */
      contributionTotal?: number;
      /**
       * 配当金(その他入出金明細)
       */
      dividend?: number;
      /**
       * 利息(その他入出金明細)
       */
      interest?: number;
      /**
       * その他入金(その他入出金明細)
       */
      otherPayment?: number;
      /**
       * 事務経費徴収額(その他入出金明細)
       */
      officeExpenses?: number;
      /**
       * その他入出金明細合計 (F)
       */
      otherDepositWithdrawalTotal?: number;
      /**
       * 理事長口未配分株数
       */
      unallocatedBalanceSharesNum?: number;
      /**
       * 理事長口未配分金銭 (G)
       */
      unallocatedBalance?: number;
      /**
       * 退会件数
       */
      withdrawalNum?: number;
      /**
       * 退会精算株数
       */
      withdrawalCalculateSharesNum?: number;
      /**
       * 退会精算額 (H)
       */
      withdrawalCalculate?: number;
      /**
       * 買増退会株数
       */
      additionalPurchaseWithdrawalSharesNum?: number;
      /**
       * 買増退会金額(I)
       */
      additionalPurchaseWithdrawalCalculate?: number;
      /**
       * 残余金 (購入可能代金)(E+F+G-H+I-J)
       */
      residual?: number;
      /**
       * 購入代金
       */
      purchasePrice?: number;
      /**
       * 今回購入株数
       */
      purchaseSharesNum?: number;
      contract?: Array<{
        /**
         * 消費税
         */
        consumptionTax?: number;
        /**
         * 約定株数
         */
        contractSharesNum?: number;
        /**
         * 約定金額
         */
        contractAmount?: number;
        /**
         * 手数料
         */
        commission?: number;
        /**
         * 受渡金額
         */
        deliveryAmount?: number;
        /**
         * 約定単価
         */
        contractUnitPrice?: number;
        /**
         * 約定年月日
         */
        contractYmd?: string;
        /**
         * 受渡年月日
         */
        deliveryYmd?: string;
      }>;
    };
    /**
     * 配当金詳細
     */
    dividend?: {
      /**
       * 配当金総額
       */
      dividendTotal?: number;
      /**
       * 所得税合計
       */
      incomeTaxTotal?: number;
      /**
       * 住民税合計
       */
      residentTaxTotal?: number;
      /**
       * 税引き後配当金
       */
      excludingTaxDividend?: number;
      /**
       * 退会者配当金
       */
      withdrawalDividend?: number;
      contract?: Array<{
        /**
         * 消費税
         */
        consumptionTax?: number;
        /**
         * 約定株数
         */
        contractSharesNum?: number;
        /**
         * 約定金額
         */
        contractAmount?: number;
        /**
         * 手数料
         */
        commission?: number;
        /**
         * 受渡金額
         */
        deliveryAmount?: number;
        /**
         * 約定単価
         */
        contractUnitPrice?: number;
        /**
         * 約定年月日
         */
        contractYmd?: string;
        /**
         * 受渡年月日
         */
        deliveryYmd?: string;
      }>;
    };
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/tradereports/{associationCd}/{tradereportClassification}/{year}/{month}',
      path: {
        year: year,
        month: month,
        tradereportClassification: tradereportClassification,
        associationCd: associationCd,
      },
      errors: {
        400: `bad request`,
      },
    });
  }
}
