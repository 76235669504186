import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { theme } from '@/theme';
import { PrevnextApplications } from '@/types/api/Applications/prevnextApplications';
import { MemberById } from '@/types/api/member';
import { FindApplicationByID } from '@/types/api/Applications/application';
import { printApplicationsComponent } from '@/utils/printApplications';
import { TitleHead } from './SharedParts/TitleHead';
import { UserInfoTable } from './SharedParts/UserInfoTable';
import { ApplicationBottom } from './SharedParts/ApplicationBottom';
import { ApplicationButtons } from './SharedParts/ApplicationButtons';
import { PrintApplicationsButton } from './PrintApplicationsButton/PrintApplicationsButton';

interface ResumeProps {
  application: FindApplicationByID;
  unitType: string;
  userData: MemberById;
  bottomData: PrevnextApplications | undefined;
  handleApplication: (value: 'APPROVAL' | 'REJECTED', comment?: string) => void;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export const ApplicationsResume: FC<ResumeProps> = ({
  application,
  unitType,
  userData,
  bottomData,
  handleApplication,
  isOpen,
  setIsOpen,
}) => (
  <Box
    sx={{
      backgroundColor: theme.palette.system['background-dark'],
      height: '100%',
    }}
  >
    <Stack
      spacing={4}
      sx={{
        backgroundColor: theme.palette.system.white,
        padding: '40px 160px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <PrintApplicationsButton
          onClick={() =>
            printApplicationsComponent('resume_print_content', '再開')
          }
        />
      </Box>
      <Stack id="resume_print_content" spacing={4}>
        <TitleHead
          title="再開"
          status={application.applicationStatus || ''}
          applicationDate={application.applicationDate || ''}
          applicationId={application.applicationId || ''}
          associationCd={application.associationCd || ''}
          proxyApplicantUserName={application.proxyApplicantUserName ?? ''}
          comment={application.rejectComment || ''}
          unitType={unitType}
        />
        <UserInfoTable data={userData} />
      </Stack>
      {application.applicationStatus !== 'COMPLETED' && application.eventNo && (
        <Stack direction="row" sx={{ alignItems: 'center' }} spacing={2}>
          <ApplicationButtons
            status={application.applicationStatus || ''}
            handleApplication={handleApplication}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
          />
        </Stack>
      )}
    </Stack>
    {bottomData && (
      <Box sx={{ padding: '32px 160px' }}>
        <ApplicationBottom data={bottomData} unitType={unitType} />
      </Box>
    )}
  </Box>
);
