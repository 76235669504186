/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MembersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * 【API-B-4001】持株会員一覧取得
   * 持株会員一覧を取得する
   *
   * @param associationCd associationCd
   * @param memberId 持株会員ID
   *
   * @param keyword キーワード：部分一致（会員コード・会員名・社員コード）
   *
   * @param userContributionStatus 会員拠出ステータス
   * - ACTIVE : 稼働中
   * - STOP : 休止中
   * - WITHDRAWN : 退会済
   *
   * @param userRegisteredStatus 会員WEB状況
   * - REGISTERED : 登録済
   * - UNREGISTERED : 未登録
   * - REVOCATION : 失効
   *
   * @param page 取得ページ番号
   *
   * @param perPageSize 1ページあたり件数
   *
   * @param sortKey ソートキー
   * - MEMBER_CD_ASC: 会員コード昇順
   * - MEMBER_CD_DESC: 会員コード降順
   * - MEMBER_NAME_ASC: 会員名昇順
   * - MEMBER_NAME_DESC: 会員名降順
   * - EMPLOYEE_CD_ASC: 社員コード昇順
   * - EMPLOYEE_CD_DESC: 社員コード降順
   * - USER_CONTRIBUTIONSTATUS_ASC: 会員拠出状況昇順
   * - USER_CONTRIBUTIONSTATUS_DESC: 会員拠出状況降順
   * - EXPIRYDAY_ASC: 専用URL初回PASS失効日昇順
   * - EXPIRYDAY_DESC: 専用URL初回PASS失効日降順
   *
   * @returns any members info response
   * @throws ApiError
   */
  public listMembers(
    associationCd: string,
    memberId?: string,
    keyword?: string,
    userContributionStatus?: 'ACTIVE' | 'STOP' | 'WITHDRAWN',
    userRegisteredStatus?: 'REGISTERED' | 'UNREGISTERED' | 'REVOCATION',
    page?: number,
    perPageSize?: number,
    sortKey?:
      | 'MEMBER_CD_ASC'
      | 'MEMBER_CD_DESC'
      | 'MEMBER_NAME_ASC'
      | 'MEMBER_NAME_DESC'
      | 'EMPLOYEE_CD_ASC'
      | 'EMPLOYEE_CD_DESC'
      | 'USER_CONTRIBUTIONSTATUS_ASC'
      | 'USER_CONTRIBUTIONSTATUS_DESC'
      | 'EXPIRYDAY_ASC'
      | 'EXPIRYDAY_DESC'
  ): CancelablePromise<
    {
      members?: Array<{
        /**
         * メンバーID
         */
        memberId?: number;
        /**
         * 会員コード
         */
        memberCd?: string;
        /**
         * 社員コード
         */
        employeeCd?: string;
        /**
         * 氏名（漢字）
         */
        nameKanji?: string;
        /**
         * 氏名（カナ）
         */
        nameKana?: string;
        /**
         * 会員拠出ステータス
         * - ACTIVE : 稼働中
         * - STOP : 休止中
         * - WITHDRAWN : 退会済
         *
         */
        userContributionStatus?: 'ACTIVE' | 'STOP' | 'WITHDRAWN';
        /**
         * 専用URL/初回PASS失効日
         */
        expiryDay?: string;
        /**
         * 会員WEB状況
         * - REGISTERED : 登録済
         * - UNREGISTERED : 未登録
         * - REVOCATION : 失効
         *
         */
        userRegisteredStatus?: 'REGISTERED' | 'UNREGISTERED' | 'REVOCATION';
      }>;
    } & {
      /**
       * 現在ページ番号
       */
      currentPage?: number;
      /**
       * 総ページ数
       */
      totalPage?: number;
      /**
       * １ページあたり件数
       */
      perPageSize?: number;
      /**
       * 総件数
       */
      totalSize?: number;
    }
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/members',
      query: {
        associationCd: associationCd,
        memberId: memberId,
        keyword: keyword,
        userContributionStatus: userContributionStatus,
        userRegisteredStatus: userRegisteredStatus,
        page: page,
        perPageSize: perPageSize,
        sortKey: sortKey,
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-4002】持株会員取得
   * 持株会員を取得する
   *
   * @param memberId ID of info to fetch
   * @returns any members info response
   * @throws ApiError
   */
  public findMember(memberId: number): CancelablePromise<{
    /**
     * メンバーID
     */
    memberId?: number;
    /**
     * ユーザID
     */
    userId?: string;
    /**
     * メールアドレス
     */
    email?: string;
    /**
     * 持株会コード
     */
    associationCd?: string;
    /**
     * 会員コード
     */
    memberCd?: string;
    /**
     * 会員ステータス
     * - UNREGISTERED : 未登録(STARⅣデータ連携直後)
     * - VARIFIED_MAIL : メール認証済み
     * - PERSONAL_INFORMATION_ENTERED : 個人情報入力済み
     * - BEING_ENROLLMENT_PROCESSED : 入会申請中
     * - NORMAL : 通常
     * - BEING_RECESSMENT_PROCESSED : 休止申請中
     * - RECESS : 休止中
     * - BEING_RESUMEMENT_PROCESSED : 再開申請中
     * - BEING_WITHDRAWAL_PROCESSED : 退会申請中
     * - WITHDRAWN : 退会済み
     * - DEREGISTRATION : 登録抹消
     * - REJECT_ENROLLMENT : 入会却下
     *
     */
    userStatus?:
      | 'UNREGISTERED'
      | 'VARIFIED_MAIL'
      | 'PERSONAL_INFORMATION_ENTERED'
      | 'BEING_ENROLLMENT_PROCESSED'
      | 'NORMAL'
      | 'BEING_RECESSMENT_PROCESSED'
      | 'RECESS'
      | 'BEING_RESUMEMENT_PROCESSED'
      | 'BEING_WITHDRAWAL_PROCESSED'
      | 'WITHDRAWN'
      | 'DEREGISTRATION'
      | 'REJECT_ENROLLMENT';
    /**
     * 登録種別
     * - WEB：web経由
     * - STAR：STARⅣ経由
     *
     */
    registeredType?: 'WEB' | 'STAR';
    /**
     * 企業コード
     */
    companyCd?: string;
    /**
     * 事業所コード
     */
    officeCd?: string;
    /**
     * 所属コード
     */
    affiliationCd?: string;
    /**
     * 社員コード
     */
    employeeCode?: string;
    /**
     * 氏名（漢字）
     */
    nameKanji?: string;
    /**
     * 氏名（カナ）
     */
    nameKana?: string;
    /**
     * 生年月日
     */
    birthday?: string;
    /**
     * 郵便番号
     */
    zipcode?: string;
    /**
     * 住所1
     */
    address1?: string;
    /**
     * 住所2
     */
    address2?: string;
    /**
     * 住所3
     */
    address3?: string;
    /**
     * 電話番号
     */
    tel?: string;
    /**
     * 電話種類
     * - MOBILE：携帯
     * - HOME：自宅
     *
     */
    telType?: 'MOBILE' | 'HOME';
    /**
     * 入会日
     */
    joinDt?: string;
    /**
     * 退会日
     */
    withdrawalDt?: string;
    /**
     * 専用URL/初回PASS失効日
     */
    expiryDay?: string;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/members/{memberId}',
      path: {
        memberId: memberId,
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * @deprecated
   * 【API-B-4003】持株会員情報更新
   * 持株会員画面で修正した情報を更新する
   *
   * @param memberId メンバーID
   * @param requestBody members member update request
   * @returns any members member update response
   * @throws ApiError
   */
  public updateMembersMember(
    memberId: number,
    requestBody: {
      /**
       * 社員コード
       */
      employeeCode?: string;
      /**
       * 生年月日
       */
      birthday?: string;
      /**
       * 氏名漢字
       */
      nameKanji?: string;
      /**
       * 氏名カナ
       */
      nameKana?: string;
      /**
       * 郵便番号
       */
      zipcode?: string;
      /**
       * 住所1
       */
      address1?: string;
      /**
       * 住所2
       */
      address2?: string;
      /**
       * 住所3
       */
      address3?: string;
      /**
       * 電話種類
       * - MOBILE：携帯
       * - HOME：自宅
       *
       */
      telType?: 'MOBILE' | 'HOME';
      /**
       * 電話番号
       */
      tel?: string;
      /**
       * 会員事業所コード
       */
      officeCd?: string;
      /**
       * 会員所属コード
       */
      affiliationCd?: string;
    }
  ): CancelablePromise<{
    /**
     * OK,NG
     */
    update_status?: string;
  }> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/members/{memberId}',
      path: {
        memberId: memberId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-4008】持株会員拠出情報取得
   * 拠出情報を取得する
   *
   * @param memberId ID of info to fetch
   * @returns any Contribution info response
   * @throws ApiError
   */
  public findMemberContribution(memberId: number): CancelablePromise<{
    /**
     * 持株会コード
     */
    associationCd?: string;
    /**
     * 会員コード
     */
    memberCd?: string;
    /**
     * 拠出開始年月日
     */
    contributionStartDate?: string;
    /**
     * 月例拠出口数
     */
    monthlyUnit?: number;
    /**
     * 月例休止開始年月日
     */
    monthlyRecessStartDate?: string;
    /**
     * 月例休止終了年月日
     */
    monthlyRecessEndDate?: string;
    /**
     * 賞与拠出口数
     */
    bonusUnit?: number;
    /**
     * 賞与拠出休止区分
     */
    bonusRecessClassification?: string;
    /**
     * 賞与_口数(x倍)
     */
    bonusRate?: number;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/members/{memberId}/contributions',
      path: {
        memberId: memberId,
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-4009】持株会員証券口座情報取得
   * 証券口座情報を取得する
   *
   * @param memberId ID of info to fetch
   * @returns any SecuritiesAccount info response
   * @throws ApiError
   */
  public findMemberSecuritiesAccount(memberId: number): CancelablePromise<{
    /**
     * 部店コード
     */
    branchCd?: string;
    /**
     * 部店名
     */
    branchName?: string;
    /**
     * 口座番号
     */
    accountNumber?: string;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/members/{memberId}/securitiesaccounts',
      path: {
        memberId: memberId,
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-4010】持株会員資産情報取得
   * 資産情報を取得する
   *
   * @param memberId ID of info to fetch
   * @returns any asset info response
   * @throws ApiError
   */
  public findMemberAsset(memberId: number): CancelablePromise<{
    /**
     * 現在の株数
     * = 旧株 (株) + 第一新株 (株) + 第二新株 (株)
     *
     */
    stocksNumber?: number;
    /**
     * 時価評価額
     */
    marketValue?: number;
    /**
     * 取得金額
     * = 平均買付単価 * 現在の株数
     *
     */
    acquisitionAmount?: number;
    /**
     * 評価損益
     * = 時価評価額 - 取得金額
     *
     */
    valuationGainLoss?: number;
    /**
     * 終値
     */
    complementaryClosingPrice?: number;
    /**
     * 旧株 (株)
     */
    oldStock?: number;
    /**
     * 第一新株 (株)
     */
    firstNewStock?: number;
    /**
     * 第二新株 (株)
     */
    secondNewStock?: number;
    /**
     * 繰越金額 (円)
     */
    carriedForwardAmount?: number;
    /**
     * 入金累計 (円)
     */
    cumulativeDeposit?: number;
    /**
     * 拠出金累計 (円)
     */
    cumulativeContribution?: number;
    /**
     * 奨励金累計 (円)
     */
    cumulativeIncentives?: number;
    /**
     * 配当金累計 (円)
     */
    cumulativeDividend?: number;
    /**
     * 利息累計 (円)
     */
    cumulativeInterest?: number;
    /**
     * その他入金累計 (円)
     */
    cumulativeOtherDeposits?: number;
    /**
     * 出金累計 (円)
     */
    cumulativeWithdrawal?: number;
    /**
     * 事務経費累計 (円)
     */
    cumulativeOfficeExpenses?: number;
    /**
     * 引き出し株数 (株)
     */
    withdrawSharesCnt?: number;
    /**
     * 平均買付価格 (円)|平均買付単価
     */
    purchaseAvarageUnitPrice?: number;
    /**
     * 最終更新日時 (持株会員預り残高TBL)
     */
    lastUpdated?: string;
    /**
     * 日付
     */
    targetDate?: string;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/members/{memberId}/assets',
      path: {
        memberId: memberId,
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-4011】持株会員残高明細取得
   * 残高明細取得を取得する
   *
   * @param memberId ID of info to fetch
   * @param year balancereport year
   * @returns any balancereport info response
   * @throws ApiError
   */
  public findMemberBalancereport(
    memberId: number,
    year: number
  ): CancelablePromise<{
    tradeHistories?: Array<{
      /**
       * 投資月度
       */
      inverstmentYm?: string;
      /**
       * 摘要
       * 旧株　　　　　　  　第１新株　　　　　　　 　第２新株
       * 01：月例追加        31：月例追加（１）       61：月例追加（２）
       * 02：月例（賞与）    32：月例（賞与）（１）   62：月例（賞与）（２）
       * 03：月例            33：月例（１）           63：月例（２）
       * 04：賞与追加        34：賞与追加（１）       64：賞与追加（２）
       * 05：賞与            35：賞与（１）           65：賞与（２）
       * 06：臨時            36：臨時（１）           66：臨時（２）
       * 07：臨時募集        37：臨時募集（１）       67：臨時募集（２）
       * 08：未配分株式      38：未配分株式（１）     68：未配分株式（２）
       * 09：配当金          39：配当金（１）         69：配当金（２）
       * 10：配当金再投資    40：配当金再投資（１）   70：配当金再投資（２）
       * 11：株式分割        41：株式分割（１）       71：株式分割（２）
       * 12：事務経費      　42：事務経費（１）     　72：事務経費（２）
       * 13：利息            43：利息（１）           73：利息（２）
       * 14：その他入出金    44：その他入出金（１）   74：その他入出金（２）
       * 15：有償増資        45：有償増資（１）       75：有償増資（２）
       * 16：減資            46：減資（１）           76：減資（２）
       * 17：新旧合併        47：新旧合併（１）       77：新旧合併（２）
       * 18：一部引出        48：一部引出（１）       78：一部引出（２）
       * 19：るいとう振替    49：るいとう振替（１）   79：るいとう振替（２）
       * 20：株券組入        50：株券組入（１）       80：株券組入（２）
       * 21：買増            51：買増（１）           81：買増（２）
       * 22：返金            52：返金（１）           82：返金（２）
       * 23：入庫            53：入庫（１）           83：入庫（２）
       * 24：出庫            54：出庫（１）           84：出庫（２）
       * 25：奨励金          55：奨励金（１）         85：奨励金（２）
       *
       */
      inverstmentDescriptionClassification?: string;
      /**
       * 拠出金
       */
      contribution?: number;
      /**
       * 奨励金
       */
      incentives?: number;
      /**
       * 入金合計/入金金額
       */
      depositAmountTotal?: number;
      /**
       * 出金金額
       */
      withdrawalAmount?: number;
      /**
       * 購入株数
       */
      stockPurchaseNumber?: number;
      /**
       * 引出株数
       */
      stockDrawerNumber?: number;
      /**
       * 単価
       */
      unitPrice?: number;
      /**
       * 新旧区分
       * - OLD : 旧株
       * - FIRST : 第一新株
       * - SECOND : 第二新株
       *
       */
      newOldClassification?: 'OLD' | 'FIRST' | 'SECOND';
      /**
       * 更新日時 (持株会員投資履歴TBL)
       */
      updated?: string;
    }>;
    rightAllocationHistories?: Array<{
      /**
       * 割当年月日
       */
      allocationYmd?: string;
      /**
       * 新旧区分
       * - OLD : 旧株
       * - FIRST : 第一新株
       * - SECOND : 第二新株
       *
       */
      newOldClassification?: 'OLD' | 'FIRST' | 'SECOND';
      /**
       * 割当株数
       */
      allocationSharesNum?: string;
      /**
       * 割当金銭（一株当たり）
       */
      allocationMoneyPerShare?: number;
      /**
       * 配当金
       */
      dividend?: number;
      /**
       * 所得税
       */
      incomeTax?: number;
      /**
       * 住民税
       */
      residentTax?: number;
      /**
       * 税引後配当金 (円)
       * = 配当金 - 所得税 - 住民税
       *
       */
      afterTaxDividend?: number;
      /**
       * 更新日時 (持株会員権利割当履歴TBL)
       */
      updated?: string;
    }>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/members/{memberId}/balancereports',
      path: {
        memberId: memberId,
      },
      query: {
        year: year,
      },
      errors: {
        400: `bad request`,
      },
    });
  }
}
