/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { ja } from 'date-fns/locale';
import { BasicTable } from '@/components/molecules/Table/BasicTable';
import { TableHeadRow } from '@/components/atoms/Table/TableHeadRow';
import { TableHeadCell } from '@/components/atoms/Table/TableHeadCell';
import { TableBodyRow } from '@/components/atoms/Table/TableBodyRow';
import { TableBodyCell } from '@/components/atoms/Table/TableBodyCell';
import { ApplicationTemporary } from '@/types/api/Applications/applications';
import { SearchByUserInputs } from './SharedParts/SearchByUserInputs';
import { IndexTop } from './SharedParts/IndexTop';

type SortKeyType = 'CREATED_ASC' | 'CREATED_DESC';

interface TemporaryProps {
  type: string;
  data: ApplicationTemporary[];
  keywordValue?: string;
  onClickSearch: (value: string) => void;
  sortKey?: SortKeyType;
  updateSortKey?: (value: SortKeyType) => void;
  applicationsTemporaryError?: any;
}
export const ApplicationsTemporaryIndex: FC<TemporaryProps> = ({
  type,
  data,
  keywordValue,
  onClickSearch,
  applicationsTemporaryError = undefined,
  sortKey,
  updateSortKey,
}) => {
  const navigate = useNavigate();
  const onClickTemporaryContact = () => {
    navigate('/applications/temporary/contact');
  };
  return (
    <Stack spacing={4}>
      <IndexTop type={type} />
      <Stack spacing={1} px={5}>
        <SearchByUserInputs
          keywordValue={keywordValue}
          onClickSearch={onClickSearch}
          onClickTemporaryContact={onClickTemporaryContact}
          displayType="temporary"
        />
        <BasicTable>
          <TableHeadRow>
            <TableHeadCell title="申請ID" width="15%" />
            <TableHeadCell title="詳細" width="42.5%" />
            <TableHeadCell
              type={sortKey?.includes('CREATED') ? 'sort' : 'normal'}
              sort={() => {}}
              sortType={sortKey?.includes('ASC') ? 'ASC' : 'DESC'}
              width="42.5%"
            >
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  if (!updateSortKey) return;
                  if (sortKey === 'CREATED_ASC') updateSortKey('CREATED_DESC');
                  else updateSortKey('CREATED_ASC');
                }}
              >
                送信日時
              </Box>
            </TableHeadCell>
          </TableHeadRow>
          {applicationsTemporaryError && <div>データ取得に失敗しました</div>}
          {!applicationsTemporaryError &&
            data.map((d) => (
              <TableBodyRow
                key={d.officeSpecialApplicationId}
                url={`/applications/temporary/${
                  d.officeSpecialApplicationId || ''
                }`}
              >
                <TableBodyCell value={d.officeSpecialApplicationId} />
                <TableBodyCell value={d.detail} />
                <TableBodyCell
                  value={
                    d.created
                      ? format(parseISO(d.created), 'yyyy/MM/dd (E) HH:mm', {
                          locale: ja,
                        })
                      : ''
                  }
                />
              </TableBodyRow>
            ))}
        </BasicTable>
      </Stack>
    </Stack>
  );
};
