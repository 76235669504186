import useSWR from 'swr';
import { useRecoilState } from 'recoil';
import { HeaderAssociationsList } from '@/recoil/associations/associations';
import { useEffect } from 'react';
import { AUTH_TOKEN } from '@/constants/Storage/StorageKey';
import { appClient } from '..';

export const useListAssociations = () => {
  const [, setAssociationList] = useRecoilState(HeaderAssociationsList);
  const token = localStorage.getItem(AUTH_TOKEN);
  const { data, error } = useSWR(
    token ? `/api/associations/${token}` : null,
    () => appClient.associations.listAssociations()
  );

  useEffect(() => {
    if (data?.associations) setAssociationList(data.associations);
  }, [data]);

  return { data: data?.associations ?? [], error };
};
