import { OfficeUsersLIst } from '@/components/organisms/OfficeUsers/OfficeUsersLIst';
import { SortKey } from '@/constants/OfficeUser/officeUsersIndex';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { useOfficeUsersIndex } from '@/services/customHooks/useOfficeUsersIndex';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

export const OfficeUsersIndex: FC = () => {
  // @ts-ignore
  const { associationCd = '' } = useRecoilValue(SelectedAssociationState);
  const navigate = useNavigate();
  const [isSort, setIsSort] = useState(false);
  const { data: users, setQueryParams } = useOfficeUsersIndex({});
  useHelmetHandler({
    title: '持株会事務局員一覧',
  });

  useEffect(() => {
    setQueryParams({
      associationCd,
      fetchType: 'include',
    });
  }, [associationCd]);

  const handleClickAuthoritySort = () => {
    const newQueryParams = {
      sortKey: isSort
        ? SortKey.AutorityCalassificationDesc
        : SortKey.AutorityCalassificationAsc,
    };

    setQueryParams(newQueryParams);
    setIsSort(!isSort);
  };

  const handleClickRow = (id: string) => {
    // 列をクリック時に担当者詳細に遷移する処理
    navigate(`/office-users/${id}`);
  };

  const handleClickRegisterButton = () => {
    navigate('/office-users/create');
  };

  const handleClickAddButton = () => {
    navigate('/office-users/add');
  };

  return (
    <OfficeUsersLIst
      isSort={isSort}
      users={users}
      handleClickAuthoritySort={handleClickAuthoritySort}
      handleClickRow={handleClickRow}
      handleClickRegisterButton={handleClickRegisterButton}
      handleClickAddButton={handleClickAddButton}
    />
  );
};
