import { Box, styled } from '@mui/material';
import { useRecoilState } from 'recoil';
import { memberInfoState } from '@/recoil/memberInfo/memberInfo';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { appClient } from '@/services';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { Information } from './Information';
import { MembersHeader } from './MembersHeader';

const Wrapper = styled(Box)({
  width: '100%',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: '32px 40px',
  gap: '24px',
});

export const MemberInformation = () => {
  const { memberId } = useParams();
  const [memberData, setMemberData] = useRecoilState(memberInfoState);
  useHelmetHandler({
    title: '持株会会員詳細 会員情報 詳細',
  });

  const getMemberInfo = useCallback(async () => {
    try {
      if (!memberId) return;
      const res = await appClient.members.findMember(parseInt(memberId, 10));
      setMemberData(res);
    } catch (e) {
      // Handle getMemberInfo error
    }
  }, [memberId]);

  useEffect(() => {
    void getMemberInfo();
  }, [getMemberInfo]);

  return (
    <Wrapper>
      <MembersHeader memberData={memberData} />
      <Information />
    </Wrapper>
  );
};
