import { SelectBox, SelectBoxProps } from '@/components/atoms/Input/SelectBox';
import { TextInput } from '@/components/atoms/Input/TextInput';
import { PrimaryTableBodyTdMulti } from '@/components/atoms/Table/PrimaryTableBodyTdMulti';
import { PrimaryTableBodyTdSingle } from '@/components/atoms/Table/PrimaryTableBodyTdSingle';
import { PrimaryTable } from '@/components/molecules/Table/PrimaryTable';
import { reasonsForWithdrawal } from '@/constants/SelectBox/choicesForSelectBox';
import { CreateApplication } from '@/types/api/applications';
import { FindMemberAsset } from '@/types/api/members';
import {
  Box,
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Stack,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { Controller, useController, UseFormReturn } from 'react-hook-form';
import {
  validateBranchCode,
  validateAccountNumber,
  validateAccountYuchoSymbol,
  validateAccountYuchoNumber,
  validateName,
  validateInstitutionCode,
} from '@/utils/validation';
import { numberFormat } from '@/utils/numberFormat';
import { theme } from '@/theme';
import { IsNumeric } from '@/utils/stringProcessor';
import { GetAssociation } from '@/types/api/associations';

type Props = {
  isConfirmed: boolean;
  stocksNumber: FindMemberAsset['stocksNumber'];
  methods: UseFormReturn<CreateApplication>;
  setIsUnit: (isUnit: boolean) => void;
  association?: GetAssociation;
};

const StyledTextInput: FC<TextFieldProps> = ({ name = '', ...props }) => {
  const {
    field: { ref, ...rest },
  } = useController({ name });
  return <TextInput inputRef={ref} {...rest} {...props} />;
};

const StyledReasonTextInput: FC<TextFieldProps> = ({ name = '', ...props }) => {
  const {
    field: { ref, ...rest },
  } = useController({ name });
  return (
    <TextInput
      inputRef={ref}
      {...rest}
      {...props}
      placeholder="退会理由を入力してください"
      maxRows={4}
      multiline
      sx={{ width: '100%', minHeight: '130px' }}
      inputProps={{
        style: {
          height: '96px',
          resize: 'none',
          color: theme.palette.system['text-normal'],
          paddingLeft: '4px',
        },
      }}
    />
  );
};

const StyledSelectBox: FC<SelectBoxProps> = ({ name = '', ...props }) => {
  const {
    field: { ref, ...rest },
  } = useController({ name });

  return <SelectBox inputRef={ref} {...rest} {...props} />;
};
const StyledRadio: FC<Pick<FormControlLabelProps, 'value' | 'label'>> = ({
  value,
  label,
}) => (
  <FormControlLabel
    value={value}
    control={<Radio sx={{ p: 0, mr: 1 }} />}
    componentsProps={{
      typography: {
        variant: 'body-main/regular',
      },
    }}
    label={label}
    sx={{ m: 0 }}
  />
);
const StyledRadioGroup: FC<RadioGroupProps> = ({
  name = '',
  children,
  ...props
}) => {
  const {
    field: { ref, ...rest },
  } = useController({ name });

  return (
    <RadioGroup sx={{ gap: 2 }} ref={ref} {...rest} {...props}>
      {children}
    </RadioGroup>
  );
};

export const MemberProxyApplicationBodyWithdrawal: FC<Props> = ({
  isConfirmed,
  stocksNumber,
  methods,
  setIsUnit,
  association,
}) => {
  const watchReason = methods.watch('applicationWithdrawal.reason');
  const watchInstitutionType = methods.watch(
    'applicationWithdrawal.institutionType'
  );

  const {
    formState: { errors },
  } = methods;

  const elementCheckConfirm = () => {
    setIsUnit(true);
    if (!methods.getValues('applicationWithdrawal.reason')) {
      setIsUnit(false);
    } else if (methods.getValues('applicationWithdrawal.reason') === 'OTHER') {
      if (!methods.getValues('applicationWithdrawal.otherReasons')) {
        setIsUnit(false);
      }
    }
    if (
      methods.getValues('applicationWithdrawal.institutionType') === 'OTHER'
    ) {
      if (
        methods.getValues(
          'applicationWithdrawal.generalAccount.institutionCode'
        ) === '' ||
        methods.getValues(
          'applicationWithdrawal.generalAccount.institutionName'
        ) === '' ||
        methods.getValues('applicationWithdrawal.generalAccount.branchCode') ===
          '' ||
        methods.getValues('applicationWithdrawal.generalAccount.branchName') ===
          '' ||
        methods.getValues(
          'applicationWithdrawal.generalAccount.accountNumber'
        ) === ''
      ) {
        setIsUnit(false);
      } else if (
        !validateBranchCode(
          methods.getValues('applicationWithdrawal.generalAccount.branchCode')
        ) ||
        !validateAccountNumber(
          methods.getValues(
            'applicationWithdrawal.generalAccount.accountNumber'
          )
        ) ||
        !validateName(
          methods.getValues(
            'applicationWithdrawal.generalAccount.institutionName'
          )
        ) ||
        !validateName(
          methods.getValues('applicationWithdrawal.generalAccount.branchName')
        ) ||
        !validateInstitutionCode(
          methods.getValues(
            'applicationWithdrawal.generalAccount.institutionCode'
          )
        )
      ) {
        setIsUnit(false);
      }
    } else if (
      methods.getValues('applicationWithdrawal.accountYuchoSymbol') === '' ||
      methods.getValues('applicationWithdrawal.accountYuchoNumber') === ''
    ) {
      setIsUnit(false);
    } else if (
      !validateAccountYuchoSymbol(
        methods.getValues('applicationWithdrawal.accountYuchoSymbol')
      ) ||
      !validateAccountYuchoNumber(
        methods.getValues('applicationWithdrawal.accountYuchoNumber')
      )
    ) {
      setIsUnit(false);
    }
  };

  const switchWithdrawalReason = (value: string) => {
    switch (value) {
      case 'LEAVE_ABSENCE':
        return '退職';
      case 'FAMILY_MATTERS':
        return '家庭の事情';
      case 'ECONOMIC':
        return '経済的理由';
      case 'OTHER':
        return 'その他（理由を記入）';
      default:
        return '退会理由を選択してください';
    }
  };

  return (
    <>
      <PrimaryTable title="退会理由">
        <PrimaryTableBodyTdMulti title="退会理由" row={1}>
          {isConfirmed ? (
            <Stack>
              <Typography>
                {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call */}
                {
                  reasonsForWithdrawal.find((item) => item.key === watchReason)
                    ?.value
                }
              </Typography>
              {watchReason === 'OTHER' && (
                <Typography>
                  {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call */}
                  {methods.getValues('applicationWithdrawal.otherReasons')}
                </Typography>
              )}
            </Stack>
          ) : (
            <>
              <StyledSelectBox
                name="applicationWithdrawal.reason"
                items={reasonsForWithdrawal}
                width="320px"
                isKeyAValue
                value={switchWithdrawalReason(
                  methods.getValues('applicationWithdrawal.reason') || ''
                )}
                onChange={(e) => {
                  methods.setValue(
                    'applicationWithdrawal.reason',
                    e.target.value as Exclude<
                      CreateApplication['applicationWithdrawal'],
                      undefined
                    >['reason']
                  );
                  elementCheckConfirm();
                }}
              />
              {watchReason === 'OTHER' && (
                <Box mt={2}>
                  <StyledReasonTextInput
                    name="applicationWithdrawal.otherReasons"
                    onChange={(e) => {
                      methods.setValue(
                        'applicationWithdrawal.otherReasons',
                        e.target.value
                      );
                      elementCheckConfirm();
                    }}
                  />
                </Box>
              )}
            </>
          )}
        </PrimaryTableBodyTdMulti>
      </PrimaryTable>
      {association?.withdrawalAllSettlementUsageClassification === '0' && (
        <PrimaryTable title="精算方法">
          {!isConfirmed && (
            <PrimaryTableBodyTdSingle>
              <Typography>
                100株未満の株式を現金精算するか、100株まで買増しして退会するかご選択ください。
              </Typography>
            </PrimaryTableBodyTdSingle>
          )}

          <PrimaryTableBodyTdMulti
            title="売買単位"
            SecondaryTitle="未満株式持分"
            row={1}
          >
            {isConfirmed ? (
              <Typography>
                {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call */}
                {methods.getValues('applicationWithdrawal.isAdditionalOption')
                  ? '臨時拠出により買増し'
                  : '持株会に売却（単元株以上は証券口座に移管）'}
              </Typography>
            ) : (
              <StyledRadioGroup
                name="applicationWithdrawal.isAdditionalOption"
                onChange={(e) =>
                  methods.setValue(
                    'applicationWithdrawal.isAdditionalOption',
                    String(e.target.value) === 'true'
                  )
                }
              >
                <StyledRadio
                  value={false}
                  label="持株会に売却（単元株以上は証券口座に移管）"
                />
                {association.withdrawalAddPurchaseUsageClassification ===
                  '1' && (
                  <>
                    <StyledRadio value label="臨時拠出により買増し" />
                    <Box
                      sx={{
                        display: 'flex',
                        marginLeft: '30px',
                        color: 'system.text-light',
                      }}
                    >
                      <Typography variant="body-sub/regular">
                        買増株数
                      </Typography>
                      <Typography
                        variant="body-sub/bold"
                        sx={{ marginLeft: '8px' }}
                      >
                        {stocksNumber
                          ? numberFormat(100 - (stocksNumber % 100), {
                              maximumFractionDigits:
                                association.investmentDigit,
                            })
                          : '0'}
                        株
                      </Typography>
                    </Box>
                  </>
                )}
              </StyledRadioGroup>
            )}
          </PrimaryTableBodyTdMulti>
        </PrimaryTable>
      )}
      <PrimaryTable title="精算金振込口座(会員名義口座)">
        {!isConfirmed && (
          <PrimaryTableBodyTdSingle>
            <Typography>
              退会精算金を振込みする際に必要となりますので、会員本人名義の銀行口座情報を入力してください。
            </Typography>
          </PrimaryTableBodyTdSingle>
        )}

        <PrimaryTableBodyTdMulti title="金融機関の種類">
          {isConfirmed ? (
            <Typography>
              {watchInstitutionType === 'OTHER'
                ? 'ゆうちょ銀行以外'
                : 'ゆうちょ銀行'}
            </Typography>
          ) : (
            <StyledRadioGroup
              name="applicatioanWithdrawal.institutionType"
              onChange={(e) => {
                if (e.target.value === 'OTHER') {
                  methods.setValue(
                    'applicationWithdrawal.institutionType',
                    'OTHER'
                  );
                } else {
                  methods.setValue(
                    'applicationWithdrawal.institutionType',
                    'YUCHO'
                  );
                }
                elementCheckConfirm();
              }}
              value={methods.getValues('applicationWithdrawal.institutionType')}
              row
            >
              <StyledRadio value="OTHER" label="ゆうちょ銀行以外" />
              <StyledRadio value="YUCHO" label="ゆうちょ銀行" />
            </StyledRadioGroup>
          )}
        </PrimaryTableBodyTdMulti>
        {watchInstitutionType === 'OTHER' ? (
          <>
            <PrimaryTableBodyTdMulti
              title="金融機関コード"
              subTitle={isConfirmed ? '' : '※半角数字4桁'}
            >
              {isConfirmed ? (
                <Typography>
                  {methods.getValues(
                    'applicationWithdrawal.generalAccount.institutionCode'
                  )}
                </Typography>
              ) : (
                <Controller
                  name="applicationWithdrawal.generalAccount.institutionCode"
                  control={methods.control}
                  render={({ field }) => (
                    <StyledTextInput
                      {...field}
                      name="applicationWithdrawal.generalAccount.institutionCode"
                      placeholder="例：1234"
                      sx={{ width: 136 }}
                      onChange={(e) => {
                        if (
                          (IsNumeric(e.target.value) &&
                            e.target.value.length <= 4) ||
                          e.target.value === ''
                        ) {
                          methods.setValue(
                            'applicationWithdrawal.generalAccount.institutionCode',
                            e.target.value
                          );
                        }
                        elementCheckConfirm();
                      }}
                    />
                  )}
                />
              )}
            </PrimaryTableBodyTdMulti>
            <PrimaryTableBodyTdMulti title="金融機関名">
              {isConfirmed ? (
                <Typography>
                  {methods.getValues(
                    'applicationWithdrawal.generalAccount.institutionName'
                  )}
                </Typography>
              ) : (
                <Controller
                  name="applicationWithdrawal.generalAccount.institutionName"
                  control={methods.control}
                  render={({ field }) => (
                    <StyledTextInput
                      {...field}
                      name="applicationWithdrawal.generalAccount.institutionName"
                      placeholder="例：〇〇銀行"
                      sx={{ width: 196 }}
                      onChange={(e) => {
                        if (e.target.value.length <= 20) {
                          methods.setValue(
                            'applicationWithdrawal.generalAccount.institutionName',
                            e.target.value
                          );
                        }
                        elementCheckConfirm();
                      }}
                    />
                  )}
                />
              )}
            </PrimaryTableBodyTdMulti>
            <PrimaryTableBodyTdMulti
              title="支店コード"
              subTitle={isConfirmed ? '' : '※半角数字3桁'}
            >
              {isConfirmed ? (
                <Typography>
                  {methods.getValues(
                    'applicationWithdrawal.generalAccount.branchCode'
                  )}
                </Typography>
              ) : (
                <Controller
                  name="applicationWithdrawal.generalAccount.branchCode"
                  control={methods.control}
                  render={({ field }) => (
                    <StyledTextInput
                      {...field}
                      name="applicationWithdrawal.generalAccount.branchCode"
                      placeholder="例：123"
                      sx={{ width: 116 }}
                      inputProps={{ maxLength: 3 }}
                      onChange={(e) => {
                        if (
                          (IsNumeric(e.target.value) &&
                            e.target.value.length <= 3) ||
                          e.target.value === ''
                        ) {
                          methods.setValue(
                            'applicationWithdrawal.generalAccount.branchCode',
                            e.target.value
                          );
                          elementCheckConfirm();
                        }
                      }}
                    />
                  )}
                />
              )}
            </PrimaryTableBodyTdMulti>
            <PrimaryTableBodyTdMulti title="支店名">
              {isConfirmed ? (
                <Typography>
                  {methods.getValues(
                    'applicationWithdrawal.generalAccount.branchName'
                  )}
                </Typography>
              ) : (
                <Controller
                  name="applicationWithdrawal.generalAccount.branchName"
                  control={methods.control}
                  render={({ field }) => (
                    <StyledTextInput
                      {...field}
                      name="applicationWithdrawal.generalAccount.branchName"
                      placeholder="例：〇〇支店"
                      sx={{ width: 196 }}
                      onChange={(e) => {
                        if (e.target.value.length <= 20) {
                          methods.setValue(
                            'applicationWithdrawal.generalAccount.branchName',
                            e.target.value
                          );
                          elementCheckConfirm();
                        }
                      }}
                    />
                  )}
                />
              )}
            </PrimaryTableBodyTdMulti>
            <PrimaryTableBodyTdMulti title="預金種別">
              {isConfirmed ? (
                <Typography>
                  {(() => {
                    switch (
                      methods.getValues(
                        'applicationWithdrawal.generalAccount.accountType'
                      )
                    ) {
                      case 'SAVINGS': {
                        return '普通';
                      }
                      case 'CURRENT': {
                        return '当座';
                      }
                      case 'OTHER': {
                        return '貯蓄';
                      }
                      default: {
                        return null;
                      }
                    }
                  })()}
                </Typography>
              ) : (
                <StyledRadioGroup
                  name="applicationWithdrawal.generalAccount.accountType"
                  row
                >
                  <StyledRadio value="SAVINGS" label="普通" />
                  <StyledRadio value="CURRENT" label="当座" />
                  <StyledRadio value="OTHER" label="貯蓄" />
                </StyledRadioGroup>
              )}
            </PrimaryTableBodyTdMulti>
            <PrimaryTableBodyTdMulti
              title="口座番号"
              subTitle={isConfirmed ? '' : '※半角数字7桁'}
            >
              {isConfirmed ? (
                <Typography>
                  {methods.getValues(
                    'applicationWithdrawal.generalAccount.accountNumber'
                  )}
                </Typography>
              ) : (
                <Controller
                  name="applicationWithdrawal.generalAccount.accountNumber"
                  control={methods.control}
                  render={({ field }) => (
                    <StyledTextInput
                      {...field}
                      name="applicationWithdrawal.generalAccount.accountNumber"
                      placeholder="例：1234567"
                      sx={{ width: 116 }}
                      inputProps={{ maxLength: 7 }}
                      onChange={(e) => {
                        if (
                          (IsNumeric(e.target.value) &&
                            e.target.value.length <= 7) ||
                          e.target.value === ''
                        ) {
                          methods.setValue(
                            'applicationWithdrawal.generalAccount.accountNumber',
                            e.target.value
                          );
                          elementCheckConfirm();
                        }
                      }}
                    />
                  )}
                />
              )}
            </PrimaryTableBodyTdMulti>
          </>
        ) : (
          <PrimaryTableBodyTdMulti title="店番・番号" row={1}>
            {isConfirmed ? (
              <Typography>
                {`${
                  methods.getValues(
                    'applicationWithdrawal.accountYuchoSymbol'
                  ) ?? ''
                } - ${
                  methods.getValues(
                    'applicationWithdrawal.accountYuchoNumber'
                  ) ?? ''
                }`}
              </Typography>
            ) : (
              <Box display="flex" alignItems="flex-end" gap={1}>
                <Box>
                  <Typography
                    variant="body-sub/regular"
                    sx={{ display: 'block', marginBottom: '4px' }}
                  >
                    店番
                  </Typography>
                  <Controller
                    name="applicationWithdrawal.accountYuchoSymbol"
                    control={methods.control}
                    render={({ field }) => (
                      <StyledTextInput
                        {...field}
                        name="applicationWithdrawal.accountYuchoSymbol"
                        placeholder="例：12345"
                        sx={{ width: 96 }}
                        inputProps={{ maxLength: 5 }}
                        onChange={(e) => {
                          let { value } = e.target;
                          if (
                            (IsNumeric(e.target.value) &&
                              e.target.value.length < 1) ||
                            e.target.value === ''
                          ) {
                            value = '';
                          }
                          methods.setValue(
                            'applicationWithdrawal.accountYuchoSymbol',
                            value
                          );
                          elementCheckConfirm();
                        }}
                      />
                    )}
                  />
                </Box>
                <Typography
                  variant="body-sub/regular"
                  sx={{ display: 'block', margin: '0 8px 8px' }}
                >
                  -
                </Typography>
                <Box>
                  <Typography
                    variant="body-sub/regular"
                    sx={{ display: 'block', marginBottom: '4px' }}
                  >
                    番号
                  </Typography>
                  <Controller
                    name="applicationWithdrawal.accountYuchoNumber"
                    control={methods.control}
                    render={({ field }) => (
                      <StyledTextInput
                        {...field}
                        name="appslicationWithdrawal.accountYuchoNumber"
                        placeholder="例：12345678"
                        sx={{ width: 128 }}
                        inputProps={{ maxLength: 8 }}
                        onChange={(e) => {
                          let { value } = e.target;
                          if (
                            (IsNumeric(e.target.value) &&
                              e.target.value.length < 1) ||
                            e.target.value === ''
                          ) {
                            value = '';
                          }
                          methods.setValue(
                            'applicationWithdrawal.accountYuchoNumber',
                            value
                          );
                          elementCheckConfirm();
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>
            )}
          </PrimaryTableBodyTdMulti>
        )}
      </PrimaryTable>
    </>
  );
};
