/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
import { FC } from 'react';
import { theme } from '@/theme/theme';
import {
  Box,
  Typography,
  styled,
  TableContainer,
  Paper,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
} from '@mui/material';
import {
  MemberBalancereports,
  RightAllocationHistories,
  TradeHistories,
} from '@/types/api/memberBalanceReports';
import { numberFormat } from '@/utils/numberFormat';
import { BalanceInquiryHeading } from './BalanceInquiryHeading';

const TableCellColors = {
  typeA: '#CCE9EB',
  typeB: '#EFFBFC',
  typeC: '#3D9EA3',
};

const TableCellWidth = {
  typeA: 54,
  typeB: 135,
  typeC: 140,
  typeD: 116,
};

const Wrapper = styled(Box)({
  marginTop: 24,
  width: 1152,
  margin: '24px auto',
});

const TableWrapper = styled(Box)({
  // overflow: 'scroll',
});

const TablePaper = styled(Paper)(() => {
  const { breakpoints, palette } = theme;
  return {
    marginTop: 24,
    borderWidth: 1,
    borderRadius: 4,
    borderStyle: 'solid',
    borderColor: palette.system.separator,

    [breakpoints.down('sm')]: {
      borderRadius: 0,
    },
  };
});

const Head = styled(TableHead)(() => {
  const { palette } = theme;
  return {
    width: '100%',
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.system.separator,
  };
});

const BaseRow = styled(TableRow)(() => {
  const { palette } = theme;

  return {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.system.separator,

    '&:last-of-type': {
      borderBottomWidth: 0,
    },
  };
});

const BaseCell = styled(TableCell)(() => {
  const { palette } = theme;

  return {
    padding: '12px 8px',
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.system.separator,

    '&:last-of-type': {
      borderRightWidth: 0,
    },
  };
});

const BaseCellException = styled(TableCell)(() => {
  const { palette } = theme;

  return {
    padding: '12px 8px',
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.system.separator,
  };
});

const HeadCellTextA = styled(Typography)(() => {
  const { typography } = theme;

  return {
    ...typography['body-sub/bold'],
    color: '#0B5D61',
  };
});

const HeadCellTextB = styled(Typography)(() => {
  const { typography } = theme;

  return {
    ...typography['body-sub/medium'],
    color: '#0B5D61',
  };
});

const HeadCellTextC = styled(Typography)(() => {
  const { typography, palette } = theme;

  return {
    ...typography['body-sub/bold'],
    color: palette.system.white,
  };
});

const CellTextGroup = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const CellText = styled(Typography)(() => {
  const { typography, palette } = theme;

  return {
    ...typography['body-main/regular'],
    lineHeight: '100%',
    color: palette.system['text-normal'],
  };
});

export const PrintBackgroundFix = {
  printColorAdjust: 'exact',
  '-webkit-print-color-adjust': 'exact',
};
const getNewOldClassification = (value: string) => {
  switch (value) {
    case 'OLD':
      return '-';
    case 'FIRST':
      return '第一新株';
    case 'SECOND':
      return '第二新株';
    default:
      return '-';
  }
};
const kanmaChange = (value: string, investmentDigit?: number) => {
  let kanma;
  if (value.includes('.')) {
    const valueArr = value.split('.');
    const kanmaInt = valueArr[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    const cutDigit = valueArr[1].substring(0, investmentDigit);
    // eslint-disable-next-line prefer-template
    kanma = kanmaInt + '.' + cutDigit;
  } else {
    kanma = value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  }
  return kanma;
};

const TransactionHistoryTypeA = ({
  tradeHistories,
  investmentDigit,
}: {
  tradeHistories: TradeHistories;
  investmentDigit?: number;
}) => (
  <>
    {tradeHistories.map((i, index) => (
      <BaseRow key={`${i.updated + index}`}>
        <BaseCell>
          <CellText sx={{ fontSize: 14, textAlign: 'center' }}>
            {`${i.inverstmentYm.slice(2, 4)}/${i.inverstmentYm.slice(4, 6)}`}
          </CellText>
        </BaseCell>
        <BaseCell>
          <CellText sx={{ fontSize: 14, textAlign: 'left' }}>
            {i.inverstmentDescriptionClassification}
          </CellText>
        </BaseCell>
        <BaseCell>
          <CellText sx={{ textAlign: 'right' }}>
            {i.contribution ? numberFormat(i.contribution) : '0'}
          </CellText>
        </BaseCell>

        <BaseCell>
          <CellText sx={{ textAlign: 'right' }}>
            {i.incentives ? numberFormat(i.incentives) : '0'}
          </CellText>
        </BaseCell>
        <BaseCell>
          <CellText sx={{ textAlign: 'right' }}>
            {i.depositAmountTotal ? numberFormat(i.depositAmountTotal) : '0'}
          </CellText>
        </BaseCell>
        <BaseCell>
          <CellText sx={{ textAlign: 'right' }}>
            {i.withdrawalAmount ? numberFormat(i.withdrawalAmount) : '0'}
          </CellText>
        </BaseCell>
        <BaseCell>
          <CellText sx={{ textAlign: 'right' }}>
            {i.stockPurchaseNumber
              ? kanmaChange(String(i.stockPurchaseNumber), investmentDigit)
              : i.stockDrawerNumber}
          </CellText>
        </BaseCell>
        <BaseCell>
          <CellText sx={{ textAlign: 'right' }}>
            {i.unitPrice ? numberFormat(i.unitPrice) : '0'}
          </CellText>
        </BaseCell>
        <BaseCell>
          <CellText sx={{ textAlign: 'right' }}>
            {getNewOldClassification(i.newOldClassification)}
          </CellText>
        </BaseCell>
      </BaseRow>
    ))}
  </>
);

const TransactionHistoryTypeB = ({
  rightAllocationHistories,
  investmentDigit,
}: {
  rightAllocationHistories: RightAllocationHistories;
  investmentDigit?: number;
}) => {
  const getAllocationYmd = (value: string) => {
    const numVal = value.replaceAll('-', '');
    return `${numVal.slice(2, 4)}/${numVal.slice(4, 6)}`;
  };

  return (
    <>
      {rightAllocationHistories.map((i, index) => (
        <BaseRow key={`${i.updated + index}`}>
          <BaseCell>
            <CellText sx={{ fontSize: 14, textAlign: 'center' }}>
              {getAllocationYmd(i.allocationYmd)}
            </CellText>
          </BaseCell>
          <BaseCell>
            <CellText sx={{ fontSize: 14, textAlign: 'left' }}>
              {getNewOldClassification(i.newOldClassification)}
            </CellText>
          </BaseCell>
          <BaseCell>
            <CellText sx={{ textAlign: 'right' }}>
              {kanmaChange(i.allocationSharesNum, investmentDigit)}
            </CellText>
          </BaseCell>
          <BaseCell>
            <CellText sx={{ textAlign: 'right' }}>
              {i.allocationMoneyPerShare
                ? numberFormat(i.allocationMoneyPerShare)
                : '0'}
            </CellText>
          </BaseCell>
          <BaseCell>
            <CellText sx={{ textAlign: 'right' }}>
              {i.dividend ? numberFormat(i.dividend) : '0'}
            </CellText>
          </BaseCell>
          <BaseCell>
            <CellText sx={{ textAlign: 'right' }}>
              {i.incomeTax ? numberFormat(i.incomeTax) : '0'}
            </CellText>
          </BaseCell>
          <BaseCell>
            <CellText sx={{ textAlign: 'right' }}>
              {i.residentTax ? numberFormat(i.residentTax) : '0'}
            </CellText>
          </BaseCell>
          <BaseCell>
            <CellText sx={{ textAlign: 'right' }}>
              {i.afterTaxDividend ? numberFormat(i.afterTaxDividend) : '0'}
            </CellText>
          </BaseCell>
        </BaseRow>
      ))}
    </>
  );
};

interface TransactionHistoryProps {
  memberBalancereports: MemberBalancereports;
  investmentDigit?: number;
}

export const TransactionHistory: FC<TransactionHistoryProps> = ({
  memberBalancereports,
  investmentDigit,
}) => {
  const cutDigit = (value: number, digit: number) => {
    let kanma = value;
    if (String(value).includes('.')) {
      const arr = String(value).split('.');
      const underDigit = arr[1].substring(0, digit);
      // eslint-disable-next-line prefer-template
      kanma = Number(arr[0] + '.' + underDigit);
    }
    return kanma;
  };

  const getContributionTotal = () => {
    if (!memberBalancereports.tradeHistories) return;
    let total = 0;
    for (let i = 0; i < memberBalancereports.tradeHistories.length; i++) {
      total += memberBalancereports.tradeHistories[i].contribution;
    }
    return numberFormat(total);
  };

  const getIncentivesTotal = () => {
    if (!memberBalancereports.tradeHistories) return;
    let total = 0;
    for (let i = 0; i < memberBalancereports.tradeHistories.length; i++) {
      total += memberBalancereports.tradeHistories[i].incentives;
    }
    return numberFormat(total);
  };

  const getDepositAmountTotalTotal = () => {
    if (!memberBalancereports.tradeHistories) return;
    let total = 0;
    for (let i = 0; i < memberBalancereports.tradeHistories.length; i++) {
      total += memberBalancereports.tradeHistories[i].depositAmountTotal;
    }
    return numberFormat(total);
  };

  const getWithdrawalAmountTotal = () => {
    if (!memberBalancereports.tradeHistories) return;
    let total = 0;
    for (let i = 0; i < memberBalancereports.tradeHistories.length; i++) {
      total += memberBalancereports.tradeHistories[i].withdrawalAmount;
    }
    return numberFormat(total);
  };

  const getStockPurchaseNumberTotal = () => {
    if (!memberBalancereports.tradeHistories) return;
    let total = 0;
    for (let i = 0; i < memberBalancereports.tradeHistories.length; i++) {
      total +=
        cutDigit(
          memberBalancereports.tradeHistories[i].stockPurchaseNumber,
          investmentDigit || 0
        ) *
        10 ** (investmentDigit || 0);
    }
    return numberFormat(Math.round(total) / 10 ** (investmentDigit || 0));
  };

  const getStockDrawerNumberTotal = () => {
    if (!memberBalancereports.tradeHistories) return;
    let total = 0;
    for (let i = 0; i < memberBalancereports.tradeHistories.length; i++) {
      total +=
        cutDigit(
          memberBalancereports.tradeHistories[i].stockDrawerNumber,
          investmentDigit || 0
        ) *
        10 ** (investmentDigit || 0);
    }
    return numberFormat(Math.round(total) / 10 ** (investmentDigit || 0));
  };
  return (
    <Wrapper>
      <BalanceInquiryHeading title="お取引の履歴" />
      {/** Primary table */}
      <TableWrapper>
        <TablePaper sx={{ width: 1152 }}>
          <TableContainer>
            <Table sx={{ tableLayout: 'fixed' }}>
              <Head>
                <BaseRow>
                  <BaseCell
                    align="center"
                    rowSpan={4}
                    sx={{
                      width: TableCellWidth.typeA,
                      padding: '8px 12px',
                      backgroundColor: TableCellColors.typeA,
                      '@media print': {
                        ...PrintBackgroundFix,
                      },
                    }}
                  >
                    <HeadCellTextA>年月</HeadCellTextA>
                  </BaseCell>
                  <BaseCell
                    align="center"
                    rowSpan={4}
                    sx={{
                      width: TableCellWidth.typeB,
                      backgroundColor: TableCellColors.typeA,
                      '@media print': {
                        ...PrintBackgroundFix,
                      },
                    }}
                  >
                    <HeadCellTextA>摘要</HeadCellTextA>
                  </BaseCell>
                  <BaseCell
                    align="center"
                    colSpan={4}
                    sx={{
                      width: TableCellWidth.typeC * 4,
                      backgroundColor: TableCellColors.typeC,
                      '@media print': {
                        ...PrintBackgroundFix,
                      },
                    }}
                  >
                    <HeadCellTextC>金銭の動き</HeadCellTextC>
                  </BaseCell>
                  <BaseCell
                    align="center"
                    colSpan={2}
                    sx={{
                      width: TableCellWidth.typeC * 2,
                      backgroundColor: TableCellColors.typeC,
                      '@media print': {
                        ...PrintBackgroundFix,
                      },
                    }}
                  >
                    <HeadCellTextC>株式の動き</HeadCellTextC>
                  </BaseCell>
                  <BaseCell
                    align="center"
                    rowSpan={4}
                    sx={{
                      width: TableCellWidth.typeD,
                      backgroundColor: TableCellColors.typeB,
                      '@media print': {
                        ...PrintBackgroundFix,
                      },
                    }}
                  >
                    <HeadCellTextB>区分</HeadCellTextB>
                  </BaseCell>
                </BaseRow>
                <BaseRow>
                  <BaseCell
                    align="center"
                    colSpan={3}
                    sx={{
                      backgroundColor: TableCellColors.typeA,
                      '@media print': {
                        ...PrintBackgroundFix,
                      },
                    }}
                  >
                    <HeadCellTextB>ご入金</HeadCellTextB>
                  </BaseCell>
                  <BaseCell
                    align="center"
                    sx={{
                      backgroundColor: TableCellColors.typeA,
                      '@media print': {
                        ...PrintBackgroundFix,
                      },
                    }}
                  >
                    <HeadCellTextB>ご出金</HeadCellTextB>
                  </BaseCell>
                  <BaseCellException
                    align="center"
                    colSpan={2}
                    sx={{
                      backgroundColor: TableCellColors.typeA,
                      '@media print': {
                        ...PrintBackgroundFix,
                      },
                    }}
                  >
                    <HeadCellTextB>ご投資株数</HeadCellTextB>
                  </BaseCellException>
                </BaseRow>
                <BaseRow>
                  <BaseCell
                    rowSpan={2}
                    sx={{
                      backgroundColor: TableCellColors.typeB,
                      '@media print': {
                        ...PrintBackgroundFix,
                      },
                    }}
                  >
                    <CellTextGroup>
                      <HeadCellTextB>拠出金</HeadCellTextB>
                      <HeadCellTextB>(円)</HeadCellTextB>
                    </CellTextGroup>
                  </BaseCell>
                  <BaseCell
                    rowSpan={2}
                    sx={{
                      backgroundColor: TableCellColors.typeB,
                      '@media print': {
                        ...PrintBackgroundFix,
                      },
                    }}
                  >
                    <CellTextGroup>
                      <HeadCellTextB>奨励金</HeadCellTextB>
                      <HeadCellTextB>(円)</HeadCellTextB>
                    </CellTextGroup>
                  </BaseCell>
                  <BaseCell
                    rowSpan={2}
                    sx={{
                      backgroundColor: TableCellColors.typeB,
                      '@media print': {
                        ...PrintBackgroundFix,
                      },
                    }}
                  >
                    <CellTextGroup>
                      <HeadCellTextB>合計</HeadCellTextB>
                      <HeadCellTextB>(円)</HeadCellTextB>
                    </CellTextGroup>
                  </BaseCell>
                  <BaseCell
                    rowSpan={2}
                    sx={{
                      backgroundColor: TableCellColors.typeB,
                      '@media print': {
                        ...PrintBackgroundFix,
                      },
                    }}
                  >
                    <CellTextGroup>
                      <HeadCellTextB>購入金額</HeadCellTextB>
                      <HeadCellTextB>(円)</HeadCellTextB>
                    </CellTextGroup>
                  </BaseCell>
                  <BaseCell
                    sx={{
                      backgroundColor: TableCellColors.typeB,
                      '@media print': {
                        ...PrintBackgroundFix,
                      },
                    }}
                  >
                    <CellTextGroup>
                      <HeadCellTextB>ご購入株数</HeadCellTextB>
                      <HeadCellTextB>(株)</HeadCellTextB>
                    </CellTextGroup>
                  </BaseCell>
                  <BaseCellException
                    rowSpan={2}
                    sx={{
                      backgroundColor: TableCellColors.typeB,
                      '@media print': {
                        ...PrintBackgroundFix,
                      },
                    }}
                  >
                    <CellTextGroup>
                      <HeadCellTextB>単価</HeadCellTextB>
                      <HeadCellTextB>(円)</HeadCellTextB>
                    </CellTextGroup>
                  </BaseCellException>
                </BaseRow>
                <BaseRow>
                  <BaseCellException
                    sx={{
                      borderRightWidth: 1,
                      backgroundColor: TableCellColors.typeB,
                      '@media print': {
                        ...PrintBackgroundFix,
                      },
                    }}
                  >
                    <CellTextGroup>
                      <HeadCellTextB>引出株数</HeadCellTextB>
                      <HeadCellTextB>(株)</HeadCellTextB>
                    </CellTextGroup>
                  </BaseCellException>
                </BaseRow>
              </Head>
              <TableBody>
                {memberBalancereports.tradeHistories && (
                  <TransactionHistoryTypeA
                    tradeHistories={memberBalancereports.tradeHistories}
                    investmentDigit={investmentDigit}
                  />
                )}
                {/** from here the result of the body totals calculations etc... */}
                <BaseRow>
                  <BaseCell
                    colSpan={2}
                    sx={{
                      backgroundColor: TableCellColors.typeB,
                      '@media print': {
                        ...PrintBackgroundFix,
                      },
                    }}
                  >
                    <HeadCellTextB sx={{ textAlign: 'center' }}>
                      期間合計
                    </HeadCellTextB>
                  </BaseCell>
                  <BaseCell>
                    <CellText sx={{ textAlign: 'right' }}>
                      {getContributionTotal()}
                    </CellText>
                  </BaseCell>
                  <BaseCell>
                    <CellText sx={{ textAlign: 'right' }}>
                      {getIncentivesTotal()}
                    </CellText>
                  </BaseCell>
                  <BaseCell>
                    <CellText sx={{ textAlign: 'right' }}>
                      {getDepositAmountTotalTotal()}
                    </CellText>
                  </BaseCell>
                  <BaseCell>
                    <CellText sx={{ textAlign: 'right' }}>
                      {getWithdrawalAmountTotal()}
                    </CellText>
                  </BaseCell>
                  <BaseCell>
                    <CellText sx={{ textAlign: 'right' }}>
                      {getStockPurchaseNumberTotal()}
                    </CellText>
                  </BaseCell>
                  <BaseCell>
                    <CellText sx={{ textAlign: 'right' }}>-</CellText>
                  </BaseCell>
                  <BaseCell>
                    <CellText sx={{ textAlign: 'right' }}>-</CellText>
                  </BaseCell>
                </BaseRow>
                <BaseRow>
                  <BaseCell colSpan={6} />
                  <BaseCell>
                    <CellText sx={{ textAlign: 'right' }}>
                      {getStockDrawerNumberTotal()}
                    </CellText>
                  </BaseCell>
                  <BaseCell colSpan={2} />
                </BaseRow>
              </TableBody>
            </Table>
          </TableContainer>
        </TablePaper>
      </TableWrapper>
      {/** Secondary table */}
      <TableWrapper>
        <TablePaper sx={{ width: 1152 }}>
          <TableContainer>
            <Table sx={{ tableLayout: 'fixed' }}>
              <Head>
                <BaseRow>
                  <BaseCell
                    align="center"
                    sx={{
                      width: TableCellWidth.typeA,
                      padding: '8px 12px',
                      backgroundColor: TableCellColors.typeA,
                      '@media print': {
                        ...PrintBackgroundFix,
                      },
                    }}
                  >
                    <HeadCellTextA>決算年月</HeadCellTextA>
                  </BaseCell>
                  <BaseCell
                    align="center"
                    sx={{
                      width: TableCellWidth.typeB,
                      backgroundColor: TableCellColors.typeA,
                      '@media print': {
                        ...PrintBackgroundFix,
                      },
                    }}
                  >
                    <HeadCellTextA>区分</HeadCellTextA>
                  </BaseCell>
                  <BaseCell
                    align="center"
                    sx={{
                      width: TableCellWidth.typeC,
                      backgroundColor: TableCellColors.typeB,
                      '@media print': {
                        ...PrintBackgroundFix,
                      },
                    }}
                  >
                    <HeadCellTextB>配当付株数(株)</HeadCellTextB>
                  </BaseCell>
                  <BaseCell
                    align="center"
                    sx={{
                      width: TableCellWidth.typeC,
                      backgroundColor: TableCellColors.typeB,
                      '@media print': {
                        ...PrintBackgroundFix,
                      },
                    }}
                  >
                    <HeadCellTextB>1株当たり配当金(円)</HeadCellTextB>
                  </BaseCell>
                  <BaseCell
                    align="center"
                    sx={{
                      width: TableCellWidth.typeC,
                      backgroundColor: TableCellColors.typeB,
                      '@media print': {
                        ...PrintBackgroundFix,
                      },
                    }}
                  >
                    <HeadCellTextB>税込配当金(円)</HeadCellTextB>
                  </BaseCell>
                  <BaseCell
                    align="center"
                    sx={{
                      width: TableCellWidth.typeC,
                      backgroundColor: TableCellColors.typeB,
                      '@media print': {
                        ...PrintBackgroundFix,
                      },
                    }}
                  >
                    <HeadCellTextB>所得税額(円)</HeadCellTextB>
                  </BaseCell>
                  <BaseCell
                    align="center"
                    sx={{
                      width: TableCellWidth.typeC,
                      backgroundColor: TableCellColors.typeB,
                      '@media print': {
                        ...PrintBackgroundFix,
                      },
                    }}
                  >
                    <HeadCellTextB>住民税額(円)</HeadCellTextB>
                  </BaseCell>
                  <BaseCell
                    align="center"
                    sx={{
                      width: TableCellWidth.typeC,
                      backgroundColor: TableCellColors.typeB,
                      '@media print': {
                        ...PrintBackgroundFix,
                      },
                    }}
                  >
                    <HeadCellTextB>税引後配当金(円)</HeadCellTextB>
                  </BaseCell>
                </BaseRow>
              </Head>

              <TableBody>
                {memberBalancereports.rightAllocationHistories && (
                  <TransactionHistoryTypeB
                    rightAllocationHistories={
                      memberBalancereports.rightAllocationHistories
                    }
                    investmentDigit={investmentDigit}
                  />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TablePaper>
      </TableWrapper>
    </Wrapper>
  );
};
