import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  styled,
  TableCell,
  TableCellProps,
  Checkbox,
  SxProps,
} from '@mui/material';

interface TableProps extends TableCellProps {
  url?: string;
  value?: string | number;
  children?: React.ReactNode | string | number;
  sxProps?: SxProps;
  sxContentContainer?: SxProps;
  type?: string;
  onCheckBoxClick?: () => void;
}

const MuiTableCell = styled((props: TableCellProps) => (
  <TableCell {...props} />
))({
  borderWidth: '0px',
  padding: '0px',
});

const Box = styled('div')(({ theme }) => {
  const { typography, palette } = theme;
  return {
    ...typography['body-sub/regular'],
    color: palette.system.table,
    display: 'flex',
    gap: '7px',
    alignItems: 'center',
  };
});

export const TableBodyCell: FC<TableProps> = ({
  value,
  children,
  sxProps,
  sxContentContainer,
  type = 'normal',
  url,
  onCheckBoxClick,
}) => {
  const [checked, setChecked] = useState<boolean>(false);
  const navigate = useNavigate();
  const onHandleClickCell = () => {
    if (url) {
      navigate(url);
    }
  };
  return (
    <>
      {type === 'normal' && (
        <MuiTableCell sx={sxProps} onClick={() => onHandleClickCell()}>
          <Box sx={{ padding: '15px 0 15px 12px', ...sxContentContainer }}>
            {value ?? children}
          </Box>
        </MuiTableCell>
      )}
      {type === 'badge' && (
        <MuiTableCell
          sx={{
            ...sxProps,
            padding: '15px 0 15px 12px',
          }}
          onClick={() => onHandleClickCell()}
        >
          {children}
        </MuiTableCell>
      )}
      {type === 'checkbox' && (
        <MuiTableCell sx={sxProps}>
          <Box sx={{ padding: '15px 0 15px 12px' }}>
            <Checkbox
              sx={{
                color: 'system.separator',
                '&.Mui-checked': {
                  color: 'secondary.normal',
                },
                padding: '0px',
              }}
              checked={checked}
              onChange={() => {
                setChecked(!checked);
                if (onCheckBoxClick) onCheckBoxClick();
              }}
            />
          </Box>
        </MuiTableCell>
      )}
      {type === 'button' && (
        <MuiTableCell sx={sxProps}>
          <Box sx={{ padding: '15px 0 15px 12px' }}>{children}</Box>
        </MuiTableCell>
      )}
    </>
  );
};
