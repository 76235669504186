import { ClickAwayListener, styled, SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';

interface CustomDropdownProps {
  sx?: SxProps;
  children: ReactNode | ReactNode[];
  onClickAway: () => void;
}

const Wrapper = styled('div')(({ theme }) => {
  const { palette } = theme;
  return {
    display: 'flex',
    gap: '16px',
    flexDirection: 'column',
    padding: '16px',
    boxShadow: ' 0px 2px 8px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    zIndex: '1000',
    position: 'absolute',
    top: '38px',
    left: '0px',
    backgroundColor: palette.system.white,
  };
});

export const CustomDropdown: FC<CustomDropdownProps> = ({
  children,
  sx,
  onClickAway,
}) => (
  <ClickAwayListener onClickAway={() => onClickAway()}>
    <Wrapper sx={sx}>{children}</Wrapper>
  </ClickAwayListener>
);
