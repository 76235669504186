import { FC, useState, ChangeEvent, useMemo, useEffect } from 'react';
import {
  styled,
  TextField,
  Box,
  Stack,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { theme } from '@/theme';
import { FindApplicationByID } from '@/types/api/Applications/application';
import { GetAssociationsContribution } from '@/types/api/associations';
import { MemberById } from '@/types/api/member';
import { PrevnextApplications } from '@/types/api/Applications/prevnextApplications';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { UnitEditInputs as Inputs } from '@/types/components/Inputs';
import { MemberContribution } from '@/types/api/memberContribution';
import { numberFormat } from '@/utils/numberFormat';
import { IsNumeric } from '@/utils/stringProcessor';
import { AssociationsContribution } from '@/types/api/Association/associationsContribution';
import { Calculations } from '@/utils/calculation';
import { TitleHead } from '../Applications/SharedParts/TitleHead';
import { UserInfoTable } from '../Applications/SharedParts/UserInfoTable';
import { ApplicationBottom } from '../Applications/SharedParts/ApplicationBottom';
import { ApplicationTable as Table } from '../Applications/SharedParts/ApplicationTable';
import { ApplicationTableRow as Row } from '../Applications/SharedParts/ApplicationTableRow';

const MuiTextField = styled(TextField)({
  width: '80px',
  '& .MuiOutlinedInput-input': {
    textAlign: 'right',
  },
});

const MuiRadioGroup = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'row',
});

interface UnitProps {
  application: FindApplicationByID;
  unitType: string;
  userData: MemberById;
  associationContribution: GetAssociationsContribution;
  memberContribution: MemberContribution;
  bottomData: PrevnextApplications | undefined;
  onSubmit: SubmitHandler<Inputs>;
  applicationType: string;
}

const schema = yup.object({
  unitNum: yup
    .string()
    .required('必須項目です')
    .matches(/[0-9]/, '*半角数字。'),
});

export const ApplicationsUnitEdit: FC<UnitProps> = ({
  application,
  associationContribution,
  memberContribution,
  unitType,
  userData,
  bottomData,
  onSubmit,
  applicationType,
}) => {
  const kanmaChange = (value: number | string) => {
    let val = value;
    if (typeof value === 'number') {
      val = String(val);
    }
    if (typeof val === 'string') {
      return val.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    }
    return value;
  };

  const unitNumInitial = application?.applicationMonthlyUnit?.monthlyUnit || 0;
  const unitNumBonusInitial =
    application?.applicationMonthlyUnit?.bonusContributionUnit || 0;
  const isBonusInitial =
    application.applicationMonthlyUnit?.bonusContributionUnit !== 0;

  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      unitNum: unitNumInitial,
      unitNumBonus: unitNumBonusInitial,
      isBonus: isBonusInitial,
      unitRate: application?.applicationMonthlyUnit?.bonusRate,
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const currentUnitNum = watch('unitNum');
  const currentUnitNumBonus = watch('unitNumBonus');
  const currentIsBonus = watch('isBonus');
  const [bonusUnit, setBonusUnit] = useState<number>(unitNumInitial || 0);
  const [bonusRate, setBonusRate] = useState<number>(0);

  const [isError, setIsError] = useState<boolean>(true);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    isBonusFlag?: boolean
  ) => {
    let amount = '';
    if (IsNumeric(e.target.value)) {
      amount = Math.floor(
        Math.min(
          Number(e.target.value),
          isBonusFlag
            ? associationContribution.maxBonusUnit ?? 0
            : associationContribution.maxMonthlyUnit ?? 0
        )
      ).toString();
    }
    if (isBonusFlag) setValue('unitNumBonus', Number(amount));
    else {
      if (
        associationContribution.bonusMultiplierDesignationType ===
        'MAGNIFICATION'
      )
        setValue('unitNumBonus', Number(amount) * bonusRate);
      if (
        associationContribution.bonusMultiplierDesignationType ===
        'FIXED_MAGNIFICATION'
      )
        setValue(
          'unitNumBonus',
          Number(amount) *
            Number(associationContribution.bonusMultiplierDetails)
        );
      setValue('unitNum', Number(amount));
    }
  };

  const renderBonusCalculation = (contribution: string, incentive: string) => (
    <>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography minWidth="65px">拠出設定</Typography>
        <Typography variant="body-main/regular">{contribution}</Typography>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography minWidth="65px">奨励金額</Typography>
        <Typography variant="body-main/regular">{incentive}</Typography>
      </Stack>
    </>
  );

  const renderRowSubRow = (label: string, value: string) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <Typography sx={{ width: '75px' }} variant="body-sub/regular">
        {label}
      </Typography>
      <Typography
        sx={{ marginLeft: '8px', marginRight: '16px' }}
        variant="body-sub/regular"
      >
        {value}
      </Typography>
    </Box>
  );

  const renderMultiplier = (rate?: number) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <Typography sx={{ marginRight: '16px' }} variant="body-sub/regular">
        {rate || 0}倍
      </Typography>
      <Typography
        variant="body-sub/regular"
        sx={{ color: 'system.text-light' }}
      >
        ※給与（毎月の拠出）対比
      </Typography>
    </Box>
  );

  const isDisabled = useMemo((): boolean => {
    let result = true;
    if (currentIsBonus) {
      switch (associationContribution.bonusMultiplierDesignationType) {
        case 'UNIT_NUMBER':
          result =
            (((Number(currentUnitNum) !== 0 &&
              Number(currentUnitNum) !==
                application?.applicationMonthlyUnit?.monthlyUnit) ||
              (Number(currentUnitNumBonus) !== 0 &&
                Number(currentUnitNumBonus) !==
                  application?.applicationMonthlyUnit
                    ?.bonusContributionUnit)) &&
              (Number(currentUnitNumBonus) !== memberContribution.bonusUnit ||
                Number(currentUnitNum) !== memberContribution.monthlyUnit)) ||
            currentIsBonus !==
              !(
                memberContribution.bonusRate === 0 &&
                memberContribution.bonusUnit === 0
              );
          break;
        case 'FIXED_MAGNIFICATION':
          result =
            ((Number(currentUnitNum) !== 0 &&
              Number(currentUnitNum) !==
                application?.applicationMonthlyUnit?.monthlyUnit) ||
              currentIsBonus !== isBonusInitial) &&
            Number(currentUnitNum) !== memberContribution.monthlyUnit;
          break;
        case 'MAGNIFICATION':
          result =
            ((Number(currentUnitNum) !== 0 &&
              Number(currentUnitNum) !==
                application?.applicationMonthlyUnit?.monthlyUnit) ||
              Number(bonusRate) !==
                application?.applicationMonthlyUnit?.bonusRate) &&
            ((Number(currentUnitNum) !== 0 &&
              Number(currentUnitNum) !== memberContribution.monthlyUnit) ||
              (Number(bonusRate) !== memberContribution.bonusRate &&
                currentIsBonus !==
                  !(
                    memberContribution.bonusRate === 0 &&
                    memberContribution.bonusUnit === 0
                  )));
          break;
        default:
          break;
      }
    } else {
      result =
        ((Number(currentUnitNum) !== 0 &&
          Number(currentUnitNum) !==
            application?.applicationMonthlyUnit?.monthlyUnit) ||
          currentIsBonus !== isBonusInitial) &&
        ((Number(currentUnitNum) !== 0 &&
          Number(currentUnitNum) !== memberContribution.monthlyUnit) ||
          currentIsBonus !==
            !(
              memberContribution.bonusRate === 0 &&
              memberContribution.bonusUnit === 0
            ));
    }
    return !result;
  }, [
    associationContribution,
    currentIsBonus,
    currentUnitNum,
    currentUnitNumBonus,
  ]);

  useEffect(() => {
    if (bonusRate === 0) {
      setBonusRate(Number(application.applicationMonthlyUnit?.bonusRate));
    }
  }, [application]);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.system['background-dark'],
        height: '100%',
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack
        spacing={4}
        sx={{
          backgroundColor: theme.palette.system.white,
          padding: '40px 160px',
        }}
      >
        <TitleHead
          title="口数変更"
          status={application.applicationStatus || ''}
          applicationDate={application.applicationDate || ''}
          applicationId={application.applicationId || ''}
          associationCd={application.associationCd || ''}
          proxyApplicantUserName={application.proxyApplicantUserName}
          unitType={unitType}
        />
        <UserInfoTable data={userData} />

        <Table title="現在の口数設定">
          <Row
            labels="1口あたり金額"
            values={`${
              associationContribution.unitAmount?.toLocaleString() || ''
            }円`}
          />

          <Row labels="給与" isEven>
            {renderRowSubRow(
              '口数',
              `${memberContribution.monthlyUnit.toLocaleString()}口`
            )}
            {renderRowSubRow(
              '拠出設定',
              `${(
                Calculations.bonusUnitAmount(
                  { ...associationContribution } as AssociationsContribution,
                  { ...memberContribution } as MemberContribution
                ) * memberContribution.monthlyUnit
              ).toLocaleString()}円`
            )}
          </Row>

          <Row labels="賞与">
            {memberContribution.bonusUnit === 0 &&
              renderRowSubRow('拠出しない', '')}
            {associationContribution.bonusMultiplierDesignationType ===
              'FIXED_MAGNIFICATION' &&
              memberContribution.bonusUnit !== 0 && (
                <>
                  {renderMultiplier(
                    Number(associationContribution.bonusMultiplierDetails)
                  )}
                  {renderRowSubRow(
                    '拠出設定',
                    `${kanmaChange(
                      (memberContribution.bonusUnit || 0) *
                        (associationContribution.unitAmount || 0)
                    )}円`
                  )}
                </>
              )}
            {associationContribution.bonusMultiplierDesignationType ===
              'MAGNIFICATION' &&
              memberContribution.bonusUnit !== 0 && (
                <>
                  {renderMultiplier(Number(memberContribution.bonusRate))}
                  {renderRowSubRow(
                    '拠出設定',
                    `${kanmaChange(
                      (memberContribution.bonusUnit || 0) *
                        (associationContribution.unitAmount || 0)
                    )}円`
                  )}
                </>
              )}
            {associationContribution.bonusMultiplierDesignationType ===
              'UNIT_NUMBER' &&
              memberContribution.bonusUnit !== 0 && (
                <>
                  {renderRowSubRow(
                    '口数',
                    `${kanmaChange(memberContribution.bonusUnit || 0)}口`
                  )}
                  {renderRowSubRow(
                    '拠出設定',
                    `${kanmaChange(
                      (memberContribution.bonusUnit || 0) *
                        (associationContribution.unitAmount || 0)
                    )}円`
                  )}
                </>
              )}
          </Row>
        </Table>
        <Table title="新しい口数設定" isRed>
          <Row
            labels="1口あたり金額"
            values={`${
              associationContribution?.unitAmount?.toLocaleString() || 0
            }円`}
          />
          <Row
            labels="奨励金率"
            values={`${associationContribution?.monthlyIncentiveRatio || 0}%`}
            isEven
          />
          <>
            <Row labels="給与">
              <Stack spacing={2}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography minWidth="65px">口数</Typography>
                  <Controller
                    name="unitNum"
                    control={control}
                    render={({ field }) => (
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{ alignItems: 'center' }}
                      >
                        <MuiTextField
                          {...field}
                          {...register('unitNum')}
                          error={'unitNum' in errors}
                          helperText={errors.unitNum?.message}
                          onChange={(e) => handleChange(e)}
                        />
                        <Typography variant="body-sub/regular">口</Typography>
                      </Stack>
                    )}
                  />
                  <Typography
                    variant="body-sub/regular"
                    sx={{ color: theme.palette.system['text-light'] }}
                  >
                    {' '}
                    ※最大
                    {numberFormat(associationContribution.maxMonthlyUnit || 0)}
                    口まで
                  </Typography>
                </Stack>
                {renderBonusCalculation(
                  `${(
                    (associationContribution.unitAmount || 0) *
                    (currentUnitNum || 0)
                  ).toLocaleString()}円`,
                  `${(
                    ((associationContribution?.unitAmount || 0) *
                      (currentUnitNum || 0) *
                      (associationContribution.monthlyIncentiveRatio || 0)) /
                    100
                  ).toLocaleString()}円`
                )}
              </Stack>
            </Row>
            {associationContribution.bonusReserveClassification !== 'NONE' && (
              <Row labels="賞与" isEven>
                <Stack spacing={2}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography minWidth="65px">拠出</Typography>
                    <FormControl>
                      <MuiRadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="yes"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          checked={currentIsBonus === true}
                          control={<Radio />}
                          label="する"
                          onClick={() => {
                            setValue('isBonus', true);
                            if (
                              associationContribution.bonusMultiplierDesignationType !==
                              'UNIT_NUMBER'
                            ) {
                              setValue(
                                'unitNumBonus',
                                Number(
                                  application.applicationMonthlyUnit?.bonusRate
                                ) * currentUnitNum
                              );
                            } else
                              setValue('unitNumBonus', currentUnitNumBonus);
                          }}
                        />
                        <FormControlLabel
                          checked={currentIsBonus === false}
                          control={<Radio />}
                          label="しない"
                          onClick={() => setValue('isBonus', false)}
                        />
                      </MuiRadioGroup>
                    </FormControl>
                  </Stack>
                  {associationContribution?.bonusMultiplierDesignationType ===
                    'FIXED_MAGNIFICATION' &&
                    currentIsBonus && (
                      <>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Typography minWidth="65px">倍率</Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                            }}
                          >
                            <Typography
                              sx={{ marginLeft: '8px', marginRight: '16px' }}
                              variant="body-sub/bold"
                            >
                              {
                                (
                                  associationContribution?.bonusMultiplierDetails ||
                                  ''
                                ).split(',')[0]
                              }
                              倍
                            </Typography>
                            <Typography
                              variant="body-sub/regular"
                              sx={{ color: 'system.text-light' }}
                            >
                              ※給与（毎月の拠出）対比
                            </Typography>
                          </Box>
                        </Stack>
                        {renderBonusCalculation(
                          `${kanmaChange(
                            (associationContribution.unitAmount || 0) *
                              (currentUnitNum *
                                Number(
                                  associationContribution?.bonusMultiplierDetails
                                ) || 0)
                          )}円`,
                          `${(
                            ((associationContribution.unitAmount || 0) *
                              (currentUnitNum *
                                Number(
                                  associationContribution?.bonusMultiplierDetails
                                ) || 0) *
                              (associationContribution.monthlyIncentiveRatio ||
                                0)) /
                            100
                          ).toLocaleString()}円`
                        )}
                      </>
                    )}

                  {associationContribution?.bonusMultiplierDesignationType ===
                    'UNIT_NUMBER' &&
                    currentIsBonus && (
                      <>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Typography minWidth="65px">口数</Typography>
                          <Controller
                            name="unitNumBonus"
                            control={control}
                            render={({ field }) => (
                              <Stack
                                direction="row"
                                spacing={1}
                                sx={{ alignItems: 'center' }}
                              >
                                <MuiTextField
                                  {...field}
                                  {...register('unitNumBonus')}
                                  error={'unitNumBonus' in errors}
                                  helperText={errors.unitNumBonus?.message}
                                  onChange={(e) => handleChange(e, true)}
                                />
                                <Typography variant="body-sub/regular">
                                  口
                                </Typography>
                              </Stack>
                            )}
                          />
                          <Typography
                            variant="body-sub/regular"
                            sx={{ color: theme.palette.system['text-light'] }}
                          >
                            {' '}
                            ※最大
                            {numberFormat(
                              associationContribution.maxBonusUnit || 0
                            )}
                            口まで
                          </Typography>
                        </Stack>
                        {renderBonusCalculation(
                          `${(
                            (associationContribution.unitAmount || 0) *
                            (currentUnitNumBonus || 0)
                          ).toLocaleString()}円`,
                          `${(
                            ((associationContribution.unitAmount || 0) *
                              (currentUnitNumBonus || 0) *
                              (associationContribution.monthlyIncentiveRatio ||
                                0)) /
                            100
                          ).toLocaleString()}円`
                        )}
                      </>
                    )}
                  {associationContribution?.bonusMultiplierDesignationType ===
                    'MAGNIFICATION' &&
                    currentIsBonus && (
                      <>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Typography minWidth="65px">倍率</Typography>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <MuiRadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue="yes"
                              name="radio-buttons-group"
                            >
                              {(
                                associationContribution?.bonusMultiplierDetails ||
                                ''
                              )
                                .split(',')
                                .map((i) => (
                                  <FormControlLabel
                                    value={i}
                                    control={<Radio />}
                                    checked={Number(i) === bonusRate}
                                    label={`${i}倍`}
                                    onClick={() => {
                                      setBonusRate(Number(i));
                                      setValue('unitRate', Number(i));
                                      setValue(
                                        'unitNumBonus',
                                        (currentUnitNum || 0) * Number(i)
                                      );
                                      setIsError(false);
                                    }}
                                  />
                                ))}
                            </MuiRadioGroup>
                          </Box>
                        </Stack>
                        {renderBonusCalculation(
                          `${kanmaChange(
                            (associationContribution.unitAmount || 0) *
                              (currentUnitNumBonus || 0)
                          )}円`,
                          `${(
                            ((associationContribution.unitAmount || 0) *
                              (currentUnitNumBonus || 0) *
                              (associationContribution.monthlyIncentiveRatio ||
                                0)) /
                            100
                          ).toLocaleString()}円`
                        )}
                      </>
                    )}
                </Stack>
              </Row>
            )}
          </>
        </Table>
        <PrimaryButton
          sx={{ width: '96px' }}
          type="submit"
          disabled={isDisabled}
        >
          修正完了
        </PrimaryButton>
      </Stack>
      {bottomData && (
        <Box sx={{ padding: '32px 160px' }}>
          <ApplicationBottom data={bottomData} unitType={unitType} />
        </Box>
      )}
    </Box>
  );
};
