import { FC } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { LoginPaper } from '@/components/organisms/Login/LoginPaper';
import { MainButton } from '@/components/organisms/Login/MainButton';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ErrorMessage } from '@/constants/Login/LoginMessage';
import { validatePassword } from '@/utils/validation';
import { theme } from '@/theme';
import { LoginErrorMessage } from './LoginErrorMessage';
import { PasswordFieldWithLabel } from './PasswordFieldWithLabel';

const Annotation = styled(Typography)(() => ({
  color: theme.palette.system['text-light'],
  display: 'block',
}));

export type LoginFormType = {
  newPassword: string;
  confirm: string;
};

interface LoginLoginPasswordChangeProps {
  title: string;
  isError: boolean;
  onSubmit: SubmitHandler<LoginFormType>;
}

export const LoginLoginPasswordChange: FC<LoginLoginPasswordChangeProps> = ({
  title,
  isError,
  onSubmit,
}) => {
  const {
    getValues,
    watch,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<LoginFormType>();

  const isDisabled = !(
    !!watch('newPassword') &&
    !!watch('confirm') &&
    watch('confirm') === watch('newPassword') &&
    validatePassword(getValues('newPassword')) &&
    watch('newPassword').length >= 8
  );

  return (
    <LoginPaper title={title} subTitle="新しいパスワード入力してください">
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        {(errors.newPassword || errors.confirm) && (
          <LoginErrorMessage
            message={ErrorMessage.Password}
            sx={{ marginBottom: 3 }}
          />
        )}
        <Box mb={0.5}>
          <PasswordFieldWithLabel
            label="新しいパスワード"
            placeholder="パスワードを入力"
            sx={{ height: '40px' }}
            error={
              !(
                !watch('newPassword') ||
                (watch('newPassword') !== '' &&
                  validatePassword(watch('newPassword')))
              )
            }
            {...register('newPassword')}
          />
          {!(
            !watch('newPassword') ||
            (watch('newPassword') !== '' &&
              validatePassword(watch('newPassword')))
          ) && (
            <Typography
              variant="caption/regular"
              color={theme.palette.states.error}
            >
              パスワードの形式が正しくありません
            </Typography>
          )}
        </Box>
        <Box mb={3}>
          <Annotation variant="caption/regular">※半角英数字8桁以上</Annotation>
          <Annotation variant="caption/regular">
            ※大文字、小文字、数字、記号をそれぞれ1文字以上使用してください。使用可能な記号は以下の通りです
          </Annotation>
          <Annotation variant="caption/regular">
            {
              '^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ ` = + -'
            }
          </Annotation>
          <Annotation variant="caption/regular">
            ※自身の名前、生年月日、電話番号、連番など推測しやすい文字列は使用しないでください
          </Annotation>
        </Box>
        <Box mb={3}>
          <PasswordFieldWithLabel
            label="新しいパスワード(再入力)"
            placeholder="パスワードを入力"
            sx={{ height: '40px' }}
            error={
              !!(
                watch('confirm') !== '' &&
                watch('confirm') !== watch('newPassword')
              )
            }
            {...register('confirm')}
          />
          {watch('confirm') !== '' &&
            watch('confirm') !== watch('newPassword') && (
              <Typography
                variant="caption/regular"
                color={theme.palette.states.error}
              >
                パスワードが一致しません
              </Typography>
            )}
        </Box>
        <Box display="flex" justifyContent="center">
          <MainButton text="変更する" type="submit" disabled={isDisabled} />
        </Box>
      </Box>
    </LoginPaper>
  );
};
