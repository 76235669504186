import { SelectBox, SelectBoxProps } from '@/components/atoms/Input/SelectBox';
import { TextInput } from '@/components/atoms/Input/TextInput';
import { PrimaryTableBodyTdMulti } from '@/components/atoms/Table/PrimaryTableBodyTdMulti';
import { PrimaryTable } from '@/components/molecules/Table/PrimaryTable';
import { reasonsForRecess } from '@/constants/SelectBox/choicesForSelectBox';
import { CreateApplication } from '@/types/api/applications';
import { Box, Stack, TextFieldProps, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from '@/theme';
import { useController, UseFormReturn } from 'react-hook-form';

type Props = {
  isConfirmed: boolean;
  methods: UseFormReturn<CreateApplication>;
  setIsUnit: (isUnit: boolean) => void;
};

const StyledTextInput: FC<TextFieldProps> = ({ name = '', ...props }) => {
  const {
    field: { ref, ...rest },
  } = useController({ name });

  return (
    <TextInput
      inputRef={ref}
      {...rest}
      {...props}
      placeholder="休止理由を入力してください"
      maxRows={4}
      multiline
      sx={{ width: '100%', minHeight: '130px' }}
      inputProps={{
        style: {
          height: '96px',
          resize: 'none',
          color: theme.palette.system['text-normal'],
          paddingLeft: '4px',
        },
      }}
    />
  );
};
const StyledSelectBox: FC<SelectBoxProps> = ({ name = '', ...props }) => {
  const {
    field: { ref, ...rest },
  } = useController({ name });

  return <SelectBox inputRef={ref} {...rest} {...props} />;
};

export const MemberProxyApplicationBodyRecess: FC<Props> = ({
  isConfirmed,
  methods,
  setIsUnit,
}) => {
  const watchReason = methods.watch('applicationRecess.reason');
  const switchRecessReason = (value: string) => {
    switch (value) {
      case 'LEAVE_ABSENCE':
        return '休職';
      case 'FAMILY_MATTERS':
        return '家庭の事情';
      case 'ECONOMIC':
        return '経済的理由';
      case 'OTHER':
        return 'その他（理由を記入）';
      default:
        return '休止理由を選択してください';
    }
  };

  return (
    <PrimaryTable title="休止理由">
      <PrimaryTableBodyTdMulti title="休止理由" row={1}>
        {isConfirmed ? (
          <Stack>
            <Typography>
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call */}
              {reasonsForRecess.find((item) => item.key === watchReason)?.value}
            </Typography>
            {watchReason === 'OTHER' && (
              <Typography>
                {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call */}
                {methods.getValues('applicationRecess.otherReasons')}
              </Typography>
            )}
          </Stack>
        ) : (
          <>
            <StyledSelectBox
              name="applicationRecess.reason"
              items={reasonsForRecess}
              width="320px"
              isKeyAValue
              placeholder="休止理由を入力してください"
              value={switchRecessReason(
                methods.getValues('applicationRecess.reason') || ''
              )}
              onChange={(e) => {
                methods.setValue(
                  'applicationRecess.reason',
                  e.target.value as Exclude<
                    CreateApplication['applicationRecess'],
                    undefined
                  >['reason']
                );
                if (e.target.value !== '休止理由を選択してください') {
                  setIsUnit(true);
                } else {
                  setIsUnit(false);
                }
              }}
            />
            {watchReason === 'OTHER' && (
              <Box mt={2}>
                <StyledTextInput name="applicationRecess.otherReasons" />
              </Box>
            )}
          </>
        )}
      </PrimaryTableBodyTdMulti>
    </PrimaryTable>
  );
};
