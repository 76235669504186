import { FC, ReactNode } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { theme } from '@/theme';

interface NotificationFormModalProps {
  headerTexts: {
    title?: string;
    subtitle?: string;
  };
  descriptionTexts?: {
    label: string;
    description: string;
  }[];
  controlContent?: ReactNode;
}

const ModalContentWrapper = styled(Box)({
  padding: 32,
  width: '520px',
});

const ModalTitleWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 24,
});

const ModalTitle = styled(Typography)({
  ...theme.typography.h4,
  color: theme.palette.system['text-normal'],
});

const ModalSubtitle = styled(Typography)({
  ...theme.typography['body-sub/regular'],
  color: theme.palette.system['text-normal'],
});

const ModalDescriptionWrapper = styled(Box)({
  padding: '16px 58px',
  backgroundColor: theme.palette.system.background,
  borderRadius: 4,
  gap: 4,
  display: 'flex',
  flexDirection: 'column',
  marginTop: 24,
});

const ModalDescriptionRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 16,
  ...theme.typography['body-sub/regular'],
});

const ModalDescriptionRowTitle = styled(Box)({
  fontWeight: 700,
  minWidth: '64px',
});

const ModalDescriptionRowDescription = styled(Box)({});

const ModalControlsWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 16,
  marginTop: 24,
});

export const NotificationFormModal: FC<NotificationFormModalProps> = ({
  headerTexts,
  descriptionTexts,
  controlContent,
}) => (
  <ModalContentWrapper>
    <ModalTitleWrapper>
      {headerTexts.title && <ModalTitle>{headerTexts.title}</ModalTitle>}
      {headerTexts.subtitle && (
        <ModalSubtitle>{headerTexts.subtitle}</ModalSubtitle>
      )}
    </ModalTitleWrapper>
    <ModalDescriptionWrapper>
      {descriptionTexts?.map((item) => (
        <ModalDescriptionRow key={item.label}>
          <ModalDescriptionRowTitle>{item.label}</ModalDescriptionRowTitle>
          <ModalDescriptionRowDescription>
            {item.description}
          </ModalDescriptionRowDescription>
        </ModalDescriptionRow>
      ))}
    </ModalDescriptionWrapper>
    <ModalControlsWrapper>{controlContent}</ModalControlsWrapper>
  </ModalContentWrapper>
);
