/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { FindTradereportsByID } from '@/types/api/tradereports';
import { TableContainer } from './TableContainer';
import { Row } from './Row';
import { Td } from './Td';
import { Th } from './Th';

interface Props {
  report: Required<FindTradereportsByID>;
}

export const MonthlyContribution: FC<Props> = ({ report }) => {
  const {
    oldContributionUsersCount,
    oldContributionUnit,
    oldUsersCount,
    oldUnit,
    joinCount,
    joinUnit,
    unitChangeCount,
    unitChangeUnitPlus,
    unitChangeUnitMinus,
    withdrawalNum,
    withdrawalUnit,
    usersCount,
    unit,
    contributionUsersCount,
    contributionUnit,
    newRecessCount,
    newRecessUnit,
    oldRecessCount,
    oldRecessUnit,
    recessUnitChangeCount,
    recessUnitChangeUnitPlus,
    recessUnitChangeUnitMinus,
    recessWithdrawalCount,
    recessWithdrawalUnit,
    resumeCount,
    resumeUnit,
    recessCount,
    recessUnit,
  } = report.monthlyBonus as Required<Props['report']['monthlyBonus']>;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '8px',
      }}
    >
      <Box display="flex" gap="16px" alignItems="center">
        <Typography variant="h5" sx={{ color: 'system.text-normal' }}>
          月例拠出
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '24px',
          width: '912px',
        }}
      >
        <TableContainer width="444px">
          <Row>
            <Th variant="medium" bgcolor="secondary.light">
              前回拠出 (A-B)
            </Th>
            <Td width="162px" align="right" borderRight>
              {`${oldContributionUsersCount.toLocaleString()}人`}
            </Td>
            <Td width="162px" align="right">
              {`${oldContributionUnit.toLocaleString()}口`}
            </Td>
          </Row>
          <Row>
            <Th variant="medium" bgcolor="secondary.light">
              前回在籍者 (A)
            </Th>
            <Td width="162px" align="right" borderRight>
              {`${oldUsersCount.toLocaleString()}名`}
            </Td>
            <Td width="162px" align="right">
              {`${oldUnit.toLocaleString()}口`}
            </Td>
          </Row>
          <Row>
            <Th variant="regular" bgcolor="secondary.bg">
              入会
            </Th>
            <Td width="162px" align="right" borderRight>
              {`+${joinCount.toLocaleString()}名`}
            </Td>
            <Td width="162px" align="right">
              {`+${joinUnit.toLocaleString()}口`}
            </Td>
          </Row>
          <Row>
            <Th variant="regular" bgcolor="secondary.bg" height="64px">
              口数変更
              <br />
              (含休止中)
            </Th>
            <Td width="162px" height="64px" align="right" borderRight>
              {`（${unitChangeCount.toLocaleString()}名)`}
            </Td>
            <Box>
              <Td width="162px" height="32px" borderBottom align="right">
                {`+${unitChangeUnitPlus.toLocaleString()}口`}
              </Td>
              <Td width="162px" height="32px" align="right">
                {`-${unitChangeUnitMinus.toLocaleString()}口`}
              </Td>
            </Box>
          </Row>
          <Row>
            <Th variant="regular" bgcolor="secondary.bg" height="80px">
              退会 (含休止中)
            </Th>
            <Td width="162px" height="80px" align="right" borderRight>
              {`-${withdrawalNum.toLocaleString()}名`}
            </Td>
            <Td width="162px" height="80px" align="right">
              {`-${withdrawalUnit.toLocaleString()}口`}
            </Td>
          </Row>
          <Row>
            <Th variant="medium" bgcolor="secondary.light">
              今回在籍者 (C)
            </Th>
            <Td width="162px" align="right" borderRight>
              {`${usersCount.toLocaleString()}名`}
            </Td>
            <Td width="162px" align="right">
              {`${unit.toLocaleString()}口`}
            </Td>
          </Row>
          <Row borderBottom={false}>
            <Th variant="medium" bgcolor="secondary.light">
              今回拠出 (C-D)
            </Th>
            <Td width="162px" align="right" borderRight>
              {`${contributionUsersCount.toLocaleString()}名`}
            </Td>
            <Td width="162px" align="right">
              {`${contributionUnit.toLocaleString()}口`}
            </Td>
          </Row>
        </TableContainer>

        <TableContainer width="444px">
          <Row>
            <Th variant="medium" bgcolor="secondary.light">
              前回休止者 (B)
            </Th>
            <Td width="162px" align="right" borderRight>
              {`${oldRecessCount.toLocaleString()}名`}
            </Td>
            <Td width="162px" align="right">
              {`${oldRecessUnit.toLocaleString()}口`}
            </Td>
          </Row>
          <Row>
            <Th variant="regular" bgcolor="secondary.bg">
              新規休止
            </Th>
            <Td width="162px" align="right" borderRight>
              {`+${newRecessCount.toLocaleString()}名`}
            </Td>
            <Td width="162px" align="right">
              {`+${newRecessUnit.toLocaleString()}口`}
            </Td>
          </Row>
          <Row>
            <Th variant="regular" bgcolor="secondary.bg" height="64px">
              休止中口数変更
            </Th>
            <Td width="162px" height="64px" align="right" borderRight>
              {`(${recessUnitChangeCount.toLocaleString()}名)`}
            </Td>
            <Box>
              <Td width="162px" height="32px" borderBottom align="right">
                {`+${recessUnitChangeUnitPlus.toLocaleString()}口`}
              </Td>
              <Td width="162px" height="32px" align="right">
                {`-${recessUnitChangeUnitMinus.toLocaleString()}口`}
              </Td>
            </Box>
          </Row>
          <Row>
            <Th variant="regular" bgcolor="secondary.bg">
              休止中の退会
            </Th>
            <Td width="162px" align="right" borderRight>
              {`-${recessWithdrawalCount.toLocaleString()}名`}
            </Td>
            <Td width="162px" align="right">
              {`-${recessWithdrawalUnit.toLocaleString()}口`}
            </Td>
          </Row>
          <Row>
            <Th variant="regular" bgcolor="secondary.bg">
              休止再開
            </Th>
            <Td width="162px" align="right" borderRight>
              {`-${resumeCount.toLocaleString()}名`}
            </Td>
            <Td width="162px" align="right">
              {`-${resumeUnit.toLocaleString()}口`}
            </Td>
          </Row>
          <Row>
            <Th variant="medium" bgcolor="secondary.light">
              今回休止者 (D)
            </Th>
            <Td width="162px" align="right" borderRight>
              {`${recessCount.toLocaleString()}名`}
            </Td>
            <Td width="162px" align="right">
              {`${recessUnit.toLocaleString()}口`}
            </Td>
          </Row>
        </TableContainer>
      </Box>
    </Box>
  );
};
