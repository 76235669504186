import { Box, SxProps, Typography } from '@mui/material';
import { FC, ReactElement } from 'react';
import { HeadlinePaper } from '@/components/molecules/Paper/HeadlinePaper';

interface LoginPaperProps {
  title: string;
  subTitle?: string;
  subTitleSx?: SxProps;
  children: ReactElement;
  popover?: ReactElement;
}

export const LoginPaper: FC<LoginPaperProps> = ({
  title,
  children,
  subTitle = '',
  subTitleSx = { marginTop: 2 },
  popover = undefined,
}) => (
  <Box display="flex" alignItems="center" flexDirection="column">
    <HeadlinePaper title={title} elevation={0} element={popover}>
      <>
        {subTitle && (
          <Box sx={subTitleSx}>
            <Typography variant="body-main/regular">{subTitle}</Typography>
          </Box>
        )}
        <Box display="flex" flexDirection="column" mt={3}>
          {children}
        </Box>
      </>
    </HeadlinePaper>
  </Box>
);
