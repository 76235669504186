import { FC } from 'react';
import { theme } from '@/theme/theme';
import { Box, Typography, styled } from '@mui/material';
import { PrintBackgroundFix } from './TransactionHistory';

interface ItemInfoRowProps {
  isHeader: boolean;
  headerText: string;
  headerSymbol: string;
  value?: string;
}

const Wrapper = styled(Box)(() => {
  const { breakpoints, palette } = theme;

  return {
    display: 'flex',
    flexDirection: 'row',
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.system.separator,

    '&:last-of-type': {
      borderBottomWidth: 0,
    },

    [breakpoints.down('sm')]: {
      width: '100%',
    },

    '@media print': {
      borderBottomWidth: 1,

      '&:last-of-type': {
        borderBottomWidth: 0,
      },
    },
  };
});

const TitleWrapper = styled(Box)(() => {
  const { breakpoints, palette } = theme;
  return {
    flex: 5,
    padding: '12px 8px',
    display: 'flex',
    flexDirection: 'row',
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.system.separator,
    justifyContent: 'flex-end',

    [breakpoints.down('sm')]: {
      flex: 5.5,
      padding: '12px 16px',
    },

    '@media print': {
      flex: 5,
      padding: '12px 8px',
    },
  };
});

const TitleTextWrapper = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
});

const SymbolWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const TitleText = styled(Typography)(() => {
  const { typography } = theme;

  return {
    ...typography['body-main/regular'],
    color: '#0B5D61',
  };
});

const ValueWrapper = styled(Box)(() => {
  const { breakpoints } = theme;
  return {
    flex: 5,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '15px 12px',

    [breakpoints.down('sm')]: {
      flex: 4.5,
      padding: '15px 16px',
    },

    '@media print': {
      flex: 5,
      padding: '15px 12px',
    },
  };
});

const ValueText = styled(Typography)(() => {
  const { typography, palette } = theme;

  return {
    ...typography.h6,
    fontWeight: 700,
    color: palette.system['text-normal'],
  };
});

export const ItemInfoRow: FC<ItemInfoRowProps> = ({
  isHeader,
  headerText,
  headerSymbol,
  value,
}) => (
  <Wrapper>
    <TitleWrapper
      sx={{
        backgroundColor: isHeader ? '#CCE9EB' : '#EFFBFC',
        '@media print': {
          ...PrintBackgroundFix,
        },
      }}
    >
      <TitleTextWrapper
        sx={{
          marginLeft: isHeader ? 0 : 2,
          '@media print': {
            marginLeft: isHeader ? 0 : 2,
          },
        }}
      >
        <TitleText
          sx={{
            fontWeight: isHeader ? 700 : 400,
            '@media print': {
              fontWeight: isHeader ? 700 : 400,
            },
          }}
        >
          {headerText}
        </TitleText>
      </TitleTextWrapper>
      <SymbolWrapper>
        <TitleText
          sx={{
            fontWeight: isHeader ? 700 : 400,
            '@media print': {
              fontWeight: isHeader ? 700 : 400,
            },
          }}
        >
          {headerSymbol}
        </TitleText>
      </SymbolWrapper>
    </TitleWrapper>
    <ValueWrapper>
      <ValueText>{value || '-'}</ValueText>
    </ValueWrapper>
  </Wrapper>
);
