import { Box, Stack, StackProps, styled, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface MembersProxyTableProps extends HeaderProps {
  children: ReactNode;
}

interface LabelAndValueProps {
  label: ReactNode;
  value: ReactNode;
}

interface RowProps extends StackProps {
  column: 1 | 2;
  underline?: boolean;
  children: ReactNode;
}

interface HeaderProps {
  title: string;
}

export const LabelAndValue: FC<LabelAndValueProps> = ({ label, value }) => (
  <Box display="flex" flexGrow={1} gap={1} maxWidth="392px">
    <Typography
      variant="body-main/bold"
      color="system.text-normal"
      width={144}
      flexShrink={0}
    >
      {label}
    </Typography>
    <Typography variant="body-main/regular" color="system.text-normal">
      {value}
    </Typography>
  </Box>
);

export const MembersProxyTableRow: FC<RowProps> = ({
  column,
  underline = false,
  children,
  ...props
}) => (
  <Stack
    borderBottom={underline ? '1px solid' : '0'}
    borderColor="system.separator-light"
    width="100%"
    pb={2}
    {...props}
  >
    {column === 1 && children}
    {column === 2 && (
      <Box display="flex" gap="20px" width="100%">
        {children}
      </Box>
    )}
  </Stack>
);

const Header: FC<HeaderProps> = ({ title }) => (
  <Stack
    spacing={1}
    pb="12px"
    borderBottom="1px solid"
    borderColor="primary.normal"
    width="100%"
  >
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Typography variant="h4" color="system.text-normal">
        {title}
      </Typography>
    </Box>
  </Stack>
);

const Table = styled(Stack)(({ theme: t }) => ({
  width: '100%',
  flexGrow: 1,
  padding: '16px',
  gap: '16px',
  border: '1px solid',
  borderColor: t.palette.system['separator-light'],
  borderRadius: '12px',
}));

const TableWrapper = styled(Box)({
  display: 'flex',
  gap: '20px',
});

export const MembersProxyTable: FC<MembersProxyTableProps> = ({
  title,
  children,
}) => (
  <TableWrapper>
    <Table>
      <Header title={title} />
      {children}
    </Table>
  </TableWrapper>
);
