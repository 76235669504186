export const bonusMultiplierDesignationType = {
  UNIT_NUMBER: '口数指定',
  MAGNIFICATION: '倍率指定',
  FIXED_MAGNIFICATION: '定倍指定',
};
export const accountType = {
  SAVINGS: '普通',
  CURRENT: '当座',
  OTHER: '貯蓄',
};
export const bonusReserveClassification = {
  PURCHASE: '買付',
  RESERVE: '積立',
  NONE: 'なし',
};
