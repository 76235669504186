import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as ExclamationIcon } from '@/assets/exclamationRed.svg';
import { ReactComponent as UnitIcon } from '@/assets/unit.svg';
import { ReactComponent as PartIcon } from '@/assets/part.svg';
import { ReactComponent as RecessIcon } from '@/assets/recess.svg';
import { ReactComponent as WithdrawalIcon } from '@/assets/withdrawal.svg';
import { ReactComponent as ArrowIcon } from '@/assets/arrowRightGrey.svg';
import { ReactComponent as ResumeIcon } from '@/assets/resume.svg';
import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { FindMember } from '@/types/api/members';
import { useGetAssociationsContribution } from '@/services/customHooks/useGetAssociationsContribution';

interface MemberProxyOrganismProps {
  /**
   * 金融機関名
   */
  financialInstitutionName: string;
  /**
   * 会員ステータス
   */
  userStatus: FindMember['userStatus'];
  isListedClassification?: 'LISTEDCOMPANY' | 'UNLISTEDCOMPANY';
}

interface IconWrapperProps {
  height: number;
  label: string;
  href: string;
  disabled?: boolean;
  children: ReactNode;
}

const IconWrapper: FC<IconWrapperProps> = ({
  height,
  label,
  href,
  disabled = false,
  children,
}) => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      alignItems="center"
      height={height}
      width={282}
      p="0 24px"
      borderRadius="12px"
      border="1px solid"
      borderColor="system.separator-light"
      sx={{
        cursor: disabled ? 'default' : 'pointer',
        opacity: disabled ? 0.4 : 1,
      }}
      onClick={disabled ? undefined : () => navigate(href)}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexGrow={1}
      >
        <Box display="flex" alignItems="center" gap={1}>
          {children}
          <Typography
            variant="body-main/medium"
            color="system.text-normal"
            whiteSpace="nowrap"
          >
            {label}
          </Typography>
        </Box>
        <ArrowIcon />
      </Box>
    </Box>
  );
};

export const MemberProxyOrganism: FC<MemberProxyOrganismProps> = ({
  financialInstitutionName,
  isListedClassification,
  userStatus,
}) => {
  const { data: associationContribution } = useGetAssociationsContribution();
  const isNotOperatePay =
    associationContribution?.monthlyUnitClassification !== '1' ||
    !(userStatus === 'NORMAL' || userStatus === 'BEING_RESUMEMENT_PROCESSED');
  const isNotOperateBonus =
    associationContribution?.bonusUnitClassification !== '1' ||
    !(userStatus === 'NORMAL' || userStatus === 'BEING_RESUMEMENT_PROCESSED');
  const isNotOperatePart =
    isListedClassification === 'UNLISTEDCOMPANY' ||
    !(
      userStatus === 'NORMAL' ||
      userStatus === 'BEING_RECESSMENT_PROCESSED' ||
      userStatus === 'RECESS' ||
      userStatus === 'BEING_RESUMEMENT_PROCESSED'
    );
  const isNotOpreateRecess = !(userStatus === 'NORMAL');
  const isNotOpreateWithdrawal = !(
    userStatus === 'NORMAL' || userStatus === 'RECESS'
  );

  return (
    <Stack spacing={3}>
      <Box display="flex" gap={1}>
        <ExclamationIcon />
        <Typography variant="body-main/regular" color="states.error">
          代理申請を行うと申請者・申請時間がシステムに記録されます。
        </Typography>
      </Box>
      {!financialInstitutionName && (
        <Stack spacing={2}>
          <Typography
            variant="h5"
            color="system.text-normal"
            pl="12px"
            borderLeft="2px solid"
            borderColor="#FC5145"
          >
            一部引出・退会には証券口座の登録が必要です。会員本人もしくは制度グループまでご連絡ください。
          </Typography>
        </Stack>
      )}
      <Box display="flex" gap={1}>
        <IconWrapper
          href="pay"
          height={152}
          label="口数を変更する"
          disabled={isNotOperatePay}
        >
          <UnitIcon />
        </IconWrapper>
        <IconWrapper
          href="part"
          height={152}
          label="一部を引き出す"
          disabled={isNotOperatePart}
        >
          <PartIcon />
        </IconWrapper>
        {userStatus === 'RECESS' ? (
          <IconWrapper href="resume" height={152} label="再開する">
            <ResumeIcon />
          </IconWrapper>
        ) : (
          <IconWrapper
            href="recess"
            height={152}
            label="休止する"
            disabled={isNotOpreateRecess}
          >
            <RecessIcon />
          </IconWrapper>
        )}
        <IconWrapper
          href="withdrawal"
          height={152}
          label="退会する"
          disabled={isNotOpreateWithdrawal}
        >
          <WithdrawalIcon />
        </IconWrapper>
      </Box>
    </Stack>
  );
};
