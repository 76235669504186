export const Path = {
  HOME: '/',
  // ログイン
  LOGIN: '/login',
  // ログインMFA
  LOGIN_MFA: '/login/mfa',
  // 管理者情報登録 / 確認
  LOGIN_ADMIN: '/login/admin',
  // 持株会情報登録 / 確認
  LOGIN_ASSOCIATION: '/login/association',
  // 持株会情報登録完了
  LOGIN_ASSOCIATION_COMPLETE: '/login/association/complete',
  // ログインパスワード設定
  LOGIN_PASSWORD_SETTING: '/login/password/setting',
  // パスワード再設定
  LOGIN_PASSWORD_RESETTING: '/login/password/change',
  // パスワード再設定MFA
  LOGIN_PASSWORD_RESETTING_MFA: '/login/password/resetting/mfa',
  // パスワード再設定完了
  LOGIN_PASSWORD_RESETTING_COMPLETE: '/login/password/change/complete',
};
