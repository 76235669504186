import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AssociationContactOrganism } from '@/components/organisms/Association/AssociationContactOrganism';
import { AssociationContactRequest } from '@/types/api/associations';
import { appClient } from '@/services';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { snackbarAtom } from '@/atom/snackbarAtom';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { FormProvider, useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import useSWR from 'swr';

export const AssociationContact = () => {
  const [isError, setIsError] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const openSnackBar = useSetRecoilState(snackbarAtom);
  const navigate = useNavigate();
  const { associationCd = '' } = useRecoilValue(SelectedAssociationState);
  const { mutate } = useSWR(
    associationCd ? '/api/associations/{associationCd}/contacts' : null,
    () => appClient.associations.getAssociationsContact(associationCd)
  );
  const methods = useForm<AssociationContactRequest>({
    defaultValues: {
      inquiryDepartment: '',
      inquiryRepresentative: '',
      inquiryTelEmail: '',
      inquiryTelExtension: '',
      inquiryTelOutsideLine: '',
    },
  });

  const onSubmit = async (data: AssociationContactRequest) => {
    try {
      await appClient.associations.updateAssociationsContact(
        associationCd,
        data
      );
      openSnackBar({
        open: true,
        message: '持株会員向けの問合わせ先を変更しました。',
      });
      navigate('/association');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
  const updateState = async () => {
    const state = await mutate();
    methods.reset(state);
  };

  useEffect(() => {
    void updateState();
  }, [associationCd]);

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
        <AssociationContactOrganism
          isError={isError}
          setIsError={(v) => setIsError(v)}
          isConfirmed={isConfirmed}
          setIsConfirmed={(v) => setIsConfirmed(v)}
          methods={methods}
        />
      </Box>
    </FormProvider>
  );
};
