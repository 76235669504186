import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { theme } from '@/theme';
import { GetAssociation } from '@/types/api/associations';
import { FindMember } from '@/types/api/members';
import { Box, Stack, styled, Typography } from '@mui/material';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

const Info = styled('div')(({ theme: t }) => {
  const { palette } = t;
  return {
    width: '100%',
    backgroundColor: palette.system.background,
    borderRadius: '12px',
    padding: '16px',
  };
});

const Header = styled(Box)({
  paddingBottom: '12px',
  width: '100%',
  borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
  marginBottom: '16px',
  display: 'flex',
  justifyContent: 'space-between',
});

const Title = styled('div')(({ theme: t }) => {
  const { typography, palette } = t;
  return {
    ...typography.h5,
    color: palette.system['text-light'],
  };
});

const Column = styled('div')({
  display: 'flex',
  width: '100%',
  gap: '20px',
});

const Border = styled('div')({
  backgroundColor: 'rgba(0, 0, 0, 0.13)',
  padding: '0.5px',
  marginTop: '8px',
  marginBottom: '8px',
});

const Item = styled('div')(({ theme: t }) => {
  const { typography } = t;
  return {
    ...typography['body-sub/regular'],
    width: '100%',
    display: 'flex',
    gap: '8px',
  };
});

const Label = styled('div')(({ theme: t }) => {
  const { palette } = t;
  return {
    color: palette.system['text-light'],
    width: '104px',
  };
});

const Value = styled('div')(({ theme: t }) => {
  const { palette } = t;
  return {
    color: palette.system['text-normal'],
  };
});

const LeftBlock = styled('div')({
  width: '50%',
});

type Props = {
  user: FindMember;
  association: GetAssociation;
  isConfirmed: boolean;
};

export const MemberProxyApplicationBodyTop: FC<Props> = ({
  user,
  association,
  isConfirmed,
}) => {
  const { memberId = '' } = useParams();

  return (
    <>
      <Info>
        <Header>
          <Title>持株会情報</Title>
        </Header>
        <Column>
          <Item>
            <Label>持株会コード</Label>
            <Value>{user.associationCd}</Value>
          </Item>
          <Item>
            <Label>持株会名</Label>
            <Value>{association.associationName}</Value>
          </Item>
        </Column>
      </Info>
      <Stack spacing={2}>
        <Info>
          <Header>
            <Title>会員情報</Title>
            {!isConfirmed && (
              <NormalLink
                icon
                color="primary.normal"
                stroke={theme.palette.primary.normal}
                href={`/members/${memberId}/information/edit`}
              >
                会員情報を変更する
              </NormalLink>
            )}
          </Header>
          <Column>
            <LeftBlock>
              <Item>
                <Label>氏名（漢字）</Label>
                <Value>{user.nameKanji}</Value>
              </Item>
              <Border />
              <Item>
                <Label>氏名(カナ)</Label>
                <Value>{user.nameKana}</Value>
              </Item>
              <Border />
              <Item>
                <Label>住所</Label>
                <Value>
                  〒{user.zipcode}
                  <br />
                  {user.address1}
                  {user.address2}
                  {user.address3}
                </Value>
              </Item>
            </LeftBlock>
            <LeftBlock>
              <Item>
                <Label>電話番号</Label>
                <Value>{user.tel}</Value>
              </Item>
              <Border />
              <Item>
                <Label>社員コード</Label>
                <Value>{user.employeeCode}</Value>
              </Item>
            </LeftBlock>
          </Column>
        </Info>
        <Typography variant="body-sub/regular" color="system.text-light">
          本申請はインサイダー情報（未公表の重要事実）を知りながら行うものではありません。
        </Typography>
      </Stack>
    </>
  );
};
