import { TextInput } from '@/components/atoms/Input/TextInput';
import { contactFormLabels } from '@/constants/Association/tableLabels';
import { AssociationContactRequest } from '@/types/api/associations';
import { AssociationOrganismProps } from '@/types/components/Association';
import { Stack, TextFieldProps, Typography } from '@mui/material';
import { validateEmail, validateNumber } from '@/utils/validation';
import { FC } from 'react';
import { useController, Controller } from 'react-hook-form';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { AssociationButtons } from './AssociationButtons';
import { AssociationHeader } from './AssociationHeader';
import { AssociationRow as Row } from './AssociationRow';

const StyledTextInput: FC<TextFieldProps> = ({ name = '', ...props }) => {
  const {
    field: { ref, ...rest },
  } = useController({ name });

  return <TextInput inputRef={ref} {...rest} {...props} />;
};

export const RenderForm: FC<
  AssociationOrganismProps<AssociationContactRequest>
> = ({ isConfirmed, setIsConfirmed, methods, isError, setIsError }) => {
  const elementCheckConfirm = () => {
    let returnParam = false;
    if (
      methods.getValues('inquiryTelOutsideLine') === '' &&
      methods.getValues('inquiryTelExtension') === ''
    ) {
      returnParam = true;
    }
    if (
      !validateNumber(methods.getValues('inquiryTelOutsideLine')) &&
      methods.getValues('inquiryTelOutsideLine') !== ''
    ) {
      returnParam = true;
    }
    if (
      !validateNumber(methods.getValues('inquiryTelExtension')) &&
      methods.getValues('inquiryTelExtension') !== ''
    ) {
      returnParam = true;
    }
    if (!validateEmail(methods.getValues('inquiryTelEmail'))) {
      returnParam = true;
    }

    setIsError(returnParam);
  };

  return (
    <>
      <Row label={contactFormLabels.inquiryDepartment}>
        {isConfirmed ? (
          <Typography variant="body-main/regular" color="system/text-normal">
            {methods.getValues('inquiryDepartment')}
          </Typography>
        ) : (
          <StyledTextInput name="inquiryDepartment" sx={{ width: 400 }} />
        )}
      </Row>
      <Row label={contactFormLabels.inquiryRepresentative}>
        {isConfirmed ? (
          <Typography variant="body-main/regular" color="system/text-normal">
            {methods.getValues('inquiryRepresentative')}
          </Typography>
        ) : (
          <StyledTextInput name="inquiryRepresentative" sx={{ width: 400 }} />
        )}
      </Row>
      <Row
        label={[
          contactFormLabels.inquiryTelEmail,
          <br key="br" />,
          <Typography
            key="a"
            variant="caption/regular"
            color="system.text-light"
          >
            ※任意
          </Typography>,
        ]}
      >
        {isConfirmed ? (
          <Stack>
            <Typography variant="body-main/regular" color="system/text-normal">
              {methods.getValues('inquiryTelEmail')}
            </Typography>
          </Stack>
        ) : (
          <Controller
            name="inquiryTelEmail"
            render={({ field }) => (
              <StyledTextInput
                {...field}
                error={!validateEmail(field.value)}
                placeholder="例：abcdef@mail.com"
                sx={{ width: 400 }}
                onChange={(e) => {
                  methods.setValue('inquiryTelEmail', e.target.value);
                  elementCheckConfirm();
                }}
              />
            )}
          />
        )}
      </Row>
      <Row
        label={[
          contactFormLabels.tel,
          <br key="br" />,
          <Typography key="a" variant="caption/regular" color="states.error">
            いずれか必須
          </Typography>,
        ]}
      >
        {isConfirmed ? (
          <Stack spacing={0.5}>
            <Typography variant="body-main/regular" color="system/text-normal">
              {`外線 ${methods.getValues('inquiryTelOutsideLine') ?? ''}`}
            </Typography>
            <Typography variant="body-main/regular" color="system/text-normal">
              {`内線 ${methods.getValues('inquiryTelExtension') ?? ''}`}
            </Typography>
          </Stack>
        ) : (
          <Stack spacing={1}>
            <Stack spacing={0.5}>
              <Typography variant="body-sub/regular" color="system.text-normal">
                外線
              </Typography>
              <Controller
                name="inquiryTelOutsideLine"
                render={({ field }) => (
                  <StyledTextInput
                    {...field}
                    error={
                      methods.getValues('inquiryTelExtension') === '' &&
                      !validateNumber(field.value)
                    }
                    placeholder="12345"
                    sx={{ width: 200 }}
                    onChange={(e) => {
                      methods.setValue('inquiryTelOutsideLine', e.target.value);
                      elementCheckConfirm();
                    }}
                  />
                )}
              />
              <Typography variant="caption/regular" color="system.text-light">
                ※半角＋ハイフンなし
              </Typography>
            </Stack>
            <Stack spacing={0.5}>
              <Typography variant="body-sub/regular" color="system.text-normal">
                内線
              </Typography>
              <Controller
                name="inquiryTelExtension"
                render={({ field }) => (
                  <StyledTextInput
                    {...field}
                    error={
                      !validateNumber(field.value) &&
                      methods.getValues('inquiryTelOutsideLine') === ''
                    }
                    placeholder="12345"
                    sx={{ width: 200 }}
                    onChange={(e) => {
                      methods.setValue('inquiryTelExtension', e.target.value);
                      elementCheckConfirm();
                    }}
                  />
                )}
              />
              <Typography variant="caption/regular" color="system.text-light">
                ※半角＋ハイフンなし
              </Typography>
            </Stack>
          </Stack>
        )}
      </Row>
    </>
  );
};

export const AssociationContactOrganism: FC<
  AssociationOrganismProps<AssociationContactRequest>
> = ({ isConfirmed, setIsConfirmed, methods, isError, setIsError }) => {
  useHelmetHandler({
    title: isConfirmed
      ? '持株会詳細 会員向けのお問い合わせ先編集 確認'
      : '持株会詳細 会員向けのお問い合わせ先編集',
  });

  return (
    <>
      <AssociationHeader type="contact" isConfirmed={isConfirmed} />
      <Stack>
        <Stack p="25px 40px">
          <Stack gap={2}>
            <RenderForm
              isConfirmed={isConfirmed}
              setIsConfirmed={setIsConfirmed}
              methods={methods}
              isError={isError}
              setIsError={setIsError}
            />
          </Stack>
          <AssociationButtons
            isConfirmed={isConfirmed}
            setIsConfirmed={setIsConfirmed}
            isError={isError}
            setIsError={setIsError}
          />
        </Stack>
      </Stack>
    </>
  );
};
