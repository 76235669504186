import { useState, useEffect, useCallback, useMemo } from 'react';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { theme } from '@/theme/theme';
import { Box, styled } from '@mui/material';
import { ReactComponent as PrintIcon } from '@/assets/print.svg';
import { appClient } from '@/services';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  MemberAssetsState,
  MemberBalanceReportsState,
} from '@/recoil/memberInfo/memberAssets';
import { memberInfoState } from '@/recoil/memberInfo/memberInfo';
import { ConvertMemberBalancereports } from '@/types/api/memberBalanceReports';
import { ConvertMemberAssets } from '@/types/api/memberAssets';
import { printComponent } from '@/utils/print';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { BalanceStatement } from './BalanceInquiry/BalanceStatement';
import { GeneralInfo } from './BalanceInquiry/GeneralInfo';
import { TransactionHistory } from './BalanceInquiry/TransactionHistory';
import { MembersHeader } from './MembersHeader';

const Wrapper = styled(Box)(() => {
  const { palette } = theme;

  return {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 40px',
    gap: '24px',
    backgroundColor: palette.system.white,
  };
});

const EmptyWrapper = styled(Box)({
  ...theme.typography['body-sub/medium'],
  color: theme.palette.system['text-light'],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  flexGrow: 1,
});

export const MemberBalance = () => {
  const [isEmpty, setEmpty] = useState(false);
  const date = useMemo(() => new Date(), []);
  const { memberId } = useParams();
  const memberData = useRecoilValue(memberInfoState);
  const [memberAssets, setMemberAssets] = useRecoilState(MemberAssetsState);
  const [memberBalanceReports, setMemberBalanceReports] = useRecoilState(
    MemberBalanceReportsState
  );
  useHelmetHandler({
    title: '持株会会員詳細 残高照会',
  });

  const getMemberAssetsData = useCallback(async () => {
    try {
      if (!memberId) return;
      const res = await appClient.members.findMemberAsset(
        parseInt(memberId, 10)
      );
      if (res) {
        const convertedData = ConvertMemberAssets(res);
        if (convertedData) setMemberAssets(convertedData);
      }
    } catch (e) {
      // handle getMemberAssetsData error
    }
  }, [memberId, setMemberAssets]);

  const getMemberBalanaceReports = useCallback(async () => {
    try {
      if (!memberId) return;
      const res = await appClient.members.findMemberBalancereport(
        parseInt(memberId, 10),
        date.getFullYear()
      );
      if (res) {
        const convertedData = ConvertMemberBalancereports(res);
        if (convertedData) setMemberBalanceReports(convertedData);
      }
    } catch (e) {
      // handle getMemberBalanceReports error
    }
  }, [date, memberId, setMemberBalanceReports]);

  const [investmentDigit, setInvestmentDigit] = useState<number>();

  const getAssociation = useCallback(async () => {
    try {
      if (!memberData?.associationCd) return;
      const res = await appClient.associations.getAssociation(
        memberData.associationCd
      );
      if (res) {
        setInvestmentDigit(res.investmentDigit);
      }
    } catch (e) {
      // handle getMemberBalanceReports error
    }
  }, [memberData]);

  const handlePrint = () => {
    printComponent('print_content_wrapper', () => {});
  };

  const renderBalance = () => (
    <Wrapper>
      <MembersHeader memberData={memberData} />
      <PrimaryButton
        startIcon={<PrintIcon />}
        sx={{
          width: 204,
          backgroundColor: 'secondary.button',
          marginBottom: '24px',
        }}
        onClick={() => handlePrint()}
      >
        <Box sx={{ color: 'secondary.text' }}>残高明細を印刷する</Box>
      </PrimaryButton>
      <Box
        id="print_content_wrapper"
        sx={{
          '@page': {
            size: 'A4 landscape',
          },
        }}
      >
        <GeneralInfo
          memberAssets={memberAssets}
          date={date}
          investmentDigit={investmentDigit}
        />
        <BalanceStatement
          memberAssets={memberAssets}
          date={date}
          investmentDigit={investmentDigit}
        />
        <TransactionHistory
          memberBalancereports={memberBalanceReports}
          investmentDigit={investmentDigit}
        />
      </Box>
    </Wrapper>
  );

  const renderEmptyContent = () => (
    <EmptyWrapper>残高明細がありません</EmptyWrapper>
  );

  const renderContent = () => {
    if (isEmpty) return renderEmptyContent();
    return renderBalance();
  };

  useEffect(() => {
    void getMemberAssetsData();
    void getMemberBalanaceReports();
  }, [getMemberAssetsData, getMemberBalanaceReports]);

  useEffect(() => {
    void getAssociation();
  }, [getAssociation]);

  return renderContent();
};
