/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ApplicationsUserDetailEdit } from '@/components/organisms/ApplicationsEdits/UserDetailEdit';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { UserEditInputs as Inputs } from '@/types/components/Inputs';
import { PrevnextApplicationParams } from '@/types/api/Applications/prevnextApplications';
import { ErrorResponse } from '@/types/api/error';
import { usePrevnextApplication } from '@/services/customHooks/usePrevnextApplication';
import { useApplication } from '@/services/customHooks/useApplication';
import { useMember } from '@/services/customHooks/useMember';
import { appClient } from '@/services';
import { useSnackbar } from '@/hooks/useSnackbar';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

export const UserDetailEdit: FC = () => {
  const navigate = useNavigate();
  const applicationId = Number(useParams().applicationId) || 0;
  const { data: applicationData } = useApplication(applicationId);
  const { data: userData, updateMemberId } = useMember();
  const [searchParams] = useSearchParams();
  const { showSnackbar } = useSnackbar();
  const [query] = useState<PrevnextApplicationParams>(
    searchParams as PrevnextApplicationParams
  );
  const { data: bottomData } = usePrevnextApplication(applicationId, query);
  useHelmetHandler({
    title: '会員情報 申請修正',
  });

  const onSubmit: SubmitHandler<Inputs> = async (d: Inputs) => {
    try {
      await appClient.applications.updateApplication(applicationId, {
        applicationId,
        applicationUser: {
          employeeCode: d.employeeCd,
          birthday: `${d.birthdayYear}-${d.birthdayMonth}-${d.birthdayDay}`,
          nameKanji: d.name,
          nameKana: d.nameKana,
          zipcode: d.zipcode,
          address1: d.address1,
          address2: d.address2,
          address3: d.address3,
          tel: d.tel,
          telType: d.telType,
          officeCd: d.officeCd,
          affiliationCd: d.affiliationCd,
        },
      });
      if (applicationId) navigate(`/applications/user/${applicationId}`);
    } catch (e) {
      // エラー処理
      showSnackbar((e as ErrorResponse).data.message);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (applicationData?.memberId) updateMemberId(applicationData?.memberId);
  }, [applicationData?.memberId]);

  if (applicationData && applicationData?.memberId === userData?.memberId) {
    return (
      <ApplicationsUserDetailEdit
        application={applicationData}
        bottomData={bottomData}
        userData={userData}
        onSubmit={onSubmit}
      />
    );
  }
  return null;
};
