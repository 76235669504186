import { CancelButton } from '@/components/atoms/Buttons/CancelButton';
import { Box, styled, SxProps, Theme, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';

interface DeleteOfficeUserProps {
  associationName: string;
  name: string;
  permission?: string;
  handleCancel: () => void;
  handleDelete: () => void;
}

const styleContainer: SxProps<Theme> = [
  {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    width: '520px',
    p: '32px',
  },
];

const Label: FC<{ children: string | undefined }> = ({ children }) => (
  <Typography
    variant="body-main/regular"
    sx={{
      color: 'system.text-normal',
      width: '65px',
      textAlign: 'left',
      minWidth: '65px',
    }}
  >
    {children}
  </Typography>
);

const Value: FC<{ children: string | undefined }> = ({ children }) => (
  <Typography
    variant="body-main/medium"
    sx={{ color: 'system.text-normal', textAlign: 'left' }}
  >
    {children}
  </Typography>
);

const ItemWrapper: FC<{ children: ReactNode }> = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      justifyItems: 'flex-start',
    }}
  >
    {children}
  </Box>
);

const ItemRow = styled(Box)({
  display: 'flex',
  gap: '16px',
  alignItems: 'flex-start',
});

export const DeleteOfficeUser: FC<DeleteOfficeUserProps> = ({
  associationName,
  name,
  permission,
  handleCancel,
  handleDelete,
}) => (
  <Box sx={styleContainer}>
    <Typography variant="h4" sx={{ color: 'system.text-normal' }}>
      担当者を持株会から外す
    </Typography>
    <Typography
      component="p"
      variant="body-main/regular"
      sx={{ color: 'system.text-normal' }}
    >
      担当者を以下の持株会から外しますか？
    </Typography>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        p: '16px',
        gap: '16px',
        width: '456px',
        bgcolor: 'system.background',
        borderRadius: '4px',
      }}
    >
      <ItemWrapper>
        <ItemRow>
          <Label>持株会名</Label>
          <Value>{associationName}</Value>
        </ItemRow>
        <ItemRow>
          <Label>名前</Label>
          <Value>{name}</Value>
        </ItemRow>
        <ItemRow>
          <Label>権限</Label>
          <Value>{permission}</Value>
        </ItemRow>
      </ItemWrapper>
    </Box>
    <Box sx={{ display: 'flex', gap: '16px' }}>
      <CancelButton
        sx={{
          width: '112px',
          height: '41px',
          whiteSpace: 'nowrap',
        }}
        onClick={handleCancel}
      >
        キャンセル
      </CancelButton>
      <PrimaryButton
        sx={{
          width: '144px',
          height: '41px',
          whiteSpace: 'nowrap',
        }}
        onClick={handleDelete}
      >
        持株会から外す
      </PrimaryButton>
    </Box>
  </Box>
);
