import { MenuItemProps } from '@mui/material';

const date = new Date();

// NUM=値 LEN=桁数
function zeroPadding(NUM: number, LEN: number) {
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  return (Array(LEN).join('0') + NUM).slice(-LEN);
}

export const years = Array.from({ length: 100 }, (_, i) => ({
  key: String(date.getFullYear() - 99 + i),
  value: String(date.getFullYear() - 99 + i),
}));

export const months = Array.from({ length: 12 }, (_, i) => ({
  key: String(zeroPadding(i + 1, 2)),
  value: String(1 + i),
}));

export const days = Array.from({ length: 31 }, (_, i) => ({
  key: String(zeroPadding(i + 1, 2)),
  value: String(1 + i),
}));

type SelectBoxOptions = MenuItemProps<
  'li',
  {
    key: string;
    value: string;
    disabled?: boolean | undefined;
  }
>[];

export const monthDays = (
  month: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
  indicatedDate?: Date
): SelectBoxOptions => {
  const today = indicatedDate || new Date();
  if (month === 2) {
    if (today.getFullYear() % 4 === 0) {
      return Array.from({ length: 29 }, (_, i) => ({
        key: String(zeroPadding(i + 1, 2)),
        value: String(1 + i),
      })) as SelectBoxOptions;
    }
    return Array.from({ length: 28 }, (_, i) => ({
      key: String(zeroPadding(i + 1, 2)),
      value: String(1 + i),
    })) as SelectBoxOptions;
  }
  if (
    month === 1 ||
    month === 3 ||
    month === 5 ||
    month === 7 ||
    month === 8 ||
    month === 10 ||
    month === 12
  ) {
    return Array.from({ length: 31 }, (_, i) => ({
      key: String(zeroPadding(i + 1, 2)),
      value: String(1 + i),
    })) as SelectBoxOptions;
  }
  return Array.from({ length: 30 }, (_, i) => ({
    key: String(zeroPadding(i + 1, 2)),
    value: String(1 + i),
  })) as SelectBoxOptions;
};

export const monthsWithSelect = () => {
  const newValue = [...months];
  newValue.unshift({ key: '0', value: '選択' });
  return newValue;
};

export const daysWithSelect = () => {
  const newValue = [...days];
  newValue.unshift({ key: '0', value: '選択' });
  return newValue;
};

export const mochikabukais = [
  {
    key: '12345671',
    value: 'AAAAAA従業員持株会',
  },
  {
    key: '12345672',
    value: 'AAAAAA役員持株会',
  },
  {
    key: '12345673',
    value: 'BBBBBBBB従業員持株会',
  },
  {
    key: '12345674',
    value: 'BBBBBBBB役員持株会',
  },
  {
    key: '12345675',
    value: 'AAAAAA従業員持株会',
  },
  {
    key: '12345676',
    value: 'AAAAAA従業員持株会',
  },
  {
    key: '12345677',
    value: 'AAAAAA役員持株会',
  },
  {
    key: '12345678',
    value: 'BBBBBBBB従業員持株会',
  },
  {
    key: '12345679',
    value: 'BBBBBBBB役員持株会',
  },
  {
    key: '12345680',
    value: 'AAAAAA従業員持株会',
  },
];

export const backofficeUsers = [
  {
    key: '0',
    value: '担当者を選択',
    disabled: true,
  },
  {
    key: '1',
    value: '全員',
  },
  {
    key: '2',
    value: '田中　太郎',
  },
  {
    key: '3',
    value: '田中　太郎',
  },
];

export const yearAndMonth = [
  {
    key: '0',
    value: '該当年月を選択',
  },
  {
    key: '1',
    value: '2022/01',
  },
  {
    key: '2',
    value: '2022/02',
  },
  {
    key: '3',
    value: '2022/03',
  },
  {
    key: '4',
    value: '2022/04',
  },
  {
    key: '5',
    value: '2022/05',
  },
];

export const prefectures = [
  { key: '1', value: '北海道' },
  { key: '2', value: '青森県' },
  { key: '3', value: '岩手県' },
  { key: '4', value: '宮城県' },
  { key: '5', value: '秋田県' },
  { key: '6', value: '山形県' },
  { key: '7', value: '福島県' },
  { key: '8', value: '茨城県' },
  { key: '9', value: '栃木県' },
  { key: '10', value: '群馬県' },
  { key: '11', value: '埼玉県' },
  { key: '12', value: '千葉県' },
  { key: '13', value: '東京都' },
  { key: '14', value: '神奈川県' },
  { key: '15', value: '新潟県' },
  { key: '16', value: '富山県' },
  { key: '17', value: '石川県' },
  { key: '18', value: '福井県' },
  { key: '19', value: '山梨県' },
  { key: '20', value: '長野県' },
  { key: '21', value: '岐阜県' },
  { key: '22', value: '静岡県' },
  { key: '23', value: '愛知県' },
  { key: '24', value: '三重県' },
  { key: '25', value: '滋賀県' },
  { key: '26', value: '京都府' },
  { key: '27', value: '大阪府' },
  { key: '28', value: '兵庫県' },
  { key: '29', value: '奈良県' },
  { key: '30', value: '和歌山県' },
  { key: '31', value: '鳥取県' },
  { key: '32', value: '島根県' },
  { key: '33', value: '岡山県' },
  { key: '34', value: '広島県' },
  { key: '35', value: '山口県' },
  { key: '36', value: '徳島県' },
  { key: '37', value: '香川県' },
  { key: '38', value: '愛媛県' },
  { key: '39', value: '高知県' },
  { key: '40', value: '福岡県' },
  { key: '41', value: '佐賀県' },
  { key: '42', value: '長崎県' },
  { key: '43', value: '熊本県' },
  { key: '44', value: '大分県' },
  { key: '45', value: '宮崎県' },
  { key: '46', value: '鹿児島県' },
  { key: '47', value: '沖縄県' },
];

export const reasonsForRecess = [
  {
    key: '',
    value: '休止理由を選択してください',
  },
  {
    key: 'LEAVE_ABSENCE',
    value: '休職',
  },
  {
    key: 'FAMILY_MATTERS',
    value: '家庭の事情',
  },
  {
    key: 'ECONOMIC',
    value: '経済的理由',
  },
  {
    key: 'OTHER',
    value: 'その他（理由を記入）',
  },
];

export const reasonsForWithdrawal = [
  {
    key: '',
    value: '退会理由を選択してください',
  },
  {
    key: 'LEAVE_ABSENCE',
    value: '退職',
  },
  {
    key: 'FAMILY_MATTERS',
    value: '家庭の事情',
  },
  {
    key: 'ECONOMIC',
    value: '経済的理由',
  },
  {
    key: 'OTHER',
    value: 'その他（理由を記入）',
  },
];

export const Listed = [
  {
    key: '0',
    value: '上場/非上場を選択',
    disabled: true,
  },
  {
    key: '1',
    value: 'すべて',
  },
  {
    key: '2',
    value: '上場',
  },
  {
    key: '3',
    value: '非上場',
  },
];
export const Asignees = [
  {
    key: '0',
    value: '担当者を選択',
    disabled: true,
  },
  {
    key: '1',
    value: '全員',
  },
  {
    key: '2',
    value: '山崎 正和',
  },
  {
    key: '3',
    value: '山本 耕三',
  },
  {
    key: '4',
    value: '勅使河原 菊久之助',
  },
];

export const Types = [
  {
    key: '0',
    value: '種別を選択',
    disabled: true,
  },
  {
    key: '1',
    value: 'すべて',
  },
  {
    key: '2',
    value: '月例',
  },
  {
    key: '3',
    value: '賞与',
  },
  {
    key: '4',
    value: '配当',
  },
  {
    key: '5',
    value: '臨時',
  },
];

export const Statuses = [
  {
    key: '0',
    value: 'ステータスを選択',
    disabled: true,
  },
  {
    key: '1',
    value: 'すべて',
  },
  {
    key: '2',
    value: '買付日登録',
    disabled: true,
  },
  {
    key: '3',
    value: '登録前',
  },
  {
    key: '4',
    value: '登録後',
  },
  {
    key: '5',
    value: '作業処理連絡票/購入(売却)連絡票',
    disabled: true,
  },
  {
    key: '6',
    value: '受領前',
  },
  {
    key: '7',
    value: '未承認',
  },
  {
    key: '8',
    value: '承認済',
  },
  {
    key: '9',
    value: '提出可',
  },
  {
    key: '10',
    value: '完了',
  },
];
