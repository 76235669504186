import { Box, Typography, styled, TextField } from '@mui/material';
import { FC } from 'react';
import { Controller, Control } from 'react-hook-form';
import {
  OfficeUserCreateInputs as Inputs,
  KeyOfOfficeUserAddInputs as KeyOfInputs,
} from '@/types/components/OfficeUser';

interface OfficeUserCreateNameFieldProps {
  label?: string;
  isConfirmed: boolean;
  value: string;
  fieldName: KeyOfInputs;
  control: Control<Inputs>;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
}
const MuiTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    paddingLeft: '8px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '8px 8px 8px 0px',
    textAlign: 'left',
  },
});

export const OfficeUserCreateNameField: FC<OfficeUserCreateNameFieldProps> = ({
  label = '',
  isConfirmed,
  value,
  fieldName,
  control,
  placeholder = '',
  error = false,
  helperText = '',
}) =>
  isConfirmed ? (
    <Typography>{value}</Typography>
  ) : (
    <Box>
      {label && <Typography>{label}</Typography>}
      <Controller
        name={fieldName}
        control={control}
        render={({ field }) => (
          <MuiTextField
            placeholder={placeholder}
            name={fieldName}
            error={error && field.value !== ''}
            sx={{
              minWidth: '200px',
              maxHeight: 39,
            }}
            value={field.value}
            onChange={field.onChange}
            helperText={error && field.value !== '' && helperText}
          />
        )}
      />
    </Box>
  );
