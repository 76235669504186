import { FC, useState } from 'react';
import {
  styled,
  Button,
  Stack,
  Box,
  Typography,
  TextField,
} from '@mui/material';
import { theme } from '@/theme';
import { BasicModal } from '@/components/molecules/Modal';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { ReactComponent as Cross } from '@/assets/cross.svg';
import { ReactComponent as CrossDisabled } from '@/assets/crossDisabled.svg';
import { ReactComponent as Approve } from '@/assets/approve.svg';
import { ReactComponent as ApproveDisabled } from '@/assets/approveDisabled.svg';
import { ReactComponent as Batsu } from '@/assets/batsu.svg';
import { FindApplicationByID } from '@/types/api/Applications/application';

const CancelButton = styled(Button)({
  ...theme.typography['body-main/regular'],
  display: 'flex',
  gap: '4px',
  border: '1px solid',
  borderColor: theme.palette.system['separator-light'],
  borderRadius: '4px',
  color: theme.palette.system['text-normal'],
  backgroundColor: theme.palette.system['background-dark'],
  padding: '8px 16px',
  '&:hover': {
    backgroundColor: theme.palette.system.inactive,
  },
});

const ApproveButton = styled(Button)({
  ...theme.typography['body-main/bold'],
  display: 'flex',
  gap: '4px',
  borderRadius: '4px',
  color: theme.palette.system.white,
  backgroundColor: theme.palette.states.success,
  padding: '8px 16px',
  '&:hover': {
    backgroundColor: theme.palette.states.success,
  },
});

interface Props {
  status: string;
  isFull?: boolean;
  handleApplication?: (
    value: 'APPROVAL' | 'REJECTED',
    comment?: string
  ) => void;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  isWithdrawal?: boolean;
  isAdmission?: boolean;
  eventNo?: FindApplicationByID['eventNo'];
}

export const ApplicationButtons: FC<Props> = ({
  status,
  isFull = false,
  handleApplication,
  isOpen,
  setIsOpen,
  isWithdrawal,
  isAdmission,
  eventNo,
}) => {
  const [comment, setComment] = useState<string>('');
  const switchButtons = () => {
    switch (status) {
      case 'PREPARATION':
      case 'APPLYING':
        if (isWithdrawal) {
          return (
            <>
              <CancelButton onClick={() => setIsOpen(true)}>
                <Cross />
                却下する
              </CancelButton>
              {isFull ? (
                <ApproveButton
                  onClick={() =>
                    handleApplication && handleApplication('APPROVAL')
                  }
                >
                  <Approve />
                  承認する
                </ApproveButton>
              ) : (
                <ApproveButton
                  sx={{ backgroundColor: 'system.background-dark' }}
                  disabled
                >
                  <ApproveDisabled />
                  承認する
                </ApproveButton>
              )}
            </>
          );
        }
        if (isAdmission && !eventNo) {
          return (
            <CancelButton onClick={() => setIsOpen(true)}>
              <Cross />
              却下する
            </CancelButton>
          );
        }
        return (
          <>
            <CancelButton onClick={() => setIsOpen(true)}>
              <Cross />
              却下する
            </CancelButton>
            <ApproveButton
              onClick={() => handleApplication && handleApplication('APPROVAL')}
            >
              <Approve />
              承認する
            </ApproveButton>
          </>
        );
      case 'OFFICER_APPROVED':
      case 'BACKOFFICER_APPROVED':
        return (
          <>
            <ApproveButton
              sx={{ backgroundColor: 'system.background-dark' }}
              disabled
            >
              <CrossDisabled />
              却下する
            </ApproveButton>
            <CancelButton sx={{ pointerEvents: 'none' }}>
              <ApproveDisabled />
              承認済み
            </CancelButton>
          </>
        );

      case 'OFFICER_REJECTED':
      case 'BACKOFFICER_REJECTED':
      case 'CANCEL':
        return (
          <>
            <CancelButton sx={{ pointerEvents: 'none' }}>
              <CrossDisabled />
              却下済み
            </CancelButton>
            <ApproveButton
              sx={{ backgroundColor: 'system.background-dark' }}
              disabled
            >
              <ApproveDisabled />
              承認する
            </ApproveButton>
          </>
        );
      default:
        return null;
    }
  };
  return (
    <>
      <Stack spacing={2} direction="row">
        {switchButtons()}
      </Stack>
      <BasicModal open={isOpen} handleClose={() => setIsOpen(false)}>
        <Stack p={3} spacing={2} width="648px">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">却下する</Typography>
            <Box
              color={theme.palette.secondary.normal}
              fontWeight={500}
              alignItems="center"
              display="flex"
              gap="9px"
              fontSize="14px"
              sx={{ cursor: 'pointer' }}
              onClick={() => setIsOpen(false)}
            >
              <Batsu />
              <Typography>閉じる</Typography>
            </Box>
          </Box>
          <Typography color={theme.palette.system['text-light']}>
            コメントを入力してください。
          </Typography>
          <TextField
            sx={{ width: '100%', height: '220px' }}
            multiline
            rows={8}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <Box display="flex" justifyContent="center" gap="16px">
            <CancelButton onClick={() => setIsOpen(false)}>
              キャンセル
            </CancelButton>
            <PrimaryButton
              disabled={comment === ''}
              onClick={() =>
                handleApplication && handleApplication('REJECTED', comment)
              }
            >
              送信する
            </PrimaryButton>
          </Box>
        </Stack>
      </BasicModal>
    </>
  );
};
