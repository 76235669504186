/* eslint-disable no-unsafe-optional-chaining */
import { FC, ChangeEvent, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { theme } from '@/theme';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextInput } from '@/components/atoms/Input/TextInput';
import { IsNumeric } from '@/utils/stringProcessor';
import * as yup from 'yup';
import { PrevnextApplications } from '@/types/api/Applications/prevnextApplications';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { PartEditInputs as Inputs } from '@/types/components/Inputs';
import { FindApplicationByID } from '@/types/api/Applications/application';
import { useForm, SubmitHandler } from 'react-hook-form';
import { FindMember } from '@/types/api/members';
import { useMemberSecuritiesAccount } from '@/services/customHooks/useMemberSecuritiesAccount';
import { MemberAssets } from '@/types/api/memberAssets';
import { TitleHead } from '../Applications/SharedParts/TitleHead';
import { UserInfoTable } from '../Applications/SharedParts/UserInfoTable';
import { ApplicationBottom } from '../Applications/SharedParts/ApplicationBottom';
import { ApplicationTable as Table } from '../Applications/SharedParts/ApplicationTable';
import { ApplicationTableRow as Row } from '../Applications/SharedParts/ApplicationTableRow';

interface PartProps {
  application: FindApplicationByID;
  unitType: string;
  userData: FindMember;
  memberAsset?: MemberAssets;
  bottomData: PrevnextApplications | undefined;
  onSubmit: SubmitHandler<Inputs>;
}

const schema = yup.object({
  unitNum: yup
    .string()
    .required('必須項目です')
    .matches(/[0-9]/, '*半角数字。'),
});

export const ApplicationsPartEdit: FC<PartProps> = ({
  application,
  unitType,
  userData,
  memberAsset,
  bottomData,
  onSubmit,
}) => {
  const { setValue, handleSubmit } = useForm<Inputs>({
    defaultValues: {
      unitNum: application?.applicationPart?.sharesNum
        ? application?.applicationPart?.sharesNum / 100
        : 0,
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const [unitNum, setUnitNum] = useState<string>(
    ((application?.applicationPart?.sharesNum || 0) / 100).toString()
  );

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let amount = unitNum;
    if (IsNumeric(e.target.value)) {
      const userMaxAvailableUnits =
        ((application?.applicationPart?.sharesNum || 0) +
          (application?.applicationPart?.leftSharesNum || 0)) /
        100;
      amount = Math.floor(
        Math.min(Number(e.target.value), userMaxAvailableUnits ?? 0)
      ).toString();
    } else if (e.target.value === '') {
      amount = '';
    }
    setUnitNum(amount || '');
    setValue('unitNum', Number(amount ?? 0));
  };

  const isDisabled = !(Number(unitNum) !== 0 && !!unitNum);

  const { data: memberSecurityAccount } = useMemberSecuritiesAccount(
    Number(userData.memberId)
  );

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.system['background-dark'],
        height: '100%',
      }}
    >
      <Stack
        spacing={4}
        sx={{
          backgroundColor: theme.palette.system.white,
          padding: '40px 160px',
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <TitleHead
          title="一部引出"
          status={application.applicationStatus || ''}
          applicationDate={application.applicationDate || ''}
          applicationId={application.applicationId || ''}
          associationCd={application.associationCd || ''}
          proxyApplicantUserName={application.proxyApplicantUserName}
          unitType={unitType}
        />
        <UserInfoTable data={userData} />
        <Table title="証券口座">
          {memberSecurityAccount &&
          memberSecurityAccount?.accountNumber &&
          memberSecurityAccount?.branchCd &&
          memberSecurityAccount?.branchName ? (
            <>
              <Row labels="金融機関名" values="東京東海証券" />
              <Row
                labels="部店コード"
                values={memberSecurityAccount.branchCd?.replace(
                  memberSecurityAccount.branchCd,
                  '*'.repeat(memberSecurityAccount.branchCd?.length)
                )}
              />
              <Row
                labels="部店名"
                values={memberSecurityAccount.branchName?.replace(
                  memberSecurityAccount.branchName,
                  '*'.repeat(memberSecurityAccount.branchName?.length)
                )}
                isEven
              />
              <Row
                labels="口座番号"
                values={memberSecurityAccount.accountNumber?.replace(
                  memberSecurityAccount.accountNumber,
                  '*'.repeat(memberSecurityAccount.accountNumber?.length)
                )}
                isEven
              />
            </>
          ) : (
            <Typography
              sx={{
                ...theme.typography['body-sub/regular'],
                color: theme.palette.system['text-normal'],
              }}
            >
              未登録です。
            </Typography>
          )}
        </Table>
        <Table title="引出株数" isRed>
          <Row
            labels="現在の保有株数"
            values={`${memberAsset?.stocksNumber?.toLocaleString() || 0}株`}
          />
          <Row labels="引出株数" isEven>
            <Stack direction="row" spacing={2} alignItems="center">
              <Box sx={{ direction: 'row', alignItems: 'center' }}>
                <TextInput
                  name="unitNum"
                  value={unitNum}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onKeyDown={(e) => {
                    if (e.code === 'Minus') e.preventDefault();
                  }}
                  sx={{ width: '80px' }}
                />
                <Typography
                  sx={{ marginLeft: '8px', marginRight: '30px' }}
                  variant="body-sub/regular"
                >
                  00株
                </Typography>
              </Box>
              <Typography
                variant="body-sub/regular"
                color={theme.palette.system['text-light']}
              >
                ※100株単位
              </Typography>
            </Stack>
          </Row>
          <Row
            labels="残り株数"
            values={`${((memberAsset?.stocksNumber || 0)  - (application?.applicationPart?.sharesNum || 0)).toLocaleString()}株`}
          />
        </Table>
        <PrimaryButton
          sx={{ width: '96px' }}
          type="submit"
          disabled={isDisabled}
        >
          修正完了
        </PrimaryButton>
      </Stack>
      {bottomData && (
        <Box sx={{ padding: '32px 160px' }}>
          <ApplicationBottom data={bottomData} unitType={unitType} />
        </Box>
      )}
    </Box>
  );
};
