import { useRecoilState } from 'recoil';

import { sessionTimeOutModalAtom } from '@/recoil/sessionTimeOutModal';
// eslint-disable-next-line import/no-cycle
import { useAuthUser } from './useAuth';

/**
 * useSessionTimeOutModal
 * @example
 * ```ts
 *  const {
 *  sessionTimeOutModal,
 *  handleSessionTimeOutModalOpen,
    handleSessionTimeOutModalClose,
    handleClickLogout, } = useLogoutModal()
 * ```
 * @category ReactHooks
 */
export const useSessionTimeOutModal = () => {
  const { handleLogout } = useAuthUser();
  // usage Recoil
  const [sessionTimeOutModal, setSessionTimeOutModal] = useRecoilState<boolean>(
    sessionTimeOutModalAtom
  );

  /**
   * handleSessionTimeOutModalOpen
   */
  const handleSessionTimeOutModalOpen = () => {
    setSessionTimeOutModal(true);
  };

  /**
   * handleSessionTimeOutModalClose
   */
  const handleSessionTimeOutModalClose = () => {
    setSessionTimeOutModal(false);
  };

  /**
   * handleClickLogout
   */
  const handleClickUserLogout = () => {
    handleLogout();
    handleSessionTimeOutModalClose();
  };

  return {
    sessionTimeOutModal,
    handleSessionTimeOutModalOpen,
    handleSessionTimeOutModalClose,
    handleClickUserLogout,
  };
};
