import {
  Box,
  MenuItem,
  MenuItemProps,
  Select,
  SelectChangeEvent,
  SelectProps,
  styled,
  SvgIconProps,
} from '@mui/material';
import { FC, useState, forwardRef } from 'react';
import { ReactComponent as ArrowIcon } from '@/assets/keyboardArrowDownIcon.svg';
import { theme } from '@/theme';

export interface SelectBoxProps extends SelectProps {
  items: MenuItemProps<
    'li',
    { key: string; value: string; disabled?: boolean }
  >[];
  width?: '256px' | '160px' | '320px' | '200px' | '88px' | '296px' | string;
  backgroundColor?: string;
  placeholder?: string;
  isKeyAValue?: boolean;
  updateValue?: (value: string) => void;
  setValue?: (value: string) => void;
  initialValue?: string;
  helperText?: string;
  setedValue?: string;
  selectBoxType?: boolean;
}

const MuiSelect = styled(Select)(() => {
  const { typography, palette } = theme;

  return {
    ...typography['body-main/regular'],
    padding: '8px',
    height: '40px',
    color: theme.palette.system['text-normal'],
    '&&> .MuiSelect-select.MuiOutlinedInput-input': {
      padding: '0 16px 0 0',
    },
    '&> fieldset': {
      border: '1px solid',
      borderColor: palette.system.placeholder,

      borderRadius: '4px',
    },
    '&.Mui-disabled': {
      opacity: 0.4,
    },
  };
});

const MuiOption = styled(MenuItem)(() => {
  const { typography, palette } = theme;
  return {
    ...typography['body-main/regular'],
    color: palette.system['text-normal'],
  };
});
const Placeholder = styled(Box)({
  color: theme.palette.system.placeholder,
});

const CustomSvgIcon: FC<SvgIconProps> = (props) => <ArrowIcon {...props} />;

export const SelectBox = forwardRef((props: SelectBoxProps, ref) => {
  const {
    items,
    width,
    backgroundColor,
    disabled,
    updateValue,
    setValue,
    initialValue = '',
    placeholder,
    isKeyAValue = false,
    setedValue,
    selectBoxType = false,
    ...rest
  } = props;
  const [selectedValue, setSelectedValue] = useState<string>(initialValue);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    if (typeof event.target.value === 'string') {
      setSelectedValue(event.target.value);
      if (setValue) setValue(event.target.value);
      if (updateValue) updateValue(event.target.value);
    }
  };

  const createRenderValue = (selectData: string) => {
    let returnParam;
    if (setedValue !== undefined) {
      returnParam = <Box>{setedValue}</Box>;
    } else if (selectBoxType) {
      returnParam = <Placeholder>{placeholder}</Placeholder>;
    } else {
      returnParam = <Box>{selectData}</Box>;
    }

    return returnParam;
  };

  return (
    <MuiSelect
      displayEmpty
      value={selectedValue}
      IconComponent={CustomSvgIcon}
      sx={{
        width,
        backgroundColor,
      }}
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onChange={(e) => {
        handleChange(e);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        rest.onChange;
      }}
      disabled={disabled}
      ref={ref}
      renderValue={(selected) => createRenderValue(String(selected))}
      {...rest}
      // renderValue={(selected) => {
      //   if (setedValue !== undefined) {
      //     return (<Box>{setedValue}</Box>);
      //   } else if (selectBoxType) {
      //     return (<Placeholder>{placeholder}</Placeholder>);
      //   } else {
      //     return (<Box>{String(selected)}</Box>);
      //   }
      // }}
      // renderValue={
      //   setedValue !== undefined
      //     ? () => <Box>{setedValue}</Box>
      //     : () => <Placeholder>{placeholder}</Placeholder>
      // }
    >
      {/* {placeholder && (
        <MuiOption value="" disabled>
          {placeholder}
        </MuiOption>
      )} */}
      {items.map((item) => {
        const { key, value, disabled: d } = item;

        return (
          <MuiOption key={key} value={isKeyAValue ? key : value} disabled={d}>
            {value}
          </MuiOption>
        );
      })}
    </MuiSelect>
  );
});
