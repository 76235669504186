import { atom } from 'recoil';

export const pageState = atom({
  key: 'pageState', // unique ID (with respect to other atoms/selectors)
  default: 1, // default value (aka initial value)
});

export const perPageSizeState = atom({
  key: 'perPageSizeState',
  default: 10,
});

export const totalSizeState = atom({
  key: 'totalSizeState',
  default: 0,
});

export const totalPageState = atom({
  key: 'totalPageState',
  default: 0,
});
