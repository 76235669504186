/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ApplicationsWithdrawal } from '@/components/organisms/Applications/Withdrawal';
import { FC, useState, useEffect } from 'react';
import {
  useMatch,
  useParams,
  useSearchParams,
  useNavigate,
} from 'react-router-dom';
import { MemberById } from '@/types/api/member';
import {
  FindAssociationsAccount,
  GetAssociation,
} from '@/types/api/associations';
import { FindApplicationByID } from '@/types/api/Applications/application';
import { usePrevnextApplication } from '@/services/customHooks/usePrevnextApplication';
import { PrevnextApplicationParams } from '@/types/api/Applications/prevnextApplications';
import { appClient } from '@/services';
import { useSnackbar } from '@/hooks/useSnackbar';
import { ErrorResponse } from '@/types/api/error';

export const Withdrawal: FC = () => {
  const match = useMatch('/applications/:unitType/:applicationId/withdrawal');
  const unitType = match?.params.unitType || '';
  const applicationId = Number(useParams().applicationId) || 0;
  const [applicationData, setApplicationData] = useState<FindApplicationByID>();
  const [userData, setUserData] = useState<MemberById>();
  const [associationData, setAssociationData] = useState<GetAssociation>();
  const [associationsAccount, setAssociationsAccount] =
    useState<FindAssociationsAccount>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  // const associationData = useAssociation(applicationData?.associationCd || '');
  const [query] = useState<PrevnextApplicationParams>(
    searchParams as PrevnextApplicationParams
  );
  const { data: bottomData } = usePrevnextApplication(applicationId, query);
  const { showSnackbar } = useSnackbar();
  const [remarks, setRemarks] = useState<string>('');
  const [transferAmount, setTransferAmount] = useState<string>(
    String(applicationData?.applicationWithdrawal?.transferAmount || '')
  );

  const handleApplication = async (
    value: 'APPROVAL' | 'REJECTED',
    comment?: string
  ) => {
    try {
      if (
        value === 'APPROVAL' &&
        associationData?.withdrawalAllSettlementUsageClassification !== '1' &&
        applicationData?.applicationWithdrawal?.isAdditionalOption &&
        (applicationData?.applicationStatus === 'APPLYING' ||
          applicationData?.applicationStatus === 'PREPARATION')
      ) {
        await appClient.applications.updateApplicationApproval(
          applicationId,
          value,
          {
            comment: comment || '',
            transferAmount: Number(transferAmount),
            remarks,
          }
        );
      } else {
        await appClient.applications.updateApplicationApproval(
          applicationId,
          value,
          {
            comment: comment || '',
            transferAmount: 0,
            remarks: '',
          }
        );
      }

      navigate(`/applications/${unitType}`);
    } catch (e) {
      showSnackbar((e as ErrorResponse).data.message);
    }
  };
  const [isOpen, setIsOpen] = useState(false);

  const getApplicationData = async () => {
    try {
      const res = await appClient.applications.findApplicationById(
        applicationId
      );
      setApplicationData(res);
    } catch (e) {
      console.log(e);
    }
  };

  const getUserData = async () => {
    try {
      if (applicationData?.memberId && applicationData.memberId !== 0) {
        const res = await appClient.members.findMember(
          applicationData?.memberId
        );
        setUserData(res);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const GetAssociationsAccount = async () => {
    try {
      if (applicationData?.associationCd) {
        const res = await appClient.associations.findAssociationsAccount(
          applicationData?.associationCd
        );
        setAssociationsAccount(res);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const GetAssociationData = async () => {
    try {
      if (applicationData?.associationCd) {
        const res = await appClient.associations.getAssociation(
          applicationData?.associationCd
        );
        setAssociationData(res);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    void getApplicationData();
  }, [applicationId]);

  useEffect(() => {
    void getUserData();
    void GetAssociationsAccount();
    void GetAssociationData();
  }, [applicationData]);

  if (applicationData && userData && associationData && associationsAccount) {
    return (
      <ApplicationsWithdrawal
        application={applicationData}
        unitType={unitType}
        userData={userData}
        bottomData={bottomData}
        associationsAccount={associationsAccount}
        associationData={associationData}
        isOpen={isOpen}
        handleApplication={handleApplication}
        setIsOpen={setIsOpen}
        transferAmount={transferAmount}
        setTransferAmount={setTransferAmount}
        setRemarks={setRemarks}
      />
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
