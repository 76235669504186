import { FC, useEffect, useMemo, useState } from 'react';
import {
  styled,
  Box,
  Stack,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { theme } from '@/theme';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { UserEditInputs as Inputs } from '@/types/components/Inputs';
import { SearchAddressButton } from '@/components/atoms/Buttons/SearchAddressButton';
import { SelectBox } from '@/components/atoms/Input/SelectBox';
import {
  months,
  prefectures,
  years,
} from '@/constants/SelectBox/choicesForSelectBox';
import { getDayList } from '@/utils/dateFunction';
import { FindApplicationByID } from '@/types/api/Applications/application';
import { PrevnextApplications } from '@/types/api/Applications/prevnextApplications';
import { format } from 'date-fns';
import {
  IsNumeric,
  IsAlphabetNumer,
  validateKatagana,
} from '@/utils/validation';
import { IsStarZenkaku } from '@/utils/stringProcessor';
import { useGetAddressApi } from '@/hooks/useGetAddressApi';
import { FindMember } from '@/types/api/members';
import { TextInput } from '@/components/atoms/Input/TextInput';
import { TitleHead } from '../Applications/SharedParts/TitleHead';
import { ApplicationBottom } from '../Applications/SharedParts/ApplicationBottom';
import { ApplicationTable as Table } from '../Applications/SharedParts/ApplicationTable';
import { ApplicationTableRow as Row } from '../Applications/SharedParts/ApplicationTableRow';

interface UserProps {
  application: FindApplicationByID;
  bottomData?: PrevnextApplications;
  userData: FindMember;
  onSubmit: SubmitHandler<Inputs>;
}

const ChangeWrapper = styled(Box)({
  backgroundColor: '#EFFBFC',
  padding: '8px 12px',
  maxWidth: '80px',
});

const MuiRadioGroup = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'row',
});

const ValidateText = styled(Typography)({
  ...theme.typography['caption/regular'],
  color: theme.palette.states.error,
});

export const ApplicationsUserDetailEdit: FC<UserProps> = ({
  application,
  userData,
  bottomData,
  onSubmit,
}) => {
  const birthdayList = useMemo(() => {
    const birthday = application.applicationUser?.birthday || 0;
    return Object.keys(birthday).length > 0
      ? format(new Date(birthday), 'yyyy-MM-dd').split('-')
      : ['', '', ''];
  }, [application.applicationUser?.birthday]);
  const { control, handleSubmit, setValue, getValues, watch } = useForm<Inputs>(
    {
      defaultValues: {
        name: `${application.applicationUser?.nameKanji ?? ''}`,
        nameKana: `${application.applicationUser?.nameKana ?? ''}`,
        zipcode: application.applicationUser?.zipcode ?? '',
        address1: application.applicationUser?.address1 ?? '',
        address2: application.applicationUser?.address2 ?? '',
        address3: application.applicationUser?.address3 ?? '',
        employeeCd: application.applicationUser?.employeeCode ?? '',
        userCd: application.applicationUser?.userCode ?? '',
        officeCd: application.applicationUser?.officeCd ?? '',
        affiliationCd: application.applicationUser?.affiliationCd ?? '',
        tel: application.applicationUser?.tel ?? '',
        telType: application.applicationUser?.telType ?? 'HOME',
        birthdayYear: birthdayList[0],
        birthdayMonth: birthdayList[1],
        birthdayDay: birthdayList[2],
      },
    }
  );
  const getIsEdited = (info: string | number, oldInfo: string | number) =>
    info !== oldInfo;

  const [dayList, setDayList] = useState([{ key: '', value: '' }]);
  const { addressData, getAddress } = useGetAddressApi();
  const beforeBirthDay = userData.birthday
    ? format(new Date(userData.birthday), 'yyyy年MM月dd日')
    : '';
  const afterBirthDay = application.applicationUser?.birthday
    ? format(new Date(application.applicationUser.birthday), 'yyyy年MM月dd日')
    : '';

  const [isNameKanjiError, setIsNameKanjiError] = useState<boolean>(false);
  const [isKataganaError, setIsKataganaError] = useState<boolean>(false);
  const [isAddress2Error, setIsAddress2Error] = useState<boolean>(false);
  const [isAddress3Error, setIsAddress3Error] = useState<boolean>(false);

  useEffect(() => {
    setValue('birthdayDay', '');
    setDayList(
      getDayList(Number(watch('birthdayYear')), Number(watch('birthdayMonth')))
    );
  }, [watch('birthdayYear'), watch('birthdayMonth')]);
  useEffect(() => {
    if (!addressData || addressData.length === 0) return;
    setValue('address1', addressData[0].pref);
    setValue('address2', `${addressData[0].city}${addressData[0].town}`);
    setValue('address3', '');
  }, [addressData]);
  useEffect(() => {
    const [birthdayYear, birthdayMonth, birthdayDay] = birthdayList;
    setValue('birthdayYear', birthdayYear);
    setValue('birthdayMonth', birthdayMonth);
    setValue('birthdayDay', birthdayDay);
  }, [birthdayList, setValue]);

  useEffect(() => {
    setValue('name', application.applicationUser?.nameKanji ?? '');
    setValue('nameKana', application.applicationUser?.nameKana ?? '');
    setValue('zipcode', application.applicationUser?.zipcode ?? '');
    setValue('address1', application.applicationUser?.address1 ?? '');
    setValue('address2', application.applicationUser?.address2 ?? '');
    setValue('address3', application.applicationUser?.address3 ?? '');
    setValue('employeeCd', application.applicationUser?.employeeCode ?? '');
    setValue('userCd', application.applicationUser?.userCode ?? '');
    setValue('officeCd', application.applicationUser?.officeCd ?? '');
    setValue('affiliationCd', application.applicationUser?.affiliationCd ?? '');
    setValue('tel', application.applicationUser?.tel ?? '');
    setValue('telType', application.applicationUser?.telType ?? 'HOME');
    setValue('birthdayYear', birthdayList[0]);
    setValue('birthdayMonth', birthdayList[1]);
    setValue('birthdayDay', birthdayList[2]);
  }, [application]);

  const isDisabled = () =>
    !(
      watch('name') &&
      watch('nameKana') &&
      watch('tel') &&
      watch('zipcode') &&
      watch('birthdayDay') &&
      watch('birthdayMonth') &&
      watch('birthdayYear') &&
      watch('tel') &&
      watch('address1') &&
      watch('address2') &&
      watch('address3') &&
      watch('employeeCd') &&
      IsStarZenkaku(watch('name').replace(/\s+/g, '')) &&
      watch('name').length <= 20 &&
      validateKatagana(watch('nameKana').replace(/\s+/g, '')) &&
      watch('nameKana').length <= 20 &&
      IsStarZenkaku(watch('address2').replace(/\s+/g, '')) &&
      IsStarZenkaku(watch('address3').replace(/\s+/g, ''))
    );

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.system['background-dark'],
        height: '100%',
      }}
    >
      <Stack
        spacing={4}
        sx={{
          backgroundColor: theme.palette.system.white,
          padding: '40px 160px',
        }}
      >
        <TitleHead
          title="会員情報変更"
          status={application.applicationStatus ?? ''}
          applicationDate={application.applicationDate ?? ''}
          applicationId={application.applicationId ?? ''}
          associationCd={application.associationCd ?? ''}
          proxyApplicantUserName={application.proxyApplicantUserName ?? ''}
          unitType="user"
        />
        <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
          <ChangeWrapper>
            <Typography variant="body-sub/medium">変更箇所</Typography>
          </ChangeWrapper>
          <Stack
            spacing={3}
            direction="row"
            sx={{ justifyContent: 'space-between' }}
          >
            <Table title="会員情報(変更前）">
              <Row
                labels="会員コード"
                values={userData.memberCd}
                sx={{ height: '64px' }}
              />
              <Row
                labels="氏名（漢字）"
                values={userData.nameKanji}
                sx={{ height: '64px' }}
              />
              <Row
                labels="氏名（カナ）"
                values={userData.nameKana}
                sx={{ height: '64px' }}
              />
              <Row
                labels="生年月日"
                values={beforeBirthDay}
                sx={{ height: '110px' }}
              />
              <Row
                labels="住所"
                values={[
                  `〒${userData?.zipcode ?? ''}`,
                  `${userData?.address1 ?? ''}${userData?.address2 ?? ''}${
                    userData?.address3 ?? ''
                  }`,
                ]}
                sx={{ height: '353px', alignItems: 'flex-start' }}
              />
              <Row
                labels="電話番号"
                values={[
                  userData.telType === 'MOBILE' ? '携帯' : '自宅',
                  userData.tel ?? '',
                ]}
                sx={{ height: '122px' }}
              />
              <Row
                labels="社員コード"
                values={userData.employeeCode}
                sx={{ height: '64px' }}
              />
              <Row
                labels={['会員事業所', 'コード']}
                values={userData.officeCd}
                sx={{ height: '90px' }}
                isOptional
              />
              <Row
                labels={['会員所属コード']}
                values={userData.affiliationCd}
                sx={{ height: '66px' }}
                isOptional
              />
            </Table>
            <Table title="会員情報(変更後）" isRed>
              <Row
                labels="会員コード"
                sx={{ height: '64px' }}
                values={userData.memberCd ?? ''}
              />
              <Row
                labels="氏名（漢字）"
                sx={{ minHeight: '64px', paddingRight: '8px' }}
                isEdited={getIsEdited(
                  application.applicationUser?.nameKanji ?? '',
                  userData.nameKanji ?? ''
                )}
              >
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Stack>
                      <TextInput
                        {...field}
                        sx={{ width: '100%', height: '40px' }}
                        placeholder="例：田中　太郎"
                        error={!watch('name') || isNameKanjiError}
                        onChange={(e) => {
                          setIsNameKanjiError(false);
                          if (e.target.value.length >= 1) {
                            setIsNameKanjiError(
                              !IsStarZenkaku(e.target.value.replace(/\s+/g, ''))
                            );
                          }
                          if (e.target.value.length > 20) {
                            setIsNameKanjiError(true);
                          }
                          setValue('name', e.target.value);
                        }}
                      />
                      {!watch('name') && (
                        <ValidateText>入力してください</ValidateText>
                      )}
                      {isNameKanjiError && watch('name').length <= 20 && (
                        <ValidateText>
                          入力できない文字が含まれています
                        </ValidateText>
                      )}
                      {isNameKanjiError && watch('name').length > 20 && (
                        <ValidateText>
                          20文字以内で入力してください
                        </ValidateText>
                      )}
                    </Stack>
                  )}
                />
              </Row>
              <Row
                labels="氏名（カナ）"
                sx={{ minHeight: '64px', paddingRight: '8px' }}
                isEdited={getIsEdited(
                  application.applicationUser?.nameKana ?? '',
                  userData.nameKana ?? ''
                )}
              >
                <Controller
                  name="nameKana"
                  control={control}
                  render={({ field }) => (
                    <Stack>
                      <TextInput
                        {...field}
                        sx={{ width: '100%', height: '40px' }}
                        placeholder="例：タナカ　タロウ"
                        error={!watch('nameKana') || isKataganaError}
                        onChange={(e) => {
                          setIsKataganaError(false);
                          if (e.target.value.length >= 1) {
                            setIsKataganaError(
                              !validateKatagana(
                                e.target.value.replace(/\s+/g, '')
                              )
                            );
                          }
                          if (e.target.value.length > 20) {
                            setIsKataganaError(true);
                          }
                          setValue('nameKana', e.target.value);
                        }}
                      />
                      {!watch('nameKana') && (
                        <ValidateText>入力してください</ValidateText>
                      )}
                      {isKataganaError && watch('nameKana').length <= 20 && (
                        <ValidateText>カタカナで入力してください</ValidateText>
                      )}
                      {isKataganaError && watch('nameKana').length > 20 && (
                        <ValidateText>
                          20文字以内で入力してください
                        </ValidateText>
                      )}
                    </Stack>
                  )}
                />
              </Row>
              <Row
                labels="生年月日"
                sx={{ height: '110px' }}
                isEdited={getIsEdited(beforeBirthDay, afterBirthDay)}
              >
                <Stack>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '8px',
                      width: '100%',
                    }}
                  >
                    <Controller
                      name="birthdayYear"
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          {...field}
                          items={years}
                          error={!watch('birthdayYear')}
                          sx={{
                            minWidth: '80px',
                            backgroundColor: !watch('birthdayYear')
                              ? '#FFF2F2'
                              : theme.palette.system.white,
                          }}
                        />
                      )}
                    />
                    <Typography variant="body-sub/regular">年</Typography>
                    <Controller
                      name="birthdayMonth"
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          {...field}
                          items={months}
                          error={!watch('birthdayMonth')}
                          sx={{
                            minWidth: '80px',
                            backgroundColor: !watch('birthdayMonth')
                              ? '#FFF2F2'
                              : theme.palette.system.white,
                          }}
                          isKeyAValue
                        />
                      )}
                    />
                    <Typography variant="body-sub/regular">月</Typography>
                    <Controller
                      name="birthdayDay"
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          {...field}
                          items={dayList}
                          error={!watch('birthdayDay')}
                          sx={{
                            minWidth: '80px',
                            backgroundColor: !watch('birthdayDay')
                              ? '#FFF2F2'
                              : theme.palette.system.white,
                          }}
                          isKeyAValue
                        />
                      )}
                    />
                    <Typography variant="body-sub/regular">日</Typography>
                  </Box>
                  {(!watch('birthdayYear') ||
                    !watch('birthdayMonth') ||
                    !watch('birthdayDay')) && (
                    <ValidateText>選択してください</ValidateText>
                  )}
                </Stack>
              </Row>
              <Row
                labels="住所"
                sx={{ minHeight: '353px', alignItems: 'flex-start' }}
                isEdited={
                  getIsEdited(
                    application.applicationUser?.zipcode ?? '',
                    userData.zipcode ?? ''
                  ) &&
                  getIsEdited(
                    application.applicationUser?.address1 ?? '',
                    userData.address1 ?? ''
                  ) &&
                  getIsEdited(
                    application.applicationUser?.address2 ?? '',
                    userData.address2 ?? ''
                  ) &&
                  getIsEdited(
                    application.applicationUser?.address3 ?? '',
                    userData.address3 ?? ''
                  )
                }
              >
                <Stack spacing={2}>
                  <Stack>
                    <Typography variant="body-sub/regular">郵便番号</Typography>
                    <Stack direction="row" spacing={1}>
                      <Controller
                        name="zipcode"
                        control={control}
                        render={({ field }) => (
                          <TextInput
                            {...field}
                            sx={{ width: '138px', height: '40px' }}
                            placeholder="例：1036129"
                            error={!watch('zipcode')}
                            onChange={(e) => {
                              if (
                                (IsNumeric(e.target.value) &&
                                  e.target.value.length <= 7) ||
                                e.target.value === ''
                              ) {
                                setValue(
                                  'zipcode',
                                  e.target.value.replaceAll(' ', '')
                                );
                              }
                            }}
                          />
                        )}
                      />
                      <SearchAddressButton
                        onClick={() => {
                          const zip = getValues().zipcode;
                          if (zip) getAddress(zip);
                        }}
                      >
                        住所を自動入力
                      </SearchAddressButton>
                    </Stack>
                    {!watch('zipcode') && (
                      <ValidateText>入力してください</ValidateText>
                    )}
                    <Typography
                      variant="caption/regular"
                      color={theme.palette.system['text-light']}
                    >
                      ※ハイフン無し、半角7桁
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography>都道府県</Typography>
                    <Controller
                      name="address1"
                      control={control}
                      render={({ field }) => (
                        <Stack>
                          <SelectBox
                            {...field}
                            items={prefectures}
                            error={!watch('address1')}
                            sx={{
                              minWidth: '160px',
                              backgroundColor: !watch('address1')
                                ? '#FFF2F2'
                                : theme.palette.system.white,
                            }}
                          />
                          {!watch('address1') && (
                            <ValidateText>選択してください</ValidateText>
                          )}
                        </Stack>
                      )}
                    />
                  </Stack>
                  <Stack gap="4px">
                    <Typography variant="body-sub/regular">市区町村</Typography>
                    <Controller
                      name="address2"
                      control={control}
                      render={({ field }) => (
                        <Stack>
                          <TextInput
                            {...field}
                            placeholder="例：中央区"
                            error={!watch('address2') || isAddress2Error}
                            sx={{ marginRight: '8px', height: '40px' }}
                            onChange={(e) => {
                              setIsAddress2Error(false);
                              if (e.target.value.length >= 1) {
                                setIsAddress2Error(
                                  !IsStarZenkaku(
                                    e.target.value.replace(/\s+/g, '')
                                  )
                                );
                              }
                              if (e.target.value.length <= 50) {
                                setValue('address2', e.target.value);
                              }
                            }}
                          />
                          {!watch('address2') && (
                            <ValidateText>入力してください</ValidateText>
                          )}
                          {isAddress2Error && (
                            <ValidateText>
                              入力できない文字が含まれています
                            </ValidateText>
                          )}
                        </Stack>
                      )}
                    />
                  </Stack>
                  <Stack>
                    <Typography variant="body-sub/regular">
                      番地・マンションなど
                    </Typography>
                    <Controller
                      name="address3"
                      control={control}
                      render={({ field }) => (
                        <Stack>
                          <TextInput
                            {...field}
                            placeholder="例：日本橋2丁目 5-1"
                            error={!watch('address3') || isAddress3Error}
                            sx={{ marginRight: '8px', height: '40px' }}
                            onChange={(e) => {
                              setIsAddress3Error(false);
                              if (e.target.value.length >= 1) {
                                setIsAddress3Error(
                                  !IsStarZenkaku(
                                    e.target.value.replace(/\s+/g, '')
                                  )
                                );
                              }
                              if (e.target.value.length <= 50) {
                                setValue('address3', e.target.value);
                              }
                            }}
                          />
                          {!watch('address3') && (
                            <ValidateText>入力してください</ValidateText>
                          )}
                          {isAddress3Error && (
                            <ValidateText>
                              入力できない文字が含まれています
                            </ValidateText>
                          )}
                        </Stack>
                      )}
                    />
                  </Stack>
                </Stack>
              </Row>
              <Row
                labels="電話番号"
                sx={{ height: '122px' }}
                isEdited={getIsEdited(
                  application.applicationUser?.tel ?? '',
                  userData.tel ?? ''
                )}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    gap: '8px',
                    width: '100%',
                  }}
                >
                  <FormControl>
                    <MuiRadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="yes"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        checked={watch('telType') === 'MOBILE'}
                        control={<Radio />}
                        label="携帯"
                        onClick={() => setValue('telType', 'MOBILE')}
                      />
                      <FormControlLabel
                        checked={watch('telType') !== 'MOBILE'}
                        control={<Radio />}
                        label="自宅"
                        onClick={() => setValue('telType', 'HOME')}
                      />
                    </MuiRadioGroup>
                  </FormControl>
                </Box>
                <Controller
                  name="tel"
                  control={control}
                  render={({ field }) => (
                    <Stack>
                      <TextInput
                        {...field}
                        placeholder="例：12345678901"
                        sx={{ width: '160px', height: '40px' }}
                        error={!watch('tel')}
                        onChange={(e) => {
                          if (
                            (e.target.value.length <= 15 &&
                              IsNumeric(e.target.value)) ||
                            e.target.value === ''
                          ) {
                            setValue('tel', e.target.value.replaceAll(' ', ''));
                          }
                        }}
                      />
                      {!watch('tel') && (
                        <ValidateText>入力してください</ValidateText>
                      )}
                    </Stack>
                  )}
                />
              </Row>
              <Row
                labels="社員コード"
                sx={{ minHeight: '64px' }}
                isEdited={getIsEdited(
                  application.applicationUser?.employeeCode ?? '',
                  userData.employeeCode ?? ''
                )}
              >
                <Controller
                  name="employeeCd"
                  control={control}
                  render={({ field }) => (
                    <Stack>
                      <TextInput
                        {...field}
                        sx={{ width: '196px', height: '40px' }}
                        placeholder="例：1234"
                        error={!watch('employeeCd')}
                        onChange={(e) => {
                          if (
                            (e.target.value.length <= 10 &&
                              IsAlphabetNumer(e.target.value)) ||
                            e.target.value === ''
                          ) {
                            setValue(
                              'employeeCd',
                              e.target.value.replaceAll(' ', '')
                            );
                          }
                        }}
                      />
                      {!watch('employeeCd') && (
                        <ValidateText>入力してください</ValidateText>
                      )}
                    </Stack>
                  )}
                />
              </Row>
              <Row
                labels={['会員事業所', 'コード']}
                sx={{ height: '90px' }}
                isEdited={getIsEdited(
                  userData.officeCd ?? '',
                  application.applicationUser?.officeCd ?? ''
                )}
                isOptional
              >
                <Controller
                  name="officeCd"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      placeholder="例：123456"
                      sx={{ width: '196px' }}
                      onChange={(e) => {
                        if (
                          (e.target.value.length <= 10 &&
                            IsAlphabetNumer(e.target.value)) ||
                          e.target.value === ''
                        ) {
                          setValue(
                            'officeCd',
                            e.target.value.replaceAll(' ', '')
                          );
                        }
                      }}
                    />
                  )}
                />
              </Row>
              <Row
                labels={['会員所属コード']}
                sx={{ height: '64px' }}
                isEdited={getIsEdited(
                  userData.affiliationCd ?? '',
                  application.applicationUser?.affiliationCd ?? ''
                )}
                isOptional
              >
                <Controller
                  name="affiliationCd"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      placeholder="例：123456"
                      sx={{ width: '196px' }}
                      onChange={(e) => {
                        if (
                          (e.target.value.length <= 10 &&
                            IsAlphabetNumer(e.target.value)) ||
                          e.target.value === ''
                        ) {
                          setValue(
                            'affiliationCd',
                            e.target.value.replaceAll(' ', '')
                          );
                        }
                      }}
                    />
                  )}
                />
              </Row>
            </Table>
          </Stack>
          <PrimaryButton
            sx={{ width: '96px' }}
            type="submit"
            disabled={isDisabled()}
          >
            修正完了
          </PrimaryButton>
        </Stack>
      </Stack>
      {bottomData && (
        <Box sx={{ padding: '32px 160px' }}>
          <ApplicationBottom data={bottomData} unitType="user" />
        </Box>
      )}
    </Box>
  );
};
