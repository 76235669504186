/* eslint-disable react/jsx-curly-brace-presence */
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, Typography, Box, Stack } from '@mui/material';
import { theme } from '@/theme';
import { PrevnextApplications } from '@/types/api/Applications/prevnextApplications';
import { StatusBadge } from '@/components/atoms/Badge/StatusBadge';
import { formatDateTimeWithSlash } from '@/utils/dateFunction';
import { ReactComponent as RightArrow } from '@/assets/rightArrow.svg';
import { ReactComponent as LeftArrow } from '@/assets/leftArrow.svg';

const NextPageBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const BoxWrapper = styled(Box)({
  backgroundColor: theme.palette.system.white,
  borderRadius: '8px',
  // width: '50%',
  display: 'flex',
  gap: '20px',
  alignItems: 'center',
  padding: '16px',
  cursor: 'pointer',
});

const Info = styled(Box)({
  color: theme.palette.system['text-light'],
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
});

const Date = styled(Typography)({
  ...theme.typography['body-sub/regular'],
  color: theme.palette.system['text-light'],
});

const convertTypeIntoJapanese = (value: string) => {
  switch (value) {
    case 'MONTHLY_UNIT':
      return '口数変更(給与)';
    case 'BONUS_UNIT':
      return '口数変更(賞与)';
    case 'PART':
      return '一部引出';
    case 'RECESS':
      return '休止';
    case 'RESUME':
      return '再開';
    case 'WITHDRAWAL':
      return '退会';
    case 'ADMISSION':
      return '新規会員登録';
    case 'USER':
      return '会員情報変更';
    case 'TEMPORARY':
      return '臨時';
    default:
      return '';
  }
};

const exchangeTypeToUrl = (value: string) => {
  switch (value) {
    case 'MONTHLY_UNIT':
    case 'BONUS_UNIT':
      return 'unit';
    case 'PART':
      return 'part';
    case 'RECESS':
      return 'recess';
    case 'RESUME':
      return 'resume';
    case 'WITHDRAWAL':
      return 'withdrawal';
    case 'ADMISSION':
      return 'newuser';
    case 'USER':
      return 'user';
    case 'TEMPORARY':
      return 'temporary';
    default:
      return '';
  }
};

interface BottomProps {
  data?: PrevnextApplications;
  unitType?: string;
}

export const ApplicationBottom: FC<BottomProps> = ({ data, unitType = '' }) => {
  const navigate = useNavigate();
  const moveNext = () => {
    if (
      data &&
      data.applicationNext &&
      data.applicationNext[0].applicationId !== 0
    ) {
      switch (unitType) {
        case 'MONTHLY_UNIT':
        case 'monthly':
          navigate(
            `/applications/monthly/${
              data?.applicationNext[0]?.applicationId || ''
            }/${exchangeTypeToUrl(
              data?.applicationNext[0].applicationType || ''
            )}`
          );
          break;
        case 'BONUS_UNIT':
        case 'bonus':
          navigate(
            `/applications/bonus/${
              data?.applicationNext[0]?.applicationId || ''
            }/${exchangeTypeToUrl(
              data?.applicationNext[0].applicationType || ''
            )}`
          );
          break;
        case 'admission':
          navigate(
            `/applications/admission/${
              data?.applicationNext[0]?.applicationId || ''
            }`
          );
          break;
        case 'user':
          navigate(
            `/applications/user/${
              data?.applicationNext[0]?.applicationId || ''
            }`
          );
          break;
        default:
          break;
      }
    }
  };
  const movePrev = () => {
    if (
      data &&
      data.applicationPrev &&
      data.applicationPrev[0].applicationId !== 0
    ) {
      switch (unitType) {
        case 'monthly':
          navigate(
            `/applications/monthly/${
              data?.applicationPrev[0]?.applicationId || ''
            }/${exchangeTypeToUrl(
              data?.applicationPrev[0].applicationType || ''
            )}`
          );
          break;
        case 'bonus':
          navigate(
            `/applications/bonus/${
              data?.applicationPrev[0]?.applicationId || ''
            }/${exchangeTypeToUrl(
              data?.applicationPrev[0].applicationType || ''
            )}`
          );
          break;
        case 'admission':
          navigate(
            `/applications/admission/${
              data?.applicationPrev[0]?.applicationId || ''
            }`
          );
          break;
        case 'user':
          navigate(
            `/applications/user/${
              data?.applicationPrev[0]?.applicationId || ''
            }`
          );
          break;
        default:
          break;
      }
    }
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      width="100%"
    >
      <Box width="50%">
        {data && data.applicationNext && data.applicationNext.length > 0 && (
          <BoxWrapper onClick={() => moveNext()}>
            <NextPageBox>
              <LeftArrow />
            </NextPageBox>
            <Stack sx={{ gap: '4px' }}>
              <Info>
                <StatusBadge
                  sx={{ width: '48px', height: '22px' }}
                  status={data.applicationNext[0].applicationStatus || ''}
                />
                <Typography variant="body-main/regular">
                  ［
                  {convertTypeIntoJapanese(
                    data.applicationNext[0].applicationType || ''
                  )}
                  ］
                </Typography>
                <Typography variant="body-main/medium">
                  申請ID：{data.applicationNext[0].applicationId}
                </Typography>
              </Info>
              <Date>
                {formatDateTimeWithSlash(
                  data.applicationNext[0].applicationDate || ''
                )}
              </Date>
            </Stack>
          </BoxWrapper>
        )}
      </Box>

      <Box width="50%">
        {data && data.applicationPrev && data.applicationPrev.length > 0 && (
          <BoxWrapper
            sx={{ justifyContent: 'space-between' }}
            onClick={() => movePrev()}
          >
            <Stack sx={{ gap: '4px' }}>
              <Info>
                <StatusBadge
                  sx={{ width: '48px', height: '22px' }}
                  status={data.applicationPrev[0].applicationStatus || ''}
                />
                <Typography variant="body-main/regular">
                  ［
                  {convertTypeIntoJapanese(
                    data.applicationPrev[0].applicationType || ''
                  )}
                  ］
                </Typography>
                <Typography variant="body-main/medium">
                  申請ID：{data.applicationPrev[0].applicationId}
                </Typography>
              </Info>
              <Date>
                {formatDateTimeWithSlash(
                  data.applicationPrev[0].applicationDate || ''
                )}
              </Date>
            </Stack>
            <NextPageBox>
              <RightArrow />
            </NextPageBox>
          </BoxWrapper>
        )}
      </Box>
    </Stack>
  );
};
