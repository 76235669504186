import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { BasicTable } from '@/components/molecules/Table/BasicTable';
import { TableHeadRow } from '@/components/atoms/Table/TableHeadRow';
import { TableHeadCell } from '@/components/atoms/Table/TableHeadCell';
import { TableBodyRow } from '@/components/atoms/Table/TableBodyRow';
import { TableBodyCell } from '@/components/atoms/Table/TableBodyCell';
import { StatusBadge } from '@/components/atoms/Badge/StatusBadge';
import { ApplicationsApplicationItem } from '@/types/api/Applications/applications';
import { format, parseISO } from 'date-fns';
import { ja } from 'date-fns/locale';
import { SearchByUserInputs } from './SharedParts/SearchByUserInputs';
import { IndexTop } from './SharedParts/IndexTop';

type Filter = {
  label: string;
  value: string;
};

type SortKeyType =
  | 'APPLICATION_ID_ASC'
  | 'APPLICATION_ID_DESC'
  | 'APPLICATION_TYPE_ASC'
  | 'APPLICATION_TYPE_DESC'
  | 'MEMBER_CD_ASC'
  | 'MEMBER_CD_DESC'
  | 'MEMBER_NAME_ASC'
  | 'MEMBER_NAME_DESC'
  | 'EMPLOYEE_CD_ASC'
  | 'EMPLOYEE_CD_DESC'
  | 'APPLICATION_DATE_ASC'
  | 'APPLICATION_DATE_DESC'
  | 'APPLICATION_STATUS_ASC'
  | 'APPLICATION_STATUS_DESC';

interface UserProps {
  type: string;
  data: ApplicationsApplicationItem[];
  keywordValue?: string;
  setSelectedFilter?: (filter: Filter) => void;
  onClickSearch: (value: string) => void;
  sortKey?: SortKeyType;
  updateSortKey?: (value: SortKeyType) => void;
}

export const ApplicationsUserIndex: FC<UserProps> = ({
  type,
  data,
  keywordValue,
  setSelectedFilter,
  onClickSearch,
  sortKey,
  updateSortKey,
}) => (
  <Stack spacing={4}>
    <IndexTop type={type} />
    <Stack spacing={1} px={5}>
      <SearchByUserInputs
        keywordValue={keywordValue}
        onClickSearch={onClickSearch}
        displayType="user"
      />
      <BasicTable>
        <TableHeadRow>
          <TableHeadCell
            type={sortKey?.includes('APPLICATION_ID') ? 'sort' : 'normal'}
            sort={() => {}}
            sortType={sortKey?.includes('ASC') ? 'ASC' : 'DESC'}
            width="15%"
          >
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                if (!updateSortKey) return;
                if (sortKey === 'APPLICATION_ID_ASC')
                  updateSortKey('APPLICATION_ID_DESC');
                else updateSortKey('APPLICATION_ID_ASC');
              }}
            >
              申請ID
            </Box>
          </TableHeadCell>
          <TableHeadCell
            type={sortKey?.includes('MEMBER_CD') ? 'sort' : 'normal'}
            sort={() => {}}
            sortType={sortKey?.includes('ASC') ? 'ASC' : 'DESC'}
            width="12%"
          >
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                if (!updateSortKey) return;
                if (sortKey === 'MEMBER_CD_ASC')
                  updateSortKey('MEMBER_CD_DESC');
                else updateSortKey('MEMBER_CD_ASC');
              }}
            >
              会員コード
            </Box>
          </TableHeadCell>
          <TableHeadCell
            type={sortKey?.includes('EMPLOYEE_CD') ? 'sort' : 'normal'}
            sort={() => {}}
            sortType={sortKey?.includes('ASC') ? 'ASC' : 'DESC'}
            width="12%"
          >
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                if (!updateSortKey) return;
                if (sortKey === 'EMPLOYEE_CD_ASC')
                  updateSortKey('EMPLOYEE_CD_DESC');
                else updateSortKey('EMPLOYEE_CD_ASC');
              }}
            >
              社員コード
            </Box>
          </TableHeadCell>
          <TableHeadCell
            type={sortKey?.includes('MEMBER_NAME') ? 'sort' : 'normal'}
            sort={() => {}}
            sortType={sortKey?.includes('ASC') ? 'ASC' : 'DESC'}
            width="41%"
          >
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                if (!updateSortKey) return;
                if (sortKey === 'MEMBER_NAME_ASC')
                  updateSortKey('MEMBER_NAME_DESC');
                else updateSortKey('MEMBER_NAME_ASC');
              }}
            >
              会員名
            </Box>
          </TableHeadCell>
          <TableHeadCell
            type={sortKey?.includes('APPLICATION_DATE') ? 'sort' : 'normal'}
            sort={() => {}}
            sortType={sortKey?.includes('ASC') ? 'ASC' : 'DESC'}
            width="13%"
          >
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                if (!updateSortKey) return;
                if (sortKey === 'APPLICATION_DATE_ASC')
                  updateSortKey('APPLICATION_DATE_DESC');
                else updateSortKey('APPLICATION_DATE_ASC');
              }}
            >
              申請日時
            </Box>
          </TableHeadCell>
          <TableHeadCell
            type={sortKey?.includes('APPLICATION_STATUS') ? 'sort' : 'normal'}
            sort={() => {}}
            sortType={sortKey?.includes('ASC') ? 'ASC' : 'DESC'}
            width="7%"
          >
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                if (!updateSortKey) return;
                if (sortKey === 'APPLICATION_STATUS_ASC')
                  updateSortKey('APPLICATION_STATUS_DESC');
                else updateSortKey('APPLICATION_STATUS_ASC');
              }}
            >
              状況
            </Box>
          </TableHeadCell>
        </TableHeadRow>
        {data.map((d) => (
          <TableBodyRow
            key={d.applicationId}
            url={`/applications/user/${d.applicationId}`}
          >
            <TableBodyCell value={d.applicationId} />
            <TableBodyCell value={d.memberCd} />
            <TableBodyCell value={d.employeeCd} />
            <TableBodyCell value={d.memberName} />
            <TableBodyCell
              value={format(
                parseISO(d.applicationDate),
                'yyyy/MM/dd (E) HH:mm',
                {
                  locale: ja,
                }
              )}
            />
            <TableBodyCell type="badge">
              <StatusBadge
                status={d.applicationStatus}
                sx={{ height: '22px', maxWidth: '48px' }}
              />
            </TableBodyCell>
          </TableBodyRow>
        ))}
      </BasicTable>
    </Stack>
  </Stack>
);
