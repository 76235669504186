import { SelectedAssociationState } from '@/recoil/associations/associations';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import useSWR from 'swr';
import { appClient } from '..';

export const useGetAssociation = () => {
  const { associationCd = '' } = useRecoilValue(SelectedAssociationState);
  const { data, error, mutate } = useSWR(
    associationCd !== '' ? '/api/associations/{associationCd}' : null,
    () => appClient.associations.getAssociation(associationCd)
  );

  useEffect(() => {
    void mutate();
  }, [associationCd]);

  const mutateGetAssociation = () => {
    void mutate();
  };

  return { data: data ?? {}, error, mutateGetAssociation };
};
