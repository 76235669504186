import { LoginSettingComplete } from '@/components/organisms/Login/LoginSettingComplete';
import { Path } from '@/constants/Router/path';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginState } from './PasswordSetting';

export const PasswordSettingComplete = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LoginState;
  const handleClickButton = () => {
    navigate(Path.LOGIN_ADMIN, { state });
  };
  return <LoginSettingComplete handleClickButton={handleClickButton} />;
};
