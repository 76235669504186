/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Box, SxProps, Table, Typography } from '@mui/material';
import { FC } from 'react';
import { FindTradereportsByID } from '@/types/api/tradereports';
import { TableHeadRow } from '@/components/atoms/Table/TableHeadRow';
import { TableBodyRow } from '@/components/atoms/Table/TableBodyRow';
import { TableHeadCell } from '@/components/atoms/Table/TableHeadCell';
import { TableBodyCell } from '@/components/atoms/Table/TableBodyCell';
import { theme } from '@/theme/theme';
import { formatDate, formatStrToDate } from '@/utils/dateFunction';
import { format } from 'date-fns';
import { TableContainer } from './TableContainer';

interface Props {
  report: Required<FindTradereportsByID>;
  type: 'dividend' | 'unit';
}

const HeadCellSx: SxProps = {
  backgroundColor: 'secondary.bg',
  color: 'secondary.text',
  padding: 0,
  width: '16.6%',
  borderRightWidth: 1,
  borderRightStyle: 'solid',
  borderRightColor: theme.palette.system.separator,
};

const HeadCellContentContainerSx: SxProps = {
  justifyContent: 'center',
};

const BodyCellSx: SxProps = {
  borderRightWidth: 1,
  borderRightStyle: 'solid',
  borderRightColor: theme.palette.system.separator,
  paddingRight: '4px',
};

const LastCell: SxProps = {
  borderRightWidth: 0,
};

const BodyCellContentContainerSx: SxProps = {
  justifyContent: 'flex-end',
};

type MonthlyBonusContract = Props['report']['monthlyBonus']['contract'];
type DividendContract = Props['report']['dividend']['contract'];

export const Execution: FC<Props> = ({ report, type }) => {
  const contracts: MonthlyBonusContract | DividendContract | undefined =
    type === 'dividend'
      ? report.dividend.contract
      : report.monthlyBonus.contract;

  if (typeof contracts === 'undefined') return null;

  let totalContractSharesNum = 0;
  let totalDeliveryAmount = 0;
  let totalCommission = 0;
  let totalConsumptionTax = 0;

  const renderTableHeadText = (text: string) => (
    <Typography variant="body-sub/regular" sx={{ color: 'secondary.text' }}>
      {text}
    </Typography>
  );

  const renderTableBodyText = (text: string, symbol?: string) => (
    <Typography
      sx={{
        color: 'system.text-normal',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '100%',
      }}
    >
      {text}
      {symbol && (
        <Typography
          component="span"
          sx={{
            marginLeft: '8px',
            fontWeight: 'bold',
          }}
        >
          {symbol}
        </Typography>
      )}
    </Typography>
  );

  /**
   *
   * @param text content of TableBodyCell
   * @param containerSx TableBodyCell SxProps
   * @param contentContainerSx Box container of TableBodyCell's content
   * @returns
   */
  const renderTableBodyCell = (
    text?: string,
    containerSx?: SxProps,
    contentContainerSx?: SxProps,
    symbol?: string
  ) => (
    <TableBodyCell
      sxProps={{
        ...BodyCellSx,
        ...containerSx,
      }}
      sxContentContainer={{
        ...BodyCellContentContainerSx,
        ...contentContainerSx,
      }}
    >
      {renderTableBodyText(text?.toLocaleString() || '', symbol)}
    </TableBodyCell>
  );

  const HeadCellTexts = [
    '購入月日',
    '購入株数',
    '購入単価',
    '手数料',
    '消費税',
    '購入代金 (手数料含)',
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '8px',
      }}
    >
      <Typography variant="h5" sx={{ color: 'system.text-normal' }}>
        約定情報
      </Typography>
      <TableContainer width="100%">
        <Table>
          <TableHeadRow>
            {HeadCellTexts.map((headText, index) => (
              <TableHeadCell
                sxProps={{
                  ...HeadCellSx,
                  borderRightWidth: index === HeadCellTexts.length - 1 ? 0 : 1,
                }}
                sxContentContainer={HeadCellContentContainerSx}
              >
                {renderTableHeadText(headText)}
              </TableHeadCell>
            ))}
          </TableHeadRow>
          {contracts?.length > 0 &&
            contracts?.map((item) => {
              const {
                contractYmd,
                contractSharesNum,
                contractUnitPrice,
                commission,
                consumptionTax,
                deliveryAmount,
              } = item;

              const date = contractYmd
                ? formatDate(format(formatStrToDate(contractYmd), 'yyyy-MM-dd'))
                : '';

              // Recalculate totalAmounts
              totalContractSharesNum += contractSharesNum || 0;
              totalDeliveryAmount += deliveryAmount || 0;
              totalCommission += commission || 0;
              totalConsumptionTax += consumptionTax || 0;
              /**
               * contactYmd
               * contractSharesNum
               * contractUnitPrice
               * comission
               * consumptionTax
               * contractAmount
               */
              return (
                <TableBodyRow>
                  {renderTableBodyCell(
                    date,
                    { justifyContent: 'center' },
                    { justifyContent: 'flex-end', paddingRight: '12px' }
                  )}
                  {renderTableBodyCell(
                    `${contractSharesNum?.toLocaleString() || ''}株`
                  )}
                  {renderTableBodyCell(
                    `${contractUnitPrice?.toLocaleString()}円`
                  )}
                  {renderTableBodyCell(`${commission?.toLocaleString()}円`)}
                  {renderTableBodyCell(`${consumptionTax?.toLocaleString()}円`)}
                  {renderTableBodyCell(
                    `${deliveryAmount?.toLocaleString()}円`,
                    { borderRightWidth: 0 }
                  )}
                </TableBodyRow>
              );
            })}
          {contracts?.length > 0 && (
            <TableBodyRow>
              {renderTableBodyCell(
                '合計',
                { justifyContent: 'center' },
                { justifyContent: 'flex-end', paddingRight: '12px' }
              )}
              {renderTableBodyCell(
                `${totalContractSharesNum.toLocaleString()}株`
              )}
              {renderTableBodyCell('-')}
              {renderTableBodyCell(`${totalCommission.toLocaleString()}円`)}
              {renderTableBodyCell(`${totalConsumptionTax.toLocaleString()}円`)}
              {renderTableBodyCell(
                `${totalDeliveryAmount.toLocaleString()}円`,
                { borderRightWidth: 0 },
                undefined,
                '(J)'
              )}
            </TableBodyRow>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};
