/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { theme } from '@/theme';
import { PrevnextApplications } from '@/types/api/Applications/prevnextApplications';
import {
  FindMemberContribution,
  MemberContribution,
} from '@/types/api/memberContribution';
import { FindApplicationByID } from '@/types/api/Applications/application';
import { MemberById } from '@/types/api/member';
import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { GetAssociationsContribution } from '@/types/api/associations';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { printApplicationsComponent } from '@/utils/printApplications';
import { AssociationsContribution } from '@/types/api/Association/associationsContribution';
import { Calculations } from '@/utils/calculation';
import { TitleHead } from './SharedParts/TitleHead';
import { UserInfoTable } from './SharedParts/UserInfoTable';
import { ApplicationBottom } from './SharedParts/ApplicationBottom';
import { ApplicationTable as Table } from './SharedParts/ApplicationTable';
import { ApplicationTableRow as Row } from './SharedParts/ApplicationTableRow';
import { ApplicationButtons } from './SharedParts/ApplicationButtons';
import { PrintApplicationsButton } from './PrintApplicationsButton/PrintApplicationsButton';

interface UnitProps {
  application: FindApplicationByID;
  unitType: string;
  userData: MemberById;
  bottomData: PrevnextApplications | undefined;
  associationsContribution: GetAssociationsContribution;
  contributionData: FindMemberContribution;
  handleApplication: (value: 'APPROVAL' | 'REJECTED', comment?: string) => void;
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
  applicationType: string;
}

export const ApplicationsUnit: FC<UnitProps> = ({
  application,
  unitType,
  userData,
  bottomData,
  associationsContribution,
  contributionData,
  handleApplication,
  setIsOpen,
  isOpen,
  applicationType,
}) => {
  const kanmaChange = (value: number | string) => {
    let val = value;
    if (typeof value === 'number') {
      val = String(val);
    }
    if (typeof val === 'string') {
      return val.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    }
    return value;
  };

  const renderRowSubRow = (label: string, value: string, isRate?: boolean) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <Typography sx={{ width: '75px' }} variant="body-sub/regular">
        {label}
      </Typography>
      <Typography
        sx={{ marginLeft: '8px', marginRight: '16px' }}
        variant="body-sub/regular"
      >
        {value}
      </Typography>
    </Box>
  );

  const renderBonusCalculations = () => (
    <>
      {renderRowSubRow(
        '拠出金額',
        `${(
          (associationsContribution.unitAmount || 0) *
          (Number(application?.applicationMonthlyUnit?.bonusContributionUnit) ||
            0)
        ).toLocaleString()}円`
      )}
      {renderRowSubRow(
        '奨励金額',
        `${(
          ((associationsContribution.unitAmount || 0) *
            (Number(
              application?.applicationMonthlyUnit?.bonusContributionUnit
            ) || 0) *
            (associationsContribution.monthlyIncentiveRatio || 0)) /
          100
        ).toLocaleString()}円`
      )}
    </>
  );

  const renderMultiplier = (rate?: number) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <Typography sx={{ marginRight: '16px' }} variant="body-sub/regular">
        {rate || 0}倍
      </Typography>
      <Typography
        variant="body-sub/regular"
        sx={{ color: 'system.text-light' }}
      >
        ※給与（毎月の拠出）対比
      </Typography>
    </Box>
  );

  useHelmetHandler({
    title: '口数変更　申請詳細 （給与）',
  });

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.system['background-dark'],
        height: '100%',
      }}
    >
      <Stack
        spacing={4}
        sx={{
          backgroundColor: theme.palette.system.white,
          padding: '40px 160px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <PrintApplicationsButton
            onClick={() =>
              printApplicationsComponent(
                'monthly_unit_print_content',
                '口数変更'
              )
            }
          />
        </Box>
        <Stack id="monthly_unit_print_content" spacing={4}>
          <TitleHead
            title="口数変更"
            status={application.applicationStatus || ''}
            applicationDate={application.applicationDate || ''}
            applicationId={application.applicationId || ''}
            associationCd={application.associationCd || ''}
            proxyApplicantUserName={application.proxyApplicantUserName ?? ''}
            unitType={unitType}
            comment={application.rejectComment}
          />
          <UserInfoTable data={userData} />

          <Table title="現在の口数設定">
            <Row
              labels="1口あたり金額"
              values={`${kanmaChange(
                associationsContribution.unitAmount || 0
              )}円`}
            />
            <Row labels="給与" isEven>
              {renderRowSubRow(
                '口数',
                `${kanmaChange(contributionData.monthlyUnit || 0)}口`
              )}
              {renderRowSubRow(
                '拠出設定',
                `${kanmaChange(
                  (contributionData.monthlyUnit || 0) *
                    (associationsContribution.unitAmount || 0)
                )}円`
              )}
            </Row>
            <Row labels="賞与">
              {associationsContribution.bonusMultiplierDesignationType ===
                'FIXED_MAGNIFICATION' && (
                <>
                  {renderMultiplier(
                    Number(associationsContribution.bonusMultiplierDetails)
                  )}
                  {renderRowSubRow(
                    '拠出設定',
                    `${kanmaChange(
                      Calculations.bonusUnitAmount(
                        {
                          ...associationsContribution,
                        } as AssociationsContribution,
                        {
                          ...contributionData,
                        } as MemberContribution
                      ) * (associationsContribution.unitAmount || 0)
                    )}円`
                  )}
                </>
              )}
              {associationsContribution.bonusMultiplierDesignationType ===
                'MAGNIFICATION' &&
                contributionData.bonusUnit !== 0 && (
                  <>
                    {renderMultiplier(Number(contributionData.bonusRate))}
                    {renderRowSubRow(
                      '拠出設定',
                      `${kanmaChange(
                        (contributionData.bonusUnit || 0) *
                          (associationsContribution.unitAmount || 0)
                      )}円`
                    )}
                  </>
                )}
              {associationsContribution.bonusMultiplierDesignationType ===
                'UNIT_NUMBER' &&
                contributionData.bonusUnit !== 0 && (
                  <>
                    {renderRowSubRow(
                      '口数',
                      `${kanmaChange(contributionData.bonusUnit || 0)}口`
                    )}
                    {renderRowSubRow(
                      '拠出設定',
                      `${kanmaChange(
                        (contributionData.bonusUnit || 0) *
                          (associationsContribution.unitAmount || 0)
                      )}円`
                    )}
                  </>
                )}
            </Row>
          </Table>
          <Table title="新しい口数設定" isRed>
            <Row
              labels="1口あたり金額"
              values={`${kanmaChange(
                associationsContribution.unitAmount || 0
              )}円`}
            />
            <Row
              labels="奨励金率"
              values={`${kanmaChange(
                associationsContribution.monthlyIncentiveRatio || 0
              )}%`}
              isEven
            />
            <>
              <Row labels="給与">
                {renderRowSubRow(
                  '口数',
                  `${kanmaChange(
                    application?.applicationMonthlyUnit?.monthlyUnit || 0
                  )}口`
                )}
                {renderRowSubRow(
                  '拠出設定',
                  `${kanmaChange(
                    (associationsContribution.unitAmount || 0) *
                      (application?.applicationMonthlyUnit?.monthlyUnit || 0)
                  )}円`
                )}
                {renderRowSubRow(
                  '奨励金額',
                  `${kanmaChange(
                    ((associationsContribution.unitAmount || 0) *
                      (application?.applicationMonthlyUnit?.monthlyUnit || 0) *
                      (associationsContribution.monthlyIncentiveRatio || 0)) /
                      100
                  )}円`
                )}
              </Row>
              {associationsContribution.bonusReserveClassification !== 'NONE' &&
                (application.applicationMonthlyUnit?.isBonusUnit ? (
                  <Row labels="賞与" isEven>
                    {associationsContribution?.bonusMultiplierDesignationType ===
                      'FIXED_MAGNIFICATION' &&
                      renderMultiplier(
                        Number(associationsContribution?.bonusMultiplierDetails)
                      )}
                    {associationsContribution?.bonusMultiplierDesignationType ===
                      'MAGNIFICATION' &&
                      renderMultiplier(
                        application.applicationMonthlyUnit?.bonusRate
                      )}
                    {associationsContribution?.bonusMultiplierDesignationType ===
                      'UNIT_NUMBER' &&
                      renderRowSubRow(
                        '口数',
                        `${kanmaChange(
                          application.applicationMonthlyUnit?.bonusContributionUnit?.toLocaleString() ||
                            0
                        )}口`
                      )}
                    {renderBonusCalculations()}
                  </Row>
                ) : (
                  <Row labels="賞与" isEven>
                    {renderRowSubRow('拠出しない', '')}
                  </Row>
                ))}
            </>
          </Table>
        </Stack>
        {application.applicationStatus !== 'COMPLETED' && application.eventNo && (
          <Stack direction="row" sx={{ alignItems: 'center' }} spacing={2}>
            <ApplicationButtons
              status={application.applicationStatus || ''}
              handleApplication={handleApplication}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
            {(application.applicationStatus === 'PREPARATION' ||
              application.applicationStatus === 'APPLYING') && (
              <NormalLink
                href={
                  application.applicationId
                    ? `/applications/${unitType}/${application.applicationId}/unit/edit`
                    : `/applications/${unitType}`
                }
                applicationType={applicationType}
              >
                申請内容を修正する
              </NormalLink>
            )}
          </Stack>
        )}
      </Stack>
      {bottomData && (
        <Box sx={{ padding: '32px 160px' }}>
          <ApplicationBottom data={bottomData} unitType={applicationType} />
        </Box>
      )}
    </Box>
  );
};
