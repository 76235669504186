import { AssociationOrganism } from '@/components/organisms/Association/AssociationOrganism';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { appClient } from '@/services';
import {
  AssociationDomainService,
  AssociationOrganismProps,
} from '@/services/associationDomainService';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import useSWR from 'swr';

export const AssociationIndex = () => {
  const [association, setAssociation] = useState<AssociationOrganismProps>(
    {} as AssociationOrganismProps
  );
  useHelmetHandler({
    title: '持株会詳細',
  });

  const { associationCd = '' } = useRecoilValue(SelectedAssociationState);
  const { data: resAssociation, mutate: mutateAssociation } = useSWR(
    associationCd ? '/api/associations/{associationCd}' : null,
    () => appClient.associations.getAssociation(associationCd)
  );
  const { data: resAssociationsAccount, mutate: mutateAssociationAccount } =
    useSWR(
      associationCd ? '/api/associations/{associationCd}/account' : null,
      () => appClient.associations.findAssociationsAccount(associationCd)
    );
  const { data: resAssociationsContact, mutate: mutateAssociationContact } =
    useSWR(
      associationCd ? '/api/associations/{associationCd}/contacts' : null,
      () => appClient.associations.getAssociationsContact(associationCd)
    );
  const {
    data: resAssociationsContribution,
    mutate: mutateAssociationContribution,
  } = useSWR(
    associationCd ? '/api/associations/{associationCd}/contributions' : null,
    () => appClient.associations.getAssociationsContribution(associationCd)
  );

  useEffect(() => {
    void mutateAssociation();
  }, [associationCd, mutateAssociation]);
  useEffect(() => {
    void mutateAssociationAccount();
  }, [associationCd, mutateAssociationAccount]);
  useEffect(() => {
    void mutateAssociationContact();
  }, [associationCd, mutateAssociationContact]);
  useEffect(() => {
    void mutateAssociationContribution();
  }, [associationCd, mutateAssociationContribution]);
  useEffect(() => {
    if (
      [
        resAssociation,
        resAssociationsAccount,
        resAssociationsContact,
        resAssociationsContribution,
      ].every((data) => data)
    ) {
      setAssociation(
        AssociationDomainService.formatData({
          ...resAssociation,
          ...resAssociationsAccount,
          ...resAssociationsContact,
          ...resAssociationsContribution,
        })
      );
    }
  }, [
    resAssociation,
    resAssociationsAccount,
    resAssociationsContact,
    resAssociationsContribution,
  ]);

  return <AssociationOrganism {...association} />;
};
