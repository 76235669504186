/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ApplicationsWithdrawalEdit } from '@/components/organisms/ApplicationsEdits/WithdrawalEdit';
import { FC, useState, useEffect } from 'react';
import {
  useMatch,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { WithdrawalEditInputs as Inputs } from '@/types/components/Inputs';
import { MemberById } from '@/types/api/member';
import { GetAssociation } from '@/types/api/associations';
import { FindApplicationByID } from '@/types/api/Applications/application';
import { ErrorResponse } from '@/types/api/error';
import { usePrevnextApplication } from '@/services/customHooks/usePrevnextApplication';
import { PrevnextApplicationParams } from '@/types/api/Applications/prevnextApplications';
import { appClient } from '@/services';
import { useSnackbar } from '@/hooks/useSnackbar';

export const WithdrawalEdit: FC = () => {
  const match = useMatch(
    '/applications/:unitType/:applicationId/withdrawal/edit'
  );
  const unitType = match?.params.unitType || '';
  const navigate = useNavigate();
  const applicationId = Number(useParams().applicationId) || 0;
  const [applicationData, setApplicationData] = useState<FindApplicationByID>();
  const [userData, setUserData] = useState<MemberById>();
  const [associationData, setAssociationData] = useState<GetAssociation>();
  const [searchParams] = useSearchParams();

  const [query] = useState<PrevnextApplicationParams>(
    searchParams as PrevnextApplicationParams
  );
  const { data: bottomData } = usePrevnextApplication(applicationId, query);
  const { showSnackbar } = useSnackbar();
  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    try {
      const otherReasons = data.reason === 'OTHER' ? data.otherReasons : '';
      await appClient.applications.updateApplication(applicationId, {
        applicationId,
        applicationWithdrawal: {
          otherReasons: data.reason === 'OTHER' ? otherReasons : '',
          reason: data.reason || '',
          generalAccount: {
            institutionCode: data.generalAccount.institutionCode,
            institutionName: data.generalAccount.institutionName,
            branchCode: data.generalAccount.branchCode,
            branchName: data.generalAccount.branchName,
            accountType: data.generalAccount.accountType,
            accountNumber: data.generalAccount.accountNumber,
          },
          isAdditionalOption:
            applicationData?.applicationWithdrawal?.isAdditionalOption,
          transferAmount:
            applicationData?.applicationWithdrawal?.transferAmount,
          remarks: applicationData?.applicationWithdrawal?.remarks,
          institutionType: data.institutionType,
          accountYuchoSymbol: data.accountYuchoSymbol,
          accountYuchoNumber: data.accountYuchoNumber,
        },
      });
      if (applicationId)
        navigate(`/applications/${unitType}/${applicationId}/withdrawal`);
    } catch (e) {
      // エラー処理
      showSnackbar((e as ErrorResponse).data.message);
    }
  };

  const getApplicationData = async () => {
    try {
      const res = await appClient.applications.findApplicationById(
        applicationId
      );
      setApplicationData(res);
    } catch (e) {
      console.log(e);
    }
  };

  const getUserData = async () => {
    try {
      if (applicationData?.memberId && applicationData.memberId !== 0) {
        const res = await appClient.members.findMember(
          applicationData?.memberId
        );
        setUserData(res);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const GetAssociationData = async () => {
    try {
      if (applicationData?.associationCd) {
        const res = await appClient.associations.getAssociation(
          applicationData?.associationCd
        );
        setAssociationData(res);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    void getApplicationData();
  }, [applicationId]);

  useEffect(() => {
    void getUserData();
    void GetAssociationData();
  }, [applicationData]);

  if (applicationData && userData && associationData) {
    return (
      <ApplicationsWithdrawalEdit
        application={applicationData}
        associationData={associationData}
        unitType={unitType}
        userData={userData}
        bottomData={bottomData}
        onSubmit={onSubmit}
      />
    );
  }
  return null;
};
