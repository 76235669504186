/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OfficeUsersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * 【API-B-2101】事務局担当者詳細を取得する
   * 事務局担当者の詳細を取得する
   *
   * @param officeUserId 事務局担当者ID
   *
   * @returns any seidogroup info response
   * @throws ApiError
   */
  public officeUserDetail(officeUserId: number): CancelablePromise<{
    /**
     * 事務局担当者ID
     */
    officeUserId?: number;
    /**
     * 氏名漢字(姓)
     */
    lastNameKanji?: string;
    /**
     * 氏名漢字(名)
     */
    firstNameKanji?: string;
    /**
     * メールアドレス(ログインID)
     */
    email?: string;
    officeUserAssociations?: Array<{
      /**
       * 持株会コード
       */
      associationCd?: string;
      /**
       * 持株会名
       */
      associationName?: string;
      /**
       * 事務局担当者権限区分
       * - GENERAL : 一般
       * - ADMINISTRATOR : 管理者
       *
       */
      authorityClassification?: 'GENERAL' | 'ADMINISTRATOR';
      /**
       * 代理申請区分
       * - IMPOSSIBLE: 不可
       * - POSSIBLE: 可能
       *
       */
      proxyApplicationClassification?: 'IMPOSSIBLE' | 'POSSIBLE';
    }>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/officeusers',
      query: {
        officeUserId: officeUserId,
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-2102】事務局担当者を登録する
   * 事務局担当者を登録する
   * @param requestBody request_office_users
   * @returns any send email for officeUser
   * @throws ApiError
   */
  public createOfficeUsers(requestBody: {
    /**
     * 持株会コード
     */
    associationCd: string;
    /**
     * 氏名_名
     */
    lastNameKanji: string;
    /**
     * 氏名_姓
     */
    firstNameKanji: string;
    /**
     * メールアドレス
     */
    mail: string;
    /**
     * 事務局担当者権限区分
     * - GENERAL : 一般
     * - ADMINISTRATOR : 管理者
     *
     */
    authorityClassification: 'GENERAL' | 'ADMINISTRATOR';
    /**
     * 代理申請区分
     * - IMPOSSIBLE: 不可
     * - POSSIBLE: 可能
     *
     */
    proxyApplicationClassification: 'IMPOSSIBLE' | 'POSSIBLE';
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/officeusers',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-2103】事務局担当者の情報を変更する
   * 事務局担当者の情報を変更する
   * @param requestBody usersreports DL
   * @returns any Office users change
   * @throws ApiError
   */
  public updateOfficeUsers(requestBody: {
    /**
     * 氏名_名
     */
    lastNameKanji?: string;
    /**
     * 氏名_姓
     */
    firstNameKanji?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/officeusers',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-2104】事務局担当者の情報を削除する
   * 事務局担当者の情報を削除する
   *
   * @param officeUserId OfficeID of info to post
   * @returns any info response
   * @throws ApiError
   */
  public deleteOfficeUsers(officeUserId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/officeusers/{officeUserId}',
      path: {
        officeUserId: officeUserId,
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-2110】事務局担当者パスワード変更
   * 事務局担当者のパスワードを変更する
   * @param requestBody reset password request
   * @returns any send email for officeUser
   * @throws ApiError
   */
  public resetOfficeUserPassword(requestBody: {
    /**
     * パスワード
     */
    password?: string;
    /**
     * 新しいパスワード
     */
    newPassword?: string;
  }): CancelablePromise<{
    AuthenticationResult?: {
      /**
       * トークンタイプ
       */
      TokenType?: string;
      /**
       * IDトークン
       */
      IdToken?: string;
      /**
       * アクセストークン
       */
      AccessToken?: string;
      /**
       * リフレッシュトークン
       */
      RefreshToken?: string;
      /**
       * 期限
       */
      ExpiresIn?: number;
    };
    /**
     * チャレンジ名
     */
    ChallengeName?: string;
    /**
     * セッション
     */
    Session?: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/officeusers/password/reset',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }
}
