import { MemberAssets } from '@/types/api/memberAssets';
import { MemberBalancereports } from '@/types/api/memberBalanceReports';
import { atom } from 'recoil';

export const MemberAssetsState = atom({
  key: 'member_assets_key',
  default: {} as MemberAssets,
});

export const MemberBalanceReportsState = atom({
  key: 'member_reports_state',
  default: {} as MemberBalancereports,
});
