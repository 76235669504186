/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { FC } from 'react';
import { theme } from '@/theme/theme';
import { MemberAssets } from '@/types/api/memberAssets';
import { formatDateTime } from '@/utils/dateFunction';
import { numberFormat } from '@/utils/numberFormat';
import { Box, Typography, styled } from '@mui/material';
import { BalanceInquiryHeading } from './BalanceInquiryHeading';
import { GeneralInfoPart } from './GeneralInfoPart';

const Wrapper = styled(Box)(() => {
  const { palette } = theme;

  return {
    backgroundColor: palette.system.background,
    padding: 24,
    borderRadius: 4,
    overflow: 'hidden',
  };
});

const InfoWrapper = styled(Box)(() => {
  const { breakpoints } = theme;

  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: 1104,
    margin: '0 auto',

    [breakpoints.down('sm')]: {
      maxWidth: '100%',
      padding: '0 16px',
      flexDirection: 'column',
    },

    '@media print': {
      flexDirection: 'row',
      padding: 0,
      maxWidth: 'none',
    },
  };
});

const InfoBox = styled(Box)(() => {
  const { breakpoints, palette } = theme;

  return {
    borderRadius: 12,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.system.separator,
    display: 'flex',
    flexDirection: 'row',
    width: 1104,
    margin: '16px auto 0',
    backgroundColor: palette.system.white,
    overflow: 'hidden',

    [breakpoints.down('sm')]: {
      maxWidth: 'calc(100% - 32px)',
      flexDirection: 'column',
      margin: '8px 16px 0',
    },

    '@media print': {
      flexDirection: 'row',
      maxWidth: 'none%',
      margin: '16px auto 0',
    },
  };
});

const Subtitle = styled(Typography)(() => {
  const { breakpoints, typography, palette } = theme;

  return {
    ...typography['body-main/regular'],
    fontSize: 14,
    color: palette.system['text-light'],

    [breakpoints.down('sm')]: {
      marginTop: 8,
    },

    '@media print': {
      marginTop: 0,
    },
  };
});

interface GeneralInfoProps {
  memberAssets: MemberAssets;
  date: Date;
  investmentDigit?: number;
}

export const GeneralInfo: FC<GeneralInfoProps> = ({
  memberAssets,
  date,
  investmentDigit,
}) => {
  const getTargetDate = () => {
    if (memberAssets.targetDate) {
      return (
        memberAssets.targetDate.replace('-', '').slice(0, 4) +
        '年' +
        Number(memberAssets.targetDate.replace('-', '').slice(4, 6)) +
        '月'
      );
    }
    const today = new Date();
    if (today.getMonth() !== 0) {
      return today.getFullYear() + '年' + today.getMonth() + '月';
    }
    return today.getFullYear() - 1 + '年12月';
  };

  const kanmaChange = (value: string) => {
    let kanma;
    if (value.includes('.')) {
      const valueArr = value.split('.');
      const kanmaInt = valueArr[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      const cutDigit = valueArr[1].substring(0, investmentDigit);
      // eslint-disable-next-line prefer-template
      kanma = kanmaInt + '.' + cutDigit;
    } else {
      kanma = value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    }
    return kanma;
  };
  return (
    <Wrapper>
      <InfoWrapper>
        <BalanceInquiryHeading
          title="ご投資状況"
          subtitle={`${formatDateTime(date.toString())} 現在`}
        />
      </InfoWrapper>
      <InfoBox>
        <GeneralInfoPart
          isHeader
          title="現在の株数"
          description={
            memberAssets.stocksNumber
              ? kanmaChange(String(memberAssets.stocksNumber))
              : '0'
          }
          descriptionSymbol="株"
          onClick={() => {}}
        />
        <GeneralInfoPart
          isHeader={false}
          title="時価評価額※"
          description={
            memberAssets.marketValue
              ? numberFormat(Math.floor(memberAssets.marketValue))
              : '0'
          }
          descriptionSymbol="円"
          onClick={() => {}}
          tooltip={{
            title: '時価評価額',
            subtitle: '現在株数×株価で算出されます。',
          }}
        />
        <GeneralInfoPart
          isHeader={false}
          title="取得金額"
          description={
            memberAssets.acquisitionAmount
              ? numberFormat(memberAssets.acquisitionAmount)
              : '0'
          }
          descriptionSymbol="円"
          onClick={() => {}}
          tooltip={{
            title: '取得金額',
            subtitle: '現在株数×平均取得単価で算出されます。',
          }}
        />
        <GeneralInfoPart
          isHeader={false}
          title="評価損益"
          description={
            memberAssets.valuationGainLoss
              ? Math.sign(memberAssets.valuationGainLoss) === -1
                ? `${numberFormat(memberAssets.valuationGainLoss)}`
                : `+${numberFormat(memberAssets.valuationGainLoss)}`
              : '0'
          }
          descriptionSymbol="円"
          color={theme.palette.states.main}
          onClick={() => {}}
          tooltip={{
            title: '評価損益',
            subtitle:
              '時価評価額(現在株数×株価)ー取得金額(現在株数×平均取得単価)で算出されます。',
          }}
        />
      </InfoBox>
      <InfoWrapper sx={{ marginTop: 2 }}>
        <Subtitle>
          ※時価評価額は{getTargetDate()}の終値、{' '}
          {memberAssets.complementaryClosingPrice
            ? Math.floor(
                memberAssets.complementaryClosingPrice
              ).toLocaleString()
            : 0}
          円で計算しています
        </Subtitle>
      </InfoWrapper>
    </Wrapper>
  );
};
