import { Outlet } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { ApplicationsMonthlyProvider } from './Monthly';
import { ApplicationsBonusProvider } from './Bonus';
import { ApplicationsDividendProvider } from './Dividend';
import { ApplicationsTemporaryProvider } from './Temporary';
import { ApplicationsAdmissionProvider } from './Admission';
import { ApplicationsUserProvider } from './User';

export const Applications = () => {
  const [selectedAssociation] = useRecoilState(SelectedAssociationState);
  const { associationCd = '' } = selectedAssociation;

  return associationCd !== '' ? (
    <ApplicationsMonthlyProvider associationCd={associationCd}>
      <ApplicationsBonusProvider associationCd={associationCd}>
        <ApplicationsDividendProvider associationCd={associationCd}>
          <ApplicationsTemporaryProvider associationCd={associationCd}>
            <ApplicationsAdmissionProvider associationCd={associationCd}>
              <ApplicationsUserProvider associationCd={associationCd}>
                <Outlet />
              </ApplicationsUserProvider>
            </ApplicationsAdmissionProvider>
          </ApplicationsTemporaryProvider>
        </ApplicationsDividendProvider>
      </ApplicationsBonusProvider>
    </ApplicationsMonthlyProvider>
  ) : null;
};
