/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OfficeUserAssociationsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * 【API-B-2105】事務局担当持株会の一覧を取得する
   * 事務局担当持株会の一覧を取得する
   * 除外する持株会コード（excludeAssociationCd）、取得したい持株会コード（includeAssociationCd）のどちらかを入れてください。
   *
   * @param includeAssociationCd 取得持株会コード
   *
   * @param excludeAssociationCd 除外持株会コード
   *
   * @param sortKey ソートキー
   * - OFFICE_USER_NAME_ASC: 事務局担当者名昇順
   * - OFFICE_USER_NAME_DESC: 事務局担当者名降順
   * - AUTORITY_CALASSIFICATION_ASC: 権限昇順
   * - AUTORITY_CALASSIFICATION_DESC: 権限降順
   * - PROXY_APPLICATION_CLASSIFICATION_ASC: 代理申請権限昇順
   * - PROXY_APPLICATION_CLASSIFICATION_DESC: 代理申請権限降順
   * - EMAIL_ASC: EMAIL昇順
   * - EMAIL_DESC: EMAIL降順
   *
   * @returns any officeUserUser info response
   * @throws ApiError
   */
  public listOfficeUserAssociations(
    includeAssociationCd?: string,
    excludeAssociationCd?: string,
    sortKey?:
      | 'OFFICE_USER_NAME_ASC'
      | 'OFFICE_USER_NAME_DESC'
      | 'AUTORITY_CALASSIFICATION_ASC'
      | 'AUTORITY_CALASSIFICATION_DESC'
      | 'PROXY_APPLICATION_CLASSIFICATION_ASC'
      | 'PROXY_APPLICATION_CLASSIFICATION_DESC'
      | 'EMAIL_ASC'
      | 'EMAIL_DESC'
  ): CancelablePromise<
    Array<{
      /**
       * 事務局担当者ID
       */
      officeUserId?: number;
      /**
       * 事務局担当者名_姓
       */
      officeUserLastName?: string;
      /**
       * 事務局担当者名_名
       */
      officeUserFirstName?: string;
      /**
       * email
       */
      email?: string;
      /**
       * 持株会CD
       */
      associationCd?: string;
      /**
       * 持株会名
       */
      associationName?: string;
      /**
       * 事務局担当者権限区分
       * - GENERAL : 一般
       * - ADMINISTRATOR : 管理者
       *
       */
      authorityClassification?: 'GENERAL' | 'ADMINISTRATOR';
      /**
       * 代理申請区分
       * - IMPOSSIBLE: 不可
       * - POSSIBLE: 可能
       *
       */
      proxyApplicationClassification?: 'IMPOSSIBLE' | 'POSSIBLE';
    }>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/officeuser/associations',
      query: {
        includeAssociationCd: includeAssociationCd,
        excludeAssociationCd: excludeAssociationCd,
        sortKey: sortKey,
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * @deprecated
   * 【API-B-2109】事務局担当持株会の情報を取得する
   * 事務局担当持株会の情報を取得する（事務局担当者の設定情報を、所属している持株会単位で取得）
   *
   * @param associationCd 持株会コード
   *
   * @param officeUserId 事務局担当者Id
   *
   * @returns any officeUserUser info response
   * @throws ApiError
   */
  public findOfficeUserAssociations(
    associationCd: string,
    officeUserId: string
  ): CancelablePromise<{
    /**
     * 事務局担当者ID
     */
    officeUserId?: number;
    /**
     * 事務局担当者名_姓
     */
    officeUserLastName?: string;
    /**
     * 事務局担当者名_名
     */
    officeUserFirstName?: string;
    /**
     * email
     */
    email?: string;
    /**
     * 持株会CD
     */
    associationCd?: string;
    /**
     * 持株会名
     */
    associationName?: string;
    /**
     * 事務局担当者権限区分
     * - GENERAL : 一般
     * - ADMINISTRATOR : 管理者
     *
     */
    authorityClassification?: 'GENERAL' | 'ADMINISTRATOR';
    /**
     * 代理申請区分
     * - IMPOSSIBLE: 不可
     * - POSSIBLE: 可能
     *
     */
    proxyApplicationClassification?: 'IMPOSSIBLE' | 'POSSIBLE';
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/officeuser/associations/{associationCd}/{officeUserId}',
      path: {
        associationCd: associationCd,
        officeUserId: officeUserId,
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-2106】事務局担当持株会を登録・削除する
   * 事務局担当持株会を登録・削除
   * @param associationCd 持株会コード
   *
   * @param requestBody request_office_user_associations
   * @returns any send email for officeUser
   * @throws ApiError
   */
  public createOfficeUserAssociations(
    associationCd: string,
    requestBody: Array<{
      /**
       * 事務局担当者ID
       */
      officeUserId?: number;
      /**
       * 事務局担当者権限区分
       * - GENERAL : 一般
       * - ADMINISTRATOR : 管理者
       *
       */
      authorityClassification?: 'GENERAL' | 'ADMINISTRATOR';
      /**
       * 代理申請区分
       * - IMPOSSIBLE: 不可
       * - POSSIBLE: 可能
       *
       */
      proxyApplicationClassification?: 'IMPOSSIBLE' | 'POSSIBLE';
      /**
       * アクションタイプ
       * - CREATE: レコード追加
       * - UPDATE: レコード更新
       * - DELETE: レコード削除
       *
       */
      actionType?: 'CREATE' | 'UPDATE' | 'DELETE';
    }>
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/officeuser/associations/{associationCd}',
      path: {
        associationCd: associationCd,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }
}
