import { Tooltip as MuiTooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import type { TooltipProps } from '@mui/material/Tooltip';

const MuiStyleTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FCFBFB',
    color: '#222222',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: 8,
    fontSize: 12,
    padding: '16px',
  },
}));

interface Props {
  text: string;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  children: React.ReactElement;
}

export const Tooltip: React.FC<Props> = ({
  text,
  placement = 'top-start',
  children,
}) => (
  <MuiStyleTooltip title={text} placement={placement}>
    {children}
  </MuiStyleTooltip>
);
