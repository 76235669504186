import { Box, styled } from '@mui/material';
import { Title as CalendarTitle } from '@/components/molecules/Title/Title';
import { Calendar } from '@/components/molecules/Calendar/Calendar';
import { CalendarEvent } from '@/components/molecules/Calendar/CalendarEvent';
import { FC, useEffect, useState } from 'react';
import { useListEventsDaily } from '@/services/customHooks/useListEventsDaily';
import {
  format,
  isBefore,
  isSameDay,
  startOfMonth,
  endOfMonth,
  parse,
} from 'date-fns';
import { isObjectEmpty } from '@/utils/empty';
import { useGetAssociationsContribution } from '@/services/customHooks/useGetAssociationsContribution';
import { ja } from 'date-fns/locale';

const CalendarSection = styled(Box)({
  marginTop: 32,
});

const CalendarContent = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 16,
  marginTop: 24,
});

const CalendarWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const CalendarEventSection = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
});

const CalendarEventsWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  flexGrow: 1,
});

type BonusReserveClassification = 'PURCHASE' | 'RESERVE' | 'NONE' | undefined;

type HomeScheduleSectionProps = {
  associationCd: string;
};

export const HomeScheduleSection: FC<HomeScheduleSectionProps> = ({
  associationCd,
}) => {
  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(today);
  const [dateFrom, setDateFrom] = useState(startOfMonth(today));
  const [dateTo, setDateTo] = useState(endOfMonth(today));

  // bonusReserveClassificationを見てRESERVE：積立、PURCHASE：買付を指定するために使用
  const { data: associationsContribution } = useGetAssociationsContribution();

  const { data } = useListEventsDaily({
    associationCd,
    dateFrom: format(dateFrom, 'yyyy-MM-dd'),
    dateTo: format(dateTo, 'yyyy-MM-dd'),
  });

  const [dataList, setDataList] = useState();

  useEffect(() => {
    if (data && !isObjectEmpty(data)) {
      // @ts-ignore
      setDataList(data);
    }
  }, [data !== dataList]);

  return (
    <CalendarSection>
      <CalendarTitle title="スケジュール" />
      <CalendarContent>
        <CalendarWrapper>
          <Calendar
            value={currentMonth}
            initEventDates={
              data
                ? data.date.map((item) => {
                    const { displayDate, events } = item;
                    if (events.length === 0) {
                      return 0;
                    }
                    const parseDisplayDate = parse(
                      displayDate,
                      'yyyy-MM-dd',
                      new Date()
                    );
                    return parseDisplayDate.getTime();
                  })
                : []
            }
            setSelectedDate={(date) => {
              setCurrentMonth(date);
              setDateFrom(startOfMonth(date));
              setDateTo(endOfMonth(new Date(date)));
            }}
          />
        </CalendarWrapper>
        <CalendarEventSection>
          <CalendarEventsWrapper>
            {data &&
              !isObjectEmpty(data) &&
              data.date.map((item) =>
                item.events.length === 0 ? null : (
                  <CalendarEvent
                    key={item.displayDate}
                    isEnabled={
                      isSameDay(today, new Date(item.displayDate)) ||
                      isBefore(today, new Date(item.displayDate))
                    }
                    day={Number(format(new Date(item.displayDate), 'dd'))}
                    week={format(new Date(item.displayDate), 'E', { locale: ja })}
                    description={item.events}
                    bonusReserveClassification={
                      associationsContribution?.bonusReserveClassification as BonusReserveClassification
                    }
                    onClick={() => {}}
                  />
                )
              )}
          </CalendarEventsWrapper>
        </CalendarEventSection>
      </CalendarContent>
    </CalendarSection>
  );
};
