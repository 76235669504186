import { Typography } from '@mui/material';
import { FC } from 'react';

export const SubmitStatus: FC = () => (
  <Typography
    variant="body-main/medium"
    sx={{
      color: 'states.success',
      border: '1px solid',
      borderColor: 'states.success',
      borderRadius: '4px',
      height: '27px',
      p: '1px 8px 3px',
    }}
  >
    提出済
  </Typography>
);
