import { FC } from 'react';
import { styled, Box, Typography, Stack } from '@mui/material';
import { theme } from '@/theme';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import { IndexTab } from './IndexTab';

const HeadWrapper = styled(Box)({
  width: '100%',
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  backgroundColor: theme.palette.system['background-light'],
  padding: '32px 40px',
  borderBottom: '1px solid',
  borderColor: theme.palette.system['separator-light'],
});

interface Props {
  type: string;
}

export const IndexTop: FC<Props> = ({ type }) => {
  const navigate = useNavigate();
  const today = new Date();
  const formatedToday = format(today, 'yyyy/MM/dd (E) HH:mm', {
    locale: ja,
  });
  const move = (to: string) => {
    navigate(`/applications/${to} `);
  };
  return (
    <Stack spacing={4}>
      <HeadWrapper>
        <Typography variant="h4" color={theme.palette.system['text-normal']}>
          申請
        </Typography>
        <Typography
          variant="body-main/regular"
          color={theme.palette.system['text-light']}
        >
          {formatedToday} 現在
        </Typography>
      </HeadWrapper>
      <Box px={5}>
        <IndexTab move={move} type={type} />
      </Box>
    </Stack>
  );
};
