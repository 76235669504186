/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { ApplicationsAdmission } from '@/components/organisms/Applications/Admission';
import { FC, useEffect, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { PrevnextApplications } from '@/types/api/Applications/prevnextApplications';
import { appClient } from '@/services';
import { FindApplicationByID } from '@/types/api/Applications/application';
import { GetAssociationsContribution } from '@/types/api/associations';
import { ErrorResponse } from '@/types/api/error';
import { useSnackbar } from '@/hooks/useSnackbar';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

export const AdmissionDetail: FC = () => {
  const applicationId = Number(useParams().applicationId);
  const [applicationData, setApplicationData] = useState<FindApplicationByID>();
  const [associationsContribution, setAssociationsContribution] =
    useState<GetAssociationsContribution>();
  useHelmetHandler({
    title: '新規会員登録 申請',
  });

  const getApplicationData = async () => {
    try {
      const res = await appClient.applications.findApplicationById(
        applicationId
      );
      setApplicationData(res);
    } catch (e) {
      console.log(e);
    }
  };

  const getAssociationsContribution = async () => {
    try {
      const res = await appClient.associations.getAssociationsContribution(
        applicationData?.associationCd || ''
      );
      setAssociationsContribution(res);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    void getApplicationData();
  }, [applicationId]);

  useEffect(() => {
    if (applicationData?.associationCd) {
      void getAssociationsContribution();
    }
  }, [applicationData]);

  const { showSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const [bottomData, setBottomData] = useState<PrevnextApplications>();
  const getBottomData = async () => {
    const res = await appClient.applications.findPrevNextApplicationById(
      applicationId,
      applicationData?.associationCd,
      undefined,
      searchParams.get('keyword') || undefined,
      Number(
        applicationData?.applicationDate?.substring(0, 7).replace('-', '')
      ) || undefined,
      'ADMISSION',
      undefined,
      undefined
    );
    setBottomData(res);
  };

  useEffect(() => {
    void getBottomData();
  }, [applicationData]);

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const handleApplication = async (
    value: 'APPROVAL' | 'REJECTED',
    comment?: string
  ) => {
    try {
      await appClient.applications.updateApplicationApproval(
        applicationId,
        value,
        {
          comment: comment || '',
          transferAmount: 0,
          remarks: '',
        }
      );
      if (applicationData) navigate(`/applications/admission`);
    } catch (e) {
      showSnackbar((e as ErrorResponse).data.message);
    }
  };

  if (applicationData && associationsContribution) {
    return (
      <ApplicationsAdmission
        application={applicationData}
        associationContribution={associationsContribution}
        bottomData={bottomData}
        handleApplication={handleApplication}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
