import {
  Box,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import React, { forwardRef, ComponentPropsWithoutRef } from 'react';

const LabelAnnotation = styled(Typography)(({ theme }) => ({
  color: theme.palette.system['text-light'],
  lineHeight: '24px',
}));

const MuiTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    paddingLeft: '8px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '8px 8px 8px 0px',
    textAlign: 'left',
  },
});

interface TextInputLabel {
  label: string;
  annotation?: string;
}

type TextInputWithLabelProps = TextInputLabel & TextFieldProps;
type ChildProps = ComponentPropsWithoutRef<'input'> & TextInputWithLabelProps;

export const EmailFieldWithLabel = forwardRef<HTMLInputElement, ChildProps>(
  ({ label, annotation, ...props }, ref) => (
    <>
      <Box display="flex" mb={1}>
        <Typography variant="body-main/bold" mr={1}>
          {label}
        </Typography>
        {annotation && (
          <LabelAnnotation variant="caption/regular">
            {annotation}
          </LabelAnnotation>
        )}
      </Box>
      <MuiTextField
        type="text"
        fullWidth
        ref={ref}
        error={props.error}
        helperText={props.error && props.helperText}
        {...props}
      />
    </>
  )
);
