/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Box, styled, Typography } from '@mui/material';
import { FC } from 'react';
import { FindTradereportsByID } from '@/types/api/tradereports';
import { Balance } from './Balance';
import { Execution } from './Execution';
import { MonthlyContribution } from './MonthlyContribution';
import { MonthlyContributionDetail } from './MonthlyContributionDetail';
import { OtherDetail } from './OtherDetail';
import { Residual } from './Residual';
import { Withdrawal } from './Withdrawal';

interface Props {
  label: string;
  value: string;
}

interface UnitProps {
  report: Required<FindTradereportsByID>;
}

const ColumnBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '24px',
});

const PurchaseBox: FC<Props> = ({ label, value }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '444px',
      p: '8px',
      gap: '16px',
      border: '1px solid',
      borderColor: 'system.separator',
      bgcolor: 'system.background',
      borderRadius: '4px',
    }}
  >
    <Typography variant="body-main/medium" sx={{ color: 'system.text-light' }}>
      {label}
    </Typography>
    <Typography
      sx={{
        color: 'system.text-normal',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '150%',
      }}
    >
      {value}
    </Typography>
  </Box>
);

export const MonthlyTradeReport: FC<UnitProps> = ({ report }) => (
  <>
    <MonthlyContribution report={report} />
    <Box sx={{ display: 'flex', gap: '24px' }}>
      <ColumnBox>
        <MonthlyContributionDetail report={report} />
      </ColumnBox>
      <ColumnBox>
        <OtherDetail report={report} />
      </ColumnBox>
    </Box>
    <Box sx={{ display: 'flex', gap: '24px' }}>
      <ColumnBox>
        <Balance report={report} />
      </ColumnBox>
      <ColumnBox>
        <Withdrawal report={report} />
      </ColumnBox>
    </Box>
    <Box sx={{ width: '100%' }}>
      <Execution report={report} type="unit" />
    </Box>
    <Residual report={report} />
    <Box sx={{ display: 'flex', gap: '24px' }}>
      <PurchaseBox
        label="購入代金"
        value={`${report.monthlyBonus.purchasePrice?.toLocaleString()}円`}
      />
      <PurchaseBox
        label="今回購入株数"
        value={`${report.monthlyBonus.purchaseSharesNum?.toLocaleString()}株`}
      />
    </Box>
  </>
);
