/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ApplicationsAdmissionDetailEdit } from '@/components/organisms/ApplicationsEdits/AdmissionDetailEdit';
import { FC, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { AdmissionEditInputs as Inputs } from '@/types/components/Inputs';
import { PrevnextApplicationParams } from '@/types/api/Applications/prevnextApplications';
import { ErrorResponse } from '@/types/api/error';
import { usePrevnextApplication } from '@/services/customHooks/usePrevnextApplication';
import { useApplication } from '@/services/customHooks/useApplication';
import { appClient } from '@/services';
import { useAssociationsContribution } from '@/services/customHooks/useAssociationsContribution';
import { useRecoilValue } from 'recoil';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { useSnackbar } from '@/hooks/useSnackbar';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

export const AdmissionDetailEdit: FC = () => {
  const navigate = useNavigate();
  const applicationId = Number(useParams().applicationId) || 0;
  const { associationCd = '' } = useRecoilValue(SelectedAssociationState);
  const { data: applicationData, mutate } = useApplication(applicationId);
  const associationContribution = useAssociationsContribution(associationCd);
  const [searchParams] = useSearchParams();
  useHelmetHandler({
    title: '新規会員登録 申請修正',
  });

  const [query] = useState<PrevnextApplicationParams>(
    searchParams as PrevnextApplicationParams
  );
  const { data: bottomData } = usePrevnextApplication(applicationId, query);
  const { showSnackbar } = useSnackbar();
  const onSubmit: SubmitHandler<Inputs> = async (d: Inputs) => {
    try {
      let bonusUnit = 0;
      let bonusRate = 0;
      switch (associationContribution?.bonusMultiplierDesignationType) {
        case 'FIXED_MAGNIFICATION':
          bonusUnit =
            Number(associationContribution.bonusMultiplierDetails) *
            Number(d.monthlyUnit || 0);
          bonusRate = Number(associationContribution.bonusMultiplierDetails);
          break;
        case 'MAGNIFICATION':
          bonusUnit = Number(d.bonusRate) * Number(d.monthlyUnit);
          bonusRate = Number(d.bonusRate);
          break;
        case 'UNIT_NUMBER':
          bonusUnit = Number(d.bonusUnit);
          break;
        default:
          break;
      }
      await appClient.applications.updateApplication(applicationId, {
        applicationId,
        applicationNewUser: {
          employeeCode: d.employeeCd,
          nameKanji: d.name,
          nameKana: d.nameKana,
          zipcode: d.zipcode,
          address1: d.address1,
          address2: d.address2,
          address3: d.address3,
          birthday: `${`${d.birthdayYear}-${d.birthdayMonth.padStart(
            2,
            '0'
          )}-${d.birthdayDay.padStart(2, '0')}`}`,
          officeCd: d.officeCd,
          affiliationCd: d.affiliationCd,
          tel: d.tel,
          telType: d.telType,
          monthlyUnit: Number(d.monthlyUnit),
          isBonusUnit: d.isBonus,
          bonusUnit: d.isBonus ? bonusUnit : 0,
          bonusRate: d.isBonus ? bonusRate : 0,
        },
      });
      if (applicationId) {
        await mutate({
          applicationNewUser: {
            ...applicationData?.applicationNewUser,
            isBonusUnit: d.isBonus,
            bonusUnit: d.isBonus ? bonusUnit : 0,
            bonusRate: d.isBonus ? bonusRate : 0,
          },
        });
        navigate(`/applications/admission/${applicationId}`);
      }
    } catch (e) {
      // エラー処理
      showSnackbar((e as ErrorResponse).data.message);
    }
  };
  if (applicationData && associationContribution) {
    return (
      <ApplicationsAdmissionDetailEdit
        application={applicationData}
        associationsContribution={associationContribution}
        bottomData={bottomData}
        onSubmit={onSubmit}
      />
    );
  }
  return null;
};
