import { AssociationBankOrganism } from '@/components/organisms/Association/AssociationBankOrganism';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UpdateAssociationsAccount } from '@/types/api/associations';
import { appClient } from '@/services';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { snackbarAtom } from '@/atom/snackbarAtom';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import useSWR from 'swr';
import { FormProvider, useForm } from 'react-hook-form';
import { Box } from '@mui/material';

export const AssociationBank = () => {
  const [isError, setIsError] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const openSnackBar = useSetRecoilState(snackbarAtom);
  const navigate = useNavigate();
  const { associationCd = '' } = useRecoilValue(SelectedAssociationState);
  const { mutate } = useSWR(
    associationCd ? '/api/associations/{associationCd}/account' : null,
    () => appClient.associations.findAssociationsAccount(associationCd)
  );
  const methods = useForm<UpdateAssociationsAccount>({
    mode: 'onBlur',
    defaultValues: {
      accountClassification: 'OTHER',
      institutionName: '',
      branchCd: '',
      branchName: '',
      accountType: 'SAVINGS',
      accountNumber: '',
      accountName: '',
      accountYuchoSymbol: '',
      accountYuchoNumber: '',
    },
  });

  const onSubmit = async (data: UpdateAssociationsAccount) => {
    try {
      const body =
        data.accountClassification === 'YUCHO'
          ? {
              accountYuchoSymbol: data.accountYuchoSymbol,
              accountYuchoNumber: data.accountYuchoNumber,
              accountClassification: data.accountClassification,
              accountName: data.accountName,
            }
          : data;

      await appClient.associations.updateAssociationsAccount(
        associationCd,
        body
      );
      openSnackBar({
        open: true,
        message: '買増精算振込先口座を変更しました。',
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    navigate('/association');
  };
  const updateState = async () => {
    const state = await mutate();
    methods.reset(state);
  };

  useEffect(() => {
    void updateState();
  }, [associationCd]);

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
        <AssociationBankOrganism
          isConfirmed={isConfirmed}
          setIsConfirmed={async (v) => {
            await methods.trigger();
            setIsConfirmed(v);
          }}
          methods={methods}
          isError={isError}
          setIsError={(v) => setIsError(v)}
        />
      </Box>
    </FormProvider>
  );
};
