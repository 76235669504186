import { theme } from '@/theme';
import { Stack, styled, Typography } from '@mui/material';

export const StyledStack = styled(Stack)(() => ({
  padding: theme.spacing(4, 5),
}));

export const Label = styled(Typography)(() => ({
  ...theme.typography['body-main/bold'],
  width: '144px',
}));

export const MailAnnotation = styled(Typography)(() => ({
  ...theme.typography['caption/regular'],
  color: theme.palette.system['text-light'],
}));

export const ErrorText = styled(Typography)(() => ({
  ...theme.typography['caption/regular'],
  color: theme.palette.states.error,
}));

export const ConfirmedSubTitle = styled(Typography)(() => ({
  ...theme.typography['body-main/regular'],
  color: theme.palette.system['text-light'],
}));
