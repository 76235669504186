import { FC, ReactNode } from 'react';
import { styled, Stack, Box, Typography } from '@mui/material';
import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { ReactComponent as Arrow } from '@/assets/arrow.svg';

const Title = styled(Box)({
  paddingBottom: '8px',
  borderBottom: '1px solid',
});

interface TableProps {
  title: string | string[];
  isRed?: boolean;
  isEdit?: boolean;
  to?: string;
  children: ReactNode;
}

export const ApplicationTable: FC<TableProps> = ({
  title,
  isRed = false,
  isEdit = false,
  children,
  to,
}) => (
  <Stack width="100%">
    <Title
      sx={{
        borderColor: isRed ? 'primary.main' : 'system.separator-light',
        color: isRed ? 'primary.main' : 'system.text-normal',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="h5">{title}</Typography>
      {isEdit && (
        <NormalLink href={to}>
          変更する
          <Arrow />
        </NormalLink>
      )}
    </Title>
    {children}
  </Stack>
);
