/* eslint-disable prefer-template */
import { useEffect, useState, useCallback } from 'react';
import { theme } from '@/theme/theme';
import {
  Box,
  styled,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import { Title } from '@/components/molecules/Title/Title';
import { appClient } from '@/services';
import { useRecoilValue } from 'recoil';
import { formatDateTimeWithSlash } from '@/utils/dateFunction';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { useGetAssociation } from '@/services/customHooks/useGetAssociation';
import { GetAssociationsBalances } from '@/types/api/associations';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const Wrapper = styled(Box)({});

const HeaderWrapper = styled(Box)({
  padding: '32px 40px',
  borderWidth: 0,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.system['separator-light'],
  backgroundColor: theme.palette.system['background-light'],
});

const HeaderTitle = styled(Typography)({
  ...theme.typography.h4,
  color: theme.palette.system['text-normal'],
});

// The title is different from HeaderTitle
const TitleWrapper = styled(Box)({
  padding: '32px 40px 0',
  width: '100%',
});

const TableCellColors = {
  typeA: '#3D9EA3',
  typeB: '#EFFBFC',
};

const CellWidth = 136;

const TableWrapper = styled(Box)({
  marginTop: 24,
  margin: '24px 40px 32px 40px',
});

const BaseCell = styled(TableCell)(() => {
  const { palette } = theme;
  return {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.system['separator-light'],
    padding: '16px 12px',

    '&:last-of-type': {
      borderRightWidth: 0,
    },
  };
});

const BaseCellException = styled(TableCell)(() => {
  const { palette } = theme;
  return {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.system['separator-light'],
    padding: '16px 12px',
  };
});

const HeadCellTextGroup = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
});

const HeadCellText = styled(Typography)(() => {
  const { typography } = theme;

  return {
    ...typography['body-sub/medium'],
    color: '#0B5D61',
  };
});

const CellText = styled(Typography)(() => {
  const { typography, palette } = theme;

  return {
    ...typography.h6,
    color: palette.system['text-normal'],
  };
});

export const Balance = () => {
  const selectedAssociation = useRecoilValue(SelectedAssociationState);
  const [balance, setBalance] = useState<GetAssociationsBalances>();
  useHelmetHandler({
    title: '残高詳細',
  });

  const getData = useCallback(async () => {
    try {
      if (!selectedAssociation.associationCd) return;
      const res = await appClient.associations.getAssociationsBalances(
        selectedAssociation.associationCd
      );
      setBalance(res);
    } catch (e) {
      // Handle getData error
    }
  }, [selectedAssociation.associationCd]);

  const { data } = useGetAssociation();
  const kanmaChange = (value: string) => {
    let kanma;
    kanma = value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    if (value.includes('.')) {
      const valueArr = value.split('.');
      const kanmaInt = valueArr[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      const cutDigit = valueArr[1].substring(0, data?.investmentDigit);
      kanma = kanmaInt + '.' + cutDigit;
    }

    return kanma;
  };

  useEffect(() => {
    void getData();
  }, [getData]);

  return (
    <Wrapper>
      <HeaderWrapper>
        <HeaderTitle>残高詳細</HeaderTitle>
      </HeaderWrapper>
      <TitleWrapper>
        <Title
          title="残高詳細"
          subtitle={`${formatDateTimeWithSlash(String(new Date()))} 現在`}
        />
      </TitleWrapper>
      <TableWrapper>
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: 'none',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.system['separator-light'],
          }}
        >
          <Table sx={{ minWidth: '100%' }}>
            <TableHead>
              <TableRow
                sx={{
                  borderWidth: 0,
                  borderBottomWidth: 1,
                  borderStyle: 'solid',
                  borderColor: theme.palette.system['separator-light'],
                }}
              >
                <BaseCell
                  align="center"
                  colSpan={3}
                  sx={{
                    width: CellWidth * 3,
                    padding: '4px 12px',
                    backgroundColor: TableCellColors.typeA,
                  }}
                >
                  <HeadCellText sx={{ color: theme.palette.system.white }}>
                    株数残合計 (株)
                  </HeadCellText>
                </BaseCell>
                <BaseCell
                  align="center"
                  colSpan={3}
                  sx={{
                    width: CellWidth * 3,
                    padding: '4px 12px',
                    backgroundColor: TableCellColors.typeA,
                  }}
                >
                  <HeadCellText sx={{ color: theme.palette.system.white }}>
                    理事長口未配分株式 (株)
                  </HeadCellText>
                </BaseCell>
                <BaseCell
                  align="center"
                  rowSpan={2}
                  sx={{
                    width: CellWidth,
                    padding: '8px 10px',
                    backgroundColor: TableCellColors.typeB,
                  }}
                >
                  <HeadCellTextGroup sx={{ justifyContent: 'center' }}>
                    <HeadCellText>理事長口未配分金</HeadCellText>
                    <HeadCellText>(円)</HeadCellText>
                  </HeadCellTextGroup>
                </BaseCell>
                <BaseCell
                  align="center"
                  rowSpan={2}
                  sx={{
                    width: CellWidth,
                    padding: '8px 10px',
                    backgroundColor: TableCellColors.typeB,
                  }}
                >
                  <HeadCellTextGroup>
                    <HeadCellText>繰越金合計</HeadCellText>
                    <HeadCellText>(円)</HeadCellText>
                  </HeadCellTextGroup>
                </BaseCell>
              </TableRow>
              <TableRow
                sx={{
                  borderWidth: 0,
                  borderBottomWidth: 1,
                  borderStyle: 'solid',
                  borderColor: theme.palette.system['separator-light'],
                }}
              >
                <BaseCell
                  align="center"
                  sx={{
                    width: CellWidth,
                    padding: '4px 12px',
                    backgroundColor: TableCellColors.typeB,
                  }}
                >
                  <HeadCellText>旧株</HeadCellText>
                </BaseCell>
                <BaseCell
                  align="center"
                  sx={{
                    width: CellWidth,
                    padding: '4px 12px',
                    backgroundColor: TableCellColors.typeB,
                  }}
                >
                  <HeadCellText>第1新株</HeadCellText>
                </BaseCell>
                <BaseCell
                  align="center"
                  sx={{
                    width: CellWidth,
                    padding: '4px 12px',
                    backgroundColor: TableCellColors.typeB,
                  }}
                >
                  <HeadCellText>第2新株</HeadCellText>
                </BaseCell>
                <BaseCell
                  align="center"
                  sx={{
                    width: CellWidth,
                    padding: '4px 12px',
                    backgroundColor: TableCellColors.typeB,
                  }}
                >
                  <HeadCellText>旧株</HeadCellText>
                </BaseCell>
                <BaseCell
                  align="center"
                  sx={{
                    width: CellWidth,
                    padding: '4px 12px',
                    backgroundColor: TableCellColors.typeB,
                  }}
                >
                  <HeadCellText>第1新株</HeadCellText>
                </BaseCell>
                <BaseCellException
                  align="center"
                  sx={{
                    width: CellWidth,
                    padding: '4px 12px',
                    backgroundColor: TableCellColors.typeB,
                  }}
                >
                  <HeadCellText>第2新株</HeadCellText>
                </BaseCellException>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <BaseCell align="right">
                  <CellText>
                    {kanmaChange(String(balance?.oldStockRemainingShares))}
                  </CellText>
                </BaseCell>
                <BaseCell align="right">
                  <CellText>
                    {kanmaChange(String(balance?.firstStockRemainingShares))}
                  </CellText>
                </BaseCell>
                <BaseCell align="right">
                  <CellText>
                    {kanmaChange(String(balance?.secondStockRemainingShares))}
                  </CellText>
                </BaseCell>
                <BaseCell align="right">
                  <CellText>
                    {kanmaChange(String(balance?.oldStockUnallocatedShares))}
                  </CellText>
                </BaseCell>
                <BaseCell align="right">
                  <CellText>
                    {kanmaChange(String(balance?.firstStockUnallocatedShares))}
                  </CellText>
                </BaseCell>
                <BaseCell align="right">
                  <CellText>
                    {kanmaChange(String(balance?.secondStockUnallocatedShares))}
                  </CellText>
                </BaseCell>
                <BaseCell align="right">
                  <CellText>
                    {kanmaChange(String(balance?.presidentUnallocatedMoney))}
                  </CellText>
                </BaseCell>
                <BaseCell align="right">
                  <CellText>
                    {kanmaChange(String(balance?.totalCarriedForwardAmount))}
                  </CellText>
                </BaseCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </TableWrapper>
    </Wrapper>
  );
};
