import { MembersProxyButtons } from '@/components/organisms/Members/MembersProxyButtons';
import { MemberProxyHeader } from '@/components/organisms/Members/MembersProxyHeader';
import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFindMember } from '@/services/customHooks/useFindMember';
import { useGetAssociation } from '@/services/customHooks/useGetAssociation';
import { MemberProxyApplicationBody } from '@/components/organisms/Members/MemberProxyApplicationBody';
import { useGetAssociationsContribution } from '@/services/customHooks/useGetAssociationsContribution';
import { useFindMemberContribution } from '@/services/customHooks/useFindMemberContribution';
import { FormProvider, useForm } from 'react-hook-form';
import { CreateApplication } from '@/types/api/applications';
import { useNavigate, useParams } from 'react-router-dom';
import { appClient } from '@/services';
import { useListEvents } from '@/services/customHooks/useListEvents';
// @ts-ignore
import { ListEvents } from '@/types/api/events';
import { useFindAssociationsAccount } from '@/services/customHooks/useFindAssociationsAccount';
import { useFindMemberAsset } from '@/services/customHooks/useFindMemberAsset';
import { BasicModal } from '@/components/molecules/Modal';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { format } from 'date-fns';
import { useMemberSecuritiesAccount } from '@/services/customHooks/useMemberSecuritiesAccount';
import { useSnackbar } from '@/hooks/useSnackbar';
import { ErrorResponse } from '@/types/api/error';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { MembersHeader } from './MembersHeader';

const styleContainer: SxProps<Theme> = [
  {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    width: '580px',
    p: '32px',
  },
];

export const MemberProxyApplication = () => {
  const [isModal, setIsModal] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isUnit, setIsUnit] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<
    Exclude<ListEvents, undefined>[number]
  >({});
  const navigate = useNavigate();
  const { memberId = '', applicationType = '' } = useParams();
  const baseUrl = `/members/${memberId}/proxy`;
  const { showSnackbar } = useSnackbar();
  const { data: memberData } = useFindMember();
  const { data: association } = useGetAssociation();
  const { data: associationContribution } = useGetAssociationsContribution();
  const { data: memberSecurityAccount } = useMemberSecuritiesAccount(
    Number(memberId)
  );
  const { data: account } = useFindAssociationsAccount();
  const { data: memberContribution } = useFindMemberContribution();
  const { data: events } = useListEvents(
    undefined,
    'PURCHASE_DATE',
    format(new Date(), 'yyyy-MM-dd'),
    undefined
  );
  const { data: memberAsset } = useFindMemberAsset();
  const contribution = {
    ...associationContribution,
    ...memberContribution,
  };
  const methods = useForm<CreateApplication>({
    defaultValues: {
      eventNo: '',
      applicationMonthlyUnit: {
        monthlyUnit: contribution.monthlyUnit || 0,
      },
      applicationBonusUnit: {
        isBonusUnit: contribution.bonusUnit !== 0,
        bonusUnit: contribution.bonusUnit,
        bonusRate: contribution.bonusRate || 0,
      },
      applicationPart: {
        sharesNum: 0,
        leftSharesNum: 0,
      },
      applicationRecess: {
        reason: undefined,
        otherReasons: '',
      },
      applicationWithdrawal: {
        reason: undefined,
        otherReasons: '',
        generalAccount: {
          institutionCode: '',
          institutionName: '',
          branchCode: '',
          branchName: '',
          accountType: 'SAVINGS',
          accountNumber: '',
          accountName: '',
        },
        isAdditionalOption: false,
        transferAmount: 0,
        remarks: '',
        institutionType: 'OTHER',
        accountYuchoSymbol: '',
        accountYuchoNumber: '',
      },
      applicationUser: {
        nameKanji: '',
        nameKana: '',
        zipcode: '',
        address1: '',
        address2: '',
        address3: '',
        telType: 'MOBILE',
        tel: '',
        employeeCode: '',
        officeCd: '',
        affiliationCd: '',
      },
    },
  });

  const convertApplicationType = (type: string) => {
    switch (type) {
      case 'pay': {
        return 'MONTHLY_UNIT';
      }
      case 'bonus': {
        return 'BONUS_UNIT';
      }
      case 'part': {
        return 'PART';
      }
      case 'recess': {
        return 'RECESS';
      }
      case 'resume': {
        return 'RESUME';
      }
      case 'withdrawal': {
        return 'WITHDRAWAL';
      }
      default: {
        return undefined;
      }
    }
  };

  const getTitle = () => {
    const appType = convertApplicationType(applicationType);
    switch (appType) {
      case 'MONTHLY_UNIT':
        return isConfirmed
          ? '持株会会員詳細 代理申請 口数変更(給与) 入力内容確認'
          : '持株会会員詳細 代理申請 口数変更(給与)';
      case 'BONUS_UNIT':
        return isConfirmed
          ? '持株会会員詳細 代理申請 口数変更(賞与) 入力内容確認'
          : '持株会会員詳細 代理申請 口数変更(賞与)';
      case 'PART':
        return isConfirmed
          ? '持株会会員詳細 代理申請 一部引出 入力内容確認'
          : '持株会会員詳細 代理申請 一部引き出し';
      case 'RECESS':
        return isConfirmed
          ? '持株会会員詳細 代理申請 休止 入力内容確認'
          : '持株会会員詳細 代理申請 休止';
      case 'RESUME':
        return isConfirmed
          ? '持株会会員詳細 代理申請 再開 入力内容確認'
          : '持株会会員詳細 代理申請 再開';
      case 'WITHDRAWAL':
        return isConfirmed
          ? '持株会会員詳細 代理申請 退会 入力内容確認'
          : '持株会会員詳細 代理申請 退会';
      default:
        return 'NexStocks';
    }
  };

  const onSubmit = async (data: CreateApplication) => {
    try {
      const bonusRate =
        contribution.bonusMultiplierDesignationType === 'FIXED_MAGNIFICATION'
          ? Number(contribution.bonusMultiplierDetails)
          : Number(data.applicationBonusUnit?.bonusRate);
      const isBonusReserve = contribution.bonusReserveClassification !== 'NONE';
      const { applicationId } = await appClient.applications.createApplication({
        memberId: Number(memberId),
        associationCd: contribution.associationCd,
        applicationType: convertApplicationType(applicationType),
        ...data,
        applicationMonthlyUnit: {
          monthlyUnit: data.applicationMonthlyUnit?.monthlyUnit,
          isBonusUnit: data.applicationBonusUnit?.isBonusUnit,
          bonusRate:
            data.applicationBonusUnit?.isBonusUnit && isBonusReserve
              ? bonusRate
              : 0,
          bonusContributionUnit:
            data.applicationBonusUnit?.isBonusUnit && isBonusReserve
              ? Number(data.applicationBonusUnit?.bonusUnit)
              : 0,
        },
        applicationPart: {
          ...data.applicationPart,
          sharesNum: (data.applicationPart?.sharesNum ?? 0) * 100,
        },
      });
      if (typeof applicationId !== 'undefined') {
        navigate(`${baseUrl}/${applicationType}/complete`, {
          state: { applicationId, ...selectedEvent },
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      showSnackbar((e as ErrorResponse).data.message);
    }
  };

  const handleClickCheck = () => {
    setIsModal(false);
  };

  useEffect(() => {
    if (events) {
      if (events.length > 0) {
        methods.setValue('eventNo', events[0].eventNo ?? '');
      }
    }
  }, [events]);

  useEffect(() => {
    if (isConfirmed) {
      if (applicationType === 'part' || applicationType === 'withdrawal') {
        if (
          memberSecurityAccount &&
          memberSecurityAccount?.accountNumber === '' &&
          memberSecurityAccount?.branchCd === '' &&
          memberSecurityAccount?.branchName === ''
        ) {
          setIsModal(true);
        }
      }
    }
  }, [isConfirmed]);

  useEffect(() => {
    if (associationContribution && memberContribution) {
      methods.setValue('applicationMonthlyUnit', {
        monthlyUnit: contribution.monthlyUnit || 0,
      });
      methods.setValue('applicationBonusUnit', {
        isBonusUnit: contribution.bonusUnit !== 0,
        bonusUnit: contribution.bonusUnit,
        bonusRate: contribution.bonusRate || 0,
      });
    }
  }, [associationContribution, memberContribution]);

  useHelmetHandler({
    title: getTitle(),
  });

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing={3} p="32px 40px">
          <MembersHeader memberData={memberData} />
          <MemberProxyHeader isConfirmed={isConfirmed} />
          <MemberProxyApplicationBody
            isConfirmed={isConfirmed}
            user={memberData ?? {}}
            association={association ?? {}}
            contribution={contribution ?? {}}
            stocksNumber={memberAsset?.stocksNumber}
            memberSecurityAccount={memberSecurityAccount}
            events={
              events?.filter(
                (event) => event.workreportApprovalStatus === 'BEFORESUBMISSION'
              ) ?? []
            }
            methods={methods}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
            setIsUnit={(v) => setIsUnit(v)}
          />
          <MembersProxyButtons
            isConfirmed={isConfirmed}
            isUnit={isUnit}
            setIsConfirmed={(v) => setIsConfirmed(v)}
          />
        </Stack>
      </Box>

      <BasicModal open={isModal} handleClose={() => setIsModal(false)}>
        <Box sx={styleContainer}>
          <Typography variant="h4" sx={{ color: 'system.text-normal' }}>
            会員への証券口座登録を依頼してください
          </Typography>
          <Typography
            component="p"
            variant="body-main/regular"
            sx={{ color: 'system.text-normal' }}
          >
            証券口座が登録されていません。
            <br />
            会員にNexStocksのマイページから証券口座登録を依頼してください。（※会員登録必須）
          </Typography>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <PrimaryButton
              sx={{
                width: '144px',
                height: '41px',
                whiteSpace: 'nowrap',
              }}
              onClick={handleClickCheck}
            >
              OK
            </PrimaryButton>
          </Box>
        </Box>
      </BasicModal>
    </FormProvider>
  );
};
