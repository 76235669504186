/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// eslint-disable-next-line import/no-cycle
import { ApplicationsDividendIndex } from '@/components/organisms/Applications/DividendIndex';
import { SubmitHandler } from 'react-hook-form';
import { WorkReportInputs as Inputs } from '@/types/components/Inputs';
import { useEffect, useState } from 'react';
import { appClient } from '@/services';
import { useRecoilValue } from 'recoil';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { FindTradereportsByID } from '@/types/api/tradereports';
import { FindWorkreportsById } from '@/types/api/workreports';
import { GetAssociation } from '@/types/api/associations';
import { toRequired } from '@/utils/formatter';
import { convertParamMonth } from '@/utils/helper';
import { getMonth, getYear } from 'date-fns';

export const DividendIndex = () => {
  const today = new Date();
  const [modal, setModal] = useState<string>('');
  const [year, setYear] = useState<number>(getYear(today));
  const [month, setMonth] = useState<number>(getMonth(today) + 1);
  const { associationCd = '' } = useRecoilValue(SelectedAssociationState);
  const [workreport, setWorkreport] = useState<Required<FindWorkreportsById>>(
    {} as Required<FindWorkreportsById>
  );
  const [tradereport, setTradereport] = useState<
    Required<FindTradereportsByID>
  >({} as Required<FindTradereportsByID>);

  const [association, setAssociation] = useState<GetAssociation>();

  const [applicationsParams, setApplicationsParams] = useState({
    associationCd,
  });

  /**
   * onSubmit
   * @param d
   */
  const onSubmit: SubmitHandler<Inputs> = async (d: Inputs) => {
    d.dividendPerUnit = (parseFloat(d.dividendPerUnit) * 100).toString();
    try {
      await appClient.workreports.createWorkreportSendById(
        associationCd,
        String(year),
        String(convertParamMonth(month)),
        'DIVIDEND',
        {
          dividend: {
            rightMonth: Number(d.rightedMonth),
            rightMonthStockCount: d.rightedMonthUnit
              ? Number(d.rightedMonthUnit.replaceAll(',', ''))
              : undefined,
            dividendOneStock: d.dividendPerUnit
              ? Number(d.dividendPerUnit.replaceAll(',', ''))
              : undefined,
            receiptDividend: d.dividendIncome
              ? Number(d.dividendIncome.replaceAll(',', ''))
              : undefined,
          },
          info: d.other || '',
        }
      );
      console.log(d);
      setModal('complete');
    } catch (e) {
      // エラー処理
    }
  };

  const openWorkReport = async () => {
    try {
      const res = await appClient.workreports.findWorkreportById(
        String(year),
        String(convertParamMonth(month)),
        'DIVIDEND',
        associationCd
      );
      setWorkreport(toRequired(res));
    } catch (e) {
      console.log(e);
    }
    setModal('workreport');
  };

  const openTradeReport = async () => {
    console.log(
      String(year),
      String(convertParamMonth(month)),
      'DIVIDEND',
      associationCd
    );
    try {
      const res = await appClient.tradereports.findTradereportsById(
        String(year),
        String(convertParamMonth(month)),
        'DIVIDEND',
        associationCd
      );
      setTradereport(toRequired(res));
      console.log(res);
    } catch (e) {
      console.log(e);
    }
    setModal('tradereport');
  };

  const getAssociation = async () => {
    try {
      const res = await appClient.associations.getAssociation(associationCd);
      setAssociation(res);
    } catch (e) {
      console.log(e);
    }
  };

  // useEffect(() => {
  //   void openWorkReport().then((r) => r);
  //   void openTradeReport().then((r) => r);
  // }, [year, month]);

  useEffect(() => {
    void getAssociation();
  }, [associationCd]);

  useEffect(() => {
    const body = {
      ...applicationsParams,
      yearMonth: Number(String(year) + String(convertParamMonth(month))),
    };
    setApplicationsParams(body);
  }, [year, month]);

  return (
    <ApplicationsDividendIndex
      type="dividend"
      onSubmit={onSubmit}
      year={year}
      month={month}
      setYear={setYear}
      setMonth={setMonth}
      tradereport={tradereport}
      workreport={workreport}
      modal={modal}
      openWorkreport={openWorkReport}
      openTradereport={openTradeReport}
      setModal={setModal}
      association={association}
    />
  );
};
