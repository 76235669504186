import { FC } from 'react';
import { theme } from '@/theme';
import { Box, Typography, styled } from '@mui/material';
import { EventsItem } from '@/types/api/listEventsDaily';
import { CalendarEventDescription } from './CalendarEventDescription';

interface CalendarEventProps {
  isEnabled: boolean;
  day: number;
  week?: string;
  description: EventsItem[];
  bonusReserveClassification?: 'PURCHASE' | 'RESERVE' | 'NONE';
  onClick: () => void;
}

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  borderRadius: 4,
  background: theme.palette.system.white,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.system['separator-light'],
  overflow: 'hidden',
});

const DateWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: 16,
  backgroundColor: theme.palette.system.background,
  gap: 4,
});

const DateText = styled(Typography)({
  ...theme.typography.h5,
  color: theme.palette.system['text-light'],
});

const DateText2 = styled(Typography)({
  ...theme.typography.h5,
  color: theme.palette.system['text-light'],
  fontSize: 16,
});

const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '25px 16px',
  gap: 16,
});

export const CalendarEvent: FC<CalendarEventProps> = ({
  day,
  week,
  description,
  bonusReserveClassification,
  isEnabled,
  onClick,
}) => {
  const renderDescriptions = () => {
    if (description.length > 0)
      return description.map((item) => (
        <CalendarEventDescription
          key={item.eventNo}
          status={item.purchaseClosingType === 'PURCHASE'}
          eventType={item.eventType}
          subtitleContext={`毎月${String(item.monthlyPurchaseScheduleDay)}日`}
          bonusReserveClassification={bonusReserveClassification}
        />
      ));
    return null;
  };

  return (
    <Wrapper onClick={onClick} sx={{ opacity: isEnabled ? 1 : 0.5 }}>
      <DateWrapper>
        <DateText>{day}日</DateText>
        <DateText2>({week})</DateText2>
      </DateWrapper>
      <ContentWrapper>{renderDescriptions()}</ContentWrapper>
    </Wrapper>
  );
};
