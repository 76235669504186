/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { FC } from 'react';
import { Controller, Control } from 'react-hook-form';
import { WorkReportInputs as Inputs } from '@/types/components/Inputs';
import { Box, Typography, TextField } from '@mui/material';

interface Props {
  isSubmit?: 'BEFORESUBMISSION' | 'SUBMISSION' | undefined;
  control?: Control<Inputs>;
  modalStatus: boolean;
  data: Inputs;
  info?: string;
}

export const Memo: FC<Props> = ({
  isSubmit,
  control,
  modalStatus,
  data,
  info,
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '8px',
    }}
  >
    <Typography variant="h5" sx={{ color: 'system.text-normal' }}>
      その他の連絡事項
    </Typography>
    {isSubmit === 'SUBMISSION' ? (
      <Box
        sx={{
          width: '872px',
          height: '100px',
          p: '12px',
          bgcolor: 'system.background-light',
          border: '1px solid',
          borderColor: 'system.separator-light',
          borderRadius: '4px',
        }}
      >
        <Typography
          variant="body-main/regular"
          sx={{ color: 'system.text-normal' }}
        >
          {info}
        </Typography>
      </Box>
    ) : (
      <Box>
        {modalStatus ? (
          <Box
            sx={{
              width: '872px',
              height: '100px',
              p: '12px',
              bgcolor: 'system.background-light',
              border: '1px solid',
              borderColor: 'system.separator-light',
              borderRadius: '4px',
            }}
          >
            <Typography
              variant="body-main/regular"
              sx={{ color: 'system.text-normal' }}
            >
              {data.other || ''}
            </Typography>
          </Box>
        ) : (
          <Controller
            name="other"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{
                  width: '872px',
                  height: '200px',
                  borderColor: 'system.separator-light',

                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'rgba(252, 251, 251, 1)',
                    padding: 0,
                  },
                  '& textarea': {
                    padding: '12px',
                  },
                }}
                multiline
                rows={6}
                placeholder="連絡事項を入力(任意)"
              />
            )}
          />
        )}
      </Box>
    )}
  </Box>
);
