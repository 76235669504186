/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CsvService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * 【API-B-2302】CSV DL_【事務局用】
   * - masterdata: マスターデータ
   * - memberrightallocation: 会員権配
   * - withdrawalinstructions: 引出指示
   * - memberallocationresult: 会員配分結果
   * - withdrawalstatus: 退会状況
   *
   * @param associationCd 持株会コード
   *
   * @param csvType CSV種別
   * - masterdata: マスターデータ
   * - memberrightallocation: 会員権配
   * - withdrawalinstructions: 引出指示
   * - memberallocationresult: 会員配分結果
   * - withdrawalstatus: 退会状況
   *
   * @returns binary report dividend response
   * @returns any unexpected error
   * @throws ApiError
   */
  public downloadCsv(
    associationCd: string,
    csvType: string
  ): CancelablePromise<
    | Blob
    | {
        /**
         * Error code
         */
        code: number;
        /**
         * Error message
         */
        message: string;
      }
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/associations/{associationCd}/{csvType}/csv',
      path: {
        associationCd: associationCd,
        csvType: csvType,
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-2303】CSV DL_【事務局用】申請履歴一覧取得
   * 申請履歴一覧を取得する
   *
   * @param associationCd 持株会コード
   *
   * @param memberId 持株会員ID
   *
   * @param keyword キーワード：部分一致（会員コード・会員名）
   *
   * @param yearMonth 取得年月
   *
   * @param eventType イベント種別
   * - MONTHLY: 月例（会員情報変更登録除く）
   * - BONUS: 賞与（新規会員登録・会員情報変更登録除く）
   * - DIVIDEND: 配当
   * - SPECIAL: 臨時
   * - ADMISSION: 新規会員登録
   * - USER: 会員情報変更
   *
   * @param applicationType 申請種別
   * - MONTHLY_UNIT: 口数変更(月例)
   * - BONUS_UNIT: 口数変更(賞与)
   * - PART: 一部引出
   * - RECESS: 休会
   * - RESUME: 再開
   * - WITHDRAWAL: 退会
   * - ADMISSION: 新規会員登録
   * - USER: 会員情報変更
   * - TEMPORARY: 臨時
   *
   * @param applicationStatus 申請ステータス
   * - UNAPPROVED: 未承認
   * - APPROVED: 承認済
   * - REJECTED: 却下
   *
   * @param sortKey ソートキー
   * - APPLICATION_ID_ASC: 申請ID昇順
   * - APPLICATION_ID_DESC: 申請ID降順
   * - APPLICATION_TYPE_ASC: 申請種別昇順
   * - APPLICATION_TYPE_DESC: 申請種別降順
   * - MEMBER_CD_ASC: 会員コード昇順
   * - MEMBER_CD_DESC: 会員コード降順
   * - MEMBER_NAME_ASC: 会員名昇順
   * - MEMBER_NAME_DESC: 会員名降順
   * - APPLICATION_DATE_ASC: 申請日時昇順
   * - APPLICATION_DATE_DESC: 申請日時降順
   * - APPLICATION_STATUS_ASC: 申請ステータス昇順
   * - APPLICATION_STATUS_DESC: 申請ステータス降順
   *
   * @returns binary report dividend response
   * @returns any unexpected error
   * @throws ApiError
   */
  public downloadCsvListApplications(
    associationCd?: string,
    memberId?: string,
    keyword?: string,
    yearMonth?: number,
    eventType?:
      | 'MONTHLY'
      | 'BONUS'
      | 'DIVIDEND'
      | 'SPECIAL'
      | 'ADMISSION'
      | 'USER',
    applicationType?:
      | 'MONTHLY_UNIT'
      | 'BONUS_UNIT'
      | 'PART'
      | 'RECESS'
      | 'RESUME'
      | 'WITHDRAWAL'
      | 'ADMISSION'
      | 'USER'
      | 'TEMPORARY',
    applicationStatus?: 'UNAPPROVED' | 'APPROVED' | 'REJECTED',
    sortKey?:
      | 'APPLICATION_ID_ASC'
      | 'APPLICATION_ID_DESC'
      | 'APPLICATION_TYPE_ASC'
      | 'APPLICATION_TYPE_DESC'
      | 'MEMBER_CD_ASC'
      | 'MEMBER_CD_DESC'
      | 'MEMBER_NAME_ASC'
      | 'MEMBER_NAME_DESC'
      | 'EMPLOYEE_CD_ASC'
      | 'EMPLOYEE_CD_DESC'
      | 'APPLICATION_DATE_ASC'
      | 'APPLICATION_DATE_DESC'
      | 'APPLICATION_STATUS_ASC'
      | 'APPLICATION_STATUS_DESC'
  ): CancelablePromise<
    | Blob
    | {
        /**
         * Error code
         */
        code: number;
        /**
         * Error message
         */
        message: string;
      }
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/applications/csv',
      query: {
        associationCd: associationCd,
        memberId: memberId,
        keyword: keyword,
        yearMonth: yearMonth,
        eventType: eventType,
        applicationType: applicationType,
        applicationStatus: applicationStatus,
        sortKey: sortKey,
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-2304】CSV DL_【事務局用】積立・奨励金情報ダウンロード
   * 会員の積立・奨励金情報一覧を取得する
   *
   * @param associationCd 持株会コード
   *
   * @param yearMonth 取得年月
   *
   * @param eventType イベント種別
   * - MONTHLY: 月例
   * - BONUS: 賞与
   *
   * @returns binary member deposit response
   * @returns any unexpected error
   * @throws ApiError
   */
  public listMemberDepositDownloadCsv(
    associationCd: string,
    yearMonth: number,
    eventType: 'MONTHLY' | 'BONUS'
  ): CancelablePromise<
    | Blob
    | {
        /**
         * Error code
         */
        code: number;
        /**
         * Error message
         */
        message: string;
      }
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/members/deposit/{associationCd}/{yearMonth}/{eventType}/csv',
      path: {
        associationCd: associationCd,
        yearMonth: yearMonth,
        eventType: eventType,
      },
      errors: {
        400: `bad request`,
      },
    });
  }
}
