/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ApplicationsRecessEdit } from '@/components/organisms/ApplicationsEdits/RecessEdit';
import { FC, useState, useEffect } from 'react';
import {
  useMatch,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { RecessEditInputs as Inputs } from '@/types/components/Inputs';
import { MemberById } from '@/types/api/member';
import { FindApplicationByID } from '@/types/api/Applications/application';
import { ErrorResponse } from '@/types/api/error';
import { usePrevnextApplication } from '@/services/customHooks/usePrevnextApplication';
import { PrevnextApplicationParams } from '@/types/api/Applications/prevnextApplications';
import { appClient } from '@/services';
import { useSnackbar } from '@/hooks/useSnackbar';

export const RecessEdit: FC = () => {
  const match = useMatch('/applications/:unitType/:applicationId/recess/edit');
  const unitType = match?.params.unitType || '';
  const applicationId = Number(useParams().applicationId) || 0;
  const [applicationData, setApplicationData] = useState<FindApplicationByID>();
  const [userData, setUserData] = useState<MemberById>();
  const [searchParams] = useSearchParams();
  const [query] = useState<PrevnextApplicationParams>(
    searchParams as PrevnextApplicationParams
  );
  const switchRecessReason = (value: string) => {
    switch (value) {
      case '休職':
        return 'LEAVE_ABSENCE';
      case '家庭の事情':
        return 'FAMILY_MATTERS';
      case '経済的理由':
        return 'ECONOMIC';
      case 'その他（理由を記入）':
        return 'OTHER';
      default:
        return undefined;
    }
  };
  const { data: bottomData } = usePrevnextApplication(applicationId, query);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const onSubmit: SubmitHandler<Inputs> = async (d: Inputs) => {
    try {
      const applicationRecessBody = {
        reason: switchRecessReason(d.reason),
      } as {
        reason?: 'OTHER' | 'LEAVE_ABSENCE' | 'FAMILY_MATTERS' | 'ECONOMIC';
        otherReasons: string;
      };

      applicationRecessBody.otherReasons = d.otherReasons;
      const res = await appClient.applications.updateApplication(
        applicationId,
        {
          applicationId,
          applicationRecess: applicationRecessBody,
        }
      );

      if (applicationData)
        navigate(
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `/associations/${applicationData?.associationCd}/applications/${applicationData?.applicationId}/recess`
        );
    } catch (e) {
      // エラー処理
      showSnackbar((e as ErrorResponse).data.message);
    }
    if (applicationId)
      navigate(`/applications/${unitType}/${applicationId}/recess`);
  };

  const getApplicationData = async () => {
    try {
      const res = await appClient.applications.findApplicationById(
        applicationId
      );
      setApplicationData(res);
    } catch (e) {
      console.log(e);
    }
  };

  const getUserData = async () => {
    try {
      if (applicationData?.memberId && applicationData.memberId !== 0) {
        const res = await appClient.members.findMember(
          applicationData?.memberId
        );
        setUserData(res);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    void getApplicationData();
  }, [applicationId]);

  useEffect(() => {
    void getUserData();
  }, [applicationData]);

  if (applicationData && userData) {
    return (
      <ApplicationsRecessEdit
        application={applicationData}
        unitType={unitType}
        userData={userData}
        bottomData={bottomData}
        onSubmit={onSubmit}
      />
    );
  }
  return null;
};
