import { FC } from 'react';
import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { styled, Box, Stack, Typography } from '@mui/material';
import { ApplicationTemporary } from '@/types/api/Applications/applications';
import { theme } from '@/theme';
import { formatDateTime } from '@/utils/dateFunction';

interface TemporaryProps {
  application: ApplicationTemporary;
}

const MuiBox = styled(Box)({
  display: 'flex',
  gap: '16px',
  borderBottom: '1px solid',
  borderColor: theme.palette.system['separator-light'],
  paddingBottom: '16px',
});

const Label = styled(Typography)({
  ...theme.typography['body-main/bold'],
  minWidth: '116px',
  display: 'flex',
  flexDirection: 'column',
});

export const ApplicationTemporaryDetail: FC<TemporaryProps> = ({
  application,
}) => (
  <Box height="100%">
    <Stack spacing={4} px={5} py={4}>
      <NormalLink href="/applications/temporary">臨時買付一覧に戻る</NormalLink>
      <Stack spacing={2}>
        <MuiBox>
          <Label>送信日時</Label>
          <Box>
            {application.created ? formatDateTime(application.created) : ''}
          </Box>
        </MuiBox>
        <MuiBox>
          <Label>詳細</Label>
          <Box>{application.detail}</Box>
        </MuiBox>
      </Stack>
    </Stack>
  </Box>
);
