/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { ApplicationsTemporaryIndex } from '@/components/organisms/Applications/TemporaryIndex';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { format } from 'date-fns';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import {
  pageState,
  perPageSizeState,
  totalPageState,
  totalSizeState,
} from '@/recoil/pagination';
import { useApplicationsTemporaryEvents } from '@/services/customHooks/useApplicationsTemporary';

const initialState = {
  associationCd: '',
  yearMonth: Number(format(new Date(), 'yyyyMM')),
  eventType: 'SPECIAL',
  page: 1,
  perPageSize: 15,
  sortKey: 'CREATED_ASC' as 'CREATED_ASC' | 'CREATED_DESC',
  keyword: '',
};

export const TemporaryIndex = () => {
  const { associationCd = '' } = useRecoilValue(SelectedAssociationState);
  const [keywordValue, setKeywordValue] = useState<string>('');
  // pages
  const [page, setPage] = useRecoilState(pageState);
  const [, setPerPageSize] = useRecoilState(perPageSizeState);
  const [, setTotalPage] = useRecoilState(totalPageState);
  const [, setTotalSize] = useRecoilState(totalSizeState);
  const [sortKey, setSortKey] = useState<'CREATED_ASC' | 'CREATED_DESC'>(
    'CREATED_ASC'
  );

  const [applicationsParams, setApplicationsParams] = useState({
    ...initialState,
    associationCd,
  });

  const { data: applicationsTemporary, error: applicationsTemporaryError } =
    useApplicationsTemporaryEvents(applicationsParams);

  const onClickSearch = (value: string) => {
    setKeywordValue(value);
    // 検索
    const body = {
      associationCd,
      yearMonth: Number(format(new Date(), 'yyyyMM')),
      eventType: 'MONTHLY',
      keyword: value,
      page: 1,
      perPageSize: 20,
      sortKey,
    };
    setApplicationsParams(body);
  };

  useEffect(() => {
    if (applicationsTemporary) {
      setPage(applicationsTemporary.currentPage || 1);
      setPerPageSize(applicationsTemporary.perPageSize || 10);
      setTotalPage(applicationsTemporary.totalPage || 0);
      setTotalSize(applicationsTemporary.totalSize || 0);
    }
  }, [applicationsTemporary]);

  const handlePageChange = useCallback(() => {
    setApplicationsParams({
      ...applicationsParams,
      page,
    });
  }, [page]);

  useEffect(() => {
    onClickSearch(keywordValue);
  }, [sortKey]);

  useEffect(() => {
    void handlePageChange();
  }, [handlePageChange]);

  return (
    <ApplicationsTemporaryIndex
      keywordValue={keywordValue}
      onClickSearch={onClickSearch}
      type="temporary"
      data={applicationsTemporary?.applications || []}
      applicationsTemporaryError={applicationsTemporaryError}
      sortKey={sortKey}
      updateSortKey={(value) => setSortKey(value)}
    />
  );
};
