/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ApplicationsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * 【API-B-4101】申請履歴一覧取得
   * 申請履歴一覧を取得する
   *
   * @param associationCd 持株会コード
   *
   * @param memberId 持株会員ID
   *
   * @param keyword キーワード：部分一致（会員コード・会員名・社員コード）
   *
   * @param yearMonth 取得年月
   *
   * @param eventType イベント種別
   * - MONTHLY: 月例（新規会員登録・会員情報変更登録除く）
   * - BONUS: 賞与（新規会員登録・会員情報変更登録除く）
   * - DIVIDEND: 配当
   * - SPECIAL: 臨時
   * - ADMISSION: 新規会員登録
   * - USER: 会員情報変更
   *
   * @param applicationType 申請種別
   * - MONTHLY_UNIT: 口数変更(月例)
   * - BONUS_UNIT: 口数変更(賞与)
   * - PART: 一部引出
   * - RECESS: 休会
   * - RESUME: 再開
   * - WITHDRAWAL: 退会
   * - ADMISSION: 新規会員登録
   * - USER: 会員情報変更
   * - TEMPORARY: 臨時
   *
   * @param applicationStatus 申請ステータス
   * - UNAPPROVED: 未承認
   * - APPROVED: 承認済
   * - REJECTED: 却下
   *
   * @param page 取得ページ番号
   *
   * @param perPageSize 1ページあたり件数
   *
   * @param sortKey ソートキー
   * - APPLICATION_ID_ASC: 申請ID昇順
   * - APPLICATION_ID_DESC: 申請ID降順
   * - APPLICATION_TYPE_ASC: 申請種別昇順
   * - APPLICATION_TYPE_DESC: 申請種別降順
   * - MEMBER_CD_ASC: 会員コード昇順
   * - MEMBER_CD_DESC: 会員コード降順
   * - MEMBER_NAME_ASC: 会員名昇順
   * - MEMBER_NAME_DESC: 会員名降順
   * - EMPLOYEE_CD_ASC: 社員コード昇順
   * - EMPLOYEE_CD_DESC: 社員コード降順
   * - APPLICATION_DATE_ASC: 申請日時昇順
   * - APPLICATION_DATE_DESC: 申請日時降順
   * - APPLICATION_STATUS_ASC: 申請ステータス昇順
   * - APPLICATION_STATUS_DESC: 申請ステータス降順
   *
   * @returns any applications response
   * @throws ApiError
   */
  public listApplications(
    associationCd?: string,
    memberId?: string,
    keyword?: string,
    yearMonth?: number,
    eventType?:
      | 'MONTHLY'
      | 'BONUS'
      | 'DIVIDEND'
      | 'SPECIAL'
      | 'ADMISSION'
      | 'USER',
    applicationType?:
      | 'MONTHLY_UNIT'
      | 'BONUS_UNIT'
      | 'PART'
      | 'RECESS'
      | 'RESUME'
      | 'WITHDRAWAL'
      | 'ADMISSION'
      | 'USER'
      | 'TEMPORARY',
    applicationStatus?: 'UNAPPROVED' | 'APPROVED' | 'REJECTED',
    page?: number,
    perPageSize?: number,
    sortKey?:
      | 'APPLICATION_ID_ASC'
      | 'APPLICATION_ID_DESC'
      | 'APPLICATION_TYPE_ASC'
      | 'APPLICATION_TYPE_DESC'
      | 'MEMBER_CD_ASC'
      | 'MEMBER_CD_DESC'
      | 'MEMBER_NAME_ASC'
      | 'MEMBER_NAME_DESC'
      | 'EMPLOYEE_CD_ASC'
      | 'EMPLOYEE_CD_DESC'
      | 'APPLICATION_DATE_ASC'
      | 'APPLICATION_DATE_DESC'
      | 'APPLICATION_STATUS_ASC'
      | 'APPLICATION_STATUS_DESC'
  ): CancelablePromise<
    {
      applications?: Array<{
        /**
         * 申請書ID
         */
        applicationId?: number;
        /**
         * メンバーID
         */
        memberId?: number;
        /**
         * 会員CD
         */
        memberCd?: string;
        /**
         * メンバー名
         */
        memberName?: string;
        /**
         * 社員CD
         */
        employeeCd?: string;
        /**
         * 持株会コード
         */
        associationCd?: string;
        /**
         * イベント№
         */
        eventNo?: string;
        /**
         * 申請種別
         * - MONTHLY_UNIT: 口数変更
         * - BONUS_UNIT: 口数変更(非推奨)
         * - PART: 一部引出
         * - RECESS: 休会
         * - RESUME: 再開
         * - WITHDRAWAL: 退会
         * - ADMISSION: 新規会員登録
         * - USER: 会員情報変更
         * - TEMPORARY: 臨時
         *
         */
        applicationType?:
          | 'MONTHLY_UNIT'
          | 'BONUS_UNIT'
          | 'PART'
          | 'RECESS'
          | 'RESUME'
          | 'WITHDRAWAL'
          | 'ADMISSION'
          | 'USER'
          | 'TEMPORARY';
        /**
         * 申請日付
         */
        applicationDate?: string;
        /**
         * 申請ステータス
         * - PREPARATION: 入力中
         * - APPLYING: 申請中
         * - OFFICER_APPROVED: 事務局承認済
         * - BACKOFFICER_APPROVED: 制度G承認済（STARⅣ処理中）
         * - COMPLETED: 完了（システム反映済み）
         * - OFFICER_REJECTED: 却下（事務局）
         * - BACKOFFICER_REJECTED: 却下（制度G）
         * - CANCEL: 申請取り消し（会員）
         *
         */
        applicationStatus?:
          | 'PREPARATION'
          | 'APPLYING'
          | 'OFFICER_APPROVED'
          | 'BACKOFFICER_APPROVED'
          | 'COMPLETED'
          | 'OFFICER_REJECTED'
          | 'BACKOFFICER_REJECTED'
          | 'CANCEL';
        /**
         * 却下コメント
         */
        rejectComment?: string;
        /**
         * 事務局承認・却下者名
         */
        officeJudgeUserName?: string;
        /**
         * 制度G承認・却下者名
         */
        backofficeJudgeUserName?: string;
        /**
         * 代理申請者名
         */
        proxyApplicantUserName?: string;
        /**
         * 事務局修正者名
         */
        officeUpdateUserName?: string;
        /**
         * 持株制度G修正者名
         */
        backofficeUpdateUserName?: string;
        /**
         * 事務局承認日時
         */
        officeJudgeDt?: string;
        /**
         * 制度G承認日時
         */
        backofficeJudgeDt?: string;
      }>;
    } & {
      /**
       * 現在ページ番号
       */
      currentPage?: number;
      /**
       * 総ページ数
       */
      totalPage?: number;
      /**
       * １ページあたり件数
       */
      perPageSize?: number;
      /**
       * 総件数
       */
      totalSize?: number;
    }
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/applications',
      query: {
        associationCd: associationCd,
        memberId: memberId,
        keyword: keyword,
        yearMonth: yearMonth,
        eventType: eventType,
        applicationType: applicationType,
        applicationStatus: applicationStatus,
        page: page,
        perPageSize: perPageSize,
        sortKey: sortKey,
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-4103】申請書を登録する（代理申請・臨時イベント申請）
   * 申請書を登録する（代理申請・臨時イベント申請）
   *
   * @param requestBody application request
   * @returns any application response
   * @throws ApiError
   */
  public createApplication(requestBody: {
    /**
     * メンバーID
     */
    memberId?: number;
    /**
     * 持株会コード
     */
    associationCd?: string;
    /**
     * イベントNO
     */
    eventNo?: string;
    /**
     * 申請種別
     * - MONTHLY_UNIT: 口数変更
     * - BONUS_UNIT: 口数変更(非推奨)
     * - PART: 一部引出
     * - RECESS: 休会
     * - RESUME: 再開
     * - WITHDRAWAL: 退会
     * - ADMISSION: 新規会員登録
     * - USER: 会員情報変更
     * - TEMPORARY: 臨時
     *
     */
    applicationType?:
      | 'MONTHLY_UNIT'
      | 'BONUS_UNIT'
      | 'PART'
      | 'RECESS'
      | 'RESUME'
      | 'WITHDRAWAL'
      | 'ADMISSION'
      | 'USER'
      | 'TEMPORARY';
    applicationBonusUnit?: {
      /**
       * 賞与拠出有無区分
       */
      isBonusUnit?: boolean;
      /**
       * 賞与拠出口数
       */
      bonusUnit?: number;
      /**
       * 賞与_口数(x倍)
       */
      bonusRate?: number;
    };
    applicationMonthlyUnit?: {
      /**
       * 月例拠出口数
       */
      monthlyUnit?: number;
      /**
       * 賞与拠出有無
       */
      isBonusUnit?: boolean;
      /**
       * 賞与拠出口数
       */
      bonusContributionUnit?: number;
      /**
       * 賞与_口数(x倍)
       */
      bonusRate?: number;
    };
    applicationPart?: {
      sharesNum?: number;
      leftSharesNum?: number;
    };
    applicationRecess?: {
      /**
       * 休会/退会理由
       * - LEAVE_ABSENCE: 休職/退職
       * - FAMILY_MATTERS: 家族理由
       * - ECONOMIC: 経済理由
       * - OTHER: その他
       *
       */
      reason?: 'LEAVE_ABSENCE' | 'FAMILY_MATTERS' | 'ECONOMIC' | 'OTHER';
      /**
       * 休会理由(その他)
       */
      otherReasons?: string;
    };
    applicationWithdrawal?: {
      /**
       * 休会/退会理由
       * - LEAVE_ABSENCE: 休職/退職
       * - FAMILY_MATTERS: 家族理由
       * - ECONOMIC: 経済理由
       * - OTHER: その他
       *
       */
      reason?: 'LEAVE_ABSENCE' | 'FAMILY_MATTERS' | 'ECONOMIC' | 'OTHER';
      /**
       * 退会理由(その他)
       */
      otherReasons?: string;
      generalAccount?: {
        /**
         * 金融機関コード
         *
         */
        institutionCode?: string;
        /**
         * 金融機関名
         *
         */
        institutionName?: string;
        /**
         * 支店コード
         *
         */
        branchCode?: string;
        /**
         * 支店名
         *
         */
        branchName?: string;
        /**
         * アカウント種別
         * - SAVINGS: 普通
         * - CURRENT: 当座
         * - OTHER: 貯蓄
         *
         */
        accountType?: 'SAVINGS' | 'CURRENT' | 'OTHER';
        /**
         * 口座番号
         *
         */
        accountNumber?: string;
        /**
         * 口座名義人
         */
        accountName?: string;
      };
      /**
       * 買増有無
       */
      isAdditionalOption?: boolean;
      /**
       * 振込金額
       */
      transferAmount?: number;
      /**
       * 備考
       */
      remarks?: string;
      /**
       * 金融機関種類
       * - YUCHO: ゆうちょ銀行
       * - OTHER: ゆうちょ銀行以外
       *
       */
      institutionType?: 'YUCHO' | 'OTHER';
      /**
       * 記号（ゆうちょ銀行）
       */
      accountYuchoSymbol?: string;
      /**
       * 番号(ゆうちょ銀行)
       */
      accountYuchoNumber?: string;
    };
    applicationUser?: {
      /**
       * 社員コード
       */
      employeeCode?: string;
      /**
       * 生年月日
       */
      birthday?: string;
      /**
       * 氏名漢字
       */
      nameKanji?: string;
      /**
       * 氏名カナ
       */
      nameKana?: string;
      /**
       * 郵便番号
       */
      zipcode?: string;
      /**
       * 住所1
       */
      address1?: string;
      /**
       * 住所2
       */
      address2?: string;
      /**
       * 住所3
       */
      address3?: string;
      /**
       * 電話種類
       * - MOBILE：携帯
       * - HOME：自宅
       *
       */
      telType?: 'MOBILE' | 'HOME';
      /**
       * 電話番号
       */
      tel?: string;
      /**
       * 会員事業所コード
       */
      officeCd?: string;
      /**
       * 会員所属コード
       */
      affiliationCd?: string;
    };
  }): CancelablePromise<{
    /**
     * 申請書ID
     */
    applicationId?: number;
    /**
     * メンバーID
     */
    memberId?: number;
    /**
     * 持株会コード
     */
    associationCd?: string;
    /**
     * イベント№
     */
    eventNo?: string;
    /**
     * 申請種別
     * - MONTHLY_UNIT: 口数変更
     * - BONUS_UNIT: 口数変更(非推奨)
     * - PART: 一部引出
     * - RECESS: 休会
     * - RESUME: 再開
     * - WITHDRAWAL: 退会
     * - ADMISSION: 新規会員登録
     * - USER: 会員情報変更
     * - TEMPORARY: 臨時
     *
     */
    applicationType?:
      | 'MONTHLY_UNIT'
      | 'BONUS_UNIT'
      | 'PART'
      | 'RECESS'
      | 'RESUME'
      | 'WITHDRAWAL'
      | 'ADMISSION'
      | 'USER'
      | 'TEMPORARY';
    /**
     * 申請日付
     */
    applicationDate?: string;
    /**
     * 申請ステータス
     * - PREPARATION: 入力中
     * - APPLYING: 申請中
     * - OFFICER_APPROVED: 事務局承認済
     * - BACKOFFICER_APPROVED: 制度G承認済（STARⅣ処理中）
     * - COMPLETED: 完了（システム反映済み）
     * - OFFICER_REJECTED: 却下（事務局）
     * - BACKOFFICER_REJECTED: 却下（制度G）
     * - CANCEL: 申請取り消し（会員）
     *
     */
    applicationStatus?:
      | 'PREPARATION'
      | 'APPLYING'
      | 'OFFICER_APPROVED'
      | 'BACKOFFICER_APPROVED'
      | 'COMPLETED'
      | 'OFFICER_REJECTED'
      | 'BACKOFFICER_REJECTED'
      | 'CANCEL';
    /**
     * 却下コメント
     */
    rejectComment?: string;
    /**
     * 承認・却下者名
     */
    judgeUserName?: string;
    /**
     * 代理申請者名
     */
    proxyApplicantUserName?: string;
    /**
     * 事務局修正者名
     */
    officeUpdateUserName?: string;
    /**
     * 持株制度G修正者名
     */
    backofficeUpdateUserName?: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/applications',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-4102】申請書取得
   * 申請書を取得する
   *
   * @param applicationId ID of application to fetch
   * @param fields 取得field \
   * fields1,fields2,fields3
   *
   * @returns any application part response
   * @throws ApiError
   */
  public findApplicationById(
    applicationId: number,
    fields?: string
  ): CancelablePromise<
    {
      /**
       * 申請書ID
       */
      applicationId?: number;
      /**
       * メンバーID
       */
      memberId?: number;
      /**
       * 会員CD
       */
      memberCd?: string;
      /**
       * メンバー名
       */
      memberName?: string;
      /**
       * 持株会コード
       */
      associationCd?: string;
      /**
       * イベント№
       */
      eventNo?: string;
      /**
       * 申請種別
       * - MONTHLY_UNIT: 口数変更
       * - BONUS_UNIT: 口数変更(非推奨)
       * - PART: 一部引出
       * - RECESS: 休会
       * - RESUME: 再開
       * - WITHDRAWAL: 退会
       * - ADMISSION: 新規会員登録
       * - USER: 会員情報変更
       * - TEMPORARY: 臨時
       *
       */
      applicationType?:
        | 'MONTHLY_UNIT'
        | 'BONUS_UNIT'
        | 'PART'
        | 'RECESS'
        | 'RESUME'
        | 'WITHDRAWAL'
        | 'ADMISSION'
        | 'USER'
        | 'TEMPORARY';
      /**
       * 申請日付
       */
      applicationDate?: string;
      /**
       * 申請ステータス
       * - PREPARATION: 入力中
       * - APPLYING: 申請中
       * - OFFICER_APPROVED: 事務局承認済
       * - BACKOFFICER_APPROVED: 制度G承認済（STARⅣ処理中）
       * - COMPLETED: 完了（システム反映済み）
       * - OFFICER_REJECTED: 却下（事務局）
       * - BACKOFFICER_REJECTED: 却下（制度G）
       * - CANCEL: 申請取り消し（会員）
       *
       */
      applicationStatus?:
        | 'PREPARATION'
        | 'APPLYING'
        | 'OFFICER_APPROVED'
        | 'BACKOFFICER_APPROVED'
        | 'COMPLETED'
        | 'OFFICER_REJECTED'
        | 'BACKOFFICER_REJECTED'
        | 'CANCEL';
      /**
       * 却下コメント
       */
      rejectComment?: string;
      /**
       * 事務局承認・却下者名
       */
      officeJudgeUserName?: string;
      /**
       * 制度G承認・却下者名
       */
      backofficeJudgeUserName?: string;
      /**
       * 代理申請者名
       */
      proxyApplicantUserName?: string;
      /**
       * 事務局修正者名
       */
      officeUpdateUserName?: string;
      /**
       * 持株制度G修正者名
       */
      backofficeUpdateUserName?: string;
      /**
       * 事務局承認日時
       */
      officeJudgeDt?: string;
      /**
       * 制度G承認日時
       */
      backofficeJudgeDt?: string;
    } & {
      applicationBonusUnit?: {
        /**
         * 賞与拠出有無区分
         */
        isBonusUnit?: boolean;
        /**
         * 賞与拠出口数
         */
        bonusUnit?: number;
        /**
         * 賞与_口数(x倍)
         */
        bonusRate?: number;
      };
      applicationMonthlyUnit?: {
        /**
         * 月例(給与)_口数
         */
        monthlyUnit?: number;
        /**
         * 変更前月例口数
         */
        preMonthlyUnit?: number;
        /**
         * 賞与拠出有無区分
         */
        isBonusUnit?: boolean;
        /**
         * 変更前賞与拠出有無区分
         */
        preIsBonusUnit?: boolean;
        /**
         * 賞与拠出口数
         */
        bonusContributionUnit?: number;
        /**
         * 変更前賞与拠出口数
         */
        preBonusContributionUnit?: number;
        /**
         * 賞与_口数(x倍)
         */
        bonusRate?: number;
        /**
         * 変更前賞与_口数(x倍)
         */
        preBonusRate?: number;
        /**
         * 月例口数変更フラグ
         */
        monthlyChangeFlg?: boolean;
        /**
         * 賞与口数変更フラグ
         */
        bonusChangeFlg?: boolean;
      };
      applicationNewUser?: {
        /**
         * 会員コード
         */
        memberCd?: string;
        /**
         * 社員コード
         */
        employeeCode?: string;
        /**
         * メールアドレス
         */
        email?: string;
        /**
         * 氏名（漢字）
         */
        nameKanji?: string;
        /**
         * 氏名（カナ）
         */
        nameKana?: string;
        /**
         * 生年月日
         */
        birthday?: string;
        /**
         * 郵便番号
         */
        zipcode?: string;
        /**
         * 都道府県名
         */
        address1?: string;
        /**
         * 市区町村
         */
        address2?: string;
        /**
         * 番地・その他住所
         */
        address3?: string;
        /**
         * 電話種類
         * - MOBILE：携帯
         * - HOME：自宅
         *
         */
        telType?: 'MOBILE' | 'HOME';
        /**
         * 電話番号
         */
        tel?: string;
        /**
         * 月例(給与)_口数
         */
        monthlyUnit?: number;
        /**
         * 賞与拠出有無区分
         */
        isBonusUnit?: boolean;
        /**
         * 賞与拠出口数
         */
        bonusUnit?: number;
        /**
         * 賞与_口数(x倍)
         */
        bonusRate?: number;
        /**
         * 会員事業所コード
         */
        officeCd?: string;
        /**
         * 会員所属コード
         */
        affiliationCd?: string;
      };
      applicationPart?: {
        sharesNum?: number;
        leftSharesNum?: number;
      };
      applicationRecess?: {
        /**
         * 休会/退会理由
         * - LEAVE_ABSENCE: 休職/退職
         * - FAMILY_MATTERS: 家族理由
         * - ECONOMIC: 経済理由
         * - OTHER: その他
         *
         */
        reason?: 'LEAVE_ABSENCE' | 'FAMILY_MATTERS' | 'ECONOMIC' | 'OTHER';
        /**
         * 休会理由(その他)
         */
        otherReasons?: string;
      };
      applicationResume?: {
        /**
         * 所持株総数
         */
        sharesNum?: number;
        /**
         * 1口あたり金額
         */
        costPerUnit?: number;
        /**
         * 月例(給与)_口数
         */
        monthlyUnit?: number;
        /**
         * 賞与_拠出有無
         */
        bonusContributionFlg?: boolean;
        /**
         * 賞与_口数
         */
        bonusUnit?: number;
        /**
         * 賞与_口数(x倍)
         */
        bonusRate?: number;
      };
      applicationUser?: {
        /**
         * 会員コード
         */
        userCode?: string;
        /**
         * 社員コード
         */
        employeeCode?: string;
        /**
         * 氏名（漢字）
         */
        nameKanji?: string;
        /**
         * 氏名（カナ）
         */
        nameKana?: string;
        /**
         * 郵便番号
         */
        zipcode?: string;
        /**
         * 都道府県名
         */
        address1?: string;
        /**
         * 市区町村
         */
        address2?: string;
        /**
         * 番地・その他住所
         */
        address3?: string;
        /**
         * 電話番号
         */
        tel?: string;
        /**
         * 電話種類
         * - MOBILE：携帯
         * - HOME：自宅
         *
         */
        telType?: 'MOBILE' | 'HOME';
        /**
         * 生年月日
         */
        birthday?: string;
        /**
         * 会員事業所コード
         */
        officeCd?: string;
        /**
         * 会員所属コード
         */
        affiliationCd?: string;
      };
      applicationWithdrawal?: {
        /**
         * 休会/退会理由
         * - LEAVE_ABSENCE: 休職/退職
         * - FAMILY_MATTERS: 家族理由
         * - ECONOMIC: 経済理由
         * - OTHER: その他
         *
         */
        reason?: 'LEAVE_ABSENCE' | 'FAMILY_MATTERS' | 'ECONOMIC' | 'OTHER';
        /**
         * 退会理由(その他)
         */
        otherReasons?: string;
        generalAccount?: {
          /**
           * 金融機関コード
           *
           */
          institutionCode?: string;
          /**
           * 金融機関名
           *
           */
          institutionName?: string;
          /**
           * 支店コード
           *
           */
          branchCode?: string;
          /**
           * 支店名
           *
           */
          branchName?: string;
          /**
           * アカウント種別
           * - SAVINGS: 普通
           * - CURRENT: 当座
           * - OTHER: 貯蓄
           *
           */
          accountType?: 'SAVINGS' | 'CURRENT' | 'OTHER';
          /**
           * 口座番号
           *
           */
          accountNumber?: string;
          /**
           * 口座名義人
           */
          accountName?: string;
        };
        /**
         * 買増有無
         */
        isAdditionalOption?: boolean;
        /**
         * 振込金額
         */
        transferAmount?: number;
        /**
         * 備考
         */
        remarks?: string;
        /**
         * 金融機関種類
         * - YUCHO: ゆうちょ銀行
         * - OTHER: ゆうちょ銀行以外
         *
         */
        institutionType?: 'YUCHO' | 'OTHER';
        /**
         * 記号
         */
        symbol?: string;
        /**
         * 番号
         */
        number?: string;
        /**
         * 買増株数
         */
        addPurchaseSharesNum?: number;
      };
    }
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/applications/{applicationId}',
      path: {
        applicationId: applicationId,
      },
      query: {
        fields: fields,
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-4104】申請書を更新する
   * 申請書を更新する
   *
   * @param applicationId ID of application to fetch
   * @param requestBody application request
   * @returns any application response
   * @throws ApiError
   */
  public updateApplication(
    applicationId: number,
    requestBody: {
      /**
       * 申請書ID
       */
      applicationId?: number;
      applicationBonusUnit?: {
        /**
         * 賞与拠出有無区分
         */
        isBonusUnit?: boolean;
        /**
         * 賞与拠出口数
         */
        bonusUnit?: number;
        /**
         * 賞与_口数(x倍)
         */
        bonusRate?: number;
      };
      applicationMonthlyUnit?: {
        /**
         * 月例拠出口数
         */
        monthlyUnit?: number;
        /**
         * 賞与拠出有無
         */
        isBonusUnit?: boolean;
        /**
         * 賞与拠出口数
         */
        bonusContributionUnit?: number;
        /**
         * 賞与_口数(x倍)
         */
        bonusRate?: number;
      };
      applicationNewUser?: {
        /**
         * 社員コード
         */
        employeeCode?: string;
        /**
         * 生年月日
         */
        birthday?: string;
        /**
         * 氏名（漢字）
         */
        nameKanji?: string;
        /**
         * 氏名（カナ）
         */
        nameKana?: string;
        /**
         * 郵便番号
         */
        zipcode?: string;
        /**
         * 都道府県名
         */
        address1?: string;
        /**
         * 市区町村
         */
        address2?: string;
        /**
         * 番地・その他住所
         */
        address3?: string;
        /**
         * 電話番号
         */
        tel?: string;
        /**
         * 電話種類
         * - MOBILE：携帯
         * - HOME：自宅
         *
         */
        telType?: 'MOBILE' | 'HOME';
        /**
         * 月例(給与)_口数
         */
        monthlyUnit?: number;
        /**
         * 賞与拠出有無区分
         */
        isBonusUnit?: boolean;
        /**
         * 賞与拠出口数
         */
        bonusUnit?: number;
        /**
         * 賞与_口数(x倍)
         */
        bonusRate?: number;
        /**
         * 会員事業所コード
         */
        officeCd?: string;
        /**
         * 会員所属コード
         */
        affiliationCd?: string;
      };
      applicationPart?: {
        sharesNum?: number;
        leftSharesNum?: number;
      };
      applicationRecess?: {
        /**
         * 休会/退会理由
         * - LEAVE_ABSENCE: 休職/退職
         * - FAMILY_MATTERS: 家族理由
         * - ECONOMIC: 経済理由
         * - OTHER: その他
         *
         */
        reason?: 'LEAVE_ABSENCE' | 'FAMILY_MATTERS' | 'ECONOMIC' | 'OTHER';
        /**
         * 休会理由(その他)
         */
        otherReasons?: string;
      };
      applicationUser?: {
        /**
         * 社員コード
         */
        employeeCode?: string;
        /**
         * 生年月日
         */
        birthday?: string;
        /**
         * 氏名（漢字）
         */
        nameKanji?: string;
        /**
         * 氏名（カナ）
         */
        nameKana?: string;
        /**
         * 郵便番号
         */
        zipcode?: string;
        /**
         * 都道府県名
         */
        address1?: string;
        /**
         * 市区町村
         */
        address2?: string;
        /**
         * 番地・その他住所
         */
        address3?: string;
        /**
         * 電話番号
         */
        tel?: string;
        /**
         * 電話種類
         * - MOBILE：携帯
         * - HOME：自宅
         *
         */
        telType?: 'MOBILE' | 'HOME';
        /**
         * 会員事業所コード
         */
        officeCd?: string;
        /**
         * 会員所属コード
         */
        affiliationCd?: string;
      };
      applicationWithdrawal?: {
        /**
         * 休会/退会理由
         * - LEAVE_ABSENCE: 休職/退職
         * - FAMILY_MATTERS: 家族理由
         * - ECONOMIC: 経済理由
         * - OTHER: その他
         *
         */
        reason?: 'LEAVE_ABSENCE' | 'FAMILY_MATTERS' | 'ECONOMIC' | 'OTHER';
        /**
         * 退会理由(その他)
         */
        otherReasons?: string;
        generalAccount?: {
          /**
           * 金融機関コード
           *
           */
          institutionCode?: string;
          /**
           * 金融機関名
           *
           */
          institutionName?: string;
          /**
           * 支店コード
           *
           */
          branchCode?: string;
          /**
           * 支店名
           *
           */
          branchName?: string;
          /**
           * アカウント種別
           * - SAVINGS: 普通
           * - CURRENT: 当座
           * - OTHER: 貯蓄
           *
           */
          accountType?: 'SAVINGS' | 'CURRENT' | 'OTHER';
          /**
           * 口座番号
           *
           */
          accountNumber?: string;
          /**
           * 口座名義人
           */
          accountName?: string;
        };
        /**
         * 買増有無
         */
        isAdditionalOption?: boolean;
        /**
         * 振込金額
         */
        transferAmount?: number;
        /**
         * 備考
         */
        remarks?: string;
        /**
         * 金融機関種類
         * - YUCHO: ゆうちょ銀行
         * - OTHER: ゆうちょ銀行以外
         *
         */
        institutionType?: 'YUCHO' | 'OTHER';
        /**
         * 記号（ゆうちょ銀行）
         */
        accountYuchoSymbol?: string;
        /**
         * 番号(ゆうちょ銀行)
         */
        accountYuchoNumber?: string;
      };
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/applications/{applicationId}',
      path: {
        applicationId: applicationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-4106】申請書の前後の申請書情報を取得
   * 申請書の前後の申請書情報を取得する
   *
   * @param applicationId ID of application to fetch
   * @param associationCd 持株会コード
   *
   * @param memberId 持株会員ID
   *
   * @param keyword キーワード：部分一致（会員コード・会員名）
   *
   * @param yearMonth 取得年月
   *
   * @param eventType イベント種別
   * - MONTHLY: 月例（新規会員登録・会員情報変更登録除く）
   * - BONUS: 賞与（新規会員登録・会員情報変更登録除く）
   * - DIVIDEND: 配当
   * - SPECIAL: 臨時
   * - ADMISSION: 新規会員登録
   * - USER: 会員情報変更
   *
   * @param applicationType 申請種別
   * - MONTHLY_UNIT: 口数変更(月例)
   * - BONUS_UNIT: 口数変更(賞与)
   * - PART: 一部引出
   * - RECESS: 休会
   * - RESUME: 再開
   * - WITHDRAWAL: 退会
   * - ADMISSION: 新規会員登録
   * - USER: 会員情報変更
   * - TEMPORARY: 臨時
   *
   * @param applicationStatus 申請ステータス
   * - UNAPPROVED: 未承認
   * - APPROVED: 承認済
   * - REJECTED: 却下
   *
   * @param sortKey ソートキー
   * - APPLICATION_ID_ASC: 申請ID昇順
   * - APPLICATION_ID_DESC: 申請ID降順
   * - APPLICATION_TYPE_ASC: 申請種別昇順
   * - APPLICATION_TYPE_DESC: 申請種別降順
   * - MEMBER_CD_ASC: 会員コード昇順
   * - MEMBER_CD_DESC: 会員コード降順
   * - MEMBER_NAME_ASC: 会員名昇順
   * - MEMBER_NAME_DESC: 会員名降順
   * - APPLICATION_DATE_ASC: 申請日時昇順
   * - APPLICATION_DATE_DESC: 申請日時降順
   * - APPLICATION_STATUS_ASC: 申請ステータス昇順
   * - APPLICATION_STATUS_DESC: 申請ステータス降順
   *
   * @returns any application part response
   * @throws ApiError
   */
  public findPrevNextApplicationById(
    applicationId: number,
    associationCd?: string,
    memberId?: string,
    keyword?: string,
    yearMonth?: number,
    eventType?:
      | 'MONTHLY'
      | 'BONUS'
      | 'DIVIDEND'
      | 'SPECIAL'
      | 'ADMISSION'
      | 'USER',
    applicationType?:
      | 'MONTHLY_UNIT'
      | 'BONUS_UNIT'
      | 'PART'
      | 'RECESS'
      | 'RESUME'
      | 'WITHDRAWAL'
      | 'ADMISSION'
      | 'USER'
      | 'TEMPORARY',
    applicationStatus?: 'UNAPPROVED' | 'APPROVED' | 'REJECTED',
    sortKey?:
      | 'APPLICATION_ID_ASC'
      | 'APPLICATION_ID_DESC'
      | 'APPLICATION_TYPE_ASC'
      | 'APPLICATION_TYPE_DESC'
      | 'MEMBER_CD_ASC'
      | 'MEMBER_CD_DESC'
      | 'MEMBER_NAME_ASC'
      | 'MEMBER_NAME_DESC'
      | 'APPLICATION_DATE_ASC'
      | 'APPLICATION_DATE_DESC'
      | 'APPLICATION_STATUS_ASC'
      | 'APPLICATION_STATUS_DESC'
  ): CancelablePromise<
    {
      applicationPrev?: Array<{
        /**
         * 申請書ID
         */
        applicationId?: number;
        /**
         * 申請種別
         * - MONTHLY_UNIT: 口数変更
         * - BONUS_UNIT: 口数変更(非推奨)
         * - PART: 一部引出
         * - RECESS: 休会
         * - RESUME: 再開
         * - WITHDRAWAL: 退会
         * - ADMISSION: 新規会員登録
         * - USER: 会員情報変更
         * - TEMPORARY: 臨時
         *
         */
        applicationType?:
          | 'MONTHLY_UNIT'
          | 'BONUS_UNIT'
          | 'PART'
          | 'RECESS'
          | 'RESUME'
          | 'WITHDRAWAL'
          | 'ADMISSION'
          | 'USER'
          | 'TEMPORARY';
        /**
         * 申請日付
         */
        applicationDate?: string;
        /**
         * 申請ステータス
         * - PREPARATION: 入力中
         * - APPLYING: 申請中
         * - OFFICER_APPROVED: 事務局承認済
         * - BACKOFFICER_APPROVED: 制度G承認済（STARⅣ処理中）
         * - COMPLETED: 完了（システム反映済み）
         * - OFFICER_REJECTED: 却下（事務局）
         * - BACKOFFICER_REJECTED: 却下（制度G）
         * - CANCEL: 申請取り消し（会員）
         *
         */
        applicationStatus?:
          | 'PREPARATION'
          | 'APPLYING'
          | 'OFFICER_APPROVED'
          | 'BACKOFFICER_APPROVED'
          | 'COMPLETED'
          | 'OFFICER_REJECTED'
          | 'BACKOFFICER_REJECTED'
          | 'CANCEL';
      }>;
    } & {
      applicationNext?: Array<{
        /**
         * 申請書ID
         */
        applicationId?: number;
        /**
         * 申請種別
         * - MONTHLY_UNIT: 口数変更
         * - BONUS_UNIT: 口数変更(非推奨)
         * - PART: 一部引出
         * - RECESS: 休会
         * - RESUME: 再開
         * - WITHDRAWAL: 退会
         * - ADMISSION: 新規会員登録
         * - USER: 会員情報変更
         * - TEMPORARY: 臨時
         *
         */
        applicationType?:
          | 'MONTHLY_UNIT'
          | 'BONUS_UNIT'
          | 'PART'
          | 'RECESS'
          | 'RESUME'
          | 'WITHDRAWAL'
          | 'ADMISSION'
          | 'USER'
          | 'TEMPORARY';
        /**
         * 申請日付
         */
        applicationDate?: string;
        /**
         * 申請ステータス
         * - PREPARATION: 入力中
         * - APPLYING: 申請中
         * - OFFICER_APPROVED: 事務局承認済
         * - BACKOFFICER_APPROVED: 制度G承認済（STARⅣ処理中）
         * - COMPLETED: 完了（システム反映済み）
         * - OFFICER_REJECTED: 却下（事務局）
         * - BACKOFFICER_REJECTED: 却下（制度G）
         * - CANCEL: 申請取り消し（会員）
         *
         */
        applicationStatus?:
          | 'PREPARATION'
          | 'APPLYING'
          | 'OFFICER_APPROVED'
          | 'BACKOFFICER_APPROVED'
          | 'COMPLETED'
          | 'OFFICER_REJECTED'
          | 'BACKOFFICER_REJECTED'
          | 'CANCEL';
      }>;
    }
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/applications/{applicationId}/prevnext',
      path: {
        applicationId: applicationId,
      },
      query: {
        associationCd: associationCd,
        memberId: memberId,
        keyword: keyword,
        yearMonth: yearMonth,
        eventType: eventType,
        applicationType: applicationType,
        applicationStatus: applicationStatus,
        sortKey: sortKey,
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-4105】申請書の承認/却下
   * 申請書の承認/却下
   *
   * @param applicationId ID of application to fetch
   * @param approvalType 承認・却下
   * - APPROVAL: 承認
   * - REJECTED: 却下
   *
   * @param requestBody application approval request
   * @returns any application response
   * @throws ApiError
   */
  public updateApplicationApproval(
    applicationId: number,
    approvalType: 'APPROVAL' | 'REJECTED',
    requestBody: {
      /**
       * コメント
       */
      comment?: string;
      /**
       * 振込金額
       */
      transferAmount?: number;
      /**
       * 備考
       */
      remarks?: string;
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/applications/{applicationId}/{approvalType}',
      path: {
        applicationId: applicationId,
        approvalType: approvalType,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-4107】臨時詳細取得
   * 臨時詳細を取得する
   *
   * @param officeSpecialApplicationId 臨時申請ID
   * @returns any application special response
   * @throws ApiError
   */
  public findOfficeSpecialApplication(
    officeSpecialApplicationId: number
  ): CancelablePromise<{
    officeSpecialApplicationId?: number;
    /**
     * 持株会コード
     */
    associationCd?: string;
    /**
     * 詳細
     */
    detail?: string;
    /**
     * 送信日時
     */
    created?: string;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/applications/special/{officeSpecialApplicationId}',
      path: {
        officeSpecialApplicationId: officeSpecialApplicationId,
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-4108】臨時一覧
   * 臨時一覧の取得
   *
   * @param associationCd associationCd
   * @param keyword キーワード（会員）
   * @param page 取得ページ番号
   *
   * @param perPageSize 1ページあたり件数
   *
   * @param sortKey ソートキー
   * - CREATED_ASC: 送信日時昇順
   * - CREATED_DESC: 送信日時降順
   *
   * @returns any office special applications response
   * @throws ApiError
   */
  public listOfficeSpecialApplication(
    associationCd: string,
    keyword?: string,
    page?: number,
    perPageSize?: number,
    sortKey?: 'CREATED_ASC' | 'CREATED_DESC'
  ): CancelablePromise<
    {
      applications?: Array<{
        /**
         * 臨時ID
         */
        officeSpecialApplicationId?: number;
        /**
         * 持株会コード
         */
        associationCd?: string;
        /**
         * 詳細内容
         */
        detail?: string;
        /**
         * 送信日時
         */
        created?: string;
      }>;
    } & {
      /**
       * 現在ページ番号
       */
      currentPage?: number;
      /**
       * 総ページ数
       */
      totalPage?: number;
      /**
       * １ページあたり件数
       */
      perPageSize?: number;
      /**
       * 総件数
       */
      totalSize?: number;
    }
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/applications/special',
      query: {
        associationCd: associationCd,
        keyword: keyword,
        page: page,
        perPageSize: perPageSize,
        sortKey: sortKey,
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 臨時申請の登録
   * 臨時申請を新規登録する
   *
   * @param requestBody office special application request
   * @returns any office special application response
   * @throws ApiError
   */
  public createOfficeSpecialApplication(requestBody: {
    /**
     * 持株会コード
     */
    associationCd: string;
    /**
     * 詳細
     */
    detail: string;
  }): CancelablePromise<{
    /**
     * CognitoのSession
     */
    Session?: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/applications/special',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }
}
