/* eslint-disable @typescript-eslint/ban-ts-comment */
import useSWR from 'swr';
import { ApplicationsParams } from '@/types/api/Applications/prevnextApplications';
import {
  OptionalApplications,
  Applications,
  ApplicationsApplicationItem,
  Pager,
  ListApplicationsQuery,
} from '@/types/api/Applications/applications';
import { useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import {
  pageState,
  perPageSizeState,
  totalPageState,
  totalSizeState,
} from '@/recoil/pagination';
import { appClient } from '..';

const ConvertApplications = (data: OptionalApplications) =>
  ({
    // @ts-ignore
    applications: data.applications.map((app: ApplicationsApplicationItem) => ({
      applicationId: app.applicationId || 0,
      memberId: app.memberId || 0,
      memberCd: app.memberCd || 0,
      memberName: app.memberName || '',
      employeeCd: app.employeeCd || 0,
      associationCd: app.associationCd || '',
      eventNo: app.eventNo || '',
      applicationType: app.applicationType || 'MONTHLY_UNIT',
      applicationDate: app.applicationDate || '',
      applicationStatus: app.applicationStatus || 'PREPARATION',
      rejectComment: app.rejectComment || '',
      judgeUserName: app.judgeUserName || '',
      proxyApplicantUserName: app.proxyApplicantUserName || '',
      officeUpdateUserName: app.officeUpdateUserName || '',
      backofficeUpdateUserName: app.backofficeUpdateUserName || '',
    })),
    currentPage: data.currentPage || 0,
    totalPage: data.totalPage || 0,
    perPageSize: data.perPageSize || 0,
    totalSize: data.totalSize || 0,
  } as Applications & Pager);

/**
 * useApplications
 * 【API-B-4101】申請履歴一覧取得
 * @param params ApplicationsParams
 */
export const useApplications = (params: ApplicationsParams) => {
  const {
    data,
    error: applicationsError,
    mutate: applicationsMutate,
  } = useSWR(
    params
      ? [
          `/api/applications?page=${params.page || 1}`,
          params.yearMonth,
          params.eventType,
          params.keyword,
          params.applicationStatus,
          params.applicationType,
          params.sortKey,
        ]
      : null,
    () =>
      appClient.applications.listApplications(
        params.associationCd,
        params.memberId,
        params.keyword === '' ? undefined : params.keyword,
        params.yearMonth,
        params.eventType,
        params.applicationType,
        params.applicationStatus,
        params.page || 1,
        params.perPageSize,
        params.sortKey || 'APPLICATION_ID_ASC'
      )
  );

  return useMemo(
    () => ({
      data: data ? ConvertApplications(data) : undefined,
      applicationsError,
      applicationsMutate,
    }),
    [data]
  );
};

export const useApplicationsUpdate = (params: ListApplicationsQuery) => {
  const [, setPage] = useRecoilState(pageState);
  const [, setPerPageSize] = useRecoilState(perPageSizeState);
  const [, setTotalPage] = useRecoilState(totalPageState);
  const [, setTotalSize] = useRecoilState(totalSizeState);
  const { data, error } = useSWR(
    params.associationCd ? [params] : null,
    // null,
    () =>
      appClient.applications.listApplications(
        params.associationCd,
        params.memberId,
        params.keyword === '' ? undefined : params.keyword,
        params.yearMonth,
        params.eventType,
        params.applicationType,
        params.applicationStatus,
        params.page || 1,
        params.perPageSize || 20,
        params.sortKey
      )
  );

  useEffect(() => {
    if (data) {
      const { currentPage, perPageSize, totalPage, totalSize } = data;
      setPage(currentPage || 0);
      setPerPageSize(perPageSize || 0);
      setTotalPage(totalPage || 0);
      setTotalSize(totalSize || 0);
    }
  }, [data, setPage, setPerPageSize, setTotalPage, setTotalSize]);

  return {
    data: data ? ConvertApplications(data) : undefined,
    error,
  };
};
