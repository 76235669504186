/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { createContext, useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { format } from 'date-fns';
import { useApplications } from '@/services/customHooks/useApplications';
import { isUnapprovedApplication } from '@/utils/application';

type Context = ReturnType<typeof useProviderAdmission>;
const ApplicationsAdmissionContext = createContext({} as unknown as Context);

const initialState = {
  associationCd: '',
  yearMonth: Number(format(new Date(), 'yyyyMM')),
  eventType: 'ADMISSION',
  // applicationType: '',
  // applicationStatus: '',
  page: 1,
  perPageSize: 20,
  sortKey: 'APPLICATION_ID_DESC',
};
/**
 * useProviderAdmission
 */
const useProviderAdmission = (associationCd: string) => {
  const now = new Date();
  const formatDateToday = format(now, 'yyyyMM');
  const [dateState, setDateState] = React.useState(formatDateToday);
  const [params, setParams] = React.useState({
    ...initialState,
    associationCd,
  });
  // 赤バッジの表示・非表示
  const [
    hasUnapprovedAdmissionApplication,
    setHasUnapprovedAdmissionApplication,
  ] = React.useState(false);

  const [
    unapprovedAdmissionApplicationCount,
    setUnapprovedAdmissionApplicationCount,
  ] = React.useState(0);

  const {
    data: applicationsData,
    applicationsError,
    applicationsMutate,
    // @ts-ignore
  } = useApplications(params);

  useEffect(() => {
    setParams({
      ...params,
      associationCd,
      yearMonth: Number(dateState),
    });
  }, [associationCd, dateState]);

  // 赤バッジの表示・非表示
  useEffect(() => {
    if (applicationsData) {
      const hasUnapprovedApplications = applicationsData.applications.filter(
        (application) => isUnapprovedApplication(application.applicationStatus)
      );
      setUnapprovedAdmissionApplicationCount(hasUnapprovedApplications.length);
      setHasUnapprovedAdmissionApplication(
        hasUnapprovedApplications.length > 0
      );
    }
  }, [applicationsData]);

  /**
   * handleDateChange
   * @param date
   */
  const handleDateChange = (date: number | Date) => {
    const formatDate = format(date, 'yyyyMM');
    setDateState(formatDate);
  };

  return {
    applicationsAdmissionData: applicationsData,
    applicationsAdmissionError: applicationsError,
    applicationsAdmissionMutate: applicationsMutate,
    applicationsAdmissionLoading: !applicationsData && !applicationsError,
    // 赤バッジ用
    hasUnapprovedAdmissionApplication,
    // 未承認数
    unapprovedAdmissionApplicationCount,
    handleDateChange,
  };
};
/**
 * useApplicationsAdmission
 */
export const useApplicationsAdmission = () =>
  useContext(ApplicationsAdmissionContext);

/**
 * ApplicationsAdmissionProvider
 * @param associationCd
 * @param children
 * @constructor
 */
export const ApplicationsAdmissionProvider: React.FC<{
  associationCd: string;
  children: React.ReactNode;
}> = ({ associationCd, children }) => {
  const applicationsAdmission = useProviderAdmission(associationCd);
  return (
    <ApplicationsAdmissionContext.Provider value={applicationsAdmission}>
      {children}
    </ApplicationsAdmissionContext.Provider>
  );
};

export const Admission = () => <Outlet />;
