import { styled, Typography } from '@mui/material';
import { FC } from 'react';

const MuiFooter = styled('footer')(({ theme }) => {
  const { palette } = theme;
  return {
    width: '100%',
    background: palette.system.main,
    textAlign: 'center',
    padding: '14px',
    borderTop: '1px solid rgba(0, 0, 0, 0.13)',
  };
});

export const Footer: FC = () => (
  <MuiFooter>
    <Typography variant="caption/regular">
      Copyright © 2001 Tokai Tokyo Securities CO.,LTD. ALL RIGHTS RESERVED.
    </Typography>
  </MuiFooter>
);
