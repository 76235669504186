import { styled, TableRow, TableBody, SxProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface TableProps {
  children: React.ReactNode;
  sx?: SxProps;
  url?: string;
  applicationType?: string;
}

const MuiTableBody = styled(TableBody)(({ theme }) => {
  const { palette } = theme;
  return {
    background: palette.system.white,
    padding: '0px',
  };
});

const MuiRow = styled(TableRow)(({ theme }) => {
  const { palette } = theme;
  return {
    padding: '0 8px',
    borderTop: '1px solid',
    height: '46px',
    borderColor: palette.system['separator-light'],
  };
});

export const TableBodyRow = ({
  children,
  sx,
  url,
  applicationType,
}: TableProps) => {
  const navigate = useNavigate();

  const move = () => {
    if (url) {
      if (applicationType) {
        navigate(url, { state: { applicationType } });
      } else {
        navigate(url);
      }
    }
  };

  return (
    <MuiTableBody>
      <MuiRow
        hover
        onClick={() => move()}
        // eslint-disable-next-line no-extra-boolean-cast
        sx={{ ...sx, cursor: !!url ? 'pointer' : '' }}
      >
        {children}
      </MuiRow>
    </MuiTableBody>
  );
};
