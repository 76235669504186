/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { theme } from '@/theme';
import { MemberById } from '@/types/api/member';
import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { PrevnextApplications } from '@/types/api/Applications/prevnextApplications';
import { FindApplicationByID } from '@/types/api/Applications/application';
import { useMemberSecuritiesAccount } from '@/services/customHooks/useMemberSecuritiesAccount';
import { MemberAssets } from '@/types/api/memberAssets';
import { printApplicationsComponent } from '@/utils/printApplications';
import { TitleHead } from './SharedParts/TitleHead';
import { UserInfoTable } from './SharedParts/UserInfoTable';
import { ApplicationBottom } from './SharedParts/ApplicationBottom';
import { ApplicationTable as Table } from './SharedParts/ApplicationTable';
import { ApplicationTableRow as Row } from './SharedParts/ApplicationTableRow';
import { ApplicationButtons } from './SharedParts/ApplicationButtons';
import { PrintApplicationsButton } from './PrintApplicationsButton/PrintApplicationsButton';

interface PartProps {
  application: FindApplicationByID;
  unitType: string;
  userData: MemberById;
  memberAsset?: MemberAssets;
  bottomData: PrevnextApplications | undefined;
  handleApplication: (value: 'APPROVAL' | 'REJECTED', comment?: string) => void;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export const ApplicationsPart: FC<PartProps> = ({
  application,
  unitType,
  userData,
  memberAsset,
  bottomData,
  handleApplication,
  isOpen,
  setIsOpen,
}) => {
  const { data: memberSecurityAccount } = useMemberSecuritiesAccount(
    Number(userData.memberId)
  );
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.system['background-dark'],
        height: '100%',
      }}
    >
      <Stack
        spacing={4}
        sx={{
          backgroundColor: theme.palette.system.white,
          padding: '40px 160px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <PrintApplicationsButton
            onClick={() =>
              printApplicationsComponent('part_print_content', '一部引出')
            }
          />
        </Box>
        <Stack id="part_print_content" spacing={4}>
          <TitleHead
            title="一部引出"
            status={application.applicationStatus || ''}
            applicationDate={application.applicationDate || ''}
            applicationId={application.applicationId || ''}
            associationCd={application.associationCd || ''}
            proxyApplicantUserName={application.proxyApplicantUserName}
            comment={application.rejectComment}
            unitType={unitType}
          />
          <UserInfoTable data={userData} />
          <Table title="証券口座">
            {memberSecurityAccount &&
            memberSecurityAccount?.accountNumber &&
            memberSecurityAccount?.branchCd &&
            memberSecurityAccount?.branchName ? (
              <>
                <Row labels="金融機関名" values="東京東海証券" />
                <Row
                  labels="部店コード"
                  values={memberSecurityAccount.branchCd?.replace(
                    memberSecurityAccount.branchCd,
                    '*'.repeat(memberSecurityAccount.branchCd?.length)
                  )}
                />
                <Row
                  labels="部店名"
                  values={memberSecurityAccount.branchName?.replace(
                    memberSecurityAccount.branchName,
                    '*'.repeat(memberSecurityAccount.branchName?.length)
                  )}
                  isEven
                />
                <Row
                  labels="口座番号"
                  values={memberSecurityAccount.accountNumber?.replace(
                    memberSecurityAccount.accountNumber,
                    '*'.repeat(memberSecurityAccount.accountNumber?.length)
                  )}
                  isEven
                />
              </>
            ) : (
              <Typography
                sx={{
                  ...theme.typography['body-sub/regular'],
                  color: theme.palette.system['text-normal'],
                }}
              >
                未登録です。
              </Typography>
            )}
          </Table>
          <Table title="引出株数" isRed>
            <Row
              labels="現在の保有株数"
              values={`${
                application.applicationStatus !== 'COMPLETED'
                  ? `${memberAsset?.stocksNumber?.toLocaleString() || 0}株`
                  : ''
              }`}
            />
            <Row
              labels="引出株数"
              values={`${application?.applicationPart?.sharesNum?.toLocaleString()}株`}
              isEven
            />
            <Row
              labels="残り株数"
              values={`${
                application.applicationStatus !== 'COMPLETED'
                  ? `${(
                      (memberAsset?.stocksNumber || 0) -
                      (application?.applicationPart?.sharesNum || 0)
                    ).toLocaleString()}株`
                  : ''
              }`}
            />
          </Table>
        </Stack>
        {application.applicationStatus !== 'COMPLETED' && application.eventNo && (
          <Stack direction="row" sx={{ alignItems: 'center' }} spacing={2}>
            <ApplicationButtons
              status={application.applicationStatus || ''}
              handleApplication={handleApplication}
              setIsOpen={setIsOpen}
              isOpen={isOpen}
            />
            {(application.applicationStatus === 'PREPARATION' ||
              application.applicationStatus === 'APPLYING') && (
              <NormalLink
                href={`/applications/${unitType}/${application.applicationId}/part/edit`}
              >
                申請内容を修正する
              </NormalLink>
            )}
          </Stack>
        )}
      </Stack>
      {bottomData && (
        <Box sx={{ padding: '32px 160px' }}>
          <ApplicationBottom data={bottomData} unitType={unitType} />
        </Box>
      )}
    </Box>
  );
};
