import { FC } from 'react';
import { BasicTable } from '@/components/molecules/Table/BasicTable';
import { TableHeadRow } from '@/components/atoms/Table/TableHeadRow';
import { TableHeadCell } from '@/components/atoms/Table/TableHeadCell';
// @ts-ignore
import { ListEventItem } from '@/types/api/events';
import { HomeTableRow } from './HomeTableRow';

interface HomeTableProps {
  data: ListEventItem[] | [];
}

export const HomeTable: FC<HomeTableProps> = ({ data }) =>
  data.length === 0 ? (
    <div>ありません</div>
  ) : (
    <BasicTable disablePagination>
      <TableHeadRow>
        <TableHeadCell title="種別" />
        <TableHeadCell title="次回買付日" />
        <TableHeadCell title="買付日登録" />
        <TableHeadCell title="買付日登録期日" />
        <TableHeadCell title="作業処理連絡票" />
        <TableHeadCell title="作業処理連絡票期日" />
        <TableHeadCell title="購入(売却)連絡票" />
      </TableHeadRow>
      {data.map((item: ListEventItem) => (
        <HomeTableRow key={item.eventNo} data={item} />
      ))}
    </BasicTable>
  );
