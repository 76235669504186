import { Box } from '@mui/material';
import Modal from '@mui/material/Modal';
import { FC, ReactNode } from 'react';

interface BasicModalProps {
  open: boolean;
  handleClose: () => void;
  hasScroll?: boolean;
  children: ReactNode;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: ' 80%',
  overflow: 'scroll',
  bgcolor: 'system.white',
  borderRadius: '8px',
  outline: 0,
};

export const BasicModal: FC<BasicModalProps> = (props) => {
  const { open, handleClose, children, hasScroll = true } = props;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        BackdropProps={{ sx: { bgcolor: 'rgba(0, 0, 0, 0.25)' } }}
      >
        <Box
          sx={{
            ...style,
            ...(hasScroll ? { overflow: 'scroll' } : { overflow: 'initial' }),
          }}
        >
          {children}
        </Box>
      </Modal>
    </div>
  );
};
