import { styled } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { theme } from '@/theme';
import { list } from '@/constants/SideBar/SideBarList';

const Wrapper = styled('div')({
  width: '208px',
  minWidth: '208px',
  borderRight: '1px solid rgba(0, 0, 0, 0.13)',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: '32px 16px',
});

export const SideBar = () => {
  const { typography, palette } = theme;
  const location = useLocation();
  const getIsActive = (link: string, title: string) => {
    if (title === 'ダッシュボード' && location.pathname === '/') {
      return true;
    }
    if (title === '残高詳細' && location.pathname === '/balance') {
      return true;
    }
    if (
      title !== 'ダッシュボード' &&
      title !== '残高詳細' &&
      location.pathname.includes(link)
    ) {
      return true;
    }
    if (location.pathname.includes('/applications/') && title === '申請') {
      return true;
    }
    return false;
  };

  return (
    <Wrapper>
      {list.map((item) => (
        <NavLink
          style={({ isActive }) => ({
            ...typography['body-main/medium'],
            borderRadius: '8px',
            padding: '8px',
            whiteSpace: 'nowrap',
            textDecoration: 'none',
            background: getIsActive(item.link, item.title)
              ? palette.primary.gradation
              : palette.system.white,
            color: getIsActive(item.link, item.title)
              ? palette.system.white
              : palette.system['text-light'],
          })}
          to={item.link}
          key={item.title}
        >
          {item.title}
        </NavLink>
      ))}
    </Wrapper>
  );
};
