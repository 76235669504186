/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { ApplicationsUnitIndex } from '@/components/organisms/Applications/UnitIndex';
import { SubmitHandler } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { WorkReportInputs as Inputs } from '@/types/components/Inputs';
import { appClient } from '@/services';
import { FindTradereportsByID } from '@/types/api/tradereports';
import { FindWorkreportsById } from '@/types/api/workreports';
import { toRequired } from '@/utils/formatter';
import { convertParamMonth } from '@/utils/helper';
import { useRecoilValue, useRecoilState } from 'recoil';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { useAssociation } from '@/services/customHooks/useAssociation';
import {
  useApplications,
  useApplicationsUpdate,
} from '@/services/customHooks/useApplications';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { format, getMonth, getYear } from 'date-fns';

import { pageState } from '@/recoil/pagination';
import { appendToQueryString } from '@/utils/queryStringHelper';
import { useSnackbar } from '@/hooks/useSnackbar';
import { ErrorResponse } from '@/types/api/error';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { downloadPdfFromBinary } from '@/utils/downloadHelper';
import {
  ApplicationsMonthlyQueryState,
  ApplicationsMonthlySortKey,
} from '@/recoil/applications/monthlyUnit';

const initialState = {
  associationCd: '',
  yearMonth: Number(format(new Date(), 'yyyyMM')),
  eventType: 'MONTHLY',
  page: 1,
  perPageSize: 20,
  sortKey: 'APPLICATION_ID_DESC' as
    | 'APPLICATION_ID_ASC'
    | 'APPLICATION_ID_DESC'
    | 'APPLICATION_TYPE_ASC'
    | 'APPLICATION_TYPE_DESC'
    | 'MEMBER_CD_ASC'
    | 'MEMBER_CD_DESC'
    | 'MEMBER_NAME_ASC'
    | 'MEMBER_NAME_DESC'
    | 'APPLICATION_DATE_ASC'
    | 'APPLICATION_DATE_DESC'
    | 'APPLICATION_STATUS_ASC'
    | 'APPLICATION_STATUS_DESC',
};

export const MonthlyIndex = () => {
  const today = new Date();
  const [modal, setModal] = useState<string>('');
  const [keywordValue, setKeywordValue] = useState<string>('');
  const [year, setYear] = useState<number>(getYear(today));
  const [month, setMonth] = useState<number>(getMonth(today) + 1);
  const [sortKey, setSortKey] = useState<
    | 'APPLICATION_ID_ASC'
    | 'APPLICATION_ID_DESC'
    | 'APPLICATION_TYPE_ASC'
    | 'APPLICATION_TYPE_DESC'
    | 'MEMBER_CD_ASC'
    | 'MEMBER_CD_DESC'
    | 'MEMBER_NAME_ASC'
    | 'MEMBER_NAME_DESC'
    | 'EMPLOYEE_CD_ASC'
    | 'EMPLOYEE_CD_DESC'
    | 'APPLICATION_DATE_ASC'
    | 'APPLICATION_DATE_DESC'
    | 'APPLICATION_STATUS_ASC'
    | 'APPLICATION_STATUS_DESC'
  >('APPLICATION_ID_DESC');
  const [type, setType] = useState<
    | 'MONTHLY_UNIT'
    | 'BONUS_UNIT'
    | 'PART'
    | 'RECESS'
    | 'RESUME'
    | 'WITHDRAWAL'
    | undefined
  >();
  const [status, setStatus] = useState<
    'UNAPPROVED' | 'APPROVED' | 'REJECTED' | undefined
  >();
  // @ts-ignore
  const { associationCd } = useRecoilValue(SelectedAssociationState);
  const associationData = useAssociation(associationCd as unknown as string);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const [query, setQuery] = useState<string>('');
  const [applicationsParams, setApplicationsParams] = useState({
    ...initialState,
    associationCd,
  });
  // pages
  const [page, setPage] = useRecoilState(pageState);
  useHelmetHandler({
    title: '口数変更 申請一覧（給与）',
  });

  const [applicationsQuery, setApplicationQuery] = useRecoilState(
    ApplicationsMonthlyQueryState
  );
  const [sortKeyUpdate, setSortKeyUpdate] = useRecoilState(
    ApplicationsMonthlySortKey
  );
  const { data: applicationsData, error: applicationsError } =
    useApplicationsUpdate({
      ...applicationsQuery,
      page,
      associationCd,
      sortKey: sortKeyUpdate,
      eventType: 'MONTHLY',
    });

  const {
    data: applicationDataUnapproved,
    // @ts-ignore
  } = useApplications({
    ...applicationsParams,
    applicationStatus: 'UNAPPROVED',
  });

  /**
   * onClickSearch
   * @param value
   */
  const onClickSearch = (
    value: string,
    _type?:
      | 'MONTHLY_UNIT'
      | 'BONUS_UNIT'
      | 'PART'
      | 'RECESS'
      | 'RESUME'
      | 'WITHDRAWAL',
    _status?: 'UNAPPROVED' | 'APPROVED' | 'REJECTED'
  ) => {
    setKeywordValue(value);
    setType(_type);
    setStatus(_status);
    const selectedDateValue =
      year && month
        ? Number(String(year) + String(convertParamMonth(month)))
        : Number(format(new Date(), 'yyyyMM'));
    // 検索
    setApplicationQuery({
      ...applicationsQuery,
      keyword: value,
      page: 1,
      yearMonth: selectedDateValue,
      applicationType: _type,
      applicationStatus: _status,
    });

    const param = createSearchParams({});

    appendToQueryString(param, 'keyword', value);
    appendToQueryString(param, 'applicationType', type);
    appendToQueryString(param, 'applicationStatus', status);
    navigate({
      pathname: location.pathname,
      search: `?${param}`,
    });
    setQuery(value);
  };

  const [workreport, setWorkreport] = useState<Required<FindWorkreportsById>>(
    {} as Required<FindWorkreportsById>
  );
  const [tradereport, setTradereport] = useState<
    Required<FindTradereportsByID>
  >({} as Required<FindTradereportsByID>);

  const onSubmit: SubmitHandler<Inputs> = async (d: Inputs) => {
    try {
      await appClient.workreports.createWorkreportSendById(
        associationCd as unknown as string,
        String(year),
        month < 10 ? '0' + String(month) : String(month),
        'MONTHLY',
        {
          jimuDaikouCommission: d.jimuProxyPurchase
            ? Number(d.jimuProxyPurchase.replaceAll(',', ''))
            : undefined,
          transferFee: d.transferAmount
            ? Number(d.transferAmount.replaceAll(',', ''))
            : undefined,
          interest: d.interest
            ? Number(d.interest.replaceAll(',', ''))
            : undefined,
          sharesPurchased:
            {
              purchaseDate1: d.sharesPurchased.purchaseDate1 || undefined,
              purchaseUnitPrice1: d.sharesPurchased.purchaseUnitPrice1
                ? Number(
                    d.sharesPurchased.purchaseUnitPrice1.replaceAll(',', '')
                  )
                : undefined,
              purchaseSharesNum1: d.sharesPurchased.purchaseSharesNum1
                ? Number(
                    d.sharesPurchased.purchaseSharesNum1.replaceAll(',', '')
                  )
                : undefined,
              purchaseDate2: d.sharesPurchased.purchaseDate2 || undefined,
              purchaseUnitPrice2: d.sharesPurchased.purchaseUnitPrice2
                ? Number(
                    d.sharesPurchased.purchaseUnitPrice2.replaceAll(',', '')
                  )
                : undefined,
              purchaseSharesNum2: d.sharesPurchased.purchaseSharesNum2
                ? Number(
                    d.sharesPurchased.purchaseSharesNum2.replaceAll(',', '')
                  )
                : undefined,
              purchaseDate3: d.sharesPurchased.purchaseDate3 || undefined,
              purchaseUnitPrice3: d.sharesPurchased.purchaseUnitPrice3
                ? Number(
                    d.sharesPurchased.purchaseUnitPrice3.replaceAll(',', '')
                  )
                : undefined,
              purchaseSharesNum3: d.sharesPurchased.purchaseSharesNum3
                ? Number(
                    d.sharesPurchased.purchaseSharesNum3.replaceAll(',', '')
                  )
                : undefined,
            } || undefined,
          info: d.other,
        }
      );
      setModal('complete');
    } catch (e) {
      showSnackbar((e as ErrorResponse).data.message);
    }
  };

  const openWorkReport = async () => {
    const res = await appClient.workreports.findWorkreportById(
      String(year),
      String(convertParamMonth(month)),
      'MONTHLY',
      associationCd as unknown as string
    );
    setWorkreport(toRequired(res));
    setModal('workreport');
  };
  const openTradeReport = async () => {
    const res = await appClient.tradereports.findTradereportsById(
      String(year),
      String(convertParamMonth(month)),
      'MONTHLY',
      associationCd as unknown as string
    );
    setTradereport(toRequired(res));
    setModal('tradereport');
  };

  const downloadCsv = async () => {
    try {
      if (!applicationsParams?.associationCd) return;
      const res = await appClient.csv.downloadCsvListApplications(
        applicationsParams.associationCd,
        undefined,
        keywordValue === '' ? undefined : keywordValue,
        Number(String(year) + String(convertParamMonth(month))),
        'MONTHLY',
        type,
        status,
        sortKeyUpdate
      );

      if (res) {
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        const blob = new Blob([bom, res as BlobPart], {
          type: 'text/csv; charset=utf-8',
        });
        const url = URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.download = `MONTHLY-${applicationsParams.associationCd}-${Number(
          format(new Date(), 'yyyyMMdd')
        )}.csv`;
        anchor.href = url;
        document.body.appendChild(anchor);
        anchor.click();
        URL.revokeObjectURL(url);
      }
    } catch (e) {
      // handle download report error
    }
  };

  const downloadIncentiveCsv = async () => {
    try {
      if (!applicationsParams?.associationCd) return;
      const res = await appClient.csv.listMemberDepositDownloadCsv(
        applicationsParams.associationCd,
        Number(String(year) + String(convertParamMonth(month))),
        'MONTHLY'
      );

      if (res) {
        downloadPdfFromBinary(
          res,
          associationData?.associationName ?? '',
          Number(String(year) + String(convertParamMonth(month))),
          true
        );
      }
    } catch (e) {
      console.error('Error: ', e);
    }
  };
  useEffect(() => {
    setPage(1);
    setApplicationQuery({
      ...applicationsQuery,
      keyword: '',
      yearMonth: Number(String(year) + String(convertParamMonth(month))),
    });
  }, [year, month]);
  useEffect(() => {
    onClickSearch(keywordValue, type, status);
  }, [sortKey]);

  return (
    <ApplicationsUnitIndex
      onClickSearch={onClickSearch}
      type="monthly"
      data={applicationsData ? applicationsData.applications : []}
      unapprovedData={
        applicationDataUnapproved ? applicationDataUnapproved.applications : []
      }
      isListed={associationData?.listedClassification === 'LISTEDCOMPANY'}
      keywordValue={keywordValue}
      onSubmit={onSubmit}
      year={year}
      month={month}
      setYear={setYear}
      setMonth={setMonth}
      tradereport={tradereport}
      workreport={workreport}
      modal={modal}
      setModal={setModal}
      openTradereport={openTradeReport}
      openWorkreport={openWorkReport}
      applicationsError={applicationsError}
      query={query}
      sortKey={sortKeyUpdate}
      updateSortKey={(value) => {
        setSortKeyUpdate(value);
      }}
      downloadCsv={downloadCsv}
      downloadIncentiveCsv={downloadIncentiveCsv}
    />
  );
};
