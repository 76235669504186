import { LoginFormType, LoginMfa } from '@/components/organisms/Login/LoginMfa';
import { Path } from '@/constants/Router/path';
import { AUTH_TOKEN } from '@/constants/Storage/StorageKey';
// import { AssociationsListState } from '@/recoil/associations/associations';
import { appClient, OpenAPI } from '@/services';
import { FC, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

type LoginState = {
  email: string;
  mfaSession: string;
};

export const PasswordResettingMfa: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LoginState;
  const [isError, setIsError] = useState(false);

  const onSubmit: SubmitHandler<LoginFormType> = async (
    data: LoginFormType
  ) => {
    try {
      const response = await appClient.authorization.authMfaConfirm({
        loginId: state.email,
        mfaSession: state.mfaSession,
        mfaCode: data.mfa,
      });
      if (response && response.AuthenticationResult) {
        // appClient headerに設定
        const token = response.AuthenticationResult;
        if (token.IdToken) {
          const jsonString = JSON.stringify(token);
          localStorage.setItem(AUTH_TOKEN, jsonString);
          const idToken = `Bearer ${token.IdToken}`;
          if (OpenAPI.HEADERS !== undefined) {
            OpenAPI.HEADERS = { Authorization: idToken };
            // api clientのヘッダーにトークンを設定
            appClient.request.config.HEADERS = { Authorization: idToken };
          }
        }
        navigate(Path.LOGIN_PASSWORD_RESETTING, {
          state: {
            email: state.email,
          },
          replace: true,
        });
      }
    } catch (e) {
      setIsError(true);
    }
  };

  const handleClickRetransmission = async () => {
    try {
      OpenAPI.HEADERS = {};
      const response = await appClient.authorization.authMailConfirm({
        email: state.email,
      });
      setIsError(false);
      localStorage.removeItem(AUTH_TOKEN);
      if (response.Session !== undefined) {
        state.mfaSession = response.Session;
      }
    } catch {
      // エラー
      if (state === null) {
        navigate(Path.LOGIN);
      }
    }
  };

  return (
    <LoginMfa
      title="多要素認証"
      onSubmit={onSubmit}
      handleClickRetransmission={handleClickRetransmission}
      isError={isError}
    />
  );
};
