/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  Box,
  Typography,
  TextField,
  SvgIcon,
  IconButton,
  Paper,
  styled,
} from '@mui/material';
import { FC, useState } from 'react';
import { theme } from '@/theme';
import {
  Controller,
  Control,
  UseFormSetValue,
  UseFormGetValues,
  UseFormRegister,
  FieldErrorsImpl,
} from 'react-hook-form';
import { formatDateTime } from '@/utils/dateFunction';
import { WorkReportInputs as Inputs } from '@/types/components/Inputs';
import { FindWorkreportsById } from '@/types/api/workreports';
import { ReactComponent as CalendarIcon } from '@/assets/calendar.svg';
import { ReactComponent as CloseIcon } from '@/assets/cross.svg';
import { Calendar } from '@/components/organisms/Calendar/Calendar';
import { TableContainer } from './TableContainer';
import { Row } from './Row';
import { Td } from './Td';
import { Th } from './Th';

interface Props {
  control: Control<Inputs>;
  isSubmit: 'BEFORESUBMISSION' | 'SUBMISSION' | undefined;
  modalStatus: boolean;
  data: Inputs;
  report: Required<FindWorkreportsById>;
  setValue: UseFormSetValue<Inputs>;
  getValues: UseFormGetValues<Inputs>;
  register: UseFormRegister<Inputs>;
  errors: FieldErrorsImpl<Inputs>;
}

const InputDisabler = styled(Box)({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 2,
  backgroundColor: 'transparent',
});

export const PurchaseStocks: FC<Props> = ({
  control,
  isSubmit,
  modalStatus,
  data,
  report,
  setValue,
  getValues,
  register,
  errors,
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState<string>('');
  const now = new Date();
  const getIsAnyError = () =>
    !!(
      (data.sharesPurchased.purchaseUnitPrice1 !== '' &&
        errors.sharesPurchased?.purchaseUnitPrice1?.message) ||
      (data.sharesPurchased.purchaseUnitPrice2 !== '' &&
        errors.sharesPurchased?.purchaseUnitPrice2?.message) ||
      (data.sharesPurchased.purchaseUnitPrice3 !== '' &&
        errors.sharesPurchased?.purchaseUnitPrice3?.message) ||
      (data.sharesPurchased.purchaseSharesNum1 !== '' &&
        errors.sharesPurchased?.purchaseSharesNum1?.message) ||
      (data.sharesPurchased.purchaseSharesNum2 !== '' &&
        errors.sharesPurchased?.purchaseSharesNum2?.message) ||
      (data.sharesPurchased.purchaseSharesNum3 !== '' &&
        errors.sharesPurchased?.purchaseSharesNum3?.message)
    );

  const renderBox = (
    type:
      | 'sharesPurchased.purchaseDate1'
      | 'sharesPurchased.purchaseDate2'
      | 'sharesPurchased.purchaseDate3'
  ) => (
    <Box sx={{ position: 'relative' }}>
      <TextField
        fullWidth
        type="text"
        value={getValues(type)}
        placeholder="年/月/日"
        sx={{
          '&:disabled': {
            backgroundColor: theme.palette.system['background-dark'],
          },
        }}
        InputProps={{
          endAdornment: (
            <SvgIcon>
              <CalendarIcon />
            </SvgIcon>
          ),
          sx: { paddingLeft: '8px' },
        }}
      />
      <InputDisabler
        onClick={() => {
          setIsCalendarOpen(type);
        }}
      />
      {isCalendarOpen === type && (
        <Paper
          sx={{
            left: 0,
            top: 40,
            zIndex: 1111,
            position: 'absolute',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              padding: '4px',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={() => {
                setIsCalendarOpen('');
              }}
            >
              <SvgIcon>
                <CloseIcon />
              </SvgIcon>
            </IconButton>
          </Box>
          <Calendar
            minDate={new Date(now.getFullYear() - 1, 0, 1)}
            maxDate={new Date(now.getFullYear() + 1, 12, 0)}
            propValue={getValues(type)}
            onChange={(e) => {
              setValue(type, e);
              setIsCalendarOpen('');
            }}
          />
        </Paper>
      )}
    </Box>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '8px',
        width: '544px',
      }}
    >
      <Box display="flex" gap={theme.spacing(1)}>
        <Typography variant="h5" sx={{ color: 'system.text-normal' }}>
          購入株式
        </Typography>
        <Typography
          variant="body-sub/regular"
          sx={{ color: 'system.text-light' }}
        >
          （外部購入があった場合のみ）
        </Typography>
      </Box>
      <TableContainer sxProps={{ width: '544px', overflow: 'visible' }}>
        <Row>
          <Th
            variant="medium"
            bgcolor="secondary.bg"
            width="224px"
            height="36px"
            borderRight
          >
            月日
            <Typography variant="caption/regular">※任意</Typography>
          </Th>
          <Th
            variant="medium"
            bgcolor="secondary.bg"
            width="160px"
            height="36px"
            borderRight
          >
            購入単価
            <Typography variant="caption/regular">※任意</Typography>
          </Th>
          <Th
            variant="medium"
            bgcolor="secondary.bg"
            width="160px"
            height="36px"
          >
            購入株数
            <Typography variant="caption/regular">※任意</Typography>
          </Th>
        </Row>
        <Row>
          {isSubmit === 'SUBMISSION' ? (
            <>
              <Td width="224px" height="64px" borderRight>
                {`${
                  report?.sharesPurchased?.purchaseDate1?.toLocaleString() ||
                  '-月-日'
                }`}
              </Td>
              <Td width="160px" height="64px" align="right" borderRight>
                {`${
                  report.sharesPurchased?.purchaseUnitPrice1?.toLocaleString() ||
                  '-'
                }円`}
              </Td>
              <Td width="160px" height="64px" align="right" borderRight>
                {`${
                  report.sharesPurchased?.purchaseSharesNum1?.toLocaleString() ||
                  '-'
                }株`}
              </Td>
            </>
          ) : (
            <>
              <Td width="224px" height="64px" borderRight>
                {modalStatus ? (
                  <Box>
                    {' '}
                    {data.sharesPurchased.purchaseDate1
                      ? formatDateTime(data.sharesPurchased.purchaseDate1, true)
                      : '-'}
                  </Box>
                ) : (
                  <> {renderBox('sharesPurchased.purchaseDate1')}</>
                )}
              </Td>
              <Td width="160px" height="64px" align="right" borderRight>
                {modalStatus ? (
                  `${
                    Number(
                      data.sharesPurchased.purchaseUnitPrice1
                    ).toLocaleString() || '-'
                  }円`
                ) : (
                  <Box display="flex" alignItems="center">
                    <Controller
                      name="sharesPurchased.purchaseUnitPrice1"
                      control={control}
                      render={({ field }) => (
                        <Box display="flex" gap="8px" alignItems="center">
                          <TextField
                            {...field}
                            {...register('sharesPurchased.purchaseUnitPrice1')}
                            sx={{
                              width: '112px',
                            }}
                            inputProps={{ style: { textAlign: 'right' } }}
                            placeholder="例:10,000"
                          />
                          円
                        </Box>
                      )}
                    />
                  </Box>
                )}
              </Td>
              <Td width="160px" height="64px" align="right" borderRight>
                {modalStatus ? (
                  `${
                    Number(
                      data.sharesPurchased.purchaseSharesNum1
                    ).toLocaleString() || '-'
                  }株`
                ) : (
                  <Box display="flex" alignItems="center">
                    <Controller
                      name="sharesPurchased.purchaseSharesNum1"
                      control={control}
                      render={({ field }) => (
                        <Box display="flex" gap="8px" alignItems="center">
                          <TextField
                            {...field}
                            {...register('sharesPurchased.purchaseSharesNum1')}
                            sx={{ width: '112px' }}
                            inputProps={{ style: { textAlign: 'right' } }}
                            placeholder="例:10,000"
                          />
                          株
                        </Box>
                      )}
                    />
                  </Box>
                )}
              </Td>
            </>
          )}
        </Row>
        <Row>
          {isSubmit === 'SUBMISSION' ? (
            <>
              <Td width="224px" height="64px" borderRight>
                {`${
                  report?.sharesPurchased?.purchaseDate2?.toLocaleString() ||
                  '-月-日'
                }`}
              </Td>
              <Td width="160px" height="64px" align="right" borderRight>
                {`${
                  report.sharesPurchased?.purchaseUnitPrice2?.toLocaleString() ||
                  '-'
                }円`}
              </Td>
              <Td width="160px" height="64px" align="right" borderRight>
                {`${
                  report.sharesPurchased?.purchaseSharesNum2?.toLocaleString() ||
                  '-'
                }株`}
              </Td>
            </>
          ) : (
            <>
              <Td width="224px" height="64px" borderRight>
                {modalStatus ? (
                  <Box>
                    {' '}
                    {data.sharesPurchased.purchaseDate2
                      ? formatDateTime(data.sharesPurchased.purchaseDate2, true)
                      : '-'}
                  </Box>
                ) : (
                  <>{renderBox('sharesPurchased.purchaseDate2')}</>
                )}
              </Td>
              <Td width="160px" height="64px" align="right" borderRight>
                {modalStatus ? (
                  `${
                    Number(
                      data.sharesPurchased.purchaseUnitPrice2
                    ).toLocaleString() || '-'
                  }円`
                ) : (
                  <Box display="flex" alignItems="center">
                    <Controller
                      name="sharesPurchased.purchaseUnitPrice2"
                      control={control}
                      render={({ field }) => (
                        <Box display="flex" gap="8px" alignItems="center">
                          <TextField
                            {...field}
                            {...register('sharesPurchased.purchaseUnitPrice2')}
                            sx={{ width: '112px' }}
                            inputProps={{ style: { textAlign: 'right' } }}
                            placeholder="例:10,000"
                          />
                          円
                        </Box>
                      )}
                    />
                  </Box>
                )}
              </Td>
              <Td width="160px" height="64px" align="right" borderRight>
                {modalStatus ? (
                  `${
                    Number(
                      data.sharesPurchased.purchaseSharesNum2
                    ).toLocaleString() || '-'
                  }株`
                ) : (
                  <Box display="flex" alignItems="center">
                    <Controller
                      name="sharesPurchased.purchaseSharesNum2"
                      control={control}
                      render={({ field }) => (
                        <Box display="flex" gap="8px" alignItems="center">
                          <TextField
                            {...field}
                            {...register('sharesPurchased.purchaseSharesNum2')}
                            sx={{ width: '112px' }}
                            inputProps={{ style: { textAlign: 'right' } }}
                            placeholder="例:10,000"
                          />
                          株
                        </Box>
                      )}
                    />
                  </Box>
                )}
              </Td>
            </>
          )}
        </Row>
        <Row borderBottom={false}>
          {isSubmit === 'SUBMISSION' ? (
            <>
              <Td width="224px" height="64px" borderRight>
                {`${
                  report?.sharesPurchased?.purchaseDate3?.toLocaleString() ||
                  '-月-日'
                }`}
              </Td>
              <Td width="160px" height="64px" align="right" borderRight>
                {`${
                  report.sharesPurchased?.purchaseUnitPrice3?.toLocaleString() ||
                  '-'
                }円`}
              </Td>
              <Td width="160px" height="64px" align="right" borderRight>
                {`${
                  report.sharesPurchased?.purchaseSharesNum3?.toLocaleString() ||
                  '-'
                }株`}
              </Td>
            </>
          ) : (
            <>
              <Td width="224px" height="64px" borderRight>
                {modalStatus ? (
                  <Box>
                    {data.sharesPurchased.purchaseDate3
                      ? formatDateTime(data.sharesPurchased.purchaseDate3, true)
                      : '-'}
                  </Box>
                ) : (
                  <> {renderBox('sharesPurchased.purchaseDate3')}</>
                )}
              </Td>
              <Td width="160px" height="64px" align="right" borderRight>
                {modalStatus ? (
                  `${
                    Number(
                      data.sharesPurchased.purchaseUnitPrice3
                    ).toLocaleString() || '-'
                  }円`
                ) : (
                  <Box display="flex" alignItems="center">
                    <Controller
                      name="sharesPurchased.purchaseUnitPrice3"
                      control={control}
                      render={({ field }) => (
                        <Box display="flex" gap="8px" alignItems="center">
                          <TextField
                            {...field}
                            {...register('sharesPurchased.purchaseUnitPrice3')}
                            sx={{ width: '112px' }}
                            inputProps={{ style: { textAlign: 'right' } }}
                            placeholder="例:10,000"
                          />
                          円
                        </Box>
                      )}
                    />
                  </Box>
                )}
              </Td>
              <Td width="160px" height="64px" align="right" borderRight>
                {modalStatus ? (
                  `${
                    Number(
                      data.sharesPurchased.purchaseSharesNum3
                    ).toLocaleString() || '-'
                  }株`
                ) : (
                  <Box display="flex" alignItems="center">
                    <Controller
                      name="sharesPurchased.purchaseSharesNum3"
                      control={control}
                      render={({ field }) => (
                        <Box display="flex" gap="8px" alignItems="center">
                          <TextField
                            {...field}
                            {...register('sharesPurchased.purchaseSharesNum3')}
                            sx={{ width: '112px' }}
                            inputProps={{ style: { textAlign: 'right' } }}
                            placeholder="例:10,000"
                          />
                          株
                        </Box>
                      )}
                    />
                  </Box>
                )}
              </Td>
            </>
          )}
        </Row>
      </TableContainer>
      {isSubmit === 'BEFORESUBMISSION' && !modalStatus && getIsAnyError() && (
        <Typography sx={{ color: theme.palette.states.error }}>
          {`*購入単価と購入株式は${
            errors.sharesPurchased?.purchaseUnitPrice1?.message ||
            errors.sharesPurchased?.purchaseUnitPrice2?.message ||
            errors.sharesPurchased?.purchaseUnitPrice3?.message ||
            errors.sharesPurchased?.purchaseSharesNum1?.message ||
            errors.sharesPurchased?.purchaseSharesNum2?.message ||
            errors.sharesPurchased?.purchaseSharesNum3?.message
          }`}
        </Typography>
      )}
    </Box>
  );
};
