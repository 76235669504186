const csvNameHandler = (
  associationName: string,
  yearMonth: number,
  isMonthly: boolean
) => {
  const type = isMonthly ? '月例' : '賞与';
  // return `WTH2072${associationCd}${type}0000.csv`;
  return `積立・奨励金情報_${yearMonth}_${type}_${associationName}`;
};

export const downloadPdfFromBinary = (
  binary: any,
  fileName: string,
  yearMonth: number,
  isMonthly = true
) => {
  if (!binary) return;
  const downloadFilename = csvNameHandler(fileName, yearMonth, isMonthly);
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, binary as BlobPart], {
    type: 'text/csv; charset=utf-8',
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = downloadFilename;
  a.click();
  window.URL.revokeObjectURL(url);
};
