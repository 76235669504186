import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { headerItems } from '@/constants/Association/headerItems';

interface AssociationHeaderProps {
  type: 'bank' | 'contact' | 'deadline';
  isConfirmed: boolean;
}

export const AssociationHeader: FC<AssociationHeaderProps> = ({
  type,
  isConfirmed,
}) => {
  const backUrl = '/association';

  return (
    <Stack
      padding="35px 40px 25px"
      borderBottom="1px solid"
      borderColor="system.separator-light"
      gap={3}
    >
      {!isConfirmed && <NormalLink href={backUrl}>持株会情報に戻る</NormalLink>}
      <Stack spacing={1}>
        <Typography variant="h4" color="system.text-normal">
          {headerItems[type].title}
        </Typography>
        {isConfirmed ? (
          <Typography variant="body-main/regular" color="system.text-light">
            以下の内容で登録します。
          </Typography>
        ) : (
          <Typography variant="body-main/regular" color="system.text-light">
            {headerItems[type].description}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
