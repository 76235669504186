import { SxProps, Theme, Typography, styled, Button } from '@mui/material';
import { FC, useRef } from 'react';
import { ReactComponent as Download } from '@/assets/download.svg';

interface ButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  sx?: SxProps<Theme>;
  text?: string;
  textSx?: SxProps<Theme>;
  trailingIcon?: boolean;
}

// Disable time, could be different compared to other buttons. Therefore, separate const
const sleepTime = 2000;

const MuiButton = styled(Button)(({ theme }) => {
  const { palette, typography } = theme;
  return {
    ...typography['body-main/medium'],
    background: palette.system.button,
    color: palette.secondary.main,
    padding: '8px 20px',
    boxShadow: 'none',
    '&:hover': {
      background: palette.system.button,
      opacity: ' 0.75',
      boxShadow: 'none',
    },
    '&:disabled': {
      background: palette.system.inactive,
      color: palette.system.placeholder,
    },
  };
});

export const DownloadButton: FC<ButtonProps> = ({
  onClick,
  sx,
  text,
  textSx,
  trailingIcon,
}) => {
  const buttonRef = useRef() as React.MutableRefObject<HTMLButtonElement>;

  return (
    <MuiButton
      ref={buttonRef}
      sx={sx}
      onClick={(e) => {
        if (e.detail > 1) {
          if (buttonRef.current.disabled) return;
          buttonRef.current.disabled = true;
          setTimeout(() => {
            buttonRef.current.disabled = false;
          }, sleepTime);
        }
        if (onClick) onClick(e);
      }}
    >
      {!trailingIcon && <Download style={{ marginRight: '6px' }} />}
      <Typography
        variant="body-sub/medium"
        sx={{
          color: 'secondary.text',
          ...textSx,
        }}
      >
        {text || 'ダウンロード'}
      </Typography>
      {trailingIcon && <Download style={{ marginLeft: '6px' }} />}
    </MuiButton>
  );
};
