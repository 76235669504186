/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ApplicationsWithdrawalAccountEdit } from '@/components/organisms/ApplicationsEdits/WithdrawalAccountEdit';
import { FC, useEffect, useState } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { appClient } from '@/services';
import { useRecoilValue } from 'recoil';
import { WithdrawalAccountEditInputs as Inputs } from '@/types/components/Inputs';
import { FindAssociationsAccount } from '@/types/api/associations';
import { ErrorResponse } from '@/types/api/error';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { useSnackbar } from '@/hooks/useSnackbar';

export const WithdrawalAccountEdit: FC = () => {
  const match = useMatch(
    '/applications/:unitType/:applicationId/withdrawal/account/edit'
  );
  const unitType = match?.params.unitType || '';
  const { associationCd = '' } = useRecoilValue(SelectedAssociationState);
  const applicationId = useParams().applicationId;
  const [associationsAccount, setAssociationsAccount] =
    useState<FindAssociationsAccount>();

  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const onSubmit: SubmitHandler<Inputs> = async (d: Inputs) => {
    try {
      const res = await appClient.associations.updateAssociationsAccount(
        associationCd,
        {
          institutionCd: String(d.institutionCd) || '',
          institutionName: d.institutionName || '',
          branchCd: d.branchCd || '',
          branchName: d.branchName || '',
          accountType: d.accountType || 'OTHER',
          accountName: d.accountName || '',
          accountNumber: d.accountNumber || '',
          accountYuchoSymbol: d.accountYuchoSymbol || '',
          accountYuchoNumber: d.accountYuchoNumber || '',
          accountClassification: d.accountClassification || 'OTHER',
        }
      );
      console.log(res);
      if (applicationId)
        navigate(`/applications/${unitType}/${applicationId}/withdrawal`);
    } catch (e) {
      // エラー処理
      showSnackbar((e as ErrorResponse).data.message);
    }
  };

  const getAssociationsAccount = async () => {
    try {
      if (associationCd !== '') {
        const res = await appClient.associations.findAssociationsAccount(
          associationCd
        );
        setAssociationsAccount(res);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    void getAssociationsAccount();
  }, []);

  const onClickCancel = () => {
    if (applicationId)
      navigate(`/applications/${unitType}/${applicationId}/withdrawal`);
  };
  if (associationsAccount) {
    return (
      <ApplicationsWithdrawalAccountEdit
        applicationId={applicationId}
        account={associationsAccount}
        unitType={unitType}
        onSubmit={onSubmit}
        onClickCancel={onClickCancel}
      />
    );
  }
  return null;
};
