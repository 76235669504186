import {
  LoginFormType,
  LoginSettingPassword,
} from '@/components/organisms/Login/LoginSettingPassword';
import { appClient, OpenAPI } from '@/services';
import { FC, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { AUTH_TOKEN } from '@/constants/Storage/StorageKey';
import { GetAssociation } from '@/types/api/associations';

export type LoginState = {
  loginId: string;
  association?: GetAssociation;
};

export const PasswordSetting: FC = () => {
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const location = useLocation();
  const state = location.state as LoginState;

  const handleSetting: SubmitHandler<LoginFormType> = async (
    data: LoginFormType
  ) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const response = await appClient.authorization.authPasswordReset({
        loginId: state.loginId,
        password: data.newPassword,
      });
      if (response && response.AuthenticationResult) {
        // appClient headerに設定
        const token = response.AuthenticationResult;
        if (token.IdToken) {
          const jsonString = JSON.stringify(token);
          localStorage.setItem(AUTH_TOKEN, jsonString);
          const idToken = `Bearer ${token.IdToken}`;
          if (OpenAPI.HEADERS !== undefined) {
            OpenAPI.HEADERS = { Authorization: idToken };
            // api clientのヘッダーにトークンを設定
            appClient.request.config.HEADERS = { Authorization: idToken };
          }
        }
        navigate('complete', { state });
      }
    } catch (e) {
      // エラー処理
      setIsError(true);
    }
  };
  return (
    <LoginSettingPassword
      title="パスワード設定"
      isError={isError}
      onSubmit={handleSetting}
    />
  );
};
