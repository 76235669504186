import { FC } from 'react';
import { Box, Typography, styled, Checkbox, SxProps } from '@mui/material';

interface CheckBoxProps {
  label?: string;
  value: boolean;
  onChange: (newState: boolean) => void;
  containerSx?: SxProps;
}

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
  alignItems: 'center',

  '&:hover': {
    cursor: 'pointer',
  },
});

export const CheckBox: FC<CheckBoxProps> = ({
  label,
  value,
  onChange,
  containerSx,
}) => (
  <Wrapper onClick={() => onChange(!value)} sx={{ ...containerSx }}>
    <Checkbox
      sx={{
        color: 'system.separator',
        '&.Mui-checked': {
          color: 'secondary.normal',
        },
        padding: 0,
      }}
      checked={value}
    />
    {label && <Typography>{label}</Typography>}
  </Wrapper>
);
