import { FC } from 'react';
import { Box, Divider, Paper, Stack, styled, Typography } from '@mui/material';
import { MainButton } from '@/components/organisms/Login/MainButton';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.system.white,
  padding: theme.spacing(4, 3),
  borderRadius: '8px',
  width: '440px',
}));

interface LoginSettingPasswordProps {
  handleClickButton: () => void;
}

export const LoginSettingComplete: FC<LoginSettingPasswordProps> = ({
  handleClickButton,
}) => {
  useHelmetHandler({
    title: 'パスワード設定完了',
  });
  return (
    <Box display="flex" justifyContent="center">
      <StyledPaper elevation={0}>
        <Stack spacing={3}>
          <Typography variant="h4">パスワード設定完了</Typography>
          <Divider />
          <Typography>パスワードの設定が完了しました。</Typography>
        </Stack>
        <Box mt={4} width="100%" display="flex" justifyContent="center">
          <MainButton text="始める" onClick={handleClickButton} />
        </Box>
      </StyledPaper>
    </Box>
  );
};
