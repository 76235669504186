/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ApplicationsUnit } from '@/components/organisms/Applications/Unit';
import { FC, useState, useEffect, useCallback } from 'react';
import {
  useMatch,
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from 'react-router-dom';
import { MemberById } from '@/types/api/member';
import { FindApplicationByID } from '@/types/api/Applications/application';
import { GetAssociationsContribution } from '@/types/api/associations';
import { ConvertContribution } from '@/services/customHooks/useMemberContribution';
import { appClient } from '@/services';
import { PrevnextApplications } from '@/types/api/Applications/prevnextApplications';
import { MemberContribution } from '@/types/api/memberContribution';
import { useSnackbar } from '@/hooks/useSnackbar';
import { ErrorResponse } from '@/types/api/error';

type ApplicationType = {
  applicationType: string;
};

export const Unit: FC = () => {
  const match = useMatch('/applications/:unitType/:applicationId/unit');
  const unitType = match?.params.unitType || '';
  const applicationId = Number(useParams().applicationId) || 0;
  const [applicationData, setApplicationData] = useState<FindApplicationByID>();
  const [userData, setUserData] = useState<MemberById>();
  const [associationsContribution, setAssociationsContribution] =
    useState<GetAssociationsContribution>();

  const [contributionData, setContributionData] =
    useState<MemberContribution>();
  const { showSnackbar } = useSnackbar();
  // const [query] = useState<PrevnextApplicationParams>(
  //   searchParams as PrevnextApplicationParams
  // );
  // const { data: bottomData } = usePrevnextApplication(applicationId, query);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const handleApplication = async (
    value: 'APPROVAL' | 'REJECTED',
    comment?: string
  ) => {
    try {
      await appClient.applications.updateApplicationApproval(
        applicationId,
        value,
        {
          comment: comment || '',
          transferAmount: 0,
          remarks: '',
        }
      );
      if (applicationData) navigate(`/applications/${unitType}`);
    } catch (e) {
      showSnackbar((e as ErrorResponse).data.message);
    }
  };
  const getApplicationData = useCallback(async () => {
    try {
      if (!applicationId) return;
      const res = await appClient.applications.findApplicationById(
        applicationId
      );
      setApplicationData(res);
    } catch (e) {
      console.log(e);
    }
  }, [applicationId]);

  const getUserData = useCallback(async () => {
    try {
      if (applicationData?.memberId && applicationData?.memberId !== 0) {
        const res = await appClient.members.findMember(
          applicationData?.memberId
        );
        setUserData(res);
      }
    } catch (e) {
      console.log(e);
    }
  }, [applicationData?.memberId]);

  const getAssociationsContribution = useCallback(async () => {
    try {
      if (!applicationData?.associationCd) return;
      const res = await appClient.associations.getAssociationsContribution(
        applicationData?.associationCd
      );
      setAssociationsContribution(res);
    } catch (e) {
      console.log(e);
    }
  }, [applicationData?.associationCd]);

  const getContributionData = useCallback(async () => {
    try {
      if (applicationData?.memberId && applicationData?.memberId !== 0) {
        const res = await appClient.members.findMemberContribution(
          applicationData?.memberId
        );
        setContributionData(ConvertContribution(res));
      }
    } catch (e) {
      console.log(e);
    }
  }, [applicationData?.memberId]);

  useEffect(() => {
    void getApplicationData();
  }, [getApplicationData]);

  useEffect(() => {
    void getUserData();
  }, [getUserData]);

  useEffect(() => {
    void getAssociationsContribution();
  }, [getAssociationsContribution]);

  useEffect(() => {
    void getContributionData();
  }, [getContributionData]);

  const [searchParams] = useSearchParams();
  const [bottomData, setBottomData] = useState<PrevnextApplications>();

  const getBottomData = useCallback(async () => {
    if (
      !applicationId ||
      !applicationData?.associationCd ||
      !applicationData.applicationDate
    )
      return;
    const res = await appClient.applications.findPrevNextApplicationById(
      applicationId,
      applicationData?.associationCd,
      undefined,
      searchParams.get('keyword') || undefined,
      Number(
        applicationData?.applicationDate?.substring(0, 7).replace('-', '')
      ) || undefined,
      unitType === 'monthly' ? 'MONTHLY' : 'BONUS',
      undefined,
      undefined
    );
    setBottomData(res);
  }, [
    applicationId,
    applicationData?.associationCd,
    applicationData?.applicationDate,
  ]);

  useEffect(() => {
    void getBottomData();
  }, [getBottomData]);

  const location = useLocation();
  const state = location.state as ApplicationType;

  if (
    applicationData &&
    userData &&
    associationsContribution &&
    // bottomData &&
    contributionData
  ) {
    return (
      <ApplicationsUnit
        application={applicationData}
        unitType={unitType}
        userData={userData}
        bottomData={bottomData}
        contributionData={contributionData}
        associationsContribution={associationsContribution}
        handleApplication={handleApplication}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        applicationType={state.applicationType}
      />
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
