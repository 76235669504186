import { MembersProxyButtons } from '@/components/organisms/Members/MembersProxyButtons';
import { MemberProxyCompleteOrganism } from '@/components/organisms/Members/MembersProxyCompleteOrganism';
import { MemberProxyHeader } from '@/components/organisms/Members/MembersProxyHeader';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { memberInfoState } from '@/recoil/memberInfo/memberInfo';
import { useLocation, useParams } from 'react-router-dom';
import { convertEventType, toDate } from '@/utils/formatter';
import { ListEvents } from '@/types/api/events';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { MembersHeader } from './MembersHeader';

export const MemberProxyComplete = () => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { applicationType } = useParams();
  const memberData = useRecoilValue(memberInfoState);
  const location = useLocation();
  const { applicationId, eventType, purchaseDate } = location.state as Exclude<
    ListEvents,
    undefined
  >[number] & { applicationId: number };
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call
  const event = `${convertEventType(eventType)} (買付日：${
    purchaseDate ? toDate(purchaseDate) : ''
  } )`;

  const convertApplicationType = (type?: string) => {
    switch (type) {
      case 'pay': {
        return 'MONTHLY_UNIT';
      }
      case 'bonus': {
        return 'BONUS_UNIT';
      }
      case 'part': {
        return 'PART';
      }
      case 'recess': {
        return 'RECESS';
      }
      case 'resume': {
        return 'RESUME';
      }
      case 'withdrawal': {
        return 'WITHDRAWAL';
      }
      default: {
        return undefined;
      }
    }
  };

  const getTitle = () => {
    const appType = convertApplicationType(applicationType);
    switch (appType) {
      case 'MONTHLY_UNIT':
        return '持株会会員詳細 代理申請 口数変更(給与) 完了';
      case 'BONUS_UNIT':
        return '持株会会員詳細 代理申請 口数変更(賞与) 完了';
      case 'PART':
        return '持株会会員詳細 代理申請 一部引出 完了';
      case 'RECESS':
        return '持株会会員詳細 代理申請 休止 完了';
      case 'RESUME':
        return '持株会会員詳細 代理申請 再開 完了';
      case 'WITHDRAWAL':
        return '持株会会員詳細 代理申請 退会 完了';
      default:
        return 'NexStocks';
    }
  };

  useHelmetHandler({
    title: getTitle(),
  });

  return (
    <Stack spacing={3} p="32px 40px">
      <MembersHeader memberData={memberData} />
      <MemberProxyHeader isConfirmed={isConfirmed} />
      <Stack width="100%" alignItems="center" p={3} gap={5}>
        <MemberProxyCompleteOrganism
          id={applicationId}
          date={toDate(String(new Date()))}
          event={event}
        />
        <MembersProxyButtons
          isConfirmed={isConfirmed}
          setIsConfirmed={(v) => setIsConfirmed(v)}
        />
      </Stack>
    </Stack>
  );
};
