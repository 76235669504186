/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { styled, Box, Stack, SvgIcon, Button, Typography } from '@mui/material';
import { FC, useState, useEffect, useCallback } from 'react';
import { ReactComponent as RightArrow } from '@/assets/rightArrow.svg';
import { ReactComponent as LeftArrow } from '@/assets/leftArrow.svg';
import { theme } from '@/theme';
import { format, addMonths, startOfMonth, endOfMonth } from 'date-fns';
import { ja } from 'date-fns/locale';
import { useRecoilValue } from 'recoil';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { appClient } from '@/services';

const SlideWrapper = styled(Box)({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
});

const Block = styled(Button)({
  border: '1px solid',
  borderColor: theme.palette.system.inactive,
  borderRadius: '4px',
  display: 'flex',
  padding: '8px',
  minWidth: '0px',
  '&:hover': {
    background: theme.palette.system.inactive,
  },
  '&:active': {
    background: theme.palette.system.inactive,
  },
  color: theme.palette.system.inactive,
});

const Items = styled(Box)({
  color: theme.palette.system['text-light'],
  display: 'flex',
  gap: '4px',
});

const today = new Date();

interface Props {
  isNeedInfo?: boolean;
  isPurchaseDate?: boolean;
  year: number;
  month: number;
  setYear: (value: number) => void;
  setMonth: (value: number) => void;
  eventType?: 'MONTHLY' | 'BONUS' | 'DIVIDEND' | 'SPECIAL';
}

export const MonthChange: FC<Props> = ({
  isNeedInfo = true,
  isPurchaseDate = true,
  year,
  month,
  setYear,
  setMonth,
  eventType,
}) => {
  // @ts-ignore
  const { associationCd = '' } = useRecoilValue(SelectedAssociationState);
  const isThisMonthSx = {
    backgroundColor: 'system.background-dark',
    border: 'none',
  };

  const nextButtonLimitDate = addMonths(today, 11);
  const nextButtonLimitYear = nextButtonLimitDate.getFullYear();
  const nextButtonLimitMonth = nextButtonLimitDate.getMonth() + 1; // format(nextButtonLimitDate, 'M', { locale: ja });

  const isLimitMonth =
    year === nextButtonLimitYear && month === nextButtonLimitMonth;

  const [eventListData, setEventListData] = useState<
    {
      associationCd?: string;
      associationName?: string;
      listedClassification?: 'LISTEDCOMPANY' | 'UNLISTEDCOMPANY';
      eventNo?: string;
      eventType?: string;
      purchaseDate?: string;
      purchaseDateInputValue?: string;
      accumulatedDateInputValue?: string;
      purchaseDateRegistrationStatus?: 'BEFORREGISTRATION' | 'REGITERED';
      purchaseDateRegistrationDate?: string;
      workreportApprovalStatus?: 'BEFORESUBMISSION' | 'SUBMISSION';
      workreportDueDate?: string;
      purchaseScheduleDay?: number;
      tradereportsStatus?: 'BEFORERECEIPT' | 'AFTERRECEIPT';
    }[]
  >();
  const getEventListData = useCallback(async () => {
    try {
      const targetMonth = new Date(year, month - 1);
      const from = format(startOfMonth(targetMonth), 'yyyy-MM-dd');
      const to = format(endOfMonth(targetMonth), 'yyyy-MM-dd');
      const res = await appClient.events.listEvents(
        associationCd,
        eventType,
        'YEAR_MONTH',
        from,
        to
      );
      setEventListData(res?.events);
    } catch (e) {
      console.error(e);
    }
  }, [year, month]);

  const [workreportDueDate, setWorkDay] = useState<string>('');
  const [purchaseDate, setPurchaseDate] = useState<string>('');
  /**
   * handleClickNextMonth
   */
  const handleClickNextMonth = () => {
    if (month === 12) {
      setYear(year + 1);
      setMonth(1);
    } else {
      setMonth(month + 1);
    }
  };
  /**
   * handleClickPreMonth
   */
  const handleClickPreMonth = () => {
    if (month === 1) {
      setYear(year - 1);
      setMonth(12);
    } else {
      setMonth(month - 1);
    }
  };

  useEffect(() => {
    if (eventListData && eventListData.length > 0) {
      const eventItem = eventListData[0];
      if (!eventItem.workreportDueDate || eventItem.workreportDueDate === '') {
        setWorkDay('');
      } else {
        setWorkDay(
          format(new Date(eventItem.workreportDueDate), 'yyyy/MM/dd (E)', {
            locale: ja,
          })
        );
      }
      if (!eventItem.purchaseDate || eventItem.purchaseDate === '') {
        setPurchaseDate('');
      } else {
        setPurchaseDate(
          format(new Date(eventItem.purchaseDate), 'yyyy/MM/dd (E)', {
            locale: ja,
          })
        );
      }
    } else {
      setWorkDay('');
      setPurchaseDate('');
    }
  }, [eventListData]);

  useEffect(() => {
    void getEventListData();
  }, [getEventListData]);

  return (
    <Stack spacing={3} alignItems="center" direction="row">
      <SlideWrapper>
        <Block onClick={() => handleClickPreMonth()}>
          <SvgIcon viewBox="-3 -3 24 24">
            <LeftArrow />
          </SvgIcon>
        </Block>
        <Typography variant="h5" color={theme.palette.system['text-normal']}>
          {year}年{month}月
        </Typography>
        <Block
          sx={isLimitMonth ? isThisMonthSx : {}}
          onClick={() => handleClickNextMonth()}
          disabled={isLimitMonth}
        >
          <SvgIcon
            viewBox="-3 -3 24 24"
            sx={{
              path: {
                stroke: isLimitMonth ? 'system.placeholder' : '',
              },
            }}
          >
            <RightArrow />
          </SvgIcon>
        </Block>
      </SlideWrapper>
      {isNeedInfo && (
        <Stack direction="row" spacing={2}>
          {workreportDueDate && (
            <Items>
              <Typography variant="caption/regular">事務局締日</Typography>
              <Typography variant="caption/regular">
                {workreportDueDate}
              </Typography>
            </Items>
          )}
          {purchaseDate && (
            <Items>
              <Typography variant="caption/regular">
                制度グループ締日
              </Typography>
              <Typography variant="caption/regular">{purchaseDate}</Typography>
            </Items>
          )}
        </Stack>
      )}
    </Stack>
  );
};
