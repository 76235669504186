import { Box, styled, SxProps, Typography } from '@mui/material';
import { ReactComponent as Icon } from '@/assets/redExclamation.svg';
import { FC } from 'react';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: '4px',
  border: '1px solid',
  borderColor: theme.palette.states.error,
}));

const Message = styled(Typography)(({ theme }) => ({
  color: theme.palette.states.error,
  whiteSpace: 'pre-wrap',
}));

interface LoginErrorMessageProps {
  message: string;
  sx?: SxProps;
}

export const LoginErrorMessage: FC<LoginErrorMessageProps> = ({
  message,
  sx = {},
}) => (
  <Container p={1} sx={sx}>
    <Box mr={1} display="flex" alignItems="center">
      <Box
        width="24px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Icon />
      </Box>
    </Box>
    <Box>
      <Message variant="body-main/medium">{message}</Message>
    </Box>
  </Container>
);
