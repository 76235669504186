import { MemberContribution } from '@/types/api/memberContribution';
import useSWR from 'swr';
import { appClient } from '..';

export const ConvertContribution = (
  data:
    | {
        associationCd?: string;
        memberCd?: string;
        contributionStartDate?: string;
        monthlyUnit?: number;
        monthlyRecessStartDate?: string;
        monthlyRecessEndDate?: string;
        bonusUnit?: number;
        bonusRecessClassification?: string;
        bonusRate?: number;
      }
    | undefined
) =>
  data
    ? ({
        associationCd: data.associationCd || '',
        memberCd: data.memberCd || '',
        contributionStartDate: data.contributionStartDate || '',
        monthlyUnit: data.monthlyUnit || 0,
        monthlyRecessStartDate: data.monthlyRecessStartDate || '',
        monthlyRecessEndDate: data.monthlyRecessEndDate || '',
        bonusUnit: data.bonusUnit || 0,
        bonusRecessClassification: data.bonusRecessClassification || '',
        bonusRate: data.bonusRate || 0,
      } as MemberContribution)
    : undefined;

export const useMemberContribution = (memberId?: number) => {
  // 【API-C-4008】持株会員拠出情報取得
  const { data: apiData, mutate } = useSWR(
    [memberId ? `/api/members/${memberId}/contributions` : null],
    () => appClient.members.findMemberContribution(memberId as number)
  );

  return {
    data: ConvertContribution(apiData),
    mutate,
  };
};
