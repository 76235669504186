import { useRecoilState } from 'recoil';
import { snackbarAtom, SnackbarType } from '@/atom/snackbarAtom';

export const useSnackbar = () => {
  const [snackbar, setSnackbar] = useRecoilState<SnackbarType>(snackbarAtom);

  const showSnackbar = (
    message: SnackbarType['message'],
    width?: SnackbarType['width']
  ) => {
    setSnackbar({
      open: true,
      message,
      width,
    });
  };

  const hideSnackbar = () => {
    setSnackbar({
      open: false,
      message: '',
      width: '',
    });
  };

  return {
    snackbar,
    showSnackbar,
    hideSnackbar,
  };
};
