import {
  AuthorityClassification,
  ProxyApplicationClassification,
} from '@/constants/OfficeUser/officeUsersIndex';
import {
  AuthorityClassificationType,
  ProxyApplicationClassificationType,
} from '@/types/components/OfficeUser';

export const switchAuthorityLabel = (
  type: AuthorityClassificationType
): '一般ユーザー' | '管理者' | '' => {
  switch (type) {
    case AuthorityClassification.General:
      return '一般ユーザー';
    case AuthorityClassification.Administrator:
      return '管理者';
    default:
      return '';
  }
};

export const switchProxyApplicationLabel = (
  type: ProxyApplicationClassificationType
): '不可' | '可能' | '' => {
  switch (type) {
    case ProxyApplicationClassification.Impossible:
      return '不可';
    case ProxyApplicationClassification.Possible:
      return '可能';
    default:
      return '';
  }
};
