import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { FindWorkreportsById } from '@/types/api/workreports';
import { TableContainer } from './TableContainer';
import { Row } from './Row';
import { Td } from './Td';
import { Th } from './Th';

interface Props {
  report: Required<FindWorkreportsById>;
}

export const Changes: FC<Props> = ({ report }) => {
  const {
    oldUsersCount,
    oldUnitCount,
    joinCount,
    withdrawalCount,
    unitChangeCount,
    pauseCount,
    resumeCount,
    joinUnit,
    withdrawalUnit,
    changeUnit,
    pauseUnit,
    resumeUnit,
    usersCount,
    unitCount,
  } = report;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '8px',
      }}
    >
      <Typography variant="h5" sx={{ color: 'system.text-normal' }}>
        今回変動内容
      </Typography>
      <TableContainer>
        <Row>
          <Th
            variant="medium"
            bgcolor="secondary.normal"
            width="152px"
            height="37px"
            borderRight
            borderBottom
          >
            内容
          </Th>
          <Th
            variant="medium"
            bgcolor="secondary.normal"
            width="136px"
            height="37px"
            borderRight
            borderBottom
          >
            件数(会員数)
          </Th>
          <Th
            variant="medium"
            bgcolor="secondary.normal"
            width="136px"
            height="37px"
            borderBottom
          >
            口数
          </Th>
        </Row>
        <Row>
          <Th
            variant="medium"
            bgcolor="secondary.light"
            width="152px"
            height="37px"
            borderRight
          >
            前回
          </Th>
          <Td bold width="136px" height="37px" align="right" borderRight>
            {`${oldUsersCount ? oldUsersCount.toLocaleString() : 0}人`}
          </Td>
          <Td bold width="136px" height="37px" align="right">
            {`${oldUsersCount ? oldUnitCount.toLocaleString() : 0}口`}
          </Td>
        </Row>
        <Row>
          <Th
            variant="regular"
            bgcolor="system.white"
            width="76px"
            height="240px"
            borderRight
          >
            今回変動
          </Th>
          <Box>
            <Th
              variant="regular"
              bgcolor="secondary.bg"
              width="76px"
              height="48px"
              borderRight
              borderBottom
            >
              入会
            </Th>
            <Th
              variant="regular"
              bgcolor="secondary.bg"
              width="76px"
              height="48px"
              borderRight
              borderBottom
            >
              退会
            </Th>
            <Th
              variant="regular"
              bgcolor="secondary.bg"
              width="76px"
              height="48px"
              borderRight
              borderBottom
            >
              口数変更
            </Th>
            <Th
              variant="regular"
              bgcolor="secondary.bg"
              width="76px"
              height="48px"
              borderRight
              borderBottom
            >
              休止
            </Th>
            <Th
              variant="regular"
              bgcolor="secondary.bg"
              width="76px"
              height="48px"
              borderRight
            >
              再開
            </Th>
          </Box>
          <Box>
            <Td
              width="136px"
              height="48px"
              align="right"
              borderRight
              borderBottom
            >
              {`${joinCount ? joinCount.toLocaleString() : 0}人`}
            </Td>
            <Td
              width="136px"
              height="48px"
              align="right"
              borderRight
              borderBottom
            >
              {`${withdrawalCount ? withdrawalCount.toLocaleString() : 0}人`}
            </Td>
            <Td
              width="136px"
              height="48px"
              align="right"
              borderRight
              borderBottom
            >
              {`${unitChangeCount ? unitChangeCount.toLocaleString() : 0}人`}
            </Td>
            <Td
              width="136px"
              height="48px"
              align="right"
              borderRight
              borderBottom
            >
              {`${pauseCount ? pauseCount.toLocaleString() : 0}人`}
            </Td>
            <Td width="136px" height="48px" align="right" borderRight>
              {`${resumeCount ? resumeCount.toLocaleString() : 0}人`}
            </Td>
          </Box>
          <Box>
            <Td width="136px" height="48px" align="right" borderBottom>
              {`${joinUnit ? joinUnit.toLocaleString() : 0}`}
            </Td>
            <Td width="136px" height="48px" align="right" borderBottom>
              {`${withdrawalUnit ? withdrawalUnit.toLocaleString() : 0}`}
            </Td>
            <Td width="136px" height="48px" align="right" borderBottom>
              {`${changeUnit ? changeUnit.toLocaleString() : 0}`}
            </Td>
            <Td width="136px" height="48px" align="right" borderBottom>
              {`${pauseUnit ? pauseUnit.toLocaleString() : 0}`}
            </Td>
            <Td width="136px" height="48px" align="right">
              {`${resumeUnit ? resumeUnit.toLocaleString() : 0}`}
            </Td>
          </Box>
        </Row>
        <Row borderBottom={false}>
          <Th
            variant="bold"
            bgcolor="secondary.light"
            width="152px"
            height="37px"
            borderRight
          >
            今回
          </Th>
          <Td bold width="136px" height="37px" align="right" borderRight>
            {`${usersCount ? usersCount.toLocaleString() : 0}人`}
          </Td>
          <Td bold width="136px" height="37px" align="right">
            {`${unitCount ? unitCount.toLocaleString() : 0}口`}
          </Td>
        </Row>
      </TableContainer>
    </Box>
  );
};
