/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { LoginPaper } from '@/components/organisms/Login/LoginPaper';
import { MainButton } from '@/components/organisms/Login/MainButton';
import { Box, Stack, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { theme } from '@/theme';
import { LoginErrorMessage } from './LoginErrorMessage';
import { PopoverQuestionMark } from './PopoverQuestionMark';
import { EmailFieldWithLabel } from './EmailFieldWithLabel';

const errorMessage =
  '認証に失敗しました。\n入力されたコードが間違っていないかご確認ください。';

export type LoginFormType = {
  mfa: string;
};
interface LoginMfaProps {
  title: string;
  onSubmit: SubmitHandler<LoginFormType>;
  isError: boolean;
  handleClickRetransmission: () => void;
}

const schema = yup
  .object()
  .required()
  .shape({
    mfa: yup.string().required('必須項目です'),
  });

export const LoginMfa: FC<LoginMfaProps> = ({
  title,
  onSubmit,
  handleClickRetransmission,
  isError,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<LoginFormType>({ resolver: yupResolver(schema) });

  return (
    <LoginPaper
      title={title}
      subTitle={`メールアドレス宛に認証コードを送信しました。\nメールに掲載されたコードを下の入力欄に入力してください。`}
      subTitleSx={{ marginTop: 3, whiteSpace: 'pre-wrap' }}
      popover={<PopoverQuestionMark />}
    >
      <Stack spacing={3}>
        {isError && (
          <LoginErrorMessage message={errorMessage} sx={{ marginBottom: 3 }} />
        )}
        <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <EmailFieldWithLabel
              label="認証コード"
              placeholder="認証コードを入力"
              error={'mfa' in errors}
              helperText={errors.mfa?.message}
              {...register('mfa')}
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <MainButton text="認証する" type="submit" />
          </Box>
        </Stack>
        <Stack
          p={3}
          spacing={2}
          sx={{ backgroundColor: theme.palette.system['background-light'] }}
          alignItems="center"
        >
          <Typography variant="body-sub/regular">
            ※認証コードが届いていない場合は、下の「認証コードを再送信する」ボタンからコードを再送信してください。
          </Typography>
          <NormalLink onClick={handleClickRetransmission}>
            認証コードを再送信する
          </NormalLink>
        </Stack>
      </Stack>
    </LoginPaper>
  );
};
