import { Button, ButtonProps, styled } from '@mui/material';
import { FC, useRef } from 'react';

const MuiButton = styled(Button)(({ theme }) => {
  const { palette, typography } = theme;
  return {
    ...typography['body-main/medium'],
    background: palette.system['background-dark'],
    color: palette.system['text-normal'],
    padding: '8px 16px',
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, 0.13)',
    borderRadius: '4px',
    '&:hover': {
      background: '#E0DFDF',

      boxShadow: 'none',
    },
    '&:disabled': {
      background: palette.system['background-dark'],
      border: '1px solid #F2F1F1',
      color: palette.system.placeholder,
    },
  };
});

// Disable time, could be different compared to other buttons. Therefore, separate const
const sleepTime = 2000;

export const CancelButton: FC<ButtonProps> = (props) => {
  const buttonRef = useRef() as React.MutableRefObject<HTMLButtonElement>;

  return (
    <MuiButton
      ref={buttonRef}
      variant="contained"
      {...props}
      onClick={(e) => {
        if (e.detail > 1) {
          if (buttonRef.current.disabled) return;
          buttonRef.current.disabled = true;
          setTimeout(() => {
            buttonRef.current.disabled = false;
          }, sleepTime);
        }
        const { onClick } = props;
        if (onClick) onClick(e);
      }}
    />
  );
};
