import { RouteObject, useRoutes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { routes } from './constants/Router';
import { sessionToken } from './recoil/token';
import { useProvideAuth } from './hooks/useAuth';

export const App = () => {
  useProvideAuth();
  const token = useRecoilValue(sessionToken);

  const routesResult: RouteObject[] = useMemo(
    () => (token.IdToken ? routes.private : routes.public),
    [token]
  );
  const element = useRoutes(routesResult);

  return element;
};
