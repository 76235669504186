import { TextWithFlowIcon } from '@/components/molecules/Flow/TextWithFlowIcon';
import { flowTexts } from '@/constants/Flow/flow';
import { Typography } from '@mui/material';
import { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';

interface MembersProxyHeaderProps {
  isConfirmed: boolean;
}

export const MemberProxyHeader: FC<MembersProxyHeaderProps> = ({
  isConfirmed,
}) => {
  const completed = useLocation().pathname.includes('complete');
  const { applicationType } = useParams();
  let title = '';

  switch (applicationType) {
    case 'pay': {
      title = '口数を変更する (代理申請)';
      break;
    }
    case 'bonus': {
      title = '口数を変更する (代理申請)';
      break;
    }
    case 'part': {
      title = '一部を引き出す(代理申請)';
      break;
    }
    case 'recess': {
      title = '休止する(代理申請)';
      break;
    }
    case 'resume': {
      title = '再開する(代理申請)';
      break;
    }
    case 'withdrawal': {
      title = '退会する(代理申請)';
      break;
    }
    default: {
      return null;
    }
  }
  const flowOn = () => {
    if (completed) return 2;
    if (isConfirmed) return 1;
    return 0;
  };

  return (
    <>
      <Typography variant="h3" color="system.text-normal">
        {title}
      </Typography>
      <TextWithFlowIcon flowTexts={flowTexts} flowOn={flowOn()} />
    </>
  );
};
