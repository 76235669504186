/* eslint-disable */
import { FC, useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { ReactComponent as ReadBadge } from '@/assets/readBadge.svg';
import {
  useApplicationsAdmission,
  useApplicationsBonus,
  useApplicationsDividend,
  useApplicationsMonthly,
  useApplicationsTemporary,
  useApplicationsUser,
} from '@/components/pages/Applications';

const Wrapper = styled('div')(({ theme }) => {
  const { palette } = theme;
  return {
    backgroundColor: palette.system.background,
    height: '40px',
    display: 'flex',
    gap: '8px',
    padding: '4px',
    borderRadius: '30px',
    maxWidth: '520px',
  };
});

const Tab = styled('div')(({ theme }) => {
  const { typography, palette } = theme;
  return {
    ...typography['body-sub/regular'],
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '6px 16px',
    color: palette.system['text-light'],
    cursor: 'pointer',
    position: 'relative',
    borderRadius: '30px',
  };
});

interface AssociationTabProps {
  move: (to: string) => void;
  type: string;
}

const titles = {
  monthly: '月例',
  bonus: '賞与',
  dividend: '配当',
  temporary: '臨時',
  admission: '新規会員登録',
  user: '会員情報変更',
};

export const IndexTab: FC<AssociationTabProps> = ({ move, type }) => {
  const { applicationsMonthlyData, hasUnapprovedMonthlyApplication } =
    useApplicationsMonthly();
  const { hasUnapprovedBonusApplication } = useApplicationsBonus();
  const { hasUnapprovedDividendApplication } = useApplicationsDividend();
  const { hasUnapprovedTemporaryApplication } = useApplicationsTemporary();
  const { hasUnapprovedAdmissionApplication } = useApplicationsAdmission();
  const { hasUnapprovedUserApplication } = useApplicationsUser();
  const [isReadList, setIsReadList] = useState<boolean[]>([
    hasUnapprovedMonthlyApplication,
    hasUnapprovedBonusApplication,
    hasUnapprovedDividendApplication,
    hasUnapprovedTemporaryApplication,
    hasUnapprovedAdmissionApplication,
    hasUnapprovedUserApplication,
  ]);

  useEffect(() => {
    setIsReadList([
      hasUnapprovedMonthlyApplication,
      hasUnapprovedBonusApplication,
      hasUnapprovedDividendApplication,
      hasUnapprovedTemporaryApplication,
      hasUnapprovedAdmissionApplication,
      hasUnapprovedUserApplication,
    ]);
  }, [
    hasUnapprovedMonthlyApplication,
    hasUnapprovedBonusApplication,
    hasUnapprovedDividendApplication,
    hasUnapprovedTemporaryApplication,
    hasUnapprovedAdmissionApplication,
    hasUnapprovedUserApplication,
  ]);

  const selectedSx = {
    background: 'linear-gradient(90deg, #F7473B -1.45%, #FC9621 100%)',
    color: 'system.white',
  };
  return (
    <Wrapper>
      {Object.entries(titles).map(([key, value], index) => (
        <Tab
          key={key}
          onClick={() => move(key)}
          sx={type === key ? selectedSx : {}}
        >
          {value}
          {isReadList[index] && (
            <ReadBadge
              style={{ position: 'absolute', top: '5px', right: '8px' }}
            />
          )}
        </Tab>
      ))}
    </Wrapper>
  );
};
