/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, useCallback, useEffect } from 'react';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { TableHeadCell } from '@/components/atoms/Table/TableHeadCell';
import { TableHeadRow } from '@/components/atoms/Table/TableHeadRow';
import { BasicTable } from '@/components/molecules/Table/BasicTable';
import { useSnackbar } from '@/hooks/useSnackbar';
import { format, subDays } from 'date-fns';
import { theme } from '@/theme';
import {
  Box,
  Typography,
  styled,
  TextField,
  SvgIcon,
  Paper,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as QuestionCircledSvg } from '@/assets/questionPopOverGrey.svg';
import { PopoverWithText } from '@/components/molecules/Popover/Popover';
import { TableBodyRow } from '@/components/atoms/Table/TableBodyRow';
import { TableBodyCell } from '@/components/atoms/Table/TableBodyCell';
import { ReactComponent as CalendarIcon } from '@/assets/calendar.svg';
import { ReactComponent as CloseIcon } from '@/assets/cross.svg';
import { Calendar } from '@/components/organisms/Calendar/Calendar';
import { appClient } from '@/services';
import { formatDateTime } from '@/utils/dateFunction';
import { EventListState } from '@/recoil/events/events';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { useRecoilValue, useRecoilState } from 'recoil';

const Wrapper = styled(Box)({});

const HeaderWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: 24,
  padding: '35px 40px 25px',
  borderWidth: 0,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.system.separator,
});

const HeaderTitle = styled(Typography)({
  ...theme.typography.h4,
  color: theme.palette.system['text-normal'],
});

const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  padding: '25px 40px',
});

const ContentHeadingWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 16,
});

const ContentHeadingItem = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
});

const ContentHeadingLabelKey = styled(Typography)({
  ...theme.typography['caption/regular'],
  color: theme.palette.system['text-light'],
});

const ContentHeadingLabelValue = styled(Typography)({
  ...theme.typography['caption/bold'],
  color: theme.palette.system['text-light'],
});

const TableWrapper = styled(Box)({
  width: '100%',
  marginTop: 16,
});

const TableHeadCellContent = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 8,
  ...theme.typography['caption/regular'],
  color: theme.palette.system['text-light'],
});

const InputDisabler = styled(Box)({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 2,
  backgroundColor: 'transparent',
});

const TableCellText = styled(Typography)({
  ...theme.typography['body-main/medium'],
  color: theme.palette.system.table,
});

export const ScheduleSelectDay = () => {
  const association = useRecoilValue(SelectedAssociationState);
  const navigate = useNavigate();
  const [events, setEvents] = useRecoilState(EventListState);
  const { eventType, eventNo } = useParams();
  const selectedEvent = events.find((e) => e.eventNo === eventNo);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [calendarSelection, setCalendarSelection] = useState('');
  const { showSnackbar } = useSnackbar();
  const [checkbox, setCheckbox] = useState(false);
  const [isReacquisition, setIsReacquisition] = useState<boolean>(true);

  const getOfficePurchaseDate = () => {
    if (calendarSelection === '') return '';
    return format(subDays(new Date(calendarSelection), 7), 'yyyy-MM-dd');
  };

  const getMemberPurchaseDate = () => {
    if (calendarSelection === '') return '';
    return format(subDays(new Date(calendarSelection), 10), 'yyyy-MM-dd');
  };

  const handleUpdate = async () => {
    try {
      if (!association?.associationCd) return;
      // todo
      // 買い付けしない場合の処理
      const res = await appClient.events.updateEventById(
        association.associationCd,
        eventNo || '',
        {
          purchaseDate: calendarSelection,
          memberDeadline: getMemberPurchaseDate(),
          officeDeadline: getOfficePurchaseDate(),
          cancelParchase: checkbox,
        }
      );
      showSnackbar(
        `${eventType === 'bonus' ? '賞与' : '配当'}買付日を登録しました。`,
        '81px'
      );
      console.log('res: ', res);
    } catch (e) {
      // Handle update error
    }
  };

  const getEvents = useCallback(async () => {
    try {
      if (!association.associationCd) return;
      const res = await appClient.events.listEvents(association.associationCd);
      if (res.events) setEvents(res.events);
    } catch (e) {
      // handle getEvents error
    }
  }, [association, setEvents]);

  useEffect(() => {
    if (!association.associationCd) return;
    if (events.length === 0 && isReacquisition) {
      setIsReacquisition(false);
      void getEvents();
    }
  }, [association]);

  const renderTableRow = (index: number) => (
    <TableBodyRow>
      <TableBodyCell>
        <TableCellText>
          {selectedEvent?.purchaseDate
            ? new Date(selectedEvent?.purchaseDate).getMonth() + 1
            : '1'}
          月
        </TableCellText>
      </TableBodyCell>
      <TableBodyCell>
        <Box sx={{ position: 'relative ' }}>
          <TextField
            fullWidth
            disabled={checkbox}
            type="text"
            value={calendarSelection}
            placeholder="年/月/日"
            sx={{
              cursor: 'pointer',
              '&:disabled': {
                backgroundColor: theme.palette.system['background-dark'],
              },
            }}
            InputProps={{
              endAdornment: (
                <SvgIcon>
                  <CalendarIcon />
                </SvgIcon>
              ),
              sx: { paddingLeft: '8px' },
            }}
          />
          <InputDisabler
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              setActiveIndex(index);
              setIsCalendarOpen(true);
            }}
          />
          {isCalendarOpen && activeIndex === index && !checkbox && (
            <Paper sx={{ position: 'absolute', left: 0, top: 40, zIndex: 10 }}>
              <Box
                sx={{
                  display: 'flex',
                  padding: '4px',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  onClick={() => {
                    setActiveIndex(-1);
                    setIsCalendarOpen(false);
                  }}
                >
                  <SvgIcon>
                    <CloseIcon />
                  </SvgIcon>
                </IconButton>
              </Box>
              <Calendar
                onChange={(e) => {
                  setCalendarSelection(e);
                  setIsCalendarOpen(false);
                }}
              />
            </Paper>
          )}
        </Box>
      </TableBodyCell>

      <TableBodyCell>
        <TableCellText>
          {calendarSelection && !checkbox
            ? formatDateTime(getMemberPurchaseDate(), true)
            : '-'}
        </TableCellText>
      </TableBodyCell>
      <TableBodyCell>
        <TableCellText>
          {calendarSelection && !checkbox
            ? formatDateTime(getOfficePurchaseDate(), true)
            : '-'}
        </TableCellText>
      </TableBodyCell>
    </TableBodyRow>
  );

  return (
    <Wrapper>
      <HeaderWrapper>
        <NormalLink onClick={() => navigate(-1)}>
          スケジュール一覧に戻る
        </NormalLink>
        <HeaderTitle>
          {eventType === 'bonus' ? '賞与' : '配当'}買付日を登録
        </HeaderTitle>
      </HeaderWrapper>
      <ContentWrapper>
        <ContentHeadingWrapper>
          {selectedEvent?.purchaseDateRegistrationDate && (
            <ContentHeadingItem>
              <ContentHeadingLabelKey>買付日登録期日</ContentHeadingLabelKey>
              <ContentHeadingLabelValue>
                {formatDateTime(
                  selectedEvent?.purchaseDateRegistrationDate,
                  true
                )}
              </ContentHeadingLabelValue>
            </ContentHeadingItem>
          )}
        </ContentHeadingWrapper>
        <TableWrapper sx={{ opacity: checkbox ? 0.6 : 1 }}>
          <BasicTable
            disablePagination
            containerSx={{
              overflow: 'visible',
            }}
          >
            <TableHeadRow>
              <TableHeadCell width="10%">
                <TableHeadCellContent>
                  <Box>買付予定月</Box>
                  <PopoverWithText
                    title="買付予定月"
                    text="初回登録時の買付月です。この月を基準に買付日を登録してください。"
                    icon={<QuestionCircledSvg width={12} height={12} />}
                  />
                </TableHeadCellContent>
              </TableHeadCell>
              <TableHeadCell width="25%">
                <TableHeadCellContent>
                  <Box>買付日</Box>
                  <PopoverWithText
                    title="買付日"
                    text="ここで選択した日付が今回の買付日となります。"
                    icon={<QuestionCircledSvg width={12} height={12} />}
                  />
                </TableHeadCellContent>
              </TableHeadCell>
              <TableHeadCell width="30%">
                <TableHeadCellContent>
                  <Box>会員受付締日(自動算出)</Box>
                  <PopoverWithText
                    title="会員受付締日(自動算出)"
                    text="買付日−10営業日＝会員受付締日となります。"
                    icon={<QuestionCircledSvg width={12} height={12} />}
                  />
                </TableHeadCellContent>
              </TableHeadCell>
              <TableHeadCell width="30%">
                <TableHeadCellContent>
                  <Box>事務局受付締日(自動算出)</Box>
                  <PopoverWithText
                    title="事務局受付締日(自動算出)"
                    text="買付日−5営業日＝事務局受付締日となります。"
                    icon={<QuestionCircledSvg width={12} height={12} />}
                  />
                </TableHeadCellContent>
              </TableHeadCell>
            </TableHeadRow>
            {renderTableRow(1)}
          </BasicTable>
        </TableWrapper>
        <Box mt={2} pl="11px">
          <FormControlLabel
            control={
              <Checkbox
                value={checkbox}
                onChange={() => setCheckbox(!checkbox)}
                sx={{
                  color: 'system.separator',
                  '&.Mui-checked': {
                    color: 'secondary.normal',
                  },
                  padding: 0,
                  marginLeft: '0px',
                }}
              />
            }
            label="買付しない"
          />
        </Box>
        <PrimaryButton
          sx={{ marginTop: '32px' }}
          onClick={async () => {
            await handleUpdate();
            navigate('/schedules');
          }}
          disabled={calendarSelection === '' && !checkbox}
        >
          入力内容を登録する
        </PrimaryButton>
      </ContentWrapper>
    </Wrapper>
  );
};
