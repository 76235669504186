import { Box, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface Props {
  variant: 'regular' | 'medium';
  width?: string;
  height?: string;
  bgcolor: 'secondary.bg' | 'secondary.light' | 'system.white';
  borderBottom?: boolean;
  children: ReactNode;
}

/**
 * Th: Table Header の略
 */
export const Th: FC<Props> = ({
  variant,
  width = '120px',
  height = '40px',
  bgcolor,
  borderBottom,
  children,
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      width,
      height,
      bgcolor,
      borderRight: '1px solid',
      borderBottom: borderBottom ? '1px solid' : '0',
      borderColor: 'system.separator-light',
      p: '8px 10px',
      gap: '4px',
      boxSizing: 'border-box',
    }}
  >
    <Typography
      variant={`body-sub/${variant}`}
      sx={{ color: 'secondary.text' }}
    >
      {children}
    </Typography>
  </Box>
);
