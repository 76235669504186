import {
  Box,
  Divider,
  Typography,
  Paper,
  PaperProps,
  styled,
} from '@mui/material';
import { FC, ReactElement } from 'react';
import { theme } from '@/theme';

const StyledPaper = styled(Paper)(() => ({
  backgroundColor: theme.palette.system.white,
  padding: theme.spacing(4),
  borderRadius: '8px',
  width: '440px',
}));

interface HeadlinePaperProps extends PaperProps {
  children: ReactElement;
  title: string;
  element?: ReactElement;
}

export const HeadlinePaper: FC<HeadlinePaperProps> = ({
  children,
  title,
  element,
  ...props
}) => (
  <StyledPaper {...props}>
    <>
      <Box display="flex" alignItems="center" mb={3} gap="11px">
        <Typography variant="h3">{title}</Typography>
        {element}
      </Box>
      <Divider />
      <Box>{children}</Box>
    </>
  </StyledPaper>
);
