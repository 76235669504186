import { styled, Stack, Pagination, Box, SxProps } from '@mui/material';
import { FC } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  pageState,
  totalPageState,
  totalSizeState,
  perPageSizeState,
} from '@/recoil/pagination';

const RecordNumber = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['body-sub/regular'],
  };
});

const records = {
  page: 1,
  startPage: 1,
  allRecords: 240,
  allPage: 24,
};

const MuiPagination = styled(Pagination)(({ theme }) => {
  const { palette } = theme;
  return {
    ul: {
      '& .MuiPaginationItem-root': {
        color: palette.secondary.normal,
      },
      button: {
        borderColor: palette.secondary.normal,
        borderWidth: '1px',
        borderStyle: 'solid',
      },
      '& .MuiButtonBase-root.Mui-selected': {
        backgroundColor: palette.secondary.normal,
        color: palette.system.white,
      },
      '& .Mui-disabled': {
        background: 'rgba(0, 0, 0, 0.13)',
        color: palette.system.placeholder,
        borderColor: 'rgba(0, 0, 0, 0.13)',
        opacity: 'unset',
      },
      '& .MuiPaginationItem-ellipsis': {
        fontWeight: 'bold',
        fontSize: 'x-large',
      },
    },
  };
});

const PaginateWrapper = styled(Box)(({ theme }) => {
  const { palette } = theme;
  return {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: palette.system.white,
    gap: '8px',
    alignItems: 'center',
  };
});

interface PaginateProps {
  sx?: SxProps;
}

export const Paginate: FC<PaginateProps> = ({
  sx = { paddingBottom: '33px' },
}) => {
  const [page, setPage] = useRecoilState(pageState);
  const totalSize = useRecoilValue(totalSizeState);
  const totalPage = useRecoilValue(totalPageState);
  const perPageSize = useRecoilValue(perPageSizeState);

  return (
    <PaginateWrapper sx={sx}>
      <RecordNumber>
        {totalSize === 0 ? 0 : (page - 1) * perPageSize + 1}〜
        {page * perPageSize > totalSize ? totalSize : page * perPageSize}件/全
        {totalSize || 0}件
      </RecordNumber>
      <Stack spacing={2}>
        <MuiPagination
          count={totalPage || 0}
          variant="outlined"
          shape="rounded"
          siblingCount={2}
          boundaryCount={1}
          defaultPage={1}
          page={page}
          onChange={(_, value) => {
            setPage(value);
          }}
        />
      </Stack>
    </PaginateWrapper>
  );
};
