import { FC } from 'react';
import { styled, Typography, Box, SxProps, Stack } from '@mui/material';
import { theme } from '@/theme';

const Wrapper = styled(Box)({
  ...theme.typography['body-main/regular'],
  display: 'flex',
  gap: '16px',
  width: '100%',
  padding: '12px 0',
  alignItems: 'center',
  borderBottom: '1px solid',
  borderColor: theme.palette.system['separator-light'],
});

const Label = styled(Stack)({
  color: theme.palette.system['text-light'],
  minWidth: '136px',
});

const Value = styled(Box)({
  color: theme.palette.system['text-normal'],
  width: '100%',
});

interface Props {
  labels?: string[] | string;
  values?: string | number | string[];
  isEven?: boolean;
  isOptional?: boolean;
  isEdited?: boolean;
  children?: React.ReactNode;
  sx?: SxProps;
}

export const ApplicationTableRow: FC<Props> = ({
  labels,
  values,
  isEven = false,
  isOptional = false,
  isEdited = false,
  children,
  sx,
}) => {
  const getBackgroundColor = () => {
    if (isEdited) {
      return '#EFFBFC';
    }
    if (isEven) {
      return 'system.background-light';
    }
    return 'system.white';
  };

  return (
    <Wrapper
      sx={{
        backgroundColor: getBackgroundColor(),
        ...sx,
      }}
    >
      {labels && (
        <Label>
          {typeof labels !== 'string' ? (
            labels.map((label) => <Typography key={label}>{label}</Typography>)
          ) : (
            <Typography>{labels}</Typography>
          )}
          {isOptional && (
            <Typography variant="caption/regular">任意</Typography>
          )}
        </Label>
      )}

      <Value>
        {values && typeof values !== 'string' && typeof values !== 'number' ? (
          values.map((value) => <Typography key={value}> {value}</Typography>)
        ) : (
          <Typography
            variant={isEdited ? 'body-main/medium' : 'body-main/regular'}
          >
            {' '}
            {values}
          </Typography>
        )}{' '}
        {children}
      </Value>
    </Wrapper>
  );
};
