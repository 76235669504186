import { TitleWithDivider } from '@/components/molecules/Title/TitleWithDivider';
import { theme } from '@/theme';
import { Box, Divider, FormControl, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { UpdateAssociationsFirst } from '@/types/api/associations';
import { SelectBox } from '@/components/atoms/Input/SelectBox';

interface ApplicationDeadlineSectionProps {
  isConfirmed: boolean;
}

export const ApplicationDeadlineSection: FC<
  ApplicationDeadlineSectionProps
> = ({ isConfirmed }) => {
  const { control, getValues, setValue, watch } =
    useFormContext<UpdateAssociationsFirst>();
  const watchDeadline = watch('memberDeadlineDays');
  return (
    <Stack spacing={2}>
      <TitleWithDivider title="会員申請締日" />
      {isConfirmed ? (
        <Typography>
          {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call */}
          {watchDeadline === 99 ? '前月末' : `${watchDeadline || 0}日前`}
        </Typography>
      ) : (
        <>
          <Typography
            variant="body-main/regular"
            sx={{
              color: theme.palette.system['text-normal'],
              whiteSpace: 'pre-wrap',
            }}
          >
            {`会員からの申請の締め切りを買付日の何日前にするかご指定ください。(事務局締日は買付日の5営業日前です)\n原則、すべての申請は月例に紐付きます。`}
          </Typography>
          <Box display="flex" alignItems="center" gap={1}>
            <Controller
              name="memberDeadlineDays"
              control={control}
              render={({ field }) => (
                <FormControl>
                  <SelectBox
                    {...field}
                    width="80px"
                    isKeyAValue
                    items={[...Array(37)].map((_, i, self) => {
                      if (self.length === i + 1) {
                        return {
                          key: '前月末',
                          value: '前月末',
                        };
                      }
                      if (i >= 30) {
                        const additionalValue = 35 + (i - 30) * 5;
                        return {
                          key: String(additionalValue),
                          value: String(additionalValue),
                        };
                      }
                      return {
                        key: String(i + 1),
                        value: String(i + 1),
                      };
                    })}
                    value={
                      getValues('memberDeadlineDays') === 99
                        ? '前月末'
                        : getValues('memberDeadlineDays')
                    }
                    onChange={(e) =>
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
                      setValue(
                        'memberDeadlineDays',
                        e.target.value !== '前月末'
                          ? Number(e.target.value)
                          : 99
                      )
                    }
                  />
                </FormControl>
              )}
            />
            {watchDeadline !== 99 && <Typography>日前</Typography>}
          </Box>
        </>
      )}
      <Divider />
    </Stack>
  );
};
