import { useEffect, useState, useCallback } from 'react';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { SelectBox } from '@/components/atoms/Input/SelectBox';
import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { monthDays } from '@/constants/SelectBox/choicesForSelectBox';
import { theme } from '@/theme';
import { Box, Typography, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as QuestionCircledSvg } from '@/assets/questionPopOverGrey.svg';
import { useRecoilValue, useRecoilState } from 'recoil';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { EventListMonthlyState } from '@/recoil/events/events';
import { appClient } from '@/services';
import { PopoverWithText } from '@/components/molecules/Popover/Popover';
import { format } from 'date-fns';
import { useSnackbar } from '@/hooks/useSnackbar';
import { ConvertDayToKanji } from '@/utils/dateFunction';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const Wrapper = styled(Box)({});

const HeaderWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: 24,
  padding: '35px 40px 25px',
  borderWidth: 0,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.system['separator-light'],
});

const HeaderTitle = styled(Typography)({
  ...theme.typography.h4,
  color: theme.palette.system['text-normal'],
});

const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  padding: '25px 40px',
});

const ContentHeadingWrapper = styled(Typography)({
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
});

const ContentHeadingLabelKey = styled(Typography)({
  ...theme.typography['caption/regular'],
  color: theme.palette.system['text-light'],
});

const ContentHeadingLabelValue = styled(Typography)({
  ...theme.typography['caption/bold'],
  color: theme.palette.system['text-light'],
});

const TableWrapper = styled(Box)({
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.system['separator-light'],
  borderRadius: 4,
  marginTop: 16,
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
});

const TableColumn = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  borderWidth: 0,
  borderRightWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.system['separator-light'],
});

const TableColumnRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  padding: '16px 8px',
  alignItems: 'center',
  gap: 12,
  borderWidth: 0,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.system['separator-light'],
});

const TableColumnHeaderRow = styled(TableColumnRow)({
  ...theme.typography['caption/regular'],
  color: theme.palette.system['text-light'],
  padding: '12px 8px',
  backgroundColor: theme.palette.system['background-light'],
});

const TableColumnRowLabelWrapper = styled(Box)({
  width: 80,
  paddingLeft: 12,
  color: theme.palette.system.table,
  fontWeight: 500,
});

const TableColumnRowContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 8,
  ...theme.typography['body-sub/regular'],
  color: theme.palette.system['text-normal'],
});

export const ScheduleSelectDays = () => {
  const navigate = useNavigate();
  const association = useRecoilValue(SelectedAssociationState);
  const [eventsMonthly, setEventsMonthly] = useRecoilState(
    EventListMonthlyState
  );
  const [selectedDays, setSelectedDays] = useState<
    | {
        date1?: string;
        date2?: string;
        date3?: string;
        date4?: string;
        date5?: string;
        date6?: string;
        date7?: string;
        date8?: string;
        date9?: string;
        date10?: string;
        date11?: string;
        date12?: string;
      }
    | undefined
  >();
  const { showSnackbar } = useSnackbar();
  useHelmetHandler({
    title: '買付日登録（月例）',
  });

  const handleUpdate = async () => {
    try {
      if (!association?.associationCd) return;
      const eventYear = (new Date().getFullYear() + 1).toString();
      const res = await appClient.events.createEventMonthly(
        association?.associationCd,
        eventYear,
        {
          // associationCd: association.associationCd,x
          monthlyPurchase: Object.entries(selectedDays ?? {}).reduce(
            (prev, curr) => {
              const [key, value] = curr;
              let month = '';
              let day = '';
              const eventMonth = key.replace(/date([0-9]+)/, '$1');
              month =
                parseInt(eventMonth, 10) < 10 ? `0${eventMonth}` : eventMonth;
              day = parseInt(value, 10) < 10 ? `0${value}` : value;

              return {
                ...prev,
                [key]: `${eventYear}-${month}-${day}`,
              };
            },
            {}
          ),
        }
      );
      if (res.update_status === 'OK') {
        showSnackbar('月例買付日を登録しました。');
      } else {
        showSnackbar('月例買付日登録に失敗しました。');
      }
      console.log('Res: ', res);
    } catch (e) {
      showSnackbar('月例買付日登録に失敗しました。');
      // Handle update error
    }
  };

  const getEventsMonthly = useCallback(async () => {
    try {
      if (!association?.associationCd) return;
      const res = await appClient.events.findEventMonthly(
        association.associationCd,
        new Date().getFullYear() + 1
      );
      if (res) setEventsMonthly(res);
    } catch (e) {
      // handle getEventsMonthly error
    }
  }, [association.associationCd, setEventsMonthly]);

  const getInitialValue = useCallback(
    (index: number) => {
      let dateText: string | undefined;
      switch (index) {
        case 1:
          dateText = eventsMonthly?.monthlyPurchase?.date1;
          break;
        case 2:
          dateText = eventsMonthly?.monthlyPurchase?.date2;
          break;
        case 3:
          dateText = eventsMonthly?.monthlyPurchase?.date3;
          break;
        case 4:
          dateText = eventsMonthly?.monthlyPurchase?.date4;
          break;
        case 5:
          dateText = eventsMonthly?.monthlyPurchase?.date5;
          break;
        case 6:
          dateText = eventsMonthly?.monthlyPurchase?.date6;
          break;
        case 7:
          dateText = eventsMonthly?.monthlyPurchase?.date7;
          break;
        case 8:
          dateText = eventsMonthly?.monthlyPurchase?.date8;
          break;
        case 9:
          dateText = eventsMonthly?.monthlyPurchase?.date9;
          break;
        case 10:
          dateText = eventsMonthly?.monthlyPurchase?.date10;
          break;
        case 11:
          dateText = eventsMonthly?.monthlyPurchase?.date11;
          break;
        case 12:
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          dateText = eventsMonthly?.monthlyPurchase?.date12;
          break;
        default:
          dateText = undefined;
          break;
      }
      if (dateText) {
        return new Date(dateText).getDate().toString();
      }
      return '';
    },
    [eventsMonthly]
  );

  const getValue = useCallback(() => {
    if (!eventsMonthly) return;
    setSelectedDays({
      date1: eventsMonthly?.monthlyPurchase?.date1
        ? new Date(eventsMonthly?.monthlyPurchase?.date1).getDate().toString()
        : undefined,
      date2: eventsMonthly?.monthlyPurchase?.date2
        ? new Date(eventsMonthly?.monthlyPurchase?.date2 || '')
            .getDate()
            .toString()
        : undefined,
      date3: eventsMonthly?.monthlyPurchase?.date3
        ? new Date(eventsMonthly?.monthlyPurchase?.date3 || '')
            .getDate()
            .toString()
        : undefined,
      date4: eventsMonthly?.monthlyPurchase?.date4
        ? new Date(eventsMonthly?.monthlyPurchase?.date4 || '')
            .getDate()
            .toString()
        : undefined,
      date5: eventsMonthly?.monthlyPurchase?.date5
        ? new Date(eventsMonthly?.monthlyPurchase?.date5 || '')
            .getDate()
            .toString()
        : undefined,
      date6: eventsMonthly?.monthlyPurchase?.date6
        ? new Date(eventsMonthly?.monthlyPurchase?.date6 || '')
            .getDate()
            .toString()
        : undefined,
      date7: eventsMonthly?.monthlyPurchase?.date7
        ? new Date(eventsMonthly?.monthlyPurchase?.date7 || '')
            .getDate()
            .toString()
        : undefined,
      date8: eventsMonthly?.monthlyPurchase?.date8
        ? new Date(eventsMonthly?.monthlyPurchase?.date8 || '')
            .getDate()
            .toString()
        : undefined,
      date9: eventsMonthly?.monthlyPurchase?.date9
        ? new Date(eventsMonthly?.monthlyPurchase?.date9 || '')
            .getDate()
            .toString()
        : undefined,
      date10: eventsMonthly?.monthlyPurchase?.date10
        ? new Date(eventsMonthly?.monthlyPurchase?.date10 || '')
            .getDate()
            .toString()
        : undefined,
      date11: eventsMonthly?.monthlyPurchase?.date11
        ? new Date(eventsMonthly?.monthlyPurchase?.date11 || '')
            .getDate()
            .toString()
        : undefined,

      date12: eventsMonthly?.monthlyPurchase?.date12
        ? new Date(eventsMonthly?.monthlyPurchase?.date12 || '')
            .getDate()
            .toString()
        : undefined,
    });
  }, [eventsMonthly]);

  const getIsDisable = () =>
    selectedDays?.date1 === undefined ||
    selectedDays?.date2 === undefined ||
    selectedDays?.date3 === undefined ||
    selectedDays?.date4 === undefined ||
    selectedDays?.date5 === undefined ||
    selectedDays?.date6 === undefined ||
    selectedDays?.date7 === undefined ||
    selectedDays?.date8 === undefined ||
    selectedDays?.date9 === undefined ||
    selectedDays?.date10 === undefined ||
    selectedDays?.date11 === undefined ||
    selectedDays?.date12 === undefined;

  useEffect(() => {
    void getEventsMonthly();
    void getValue();
  }, []);

  const registrationDate = new Date(new Date().getFullYear(), 10, 30);

  return (
    <Wrapper>
      <HeaderWrapper>
        <NormalLink onClick={() => navigate(-1)}>
          スケジュール一覧に戻る
        </NormalLink>
        <HeaderTitle>月例の買付日を登録</HeaderTitle>
      </HeaderWrapper>
      <ContentWrapper>
        <ContentHeadingWrapper>
          <ContentHeadingLabelKey>買付日登録期日</ContentHeadingLabelKey>
          <ContentHeadingLabelValue>
            {format(registrationDate, 'yyyy/MM/dd')} (
            {ConvertDayToKanji(registrationDate.getDay())})
          </ContentHeadingLabelValue>
        </ContentHeadingWrapper>
        <TableWrapper>
          <TableColumn>
            <TableColumnHeaderRow>
              <TableColumnRowLabelWrapper
                sx={{
                  ...theme.typography['caption/regular'],
                  color: theme.palette.system['text-light'],
                }}
              >
                買付月
              </TableColumnRowLabelWrapper>
              <TableColumnRowContentWrapper
                sx={{
                  ...theme.typography['caption/regular'],
                  color: theme.palette.system['text-light'],
                }}
              >
                <Box>買付日</Box>
                <PopoverWithText
                  title="買付日"
                  text="ここで選択した日付が今回の買付日となります。"
                  icon={<QuestionCircledSvg width={12} height={12} />}
                />
              </TableColumnRowContentWrapper>
            </TableColumnHeaderRow>
            <TableColumnRow>
              <TableColumnRowLabelWrapper>1月</TableColumnRowLabelWrapper>
              <TableColumnRowContentWrapper>
                <SelectBox
                  items={monthDays(1)}
                  name="days"
                  sx={{ width: 72 }}
                  placeholder="選択"
                  setedValue={selectedDays?.date1}
                  value={selectedDays?.date1 || undefined}
                  onChange={(e) =>
                    setSelectedDays({
                      ...selectedDays,
                      date1: e.target.value as string,
                    })
                  }
                  selectBoxType
                />
                日
              </TableColumnRowContentWrapper>
            </TableColumnRow>
            <TableColumnRow>
              <TableColumnRowLabelWrapper>2月</TableColumnRowLabelWrapper>
              <TableColumnRowContentWrapper>
                <SelectBox
                  items={monthDays(
                    2,
                    new Date(new Date().getFullYear() + 1, 0, 1)
                  )}
                  name="days"
                  sx={{ width: 72 }}
                  setedValue={selectedDays?.date2}
                  placeholder="選択"
                  value={selectedDays?.date2 || getInitialValue(0)}
                  onChange={(e) =>
                    setSelectedDays({
                      ...selectedDays,
                      date2: e.target.value as string,
                    })
                  }
                  selectBoxType
                />
                日
              </TableColumnRowContentWrapper>
            </TableColumnRow>
            <TableColumnRow>
              <TableColumnRowLabelWrapper>3月</TableColumnRowLabelWrapper>
              <TableColumnRowContentWrapper>
                <SelectBox
                  items={monthDays(3)}
                  name="days"
                  sx={{ width: 72 }}
                  placeholder="選択"
                  setedValue={selectedDays?.date3}
                  value={selectedDays?.date3 || getInitialValue(0)}
                  onChange={(e) =>
                    setSelectedDays({
                      ...selectedDays,
                      date3: e.target.value as string,
                    })
                  }
                  selectBoxType
                />
                日
              </TableColumnRowContentWrapper>
            </TableColumnRow>
            <TableColumnRow>
              <TableColumnRowLabelWrapper>4月</TableColumnRowLabelWrapper>
              <TableColumnRowContentWrapper>
                <SelectBox
                  items={monthDays(4)}
                  name="days"
                  sx={{ width: 72 }}
                  placeholder="選択"
                  setedValue={selectedDays?.date4}
                  value={selectedDays?.date4 || getInitialValue(0)}
                  onChange={(e) =>
                    setSelectedDays({
                      ...selectedDays,
                      date4: e.target.value as string,
                    })
                  }
                  selectBoxType
                />
                日
              </TableColumnRowContentWrapper>
            </TableColumnRow>
            <TableColumnRow>
              <TableColumnRowLabelWrapper>5月</TableColumnRowLabelWrapper>
              <TableColumnRowContentWrapper>
                <SelectBox
                  items={monthDays(5)}
                  name="days"
                  sx={{ width: 72 }}
                  setedValue={selectedDays?.date5}
                  placeholder="選択"
                  value={selectedDays?.date5 || getInitialValue(0)}
                  onChange={(e) =>
                    setSelectedDays({
                      ...selectedDays,
                      date5: e.target.value as string,
                    })
                  }
                  selectBoxType
                />
                日
              </TableColumnRowContentWrapper>
            </TableColumnRow>
            <TableColumnRow sx={{ borderBottomWidth: 0 }}>
              <TableColumnRowLabelWrapper>6月</TableColumnRowLabelWrapper>
              <TableColumnRowContentWrapper>
                <SelectBox
                  items={monthDays(6)}
                  name="days"
                  sx={{ width: 72 }}
                  placeholder="選択"
                  setedValue={selectedDays?.date6}
                  value={selectedDays?.date6 || getInitialValue(0)}
                  onChange={(e) =>
                    setSelectedDays({
                      ...selectedDays,
                      date6: e.target.value as string,
                    })
                  }
                  selectBoxType
                />
                日
              </TableColumnRowContentWrapper>
            </TableColumnRow>
          </TableColumn>
          <TableColumn sx={{ borderRightWidth: 0 }}>
            <TableColumnHeaderRow>
              <TableColumnRowLabelWrapper
                sx={{
                  ...theme.typography['caption/regular'],
                  color: theme.palette.system['text-light'],
                }}
              >
                買付月
              </TableColumnRowLabelWrapper>
              <TableColumnRowContentWrapper
                sx={{
                  ...theme.typography['caption/regular'],
                  color: theme.palette.system['text-light'],
                }}
              >
                <Box>買付日</Box>
                <PopoverWithText
                  title="買付日"
                  text="ここで選択した日付が今回の買付日となります。"
                  icon={<QuestionCircledSvg width={12} height={12} />}
                />
              </TableColumnRowContentWrapper>
            </TableColumnHeaderRow>
            <TableColumnRow>
              <TableColumnRowLabelWrapper>7月</TableColumnRowLabelWrapper>
              <TableColumnRowContentWrapper>
                <SelectBox
                  items={monthDays(7)}
                  name="days"
                  sx={{ width: 72 }}
                  placeholder="選択"
                  setedValue={selectedDays?.date7}
                  value={selectedDays?.date7 || getInitialValue(0)}
                  onChange={(e) =>
                    setSelectedDays({
                      ...selectedDays,
                      date7: e.target.value as string,
                    })
                  }
                  selectBoxType
                />
                日
              </TableColumnRowContentWrapper>
            </TableColumnRow>
            <TableColumnRow>
              <TableColumnRowLabelWrapper>8月</TableColumnRowLabelWrapper>
              <TableColumnRowContentWrapper>
                <SelectBox
                  items={monthDays(8)}
                  name="days"
                  sx={{ width: 72 }}
                  placeholder="選択"
                  value={selectedDays?.date8 || getInitialValue(0)}
                  setedValue={selectedDays?.date8}
                  onChange={(e) =>
                    setSelectedDays({
                      ...selectedDays,
                      date8: e.target.value as string,
                    })
                  }
                  selectBoxType
                />
                日
              </TableColumnRowContentWrapper>
            </TableColumnRow>
            <TableColumnRow>
              <TableColumnRowLabelWrapper>9月</TableColumnRowLabelWrapper>
              <TableColumnRowContentWrapper>
                <SelectBox
                  items={monthDays(9)}
                  name="days"
                  sx={{ width: 72 }}
                  placeholder="選択"
                  value={selectedDays?.date9 || getInitialValue(0)}
                  setedValue={selectedDays?.date9}
                  onChange={(e) =>
                    setSelectedDays({
                      ...selectedDays,
                      date9: e.target.value as string,
                    })
                  }
                  selectBoxType
                />
                日
              </TableColumnRowContentWrapper>
            </TableColumnRow>
            <TableColumnRow>
              <TableColumnRowLabelWrapper>10月</TableColumnRowLabelWrapper>
              <TableColumnRowContentWrapper>
                <SelectBox
                  items={monthDays(10)}
                  name="days"
                  sx={{ width: 72 }}
                  placeholder="選択"
                  value={selectedDays?.date10 || getInitialValue(0)}
                  setedValue={selectedDays?.date10}
                  onChange={(e) =>
                    setSelectedDays({
                      ...selectedDays,
                      date10: e.target.value as string,
                    })
                  }
                  selectBoxType
                />
                日
              </TableColumnRowContentWrapper>
            </TableColumnRow>
            <TableColumnRow>
              <TableColumnRowLabelWrapper>11月</TableColumnRowLabelWrapper>
              <TableColumnRowContentWrapper>
                <SelectBox
                  items={monthDays(11)}
                  name="days"
                  sx={{ width: 72 }}
                  placeholder="選択"
                  value={selectedDays?.date11 || getInitialValue(0)}
                  setedValue={selectedDays?.date11}
                  onChange={(e) =>
                    setSelectedDays({
                      ...selectedDays,
                      date11: e.target.value as string,
                    })
                  }
                  selectBoxType
                />
                日
              </TableColumnRowContentWrapper>
            </TableColumnRow>
            <TableColumnRow sx={{ borderBottomWidth: 0 }}>
              <TableColumnRowLabelWrapper>12月</TableColumnRowLabelWrapper>
              <TableColumnRowContentWrapper>
                <SelectBox
                  items={monthDays(12)}
                  name="days"
                  sx={{
                    width: 72,
                  }}
                  placeholder="選択"
                  value={selectedDays?.date12 || getInitialValue(0)}
                  setedValue={selectedDays?.date12}
                  onChange={(e) =>
                    setSelectedDays({
                      ...selectedDays,
                      date12: e.target.value as string,
                    })
                  }
                  selectBoxType
                />
                日
              </TableColumnRowContentWrapper>
            </TableColumnRow>
          </TableColumn>
        </TableWrapper>
        <PrimaryButton
          sx={{ marginTop: '32px' }}
          disabled={getIsDisable()}
          onClick={async () => {
            await handleUpdate();
            navigate('/schedules');
          }}
        >
          入力内容を登録する
        </PrimaryButton>
      </ContentWrapper>
    </Wrapper>
  );
};
