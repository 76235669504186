/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  styled,
  SvgIcon,
  SxProps,
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { theme } from '@/theme/theme';
import { Title } from '@/components/molecules/Title/Title';
import { SelectBox } from '@/components/atoms/Input/SelectBox';
import { backofficeUsers } from '@/constants/SelectBox/choicesForSelectBox';
import { BasicTable } from '@/components/molecules/Table/BasicTable';
import { TableHeadRow } from '@/components/atoms/Table/TableHeadRow';
import { TableHeadCell } from '@/components/atoms/Table/TableHeadCell';
import { TableBodyRow } from '@/components/atoms/Table/TableBodyRow';
import { TableBodyCell } from '@/components/atoms/Table/TableBodyCell';
import { CustomDropdown } from '@/components/molecules/Dropdown/CustomDropdown';
import { StatusBadge } from '@/components/atoms/Badge/StatusBadge';
import { ReactComponent as Error } from '@/assets/errorOops.svg';
import { ReactComponent as NarrowingIcon } from '@/assets/narrowing.svg';
import { appClient } from '@/services';
import { useRecoilValue, useRecoilState } from 'recoil';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { PopoverWithText } from '@/components/molecules/Popover/Popover';
import {
  pageState as pageValue,
  totalPageState,
  perPageSizeState,
  totalSizeState,
} from '@/recoil/pagination';
import { ReactComponent as Search } from '@/assets/search.svg';
import { ReactComponent as CancelSearch } from '@/assets/cancelSearch.svg';
import { format } from 'date-fns';
import { formatDateTimeWithSlash } from '@/utils/dateFunction';
import { ja } from 'date-fns/locale';
import {
  ListMembers,
  ListMembersItem,
  ListMembersParams,
} from '@/types/api/members';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

type SortKeyType =
  | 'MEMBER_CD_ASC'
  | 'MEMBER_CD_DESC'
  | 'MEMBER_NAME_ASC'
  | 'MEMBER_NAME_DESC'
  | 'USER_CONTRIBUTIONSTATUS_ASC'
  | 'USER_CONTRIBUTIONSTATUS_DESC'
  | 'EXPIRYDAY_ASC'
  | 'EXPIRYDAY_DESC';

const Wrapper = styled(Box)({});

const HeaderSection = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const TitleWrapper = styled(Box)({
  padding: '32px 40px',
  backgroundColor: theme.palette.system['background-light'],
  borderWidth: 0,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.system['separator-light'],
});

const ContentWrapper = styled(Box)({
  padding: '32px 40px',
});

const OptionsWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const TableWrapper = styled(Box)({
  marginTop: '8px',
});

const BadgeSx: SxProps = {
  ...theme.typography['caption/medium'],
  width: 48,
  paddingTop: '2px',
  paddingBottom: '2px',
};

const SearchWrapper = styled(Box)({
  display: 'flex',
  gap: '8px',
});

const LeftWrapper = styled(Box)({
  display: 'flex',
  gap: '4px',
});

const NarrowingWrapper = styled(Box)({
  position: 'relative',
});

const NarrowingButton = styled(Button)(() => {
  const { palette } = theme;
  return {
    border: '1px solid',
    borderColor: palette.system.separator,
    borderRadius: '4px',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    color: palette.system['text-normal'],
    height: '38px',
    '&:hover': {
      backgroundColor: palette.system.white,
    },
  };
});

const Row = styled(Box)({
  display: 'flex',
  gap: '16px',
  alignItems: 'flex-start',
});

const OptionWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const OptionsRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
});

const LabelWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: 56,
  height: '41px',
});

const LabelText = styled(Typography)(() => {
  const { typography } = theme;

  return {
    ...typography['body-sub/regular'],
  };
});

const MuiButton = styled(Button)(() => {
  const { typography, palette } = theme;
  return {
    ...typography['body-main/medium'],
    padding: '8px 16px',
    backgroundColor: palette.system['background-dark'],
    borderRadius: '4px',
    color: palette.system['text-normal'],
    minWidth: '79px',
    '&:hover': {
      background: palette.system.inactive,
    },
  };
});

export const MembersIndex = () => {
  const [page, setPage] = useRecoilState(pageValue);
  const [, setTotalPage] = useRecoilState(totalPageState);
  const [, setPerPageSize] = useRecoilState(perPageSizeState);
  const [, setTotalSize] = useRecoilState(totalSizeState);
  const [members, setMembers] = useState<ListMembers['members']>();
  const [selection] = useState<number[]>([]);
  const associationData = useRecoilValue(SelectedAssociationState);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filter, setFilter] = useState<string[]>([]);
  const [supervisorFilter, setSuperVisorFilter] = useState('');
  const [searchText, setSearchText] = useState('');
  const [userContributionStatus, setUserContributionStatus] = useState<
    '稼働中' | '休止中' | undefined
  >();
  const [userRegisteredStatus, setUserRegisteredStatus] = useState<
    '未登録' | '失効' | '登録済' | 'MFA未登録' | undefined
  >();
  const [sortKey, setSortKey] =
    useState<Exclude<ListMembersParams['sortKey'], undefined>>('MEMBER_CD_ASC');
  const [keyword, setKeyword] = useState<string>();
  useHelmetHandler({
    title: '持株会会員一覧',
  });

  const userContributionStatusToParam = () => {
    switch (userContributionStatus) {
      case '休止中':
        return 'STOP';
      case '稼働中':
        return 'ACTIVE';
      default:
        return undefined;
    }
  };

  const uRegisteredStatusToParam = () => {
    switch (userRegisteredStatus) {
      case '失効':
        return 'REVOCATION';
      case '未登録':
        return 'UNREGISTERED';
      case '登録済':
        return 'REGISTERED';
      default:
        return undefined;
    }
  };

  const retirieveData = useCallback(async () => {
    try {
      if (!associationData?.associationCd) return;
      const res = await appClient.members.listMembers(
        associationData?.associationCd,
        undefined,
        keyword === '' ? undefined : keyword,
        userContributionStatusToParam(),
        uRegisteredStatusToParam(),
        page || 1,
        20,
        sortKey
      );
      if (res.members) setMembers([...res.members]);
      setPerPageSize(res.perPageSize || 0);
      setTotalPage(res.totalPage || 0);
      setTotalSize(res.totalSize || 0);
    } catch (e) {
      // Handle error
    }
  }, [
    keyword,
    page,
    sortKey,
    userContributionStatus,
    userRegisteredStatus,
    associationData,
    setPerPageSize,
    setTotalPage,
    setTotalSize,
  ]);

  const handleSearchSubmit = (_keyword?: string) => {
    setKeyword(_keyword || '');
    setPage(1);
    setUserContributionStatus(undefined);
    setUserRegisteredStatus(undefined);
  };

  const updateFilter = (
    type: 'ADD' | 'REMOVE',
    value: string,
    supervisor: boolean
  ) => {
    if (type === 'REMOVE') {
      filter.splice(filter.indexOf(value), 1);
      setFilter([...filter]);
    } else {
      if (supervisor) {
        // Remove the old supervisor name from filter list
        if (supervisorFilter)
          filter.splice(filter.indexOf(supervisorFilter), 1, value);
        else filter.push(value);
        // Update the filter list and supervisor name
        setSuperVisorFilter(value);
        setFilter([...filter]);
        return;
      }
      filter.push(value);
      setFilter([...filter]);
    }
  };

  const updateUserContributionStatus = (value: '稼働中' | '休止中') => {
    setPage(1);
    if (userContributionStatus === value) setUserContributionStatus(undefined);
    else setUserContributionStatus(value);
  };

  const updateUserRegisteredStatus = (value: '未登録' | '失効' | '登録済') => {
    setPage(1);
    if (userRegisteredStatus === value) setUserRegisteredStatus(undefined);
    else setUserRegisteredStatus(value);
  };

  const tableRowBackgroundColor = (memberId?: number) => {
    if (memberId && selection.includes(memberId)) return 'secondary.bg';
    return undefined;
  };

  const handleMouseDown = () => {
    setSearchText('');
    handleSearchSubmit();
  };

  const handleClickDelete = () => {
    setSearchText('');
  };

  const renderTableRow = (data: ListMembersItem) => {
    const emptySymbol = '-';
    const MultiFactorAuthentication = () => {
      if (data.userRegisteredStatus === 'UNREGISTERED' && data.userId !== '') {
        data.userRegisteredStatus = 'MULTIFACTOR';
      }
    };
    MultiFactorAuthentication();

    return (
      <TableBodyRow
        key={data.memberId}
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        url={data.memberId !== undefined ? `${data.memberId}/information` : ''}
        sx={{
          // opacity: data.disabled ? 0.5 : 1,
          backgroundColor: tableRowBackgroundColor(data.memberId),
        }}
      >
        <TableBodyCell value={data.memberCd} />
        <TableBodyCell value={data.employeeCd} />
        <TableBodyCell value={data.nameKanji} />
        <TableBodyCell type="badge">
          {data.userContributionStatus ? (
            <StatusBadge
              sx={{
                ...BadgeSx,
              }}
              status={data.userContributionStatus}
            />
          ) : (
            emptySymbol
          )}
        </TableBodyCell>
        <TableBodyCell
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
        >
          {data.expiryDay && data.userRegisteredStatus === 'UNREGISTERED' && (
            <Typography
              color={theme.palette.system.table}
              variant="body-sub/regular"
            >
              {formatDateTimeWithSlash(data.expiryDay, true)}
            </Typography>
          )}
          {data.userRegisteredStatus === 'REVOCATION' && data.expiryDay && (
            <>
              <Typography
                color={theme.palette.states.error}
                variant="body-sub/regular"
              >
                {formatDateTimeWithSlash(data.expiryDay, true)}
              </Typography>
              <PopoverWithText
                icon={
                  <SvgIcon sx={{ width: 16, height: 16 }}>
                    <Error />
                  </SvgIcon>
                }
                text="専用URLが消失しています。"
              />
            </>
          )}
          {data.userRegisteredStatus === 'REGISTERED' && '-'}
        </TableBodyCell>
        <TableBodyCell>
          {data.userRegisteredStatus && (
            <StatusBadge
              sx={{ width: '48px' }}
              status={data.userRegisteredStatus}
            />
          )}
        </TableBodyCell>
      </TableBodyRow>
    );
  };

  const renderDropdownRow = (
    data: { label: string; options?: string[][] },
    setState: (value: string) => void,
    currentValue: string
  ) => (
    <Row key={data.label}>
      <LabelWrapper>
        <LabelText>{data.label}</LabelText>
      </LabelWrapper>
      <OptionWrapper>
        {data.options ? (
          data.options.map((row, index) => (
            <OptionsRow key={`data_row_${index + 1}`}>
              {row.map((value) => (
                <MuiButton
                  key={value}
                  onClick={() => setState(value)}
                  sx={{
                    backgroundColor:
                      value === currentValue
                        ? theme.palette.secondary.main
                        : theme.palette.system.background,
                    color:
                      value === currentValue
                        ? theme.palette.system.white
                        : theme.palette.system['text-normal'],
                  }}
                >
                  {value}
                </MuiButton>
              ))}
            </OptionsRow>
          ))
        ) : (
          <SelectBox
            items={backofficeUsers}
            width="200px"
            updateValue={(value: string) => updateFilter('ADD', value, true)}
          />
        )}
      </OptionWrapper>
    </Row>
  );

  useEffect(() => {
    void retirieveData();
  }, [retirieveData]);

  useEffect(() => {
    setPage(1);
  }, []);

  const formatedToday = format(new Date(), 'yyyy/MM/dd (E) HH:mm', {
    locale: ja,
  });

  return (
    <Wrapper>
      <TitleWrapper>
        <Title title="会員一覧" subtitle={`${formatedToday} 現在`} />
      </TitleWrapper>
      <ContentWrapper>
        <HeaderSection>
          <OptionsWrapper>
            <SearchWrapper>
              <LeftWrapper>
                <TextField
                  placeholder="会員名、会員コード、社員コードを入力"
                  name="memberSearch"
                  sx={{
                    width: '316px',
                  }}
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    handleSearchSubmit(e.target.value);
                  }}
                  InputProps={{
                    style: {
                      paddingLeft: '0px',
                      backgroundColor: theme.palette.system['background-light'],
                      borderColor: theme.palette.system.separator,
                      fontSize: '14px',
                    },
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ marginLeft: '8px' }}
                      >
                        <Search />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleClickDelete}
                          onMouseDown={handleMouseDown}
                        >
                          <CancelSearch />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </LeftWrapper>
              <NarrowingWrapper>
                <NarrowingButton
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  <NarrowingIcon />
                  絞り込み
                </NarrowingButton>
                {isDropdownOpen && (
                  <CustomDropdown
                    onClickAway={() => setIsDropdownOpen(false)}
                    sx={{ width: 359, left: 0, top: 39 }}
                  >
                    {renderDropdownRow(
                      {
                        label: '拠出状況',
                        options: [['稼働中', '休止中']],
                      },
                      (value) =>
                        updateUserContributionStatus(
                          value as '稼働中' | '休止中'
                        ),
                      userContributionStatus as string
                    )}
                    {renderDropdownRow(
                      {
                        label: 'web状況',
                        options: [['未登録', '失効', '登録済']],
                      },
                      (value) =>
                        updateUserRegisteredStatus(
                          value as '未登録' | '失効' | '登録済'
                        ),
                      userRegisteredStatus as string
                    )}
                  </CustomDropdown>
                )}
              </NarrowingWrapper>
            </SearchWrapper>
          </OptionsWrapper>
        </HeaderSection>
        <TableWrapper>
          <BasicTable>
            <TableHeadRow>
              <TableHeadCell
                type={sortKey.includes('MEMBER_CD') ? 'sort' : 'normal'}
                sort={() => {}}
                sortType={sortKey.includes('ASC') ? 'ASC' : 'DESC'}
                width="9%"
              >
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (sortKey === 'MEMBER_CD_ASC')
                      setSortKey('MEMBER_CD_DESC');
                    else setSortKey('MEMBER_CD_ASC');
                  }}
                >
                  会員コード
                </Box>
              </TableHeadCell>
              <TableHeadCell
                type={sortKey.includes('EMPLOYEE_CD') ? 'sort' : 'normal'}
                sort={() => {}}
                sortType={sortKey.includes('ASC') ? 'ASC' : 'DESC'}
                width="9%"
              >
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (sortKey === 'EMPLOYEE_CD_ASC')
                      setSortKey('EMPLOYEE_CD_DESC');
                    else setSortKey('EMPLOYEE_CD_ASC');
                  }}
                >
                  社員コード
                </Box>
              </TableHeadCell>
              <TableHeadCell
                type={sortKey.includes('MEMBER_NAME') ? 'sort' : 'normal'}
                sort={() => {}}
                sortType={sortKey.includes('ASC') ? 'ASC' : 'DESC'}
                width="36%"
              >
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (sortKey === 'MEMBER_NAME_ASC')
                      setSortKey('MEMBER_NAME_DESC');
                    else setSortKey('MEMBER_NAME_ASC');
                  }}
                >
                  会員名
                </Box>
              </TableHeadCell>
              <TableHeadCell
                type={
                  sortKey.includes('USER_CONTRIBUTIONSTATUS')
                    ? 'sort'
                    : 'normal'
                }
                sort={() => {}}
                sortType={sortKey.includes('ASC') ? 'ASC' : 'DESC'}
                width="12%"
              >
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (sortKey === 'USER_CONTRIBUTIONSTATUS_ASC')
                      setSortKey('USER_CONTRIBUTIONSTATUS_DESC');
                    else setSortKey('USER_CONTRIBUTIONSTATUS_ASC');
                  }}
                >
                  拠出状況
                </Box>
              </TableHeadCell>
              <TableHeadCell
                type={sortKey.includes('EXPIRYDAY') ? 'sort' : 'normal'}
                sort={() => {}}
                sortType={sortKey.includes('ASC') ? 'ASC' : 'DESC'}
                width="18%"
              >
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (sortKey === 'EXPIRYDAY_ASC')
                      setSortKey('EXPIRYDAY_DESC');
                    else setSortKey('EXPIRYDAY_ASC');
                  }}
                >
                  専用URL/初回PASS失効日
                </Box>
              </TableHeadCell>
              <TableHeadCell title="登録状況" width="12%" />
            </TableHeadRow>
            {members?.map((d) => renderTableRow(d))}
          </BasicTable>
        </TableWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};
