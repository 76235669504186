/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/extensions */
import { NotificationFormEdit } from '@/components/organisms/Notification/NotificationFormEdit';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useEffect, useState, FC } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { NotidicationInputs as Inputs } from '@/types/components/Inputs';
import { NotificationInformation } from '@/types/api/Informations/NotificationInformation';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { appClient } from '@/services';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

export const InformationEdit: FC = () => {
  const association = useRecoilValue(SelectedAssociationState);
  const informationId = Number(useParams().associationInformationId || 0);
  const [information, setInformation] = useState<NotificationInformation>();
  const getInformation = async () => {
    try {
      const res = await appClient.informations.findMemberInformationById(
        informationId
      );
      if (res) {
        setInformation(res);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const [modal, setModal] = useState<string>('');
  const [isConfirming, setIsConfirming] = useState(false);
  useHelmetHandler({
    title: isConfirming ? '会員向けお知らせ 内容確認' : '会員向けお知らせ 内容',
  });

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    try {
      await appClient.informations.updateMemberInformation(
        Number(informationId),
        {
          memberInformationId: Number(informationId),
          associationCd: association.associationCd,
          displayPeriodFrom: data.startDate,
          displayPeriodTo: data.endDate,
          informationTitle: data.title,
          informationBody: data.description,
          priority: data.priority || 'LOW',
          informationStatus: information?.informationStatus,
        }
      );
      setModal('confirming');
    } catch (e) {
      console.log(e);
    }
  };

  const withdrawInformation = async () => {
    try {
      await appClient.informations.updateMemberInformation(
        Number(informationId),
        {
          ...information,
          informationStatus: 'DROP',
        }
      );
      setModal('withdrawlConfirmed');
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    void getInformation();
  }, []);

  if (information) {
    return (
      <NotificationFormEdit
        isGeneral
        informationData={information}
        onSubmit={onSubmit}
        modal={modal}
        setModal={setModal}
        isConfirming={isConfirming}
        setIsConfirming={setIsConfirming}
        withdrawalInformation={withdrawInformation}
        associationName={association.associationName || ''}
      />
    );
  }
  return null;
};
