import { AdminOrganism } from '@/components/organisms/Login/AdminOrganism';
import { Path } from '@/constants/Router/path';
import { useSnackbar } from '@/hooks/useSnackbar';
import { appClient } from '@/services';
import { ErrorResponse } from '@/types/api/error';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginState } from './PasswordSetting';

export interface AdminFormInput {
  firstNameKanji: string;
  lastNameKanji: string;
}

export const Admin = () => {
  const [isError, setIsError] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LoginState;
  const { showSnackbar } = useSnackbar();

  const methods = useForm({
    defaultValues: {
      firstNameKanji: '',
      lastNameKanji: '',
    },
  });

  const handleClickButton = async (data: AdminFormInput) => {
    await appClient.officeUsers.updateOfficeUsers(data).catch((e) => {
      showSnackbar((e as ErrorResponse).data.message);
    });
    navigate(Path.HOME, { state });
  };

  return (
    <AdminOrganism
      isError={isError}
      setIsError={setIsError}
      isConfirmed={isConfirmed}
      setIsConfirmed={setIsConfirmed}
      methods={methods}
      state={state}
      handleClickButton={handleClickButton}
    />
  );
};
