/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Box, styled } from '@mui/material';
import { FC } from 'react';
import { WorkReportInputs as Inputs } from '@/types/components/Inputs';
import { FindWorkreportsById } from '@/types/api/workreports';
import { Control, FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { Changes } from './Changes';
import { Part } from './Part';
import { PurchaseDay } from './PurchaseDay';
import { Purchases } from './Purchases';

interface Props {
  isListed: boolean;
  isSubmit: 'BEFORESUBMISSION' | 'SUBMISSION' | undefined;
  control: Control<Inputs>;
  modalStatus: boolean;
  data: Inputs;
  report: Required<FindWorkreportsById>;
  errors: FieldErrorsImpl<Inputs>;
  register: UseFormRegister<Inputs>;
}

const ColumnBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '24px',
});

export const MonthlyWorkReport: FC<Props> = ({
  isListed,
  isSubmit,
  control,
  modalStatus,
  data,
  report,
  register,
  errors,
}) => (
  <Box sx={{ display: 'flex', gap: '24px' }}>
    <ColumnBox>
      <Changes report={report} />
    </ColumnBox>
    <ColumnBox>
      <Purchases
        isSubmit={isSubmit}
        control={control}
        modalStatus={modalStatus}
        data={data}
        report={report}
        register={register}
        errors={errors}
        type="monthly"
      />
      {isListed && (
        <>
          <Part report={report} />
          <PurchaseDay report={report} />
        </>
      )}
    </ColumnBox>
  </Box>
);
