/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Stack } from '@mui/material';
import { theme } from '@/theme/theme';
import { TopMessage } from '@/components/organisms/Message/TopMessage';
import { FC } from 'react';
import { useInformations } from '@/services/customHooks/useInformations';
import { InfomationItem } from '@/types/api/Infomation';

interface HomeMessageSectionProps {
  associationCd: string;
}

export const HomeMessageSection: FC<HomeMessageSectionProps> = ({
  associationCd,
}) => {
  const { informations } = useInformations(associationCd);

  return informations && informations.length === 0 ? null : (
    <Stack spacing={1} mb={4}>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
      {informations?.map((information: InfomationItem) => {
        let bgColor = theme.palette.states['error-bg'];
        let color = theme.palette.states.error;
        if (information.priority === 'LOW') {
          bgColor = '#E0F4F5';
          color = '#0B5D61';
        }
        if (information.priority === 'MIDDLE') {
          bgColor = '#FFEDE3';
          color = theme.palette.primary.normal;
        }
        return (
          <TopMessage
            key={information.associationInformationId}
            title={information.informationTitle}
            bg={bgColor}
            color={color}
            message={information.informationBody}
          />
        );
      })}
    </Stack>
  );
};
