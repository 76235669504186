/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FC, useCallback } from 'react';
import {
  styled,
  Box,
  Stack,
  TextField,
  Radio,
  Typography,
  RadioGroup,
} from '@mui/material';
import { theme } from '@/theme';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { WithdrawalEditInputs as Inputs } from '@/types/components/Inputs';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { reasonsForWithdrawal } from '@/constants/SelectBox/choicesForSelectBox';
import { MemberById } from '@/types/api/member';
import { GetAssociation } from '@/types/api/associations';
import { FindApplicationByID } from '@/types/api/Applications/application';
import { SelectBox } from '@/components/atoms/Input/SelectBox';
import { PrevnextApplications } from '@/types/api/Applications/prevnextApplications';
import { InputValidation } from '@/utils/validation';
import { useMemberSecuritiesAccount } from '@/services/customHooks/useMemberSecuritiesAccount';
import { TitleHead } from '../Applications/SharedParts/TitleHead';
import { UserInfoTable } from '../Applications/SharedParts/UserInfoTable';
import { ApplicationBottom } from '../Applications/SharedParts/ApplicationBottom';
import { ApplicationTable as Table } from '../Applications/SharedParts/ApplicationTable';
import { ApplicationTableRow as Row } from '../Applications/SharedParts/ApplicationTableRow';

const BasicWrapper = styled(Box)({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
});
const RadioWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const RadioButton = styled(Radio)({
  padding: '0px',
  color: theme.palette.secondary.main,
  '&.Mui-checked': {
    color: theme.palette.secondary.main,
  },
});

const SmallWrapper = styled(Box)({
  display: 'flex',
  gap: '8px',
  color: theme.palette.system['text-light'],
});

const YuchoInputWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  justifyContent: 'flex-start',
}));

const YuchoInputLabel = styled(Typography)(() => ({
  ...theme.typography['body-sub/regular'],
  color: theme.palette.system['text-normal'],
}));

const YuchoSeparator = styled(YuchoInputWrapper)(() => ({
  ...theme.typography['body-sub/regular'],
  margin: '0 8px',
  alignItems: 'flex-end',
  color: theme.palette.system['text-normal'],
  lineHeight: '40px',
}));

interface WithrawalProps {
  application: FindApplicationByID;
  associationData: GetAssociation;
  unitType: string;
  userData: MemberById;
  bottomData: PrevnextApplications | undefined;
  onSubmit: SubmitHandler<Inputs>;
}

export const ApplicationsWithdrawalEdit: FC<WithrawalProps> = ({
  application,
  unitType,
  userData,
  bottomData,
  associationData,
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      reason: application?.applicationWithdrawal?.reason,
      otherReasons: application.applicationWithdrawal?.otherReasons,
      generalAccount: {
        institutionCode:
          application?.applicationWithdrawal?.generalAccount?.institutionCode,
        institutionName:
          application?.applicationWithdrawal?.generalAccount?.institutionName,
        branchCode:
          application?.applicationWithdrawal?.generalAccount?.branchCode,
        branchName:
          application?.applicationWithdrawal?.generalAccount?.branchName,
        accountType:
          application?.applicationWithdrawal?.generalAccount?.accountType,
        accountNumber:
          application?.applicationWithdrawal?.generalAccount?.accountNumber,
      },
      isAdditionalOption:
        application?.applicationWithdrawal?.isAdditionalOption ?? false,
      institutionType: application?.applicationWithdrawal?.institutionType,
      accountYuchoSymbol: application?.applicationWithdrawal?.symbol,
      accountYuchoNumber: application?.applicationWithdrawal?.number,
      addPurchaseSharesNum:
        application?.applicationWithdrawal?.addPurchaseSharesNum,
    },
    mode: 'onSubmit',
  });

  const institutionType = watch('institutionType');
  const accountYuchoSymbol = watch('accountYuchoSymbol');
  const accountYuchoNumber = watch('accountYuchoNumber');
  const generalAccount = watch('generalAccount');
  const reason = watch('reason');

  const disabled = useCallback(() => {
    let result = true;
    if (institutionType === 'OTHER') {
      const {
        institutionCode,
        institutionName,
        branchCode,
        branchName,
        accountNumber,
      } = generalAccount;
      result = ![
        institutionCode,
        institutionName,
        branchCode,
        branchName,
        accountNumber,
      ].some((item) => item === '');
    } else {
      result =
        accountYuchoSymbol !== '' && accountYuchoNumber !== '' && !!reason;
    }
    return !result;
  }, [
    institutionType,
    generalAccount,
    accountYuchoSymbol,
    accountYuchoNumber,
    reason,
  ]);

  const setInputClear = () => {
    setValue('accountYuchoNumber', '');
    setValue('accountYuchoSymbol', '');
    setValue('generalAccount.institutionCode', '');
    setValue('generalAccount.institutionName', '');
    setValue('generalAccount.branchCode', '');
    setValue('generalAccount.branchName', '');
    setValue('generalAccount.accountNumber', '');
  };

  const { data: memberSecurityAccount } = useMemberSecuritiesAccount(
    Number(userData.memberId)
  );

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.system['background-dark'],
        height: '100%',
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack
        spacing={4}
        sx={{
          backgroundColor: theme.palette.system.white,
          padding: '40px 160px',
        }}
      >
        <TitleHead
          title="退会"
          status={application.applicationStatus || ''}
          applicationDate={application.applicationDate || ''}
          applicationId={application.applicationId || ''}
          associationCd={application.associationCd || ''}
          proxyApplicantUserName={application.proxyApplicantUserName}
          unitType={unitType}
        />
        <UserInfoTable data={userData} />
        <Table title="証券口座">
          {memberSecurityAccount &&
          memberSecurityAccount?.accountNumber &&
          memberSecurityAccount?.branchCd &&
          memberSecurityAccount?.branchName ? (
            <>
              <Row labels="金融機関名" values="東京東海証券" />
              <Row
                labels="部店コード"
                values={memberSecurityAccount.branchCd?.replace(
                  memberSecurityAccount.branchCd,
                  '*'.repeat(memberSecurityAccount.branchCd?.length)
                )}
              />
              <Row
                labels="部店名"
                values={memberSecurityAccount.branchName?.replace(
                  memberSecurityAccount.branchName,
                  '*'.repeat(memberSecurityAccount.branchName?.length)
                )}
                isEven
              />
              <Row
                labels="口座番号"
                values={memberSecurityAccount.accountNumber?.replace(
                  memberSecurityAccount.accountNumber,
                  '*'.repeat(memberSecurityAccount.accountNumber?.length)
                )}
                isEven
              />
            </>
          ) : (
            <Typography
              sx={{
                ...theme.typography['body-sub/regular'],
                color: theme.palette.system['text-normal'],
              }}
            >
              未登録です。
            </Typography>
          )}
        </Table>
        <Table title="退会理由" isRed>
          <Row>
            <Stack gap={2}>
              <Controller
                name="reason"
                control={control}
                render={({ field }) => (
                  <SelectBox
                    width="320px"
                    {...field}
                    isKeyAValue
                    renderValue={undefined}
                    items={reasonsForWithdrawal}
                  />
                )}
              />

              {watch('reason') === 'OTHER' && (
                <Controller
                  name="otherReasons"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      maxRows={4}
                      multiline
                      placeholder="退会理由を入力してください"
                      sx={{ width: '100%', minHeight: '130px' }}
                      inputProps={{
                        style: {
                          color: theme.palette.system['text-normal'],
                          height: '96px',
                          resize: 'none',
                        },
                      }}
                    />
                  )}
                />
              )}
            </Stack>
          </Row>
        </Table>
        {associationData.withdrawalAllSettlementUsageClassification !== '1' && (
          <Table title="精算方法" isRed>
            <Row labels={['売買単位', '未満株式持分']} isEven>
              {application.applicationWithdrawal?.isAdditionalOption ? (
                <Stack>
                  臨時拠出により買増し
                  <BasicWrapper>
                    <SmallWrapper>
                      <Typography variant="body-sub/regular">
                        振込金額
                      </Typography>
                      <Typography variant="body-sub/bold">
                        {application.applicationWithdrawal?.transferAmount?.toLocaleString() ||
                          0}
                        円
                      </Typography>
                    </SmallWrapper>
                    <SmallWrapper>
                      <Typography variant="body-sub/regular">
                        買増株数
                      </Typography>
                      <Typography variant="body-sub/bold">
                        {application?.applicationWithdrawal?.addPurchaseSharesNum?.toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits:
                              associationData.investmentDigit,
                          }
                        ) || 0}
                        株
                      </Typography>
                    </SmallWrapper>
                  </BasicWrapper>
                </Stack>
              ) : (
                <Typography>
                  持株会に売却（単元株以上は証券口座に移管）
                </Typography>
              )}
              {/* <Controller
                name="isAdditionalOption"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    onChange={(e) =>
                      setValue(
                        'isAdditionalOption',
                        e.target.value === 'true' || false
                      )
                    }
                    sx={{ display: 'flex', gap: '16px' }}
                  >
                    <RadioWrapper>
                      <RadioButton value={false} />
                    </RadioWrapper>
                    <RadioWrapper sx={{ alignItems: 'start' }}>
                      <RadioButton value />
                      <Stack>
                        臨時拠出により買増し
                        <BasicWrapper>
                          <SmallWrapper>
                            <Typography variant="body-sub/regular">
                              振込金額
                            </Typography>
                            <Typography variant="body-sub/bold">
                              {application.applicationWithdrawal?.transferAmount?.toLocaleString() ||
                                0}
                              円
                            </Typography>
                          </SmallWrapper>
                          <SmallWrapper>
                            <Typography variant="body-sub/regular">
                              買増株数
                            </Typography>
                            <Typography variant="body-sub/bold">
                              {application?.applicationWithdrawal?.addPurchaseSharesNum?.toLocaleString(
                                undefined,
                                {
                                  maximumFractionDigits:
                                    associationData.investmentDigit,
                                }
                              ) || 0}
                              株
                            </Typography>
                          </SmallWrapper>
                        </BasicWrapper>
                      </Stack>
                    </RadioWrapper>
                  </RadioGroup>
                )}
              /> */}
            </Row>
          </Table>
        )}
        <Table title="精算金振込口座" isRed>
          <Row labels="金融機関の種類">
            <Controller
              name="institutionType"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  name="institutionType"
                  sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}
                >
                  <RadioWrapper>
                    <RadioButton
                      value="OTHER"
                      name="institutionType"
                      onChange={() => setInputClear()}
                    />
                    ゆうちょ銀行以外
                  </RadioWrapper>
                  <RadioWrapper>
                    <RadioButton
                      value="YUCHO"
                      name="institutionType"
                      onChange={() => setInputClear()}
                    />
                    ゆうちょ銀行
                  </RadioWrapper>
                </RadioGroup>
              )}
            />
          </Row>

          {institutionType === 'OTHER' ? (
            <>
              <Row labels="金融機関コード" isEven>
                <Controller
                  name="generalAccount.institutionCode"
                  control={control}
                  rules={{
                    minLength: 4,
                    maxLength: 4,
                  }}
                  render={({ field }) => (
                    <TextField
                      error={
                        errors.generalAccount?.institutionCode !== undefined
                      }
                      value={field.value}
                      onChange={(e) => {
                        if (
                          InputValidation(e.target.value, {
                            maxLength: 4,
                            onlyNumber: true,
                          })
                        )
                          field.onChange(e);
                      }}
                      helperText={
                        errors.generalAccount?.institutionCode && '※半角数字4桁'
                      }
                      inputProps={{
                        style: {
                          maxHeight: '40px',
                          paddingLeft: '8px',
                        },
                      }}
                      sx={{ width: '116px' }}
                    />
                  )}
                />
              </Row>
              <Row labels="金融機関名">
                <Controller
                  name="generalAccount.institutionName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      value={field.value}
                      onChange={(e) => {
                        if (InputValidation(e.target.value, { maxLength: 18 }))
                          field.onChange(e);
                      }}
                      sx={{ width: '196px' }}
                      inputProps={{ style: { paddingLeft: '8px' } }}
                    />
                  )}
                />
              </Row>
              <Row labels="支店コード" isEven>
                <Controller
                  name="generalAccount.branchCode"
                  control={control}
                  rules={{
                    minLength: 3,
                    maxLength: 3,
                  }}
                  render={({ field }) => (
                    <TextField
                      error={errors.generalAccount?.branchCode !== undefined}
                      helperText={
                        errors.generalAccount?.branchCode && '※半角数字3桁'
                      }
                      value={field.value}
                      onChange={(e) => {
                        if (
                          InputValidation(e.target.value, {
                            maxLength: 3,
                            onlyNumber: true,
                          })
                        ) {
                          field.onChange(e);
                        }
                      }}
                      sx={{ width: '116px' }}
                      inputProps={{ style: { paddingLeft: '8px' } }}
                    />
                  )}
                />
              </Row>
              <Row labels="支店名">
                <Controller
                  name="generalAccount.branchName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      value={field.value}
                      onChange={(e) => {
                        if (InputValidation(e.target.value, { maxLength: 18 }))
                          field.onChange(e);
                      }}
                      sx={{ width: '196px' }}
                      inputProps={{ style: { paddingLeft: '8px' } }}
                    />
                  )}
                />
              </Row>
              <Row labels="種別" isEven>
                <Controller
                  name="generalAccount.accountType"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      name="generalAccount.accountType"
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '16px',
                      }}
                    >
                      <RadioWrapper>
                        <RadioButton
                          value="SAVINGS"
                          onChange={field.onChange}
                          name="generalAccount.accountType"
                        />
                        普通
                      </RadioWrapper>
                      <RadioWrapper>
                        <RadioButton
                          value="CURRENT"
                          onChange={field.onChange}
                          name="generalAccount.accountType"
                        />
                        当座
                      </RadioWrapper>
                      <RadioWrapper>
                        <RadioButton
                          value="OTHER"
                          onChange={field.onChange}
                          name="generalAccount.accountType"
                        />
                        貯蓄
                      </RadioWrapper>
                    </RadioGroup>
                  )}
                />
              </Row>
              <Row labels="口座番号">
                <Controller
                  name="generalAccount.accountNumber"
                  control={control}
                  rules={{
                    minLength: 7,
                    maxLength: 7,
                  }}
                  render={({ field }) => (
                    <TextField
                      error={errors.generalAccount?.accountNumber !== undefined}
                      helperText={
                        errors.generalAccount?.accountNumber && '※半角数字7桁'
                      }
                      value={field.value}
                      onChange={(e) => {
                        if (
                          InputValidation(e.target.value, {
                            maxLength: 7,
                            onlyNumber: true,
                          })
                        )
                          field.onChange(e);
                      }}
                      sx={{ width: '196px' }}
                      inputProps={{ style: { paddingLeft: '8px' } }}
                    />
                  )}
                />
              </Row>
            </>
          ) : (
            <Row labels="店番・番号" isEven>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <YuchoInputWrapper>
                  <YuchoInputLabel>店番</YuchoInputLabel>
                  <Controller
                    name="accountYuchoSymbol"
                    control={control}
                    rules={{
                      minLength: 1,
                      maxLength: 5,
                    }}
                    render={({ field }) => (
                      <TextField
                        error={errors.accountYuchoSymbol !== undefined}
                        helperText={errors.accountYuchoSymbol && '※半角数字5桁'}
                        value={field.value}
                        placeholder="例：12345"
                        onChange={(e) => {
                          if (
                            InputValidation(e.target.value, {
                              maxLength: 5,
                              onlyNumber: true,
                            })
                          )
                            field.onChange(e);
                        }}
                        sx={{ width: '104px' }}
                        inputProps={{ style: { paddingLeft: '8px' } }}
                      />
                    )}
                  />
                </YuchoInputWrapper>
                <YuchoInputWrapper sx={{ marginTop: '25px' }}>
                  <YuchoSeparator>-</YuchoSeparator>
                </YuchoInputWrapper>
                <YuchoInputWrapper>
                  <YuchoInputLabel>番号</YuchoInputLabel>
                  <Controller
                    name="accountYuchoNumber"
                    control={control}
                    rules={{
                      minLength: 1,
                      maxLength: 8,
                    }}
                    render={({ field }) => (
                      <TextField
                        error={errors.accountYuchoNumber !== undefined}
                        helperText={
                          errors.accountYuchoNumber && '※半角数字7桁(8桁)'
                        }
                        value={field.value}
                        placeholder="例：12345678"
                        onChange={(e) => {
                          if (
                            InputValidation(e.target.value, {
                              maxLength: 8,
                              onlyNumber: true,
                            })
                          )
                            field.onChange(e);
                        }}
                        sx={{ width: '128px' }}
                        inputProps={{ style: { paddingLeft: '8px' } }}
                      />
                    )}
                  />
                </YuchoInputWrapper>
              </Box>
            </Row>
          )}
        </Table>
        <PrimaryButton
          sx={{ width: '96px' }}
          type="submit"
          disabled={disabled()}
        >
          修正完了
        </PrimaryButton>
      </Stack>
      {bottomData && (
        <Box sx={{ padding: '32px 160px' }}>
          <ApplicationBottom data={bottomData} unitType={unitType} />
        </Box>
      )}
    </Box>
  );
};
