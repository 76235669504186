import { theme } from '@/theme';
import { Box, Divider, SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Label, StandardFieldWrapper } from './StyledComponent';

interface InputWithLabelAndDividerProps {
  label: string;
  labelAlignSelf?: string;
  subLabel?: ReactNode;
  children: ReactNode;
  sx?: SxProps;
}

export const InputWithLabelAndDivider: FC<InputWithLabelAndDividerProps> = ({
  label,
  labelAlignSelf = '',
  children,
  subLabel,
  sx = { padding: theme.spacing(2, 0) },
}) => (
  <>
    <StandardFieldWrapper sx={sx}>
      <Box
        alignSelf={labelAlignSelf}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Label>{label}</Label>
        {subLabel}
      </Box>
      {children}
    </StandardFieldWrapper>
    <Divider />
  </>
);
