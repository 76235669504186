import useSWR from 'swr';
import { appClient } from '..';

export const useMemberSecuritiesAccount = (memberId?: number) => {
  const { data } = useSWR(
    memberId ? `/api/members/${memberId}/securitiesaccounts` : null,
    () => appClient.members.findMemberSecuritiesAccount(memberId as number)
  );

  return {
    data,
  };
};
