/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { theme } from '@/theme';
import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { FindApplicationByID } from '@/types/api/Applications/application';
import { MemberById } from '@/types/api/member';
import { PrevnextApplications } from '@/types/api/Applications/prevnextApplications';
import { printApplicationsComponent } from '@/utils/printApplications';
import { TitleHead } from './SharedParts/TitleHead';
import { UserInfoTable } from './SharedParts/UserInfoTable';
import { ApplicationBottom } from './SharedParts/ApplicationBottom';
import { ApplicationTable as Table } from './SharedParts/ApplicationTable';
import { ApplicationTableRow as TableRow } from './SharedParts/ApplicationTableRow';
import { ApplicationButtons } from './SharedParts/ApplicationButtons';
import { PrintApplicationsButton } from './PrintApplicationsButton/PrintApplicationsButton';

interface RecessProps {
  application: FindApplicationByID;
  unitType: string;
  userData: MemberById;
  bottomData: PrevnextApplications | undefined;
  handleApplication: (value: 'APPROVAL' | 'REJECTED', comment?: string) => void;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export const ApplicationsRecess: FC<RecessProps> = ({
  application,
  unitType,
  userData,
  bottomData,
  isOpen,
  handleApplication,
  setIsOpen,
}) => {
  const switchRecessReason = () => {
    switch (application?.applicationRecess?.reason) {
      case 'LEAVE_ABSENCE':
        return '休職のため';
      case 'FAMILY_MATTERS':
        return '家族の都合';
      case 'ECONOMIC':
        return '経済的な事情';
      case 'OTHER':
        return ['その他', application.applicationRecess.otherReasons || ''];
      default:
        return '';
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.system['background-dark'],
        height: '100%',
      }}
    >
      <Stack
        spacing={4}
        sx={{
          backgroundColor: theme.palette.system.white,
          padding: '40px 160px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <PrintApplicationsButton
            onClick={() =>
              printApplicationsComponent('recess_print_content', '休止')
            }
          />
        </Box>
        <Stack id="recess_print_content" spacing={4}>
          <TitleHead
            title="休止"
            status={application.applicationStatus || ''}
            applicationDate={application.applicationDate || ''}
            applicationId={application.applicationId || ''}
            associationCd={application.associationCd || ''}
            proxyApplicantUserName={application.proxyApplicantUserName ?? ''}
            unitType={unitType}
            comment={application.rejectComment}
          />
          <UserInfoTable data={userData} />
          <Table title="休止理由" isRed>
            <TableRow values={switchRecessReason()} />
          </Table>
        </Stack>
        {application.applicationStatus !== 'COMPLETED' && application.eventNo && (
          <Stack direction="row" sx={{ alignItems: 'center' }} spacing={2}>
            <ApplicationButtons
              status={application.applicationStatus || ''}
              handleApplication={handleApplication}
              setIsOpen={setIsOpen}
              isOpen={isOpen}
            />
            {(application.applicationStatus === 'PREPARATION' ||
              application.applicationStatus === 'APPLYING') && (
              <NormalLink
                href={
                  application.applicationId
                    ? `/applications/${unitType}/${application.applicationId}/recess/edit`
                    : `/applications/${unitType}`
                }
              >
                申請内容を修正する
              </NormalLink>
            )}
          </Stack>
        )}
      </Stack>
      {bottomData && (
        <Box sx={{ padding: '32px 160px' }}>
          <ApplicationBottom data={bottomData} unitType={unitType} />
        </Box>
      )}
    </Box>
  );
};
