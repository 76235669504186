import { RowProps } from '@/types/components/Association';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';

export const AssociationRow: FC<RowProps> = ({ label, children }) => (
  <Box
    display="flex"
    gap={1}
    width="100%"
    pb={2}
    borderBottom="1px solid"
    borderColor="system.separator-light"
    alignItems="center"
  >
    <Typography
      variant="body-main/bold"
      color="system.text-normal"
      width={144}
      whiteSpace="nowrap"
    >
      {label}
    </Typography>
    {children}
  </Box>
);
