import {
  GetAssociationsContribution,
  ListAssociationItem,
} from '@/types/api/associations';
import { atom } from 'recoil';

export const SelectedAssociationState = atom({
  key: 'selected_association',
  default: {} as ListAssociationItem,
});

export const AssociationContributionState = atom({
  key: 'association_contribution',
  default: {} as GetAssociationsContribution,
});

export const HeaderAssociationsList = atom({
  key: 'associations_list',
  default: [] as ListAssociationItem[],
});
