/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthorizationService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * 【API-B-0101】事務局ログイン
   * 事務局ログイン
   *
   * @param requestBody auth signin request
   * @returns any auth signin response
   * @throws ApiError
   */
  public authSignin(requestBody: {
    /**
     * ログインID
     */
    loginId: string;
    /**
     * パスワード
     */
    loginPassword: string;
  }): CancelablePromise<{
    /**
     * CognitoのSession
     */
    Session?: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/auth/signin',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-B-0105】ログインパスワード変更
   * パスワードを再設定の認証
   *
   * @param requestBody auth password reset request
   * @returns any auto password reset response
   * @throws ApiError
   */
  public authPasswordReset(requestBody: {
    /**
     * ログインID
     */
    loginId: string;
    /**
     * パスワード
     */
    password: string;
  }): CancelablePromise<{
    AuthenticationResult?: {
      /**
       * トークンタイプ
       */
      TokenType?: string;
      /**
       * IDトークン
       */
      IdToken?: string;
      /**
       * アクセストークン
       */
      AccessToken?: string;
      /**
       * リフレッシュトークン
       */
      RefreshToken?: string;
      /**
       * 期限
       */
      ExpiresIn?: number;
    };
    /**
     * チャレンジ名
     */
    ChallengeName?: string;
    /**
     * セッション
     */
    Session?: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/auth/password-reset',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 二要素認証
   * 事務局ログインの二要素認証
   *
   * @param requestBody auth mfa confirm request
   * @returns any auth mfa confirm response
   * @throws ApiError
   */
  public authMfaConfirm(requestBody: {
    /**
     * ログインID
     */
    loginId: string;
    /**
     * パスワード
     */
    mfaCode: string;
    /**
     * CognitoのSession
     */
    mfaSession: string;
  }): CancelablePromise<{
    AuthenticationResult?: {
      /**
       * トークンタイプ
       */
      TokenType?: string;
      /**
       * IDトークン
       */
      IdToken?: string;
      /**
       * アクセストークン
       */
      AccessToken?: string;
      /**
       * リフレッシュトークン
       */
      RefreshToken?: string;
      /**
       * 期限
       */
      ExpiresIn?: number;
    };
    /**
     * チャレンジ名
     */
    ChallengeName?: string;
    /**
     * セッション
     */
    Session?: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/auth/mfa-confirm',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
        401: `unauthorized`,
      },
    });
  }


  /**
   * IDトークンに持株会を追加
   *
   * @param requestBody auth mfa confirm request
   * @returns any auth mfa confirm response
   * @throws ApiError
   */
  public authAssociation(
    /**
     * 持株会コード
     */
    associationCd: string,
  ): CancelablePromise<{
    /**
     * 持株会トークン
     */
    AssociationCd?: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/auth/associations/{associationCd}',
      path: {
        associationCd: associationCd,
      },
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
        401: `unauthorized`,
      },
    });
  }

  /**
   * 事務局パスワードを忘れた方用
   * 事務局パスワードを忘れた方
   *
   * @param requestBody auth mail confirm request
   * @returns any auth mfa confirm response
   * @throws ApiError
   */
  public authMailConfirm(requestBody: {
    /**
     * メールアドレス
     */
    email: string;
  }): CancelablePromise<{
    /**
     * CognitoのSession
     */
    Session?: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/auth/mail-confirm',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }
}
