import {
  LoginLoginPasswordChange,
  LoginFormType,
} from '@/components/organisms/Login/LoginPasswordChange';
import { Path } from '@/constants/Router/path';
import { appClient } from '@/services';
import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

type LoginState = {
  email: string;
};

export const PasswordChange = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LoginState;
  const [isError, setIsError] = useState(false);

  const handleSetting: SubmitHandler<LoginFormType> = async (
    data: LoginFormType
  ) => {
    try {
      await appClient.authorization.authPasswordReset({
        loginId: state.email,
        password: data.newPassword,
      });
      navigate(Path.LOGIN_PASSWORD_RESETTING_COMPLETE);
    } catch (e) {
      // エラー処理
      setIsError(true);
    }
  };
  return (
    <LoginLoginPasswordChange
      title="パスワード変更"
      isError={isError}
      onSubmit={handleSetting}
    />
  );
};
