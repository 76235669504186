import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';

interface Props {
  onClose: () => void;
}

export const Complete: FC<Props> = ({ onClose }) => (
  <Stack spacing={3} alignItems="center" width="520px" py="32px">
    <Typography>作業処理連絡票を提出しました。</Typography>
    <Box>
      <PrimaryButton onClick={() => onClose()}>OK</PrimaryButton>
    </Box>
  </Stack>
);
