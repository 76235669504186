/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Box, Button, SxProps, Theme, Typography, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { CancelButton } from '@/components/atoms/Buttons/CancelButton';
import { WorkReportInputs as Inputs } from '@/types/components/Inputs';
import { ReactComponent as Icon } from '@/assets/batsu.svg';
import { ReactComponent as PrintIcon } from '@/assets/print.svg';
import { printComponent } from '@/utils/print';
import { FindWorkreportsById } from '@/types/api/workreports';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { format, parseISO } from 'date-fns';
import { ja } from 'date-fns/locale';
import { GetAssociation } from '@/types/api/associations';
import { MonthlyWorkReport } from './MonthlyWorkReport';
import { Memo } from './Memo';
import { BonusWorkReport } from './BonusWorkReport';
import { SubmitStatus } from './SubmitStatus';
import { PurchaseStocks } from './PurchaseStocks';
import { EventExtension } from './EventExtension';
import { DividendWorkReport } from './DividendWorkReport';

interface Props {
  type: string;
  isListed?: boolean;
  onSubmit: SubmitHandler<Inputs>;
  isSubmit: 'BEFORESUBMISSION' | 'SUBMISSION' | undefined;
  workreport: Required<FindWorkreportsById>;
  setModal: (val: string) => void;
  month: number;
  association?: GetAssociation;
}

const styleTableContainer: SxProps<Theme> = [
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    width: '920 px',
    p: '24px',
    borderRadius: '8px',
  },
];

const schema = yup.object().shape({
  sharesPurchased: yup.object().shape({
    purchaseUnitPrice1: yup
      .string()
      .max(9, '9文字以下で入力してください')
      .matches(/^[1-9][0-9]*(,[1-9][0-9]*)*$/, '半角数字で入力してください')
      .notRequired(),
    purchaseSharesNum1: yup
      .string()
      .max(9, '9文字以下で入力してください')
      .matches(/^[1-9][0-9]*(,[1-9][0-9]*)*$/, '半角数字で入力してください')
      .notRequired(),
    purchaseUnitPrice2: yup
      .string()
      .max(9, '9文字以下で入力してください')
      .matches(/^[1-9][0-9]*(,[1-9][0-9]*)*$/, '半角数字で入力してください')
      .notRequired(),
    purchaseSharesNum2: yup
      .string()
      .max(9, '9文字以下で入力してください')
      .matches(/^[1-9][0-9]*(,[1-9][0-9]*)*$/, '半角数字で入力してください')
      .notRequired(),
    purchaseUnitPrice3: yup
      .string()
      .max(9, '9文字以下で入力してください')
      .matches(/^[1-9][0-9]*(,[1-9][0-9]*)*$/, '半角数字で入力してください')
      .notRequired(),
    purchaseSharesNum3: yup
      .string()
      .max(9, '9文字以下で入力してください')
      .matches(/^[1-9][0-9]*(,[1-9][0-9]*)*$/, '半角数字で入力してください')
      .notRequired(),
  }),
  jimuProxyPurchase: yup
    .string()
    .max(9, '*9文字以下で入力してください')
    .matches(/^[1-9][0-9]*(,[1-9][0-9]*)*$/, '*半角数字で入力してください')
    .notRequired(),
  transferAmount: yup
    .string()
    .max(9, '*9文字以下で入力してください')
    .matches(/^[1-9][0-9]*(,[1-9][0-9]*)*$/, '*半角数字で入力してください')
    .notRequired(),
  interest: yup
    .string()
    .max(9, '*9文字以下で入力してください')
    .matches(/^[1-9][0-9]*(,[1-9][0-9]*)*$/, '*半角数字で入力してください')
    .notRequired(),
  rightedMonthUnit: yup
    .string()
    .matches(/[\d,]+(\.\d+)?/, '*半角数字')
    .notRequired(),
  dividendPerUnit: yup
    .string()
    .matches(/^[1-9][0-9]*(,[1-9][0-9]*)*\.?[0-9]*$/, '*半角数字')
    .notRequired(),
  dividendIncome: yup
    .string()
    .matches(/^[1-9][0-9]*(,[1-9][0-9]*)*$/, '*半角数字')
    .notRequired(),
});

export const WorkReport: FC<Props> = ({
  type,
  isListed = false,
  onSubmit,
  isSubmit,
  workreport,
  setModal,
  month,
  association,
}) => {
  const {
    control,
    getValues,
    setValue,
    watch,
    register,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      jimuProxyPurchase: '',
      transferAmount: '',
      interest: '',
      sharesPurchased: {
        purchaseDate1: '',
        purchaseUnitPrice1: '',
        purchaseSharesNum1: '',
        purchaseDate2: '',
        purchaseUnitPrice2: '',
        purchaseSharesNum2: '',
        purchaseDate3: '',
        purchaseUnitPrice3: '',
        purchaseSharesNum3: '',
      },
      rightedMonth: '選択',
      rightedMonthUnit: '',
      dividendPerUnit: '',
      dividendIncome: '',
      other: '',
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

  const renderType = () => {
    switch (type) {
      case 'monthly': {
        return '月例';
      }
      case 'bonus': {
        return '賞与';
      }
      case 'dividend': {
        return '配当金';
      }
      default: {
        return null;
      }
    }
  };

  const getIsAnyError = () =>
    !!(
      (watch('sharesPurchased.purchaseUnitPrice1') !== '' &&
        errors.sharesPurchased?.purchaseUnitPrice1?.message) ||
      (watch('sharesPurchased.purchaseUnitPrice2') !== '' &&
        errors.sharesPurchased?.purchaseUnitPrice2?.message) ||
      (watch('sharesPurchased.purchaseUnitPrice3') !== '' &&
        errors.sharesPurchased?.purchaseUnitPrice3?.message) ||
      (watch('sharesPurchased.purchaseSharesNum1') !== '' &&
        errors.sharesPurchased?.purchaseSharesNum1?.message) ||
      (watch('sharesPurchased.purchaseSharesNum2') !== '' &&
        errors.sharesPurchased?.purchaseSharesNum2?.message) ||
      (watch('sharesPurchased.purchaseSharesNum3') !== '' &&
        errors.sharesPurchased?.purchaseSharesNum3?.message) ||
      (watch('jimuProxyPurchase') !== '' &&
        errors.jimuProxyPurchase?.message) ||
      (watch('transferAmount') !== '' && errors.transferAmount?.message) ||
      (watch('interest') !== '' && errors.interest?.message)
    );

  const getIsAnyErrorInDividend = () =>
    !!(
      !!errors?.rightedMonthUnit?.message ||
      !!errors?.dividendIncome?.message ||
      !!errors?.dividendPerUnit?.message ||
      !watch('rightedMonthUnit') ||
      !watch('dividendIncome') ||
      !watch('dividendPerUnit') ||
      watch('rightedMonth') === '選択'
    );

  const renderComponent = () => {
    switch (type) {
      case 'monthly': {
        return (
          <MonthlyWorkReport
            isListed={isListed}
            isSubmit={isSubmit}
            control={control}
            report={workreport}
            modalStatus={isConfirmed}
            data={getValues()}
            register={register}
            errors={errors}
          />
        );
      }
      case 'bonus': {
        return (
          <BonusWorkReport
            isListed={isListed}
            control={control}
            report={workreport}
            isSubmit={isSubmit}
            modalStatus={isConfirmed}
            data={getValues()}
            register={register}
            errors={errors}
          />
        );
      }
      case 'dividend': {
        return (
          <DividendWorkReport
            isSubmit={isSubmit}
            control={control}
            report={workreport}
            modalStatus={isConfirmed}
            data={getValues()}
            register={register}
            errors={errors}
            association={association}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  const today = new Date();
  const formatedToday = format(today, 'yyyy/MM/dd (E) HH:mm', {
    locale: ja,
  });

  return (
    <Box id="workreport" sx={styleTableContainer}>
      <Box
        sx={{
          width: '872px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '24px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <SubmitStatus submit={isSubmit === 'SUBMISSION'} />
          <Typography variant="h4" sx={{ color: 'system.text-normal' }}>
            作業処理連絡票
          </Typography>
          {isSubmit === 'SUBMISSION' ? (
            <Typography
              variant="body-sub/medium"
              sx={{ color: 'system.text-light' }}
            >
              提出日時：
              {format(parseISO(workreport.submissionDate), 'yyyy/MM/dd (E)', {
                locale: ja,
              })}
            </Typography>
          ) : (
            <Typography
              variant="body-sub/medium"
              sx={{ color: 'system.text-light' }}
            >
              {formatedToday} 現在
            </Typography>
          )}
        </Box>
        <Button
          id="workreport-close-button"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '9px',
            '&& .MuiTouchRipple-child': {
              bgcolor: 'secondary.normal',
            },
          }}
          onClick={() => setModal('')}
        >
          <Icon />
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '100%',
              color: 'secondary.normal',
            }}
          >
            閉じる
          </Typography>
        </Button>
      </Box>
      <Box>
        <Typography
          variant="body-main/regular"
          sx={{ color: 'system.text-light' }}
        >
          下記の通り、{month}月
        </Typography>
        <Typography variant="body-main/bold" color="rgba(102, 102, 102, 1)">
          {renderType()}
        </Typography>
        <Typography
          variant="body-main/regular"
          sx={{ color: 'system.text-light' }}
        >
          の連絡票を提出いたします。
        </Typography>
      </Box>
      {!isConfirmed && (
        <Button
          id="workreport-print-button"
          sx={{
            width: '172px',
            height: '40px',
            whiteSpace: 'nowrap',
            bgcolor: 'secondary.button',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            p: '8px 16px 8px 12px',
            borderRadius: '4px',
            '&:hover': {
              bgcolor: 'secondary.button',
            },
            '&& .MuiTouchRipple-child': {
              bgcolor: 'secondary.normal',
            },
          }}
          onClick={() => printComponent('workreport', () => setModal(''))}
        >
          <PrintIcon />
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '16px',
              lineHeight: '100%',
              color: 'secondary.text',
            }}
          >
            画面を印刷する
          </Typography>
        </Button>
      )}

      {renderComponent()}
      {!isListed && type !== 'dividend' && (
        <Box display="flex" justifyContent="space-between" gap="24px">
          <PurchaseStocks
            control={control}
            isSubmit={isSubmit}
            modalStatus={isConfirmed}
            data={getValues()}
            report={workreport}
            setValue={setValue}
            getValues={getValues}
            register={register}
            errors={errors}
          />
          <EventExtension date={workreport.officeClosingDate} />
        </Box>
      )}
      <Memo
        control={control}
        isSubmit={isSubmit}
        modalStatus={isConfirmed}
        data={getValues()}
        info={workreport.info}
      />
      {isSubmit === 'BEFORESUBMISSION' && (
        <Stack spacing={3} width="100%" alignItems="center">
          {isConfirmed ? (
            <>
              <Typography variant="body-main/medium" color="system.text-light">
                以上の内容で提出します。
              </Typography>
              <Box display="flex" gap="16px">
                <CancelButton onClick={() => setIsConfirmed(false)}>
                  入力に戻る
                </CancelButton>
                <PrimaryButton onClick={() => onSubmit(getValues())}>
                  作業処理連絡票を提出
                </PrimaryButton>
              </Box>
            </>
          ) : (
            <Box id="workreport-button-area" display="flex" gap="16px">
              <CancelButton onClick={() => setModal('')}>
                キャンセル
              </CancelButton>
              {type === 'dividend' ? (
                <PrimaryButton
                  onClick={() => setIsConfirmed(true)}
                  disabled={getIsAnyErrorInDividend()}
                >
                  入力内容を確認
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  onClick={() => setIsConfirmed(true)}
                  disabled={getIsAnyError()}
                >
                  入力内容を確認
                </PrimaryButton>
              )}
            </Box>
          )}
        </Stack>
      )}
    </Box>
  );
};
