/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FindApplicationByID } from '@/types/api/Applications/application';
import useSWR from 'swr';
import { appClient } from '..';

const ConvertApplication = (data: FindApplicationByID | undefined) =>
  data
    ? ({
        applicationId: data.applicationId || 0,
        memberId: data.memberId || 0,
        memberName: data.memberName || '',
        associationCd: data.associationCd || '',
        eventNo: data.eventNo || '',
        applicationType: data.applicationType || 'MONTHLY_UNIT',
        applicationDate: data.applicationDate || '',
        applicationStatus: data.applicationStatus || 'PREPARATION',
        rejectComment: data.rejectComment || '',
        // proxyApplicationUserName: data.proxyApplicationUserName || '',
        officeUpdateUserName: data.officeUpdateUserName || '',
        backofficeUpdateUserName: data.backofficeUpdateUserName || '',
        applicationBonusUnit: {
          isBonusUnit: data.applicationBonusUnit?.isBonusUnit || false,
          bonusUnit: data.applicationBonusUnit?.bonusUnit || 0,
          bonusRate: data.applicationBonusUnit?.bonusRate || 1,
        },
        applicationMonthlyUnit: {
          monthlyUnit: data.applicationMonthlyUnit?.monthlyUnit || 0,
        },
        applicationNewUser: {
          employeeCode: data.applicationNewUser?.employeeCode || '',
          email: data.applicationNewUser?.email || '',
          nameKanji: data.applicationNewUser?.nameKanji || '',
          nameKana: data.applicationNewUser?.nameKana || '',
          birthday: data.applicationNewUser?.birthday || '',
          zipcode: data.applicationNewUser?.zipcode || '',
          address1: data.applicationNewUser?.address1 || '',
          address2: data.applicationNewUser?.address2 || '',
          address3: data.applicationNewUser?.address3 || '',
          telType: data.applicationNewUser?.telType || 'MOBILE',
          tel: data.applicationNewUser?.tel || '',
          monthlyUnit: data.applicationNewUser?.monthlyUnit || 0,
          isBonusUnit: data.applicationNewUser?.isBonusUnit || false,
          bonusUnit: data.applicationNewUser?.bonusUnit || 0,
          bonusRate: data.applicationNewUser?.bonusRate || 0,
        },
        applicationPart: {
          sharesNum: data.applicationPart?.sharesNum || 0,
          leftSharesNum: data.applicationPart?.leftSharesNum || 0,
        },
        applicationRecess: {
          reason: data.applicationRecess?.reason || 'LEAVE_ABSENCE',
          otherReasons: data.applicationRecess?.otherReasons || '',
        },
        applicationResume: {
          sharesNum: data.applicationResume?.sharesNum || 0,
          costPerUnit: data.applicationResume?.costPerUnit || 0,
          monthlyUnit: data.applicationResume?.monthlyUnit || 0,
          bonusContributionFlg:
            data.applicationResume?.bonusContributionFlg || false,
          bonusUnit: data.applicationResume?.bonusUnit || 0,
          bonusRate: data.applicationResume?.bonusRate || 0,
        },
        applicationUser: {
          userCode: data.applicationUser?.userCode || '',
          employeeCode: data.applicationUser?.employeeCode || '',
          nameKanji: data.applicationUser?.nameKanji || '',
          nameKana: data.applicationUser?.nameKana || '',
          zipcode: data.applicationUser?.zipcode || '',
          address1: data.applicationUser?.address1 || '',
          address2: data.applicationUser?.address2 || '',
          address3: data.applicationUser?.address3 || '',
          birthday: data.applicationUser?.birthday || '',
          tel: data.applicationUser?.tel || '',
          telType: data.applicationUser?.telType || 'MOBILE',
          officeCd: data.applicationUser?.officeCd || '',
          affiliationCd: data.applicationUser?.affiliationCd || '',
        },
        applicationWithdrawal: {
          reason: data.applicationWithdrawal?.reason || 'LEAVE_ABSENCE',
          otherReasons: data.applicationWithdrawal?.otherReasons || '',
          generalAccount: {
            institutionCode:
              data.applicationWithdrawal?.generalAccount?.institutionCode || '',
            institutionName:
              data.applicationWithdrawal?.generalAccount?.institutionName || '',
            branchCode:
              data.applicationWithdrawal?.generalAccount?.branchCode || '',
            branchName:
              data.applicationWithdrawal?.generalAccount?.branchName || '',
            accountType:
              data.applicationWithdrawal?.generalAccount?.accountType ||
              'SAVINGS',
            accountNumber:
              data.applicationWithdrawal?.generalAccount?.accountNumber || '',
          },
          isAdditionalOption:
            data.applicationWithdrawal?.isAdditionalOption || false,
          transferAmount: data.applicationWithdrawal?.transferAmount || 0,
          remarks: data.applicationWithdrawal?.remarks || '',
          institutionType:
            data.applicationWithdrawal?.institutionType || 'YUCHO',
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          accountYuchoSymbol:
            // @ts-ignore
            data.applicationWithdrawal?.accountYuchoSymbol || '',
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          accountYuchoNumber:
            // @ts-ignore
            data.applicationWithdrawal?.accountYuchoNumber || '',
          addPurchaseSharesNum:
            data.applicationWithdrawal?.addPurchaseSharesNum || 0,
        },
      } as FindApplicationByID)
    : undefined;

export const useApplication = (applicationId: number) => {
  // 【API-C-4102】申請書取得
  const { data: apiData, mutate } = useSWR(
    `/api/applications/${applicationId}`,
    () => appClient.applications.findApplicationById(applicationId)
  );
  return {
    data: ConvertApplication(apiData),
    mutate,
  };
};
