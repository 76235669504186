import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { ButtonProps } from '@mui/material';
import { FC } from 'react';

interface MainButtonProps extends ButtonProps {
  text: string;
}

export const MainButton: FC<MainButtonProps> = ({ text, ...props }) => (
  <PrimaryButton
    sx={{
      padding: '12px 24px 13px',
    }}
    {...props}
  >
    {text}
  </PrimaryButton>
);
