import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  styled,
  TableCellProps,
  TableCell,
} from '@mui/material';
import { theme } from '@/theme';
import { useNavigate } from 'react-router-dom';
import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { BasicTable } from '@/components/molecules/Table/BasicTable';
import { TableBodyRow } from '@/components/atoms/Table/TableBodyRow';
import { CheckBox } from '@/components/atoms/Input/CheckBox';
import { CancelButton } from '@/components/atoms/Buttons/CancelButton';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { useRecoilValue } from 'recoil';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { useOfficeUsersIndex } from '@/services/customHooks/useOfficeUsersIndex';
import { ListOfficeUserAssociationItem } from '@/types/api/officeUserAssociations';
import { appClient } from '@/services';
import { useSnackbar } from '@/hooks/useSnackbar';

const Wrapper = styled(Box)({});

const HeaderSection = styled(Box)({
  padding: '32px 40px 24px',
  backgroundColor: theme.palette.system.white,
  borderWidth: 0,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.system.separator,
});

const HeaderTitle = styled(Typography)({
  marginTop: 24,
  ...theme.typography.h4,
  color: theme.palette.system['text-normal'],
});

const HeaderSubtitle = styled(Typography)({
  marginTop: 8,
  ...theme.typography['body-main/regular'],
  color: theme.palette.system['text-light'],
});

const ContentWrapper = styled(Box)({
  padding: '24px 40px',
});

const SelectionWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
  borderWidth: 0,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.system.separator,
  paddingBottom: 12,
});

const SelectionLabelWrapper = styled(Box)({});

const SelectionLabel = styled(Typography)({
  width: 144,
  ...theme.typography['body-main/bold'],
  color: theme.palette.system['text-normal'],
});

const SelectionTableWrapper = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const MuiTableCell = styled((props: TableCellProps) => (
  <TableCell {...props} />
))({
  borderWidth: '0px',
  padding: '0px',
});

const CellText = styled(Typography)({
  ...theme.typography['body-sub/regular'],
  color: theme.palette.system.table,
});

const TableSelectionDescription = styled(Typography)({
  ...theme.typography['body-sub/regular'],
  color: theme.palette.system['text-light'],
});

const DownloadDescriptionText = styled(Typography)({
  marginTop: 16,
  ...theme.typography['body-sub/regular'],
  color: theme.palette.system['text-normal'],
});

const ControlsWrapper = styled(Box)({
  marginTop: 32,
  display: 'flex',
  flexDirection: 'row',
  gap: 16,
});

const SelectedResultWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '4px',
});

const SelectedResultItem = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
});

const SelectedResultItemText = styled(Box)({
  ...theme.typography['body-main/regular'],
  color: theme.palette.system['text-normal'],
});

export const OfficeUserAdd = () => {
  const navigate = useNavigate();
  const { associationCd = '', associationName = '' } = useRecoilValue(
    SelectedAssociationState
  );
  const { data: officeUsers, setQueryParams } = useOfficeUsersIndex({});

  useEffect(() => {
    setQueryParams({
      associationCd,
      fetchType: 'exclude',
    });
  }, [associationCd]);

  const [selection, setSelection] = useState<number[]>([]);
  const [isConfirming, setIsConfirming] = useState(false);
  const { showSnackbar } = useSnackbar();

  const Add = async () => {
    try {
      if (!associationCd) return;
      await appClient.officeUserAssociations.createOfficeUserAssociations(
        associationCd,
        officeUsers
          .filter((_, index) => selection.includes(index))
          .map((user) => ({
            officeUserId: Number(user.officeUserId),
            /**
             * "事務局担当者権限区分"と"代理申請区分"については初期値を設定する
             */
            authorityClassification: 'GENERAL',
            proxyApplicationClassification: 'POSSIBLE',
            actionType: 'CREATE',
          }))
      );
      showSnackbar('担当者を持株会に追加しました。', '272px');
      navigate('/office-users');
    } catch (e) {
      // Handle Add office users error
    }
  };

  const renderTableRow = (
    data: ListOfficeUserAssociationItem,
    index: number
  ) => (
    <TableBodyRow
      key={data.officeUserId}
      sx={{
        backgroundColor: selection.includes(index)
          ? '#EFFBFC'
          : theme.palette.system.white,
      }}
    >
      <MuiTableCell sx={{ width: '5%', padding: 0 }}>
        <Box
          sx={{
            display: 'flex',
            padding: '12px 0',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CheckBox
            value={selection.includes(index)}
            onChange={() => {
              if (selection.includes(index)) {
                const elementIndex = selection.indexOf(index);
                selection.splice(elementIndex, 1);
                setSelection([...selection]);
              } else {
                selection.push(index);
                setSelection([...selection]);
              }
            }}
          />
        </Box>
      </MuiTableCell>
      <MuiTableCell sx={{ width: '47.5%', padding: 0 }}>
        <CellText>
          {data.officeUserLastName} {data.officeUserFirstName}
        </CellText>
      </MuiTableCell>
      <MuiTableCell sx={{ width: '47.5%', padding: 0 }}>
        <CellText>{data.email}</CellText>
      </MuiTableCell>
    </TableBodyRow>
  );

  const renderSelectionContent = () => {
    if (isConfirming && officeUsers) {
      return (
        <SelectedResultWrapper>
          {selection.map((data) => (
            <SelectedResultItem key={officeUsers[data].officeUserId}>
              <SelectedResultItemText>
                {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                {`${officeUsers[data].officeUserLastName} ${officeUsers[data].officeUserFirstName}`}
              </SelectedResultItemText>
              <SelectedResultItemText>
                {officeUsers[data].email}
              </SelectedResultItemText>
            </SelectedResultItem>
          ))}
        </SelectedResultWrapper>
      );
    }
    return (
      <>
        <BasicTable
          disablePagination
          containerSx={{
            height: 200,
            overflow: 'scroll',
          }}
        >
          {officeUsers?.map((data, index) => renderTableRow(data, index))}
        </BasicTable>
        <TableSelectionDescription>
          {selection.length} 人選択中
        </TableSelectionDescription>
      </>
    );
  };

  const getSubtitle = (): string =>
    isConfirming
      ? `以下の担当者を株式会社${associationName}従業員持株会の担当者として追加します。`
      : `他の持株会に登録されている担当者を、${associationName}の担当者として追加します。`;

  return (
    <Wrapper>
      <HeaderSection>
        <NormalLink href="/office-users">担当者一覧に戻る</NormalLink>
        <HeaderTitle>既存担当者を追加</HeaderTitle>
        <HeaderSubtitle>{getSubtitle()}</HeaderSubtitle>
      </HeaderSection>
      <ContentWrapper>
        <SelectionWrapper>
          <SelectionLabelWrapper>
            <SelectionLabel>担当者</SelectionLabel>
          </SelectionLabelWrapper>
          <SelectionTableWrapper>
            {renderSelectionContent()}
          </SelectionTableWrapper>
        </SelectionWrapper>
        <DownloadDescriptionText>
          ログインには現在のパスワードを使用できます。また、ログイン後に複数の持株会を横断することが可能になります。
        </DownloadDescriptionText>
        <ControlsWrapper>
          <CancelButton
            onClick={() => {
              if (isConfirming) setIsConfirming(false);
              else navigate('/office-users');
            }}
          >
            {isConfirming ? '選択へ戻る' : 'キャンセル'}
          </CancelButton>
          <PrimaryButton
            disabled={selection.length === 0}
            onClick={() => {
              if (isConfirming) void Add();
              else setIsConfirming(true);
            }}
          >
            {isConfirming ? '追加する' : '確認へ進む'}
          </PrimaryButton>
        </ControlsWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};
