import { FC } from 'react';
import { styled, Typography, Stack, Divider, Box } from '@mui/material';
import { theme } from '@/theme';
import { StatusBadge } from '@/components/atoms/Badge/StatusBadge';
import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { formatDateTime } from '@/utils/dateFunction';
import { FindApplicationByID } from '@/types/api/Applications/application';

const Date = styled(Typography)({
  ...theme.typography['body-sub/regular'],
  color: theme.palette.system['text-light'],
});

interface Props {
  title: string;
  status: string;
  associationCd: string;
  applicationId: number | string;
  applicationDate: string;
  proxyApplicantUserName: FindApplicationByID['proxyApplicantUserName'];
  comment?: string;
  unitType: string;
}

export const TitleHead: FC<Props> = ({ ...props }) => (
  <Stack spacing={4}>
    <NormalLink href={`/applications/${props.unitType}`}>
      申請一覧へ戻る
    </NormalLink>
    <Stack spacing={2}>
      <Stack direction="row" spacing={1} alignItems="center">
        <StatusBadge
          sx={{ width: '64px', height: '27px' }}
          status={props.status}
        />
        <Typography variant="h3">{props.title}</Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Date>申請ID：{props.applicationId}</Date>
        <Date>申請日時： {formatDateTime(props.applicationDate)}</Date>
      </Stack>
      {props.comment && props.status === 'OFFICER_REJECTED' && (
        <Stack
          spacing={1}
          p={2}
          borderRadius={1}
          sx={{ backgroundColor: theme.palette.system.background }}
        >
          <Typography variant="body-main/bold">事務局よりコメント</Typography>
          <Typography variant="body-main/regular">{props.comment}</Typography>
        </Stack>
      )}
      {props.comment && props.status === 'BACKOFFICER_REJECTED' && (
        <Stack
          spacing={1}
          p={2}
          borderRadius={1}
          sx={{ backgroundColor: theme.palette.system.background }}
        >
          <Typography variant="body-main/bold">
            制度グループよりコメント
          </Typography>
          <Typography variant="body-main/regular">{props.comment}</Typography>
        </Stack>
      )}
      {props.proxyApplicantUserName !== '' && (
        <Box>
          <Box
            display="inline-flex"
            height={40}
            borderRadius={1}
            p={1}
            gap={1}
            sx={{ backgroundColor: theme.palette.secondary.bg }}
          >
            <Typography variant="body-main/medium" color="secondary.text">
              ※代理申請された申請です。
            </Typography>
            <Typography variant="body-main/regular" color="secondary.text">
              {`代理申請者： ${props.proxyApplicantUserName ?? ''}`}
            </Typography>
          </Box>
        </Box>
      )}
      <Divider />
    </Stack>
  </Stack>
);
