import { styled, TableRow, TableCell, TableCellProps } from '@mui/material';
import { FC } from 'react';

const StyledTableRow = styled(TableRow)(({ theme }) => {
  const { palette } = theme;
  return {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:nth-of-type(even)': {
      background: palette.system['background-light'],
    },
  };
});

export const PrimaryTableBodyTdSingle: FC<TableCellProps> = ({
  children,
  ...props
}) => (
  <StyledTableRow>
    <TableCell
      colSpan={2}
      sx={{
        borderBottom: 'none',
      }}
      {...props}
    >
      {children}
    </TableCell>
  </StyledTableRow>
);
