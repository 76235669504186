import { Typography } from '@mui/material';

export const userLabels = {
  name_kanji: '氏名（漢字）',
  name_kana: '氏名（カナ）',
  birthday: '生年月日',
  zipcode: '郵便番号',
  address: '住所',
  telType: '電話種類',
  tel: '電話番号',
  employee_cd: '社員コード',
  member_cd: '会員コード',
  office_cd: ['会員事務所', <br key="br" />, 'コード'],
  affiliation_cd: '会員所属コード',
};

export const securitiesLabels = {
  institutionName: '金融機関名',
  branch_cd: '部店コード',
  branch_name: '部店名',
  account_number: '口座番号',
};

export const contributionLabels = {
  status: '稼働/休止',
  start_date: '積立開始日',
  bounty_rate: '奨励金率',
  monthly_unit: ['給与', <br key="br" />, '（毎月の拠出）'],
  bonus_unit: '賞与',
};

export const loginLabels = {
  loginId: [
    'ログインID',
    <br key="br" />,
    <Typography key="typo" variant="caption/regular" color="system.text-light">
      ※メールアドレス
    </Typography>,
  ],
};
