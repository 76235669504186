import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { FindWorkreportsById } from '@/types/api/workreports';
import { TableContainer } from './TableContainer';
import { Row } from './Row';
import { Td } from './Td';
import { Th } from './Th';

interface Props {
  report: Required<FindWorkreportsById>;
}

export const Part: FC<Props> = ({ report }) => {
  const { partCount, partSharesNum } = report;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '8px',
      }}
    >
      <Typography variant="h5" sx={{ color: 'system.text-normal' }}>
        株式移動
      </Typography>
      <TableContainer>
        <Row borderBottom={false}>
          <Th
            variant="medium"
            bgcolor="secondary.bg"
            width="120px"
            height="48px"
            borderRight
          >
            一部引出
          </Th>
          <Td width="304px" height="48px">
            {`${partCount ? partCount.toLocaleString() : 0}件 ${
              partSharesNum.toLocaleString() ?? 0
            }株`}
          </Td>
        </Row>
      </TableContainer>
    </Box>
  );
};
