export interface MemberBalancereports {
  tradeHistories?: TradeHistories;
  rightAllocationHistories?: RightAllocationHistories;
}

export type TradeHistories = {
  inverstmentYm: string;
  inverstmentDescriptionClassification: string;
  contribution: number;
  incentives: number;
  depositAmountTotal: number;
  withdrawalAmount: number;
  stockPurchaseNumber: number;
  stockDrawerNumber: number;
  unitPrice: number;
  newOldClassification: string;
  updated: string;
}[];

export type RightAllocationHistories = {
  allocationYmd: string;
  newOldClassification: string;
  allocationSharesNum: string;
  allocationMoneyPerShare: number;
  dividend: number;
  incomeTax: number;
  residentTax: number;
  afterTaxDividend: number;
  updated: string;
}[];

export const ConvertMemberBalancereports = (
  data:
    | {
        tradeHistories?: Array<{
          inverstmentYm?: string;
          inverstmentDescriptionClassification?: string;
          contribution?: number;
          incentives?: number;
          depositAmountTotal?: number;
          withdrawalAmount?: number;
          stockPurchaseNumber?: number;
          stockDrawerNumber?: number;
          unitPrice?: number;
          newOldClassification?: string;
          updated?: string;
        }>;
        rightAllocationHistories?: Array<{
          allocationYmd?: string;
          newOldClassification?: string;
          allocationSharesNum?: string;
          allocationMoneyPerShare?: number;
          dividend?: number;
          incomeTax?: number;
          residentTax?: number;
          afterTaxDividend?: number;
          updated?: string;
        }>;
      }
    | undefined
) => {
  if (data === undefined) {
    return undefined;
  }

  const tradeHistories = data.tradeHistories
    ? data.tradeHistories.map((i) => ({
        inverstmentYm: i.inverstmentYm || '',
        inverstmentDescriptionClassification:
          i.inverstmentDescriptionClassification || '',
        contribution: i.contribution || 0,
        incentives: i.incentives || 0,
        depositAmountTotal: i.depositAmountTotal || 0,
        withdrawalAmount: i.withdrawalAmount || 0,
        stockPurchaseNumber: i.stockPurchaseNumber || 0,
        stockDrawerNumber: i.stockDrawerNumber || 0,
        unitPrice: i.unitPrice || 0,
        newOldClassification: i.newOldClassification || '',
        updated: i.updated || '',
      }))
    : undefined;

  const rightAllocationHistories = data.rightAllocationHistories
    ? data.rightAllocationHistories.map((i) => ({
        allocationYmd: i.allocationYmd || '',
        newOldClassification: i.newOldClassification || '',
        allocationSharesNum: i.allocationSharesNum || '',
        allocationMoneyPerShare: i.allocationMoneyPerShare || 0,
        dividend: i.dividend || 0,
        incomeTax: i.incomeTax || 0,
        residentTax: i.residentTax || 0,
        afterTaxDividend: i.afterTaxDividend || 0,
        updated: i.updated || '',
      }))
    : undefined;
  return {
    tradeHistories,
    rightAllocationHistories,
  } as MemberBalancereports;
};
