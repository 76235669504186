import { SelectedAssociationState } from '@/recoil/associations/associations';
import { ListEventsQuery } from '@/types/api/events';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import useSWR from 'swr';
import { appClient } from '..';

export const useListEvents = (
  eventType?: ListEventsQuery['eventType'],
  eventTargetDateType?: ListEventsQuery['eventTargetDateType'],
  dateFrom?: ListEventsQuery['dateFrom'],
  dateTo?: ListEventsQuery['dateTo']
) => {
  const { associationCd = '' } = useRecoilValue(SelectedAssociationState);
  const { data, mutate } = useSWR(
    associationCd !== '' ? '/api/events/{associationCd}' : null,
    () =>
      appClient.events.listEvents(
        associationCd,
        eventType,
        eventTargetDateType || 'YEAR_MONTH',
        dateFrom,
        dateTo
      )
  );

  useEffect(() => {
    void mutate();
  }, [associationCd]);

  return { data: data?.events };
};
