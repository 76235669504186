import { FC } from 'react';
import { theme } from '@/theme/theme';
import { Box, Typography, styled } from '@mui/material';
import { formatDateTime } from '@/utils/dateFunction';
import { MemberAssets } from '@/types/api/memberAssets';
import { numberFormat } from '@/utils/numberFormat';
import { ItemInfoRow } from './InvestmentInfoRow';
import { BalanceInquiryHeading } from './BalanceInquiryHeading';

const Wrapper = styled(Box)({
  marginTop: 32,
});

const GroupManager = styled(Box)(() => {
  const { breakpoints } = theme;

  return {
    margin: 0,
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: 16,
      justifyContent: undefined,
      width: '100%',
    },

    '@media print': {
      flexDirection: 'row',
      marginTop: 20,
      justifyContent: 'space-between',
    },
  };
});

const Group = styled(Box)(() => {
  const { breakpoints } = theme;

  return {
    flex: 1,
    flexDirection: 'column',
    marginLeft: 24,

    '&:first-of-type': {
      marginLeft: 0,
    },

    [breakpoints.down('sm')]: {
      marginTop: 8,
      marginLeft: 0,
      '&:first-of-type': {
        marginTop: 0,
      },
    },

    '@media print': {
      flex: 1,
      flexDirection: 'column',
      marginLeft: 24,

      '&:first-of-type': {
        marginLeft: 0,
      },
    },
  };
});

const GroupItem = styled(Box)(() => {
  const { breakpoints, palette } = theme;

  return {
    flex: 1,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.system.separator,
    borderRadius: 4,
    marginTop: 24,

    '&:first-of-type': {
      marginTop: 0,
    },

    [breakpoints.down('sm')]: {
      borderRadius: 0,
      marginTop: 8,
      borderRightWidth: 0,
      borderLeftWidth: 0,

      '&:first-of-type': {
        marginTop: 0,
      },
    },

    '@media print': {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: palette.system.separator,
      borderRadius: 4,
      marginTop: 24,

      '&:first-of-type': {
        marginTop: 0,
      },
    },
  };
});

const SubHeadingWrapper = styled(Box)({
  marginTop: 32,
});

const SubHeading = styled(Typography)({
  ...theme.typography.h6,
  color: theme.palette.system['text-normal'],
});

interface Props {
  memberAssets: MemberAssets;
  date: Date;
  investmentDigit?: number;
}

export const CurrentInvestmentStatus: FC<Props> = ({
  memberAssets,
  date,
  investmentDigit,
}) => (
  <Wrapper>
    <BalanceInquiryHeading
      title="残高明細 "
      subtitle={`${formatDateTime(date.toString())} 現在`}
      sx={{ padding: { xs: '0 16px', sm: 0 } }}
    />
    <SubHeadingWrapper>
      <SubHeading>現在のご投資状況</SubHeading>
    </SubHeadingWrapper>
    <GroupManager>
      <Group>
        <GroupItem>
          <ItemInfoRow
            headerText="ご投資明細現在高"
            headerSymbol="(株)"
            value={
              memberAssets.stocksNumber
                ? memberAssets.stocksNumber.toLocaleString(undefined, {
                    maximumFractionDigits: investmentDigit,
                  })
                : '0'
            }
            isHeader
          />
          <ItemInfoRow
            headerText="旧株"
            headerSymbol="(株)"
            value={
              memberAssets.oldStock
                ? memberAssets.oldStock.toLocaleString(undefined, {
                    maximumFractionDigits: investmentDigit,
                  })
                : '0'
            }
            isHeader={false}
          />
          <ItemInfoRow
            headerText="第一新株"
            headerSymbol="(株)"
            value={
              memberAssets.firstNewStock
                ? memberAssets.firstNewStock.toLocaleString(undefined, {
                    maximumFractionDigits: investmentDigit,
                  })
                : '0'
            }
            isHeader={false}
          />
          <ItemInfoRow
            headerText="第二新株"
            headerSymbol="(株)"
            value={
              memberAssets.secondNewStock
                ? memberAssets.secondNewStock.toLocaleString(undefined, {
                    maximumFractionDigits: investmentDigit,
                  })
                : '0'
            }
            isHeader={false}
          />
        </GroupItem>
        <GroupItem>
          <ItemInfoRow
            headerText="繰越金額"
            headerSymbol="(円)"
            value={
              memberAssets.carriedForwardAmount
                ? numberFormat(memberAssets.carriedForwardAmount)
                : '0'
            }
            isHeader
          />
        </GroupItem>
      </Group>
      <Group>
        <GroupItem>
          <ItemInfoRow
            headerText="入金合計"
            headerSymbol="(円)"
            value={
              memberAssets.cumulativeDeposit
                ? numberFormat(memberAssets.cumulativeDeposit)
                : '0'
            }
            isHeader
          />
          <ItemInfoRow
            headerText="拠出金"
            headerSymbol="(円)"
            value={
              memberAssets.cumulativeContribution
                ? numberFormat(memberAssets.cumulativeContribution)
                : '0'
            }
            isHeader={false}
          />
          <ItemInfoRow
            headerText="奨励金"
            headerSymbol="(円)"
            value={
              memberAssets.cumulativeIncentives
                ? numberFormat(memberAssets.cumulativeIncentives)
                : '0'
            }
            isHeader={false}
          />
          <ItemInfoRow
            headerText="配当金"
            headerSymbol="(円)"
            value={
              memberAssets.cumulativeDividend
                ? numberFormat(memberAssets.cumulativeDividend)
                : '0'
            }
            isHeader={false}
          />
          <ItemInfoRow
            headerText="利息"
            headerSymbol="(円)"
            value={
              memberAssets.cumulativeInterest
                ? numberFormat(memberAssets.cumulativeInterest)
                : '0'
            }
            isHeader={false}
          />
          <ItemInfoRow
            headerText="その他入金"
            headerSymbol="(円)"
            value={
              memberAssets.cumulativeOtherDeposits
                ? numberFormat(memberAssets.cumulativeOtherDeposits)
                : '0'
            }
            isHeader={false}
          />
        </GroupItem>
      </Group>
      <Group>
        <GroupItem>
          <ItemInfoRow
            headerText="出金累計"
            headerSymbol="(円)"
            value={
              memberAssets.cumulativeWithdrawal
                ? numberFormat(memberAssets.cumulativeWithdrawal)
                : '0'
            }
            isHeader
          />
          <ItemInfoRow
            headerText="事務経費"
            headerSymbol="(円)"
            value={
              memberAssets.cumulativeOfficeExpenses
                ? numberFormat(memberAssets.cumulativeOfficeExpenses)
                : '0'
            }
            isHeader={false}
          />
        </GroupItem>
        <GroupItem>
          <ItemInfoRow
            headerText="ご投資明細"
            headerSymbol="(株)"
            value={
              memberAssets.stocksNumber || memberAssets.withdrawSharesCnt
                ? (
                    (memberAssets.stocksNumber || 0) +
                    (memberAssets.withdrawSharesCnt || 0)
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: investmentDigit,
                  })
                : '0'
            }
            isHeader
          />
          <ItemInfoRow
            headerText="引出株数"
            headerSymbol="(株)"
            value={
              memberAssets.withdrawSharesCnt
                ? memberAssets.withdrawSharesCnt.toLocaleString(undefined, {
                    maximumFractionDigits: investmentDigit,
                  })
                : '0'
            }
            isHeader={false}
          />
          <ItemInfoRow
            headerText="平均買付価格"
            headerSymbol="(円)"
            value={
              memberAssets.purchaseAvarageUnitPrice
                ? memberAssets.purchaseAvarageUnitPrice.toLocaleString(
                    undefined,
                    {
                      maximumFractionDigits: investmentDigit,
                    }
                  )
                : '0'
            }
            isHeader
          />
        </GroupItem>
      </Group>
    </GroupManager>
  </Wrapper>
);
