/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { FC } from 'react';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { theme } from '@/theme';
import { NormalLink } from '@/components/atoms/Link/NormalLink';
import { FindAssociationsAccount } from '@/types/api/Association/associationsAccount';
import { PrevnextApplications } from '@/types/api/Applications/prevnextApplications';
import { MemberById } from '@/types/api/member';
import { FindApplicationByID } from '@/types/api/Applications/application';
import { GetAssociation } from '@/types/api/associations';
import { useMemberSecuritiesAccount } from '@/services/customHooks/useMemberSecuritiesAccount';
import { IsNumeric } from '@/utils/validation';
import { printApplicationsComponent } from '@/utils/printApplications';
import { TitleHead } from './SharedParts/TitleHead';
import { UserInfoTable } from './SharedParts/UserInfoTable';
import { ApplicationBottom } from './SharedParts/ApplicationBottom';
import { ApplicationTable as Table } from './SharedParts/ApplicationTable';
import { ApplicationTableRow as Row } from './SharedParts/ApplicationTableRow';
import { ApplicationButtons } from './SharedParts/ApplicationButtons';
import { PrintApplicationsButton } from './PrintApplicationsButton/PrintApplicationsButton';

interface WithrawalProps {
  application: FindApplicationByID;
  unitType: string;
  userData: MemberById;
  associationData: GetAssociation;
  associationsAccount: FindAssociationsAccount;
  bottomData: PrevnextApplications | undefined;
  handleApplication: (value: 'APPROVAL' | 'REJECTED', comment?: string) => void;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  setTransferAmount: (value: string) => void;
  transferAmount: string;
  setRemarks: (value: string) => void;
}

export const ApplicationsWithdrawal: FC<WithrawalProps> = ({
  application,
  unitType,
  userData,
  associationData,
  associationsAccount,
  bottomData,
  handleApplication,
  setIsOpen,
  isOpen,
  transferAmount,
  setTransferAmount,
  setRemarks,
}) => {
  const switchRecessReason = () => {
    switch (application?.applicationWithdrawal?.reason) {
      case 'LEAVE_ABSENCE':
        return '退職のため';
      case 'FAMILY_MATTERS':
        return '家族の都合';
      case 'ECONOMIC':
        return '経済的な事情';
      case 'OTHER':
        return ['その他', application.applicationWithdrawal.otherReasons || ''];
      default:
        return '';
    }
  };

  const switchAccountType = (type: string | undefined) => {
    if (type) {
      switch (type) {
        case 'SAVINGS':
          return '普通';
        case 'CURRENT':
          return '当座';
        case 'OTHER':
          return '貯蓄';
        default:
          return '';
      }
    }
    return '';
  };

  const { data: memberSecurityAccount } = useMemberSecuritiesAccount(
    Number(userData.memberId)
  );

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.system['background-dark'],
        height: '100%',
      }}
    >
      <Stack
        spacing={4}
        sx={{
          backgroundColor: theme.palette.system.white,
          padding: '40px 160px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <PrintApplicationsButton
            onClick={() =>
              printApplicationsComponent('withdrawal_print_content', '退会')
            }
          />
        </Box>
        <Stack id="withdrawal_print_content" spacing={4}>
          <TitleHead
            title="退会"
            status={application.applicationStatus || ''}
            applicationDate={application.applicationDate || ''}
            applicationId={application.applicationId || ''}
            associationCd={application.associationCd || ''}
            proxyApplicantUserName={application.proxyApplicantUserName ?? ''}
            unitType={unitType}
            comment={application.rejectComment}
          />
          <UserInfoTable data={userData} />
          <Table title="証券口座">
            {memberSecurityAccount &&
            memberSecurityAccount?.accountNumber &&
            memberSecurityAccount?.branchCd &&
            memberSecurityAccount?.branchName ? (
              <>
                <Row labels="金融機関名" values="東京東海証券" />
                <Row
                  labels="部店コード"
                  values={memberSecurityAccount.branchCd?.replace(
                    memberSecurityAccount.branchCd,
                    '*'.repeat(memberSecurityAccount.branchCd?.length)
                  )}
                />
                <Row
                  labels="部店名"
                  values={memberSecurityAccount.branchName?.replace(
                    memberSecurityAccount.branchName,
                    '*'.repeat(memberSecurityAccount.branchName?.length)
                  )}
                  isEven
                />
                <Row
                  labels="口座番号"
                  values={memberSecurityAccount.accountNumber?.replace(
                    memberSecurityAccount.accountNumber,
                    '*'.repeat(memberSecurityAccount.accountNumber?.length)
                  )}
                  isEven
                />
              </>
            ) : (
              <Typography
                sx={{
                  ...theme.typography['body-sub/regular'],
                  color: theme.palette.system['text-normal'],
                }}
              >
                未登録です。
              </Typography>
            )}
          </Table>
          <Table title="退会理由" isRed>
            <Row values={switchRecessReason()} />
          </Table>
          {associationData.withdrawalAllSettlementUsageClassification !==
            '1' && (
            <Table title="精算方法" isRed>
              {application?.applicationWithdrawal?.isAdditionalOption ? (
                <>
                  <Row labels="精算方法" values="買増精算" />
                  <Row
                    labels={['売買単位', '未満株式持分']}
                    values="臨時拠出により買増し"
                    isEven
                  />
                  <Row
                    labels="買増株数"
                    values={`${
                      application?.applicationWithdrawal?.addPurchaseSharesNum?.toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits:
                            associationData.investmentDigit,
                        }
                      ) || 0
                    }株`}
                  />
                  {application.applicationStatus === 'APPLYING' ||
                  application.applicationStatus === 'PREPARATION' ? (
                    <>
                      {' '}
                      <Row labels="振込金額" isEven>
                        <Box gap="8px" display="flex" alignItems="center">
                          <TextField
                            placeholder="例：10000"
                            sx={{ width: '196px' }}
                            onChange={(e) => {
                              if (
                                (e.target.value.length === 1 ||
                                  e.target.value.split('')[0] !== '0') &&
                                e.target.value.length <= 9 &&
                                (IsNumeric(e.target.value) ||
                                  e.target.value === '')
                              ) {
                                setTransferAmount(e.target.value);
                              }
                            }}
                            value={transferAmount}
                            inputProps={{ style: { textAlign: 'right' } }}
                          />
                          <Typography>円</Typography>
                        </Box>
                      </Row>
                      <Row labels="備考" isOptional>
                        <TextField
                          placeholder="例：〇〇〇〇年〇〇月〇〇日までにお振込ください。"
                          onChange={(e) => setRemarks(e.target.value)}
                          rows={2}
                          multiline
                          sx={{ width: '100%', height: '88px' }}
                        />
                      </Row>
                    </>
                  ) : (
                    <>
                      {' '}
                      <Row labels="振込金額" isEven>
                        <Box gap="8px" display="flex" alignItems="center">
                          <Typography>
                            {application?.applicationWithdrawal?.transferAmount?.toLocaleString() ||
                              0}
                            円
                          </Typography>
                        </Box>
                      </Row>
                      <Row labels="備考" isOptional>
                        <Typography>
                          {application?.applicationWithdrawal?.remarks}
                        </Typography>
                      </Row>
                    </>
                  )}
                </>
              ) : (
                <Row labels="精算方法" values="未満株精算" />
              )}
            </Table>
          )}
          {associationData.withdrawalAllSettlementUsageClassification !== '1' &&
            application?.applicationWithdrawal?.isAdditionalOption && (
              <Table
                title="買増精算振込先口座情報"
                isEdit
                to={
                  application.applicationId
                    ? `/applications/${unitType}/${application.applicationId}/withdrawal/account/edit`
                    : `/applications/${unitType}`
                }
              >
                {associationsAccount.accountClassification === 'YUCHO' ? (
                  <>
                    <Row labels="金融機関名" values="ゆうちょ銀行" />
                    <Row
                      labels="店番"
                      values={associationsAccount.accountYuchoSymbol}
                      isEven
                    />
                    <Row
                      labels="番号"
                      values={associationsAccount.accountYuchoNumber}
                    />
                  </>
                ) : (
                  <>
                    <Row
                      labels="金融機関名"
                      values={associationsAccount.institutionName}
                    />
                    <Row
                      labels="支店コード"
                      values={associationsAccount.branchCd}
                      isEven
                    />
                    <Row
                      labels="支店名"
                      values={associationsAccount.branchName}
                    />
                    <Row
                      labels="種別"
                      values={switchAccountType(
                        associationsAccount.accountType
                      )}
                      isEven
                    />
                    <Row
                      labels="口座番号"
                      values={associationsAccount.accountNumber}
                    />
                    <Row
                      labels="口座名義"
                      values={associationsAccount.accountName}
                      isEven
                    />
                  </>
                )}
              </Table>
            )}

          <Table title="精算金振込口座" isRed>
            {application?.applicationWithdrawal?.institutionType === 'YUCHO' ? (
              <>
                <Row labels="金融機関の種類" values="ゆうちょ" />
                <Row
                  labels="店番"
                  values={application.applicationWithdrawal?.symbol || ''}
                />
                <Row
                  labels="番号"
                  values={application.applicationWithdrawal?.number || ''}
                />
              </>
            ) : (
              <>
                <Row labels="金融機関の種類" values="ゆうちょ銀行以外" />
                <Row
                  labels="金融機関コード"
                  values={
                    application?.applicationWithdrawal?.generalAccount
                      ?.institutionCode
                  }
                  isEven
                />
                <Row
                  labels="金融機関名"
                  values={
                    application.applicationWithdrawal?.generalAccount
                      ?.institutionName
                  }
                />
                <Row
                  labels="支店コード"
                  values={
                    application?.applicationWithdrawal?.generalAccount
                      ?.branchCode
                  }
                  isEven
                />
                <Row
                  labels="支店名"
                  values={
                    application?.applicationWithdrawal?.generalAccount
                      ?.branchName
                  }
                />
                <Row
                  labels="種別"
                  values={switchAccountType(
                    application?.applicationWithdrawal?.generalAccount
                      ?.accountType
                  )}
                  isEven
                />
                <Row
                  labels="口座番号"
                  values={
                    application?.applicationWithdrawal?.generalAccount
                      ?.accountNumber
                  }
                />
              </>
            )}
          </Table>
        </Stack>
        {application.applicationStatus !== 'COMPLETED' && application.eventNo && (
          <Stack direction="row" sx={{ alignItems: 'center' }} spacing={2}>
            <ApplicationButtons
              status={application.applicationStatus || ''}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              handleApplication={handleApplication}
              isWithdrawal={
                associationData.withdrawalAllSettlementUsageClassification !==
                  '1' && application?.applicationWithdrawal?.isAdditionalOption
              }
              isFull={
                (associationData.withdrawalAllSettlementUsageClassification !==
                  '1' &&
                  application?.applicationWithdrawal?.isAdditionalOption &&
                  application.applicationStatus === 'APPLYING') ||
                application.applicationStatus === 'PREPARATION'
                  ? IsNumeric(transferAmount) && transferAmount !== ''
                  : true
              }
            />
            {(application.applicationStatus === 'PREPARATION' ||
              application.applicationStatus === 'APPLYING') && (
              <NormalLink
                href={
                  application.applicationId
                    ? `/applications/${unitType}/${application.applicationId}/withdrawal/edit`
                    : `/applications/${unitType}`
                }
              >
                申請内容を修正する
              </NormalLink>
            )}
          </Stack>
        )}
      </Stack>
      {bottomData && (
        <Box sx={{ padding: '32px 160px' }}>
          <ApplicationBottom data={bottomData} unitType={unitType} />
        </Box>
      )}
    </Box>
  );
};
