/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Box, Stack, styled, Typography } from '@mui/material';

import { NormalLink } from '@/components/atoms/Link/NormalLink';
import {
  applicationLabels,
  bankLeftLabels,
  bankLeftLabelsYucho,
  bankRightLabels,
  bankRightLabelsYucho,
  bonusLabels,
  deadlineLabels,
  informationLeftLabels,
  informationRightLabels,
  urlLabels,
} from '@/constants/Association/tableLabels';
import { FC, ReactNode } from 'react';
import { AssociationOrganismProps } from '@/services/associationDomainService';
import { toDateTime } from '@/utils/formatter';

interface RowProps {
  label: ReactNode;
  value: ReactNode;
  underline: boolean;
}

interface BodyProps {
  labelData: { [key: string]: ReactNode };
  valueData: { [key: string]: ReactNode };
  isBonusReserveClassification?: boolean;
}

interface HeaderProps {
  title:
    | '登録情報'
    | '買増精算振込先口座'
    | 'ログイン/入会URL'
    | '持株会員向けの問合せ先'
    | '会員申請締日'
    | '申請受付月'
    | '賞与設定';
  linkLabel?:
    | '買増精算振込先口座を変更する'
    | '問合せ先を変更する'
    | '会員申請締日を変更する';
  href?: 'bank' | 'contact' | 'deadline';
  description?:
    | '退会申請時の買増精算にて会員が買増分の金額を振り込む際に使用される口座をご指定ください。'
    | '持株会パスワードは入会時に会員が使用します。'
    | '会員から事務局への連絡先として、会員側の画面に表示されます。';
}

const Row: FC<RowProps> = ({ label, value, underline }) => (
  <Stack
    borderBottom={underline ? '1px solid' : '0'}
    borderColor="system.separator-light"
    width="100%"
    pb={1}
  >
    <Box display="flex" gap={1}>
      <Typography
        variant="body-sub/regular"
        color="system.text-light"
        width={104}
        flexShrink={0}
      >
        {label}
      </Typography>
      <Typography variant="body-sub/regular" color="system.text-normal">
        {value}
      </Typography>
    </Box>
  </Stack>
);

const Body: FC<BodyProps> = ({
  labelData,
  valueData,
  isBonusReserveClassification = false,
}) => (
  <Stack spacing={1} width="100%" flexGrow={1}>
    {Object.entries(labelData).map(
      ([key, label], index, self) =>
        (!isBonusReserveClassification ||
          (isBonusReserveClassification &&
            key === 'bonusReserveClassification')) && (
          <Row
            key={key}
            label={label}
            value={valueData[key]}
            underline={self.length - 1 !== index}
          />
        )
    )}
  </Stack>
);

const Header: FC<HeaderProps> = ({
  title,
  linkLabel = '',
  href = '',
  description = '',
}) => (
  <Stack
    spacing={1}
    pb="12px"
    borderBottom="1px solid"
    borderColor="system.separator"
    width="100%"
  >
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Typography variant="h5" color="system.text-normal">
        {title}
      </Typography>
      {!!linkLabel && (
        <Box>
          <NormalLink href={href} icon>
            {linkLabel}
          </NormalLink>
        </Box>
      )}
    </Box>
    {!!description && (
      <Typography variant="body-main/regular" color="system.text-light">
        {description}
      </Typography>
    )}
  </Stack>
);

const Table = styled(Stack)(({ theme: t }) => ({
  width: '100%',
  flexGrow: 1,
  padding: '24px',
  gap: '16px',
  backgroundColor: t.palette.system['background-light'],
  borderRadius: '8px',
}));

const TableWrapper = styled(Box)({
  display: 'flex',
  gap: '20px',
});

export const AssociationOrganism: FC<AssociationOrganismProps> = ({
  ...association
}) => {
  const now = String(new Date());

  return (
    <Stack>
      <Box
        display="flex"
        height={88}
        padding="32px 40px"
        gap={1}
        bgcolor="system.background-light"
        borderBottom="1px solid"
        borderColor="system.separator-light"
      >
        <Typography variant="h4" color="system.text-normal">
          持株会情報
        </Typography>
        <Typography variant="body-main/regular" color="system.text-light">
          {toDateTime(now)} 現在
        </Typography>
      </Box>
      <Stack spacing={4} p="32px 40px" width="100%">
        <Stack spacing="20px">
          <TableWrapper>
            <Table>
              <Header title="登録情報" />
              <Box display="flex" gap={2} width="100%">
                <Body
                  labelData={informationLeftLabels}
                  valueData={{ ...association }}
                />
                <Body
                  labelData={informationRightLabels}
                  valueData={{ ...association }}
                />
              </Box>
            </Table>
          </TableWrapper>
          {association.withdrawalAddPurchaseUsageClassification === '1' && (
            <TableWrapper>
              <Table>
                <Header
                  title="買増精算振込先口座"
                  description="退会申請時の買増精算にて会員が買増分の金額を振り込む際に使用される口座をご指定ください。"
                  linkLabel="買増精算振込先口座を変更する"
                  href="bank"
                />
                <Box display="flex" gap={2} width="100%">
                  <Body
                    labelData={
                      association.accountClassification === 'YUCHO'
                        ? bankLeftLabelsYucho
                        : bankLeftLabels
                    }
                    valueData={
                      association.accountClassification === 'YUCHO'
                        ? {
                            accountNumber: `${
                              association.accountYuchoSymbol as string
                            }-${association.accountYuchoNumber as string}`,
                          }
                        : { ...association }
                    }
                  />
                  <Body
                    labelData={
                      association.accountClassification === 'YUCHO'
                        ? bankRightLabelsYucho
                        : bankRightLabels
                    }
                    valueData={{ ...association }}
                  />
                </Box>
              </Table>
            </TableWrapper>
          )}
          <TableWrapper>
            <Table>
              <Header
                title="ログイン/入会URL"
                description="持株会パスワードは入会時に会員が使用します。"
              />
              <Body labelData={urlLabels} valueData={{ ...association }} />
            </Table>
            <Table>
              <Header
                title="持株会員向けの問合せ先"
                description="会員から事務局への連絡先として、会員側の画面に表示されます。"
                linkLabel="問合せ先を変更する"
                href="contact"
              />
              <Stack spacing={1} width="100%" flexGrow={1}>
                <Row
                  label="担当者"
                  value={`${
                    association.inquiryDepartment +
                    ' ' +
                    association.inquiryRepresentative
                  }`}
                  underline
                />
                <Row
                  label="メールアドレス"
                  value={association.inquiryTelEmail}
                  underline
                />
                <Row label="電話番号" value={association.tel} underline />
              </Stack>
            </Table>
          </TableWrapper>
          <TableWrapper>
            <Table>
              <Header
                title="会員申請締日"
                linkLabel="会員申請締日を変更する"
                href="deadline"
              />
              <Box display="flex" gap={2} width="100%">
                <Body
                  labelData={deadlineLabels}
                  valueData={{ ...association }}
                />
              </Box>
            </Table>
          </TableWrapper>
          <TableWrapper>
            <Table>
              <Header title="申請受付月" />
              <Body
                labelData={applicationLabels}
                valueData={{ ...association }}
              />
            </Table>
            <Table>
              <Header title="賞与設定" />
              <Body
                labelData={bonusLabels}
                valueData={{ ...association }}
                isBonusReserveClassification={
                  association.bonusReserveClassification === 'なし'
                }
              />
            </Table>
          </TableWrapper>
        </Stack>
      </Stack>
    </Stack>
  );
};
