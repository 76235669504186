import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { BasicTable } from '@/components/molecules/Table/BasicTable';
import { theme } from '@/theme';
import styled from '@emotion/styled';
import {
  Box,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SortArrow } from '@/assets/sortArrow.svg';
import { TitleWithDate } from '@/components/molecules/Title/TitleWithDate';
import { CancelButton } from '@/components/atoms/Buttons/CancelButton';

import { toDateTime } from '@/utils/formatter';
import {
  switchAuthorityLabel,
  switchProxyApplicationLabel,
} from '@/utils/officeUser';
import { ListOfficeUserAssociations } from '@/types/api/officeUserAssociations';
import { useFindUser } from '@/services/customHooks/useFindUser';

const StyledTableCell = styled(TableCell)(() => ({
  backgroundColor: theme.palette.system.background,
  height: '42px',
  padding: theme.spacing(1.5),
  '&:first-of-type': {
    paddingLeft: theme.spacing(2.5),
  },
  '&:last-child': {
    paddingRight: theme.spacing(2.5),
  },
}));

const StyledTableCellWithArrow = styled(StyledTableCell)(() => ({
  backgroundColor: theme.palette.system['background-dark'],
}));

const TableHeadText = styled(Typography)(() => ({
  color: theme.palette.system['text-light'],
  ...theme.typography['caption/regular'],
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const SortArrowWrapper = styled(Box)(() => ({
  display: 'flex',
  width: '16px',
  height: '16px',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: theme.spacing(0.5),
  '&:hover': {
    cursor: 'pointer',
  },
}));

const SortArrowASC = styled(SortArrow)(() => ({
  transform: 'scale(1,-1)',
}));

interface OfficeUsersLIstProps {
  users: ListOfficeUserAssociations;
  isSort?: boolean;
  handleClickAuthoritySort: () => void;
  handleClickRow: (id: string) => void;
  handleClickRegisterButton: () => void;
  handleClickAddButton: () => void;
}

export const OfficeUsersLIst: FC<OfficeUsersLIstProps> = ({
  users,
  isSort = false,
  handleClickAuthoritySort,
  handleClickRow,
  handleClickRegisterButton,
  handleClickAddButton,
}) => {
  const navigate = useNavigate();
  const { data: findUser } = useFindUser();
  users.map((user) => {
    if (findUser?.email === user.email && user.authorityClassification === "GENERAL") {
      navigate(`/`);
    }
    return null;
  });
  return (
  <>
    <TitleWithDate
      title="担当者一覧"
      subtitle={`${toDateTime(String(new Date()))} 現在`}
    />
    <Stack px={4} py={5} spacing={3}>
      <Typography variant="body-sub/regular">
        すでに他の持株会に登録されている担当者を現在の持株会に追加する場合には、「既存担当者を追加する」から担当者を選んでください。
      </Typography>
      <Box display="flex" gap={2}>
        <PrimaryButton onClick={handleClickRegisterButton}>
          新規担当者を登録する
        </PrimaryButton>
        <CancelButton onClick={handleClickAddButton}>
          既存担当者を追加する
        </CancelButton>
      </Box>
      <Box mt={2}>
        <BasicTable disablePagination>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <TableHeadText>氏名</TableHeadText>
              </StyledTableCell>
              <StyledTableCellWithArrow>
                <Box display="flex" alignItems="center">
                  <TableHeadText>権限</TableHeadText>
                  <SortArrowWrapper onClick={handleClickAuthoritySort}>
                    {!isSort ? <SortArrow /> : <SortArrowASC />}
                  </SortArrowWrapper>
                </Box>
              </StyledTableCellWithArrow>
              <StyledTableCell>
                <TableHeadText>代理申請権限</TableHeadText>
              </StyledTableCell>
              <StyledTableCell>
                <TableHeadText>担当者</TableHeadText>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <StyledTableRow
                key={user.officeUserId}
                onClick={() => handleClickRow(String(user.officeUserId))}
                sx={{ cursor: 'pointer' }}
              >
                {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                <TableCell>{`${user.officeUserLastName} ${user.officeUserFirstName}`}</TableCell>
                <TableCell width="120px">
                  {switchAuthorityLabel(user.authorityClassification)}
                </TableCell>
                <TableCell width="128px">
                  {switchProxyApplicationLabel(
                    user.proxyApplicationClassification
                  )}
                </TableCell>
                <TableCell>{user.email}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </BasicTable>
      </Box>
    </Stack>
  </>
)};
