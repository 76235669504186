import {
  TextFieldProps,
  InputAdornment,
  IconButton,
  TextField,
} from '@mui/material';
import { forwardRef, useState } from 'react';
import { ReactComponent as IconOn } from '@/assets/visibilityOn.svg';
import { ReactComponent as IconOff } from '@/assets/visibilityOff.svg';
import { ReactComponent as Search } from '@/assets/search.svg';
import { ReactComponent as CancelSearch } from '@/assets/cancelSearch.svg';

type TextInputProps = {
  onClickDelete?: () => void;
} & TextFieldProps;

export const TextInput = forwardRef((props: TextInputProps, ref) => {
  const {
    type,
    sx,
    placeholder,
    helperText,
    error = false,
    onClickDelete,
    ...rest
  } = props;
  const [valueType, setValueType] = useState<string>('text');
  const handleClickShowPassword = () => {
    setValueType(valueType === 'text' ? 'password' : 'text');
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (typeof onClickDelete === 'function') {
      onClickDelete();
    }
  };

  return (
    <TextField
      type={valueType}
      placeholder={placeholder}
      sx={sx}
      error={error}
      helperText={error && helperText}
      inputRef={ref}
      InputProps={{
        style: {
          paddingLeft: type === 'search' ? '0px' : '8px',
          color: 'red',
        },
        startAdornment: type === 'search' && (
          <InputAdornment position="start" sx={{ marginLeft: '8px' }}>
            <Search />
          </InputAdornment>
        ),
        endAdornment:
          (type === 'password' && (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDown}
              >
                {valueType === 'text' ? <IconOn /> : <IconOff />}
              </IconButton>
            </InputAdornment>
          )) ||
          (type === 'search' && (
            <InputAdornment position="end">
              <IconButton
                onClick={(e) => handleClickDelete(e)}
                onMouseDown={handleMouseDown}
              >
                <CancelSearch />
              </IconButton>
            </InputAdornment>
          )) ||
          null,
      }}
      {...rest}
    />
  );
});
