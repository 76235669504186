import { Box, Typography } from '@mui/material';
import { FC } from 'react';

interface Props {
  width: string;
  height?: string;
  borderRight?: boolean;
  borderBottom?: boolean;
  align?: 'left' | 'right';
  children: string;
}

/**
 * Td: Table Data の略
 */
export const Td: FC<Props> = ({
  width,
  height = '40px',
  borderRight = false,
  borderBottom = false,
  align = 'left',
  children,
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: align === 'left' ? 'flex-start' : 'flex-end',
      alignItems: 'center',
      flexShrink: 0,
      width,
      height,
      p: '12px',
      gap: '10px',
      borderRight: borderRight ? '1px solid' : '0',
      borderBottom: borderBottom ? '1px solid' : '0',
      borderColor: 'system.separator-light',
    }}
  >
    <Typography
      sx={{
        color: 'system.text-normal',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '100%',
      }}
    >
      {children}
    </Typography>
  </Box>
);
