import { Box, styled } from '@mui/material';
import { HomeEventSection } from '@/components/organisms/Home/HomeEventSection';
import { HomeScheduleSection } from '@/components/organisms/Home/HomeScheduleSection';
import { HomeMessageSection } from '@/components/organisms/Home/HomeMessageSection';
import { useRecoilValue } from 'recoil';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const Wrapper = styled(Box)({
  padding: '32px 40px',
});

export const Home = () => {
  const { associationCd = '' } = useRecoilValue(SelectedAssociationState);
  useHelmetHandler({
    title: '事務局トップ',
  });

  return associationCd === '' ? (
    <Wrapper>
      <div>持株会を選択してください</div>
    </Wrapper>
  ) : (
    <Wrapper>
      <HomeMessageSection associationCd={associationCd} />
      <HomeEventSection associationCd={associationCd} />
      <HomeScheduleSection associationCd={associationCd} />
    </Wrapper>
  );
};
