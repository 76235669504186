import useSWR from 'swr';
import { appClient } from '@/services';
import { OptionalInfomationItem, InfomationItem } from '@/types/api/Infomation';

import { useSessionTimeOutModal } from '@/hooks/useSessionTimeOutModal';

const convertInformations = (item: OptionalInfomationItem): InfomationItem => ({
  associationInformationId: item?.associationInformationId || 0,
  associationCd: item?.associationCd || '',
  informationType: item?.informationType || 'PRIVATE',
  displayPeriodFrom: item?.displayPeriodFrom || new Date().toISOString(),
  displayPeriodTo: item?.displayPeriodTo || new Date().toISOString(),
  informationTitle: item?.informationTitle || '',
  informationBody: item?.informationBody || '',
  priority: item?.priority || 'LOW',
  informationStatus: item?.informationStatus || 'NORMAL',
});

export const useInformations = (associationCd: string) => {
  const { handleSessionTimeOutModalOpen } = useSessionTimeOutModal();
  // 【API-B-0604】事務局向けお知らせ一覧取得
  const { data: apiData, error } = useSWR<
    {
      informations?: OptionalInfomationItem[];
    },
    Error
  >(
    associationCd !== ''
      ? ['/api/associationinformations', associationCd]
      : null,
    // @ts-ignore
    () => appClient.informations.listAssociationInformations(associationCd)
  );

  // session timeout
  // @ts-ignore
  if (associationCd !== '' && error && error.status === 401) {
    handleSessionTimeOutModalOpen();
  }

  return {
    informations: apiData
      ? apiData.informations?.map((item: OptionalInfomationItem) =>
          convertInformations(item)
        )
      : undefined,
    isLoading: !error && !apiData,
    error,
  };
};
