import { CancelButton } from '@/components/atoms/Buttons/CancelButton';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { SubmitButton } from '@/components/atoms/Buttons/SubmitButton';
import { Box } from '@mui/material';
import { FC } from 'react';

interface ButtonBoxProps {
  isConfirmed: boolean;
  handleClickCancel: () => void;
}
export const ButtonBox: FC<ButtonBoxProps> = ({
  isConfirmed,
  handleClickCancel,
}) => (
  <Box>
    {isConfirmed ? (
      <Box display="flex" gap={2}>
        <CancelButton onClick={handleClickCancel}>入力に戻る</CancelButton>
        <SubmitButton>持株会情報を登録する</SubmitButton>
      </Box>
    ) : (
      <PrimaryButton type="submit" disabled={false}>
        確認へ進む
      </PrimaryButton>
    )}
  </Box>
);
