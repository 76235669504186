import { MemberProxyOrganism } from '@/components/organisms/Members/MemberProxyOrganism';
import { useMemberSecuritiesAccount } from '@/services/customHooks/useMemberSecuritiesAccount';
import { useFindMember } from '@/services/customHooks/useFindMember';
import { useParams } from 'react-router-dom';
import { Box, styled } from '@mui/material';
import { GetAssociation } from '@/types/api/associations';
import { useEffect, useState } from 'react';
import { appClient } from '@/services';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { MembersHeader } from './MembersHeader';

const Wrapper = styled(Box)({
  width: '100%',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: '32px 40px',
  gap: '24px',
});

export const MemberProxyIndex = () => {
  const { data: memberData } = useFindMember();
  const { memberId } = useParams();
  const { data: account } = useMemberSecuritiesAccount(Number(memberId));
  const [association, setAssociation] = useState<GetAssociation>();
  useHelmetHandler({
    title: '持株会会員詳細 代理申請',
  });

  const getAssociation = async () => {
    try {
      if (memberData?.associationCd) {
        const res = await appClient.associations.getAssociation(
          memberData?.associationCd
        );
        setAssociation(res);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    void getAssociation();
  }, [memberData]);
  return (
    <Wrapper>
      <MembersHeader memberData={memberData} />
      <MemberProxyOrganism
        financialInstitutionName={account?.branchName ?? ''}
        userStatus={memberData?.userStatus}
        isListedClassification={association?.listedClassification}
      />
    </Wrapper>
  );
};
