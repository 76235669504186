import {
  accountType as AccountType,
  bonusMultiplierDesignationType as BonusMultiplierDesignationType,
  bonusReserveClassification as BonusReserveClassification,
} from '@/constants/Association/codeList';
import { AssociationProps } from '@/types/api/associations';
import { toZip } from '@/utils/formatter';

export type AssociationOrganismProps = ReturnType<
  typeof AssociationDomainService.formatData
>;

export class AssociationDomainService {
  /**
   * 持株会情報ページのためのデータフォーマッタ
   * /associations/:associationCd/detail
   * @param association {AssociationProps}
   * @return 持株会情報ページで使うObjectデータ
   */
  public static formatData(association: AssociationProps) {
    const {
      joinDt: jDt = '',
      associationZipcode = '',
      deploymentAddress1 = '',
      deploymentAddress2 = '',
      supplementAddress1 = '',
      supplementAddress2 = '',
      accountType: aType = 'CURRENT',
      inquiryDepartment = '',
      inquiryRepresentative = '',
      inquiryTelExtension = '',
      inquiryTelOutsideLine = '',
      bonusReserveClassification: bonusRC = 'NONE',
      bonusMultiplierDesignationType: bonusMDT = 'UNIT_NUMBER',
      bonusMultiplierDetails: bonusMD = '',
      memberDeadlineDays: memberDD = '',
    } = association;
    /**
     * 加入日
     */
    const joinDt = jDt.replace(/-/g, '/');
    /**
     * 住所
     */
    const zip = toZip(associationZipcode);
    const address = [
      zip,
      <br key="br" />,
      `${deploymentAddress1}${deploymentAddress2}${supplementAddress1}${supplementAddress2}`,
    ];
    /**
     * 種別
     */
    const accountType = AccountType[aType];
    /**
     * 会員向けURL
     */
    const kaiin = 'https://nexstocks.jp';
    /**
     * 事務局向けURL
     */
    const jimukyoku = 'https://officers.nexstocks.jp';
    /**
     * 入会向けURL
     */
    const nyuukai = 'https://nexstocks.jp/signup';
    /**
     * 担当者
     */
    const representative = `${inquiryDepartment} ${inquiryRepresentative}`;
    /**
     * 電話番号
     */
    const tel = [
      `外線 ${inquiryTelOutsideLine}`,
      <br key="br" />,
      `内線 ${inquiryTelExtension}`,
    ];
    /**
     * 口数変更
     */
    const unitMonth = this.getReceptionMonth(association, 'unitMonth');
    /**
     * 入会
     */
    const newUserMonth = this.getReceptionMonth(association, 'newUserMonth');
    /**
     * 積立有無区分
     */
    const bonusReserveClassification = BonusReserveClassification[bonusRC];
    /**
     * 倍率指定
     */
    const bonusMultiplierDesignationType =
      BonusMultiplierDesignationType[bonusMDT];
    /**
     * 倍率詳細
     */
    const bonusMultiplierDetails =
      association.bonusMultiplierDesignationType === 'UNIT_NUMBER'
        ? '会員が自由に倍率を指定できます'
        : bonusMD
            .split(',')
            .map((v) => `${v}倍`)
            .join(', ');
    const memberDeadlineDays =
      memberDD === 99
        ? '買付日の前月末'
        : `買付日の${String(memberDD ?? 0)}日前`;

    return {
      ...association,
      joinDt,
      address,
      accountType,
      kaiin,
      jimukyoku,
      nyuukai,
      representative,
      tel,
      unitMonth,
      newUserMonth,
      bonusReserveClassification,
      bonusMultiplierDesignationType,
      bonusMultiplierDetails,
      memberDeadlineDays,
    };
  }

  public static getReceptionMonth(data: AssociationProps, prefix: string) {
    const receptionMonths = Object.entries(data).reduce(
      (prev: string[], curr) => {
        const [key, value] = curr;
        if (key.includes(prefix) && value === '1') {
          const month = key.replace(/[a-zA-Z]+(\d+)/g, '$1');
          return [...prev, month];
        }

        return prev;
      },
      []
    );

    return receptionMonths.length === 12
      ? '毎月受付する'
      : [
          '受付月を指定する',
          <br key="br" />,
          receptionMonths
            .map((month) => Number(month))
            .sort((a, b) => (a > b ? 1 : -1))
            .map((e) => `${e}月`)
            .join(' '),
        ];
  }
}
