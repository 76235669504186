import { Association } from '@/types/api/Association/association';
import useSWR from 'swr';
import { appClient } from '..';

const ConvertAssociation = (
  data:
    | {
        officeCd?: string;
        associationCd?: string;
        associationName?: string;
        stockCode?: string;
        associationType?: string;
        associationRepresentative?: string;
        associationTel?: string;
        associationEmail?: string;
        associationZipcode?: string;
        associationAddressCd?: string;
        deploymentAddress1?: string;
        deploymentAddress2?: string;
        supplementAddress1?: string;
        supplementAddress2?: string;
        investmentDigit?: number;
        dividendProcessingClassification?: string;
        withdrawalSettlementDigit?: number;
        withdrawalAllSettlementUsageClassification?: string;
        withdrawalLessThanSettlementUsageClassification?: string;
        withdrawalAddPurchaseUsageClassification?: string;
        associationStatus?: string;
        dissolutionYmd?: string;
        openedFlg?: boolean;
        listedClassification?: 'LISTEDCOMPANY' | 'UNLISTEDCOMPANY';
        stockOfficialNameKanji?: string;
        settlementCnt?: number;
        settlementMonth?: number;
      }
    | undefined
) =>
  data
    ? ({
        officeCd: data.officeCd || '',
        associationCd: data.associationCd || '',
        associationName: data.associationName || '',
        stockCode: data.stockCode || '',
        associationType: data.associationType || '',
        associationRepresentative: data.associationRepresentative || '',
        associationTel: data.associationTel || '',
        associationEmail: data.associationEmail || '',
        associationZipcode: data.associationZipcode || '',
        associationAddressCd: data.associationAddressCd || '',
        deploymentAddress1: data.deploymentAddress1 || '',
        deploymentAddress2: data.deploymentAddress2 || '',
        supplementAddress1: data.supplementAddress1 || '',
        supplementAddress2: data.supplementAddress2 || '',
        investmentDigit: data.investmentDigit || 0,
        dividendProcessingClassification:
          data.dividendProcessingClassification || '',
        withdrawalSettlementDigit: data.withdrawalSettlementDigit || 0,
        withdrawalAllSettlementUsageClassification:
          data.withdrawalAllSettlementUsageClassification || '',
        withdrawalLessThanSettlementUsageClassification:
          data.withdrawalLessThanSettlementUsageClassification || '',
        withdrawalAddPurchaseUsageClassification:
          data.withdrawalAddPurchaseUsageClassification || '',
        associationStatus: data.associationStatus || '',
        dissolutionYmd: data.dissolutionYmd || '',
        openedFlg: data.openedFlg || false,
        listedClassification: data.listedClassification || 'LISTEDCOMPANY',
        stockOfficialNameKanji: data.stockOfficialNameKanji || '',
        settlementCnt: data.settlementCnt || 0,
        settlementMonth: data.settlementMonth || 0,
      } as Association)
    : undefined;

export const useAssociation = (associationCd: string) => {
  // 【API-C-0710】申請書取得
  const { data: apiData } = useSWR('/api/applications/{applicationId}', () =>
    appClient.associations.getAssociation(associationCd)
  );
  return ConvertAssociation(apiData);
};
