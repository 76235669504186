import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { FC } from 'react';

const styleContainer: SxProps<Theme> = [
  {
    width: '580px',
    height: '177px',
    textAlign: 'center',
    p: '32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    borderRadius: '8px',
  },
];
interface LogoutProps {
  handleClickLogout: () => void;
}

/**
 * TimeOut
 * @constructor
 */
export const TimeOut: FC<LogoutProps> = ({ handleClickLogout }) => (
  <Box sx={styleContainer}>
    <Typography
      component="p"
      variant="body-main/regular"
      sx={{ color: 'system.text-normal' }}
    >
      一定時間操作がなかったため、自動的にログアウトしました。
      <br />
      再度ログインしてください。
    </Typography>
    <PrimaryButton sx={{ width: '176px' }} onClick={handleClickLogout}>
      ログイン画面へ移動
    </PrimaryButton>
  </Box>
);
