import { Box } from '@mui/material';
import { FC } from 'react';

interface Props {
  width: string;
  height: string;
  bold?: boolean;
  borderRight?: boolean;
  borderBottom?: boolean;
  align?: 'left' | 'right' | 'center';
  children: string | React.ReactNode;
}

/**
 * Table Data の略
 */
export const Td: FC<Props> = ({
  width,
  height,
  bold,
  borderRight = false,
  borderBottom = false,
  align = 'center',
  children,
}) => {
  const getJustifyContent = () => {
    switch (align) {
      case 'left': {
        return 'flex-start';
      }
      case 'right': {
        return 'flex-end';
      }
      default: {
        return 'center';
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: getJustifyContent(),
        alignItems: 'center',
        flexShrink: 0,
        width,
        height,
        p: '12px',
        gap: '10px',
        borderRight: borderRight ? '1px solid' : '0',
        borderBottom: borderBottom ? '1px solid' : '0',
        borderColor: 'system.separator-light',
      }}
    >
      <Box
        sx={{
          color: 'system.text-normal',
          fontStyle: 'normal',
          fontWeight: bold ? 700 : 400,
          fontSize: bold ? '18px' : '16px',
          lineHeight: bold ? '150%' : '100%',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
