import { Button, ButtonProps, styled } from '@mui/material';
import { FC, useRef } from 'react';

const MuiButton = styled(Button)(({ theme }) => {
  const { palette, typography } = theme;
  return {
    ...typography['body-main/bold'],
    background: palette.states.error,
    color: palette.system.white,
    padding: '8px 16px 9px 16px',
    boxShadow: 'none',
    borderRadius: '4px',
    '&:hover': {
      background: '#A70707',
      boxShadow: 'none',
    },
    '&:disabled': {
      background: palette.system['background-dark'],
      color: palette.system.placeholder,
    },
  };
});

// Disable time, could be different compared to other buttons. Therefore, separate const
const sleepTime = 2000;

export const SecondaryButton: FC<ButtonProps> = (props) => {
  const buttonRef = useRef() as React.MutableRefObject<HTMLButtonElement>;

  return (
    <MuiButton
      ref={buttonRef}
      variant="contained"
      {...props}
      onClick={(e) => {
        if (e.detail > 1) {
          if (buttonRef.current.disabled) return;
          buttonRef.current.disabled = true;
          setTimeout(() => {
            buttonRef.current.disabled = false;
          }, sleepTime);
        }
        const { onClick } = props;
        if (onClick) onClick(e);
      }}
    />
  );
};
