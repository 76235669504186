/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * ユーザー情報取得
   * 事務局のログインユーザーを情報を取得する
   * @returns any office response
   * @throws ApiError
   */
  public findUser(): CancelablePromise<{
    /**
     * 事務局担当者ID
     */
    officeUserId?: number;
    /**
     * ユーザーID
     */
    userId?: string;
    /**
     * 氏名漢字（姓）
     */
    lastNameKanji?: string;
    /**
     * 氏名漢字（名）
     */
    firstNameKanji?: string;
    /**
     * メールアドレス
     */
    email?: string;
    /**
     * ユーザー種別
     */
    userType?: string;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/user',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * ログアウト
   * ユーザーのログアウト
   *
   * @returns any user signout response
   * @throws ApiError
   */
  public userSignOut(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/users/signout',
      errors: {
        400: `bad request`,
        401: `unauthorized`,
      },
    });
  }
}
