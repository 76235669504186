import { AccountSection } from '@/components/organisms/Association/FirstRegistration/AccountSection';
import { ApplicationDeadlineSection } from '@/components/organisms/Association/FirstRegistration/ApplicationDeadlineSection';
import { AssociationInfoSection } from '@/components/organisms/Association/FirstRegistration/AssociationInfoSection';
import { ButtonBox } from '@/components/organisms/Association/FirstRegistration/ButtonBox';
import { ContactInfoSection } from '@/components/organisms/Association/FirstRegistration/ContactInfoSection';
import { StyledStack } from '@/components/organisms/Association/FirstRegistration/StyledComponent';
import { Path } from '@/constants/Router/path';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

import { useSnackbar } from '@/hooks/useSnackbar';
import { appClient } from '@/services';
import { useGetAssociationsContribution } from '@/services/customHooks/useGetAssociationsContribution';
import { UpdateAssociationsFirst } from '@/types/api/associations';
import { ErrorResponse } from '@/types/api/error';
import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginState } from './PasswordSetting';

export const Association = () => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LoginState;
  const { data: contribution, error: errorContribution } =
    useGetAssociationsContribution();
  const { showSnackbar } = useSnackbar();
  const formMethods = useForm<UpdateAssociationsFirst>({
    defaultValues: {
      inquiryDepartment: '',
      inquiryRepresentative: '',
      inquiryEmail: '',
      inquiryTelOutsideLine: '',
      inquiryTelExtension: '',
      memberDeadlineDays: 10,
      accountClassification: 'OTHER',
      financialInstitutionName: '',
      bankBranchCd: '',
      bankBranchName: '',
      accountType: 'SAVINGS',
      accountNumber: '',
      accountName: '',
      accountYuchoSymbol: '',
      accountYuchoNumber: '',
    },
  });

  const { handleSubmit } = formMethods;

  const onFormSubmit = async (data: UpdateAssociationsFirst) => {
    if (!isConfirmed) {
      setIsConfirmed(true);
      return;
    }

    if (data.memberDeadlineDays?.toString() === '前月末') {
      data.memberDeadlineDays = 99;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    await appClient.associations
      .updateAssociationsFirst(state?.association?.associationCd ?? '', data)
      .then(() => navigate(Path.LOGIN_ASSOCIATION_COMPLETE))
      .catch((e) => showSnackbar((e as ErrorResponse).data.message));
  };

  const handleClickCancel = () => {
    setIsConfirmed(false);
  };

  useHelmetHandler({
    title: isConfirmed ? '初回 持株会情報登録 内容確認' : '初回 持株会情報登録',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isConfirmed]);
  useEffect(() => {
    if (errorContribution) {
      showSnackbar((errorContribution as ErrorResponse).data.message);
    }
  }, [errorContribution]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Box width="100%" display="flex" justifyContent="center">
          <StyledStack spacing={5}>
            <Stack spacing={2}>
              <Typography variant="h3">
                {isConfirmed ? '持株会情報入力内容確認' : '持株会情報入力'}
              </Typography>
              <Typography variant="body-main/regular">
                {isConfirmed
                  ? '入力内容を確認してください。'
                  : '持株会の基本情報を入力してください。'}
              </Typography>
            </Stack>
            <AssociationInfoSection
              association={state?.association ?? {}}
              joinDt={contribution.joinDt}
            />
            <ContactInfoSection isConfirmed={isConfirmed} />
            <ApplicationDeadlineSection isConfirmed={isConfirmed} />
            {state?.association?.withdrawalAddPurchaseUsageClassification ===
              '1' && <AccountSection isConfirmed={isConfirmed} />}

            <ButtonBox
              isConfirmed={isConfirmed}
              handleClickCancel={handleClickCancel}
            />
          </StyledStack>
        </Box>
      </form>
    </FormProvider>
  );
};
