/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { FindTradereportsByID } from '@/types/api/tradereports';
import { TableContainer } from './TableContainer';
import { Row } from './Row';
import { Td } from './Td';
import { Th } from './Th';

interface Props {
  report: Required<FindTradereportsByID>;
}

export const MonthlyContributionDetail: FC<Props> = ({ report }) => {
  const {
    carryoverMoney,
    contribution,
    incentives,
    plusCount,
    plusContribution,
    plusIncentives,
    contributionTotal,
  } = report.monthlyBonus as Required<Props['report']['monthlyBonus']>;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '8px',
      }}
    >
      <Typography variant="h5" sx={{ color: 'system.text-normal' }}>
        拠出金明細
      </Typography>
      <TableContainer width="444px">
        <Row>
          <Th variant="regular" bgcolor="secondary.bg" width="184px">
            繰越金
          </Th>
          <Td width="260px" align="right">
            {`${carryoverMoney.toLocaleString()}円`}
          </Td>
        </Row>
        <Row>
          <Th
            variant="regular"
            bgcolor="system.white"
            width="96px"
            height="80px"
          >
            月例
          </Th>
          <Box>
            <Th
              variant="regular"
              bgcolor="secondary.bg"
              width="88px"
              borderBottom
            >
              拠出金
            </Th>
            <Th variant="regular" bgcolor="secondary.bg" width="88px">
              奨励金
            </Th>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Td width="260px" align="right" borderBottom>
              {`${contribution.toLocaleString()}円`}
            </Td>
            <Td width="260px" align="right">
              {`${incentives.toLocaleString()}円`}
            </Td>
          </Box>
        </Row>
        <Row>
          <Th
            variant="regular"
            bgcolor="system.white"
            width="96px"
            height="120px"
          >
            月例追加
          </Th>
          <Box>
            <Th
              variant="regular"
              bgcolor="secondary.bg"
              width="88px"
              borderBottom
            >
              件数
            </Th>
            <Th
              variant="regular"
              bgcolor="secondary.bg"
              width="88px"
              borderBottom
            >
              拠出金
            </Th>
            <Th variant="regular" bgcolor="secondary.bg" width="88px">
              奨励金
            </Th>
          </Box>
          <Box>
            <Td width="260px" align="right" borderBottom>
              {`${plusCount.toLocaleString()}件`}
            </Td>
            <Td width="260px" align="right" borderBottom>
              {`${plusContribution.toLocaleString()}円`}
            </Td>
            <Td width="260px" align="right">
              {`${plusIncentives.toLocaleString()}円`}
            </Td>
          </Box>
        </Row>
        <Row borderBottom={false}>
          <Th variant="medium" width="184px" bgcolor="secondary.light">
            合計 (E)
          </Th>
          <Td width="260px" align="right">
            {`${contributionTotal.toLocaleString()}円`}
          </Td>
        </Row>
      </TableContainer>
    </Box>
  );
};
