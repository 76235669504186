/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { FindTradereportsByID } from '@/types/api/tradereports';
import { TableContainer } from './TableContainer';
import { Row } from './Row';
import { Td } from './Td';
import { Th } from './Th';

interface Props {
  report: Required<FindTradereportsByID>;
}

export const Residual: FC<Props> = ({ report }) => {
  const { residual } = report.monthlyBonus as Required<
    Props['report']['monthlyBonus']
  >;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '8px',
      }}
    >
      <Typography variant="h5" sx={{ color: 'system.text-normal' }}>
        残余金 (購入可能代金)
      </Typography>
      <TableContainer width="444px">
        <Row borderBottom={false}>
          <Th variant="medium" width="184px" bgcolor="secondary.light">
            (E+F+G-H+I-J)
          </Th>
          <Td width="260px" align="right">
            {`${residual.toLocaleString()}円`}
          </Td>
        </Row>
      </TableContainer>
    </Box>
  );
};
