import { SelectBox, SelectBoxProps } from '@/components/atoms/Input/SelectBox';
import { deadlineLabels } from '@/constants/Association/tableLabels';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { AssociationRequest } from '@/types/api/associations';
import { AssociationOrganismProps } from '@/types/components/Association';
import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useController } from 'react-hook-form';
import { AssociationButtons } from './AssociationButtons';
import { AssociationHeader } from './AssociationHeader';
import { AssociationRow as Row } from './AssociationRow';

const StyledSelectBox: FC<SelectBoxProps> = ({ name = '', ...props }) => {
  const {
    field: { ref, ...rest },
  } = useController({ name });

  return <SelectBox inputRef={ref} {...rest} {...props} />;
};

export const AssociationDeadlineOrganism: FC<
  AssociationOrganismProps<AssociationRequest>
> = ({ isConfirmed, setIsConfirmed, methods, isError, setIsError }) => {
  const watchDeadline = methods.watch('memberDeadlineDays');
  useHelmetHandler({
    title: isConfirmed ? '持株会詳細 締日変更 確認' : '持株会詳細 締日変更',
  });

  return (
    <>
      <AssociationHeader type="deadline" isConfirmed={isConfirmed} />
      <Stack>
        <Stack p="25px 40px">
          <Row label={deadlineLabels.memberDeadlineDays}>
            {isConfirmed ? (
              <Typography
                variant="body-main/regular"
                color="system.text-normal"
              >
                {
                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call
                  watchDeadline === 99 ? '前月末' : `${watchDeadline}日前`
                }
              </Typography>
            ) : (
              <Box display="flex" alignItems="center" gap={1}>
                <StyledSelectBox
                  name="memberDeadlineDays"
                  width="80px"
                  isKeyAValue
                  items={[...Array(37)].map((_, i, self) => {
                    if (self.length === i + 1) {
                      return {
                        key: '前月末',
                        value: '前月末',
                      };
                    }
                    if (i >= 30) {
                      const additionalValue = 35 + (i - 30) * 5;
                      return {
                        key: String(additionalValue),
                        value: String(additionalValue),
                      };
                    }
                    return {
                      key: String(i + 1),
                      value: String(i + 1),
                    };
                  })}
                  value={
                    methods.getValues('memberDeadlineDays') === 99
                      ? '前月末'
                      : methods.getValues('memberDeadlineDays')
                  }
                  onChange={(e) =>
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
                    methods.setValue(
                      'memberDeadlineDays',
                      e.target.value !== '前月末' ? Number(e.target.value) : 99
                    )
                  }
                />
                {watchDeadline !== 99 && (
                  <Typography
                    variant="body-sub/regular"
                    color="system.text-normal"
                  >
                    日前
                  </Typography>
                )}
              </Box>
            )}
          </Row>
          <AssociationButtons
            isConfirmed={isConfirmed}
            setIsConfirmed={setIsConfirmed}
            isError={isError}
            setIsError={setIsError}
          />
        </Stack>
      </Stack>
    </>
  );
};
