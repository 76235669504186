import { CancelButton } from '@/components/atoms/Buttons/CancelButton';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { Box, Stack, styled } from '@mui/material';
import { FC } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

interface MembersProxyButtonsProps {
  isConfirmed: boolean;
  isUnit?: boolean;
  setIsConfirmed: (isConfirmed: boolean) => void;
}

const Wrapper = styled(Stack)({
  width: '100%',
  alignItems: 'center',
});

export const MembersProxyButtons: FC<MembersProxyButtonsProps> = ({
  isConfirmed,
  isUnit,
  setIsConfirmed,
}) => {
  const completed = useLocation().pathname.includes('complete');
  const { memberId = '' } = useParams();
  const baseUrl = `/members/${memberId}/proxy`;
  const navigate = useNavigate();

  const isDisabled = !isUnit;

  if (completed) {
    return (
      <Wrapper>
        <PrimaryButton onClick={() => navigate(baseUrl)}>
          会員詳細へ戻る
        </PrimaryButton>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {isConfirmed ? (
        <Box display="flex" gap={2}>
          <CancelButton onClick={() => setIsConfirmed(false)}>
            入力へ戻る
          </CancelButton>
          <PrimaryButton type="submit">申請する</PrimaryButton>
        </Box>
      ) : (
        <PrimaryButton
          disabled={isDisabled}
          onClick={() => setIsConfirmed(true)}
        >
          確認へ進む
        </PrimaryButton>
      )}
    </Wrapper>
  );
};
