import { CancelButton } from '@/components/atoms/Buttons/CancelButton';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { FC } from 'react';

const styleContainer: SxProps<Theme> = [
  {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    width: '580px',
    p: '32px',
  },
];

interface LogoutProps {
  handleClickCancel: () => void;
  handleClickLogout: () => void;
}

export const Logout: FC<LogoutProps> = ({
  handleClickCancel,
  handleClickLogout,
}) => (
  <Box sx={styleContainer}>
    <Typography variant="h4" sx={{ color: 'system.text-normal' }}>
      ログアウト
    </Typography>
    <Typography
      component="p"
      variant="body-main/regular"
      sx={{ color: 'system.text-normal' }}
    >
      ログアウトしますか？
    </Typography>
    <Box sx={{ display: 'flex', gap: '8px' }}>
      <CancelButton
        sx={{
          width: '112px',
          height: '41px',
          whiteSpace: 'nowrap',
        }}
        onClick={handleClickCancel}
      >
        キャンセル
      </CancelButton>
      <PrimaryButton
        sx={{
          width: '144px',
          height: '41px',
          whiteSpace: 'nowrap',
        }}
        onClick={handleClickLogout}
      >
        ログアウトする
      </PrimaryButton>
    </Box>
  </Box>
);
