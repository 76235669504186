import { Box, Link, LinkProps } from '@mui/material';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as RightIcon } from '@/assets/arrowRight.svg';

interface NormalLinkProps extends LinkProps {
  icon?: boolean;
  stroke?: string;
  applicationType?: string;
}

export const NormalLink: FC<NormalLinkProps> = ({
  icon = false,
  stroke,
  href,
  children,
  applicationType,
  ...props
}) => (
  /**
   * 幅が100%になってしまわないようブロック要素でラップし、displayをinline-flexに設定
   */
  <Box>
    <Link
      component={RouterLink}
      to={href || ''}
      variant="body-sub/medium"
      color="secondary.normal"
      display="inline-flex"
      gap="4px"
      alignItems="center"
      state={{ applicationType }}
      {...props}
    >
      {children}
      {icon && <RightIcon stroke={stroke || '#169FA6'} />}
    </Link>
  </Box>
);
