import { IconButton, Popover, styled, Typography, Stack } from '@mui/material';
import { FC, ReactElement, useState } from 'react';

interface PopOverProps {
  text?: string;
  icon?: ReactElement;
  title?: string;
}

const Wrapper = styled('div')({
  display: 'inline',
});

const MuiIconButton = styled(IconButton)({
  padding: '0px',
  '&:hover': {
    background: 'none',
  },
});

const MuiBox = styled(Stack)(() => ({
  padding: '16px',
  gap: '4px',
  maxWidth: '320px',
}));

const MuiPopover = styled(Popover)(({ theme }) => ({
  '.MuiPopover-paper': {
    boxShadow: 'none',
    background: theme.palette.system['background-light'],
    borderRadius: '8px',
    border: `1px solid`,
    borderColor: theme.palette.system.separator,
  },
}));

export const PopoverWithText: FC<PopOverProps> = (props) => {
  const { title, text, icon } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Wrapper>
      {icon && (
        <MuiIconButton
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {icon}
        </MuiIconButton>
      )}
      <MuiPopover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <MuiBox>
          <Typography variant="body-sub/bold">{title}</Typography>
          <Typography variant="caption/regular">{text}</Typography>
        </MuiBox>
      </MuiPopover>
    </Wrapper>
  );
};
