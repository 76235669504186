import {
  styled,
  TableRow,
  TableCell,
  Typography,
  SxProps,
} from '@mui/material';

interface TableProps {
  children: React.ReactNode;
  title?: string;
  SecondaryTitle?: string;
  subTitle?: string;
  row?: number;
  sx?: SxProps;
}

interface SubProps {
  title: string;
  children: React.ReactNode;
}

const MuiTableCellTh = styled(TableCell)(({ theme }) => {
  const { palette, typography } = theme;
  return {
    ...typography['body-main/bold'],
    color: palette.system['text-normal'],
    background: palette.primary.light,
    width: '170px',
    textAlign: 'center',
  };
});

const StyledTableRow = styled(TableRow)(({ theme }) => {
  const { palette } = theme;
  return {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:nth-of-type(even)': {
      background: palette.system['background-light'],
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  };
});

const SubWrraper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const SubTitle = styled('div')({
  width: '72px',
  marginRight: '16px',
});

const SubContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'bold',
})<{ bold?: boolean }>(({ bold }) => ({
  fontWeight: bold ? 'bold' : 'normal',
}));

export const PrimaryTableBodyTdMulti = ({
  children,
  title = '',
  SecondaryTitle = '',
  subTitle,
  row = 1,
  sx,
}: TableProps) => (
  <StyledTableRow>
    <MuiTableCellTh rowSpan={row} sx={{ border: 'none' }}>
      <Typography variant="body-main/bold" sx={{ display: 'block' }}>
        {title}
      </Typography>
      <Typography variant="body-main/bold" sx={{ display: 'block' }}>
        {SecondaryTitle}
      </Typography>
      {subTitle && (
        <Typography variant="caption/regular" sx={{ display: 'block' }}>
          {subTitle}
        </Typography>
      )}
    </MuiTableCellTh>
    <TableCell sx={sx} style={{ border: 'none' }}>
      {children}
    </TableCell>
  </StyledTableRow>
);

export const PrimaryTableBodyTdSub = ({ title, children }: SubProps) => (
  <SubWrraper>
    <SubTitle>{title}</SubTitle>
    <SubContent>{children}</SubContent>
  </SubWrraper>
);
