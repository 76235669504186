import { CancelButton } from '@/components/atoms/Buttons/CancelButton';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { theme } from '@/theme';
import { Box, SxProps, Theme, Typography, styled } from '@mui/material';
import { FC } from 'react';

const styleContainer: SxProps<Theme> = [
  {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    width: 520,
    p: '32px',
  },
];

const Title = styled(Typography)({
  ...theme.typography.h4,
  color: theme.palette.system['text-normal'],
});

const Subtitle = styled(Typography)({
  marginTop: 24,
  ...theme.typography['body-main/regular'],
  color: theme.palette.system['text-normal'],
});

const ContentWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'column',
  gap: 8,
  marginTop: 24,
  padding: 16,
  borderRadius: 4,
  backgroundColor: theme.palette.system.background,
});

const ContentRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
  ...theme.typography['body-main/medium'],
  color: theme.palette.system['text-normal'],
});

const ContentRowLabel = styled(Box)({
  width: 81,
});

const ContentRowText = styled(Box)({});

const ContentDetails = styled(Typography)({
  marginTop: 24,
  ...theme.typography['body-main/bold'],
  color: theme.palette.system['text-normal'],
});

interface MembershipReportProps {
  memberCd?: string;
  memberNameKanji?: string;
  onModalClose: () => void;
  handleClickSend: () => void;
}

export const MembershipReport: FC<MembershipReportProps> = ({
  memberCd,
  memberNameKanji,
  onModalClose,
  handleClickSend,
}) => (
  <Box sx={styleContainer}>
    <Title>配当金支払証明書兼支払通知書</Title>
    <Subtitle>
      この会員の配当金支払証明書兼支払通知書を
      <br />
      ダウンロードしますか？
    </Subtitle>
    <ContentWrapper>
      <ContentRow>
        <ContentRowLabel>会員コード</ContentRowLabel>
        <ContentRowText>{memberCd || '123456789'}</ContentRowText>
      </ContentRow>
      <ContentRow>
        <ContentRowLabel>氏名</ContentRowLabel>
        <ContentRowText>{memberNameKanji || '田中 太郎'}</ContentRowText>
      </ContentRow>
    </ContentWrapper>
    <ContentDetails>
      ダウンロード期限は{new Date().getFullYear() + 1}/03/31 (月)
      23:59までとなります。
    </ContentDetails>
    <Box sx={{ display: 'flex', gap: '16px', marginTop: '24px' }}>
      <CancelButton
        sx={{
          width: '112px',
          height: '41px',
          whiteSpace: 'nowrap',
        }}
        onClick={() => onModalClose()}
      >
        キャンセル
      </CancelButton>
      <PrimaryButton
        sx={{
          height: '41px',
          whiteSpace: 'nowrap',
        }}
        onClick={() => handleClickSend()}
      >
        ダウンロードする
      </PrimaryButton>
    </Box>
  </Box>
);
