import { styled, TableHead, TableRow } from '@mui/material';

interface TableProps {
  children: React.ReactNode;
}

const MuiTableHead = styled(TableHead)(({ theme }) => {
  const { palette } = theme;
  return {
    background: palette.system['background-dark'],
    height: '42px',
    padding: '0px',
    borderColor: theme.palette.system.separator,
    '& .MuiTableHead-root': {
      background: palette.system['background-dark'],
    },
  };
});
const MuiRow = styled(TableRow)({
  padding: '0 8px',
  borderWidth: '0px',
});

export const TableHeadRow = ({ children }: TableProps) => (
  <MuiTableHead>
    <MuiRow>{children}</MuiRow>
  </MuiTableHead>
);
