import { createTheme } from '@mui/material';

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused:not(.Mui-error)': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid',
              /**
               * system.text-light
               */
              borderColor: `#666666`,
            },
          },
          '&.Mui-focused.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid',
              /**
               * states.error
               */
              borderColor: `#D20B0B`,
            },
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.error
            ? {
                height: '66px',
              }
            : {
                height: '40px',
              }),
          input: {
            padding: '8px',
            paddingLeft: '0px',
            border: 'none',
            /**
             * body-main/medium
             */
            fontWeight: 500,
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '150%',
            ...(ownerState.error
              ? {
                  /**
                   * states.error
                   */
                  color: '#D20B0B',
                  '&::placeholder': {
                    /**
                     * states.error
                     */
                    color: '#D20B0B',
                  },
                }
              : {
                  /**
                   * system.text-normal
                   */
                  color: '#222222',
                  '&::placeholder': {
                    /**
                     * system.placeholder
                     */
                    color: '#AAAAAA',
                  },
                }),
          },
          '& .MuiOutlinedInput-root': {
            ...(ownerState.error
              ? {
                  /**
                   * states.error-bg
                   */
                  backgroundColor: '#FFF2F2',
                }
              : {
                  /**
                   * system.white
                   */
                  backgroundColor: '#FFFFFF',
                }),
            '& > fieldset': {
              border: '1px solid',
              /**
               * system.placeholder
               */
              borderColor: '#AAAAAA',
              borderRadius: '4px',
            },
          },
          '& .MuiIconButton-sizeMedium': {
            padding: 0,
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: 0,
          '&.Mui-error': {
            marginTop: '8px',
            height: '18px',
            /**
             * caption/regular
             */
            fontWeight: 400,
            fontStyle: 'normal',
            fontSize: '12px',
            lineHeight: '150%',
            /**
             * states.error
             */
            color: '#D20B0B',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            /**
             * system.background
             */
            backgroundColor: '#F7F6F6',
            '&:hover': {
              backgroundColor: '#F7F6F6',
            },
          },
          '&.Mui-selected.Mui-focusVisible': {
            backgroundColor: '#F7F6F6',
          },
          '&:hover': {
            backgroundColor: '#F7F6F6',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: ['Roboto', 'Noto Sans JP', 'Meiryo', 'sans-serif'].join(','),
    button: {
      textTransform: 'none',
    },
    h2: {
      fontWeight: 500,
      fontSize: '36px',
      lineHeight: '100%',
    },
    h3: {
      fontWeight: 500,
      fontSize: '30px',
      lineHeight: '100%',
    },
    h4: {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '100%',
    },
    h5: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '100%',
    },
    h6: {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '100%',
    },
    'body-main/regular': {
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: '150%',
    },
    'body-main/medium': {
      fontWeight: 500,
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: '150%',
    },
    'body-main/bold': {
      fontWeight: 700,
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: '150%',
    },
    'body-sub/regular': {
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '150%',
    },
    'body-sub/medium': {
      fontWeight: 500,
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '150%',
    },
    'body-sub/bold': {
      fontWeight: 700,
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '150%',
    },
    'caption/regular': {
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '12px',
      lineHeight: '150%',
    },
    'caption/medium': {
      fontWeight: 500,
      fontStyle: 'normal',
      fontSize: '12px',
      lineHeight: '150%',
    },
    'caption/bold': {
      fontWeight: 700,
      fontStyle: 'normal',
      fontSize: '12px',
      lineHeight: '150%',
    },
  },
  palette: {
    primary: {
      main: '#ED5045',
      normal: '#ED5045',
      light: '#FFF1EB',
      gradation: 'linear-gradient(90deg, #F7473B -1.45%, #FC9621 100%)',
    },
    secondary: {
      main: '#169FA6',
      text: '#0B5D61',
      normal: '#169FA6',
      light: '#CCE9EB',
      bg: '#EFFBFC',
      button: '#E0F4F5',
    },
    states: {
      main: '#EE3E3E',
      plus: '#EE3E3E',
      error: '#D20B0B',
      'error-bg': '#FFF2F2',
      deadline: '#EE931D',
      success: '#049512',
      minus: '#049512',
      done: '#0451A0',
    },
    system: {
      main: '#FFFFFF',
      white: '#FFFFFF',
      'text-normal': '#222222',
      table: '#555555',
      'text-light': '#666666',
      placeholder: '#AAAAAA',
      inactive: '#CCCCCC',
      separator: '#00000033',
      'separator-light': '#00000021',
      'background-dark': '#F2F1F1',
      background: '#F7F6F6',
      'background-light': '#FCFBFB',
      link: '#4290D9',
      button: '#E0F4F5',
    },
  },
});
