/* eslint-disable @typescript-eslint/no-unsafe-call */
import { FC, useEffect, useState } from 'react';
import { Box, Typography, SvgIcon, styled } from '@mui/material';
import { theme } from '@/theme/theme';
import { addMonths, format } from 'date-fns';
import { ReactComponent as Error } from '@/assets/errorOops.svg';
import { HomeTable } from '@/components/organisms/Home/HomeTable/HomeTable';
// @ts-ignore
import {
  ListEvents,
  ListEventItem,
  ListEventsApiParams,
} from '@/types/api/events';
import { appClient } from '@/services';

const TitleWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const Title = styled(Typography)({
  ...theme.typography.h5,
  color: theme.palette.system['text-normal'],
});

const DeadlineWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 16px',
  backgroundColor: theme.palette.states['error-bg'],
  borderRadius: 4,
  marginLeft: 16,
});

const SvgIconWrapper = styled(Box)({
  width: 20,
  height: 20,
  marginRight: 6,
});

const DeadlineText = styled(Typography)({
  ...theme.typography['body-main/medium'],
  color: theme.palette.states.error,
});

const TableWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginTop: 16,
});

const listEventsInitParams = {
  associationCd: '',
  eventTargetDateType: 'YEAR_MONTH',
  dateFrom: format(new Date(), 'yyyy-MM-dd'),
  dateTo: format(addMonths(new Date(), 2), 'yyyy-MM-dd'),
};

interface HomeEventSectionProps {
  associationCd: string;
}

export const HomeEventSection: FC<HomeEventSectionProps> = ({
  associationCd,
}) => {
  const [queryParams, setQueryParams] = useState<ListEventsApiParams>({
    // @ts-ignore
    associationCd,
    ...listEventsInitParams,
  } as ListEventsApiParams);
  const isLoading = false;
  const eventListError = false;
  // const { eventListData, isLoading, eventListError } = useEventList(
  //   associationCd,
  //   queryParams
  // );

  const [eventListData, setEventListData] = useState<ListEvents>();
  const getEventListData = async () => {
    try {
      const res = await appClient.events.listEvents(
        associationCd,
        undefined,
        'PURCHASE_DATE',
        format(new Date(), 'yyyy-MM-dd'),
        format(addMonths(new Date(), 2), 'yyyy-MM-dd')
      );
      // @ts-ignore
      setEventListData(res);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    void getEventListData();
  }, []);

  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    // @ts-ignore
    if (eventListData && eventListData.events) {
      // @ts-ignore
      const { events } = eventListData;

      setIsExpired(
        events.some(
          // @ts-ignore
          (item: ListEventItem) => new Date(item.nextPurchaseDate) < new Date()
        )
      );
    }
  }, [eventListData]);

  if (eventListError) {
    return <div>Error</div>;
  }

  return (
    <>
      <TitleWrapper>
        <Title>予定イベント</Title>
        {isExpired && (
          <DeadlineWrapper>
            <SvgIconWrapper>
              <SvgIcon sx={{ width: 20, height: 20 }}>
                <Error />
              </SvgIcon>
            </SvgIconWrapper>
            <DeadlineText>
              買付日登録期日を超過している持株会です！
            </DeadlineText>
          </DeadlineWrapper>
        )}
      </TitleWrapper>
      <TableWrapper>
        <Typography
          variant="body-sub/regular"
          color={theme.palette.system['text-light']}
        >
          ※直近2ヶ月の予定です
        </Typography>
        {isLoading ? (
          <HomeTable data={[]} />
        ) : (
          <HomeTable
            data={
              // @ts-ignore
              eventListData && eventListData.events ? eventListData.events : []
            }
          />
        )}
      </TableWrapper>
    </>
  );
};
