import { FC, useEffect, useState, ChangeEvent } from 'react';
import {
  Box,
  Stack,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  styled,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';
import { theme } from '@/theme';
import {
  useForm,
  Controller,
  SubmitHandler,
  useController,
} from 'react-hook-form';
import { AdmissionEditInputs as Inputs } from '@/types/components/Inputs';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { SelectBox } from '@/components/atoms/Input/SelectBox';
import {
  months,
  prefectures,
  years,
} from '@/constants/SelectBox/choicesForSelectBox';
import { getDayList } from '@/utils/dateFunction';
import { TextInput } from '@/components/atoms/Input/TextInput';
import { SearchAddressButton } from '@/components/atoms/Buttons/SearchAddressButton';
import { FindApplicationByID } from '@/types/api/Applications/application';
import { AssociationsContribution } from '@/types/api/Association/associationsContribution';
import { PrevnextApplications } from '@/types/api/Applications/prevnextApplications';
import { format } from 'date-fns';
import { useGetAddressApi } from '@/hooks/useGetAddressApi';
import { numberFormat } from '@/utils/numberFormat';
import { IsNumeric, IsStarZenkaku } from '@/utils/stringProcessor';
import { IsAlphabetNumer, validateKatagana } from '@/utils/validation';
import { TitleHead } from '../Applications/SharedParts/TitleHead';
import { ApplicationBottom } from '../Applications/SharedParts/ApplicationBottom';
import { ApplicationTable as Table } from '../Applications/SharedParts/ApplicationTable';
import { ApplicationTableRow as Row } from '../Applications/SharedParts/ApplicationTableRow';

interface AdmissionProps {
  application: FindApplicationByID;
  bottomData: PrevnextApplications | undefined;
  associationsContribution: AssociationsContribution;
  onSubmit: SubmitHandler<Inputs>;
}

const MuiRadioGroup = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'row',
});

const RGroup: FC<RadioGroupProps> = ({ name = '', children, ...props }) => {
  const {
    field: { ref, ...rest },
  } = useController({ name });

  return (
    <RadioGroup sx={{ gap: 2 }} ref={ref} {...rest} {...props}>
      {children}
    </RadioGroup>
  );
};

const MuiBox = styled(Box)({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
});

const ValidateText = styled(Typography)({
  ...theme.typography['caption/regular'],
  color: theme.palette.states.error,
});

export const ApplicationsAdmissionDetailEdit: FC<AdmissionProps> = ({
  application,
  associationsContribution,
  bottomData,
  onSubmit,
}) => {
  const isBonusInitial = application.applicationNewUser?.isBonusUnit ?? false;
  const birthdayList =
    Object.keys(application.applicationNewUser?.birthday ?? []).length &&
    application.applicationNewUser?.birthday !== ''
      ? format(
          new Date(application.applicationNewUser?.birthday ?? 0),
          'yyyy-MM-dd'
        ).split('-')
      : ['', '', ''];
  const { control, handleSubmit, getValues, setValue, watch } = useForm<Inputs>(
    {
      defaultValues: {
        name: application.applicationNewUser?.nameKanji ?? '',
        nameKana: application.applicationNewUser?.nameKana ?? '',
        zipcode: application.applicationNewUser?.zipcode ?? '',
        address1: application.applicationNewUser?.address1 ?? '',
        address2: application.applicationNewUser?.address2 ?? '',
        address3: application.applicationNewUser?.address3 ?? '',
        employeeCd: application.applicationNewUser?.employeeCode ?? '',
        monthlyUnit: application.applicationNewUser?.monthlyUnit ?? 0,
        bonusUnit: application.applicationNewUser?.bonusUnit ?? 0,
        isBonus: isBonusInitial,
        bonusRate: application.applicationNewUser?.bonusRate ?? 0,
        birthday: application.applicationNewUser?.birthday ?? '',
        memberCd: application.applicationNewUser?.memberCd ?? '',
        officeCd: '',
        affiliationCd: '',
        tel: application.applicationNewUser?.tel ?? '',
        telType: application.applicationNewUser?.telType ?? 'MOBILE',
        birthdayYear: birthdayList[0],
        birthdayMonth: birthdayList[1],
        birthdayDay: birthdayList[2],
      },
    }
  );

  const [dayList, setDayList] = useState([{ key: '', value: '' }]);
  const { addressData, getAddress } = useGetAddressApi();
  const [monthlyUnit, setMonthlyUnit] = useState<string>();
  const [bonusUnit, setBonusUnit] = useState<string>();
  const birthdayDay = watch('birthdayDay');
  const [isNameKanjiError, setIsNameKanjiError] = useState<boolean>(false);
  const [isKataganaError, setIsKataganaError] = useState<boolean>(false);
  const [isAddress2Error, setIsAddress2Error] = useState<boolean>(false);
  const [isAddress3Error, setIsAddress3Error] = useState<boolean>(false);

  useEffect(() => {
    const birthdayDayHolder = getValues().birthdayDay;
    setValue('birthdayDay', '');
    setDayList(
      getDayList(Number(watch('birthdayYear')), Number(watch('birthdayMonth')))
    );
    setValue('birthdayDay', birthdayDayHolder);
  }, [watch('birthdayYear'), watch('birthdayMonth')]);

  useEffect(() => {
    setValue('birthdayDay', birthdayDay);
  }, [watch('birthdayYear')]);

  useEffect(() => {
    if (!addressData || addressData.length === 0) return;
    setValue('address1', addressData[0].pref);
    setValue('address2', `${addressData[0].city}${addressData[0].town}`);
  }, [addressData]);

  const monthlyUnitChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let amount = monthlyUnit;
    if (IsNumeric(e.target.value)) {
      amount = Math.floor(
        Math.min(
          Number(e.target.value),
          associationsContribution?.maxMonthlyUnit ?? 0
        )
      ).toString();
    } else if (e.target.value === '') {
      amount = undefined;
    }
    setMonthlyUnit(amount || '');
    setValue('monthlyUnit', Number(amount));
    if (
      associationsContribution.bonusMultiplierDesignationType !== 'UNIT_NUMBER'
    ) {
      setValue('bonusUnit', Number(amount) * watch('bonusRate'));
    }
  };

  const bonusUnitChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let amount = bonusUnit;
    if (IsNumeric(e.target.value)) {
      amount = Math.floor(
        Math.min(
          Number(e.target.value),
          associationsContribution?.maxBonusUnit ?? 0
        )
      ).toString();
    } else if (e.target.value === '') {
      amount = undefined;
    }
    setBonusUnit(amount || '');
    setValue('bonusUnit', Number(amount));
  };

  useEffect(() => {
    if (application) {
      setMonthlyUnit(
        (application.applicationNewUser?.monthlyUnit ?? 0).toString()
      );
      setBonusUnit((application.applicationNewUser?.bonusUnit ?? 0).toString());
    }
  }, [application]);

  const isDisabled = () =>
    !(
      (watch('name') &&
        watch('nameKana') &&
        watch('tel') &&
        watch('zipcode') &&
        watch('birthdayDay') &&
        watch('birthdayMonth') &&
        watch('birthdayYear') &&
        watch('address1') &&
        watch('address2') &&
        watch('address3') &&
        watch('employeeCd') &&
        IsStarZenkaku(watch('name').replace(/\s+/g, '')) &&
        watch('name').length <= 20 &&
        validateKatagana(watch('nameKana').replace(/\s+/g, '')) &&
        watch('nameKana').length <= 20 &&
        IsStarZenkaku(watch('address2').replace(/\s+/g, '')) &&
        IsStarZenkaku(watch('address3').replace(/\s+/g, ''))) ||
      (watch('monthlyUnit') !== application.applicationNewUser?.monthlyUnit &&
        associationsContribution.bonusMultiplierDesignationType !==
          'UNIT_NUMBER' &&
        ((watch('isBonus') !== isBonusInitial &&
          watch('isBonus') !==
            (application.applicationNewUser?.isBonusUnit as boolean)) ||
          watch('bonusUnit') !== application.applicationNewUser?.bonusUnit))
    );

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.system['background-dark'],
        height: '100%',
      }}
    >
      <Stack
        spacing={4}
        sx={{
          backgroundColor: theme.palette.system.white,
          padding: '40px 160px',
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <TitleHead
          title="新規会員登録"
          status={application.applicationStatus ?? ''}
          applicationDate={application.applicationDate ?? ''}
          applicationId={application.applicationId ?? ''}
          associationCd={application.associationCd ?? ''}
          proxyApplicantUserName={application.proxyApplicantUserName ?? ''}
          unitType="admission"
        />
        <Table title="会員情報" isRed>
          <Row
            labels="会員コード"
            values={application.applicationNewUser?.memberCd}
          />
          <Row labels="氏名（漢字)" isEven>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <MuiBox>
                  <TextField
                    {...field}
                    sx={{ width: '240px', height: '40px' }}
                    placeholder="例：田中　太郎"
                    inputProps={{ style: { paddingLeft: '8px' } }}
                    error={!watch('name') || isNameKanjiError}
                    onChange={(e) => {
                      setIsNameKanjiError(false);
                      if (e.target.value.length >= 1) {
                        setIsNameKanjiError(
                          !IsStarZenkaku(e.target.value.replace(/\s+/g, ''))
                        );
                      }
                      if (e.target.value.length > 20) {
                        setIsNameKanjiError(true);
                      }
                      setValue('name', e.target.value);
                    }}
                  />
                  {!watch('name') && (
                    <ValidateText>入力してください</ValidateText>
                  )}
                  {isNameKanjiError && watch('name').length <= 20 && (
                    <ValidateText>
                      入力できない文字が含まれています
                    </ValidateText>
                  )}
                  {isNameKanjiError && watch('name').length > 20 && (
                    <ValidateText>20文字以内で入力してください</ValidateText>
                  )}
                </MuiBox>
              )}
            />
          </Row>
          <Row labels="氏名（カナ)">
            <Controller
              name="nameKana"
              control={control}
              render={({ field }) => (
                <Stack>
                  <MuiBox>
                    <TextField
                      {...field}
                      sx={{ width: '240px', height: '40px' }}
                      inputProps={{ style: { paddingLeft: '8px' } }}
                      placeholder="例：タナカ　タロウ"
                      error={!watch('nameKana') || isKataganaError}
                      onChange={(e) => {
                        setIsKataganaError(false);
                        if (e.target.value.length >= 1) {
                          setIsKataganaError(
                            !validateKatagana(
                              e.target.value.replace(/\s+/g, '')
                            )
                          );
                        }
                        if (e.target.value.length > 20) {
                          setIsKataganaError(true);
                        }
                        setValue('nameKana', e.target.value);
                      }}
                    />
                    {!watch('nameKana') && (
                      <ValidateText>入力してください</ValidateText>
                    )}
                    {isKataganaError && watch('nameKana').length <= 20 && (
                      <ValidateText>カタカナで入力してください</ValidateText>
                    )}
                    {isKataganaError && watch('nameKana').length > 20 && (
                      <ValidateText>20文字以内で入力してください</ValidateText>
                    )}
                  </MuiBox>
                  <Typography
                    variant="caption/regular"
                    color={theme.palette.system['text-light']}
                  >
                    ※カタカナ
                  </Typography>
                </Stack>
              )}
            />
          </Row>
          <Row labels="生年月日" isEven>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '8px',
                width: '100%',
              }}
            >
              <Controller
                name="birthdayYear"
                control={control}
                render={({ field }) => (
                  <MuiBox>
                    <SelectBox
                      {...field}
                      items={years}
                      error={!watch('birthdayYear')}
                      sx={{
                        minWidth: '80px',
                        backgroundColor: !watch('birthdayYear')
                          ? '#FFF2F2'
                          : theme.palette.system.white,
                      }}
                    />
                    <Typography variant="body-sub/regular">年</Typography>
                  </MuiBox>
                )}
              />

              <Controller
                name="birthdayMonth"
                control={control}
                render={({ field }) => (
                  <MuiBox>
                    <SelectBox
                      {...field}
                      items={months}
                      error={!watch('birthdayMonth')}
                      sx={{
                        minWidth: '80px',
                        backgroundColor: !watch('birthdayMonth')
                          ? '#FFF2F2'
                          : theme.palette.system.white,
                      }}
                    />
                    <Typography variant="body-sub/regular">月</Typography>
                  </MuiBox>
                )}
              />

              <Controller
                name="birthdayDay"
                control={control}
                render={({ field }) => (
                  <MuiBox>
                    <SelectBox
                      {...field}
                      items={dayList}
                      error={!watch('birthdayDay')}
                      sx={{
                        minWidth: '80px',
                        backgroundColor: !watch('birthdayDay')
                          ? '#FFF2F2'
                          : theme.palette.system.white,
                      }}
                    />
                    <Typography variant="body-sub/regular">日</Typography>
                  </MuiBox>
                )}
              />
              {(watch('birthdayYear') === '' ||
                watch('birthdayMonth') === '' ||
                watch('birthdayDay') === '') && (
                <ValidateText>選択してください</ValidateText>
              )}
            </Box>
          </Row>
          <Row labels="住所">
            <Stack spacing={2}>
              <Stack>
                <Typography variant="body-sub/regular">郵便番号</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Controller
                    name="zipcode"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        sx={{ width: '160px', height: '40px' }}
                        placeholder="例：1036129"
                        inputProps={{ style: { paddingLeft: '8px' } }}
                        error={!watch('zipcode')}
                        onChange={(e) => {
                          if (
                            (IsNumeric(e.target.value) &&
                              e.target.value.length <= 7) ||
                            e.target.value === ''
                          ) {
                            setValue(
                              'zipcode',
                              e.target.value.replaceAll(' ', '')
                            );
                          }
                        }}
                      />
                    )}
                  />
                  <SearchAddressButton
                    onClick={() => {
                      const zip = getValues().zipcode;
                      if (zip) getAddress(zip);
                    }}
                  >
                    住所を自動入力
                  </SearchAddressButton>
                  {watch('zipcode') === '' && (
                    <ValidateText>入力してください</ValidateText>
                  )}
                </Stack>
                <Typography
                  variant="caption/regular"
                  color={theme.palette.system['text-light']}
                >
                  ※ハイフン無し、半角7桁
                </Typography>
              </Stack>
              <Stack>
                <Typography variant="body-sub/regular">都道府県</Typography>
                <Controller
                  name="address1"
                  control={control}
                  render={({ field }) => (
                    <MuiBox>
                      <SelectBox
                        {...field}
                        items={prefectures}
                        error={!watch('address1')}
                        sx={{
                          minWidth: '160px',
                          backgroundColor: !watch('address1')
                            ? '#FFF2F2'
                            : theme.palette.system.white,
                        }}
                      />
                      {watch('address1') === '' && (
                        <ValidateText>選択してください</ValidateText>
                      )}
                    </MuiBox>
                  )}
                />
              </Stack>
              <Stack>
                <Typography variant="body-sub/regular">市区町村</Typography>
                <Controller
                  name="address2"
                  control={control}
                  render={({ field }) => (
                    <Stack>
                      <TextField
                        {...field}
                        sx={{ width: '100%', height: '40px' }}
                        error={!watch('address2') || isAddress2Error}
                        placeholder="例：中央区"
                        inputProps={{ style: { paddingLeft: '8px' } }}
                        onChange={(e) => {
                          setIsAddress2Error(false);
                          if (e.target.value.length >= 1) {
                            setIsAddress2Error(
                              !IsStarZenkaku(e.target.value.replace(/\s+/g, ''))
                            );
                          }
                          if (e.target.value.length <= 50) {
                            setValue('address2', e.target.value);
                          }
                        }}
                      />
                      {watch('address2') === '' && (
                        <ValidateText>入力してください</ValidateText>
                      )}
                      {isAddress2Error && (
                        <ValidateText>
                          入力できない文字が含まれています
                        </ValidateText>
                      )}
                    </Stack>
                  )}
                />
              </Stack>
              <Stack>
                <Typography variant="body-sub/regular">
                  番地・マンションなど
                </Typography>
                <Controller
                  name="address3"
                  control={control}
                  render={({ field }) => (
                    <Stack>
                      <TextField
                        {...field}
                        sx={{ width: '100%', height: '40px' }}
                        placeholder="例：日本橋2丁目 5-1"
                        error={!watch('address3') || isAddress3Error}
                        inputProps={{ style: { paddingLeft: '8px' } }}
                        onChange={(e) => {
                          setIsAddress3Error(false);
                          if (e.target.value.length >= 1) {
                            setIsAddress3Error(
                              !IsStarZenkaku(e.target.value.replace(/\s+/g, ''))
                            );
                          }
                          if (e.target.value.length <= 50) {
                            setValue('address3', e.target.value);
                          }
                        }}
                      />
                      {watch('address3') === '' && (
                        <ValidateText>入力してください</ValidateText>
                      )}
                      {isAddress3Error && (
                        <ValidateText>
                          入力できない文字が含まれています
                        </ValidateText>
                      )}
                    </Stack>
                  )}
                />
              </Stack>
            </Stack>
          </Row>
          <Row labels="電話番号" isEven>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                gap: '8px',
                width: '100%',
              }}
            >
              <FormControl>
                <MuiRadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={
                    application.applicationNewUser?.telType || 'MOBILE'
                  }
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="MOBILE"
                    control={<Radio />}
                    onClick={() => setValue('telType', 'MOBILE')}
                    label={
                      <Typography
                        variant="body-main/regular"
                        sx={{ color: 'system.text-normal' }}
                      >
                        携帯
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="HOME"
                    control={<Radio />}
                    onClick={() => setValue('telType', 'HOME')}
                    label={
                      <Typography
                        variant="body-main/regular"
                        sx={{ color: 'system.text-normal' }}
                      >
                        自宅
                      </Typography>
                    }
                  />
                </MuiRadioGroup>
              </FormControl>
            </Box>
            <Controller
              name="tel"
              control={control}
              render={({ field }) => (
                <MuiBox>
                  <TextField
                    {...field}
                    inputProps={{ style: { paddingLeft: '8px' } }}
                    sx={{ height: '40px' }}
                    placeholder="例：12345678901"
                    error={!watch('tel')}
                    onChange={(e) => {
                      if (
                        (e.target.value.length <= 15 &&
                          IsNumeric(e.target.value)) ||
                        e.target.value === ''
                      ) {
                        setValue('tel', e.target.value.replaceAll(' ', ''));
                      }
                    }}
                  />
                  {watch('tel') === '' && (
                    <ValidateText>入力してください</ValidateText>
                  )}
                </MuiBox>
              )}
            />
          </Row>
          <Row labels="社員コード">
            <Controller
              name="employeeCd"
              control={control}
              render={({ field }) => (
                <MuiBox>
                  <TextField
                    {...field}
                    sx={{ width: '160px', height: '40px' }}
                    placeholder="例：1234"
                    error={!watch('employeeCd')}
                    inputProps={{ style: { paddingLeft: '8px' } }}
                    onChange={(e) => {
                      if (
                        (e.target.value.length <= 10 &&
                          IsAlphabetNumer(e.target.value)) ||
                        e.target.value === ''
                      ) {
                        setValue(
                          'employeeCd',
                          e.target.value.replaceAll(' ', '')
                        );
                      }
                    }}
                  />
                  {watch('employeeCd') === '' && (
                    <ValidateText>入力してください</ValidateText>
                  )}
                </MuiBox>
              )}
            />
          </Row>
          <Row labels="会員事業所コード" isEven isOptional>
            <Controller
              name="officeCd"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ width: '160px' }}
                  inputProps={{ style: { paddingLeft: '8px' } }}
                  onChange={(e) => {
                    if (
                      (e.target.value.length <= 10 &&
                        IsAlphabetNumer(e.target.value)) ||
                      e.target.value === ''
                    ) {
                      setValue('officeCd', e.target.value.replaceAll(' ', ''));
                    }
                  }}
                />
              )}
            />
          </Row>

          <Row labels="会員所属コード" isOptional>
            <Controller
              name="affiliationCd"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ width: '160px' }}
                  inputProps={{ style: { paddingLeft: '8px' } }}
                  onChange={(e) => {
                    if (
                      (e.target.value.length <= 10 &&
                        IsAlphabetNumer(e.target.value)) ||
                      e.target.value === ''
                    ) {
                      setValue(
                        'affiliationCd',
                        e.target.value.replaceAll(' ', '')
                      );
                    }
                  }}
                />
              )}
            />
          </Row>
        </Table>
        <Table title="Web利用規約同意確認" isRed>
          <Row values="Web利用規約に同意する" />
        </Table>
        <Table title="新しい口数設定" isRed>
          <Row
            labels="一口当たり金額"
            values={`${associationsContribution.unitAmount.toLocaleString()}円`}
          />
          <Row
            labels="奨励金率"
            values={`${associationsContribution.monthlyIncentiveRatio}%`}
            isEven
          />
          <Row labels="給与(月例)">
            <Stack spacing={2}>
              <Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography minWidth="65px">口数</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextInput
                      name="monthlyUnit"
                      value={monthlyUnit}
                      error={!IsNumeric(String(watch('monthlyUnit')))}
                      sx={{ height: '40px' }}
                      onChange={(e) => monthlyUnitChange(e)}
                    />
                    <Typography
                      sx={{ marginLeft: '8px', marginRight: '30px' }}
                      variant="body-sub/regular"
                    >
                      口
                    </Typography>
                  </Box>
                  <Typography
                    variant="body-sub/regular"
                    sx={{ color: 'system.text-light' }}
                  >
                    ※最大
                    {numberFormat(associationsContribution.maxMonthlyUnit)}口
                  </Typography>
                </Stack>
                {!IsNumeric(String(watch('monthlyUnit'))) && (
                  <ValidateText>入力してください</ValidateText>
                )}
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography>拠出金額</Typography>
                <Typography fontWeight={500} fontSize="20px">
                  {(
                    associationsContribution.unitAmount * Number(monthlyUnit)
                  ).toLocaleString()}
                  円
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography minWidth="65px">奨励金額</Typography>
                <Typography fontWeight={500} fontSize="20px">
                  {(
                    (associationsContribution.unitAmount *
                      Number(monthlyUnit) *
                      associationsContribution.monthlyIncentiveRatio) /
                    100
                  ).toLocaleString()}
                  円
                </Typography>
              </Stack>
            </Stack>
          </Row>
          {associationsContribution.bonusReserveClassification !== 'NONE' && (
            <Row labels="賞与" isEven>
              <Stack spacing={2}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography minWidth="65px">拠出</Typography>
                  <FormControl>
                    <MuiRadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="yes"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        checked={watch('isBonus') === true}
                        control={<Radio />}
                        label="する"
                        onClick={() => {
                          if (
                            associationsContribution.bonusMultiplierDesignationType ===
                            'MAGNIFICATION'
                          ) {
                            const options = (
                              associationsContribution.bonusMultiplierDetails ||
                              ''
                            ).split(',');
                            setValue('bonusRate', Number(options[0]));
                          }
                          setValue('isBonus', true);
                        }}
                      />
                      <FormControlLabel
                        checked={watch('isBonus') === false}
                        control={<Radio />}
                        label="しない"
                        onClick={() => setValue('isBonus', false)}
                      />
                    </MuiRadioGroup>
                  </FormControl>
                </Stack>
                {associationsContribution?.bonusMultiplierDesignationType ===
                  'FIXED_MAGNIFICATION' &&
                  watch('isBonus') && (
                    <>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            gap: '16px',
                          }}
                        >
                          <Typography sx={{ minWidth: '65px' }}>
                            倍率
                          </Typography>
                          <Typography
                            sx={{ marginRight: '16px' }}
                            variant="body-main/bold"
                          >
                            {
                              (
                                associationsContribution?.bonusMultiplierDetails ||
                                ''
                              ).split(',')[0]
                            }
                            倍
                          </Typography>

                          <Typography
                            variant="body-sub/regular"
                            sx={{ color: 'system.text-light' }}
                          >
                            ※給与（毎月の拠出）の
                            {
                              (
                                associationsContribution?.bonusMultiplierDetails ||
                                ''
                              ).split(',')[0]
                            }
                            倍となります
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Typography sx={{ minWidth: '65px' }}>
                          拠出金額
                        </Typography>
                        <Typography fontWeight={500} fontSize="20px">
                          {(
                            associationsContribution.unitAmount *
                            Number(monthlyUnit) *
                            Number(
                              associationsContribution?.bonusMultiplierDetails ||
                                1
                            )
                          ).toLocaleString()}
                          円
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Typography minWidth="65px">奨励金額</Typography>
                        <Typography fontWeight={500} fontSize="20px">
                          {(
                            (associationsContribution.unitAmount *
                              Number(monthlyUnit) *
                              Number(
                                associationsContribution?.bonusMultiplierDetails ||
                                  1
                              ) *
                              associationsContribution.monthlyIncentiveRatio) /
                            100
                          ).toLocaleString()}
                          円
                        </Typography>
                      </Stack>
                    </>
                  )}
                {associationsContribution?.bonusMultiplierDesignationType ===
                  'UNIT_NUMBER' &&
                  watch('isBonus') && (
                    <>
                      <Stack>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Typography minWidth="65px">口数</Typography>

                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Stack>
                              <TextInput
                                name="bonusUnit"
                                value={bonusUnit}
                                onChange={(e) => bonusUnitChange(e)}
                                error={!IsNumeric(String(watch('bonusUnit')))}
                                sx={{ height: '40px' }}
                              />
                            </Stack>
                            <Typography
                              sx={{ marginLeft: '8px', marginRight: '30px' }}
                              variant="body-sub/regular"
                            >
                              口
                            </Typography>
                          </Box>
                          <Typography
                            variant="body-sub/regular"
                            sx={{ color: 'system.text-light' }}
                          >
                            ※最大
                            {numberFormat(
                              associationsContribution.maxBonusUnit
                            )}
                            口
                          </Typography>
                        </Stack>
                        {!IsNumeric(String(watch('bonusUnit'))) && (
                          <ValidateText>入力してください</ValidateText>
                        )}
                      </Stack>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Typography>拠出金額</Typography>
                        <Typography fontWeight={500} fontSize="20px">
                          {(
                            Number(bonusUnit) *
                            associationsContribution.unitAmount
                          ).toLocaleString()}
                          円
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Typography minWidth="65px">奨励金額</Typography>
                        <Typography fontWeight={500} fontSize="20px">
                          {(
                            (Number(bonusUnit) *
                              associationsContribution.unitAmount *
                              associationsContribution.monthlyIncentiveRatio) /
                            100
                          ).toLocaleString()}
                          円
                        </Typography>
                      </Stack>
                    </>
                  )}
                {associationsContribution.bonusMultiplierDesignationType ===
                  'MAGNIFICATION' &&
                  watch('isBonus') && (
                    <>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Typography minWidth="65px">口数</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <MuiRadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="yes"
                            name="radio-buttons-group"
                          >
                            {(
                              associationsContribution.bonusMultiplierDetails ||
                              ''
                            )
                              .split(',')
                              .map((i) => (
                                <FormControlLabel
                                  value={i}
                                  control={<Radio />}
                                  checked={Number(i) === watch('bonusRate')}
                                  label={`${i}倍`}
                                  onClick={() => {
                                    setValue('bonusRate', Number(i));
                                    setValue(
                                      'bonusUnit',
                                      (application.applicationNewUser
                                        ?.monthlyUnit || 0) * Number(i)
                                    );
                                  }}
                                />
                              ))}
                          </MuiRadioGroup>
                        </Box>
                      </Stack>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Typography>拠出金額</Typography>
                        <Typography fontWeight={500} fontSize="20px">
                          {(
                            associationsContribution.unitAmount *
                            Number(monthlyUnit) *
                            Number(watch('bonusRate') || 1)
                          ).toLocaleString()}
                          円
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Typography minWidth="65px">奨励金額</Typography>
                        <Typography fontWeight={500} fontSize="20px">
                          {(
                            (associationsContribution.unitAmount *
                              Number(monthlyUnit) *
                              Number(watch('bonusRate') || 1) *
                              associationsContribution.monthlyIncentiveRatio) /
                            100
                          ).toLocaleString()}
                          円
                        </Typography>
                      </Stack>
                    </>
                  )}
              </Stack>
            </Row>
          )}
        </Table>
        <PrimaryButton
          sx={{ width: '96px' }}
          type="submit"
          disabled={isDisabled()}
        >
          修正完了
        </PrimaryButton>
      </Stack>
      {bottomData && (
        <Box sx={{ padding: '32px 160px' }}>
          <ApplicationBottom data={bottomData} unitType="admission" />
        </Box>
      )}
    </Box>
  );
};
