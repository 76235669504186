import { Home } from '@/components/pages/Home';
import { Layout } from '@/components/templates/Layout';
import { LoginLayout } from '@/components/templates/LoginLayout';
import {
  Admin,
  Association as LoginAssociation,
  AssociationComplete,
  Login,
  Mfa,
  PasswordChange,
  PasswordChangeComplete,
  PasswordResetting,
  PasswordResettingMfa,
  PasswordSetting,
  PasswordSettingComplete,
} from '@/components/pages/Login';
import {
  Applications,
  Admission,
  AdmissionIndex,
  AdmissionDetail,
  Bonus,
  BonusIndex,
  Dividend,
  DividendIndex,
  Monthly,
  MonthlyIndex,
  Part,
  Recess,
  Resume,
  Temporary,
  TemporaryDetail,
  TemporaryIndex,
  TemporaryContact,
  Unit,
  User,
  UserDetail,
  UserIndex,
  Withdrawal,
} from '@/components/pages/Applications';
import {
  UnitEdit,
  PartEdit,
  RecessEdit,
  WithdrawalEdit,
  WithdrawalAccountEdit,
  AdmissionDetailEdit,
  UserDetailEdit,
} from '@/components/pages/ApplicationsEdits';
import {
  PurchaseDate,
  Schedules,
  SchedulesIndex,
} from '@/components/pages/Schedules';
import { Balance } from '@/components/pages/Balance';
import { Downloads } from '@/components/pages/Downloads/Downloads';
import {
  MemberBalance,
  MemberDownload,
  MemberInformation,
  MemberInformationEdit,
  MemberProxy,
  MemberProxyApplication,
  MemberProxyComplete,
  MemberProxyIndex,
  Members,
  MembersIndex,
} from '@/components/pages/Members';
import {
  Informations,
  InformationsIndex,
  InformationEdit,
} from '@/components/pages/Informations';
import { Information } from '@/components/pages/Informations/Information';
import {
  Association,
  AssociationBank,
  AssociationContact,
  AssociationDeadline,
  AssociationIndex,
} from '@/components/pages/Association';
import {
  OfficeUser,
  OfficeUsers,
  OfficeUserAdd,
  OfficeUserCreate,
  OfficeUsersIndex,
} from '@/components/pages/OfficeUsers';
import { Account, AccountEdit, AccountIndex } from '@/components/pages/Account';
import { ScheduleSelectDays } from '@/components/pages/Schedules/ScheduleSelectDays';
import { ScheduleSelectDay } from '@/components/pages/Schedules/ScheduleSelectDay';

import { AuthUserProvider } from '@/hooks/useAuth';
import { Navigate, RouteObject } from 'react-router-dom';

export const routes = {
  private: [
    /**
     * ダッシュボード
     */
    {
      path: '/',
      element: (
        <AuthUserProvider>
          <Layout />
        </AuthUserProvider>
      ),
      children: [
        /**
         * ダッシュボード
         */
        { index: true, element: <Home /> },
        {
          path: 'applications',
          element: <Applications />,
          children: [
            /**
             * 申請 > 月例
             */
            {
              path: 'monthly',
              element: <Monthly />,
              children: [
                {
                  index: true,
                  element: <MonthlyIndex />,
                },
                /**
                 * 口数変更
                 */
                {
                  path: ':applicationId/unit',
                  element: <Unit />,
                },
                // * 一部引出
                // */
                {
                  path: ':applicationId/part',
                  element: <Part />,
                },
                /**
                 * 休止
                 */
                {
                  path: ':applicationId/recess',
                  element: <Recess />,
                },
                /**
                 * 再開
                 */
                {
                  path: ':applicationId/resume',
                  element: <Resume />,
                },
                //   * 退会
                {
                  path: ':applicationId/withdrawal',
                  element: <Withdrawal />,
                },
                // 編集
                // 口数変更
                {
                  path: ':applicationId/unit/edit',
                  element: <UnitEdit />,
                },

                // * 一部引出
                // */
                {
                  path: ':applicationId/part/edit',
                  element: <PartEdit />,
                },
                /**
                 * 休止
                 */
                {
                  path: ':applicationId/recess/edit',
                  element: <RecessEdit />,
                },
                //   * 退会
                {
                  path: ':applicationId/withdrawal/edit',
                  element: <WithdrawalEdit />,
                },
                {
                  path: ':applicationId/withdrawal/account/edit',
                  element: <WithdrawalAccountEdit />,
                },
              ],
            },
            /**
             * 申請 > 賞与
             */
            {
              path: 'bonus',
              element: <Bonus />,
              children: [
                {
                  index: true,
                  element: <BonusIndex />,
                },
                /**
                 * 口数変更
                 */
                {
                  path: ':applicationId/unit',
                  element: <Unit />,
                },
                // * 一部引出
                // */
                {
                  path: ':applicationId/part',
                  element: <Part />,
                },
                /**
                 * 休止
                 */
                {
                  path: ':applicationId/recess',
                  element: <Recess />,
                },
                /**
                 * 再開
                 */
                {
                  path: ':applicationId/resume',
                  element: <Resume />,
                },
                //   * 退会
                {
                  path: ':applicationId/withdrawal',
                  element: <Withdrawal />,
                },
                // 編集
                // 口数変更
                {
                  path: ':applicationId/unit/edit',
                  element: <UnitEdit />,
                },

                // * 一部引出
                // */
                {
                  path: ':applicationId/part/edit',
                  element: <PartEdit />,
                },
                /**
                 * 休止
                 */
                {
                  path: ':applicationId/recess/edit',
                  element: <RecessEdit />,
                },
                //   * 退会
                {
                  path: ':applicationId/withdrawal/edit',
                  element: <WithdrawalEdit />,
                },
                {
                  path: ':applicationId/withdrawal/account/edit',
                  element: <WithdrawalAccountEdit />,
                },
              ],
            },
            /**
             * 申請 > 配当
             */
            {
              path: 'dividend',
              element: <Dividend />,
              children: [{ index: true, element: <DividendIndex /> }],
            },
            /**
             * 申請 > 臨時
             */
            {
              path: 'temporary',
              element: <Temporary />,
              children: [
                { index: true, element: <TemporaryIndex /> },
                /**
                 * 詳細
                 */
                { path: ':applicationId', element: <TemporaryDetail /> },
                // 買付連絡
                { path: 'contact', element: <TemporaryContact /> },
              ],
            },
            /**
             * 申請 > 新規会員登録
             */
            {
              path: 'admission',
              element: <Admission />,
              children: [
                { index: true, element: <AdmissionIndex /> },
                /**
                 * 詳細
                 */
                {
                  path: ':applicationId',
                  element: <AdmissionDetail />,
                },
                {
                  path: ':applicationId/edit',
                  element: <AdmissionDetailEdit />,
                },
              ],
            },
            /**
             * 申請 > 会員情報変更
             */
            {
              path: 'user',
              element: <User />,
              children: [
                { index: true, element: <UserIndex /> },
                /**
                 * 詳細
                 */
                {
                  path: ':applicationId',
                  element: <UserDetail />,
                },
                // 編集
                {
                  path: ':applicationId/edit',
                  element: <UserDetailEdit />,
                },
              ],
            },
          ],
        },
        /**
         * スケジュール
         */
        {
          path: 'schedules',
          element: <Schedules />,
          children: [
            { index: true, element: <SchedulesIndex /> },
            { path: 'purchase-date', element: <PurchaseDate /> },
            { path: 'create', element: <ScheduleSelectDays /> },
            {
              path: 'create/:eventNo/:eventType',
              element: <ScheduleSelectDay />,
            },
          ],
        },
        /**
         * 残高詳細
         */
        { path: 'balance', element: <Balance /> },
        /**
         * 帳票ダウンロード
         */
        { path: 'downloads', element: <Downloads /> },
        /**
         * 会員一覧
         */
        {
          path: 'members',
          element: <Members />,
          children: [
            { index: true, element: <MembersIndex /> },
            /**
             * 会員情報
             */
            {
              path: ':memberId/information',
              element: <MemberInformation />,
            },
            /**
             * 会員情報 > 会員情報変更
             */
            {
              path: ':memberId/information/edit',
              element: <MemberInformationEdit />,
            },
            /**
             * 残高明細
             */
            {
              path: ':memberId/balance',
              element: <MemberBalance />,
            },
            /**
             * 帳票ダウンロード
             */
            {
              path: ':memberId/download',
              element: <MemberDownload />,
            },
            /**
             * 代理申請
             */
            {
              path: ':memberId/proxy',
              element: <MemberProxy />,
              children: [
                { index: true, element: <MemberProxyIndex /> },
                /**
                 * pay: 口数変更（給与）
                 * bonus: 口数変更（賞与）
                 * part: 一部引出
                 * recess: 休止
                 * resume: 再開
                 * withdrawal: 退会
                 */
                {
                  path: ':applicationType',
                  element: <MemberProxyApplication />,
                },
                /**
                 * 完了
                 */
                {
                  path: ':applicationType/complete',
                  element: <MemberProxyComplete />,
                },
              ],
            },
          ],
        },
        /**
         * 会員向けお知らせ
         */
        {
          path: 'informations',
          element: <Informations />,
          children: [
            { index: true, element: <InformationsIndex /> },
            /**
             * お知らせを追加する
             */
            { path: 'create', element: <Information /> },
            /**
             * 編集
             */
            { path: ':associationInformationId', element: <InformationEdit /> },
          ],
        },
        /**
         * 持株会情報
         */
        {
          path: 'association',
          element: <Association />,
          children: [
            {
              index: true,
              element: <AssociationIndex />,
            },
            /**
             * 買増清算金振込口座を変更
             */
            { path: 'bank', element: <AssociationBank /> },
            /**
             * 買増清算金振込口座を変更
             */
            { path: 'contact', element: <AssociationContact /> },
            /**
             * 会員申請締日を変更
             */
            { path: 'deadline', element: <AssociationDeadline /> },
          ],
        },
        /**
         * 担当者一覧
         */
        {
          path: 'office-users',
          element: <OfficeUsers />,
          children: [
            { index: true, element: <OfficeUsersIndex /> },
            /**
             * 新規担当者を登録
             */
            { path: 'create', element: <OfficeUserCreate /> },
            /**
             * 担当者情報を編集
             */
            { path: ':officeUserId', element: <OfficeUser /> },
            /**
             * 既存担当者を追加
             */
            { path: 'add', element: <OfficeUserAdd /> },
          ],
        },
        /**
         * アカウント設定
         */
        {
          path: 'account',
          element: <Account />,
          children: [
            { index: true, element: <AccountIndex /> },
            { path: 'edit', element: <AccountEdit /> },
          ],
        },
        {
          path: '/login',
          element: <Navigate to="/" />,
        },
      ],
    },
    {
      path: '/login',
      element: <LoginLayout />,
      children: [
        /**
         * 持株会情報登録 / 確認
         */
        { path: 'association', element: <LoginAssociation /> },
        /**
         * 持株会情報登録完了
         */
        { path: 'association/complete', element: <AssociationComplete /> },
      ],
    },
  ] as RouteObject[],
  public: [
    /**
     * エラーページ
     */
    // { path: '*', element: <NotFound /> },

    /**
     * ログイン
     */
    {
      path: '/login',
      element: <LoginLayout />,
      children: [
        { index: true, element: <Login /> },
        /**
         * 多要素認証
         */
        { path: 'mfa', element: <Mfa /> },
        /**
         * パスワード設定
         */
        { path: 'password/setting', element: <PasswordSetting /> },
        /**
         * パスワード設定完了
         */
        {
          path: 'password/setting/complete',
          element: <PasswordSettingComplete />,
        },
        /**
         * 管理者情報登録 / 確認
         */
        { path: 'admin', element: <Admin /> },
        /**
         * パスワード再設定
         */
        { path: 'password/resetting', element: <PasswordResetting /> },
        /**
         * パスワード再設定MFA
         */
        { path: 'password/resetting/mfa', element: <PasswordResettingMfa /> },
        /**
         * パスワード変更
         */
        { path: 'password/change', element: <PasswordChange /> },
        /**
         * パスワード変更完了
         */
        {
          path: 'password/change/complete',
          element: <PasswordChangeComplete />,
        },
      ],
    },
    {
      path: '/',
      element: <Navigate to="/login" />,
    },
  ] as RouteObject[],
};
