import { FC, useState } from 'react';
import { theme } from '@/theme/theme';
import {
  Box,
  Typography,
  styled,
  SvgIcon,
  Tooltip,
  tooltipClasses,
  ClickAwayListener,
} from '@mui/material';
import { ReactComponent as QuestionIcon } from '@/assets/questionPopOver.svg';
import { PrintBackgroundFix } from './TransactionHistory';

interface GeneralInfoPartProps {
  isHeader: boolean;
  title: string;
  description: string;
  descriptionSymbol?: string;
  tooltip?: {
    title?: string;
    subtitle?: string;
    placement?: 'top-start' | 'top-end';
  };
  onClick: () => void;
  color?: string;
}

const Wrapper = styled(Box)(() => {
  const { breakpoints, palette } = theme;

  return {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: palette.system['separator-light'],
    borderStyle: 'solid',

    '&:last-of-type': {
      borderRightWidth: 0,
    },

    [breakpoints.down('sm')]: {
      flexDirection: 'row',
      borderRightWidth: 0,
      borderBottomWidth: 1,

      '&:last-of-type': {
        borderBottomWidth: 0,
      },
    },

    '@media print': {
      flexDirection: 'column',
      borderWidth: 0,
      borderRightWidth: 1,
      borderColor: palette.system['separator-light'],
      borderStyle: 'solid',

      '&:last-of-type': {
        borderRightWidth: 0,
      },
    },
  };
});

const HeaderWrapper = styled(Box)(() => {
  const { breakpoints, palette } = theme;

  return {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '23px 0',
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: palette.system['separator-light'],
    borderStyle: 'solid',

    [breakpoints.down('sm')]: {
      flexDirection: 'row',
      borderBottomWidth: 1,
    },

    '@media print': {
      flexDirection: 'column',
      borderWidth: 0,
      borderRightWidth: 1,
    },
  };
});

const TitleWrapper = styled(Box)(() => {
  const { breakpoints, palette } = theme;

  return {
    flex: 4.5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#EFFBFC',
    flexDirection: 'row',
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.system['separator-light'],

    [breakpoints.down('sm')]: {
      padding: '17px 16px',
      borderBottomWidth: 0,
      borderRightWidth: 1,
      justifyContent: 'flex-start',
    },

    '@media print': {
      padding: 0,
      borderWidth: 0,
      borderBottomWidth: 1,
      justifyContent: 'center',
      ...PrintBackgroundFix,
    },
  };
});

const DescriptionWrapper = styled(Box)(() => {
  const { breakpoints } = theme;

  return {
    flex: 5.5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [breakpoints.down('sm')]: {
      padding: '17px 16px',
      justifyContent: 'flex-end',
    },

    '@media print': {
      padding: 0,
      justifyContent: 'center',
    },
  };
});

const HeaderTitleWrapper = styled(Box)(() => {
  const { breakpoints } = theme;

  return {
    marginBottom: 16,

    [breakpoints.down('sm')]: {
      marginBottom: 0,
      marginRight: 8,
    },

    '@media print': {
      marginBottom: 16,
      marginRight: 0,
    },
  };
});

const Title = styled(Typography)(() => {
  const { breakpoints, typography } = theme;

  return {
    ...typography.h6,
    color: '#0B5D61',

    [breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '150%',
    },

    '@media print': {
      fontSize: 18,
    },
  };
});

const Description = styled(Typography)(() => {
  const { breakpoints, typography, palette } = theme;

  return {
    ...typography.h4,
    fontWeight: 700,
    fontSize: 30,
    color: palette.system['text-normal'],

    [breakpoints.down('sm')]: {
      fontSize: 22,
      lineHeight: '100%',
    },

    '@media print': {
      fontSize: 30,
    },
  };
});

const SymbolWrapper = styled(Box)({
  marginLeft: 2,
});

const Symbol = styled(Box)(() => {
  const { palette, breakpoints } = theme;

  return {
    fontSize: 20,
    lineHeight: '170%',
    fontWeight: 700,
    color: palette.system['text-normal'],

    [breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '190%',
    },

    '@media print': {
      fontSize: 20,
      lineHeight: '170%',
    },
  };
});

const IconWrapper = styled(Box)(() => {
  const { breakpoints } = theme;
  return {
    marginLeft: 11,

    [breakpoints.down('sm')]: {
      marginTop: 5,
    },

    '@media print': {
      marginLeft: 11,
      marginTop: 0,
    },
  };
});

const TooltipTextContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
});

const TooltipTitleText = styled(Box)({
  ...theme.typography['body-sub/bold'],
  color: theme.palette.system['text-normal'],
});

const TooltipSubtitleText = styled(Box)({
  ...theme.typography['caption/regular'],
  color: theme.palette.system['text-normal'],
});

const IconTooltip = styled(Tooltip)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export const GeneralInfoPart: FC<GeneralInfoPartProps> = ({
  isHeader,
  title,
  description,
  descriptionSymbol,
  onClick,
  tooltip,
  color,
}) => {
  const [open, setOpen] = useState(false);

  const renderContent = () => {
    if (isHeader) {
      return (
        <HeaderWrapper>
          <HeaderTitleWrapper>
            <Title>{title}</Title>
          </HeaderTitleWrapper>
          <Description>
            {description}
            {descriptionSymbol && (
              <SymbolWrapper component="span">
                <Symbol component="span">{descriptionSymbol}</Symbol>
              </SymbolWrapper>
            )}
          </Description>
        </HeaderWrapper>
      );
    }

    const renderIcon = () => (
      <IconWrapper onClick={() => setOpen(true)} component="span">
        <SvgIcon sx={{ width: 18, height: 18 }} viewBox="0 0 18 18">
          <QuestionIcon />
        </SvgIcon>
      </IconWrapper>
    );

    const getIconContent = () => {
      if (tooltip) {
        return (
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <IconTooltip
              title={
                <TooltipTextContainer>
                  <TooltipTitleText color="inherit">
                    {tooltip.title}
                  </TooltipTitleText>
                  <TooltipSubtitleText color="inherit">
                    {tooltip.subtitle}
                  </TooltipSubtitleText>
                </TooltipTextContainer>
              }
              placement="top"
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: theme.palette.system['background-light'],
                    padding: '16px',
                    borderColor: theme.palette.system.separator,
                    borderWidth: 1,
                    borderRadius: '8px',
                    borderStyle: 'solid',
                    width: 320,
                    margin: 0,
                  },
                },
                arrow: {
                  sx: {
                    color: theme.palette.common.white,
                    '&::before': {
                      backgroundColor: theme.palette.system['background-light'],
                      borderColor: theme.palette.system.separator,
                      borderWidth: 1,
                      borderStyle: 'solid',
                    },
                  },
                },
              }}
              open={open}
              PopperProps={{
                popperOptions: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -11],
                      },
                    },
                  ],
                },
              }}
            >
              {renderIcon()}
            </IconTooltip>
          </ClickAwayListener>
        );
      }
      return renderIcon();
    };

    return (
      <Wrapper>
        <TitleWrapper>
          <Title>{title}</Title>
          {getIconContent()}
        </TitleWrapper>
        <DescriptionWrapper>
          <Description sx={{ color }}>
            {description}
            {descriptionSymbol && (
              <SymbolWrapper component="span">
                <Symbol component="span" sx={{ color }}>
                  {descriptionSymbol}
                </Symbol>
              </SymbolWrapper>
            )}
          </Description>
        </DescriptionWrapper>
      </Wrapper>
    );
  };

  return renderContent();
};
