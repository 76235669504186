/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useCallback, useEffect, useState } from 'react';
import { theme } from '@/theme';
import {
  Box,
  Typography,
  Button,
  styled,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import { ReactComponent as LeftArrow } from '@/assets/leftArrow.svg';
import { ReactComponent as RightArrow } from '@/assets/rightArrow.svg';
import { ReactComponent as ErrorOops } from '@/assets/errorOops.svg';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { ReactComponent as EditIcon } from '@/assets/edit.svg';
import { ReactComponent as EditGreyIcon } from '@/assets/editGrey.svg';
import { appClient } from '@/services';
import { GetAssociationsContribution } from '@/types/api/Association/associationsContribution';
import { useRecoilState, useRecoilValue } from 'recoil';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { EventListMonthlyState, EventListState } from '@/recoil/events/events';
import { ConvertDayToKanji, formatDate } from '@/utils/dateFunction';
import { ListEvents } from '@/types/api/events';
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const TableCellColors = {
  typeA: '#3D9EA3',
  typeB: '#EFFBFC',
};

const CellWidth = 146;

const Wrapper = styled(Box)({});

const HeaderSection = styled(Box)({
  padding: '32px 40px',
  backgroundColor: theme.palette.system['background-light'],
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 8,
  borderWidth: 0,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.system['separator-light'],
});

const HeaderTitle = styled(Typography)({
  ...theme.typography.h4,
  color: theme.palette.system['text-normal'],
});

const HeaderSubtitle = styled(Typography)({
  ...theme.typography['body-main/regular'],
  color: theme.palette.system['text-light'],
});

const ContentWrapper = styled(Box)({
  padding: '32px 40px',
});

const YearControllerWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
  alignItems: 'center',
});

const YearControllerLabel = styled(Typography)({
  ...theme.typography.h5,
  fontWeight: 700,
  color: theme.palette.system['text-normal'],
});

const YearControllerBtn = styled(Button)({
  width: 32,
  height: 32,
  minWidth: 32,
  minHeight: 32,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.system.separator,
  borderRadius: 4,

  '&:disabled': {
    backgroundColor: theme.palette.system['background-dark'],
  },
});

const ContentItem = styled(Box)({
  marginTop: 32,
});

const SharedTitleWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
});

const SharedTitle = styled(Typography)({
  ...theme.typography.h6,
  color: theme.palette.system['text-normal'],
});

const SharedSubtitle = styled(Typography)({
  ...theme.typography['body-sub/regular'],
  color: theme.palette.system['text-light'],
});

const ErrorMessageWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginTop: 16,
});

const ErrorMessageText = styled(Typography)({
  ...theme.typography['body-main/medium'],
  color: theme.palette.states.error,
});

const TableWrapper = styled(Box)({
  marginTop: 12,
  width: CellWidth * 5,
});

const BaseCell = styled(TableCell)(() => {
  const { palette } = theme;
  return {
    borderWidth: 0,
    borderRightWidth: 1,
    borderBottomWidtH: 1,
    borderStyle: 'solid',
    borderColor: palette.system.separator,
    padding: '16px 12px',

    '&:last-of-type': {
      borderRightWidth: 0,
    },
  };
});

const BaseCellException = styled(TableCell)(() => {
  const { palette } = theme;
  return {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.system.separator,
    padding: '16px 12px',
  };
});

const HeadCellTextGroup = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
});

const HeadCellText = styled(Typography)(() => {
  const { typography } = theme;

  return {
    ...typography['body-sub/medium'],
    color: '#0B5D61',
  };
});

const CellTextWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
  justifyContent: 'center',
  alignItems: 'center',
});

const CellText = styled(Typography)(() => {
  const { typography, palette } = theme;

  return {
    ...typography['body-main/regular'],
    color: palette.system['text-normal'],
  };
});

const CellTextError = styled(CellText)({
  color: theme.palette.states.error,
});

const CellButton = styled(Button)({
  display: 'flex',
  flexDirection: 'row',
  margin: 0,
  gap: 4,
  width: '100%',
});

export const SchedulesIndex = () => {
  const navigate = useNavigate();
  const associationCd = useRecoilValue(SelectedAssociationState);
  const [associationContribution, setAssociationContribution] =
    useState<GetAssociationsContribution>();
  const [events, setEvents] = useRecoilState(EventListState);
  const [eventsMonthly, setEventsMonthly] = useRecoilState(
    EventListMonthlyState
  );
  const [eventBonus, setEventBonus] = useState<ListEvents>();
  const [eventDividend, setEventDividend] = useState<ListEvents>();

  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  useHelmetHandler({
    title: 'スケジュール 一覧',
  });

  const getAssociationsContribution = async () => {
    try {
      const res = await appClient.associations.getAssociationsContribution(
        associationCd.associationCd || ''
      );
      if (res) {
        setAssociationContribution(res);
      }
    } catch (e) {
      // catch error
    }
  };

  useEffect(() => {
    if (associationCd.associationCd) {
      void getAssociationsContribution();
    }
  }, [associationCd]);

  // Filter list by eventNo
  const filterEventsByEventNo = (list: ListEvents): ListEvents => {
    const filteredList = list?.filter((item) => {
      const itemYearStr = item.eventNo?.substring(0, 4);
      if (itemYearStr === selectedYear.toString()) return item;
      return null;
    });

    return filteredList;
  };

  const getEvents = useCallback(async () => {
    try {
      if (!associationCd?.associationCd) return;
      const res = await appClient.events.listEvents(
        associationCd.associationCd
      );
      const resEvBonus = await appClient.events.listEvents(
        associationCd.associationCd,
        'BONUS'
      );
      const resEvDividend = await appClient.events.listEvents(
        associationCd.associationCd,
        'DIVIDEND'
      );
      const filteredBonus = filterEventsByEventNo(resEvBonus.events);
      const filteredDividend = filterEventsByEventNo(resEvDividend.events);
      setEventBonus(filteredBonus ? [...filteredBonus] : undefined);
      setEventDividend(filteredDividend ? [...filteredDividend] : undefined);
      if (res.events) setEvents(res.events);
    } catch (e) {
      // handle getEvents error
    }
  }, [associationCd, selectedYear, setEvents]);

  const getEventsMonthly = useCallback(async () => {
    try {
      if (!associationCd?.associationCd) return;
      const res = await appClient.events.findEventMonthly(
        associationCd.associationCd,
        selectedYear
      );
      if (res) setEventsMonthly(res);
    } catch (e) {
      // handle getEventsMonthly error
    }
  }, [associationCd.associationCd, setEventsMonthly, selectedYear]);

  const getEventsMonthlyText = (index: number): string | undefined => {
    let dateText: string | undefined;
    switch (index) {
      case 0:
        dateText = eventsMonthly?.monthlyPurchase?.date1;
        break;
      case 1:
        dateText = eventsMonthly?.monthlyPurchase?.date2;
        break;
      case 2:
        dateText = eventsMonthly?.monthlyPurchase?.date3;
        break;
      case 3:
        dateText = eventsMonthly?.monthlyPurchase?.date4;
        break;
      case 4:
        dateText = eventsMonthly?.monthlyPurchase?.date5;
        break;
      case 5:
        dateText = eventsMonthly?.monthlyPurchase?.date6;
        break;
      case 6:
        dateText = eventsMonthly?.monthlyPurchase?.date7;
        break;
      case 7:
        dateText = eventsMonthly?.monthlyPurchase?.date8;
        break;
      case 8:
        dateText = eventsMonthly?.monthlyPurchase?.date9;
        break;
      case 9:
        dateText = eventsMonthly?.monthlyPurchase?.date10;
        break;
      case 10:
        dateText = eventsMonthly?.monthlyPurchase?.date11;
        break;
      case 11:
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        dateText = eventsMonthly?.monthlyPurchase?.date12;
        break;
      default:
        dateText = undefined;
        break;
    }

    if (dateText) {
      return `${format(new Date(dateText), 'MM/dd')} (${ConvertDayToKanji(
        new Date(dateText).getDay()
      )})`;
    }
    return '-';
  };

  const getEventsMemberDeadlineText = (index: number) => {
    let dateText: string | undefined;
    switch (index) {
      case 0:
        dateText = eventsMonthly?.memberDeadline?.date1;
        break;
      case 1:
        dateText = eventsMonthly?.memberDeadline?.date2;
        break;
      case 2:
        dateText = eventsMonthly?.memberDeadline?.date3;
        break;
      case 3:
        dateText = eventsMonthly?.memberDeadline?.date4;
        break;
      case 4:
        dateText = eventsMonthly?.memberDeadline?.date5;
        break;
      case 5:
        dateText = eventsMonthly?.memberDeadline?.date6;
        break;
      case 6:
        dateText = eventsMonthly?.memberDeadline?.date7;
        break;
      case 7:
        dateText = eventsMonthly?.memberDeadline?.date8;
        break;
      case 8:
        dateText = eventsMonthly?.memberDeadline?.date9;
        break;
      case 9:
        dateText = eventsMonthly?.memberDeadline?.date10;
        break;
      case 10:
        dateText = eventsMonthly?.memberDeadline?.date11;
        break;
      case 11:
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        dateText = eventsMonthly?.memberDeadline?.date12;
        break;
      default:
        dateText = undefined;
        break;
    }

    if (dateText) {
      return `${format(new Date(dateText), 'MM/dd')} (${ConvertDayToKanji(
        new Date(dateText).getDay()
      )})`;
    }
    return '-';
  };

  const getEventsOfficeDeadlineText = (index: number) => {
    let dateText: string | undefined;
    switch (index) {
      case 0:
        dateText = eventsMonthly?.officeDeadline?.date1;
        break;
      case 1:
        dateText = eventsMonthly?.officeDeadline?.date2;
        break;
      case 2:
        dateText = eventsMonthly?.officeDeadline?.date3;
        break;
      case 3:
        dateText = eventsMonthly?.officeDeadline?.date4;
        break;
      case 4:
        dateText = eventsMonthly?.officeDeadline?.date5;
        break;
      case 5:
        dateText = eventsMonthly?.officeDeadline?.date6;
        break;
      case 6:
        dateText = eventsMonthly?.officeDeadline?.date7;
        break;
      case 7:
        dateText = eventsMonthly?.officeDeadline?.date8;
        break;
      case 8:
        dateText = eventsMonthly?.officeDeadline?.date9;
        break;
      case 9:
        dateText = eventsMonthly?.officeDeadline?.date10;
        break;
      case 10:
        dateText = eventsMonthly?.officeDeadline?.date11;
        break;
      case 11:
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        dateText = eventsMonthly?.officeDeadline?.date12;
        break;
      default:
        dateText = undefined;
        break;
    }

    if (dateText) {
      return `${format(new Date(dateText), 'MM/dd')} (${ConvertDayToKanji(
        new Date(dateText).getDay()
      )})`;
    }
    return '-';
  };

  const getEventsBonusAndNewUserMonths = (index: number) => {
    let unitMonth: string | undefined;
    let newUserMonth: string | undefined;
    switch (index) {
      case 0:
        unitMonth = associationContribution?.unitMonth1;
        newUserMonth = associationContribution?.newUserMonth1;
        break;
      case 1:
        unitMonth = associationContribution?.unitMonth2;
        newUserMonth = associationContribution?.newUserMonth2;
        break;
      case 2:
        unitMonth = associationContribution?.unitMonth3;
        newUserMonth = associationContribution?.newUserMonth3;
        break;
      case 3:
        unitMonth = associationContribution?.unitMonth4;
        newUserMonth = associationContribution?.newUserMonth4;
        break;

      case 4:
        unitMonth = associationContribution?.unitMonth5;
        newUserMonth = associationContribution?.newUserMonth5;
        break;
      case 5:
        unitMonth = associationContribution?.unitMonth6;
        newUserMonth = associationContribution?.newUserMonth6;
        break;
      case 6:
        unitMonth = associationContribution?.unitMonth7;
        newUserMonth = associationContribution?.newUserMonth7;
        break;
      case 7:
        unitMonth = associationContribution?.unitMonth8;
        newUserMonth = associationContribution?.newUserMonth8;
        break;
      case 8:
        unitMonth = associationContribution?.unitMonth9;
        newUserMonth = associationContribution?.newUserMonth9;
        break;
      case 9:
        unitMonth = associationContribution?.unitMonth10;
        newUserMonth = associationContribution?.newUserMonth10;
        break;
      case 10:
        unitMonth = associationContribution?.unitMonth11;
        newUserMonth = associationContribution?.newUserMonth11;
        break;
      case 11:
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        unitMonth = associationContribution?.unitMonth12;
        newUserMonth = associationContribution?.newUserMonth12;
        break;
      default:
        unitMonth = undefined;
        newUserMonth = undefined;
        break;
    }

    if (unitMonth === '1' && newUserMonth === '1') {
      return '口数変更、一部引出、休止、再開、退会、新規会員登録';
    }
    if (unitMonth === '1' && newUserMonth === '0') {
      return '口数変更、一部引出、休止、再開、退会';
    }
    if (unitMonth === '0' && newUserMonth === '1') {
      return '一部引出、休止、再開、退会、新規会員登録';
    }
    if (unitMonth === '0' && newUserMonth === '0') {
      return '一部引出、休止、再開、退会';
    }
    return '-';
  };

  const getBonusMonths = () => {
    const months = [];
    if (associationContribution?.bonusMonth1 === '1') {
      months.push('1月');
    }
    if (associationContribution?.bonusMonth2 === '1') {
      months.push('2月');
    }
    if (associationContribution?.bonusMonth3 === '1') {
      months.push('3月');
    }
    if (associationContribution?.bonusMonth4 === '1') {
      months.push('4月');
    }
    if (associationContribution?.bonusMonth5 === '1') {
      months.push('5月');
    }
    if (associationContribution?.bonusMonth6 === '1') {
      months.push('6月');
    }
    if (associationContribution?.bonusMonth7 === '1') {
      months.push('7月');
    }
    if (associationContribution?.bonusMonth8 === '1') {
      months.push('8月');
    }
    if (associationContribution?.bonusMonth9 === '1') {
      months.push('9月');
    }
    if (associationContribution?.bonusMonth10 === '1') {
      months.push('10月');
    }
    if (associationContribution?.bonusMonth11 === '1') {
      months.push('11月');
    }
    if (associationContribution?.bonusMonth11 === '1') {
      months.push('12月');
    }
    return months;
  };

  const getDividendMonths = () => {
    const emptyText = '-';
    const months: string[] = [];

    if (eventDividend) {
      eventDividend.forEach((item) => {
        months.push(
          `${
            item.eventNo?.slice(4, 5) === '0'
              ? item.eventNo.slice(5, 6)
              : item.eventNo?.slice(4, 6)
          }月` || emptyText
        );
      });
    }
    return months;
  };

  const renderFullTableRow = (index: number, isLast?: boolean) => {
    let returnParam;
    if (getEventsMonthlyText(index) !== '-') {
      returnParam = (
        <TableRow
          key={`${index}_random_key`}
          sx={{
            borderWidth: 0,
            borderBottomWidth: isLast ? 0 : 1,
            borderStyle: 'solid',
            borderColor: theme.palette.system.separator,
          }}
        >
          <BaseCell align="center" sx={{ padding: '4px 10px' }}>
            <CellText>{index + 1}月</CellText>
          </BaseCell>
          <BaseCell align="center" sx={{ padding: '4px 10px' }}>
            <CellText>{getEventsMonthlyText(index)}</CellText>
          </BaseCell>
          <BaseCell align="center" sx={{ padding: '4px 10px' }}>
            <CellText>{getEventsMemberDeadlineText(index)}</CellText>
          </BaseCell>
          <BaseCell align="center" sx={{ padding: '4px 10px' }}>
            <CellText>{getEventsOfficeDeadlineText(index)}</CellText>
          </BaseCell>
          <BaseCell align="left" sx={{ padding: '4px 10px' }}>
            <CellText>{getEventsBonusAndNewUserMonths(index)}</CellText>
          </BaseCell>
        </TableRow>
      );
    }

    return returnParam;
  };

  const renderMonthlyPurchaseDates = () => {
    let renderMonthlyPurchaseCount = 0;

    Array.from({ length: 12 }).map((_, index) => {
      if (getEventsMonthlyText(index) !== '-') {
        renderMonthlyPurchaseCount += 1;
      }
      return null;
    });

    return (
      <ContentItem>
        <TableWrapper sx={{ width: '100%' }}>
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: 'none',
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: theme.palette.system.separator,
            }}
          >
            <Table sx={{ minWidth: '100%' }}>
              <TableHead>
                <TableRow
                  sx={{
                    borderWidth: 0,
                    borderBottomWidth: 1,
                    borderStyle: 'solid',
                    borderColor: theme.palette.system.separator,
                  }}
                >
                  <BaseCell
                    align="center"
                    sx={{
                      width: '7%',
                      padding: '4px 10px',
                      backgroundColor: TableCellColors.typeB,
                    }}
                  >
                    <HeadCellText>買付月</HeadCellText>
                  </BaseCell>
                  <BaseCell
                    align="center"
                    sx={{
                      width: '13%',
                      padding: '4px 12px',
                      backgroundColor: TableCellColors.typeB,
                    }}
                  >
                    <HeadCellText>買付日</HeadCellText>
                  </BaseCell>
                  <BaseCell
                    align="center"
                    sx={{
                      width: '13%',
                      padding: '4px 12px',
                      backgroundColor: TableCellColors.typeB,
                    }}
                  >
                    <HeadCellText>会員受付締日</HeadCellText>
                  </BaseCell>
                  <BaseCell
                    align="center"
                    sx={{
                      width: '13%',
                      padding: '4px 12px',
                      backgroundColor: TableCellColors.typeB,
                    }}
                  >
                    <HeadCellText>事務局受付締日</HeadCellText>
                  </BaseCell>
                  <BaseCell
                    align="center"
                    sx={{
                      width: '54%',
                      padding: '4px 12px',
                      backgroundColor: TableCellColors.typeB,
                    }}
                  >
                    <HeadCellText>受付申請</HeadCellText>
                  </BaseCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from({ length: 12 }).map((_, index) =>
                  renderFullTableRow(index, index === 11)
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TableWrapper>
        {renderMonthlyPurchaseCount === 0 ? (
          <Box>イベントが存在しません</Box>
        ) : null}
      </ContentItem>
    );
  };

  const renderTableRow = (
    data: {
      eventNo?: string;
      eventType?: string;
      purchaseDate?: string;
      closingDate?: string;
      registrationDeadline?: string;
      workreportApprovalStatus?: 'BEFORESUBMISSION' | 'SUBMISSION';
      edit: boolean;
      error: boolean;
    },
    isLast?: boolean,
    originalData?: any
  ) => {
    const emptyText = '-';

    if (data.eventType === '02' && data.purchaseDate === '') {
      data.closingDate = '';
    }

    return (
      <TableRow
        key={`${data.eventNo}_${data.eventType}`}
        sx={{
          borderWidth: 0,
          borderBottomWidth: isLast ? 0 : 1,
          borderStyle: 'solid',
          borderColor: theme.palette.system.separator,
        }}
      >
        <BaseCell
          align="center"
          sx={{
            padding: '4px 0',
            backgroundColor: data.error
              ? theme.palette.states['error-bg']
              : 'unset',
          }}
        >
          <CellText>
            {`${
              data.eventNo?.slice(4, 5) === '0'
                ? data.eventNo.slice(5, 6)
                : data.eventNo?.slice(4, 6)
            }月` || emptyText}
          </CellText>
        </BaseCell>
        <BaseCell
          align="center"
          sx={{
            padding: '4px 0',
            backgroundColor: data.error
              ? theme.palette.states['error-bg']
              : 'unset',
          }}
        >
          <CellText>{data.purchaseDate || emptyText}</CellText>
        </BaseCell>
        <BaseCell
          align="center"
          sx={{
            padding: '4px 0',
            backgroundColor: data.error
              ? theme.palette.states['error-bg']
              : 'unset',
          }}
        >
          <CellText>{data.closingDate || emptyText}</CellText>
        </BaseCell>
        <BaseCell
          align="center"
          sx={{
            padding: '4px 0',
            backgroundColor: data.error
              ? theme.palette.states['error-bg']
              : 'unset',
          }}
        >
          {data.error ? (
            <CellTextWrapper>
              <CellTextError>{data.registrationDeadline}</CellTextError>
              <ErrorOops width={16} height={16} />
            </CellTextWrapper>
          ) : (
            <CellText>{data.registrationDeadline}</CellText>
          )}
        </BaseCell>
        {data.eventType === '02' && (
          <BaseCell align="right" sx={{ padding: '4px 0' }}>
            <CellButton
              variant="text"
              disabled={data.workreportApprovalStatus === 'SUBMISSION'}
              disableRipple
              sx={{ padding: 0 }}
              onClick={() =>
                navigate(
                  `/schedules/create/${data?.eventNo || ''}/${
                    data.eventType === '02' ? 'bonus' : 'dividend'
                  }`
                )
              }
            >
              {data.workreportApprovalStatus !== 'SUBMISSION' ? (
                <EditIcon width={16} height={16} />
              ) : (
                <EditGreyIcon width={16} height={16} />
              )}

              <CellText
                sx={{
                  color:
                    data.workreportApprovalStatus !== 'SUBMISSION'
                      ? theme.palette.secondary.normal
                      : theme.palette.system.inactive,
                }}
              >
                編集する
              </CellText>
            </CellButton>
          </BaseCell>
        )}
      </TableRow>
    );
  };

  const isError = (item: {
    associationCd?: string;
    associationName?: string;
    listedClassification?: 'LISTEDCOMPANY' | 'UNLISTEDCOMPANY';
    eventNo?: string;
    eventType?: string;
    purchaseDate?: string;
    purchaseDateInputValue?: string;
    accumulatedDateInputValue?: string;
    purchaseDateRegistrationStatus?: 'BEFORREGISTRATION' | 'REGITERED';
    purchaseDateRegistrationDate?: string;
    workreportApprovalStatus?: 'BEFORESUBMISSION' | 'SUBMISSION';
    workreportDueDate?: string;
    purchaseScheduleDay?: number;
  }) => {
    if (
      !item.purchaseDate &&
      !item.workreportDueDate &&
      item.purchaseDateRegistrationDate &&
      new Date(item.purchaseDateRegistrationDate) < new Date()
    ) {
      return true;
    }
    return false;
  };

  const isEdit = (registrationDate?: string) => {
    if (
      !registrationDate ||
      (registrationDate && new Date(registrationDate) < new Date())
    ) {
      return true;
    }
    return true;
  };

  const today = new Date();
  const formatedToday = format(today, 'yyyy/MM/dd (E) HH:mm', {
    locale: ja,
  });

  const checkPurchaseDateIsRegistered = (eventNumber: string) => {
    let eventsOfSelectedYear = events
      .filter((e) => e.eventType === eventNumber)
      .filter(
        (e) => new Date(e.purchaseDate || '').getFullYear() === selectedYear
      );
    if (
      eventsOfSelectedYear.some(
        (e) =>
          new Date(e.purchaseDateRegistrationDate || '') > new Date() &&
          e.purchaseDate === ''
      )
    ) {
      return (
        <ErrorMessageWrapper>
          <ErrorOops />
          <ErrorMessageText>買付日登録期日を超過しています！</ErrorMessageText>
        </ErrorMessageWrapper>
      );
    }
    eventsOfSelectedYear = events
      .filter((e) => e.eventType === eventNumber)
      .filter((e) => e.purchaseDate === '');
    if (eventsOfSelectedYear.some((e) => e.purchaseDate === '')) {
      return (
        <ErrorMessageWrapper>
          <ErrorOops />
          <ErrorMessageText>買付日が未登録です！</ErrorMessageText>
        </ErrorMessageWrapper>
      );
    }
    return '';
  };

  // const renderDividendProcessingClassification = () => {
  //   switch (association.dividendProcessingClassification ?? '') {
  //     case '1': {
  //       return '積立';
  //     }
  //     case '2': {
  //       return '買付';
  //     }
  //     case '3': {
  //       return '支払';
  //     }
  //     default: {
  //       return '';
  //     }
  //   }
  // };

  const renderBonusReserveClassification = () => {
    switch (associationContribution?.bonusReserveClassification ?? '') {
      case 'PURCHASE': {
        return '積立';
      }
      case 'RESERVE': {
        return '買付';
      }
      default: {
        return '';
      }
    }
  };

  useEffect(() => {
    void getEvents();
    void getEventsMonthly();
  }, [getEvents, getEventsMonthly]);

  if (associationContribution) {
    return (
      <Wrapper>
        <HeaderSection>
          <HeaderTitle>スケジュール</HeaderTitle>
          <HeaderSubtitle>{formatedToday} 現在</HeaderSubtitle>
        </HeaderSection>
        <ContentWrapper>
          <YearControllerWrapper>
            <YearControllerBtn
              onClick={() => setSelectedYear(selectedYear - 1)}
              disabled={!!(selectedYear < 2020)}
            >
              <LeftArrow />
            </YearControllerBtn>
            <YearControllerLabel>{selectedYear}年</YearControllerLabel>
            <YearControllerBtn
              onClick={() => setSelectedYear(selectedYear + 1)}
              disabled={!!(selectedYear === new Date().getFullYear() + 1)}
            >
              <RightArrow />
            </YearControllerBtn>
          </YearControllerWrapper>
          <ContentItem>
            <SharedTitleWrapper>
              <SharedTitle>月例買付日</SharedTitle>
            </SharedTitleWrapper>
            {renderMonthlyPurchaseDates()}
            {associationContribution.monthlyPurchaseDateUsageType === 'WEB' &&
              selectedYear === new Date().getFullYear() + 1 && (
                <Box>
                  {!!eventsMonthly ? (
                    <PrimaryButton
                      sx={{ marginTop: '16px' }}
                      onClick={() => navigate('/schedules/create')}
                    >
                      月例の買付日を編集する
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton
                      sx={{ marginTop: '16px' }}
                      onClick={() => navigate('/schedules/create')}
                    >
                      月例の買付日を登録する
                    </PrimaryButton>
                  )}
                </Box>
              )}
          </ContentItem>
          {associationContribution?.bonusReserveClassification !== 'NONE' && (
            <ContentItem sx={{ marginTop: '32px' }}>
              <SharedTitleWrapper>
                <SharedTitle>{`賞与${renderBonusReserveClassification()}日`}</SharedTitle>
                <SharedSubtitle>
                  {renderBonusReserveClassification()}予定月{' '}
                  {getBonusMonths().join(' ')}
                </SharedSubtitle>
              </SharedTitleWrapper>
              <TableWrapper>
                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: 'none',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: theme.palette.system.separator,
                  }}
                >
                  <Table sx={{ minWidth: '100%' }}>
                    <TableHead>
                      <TableRow
                        sx={{
                          borderWidth: 0,
                          borderBottomWidth: 1,
                          borderStyle: 'solid',
                          borderColor: theme.palette.system.separator,
                        }}
                      >
                        <BaseCell
                          align="center"
                          sx={{
                            width: CellWidth,
                            padding: '4px 12px',
                            backgroundColor: TableCellColors.typeB,
                          }}
                        >
                          <HeadCellText>{`${renderBonusReserveClassification()}予定月`}</HeadCellText>
                        </BaseCell>
                        <BaseCell
                          align="center"
                          sx={{
                            width: CellWidth,
                            padding: '4px 12px',
                            backgroundColor: TableCellColors.typeB,
                          }}
                        >
                          <HeadCellText>{`${renderBonusReserveClassification()}日`}</HeadCellText>
                        </BaseCell>
                        <BaseCell
                          align="center"
                          sx={{
                            width: CellWidth,
                            padding: '4px 12px',
                            backgroundColor: TableCellColors.typeB,
                          }}
                        >
                          <HeadCellText>事務局受付締日</HeadCellText>
                        </BaseCell>
                        <BaseCell
                          align="center"
                          sx={{
                            width: CellWidth,
                            padding: '4px 12px',
                            backgroundColor: TableCellColors.typeB,
                          }}
                        >
                          <HeadCellText>{`${renderBonusReserveClassification()}日登録期日`}</HeadCellText>
                        </BaseCell>
                        <BaseCell
                          align="center"
                          sx={{
                            width: CellWidth,
                            padding: '4px 12px',
                            backgroundColor: TableCellColors.typeB,
                          }}
                        >
                          <HeadCellText>編集</HeadCellText>
                        </BaseCell>
                      </TableRow>
                    </TableHead>
                    {eventBonus && eventBonus?.length > 0 && (
                      <TableBody>
                        {eventBonus.map((item) =>
                          renderTableRow(
                            {
                              eventNo: item.eventNo,
                              eventType: item.eventType,
                              purchaseDate: formatDate(
                                item.purchaseDateInputValue || ''
                              ),
                              closingDate: formatDate(
                                item.workreportDueDate || ''
                              ),
                              registrationDeadline: formatDate(
                                item.purchaseDateRegistrationDate || ''
                              ),
                              workreportApprovalStatus:
                                item.workreportApprovalStatus,
                              edit: true,
                              error: isError(item),
                            },
                            true
                          )
                        )}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </TableWrapper>
              {!eventBonus ||
                (eventBonus.length === 0 ? (
                  <Box>イベントが存在しません。</Box>
                ) : (
                  checkPurchaseDateIsRegistered('02')
                ))}
            </ContentItem>
          )}
          <ContentItem sx={{ marginTop: '32px' }}>
            <SharedTitleWrapper>
              <SharedTitle>配当支給日</SharedTitle>
              <SharedSubtitle>
                支給予定月 {getDividendMonths().join(' ')}
              </SharedSubtitle>
            </SharedTitleWrapper>
            <TableWrapper>
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: 'none',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: theme.palette.system.separator,
                }}
              >
                <Table sx={{ minWidth: '100%' }}>
                  <TableHead>
                    <TableRow
                      sx={{
                        borderWidth: 0,
                        borderBottomWidth: 1,
                        borderStyle: 'solid',
                        borderColor: theme.palette.system.separator,
                      }}
                    >
                      <BaseCell
                        align="center"
                        sx={{
                          width: CellWidth,
                          padding: '4px 12px',
                          backgroundColor: TableCellColors.typeB,
                        }}
                      >
                        <HeadCellText>支給予定月</HeadCellText>
                      </BaseCell>
                      <BaseCell
                        align="center"
                        sx={{
                          width: CellWidth,
                          padding: '4px 12px',
                          backgroundColor: TableCellColors.typeB,
                        }}
                      >
                        <HeadCellText>支給日</HeadCellText>
                      </BaseCell>
                      <BaseCell
                        align="center"
                        sx={{
                          width: CellWidth,
                          padding: '4px 12px',
                          backgroundColor: TableCellColors.typeB,
                        }}
                      >
                        <HeadCellText>事務局受付締日</HeadCellText>
                      </BaseCell>
                      <BaseCell
                        align="center"
                        sx={{
                          width: CellWidth,
                          padding: '4px 12px',
                          backgroundColor: TableCellColors.typeB,
                        }}
                      >
                        <HeadCellText>権利付き最終日</HeadCellText>
                      </BaseCell>
                    </TableRow>
                  </TableHead>
                  {eventDividend && eventDividend.length > 0 && (
                    <TableBody>
                      {eventDividend.map((item) =>
                        renderTableRow(
                          {
                            eventNo: item.eventNo,
                            eventType: item.eventType,
                            purchaseDate: formatDate(item.purchaseDate || ''),
                            closingDate: formatDate(
                              item.workreportDueDate || ''
                            ),
                            registrationDeadline: formatDate(
                              item.purchaseDateRegistrationDate || ''
                            ),
                            workreportApprovalStatus:
                              item.workreportApprovalStatus,
                            edit: isEdit(item?.purchaseDate),
                            error: isError(item),
                          },
                          undefined,
                          item
                        )
                      )}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </TableWrapper>
            {!eventDividend ||
              (eventDividend.length === 0 ? (
                <Box>イベントが存在しません</Box>
              ) : null)}
          </ContentItem>
        </ContentWrapper>
      </Wrapper>
    );
  }
  return null;
};
