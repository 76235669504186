export const AuthorityClassification = {
  General: 'GENERAL',
  Administrator: 'ADMINISTRATOR',
} as const;

export const ProxyApplicationClassification = {
  Impossible: 'IMPOSSIBLE',
  Possible: 'POSSIBLE',
} as const;

export const SortKey = {
  OfficeUserNameAsc: 'OFFICE_USER_NAME_ASC',
  OfficeUesrNameDesc: 'OFFICE_USER_NAME_DESC',
  AutorityCalassificationAsc: 'AUTORITY_CALASSIFICATION_ASC',
  AutorityCalassificationDesc: 'AUTORITY_CALASSIFICATION_DESC',
  ProxyApplicationClassificationAsc: 'PROXY_APPLICATION_CLASSIFICATION_ASC',
  ProxyApplicationClassificationDesc: 'PROXY_APPLICATION_CLASSIFICATION_DESC',
  EmailAsc: 'EMAIL_ASC',
  EmailDesc: 'EMAIL_DESC',
} as const;
