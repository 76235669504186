import {
  Box,
  Divider,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { FC } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CancelButton } from '@/components/atoms/Buttons/CancelButton';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { SubmitButton } from '@/components/atoms/Buttons/SubmitButton';
import { OfficeUserCreateInputs as Inputs } from '@/types/components/OfficeUser';
import {
  switchAuthorityLabel,
  switchProxyApplicationLabel,
} from '@/utils/officeUser';
import {
  AuthorityClassification,
  ProxyApplicationClassification,
} from '@/constants/OfficeUser/officeUsersIndex';
import { validateEmail, inputRequiredValidateEmail } from '@/utils/validation';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { OfficeUserCreateHead } from './OfficeUserCreateHead';
import { OfficeUserCreateNameField } from './OfficeUserCreateNameField';
import {
  Label,
  MailAnnotation,
  StyledStack,
  ErrorText,
} from './StyledComponent';

interface NewOfficeUserCreateProps {
  onSubmit: SubmitHandler<Inputs>;
  handleClickCancel: () => void;
  handleClickConfirm: () => void;
  isConfirmed: boolean;
  isError: boolean;
}

const MuiTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    paddingLeft: '8px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '8px 8px 8px 0px',
    textAlign: 'left',
  },
});

export const NewOfficeUserCreate: FC<NewOfficeUserCreateProps> = ({
  onSubmit,
  handleClickCancel,
  handleClickConfirm,
  isConfirmed,
  isError,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      firstNameKanji: '',
      lastNameKanji: '',
      mail: '',
      authorityClassification: AuthorityClassification.General,
      proxyApplicationClassification: ProxyApplicationClassification.Impossible,
    },
  });
  useHelmetHandler({
    title: isConfirmed ? '持株会事務局員追加 内容確認' : '持株会事務局追加',
  });
  const isDisabled = !(
    !!watch('firstNameKanji') &&
    !!watch('lastNameKanji') &&
    !!inputRequiredValidateEmail(getValues('mail'))
  );

  const isNameValid = (text: string) => {
    if (text.length === 0) return false;
    const processedText = text.replaceAll(' ', '');
    return processedText.length > 0;
  };

  return (
    <>
      <OfficeUserCreateHead
        title="新規担当者を登録"
        isConfirmed={isConfirmed}
        confirmedSubText="以下の内容で登録します。"
      />
      <Divider />
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <StyledStack>
          {isError ? (
            <Typography
              marginRight={30}
              variant="body-main/regular"
              mb={3}
              color="states.error"
            >
              対象のログインIDはすでに登録されています。
            </Typography>
          ) : null}
          <Box display="flex" gap={1} pb={5}>
            <Label>氏名</Label>
            <OfficeUserCreateNameField
              placeholder="例：田中"
              label="姓"
              control={control}
              value={String(getValues('lastNameKanji'))}
              isConfirmed={isConfirmed}
              fieldName="lastNameKanji"
              error={!isNameValid(getValues('lastNameKanji'))}
              helperText="姓を入力してください"
            />
            <OfficeUserCreateNameField
              placeholder="例：太郎"
              label="名"
              control={control}
              value={String(getValues('firstNameKanji'))}
              isConfirmed={isConfirmed}
              fieldName="firstNameKanji"
              error={!isNameValid(getValues('firstNameKanji'))}
              helperText="名を入力してください"
            />
          </Box>
          <Divider />
          <Box
            py={1}
            display="flex"
            gap={1}
            alignItems="center"
            minHeight="58px"
          >
            <Label>権限</Label>
            {isConfirmed ? (
              <Typography>
                {switchAuthorityLabel(getValues('authorityClassification'))}
              </Typography>
            ) : (
              <Controller
                name="authorityClassification"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    name="authorityClassification"
                    defaultValue={getValues('authorityClassification')}
                  >
                    {Object.values(AuthorityClassification).map((value) => (
                      <FormControlLabel
                        key={value}
                        value={value}
                        control={<Radio color="secondary" />}
                        label={switchAuthorityLabel(value)}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            )}
          </Box>
          <Divider />
          <Box
            py={1}
            display="flex"
            gap={1}
            alignItems="center"
            minHeight="58px"
          >
            <Label>代理申請</Label>
            {isConfirmed ? (
              <Typography>
                {switchProxyApplicationLabel(
                  getValues('proxyApplicationClassification')
                )}
              </Typography>
            ) : (
              <Controller
                name="proxyApplicationClassification"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    name="proxyApplicationClassification"
                    defaultValue={getValues('proxyApplicationClassification')}
                  >
                    {Object.values(ProxyApplicationClassification).map(
                      (value) => (
                        <FormControlLabel
                          key={value}
                          value={value}
                          control={<Radio color="secondary" />}
                          label={switchProxyApplicationLabel(value)}
                        />
                      )
                    )}
                  </RadioGroup>
                )}
              />
            )}
          </Box>
          <Divider />
          <Box py={2} gap={1} display="flex">
            <Label>ログインID</Label>
            {isConfirmed ? (
              <Box>{getValues('mail')}</Box>
            ) : (
              <Stack gap={0.5}>
                <Controller
                  name="mail"
                  control={control}
                  render={({ field }) => (
                    <MuiTextField
                      {...field}
                      error={
                        !inputRequiredValidateEmail(field.value) &&
                        field.value !== ''
                      }
                      placeholder="例：abcdef@mail.com"
                      sx={{
                        minWidth: '408px',
                        maxHeight: '40px',
                      }}
                      inputProps={{ style: { paddingLeft: '8px' } }}
                    />
                  )}
                />
                <MailAnnotation>
                  ※業務用メールアドレスを入力してください
                </MailAnnotation>
                {!inputRequiredValidateEmail(watch('mail')) &&
                watch('mail') !== '' ? (
                  <ErrorText>
                    メールアドレスを正しい形式で入力してください。
                  </ErrorText>
                ) : null}
              </Stack>
            )}
          </Box>
          <Divider />
          <Typography variant="body-sub/regular" mt={2}>
            初期パスワードは自動で発行され、登録メールアドレスへ送信されます。
          </Typography>
          <Box display="flex" gap={2} mt={4}>
            <CancelButton onClick={handleClickCancel}>
              {isConfirmed ? '入力へ戻る' : 'キャンセル'}
            </CancelButton>
            {isConfirmed ? (
              <SubmitButton type="submit">追加する</SubmitButton>
            ) : (
              <PrimaryButton
                disabled={isDisabled}
                onClick={() => {
                  if (
                    validateEmail(getValues().mail) &&
                    isNameValid(getValues().firstNameKanji) &&
                    isNameValid(getValues().lastNameKanji)
                  ) {
                    setValue(
                      'lastNameKanji',
                      getValues().lastNameKanji.replaceAll(' ', '')
                    );
                    setValue(
                      'firstNameKanji',
                      getValues().firstNameKanji.replaceAll(' ', '')
                    );
                    setValue('mail', getValues().mail);
                    handleClickConfirm();
                  }
                }}
              >
                確認へ進む
              </PrimaryButton>
            )}
          </Box>
        </StyledStack>
      </Box>
    </>
  );
};
