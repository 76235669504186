import { AssociationsContribution } from '@/types/api/Association/associationsContribution';
import { GetAssociationsContribution } from '@/types/api/associations';
import { MemberContribution } from '@/types/api/memberContribution';

export const BountyCalculation = (
  type: 'MONTHLY' | 'BONUS',
  data: GetAssociationsContribution,
  bonusUnit: number
): number => {
  let result = 0;
  if (type === 'BONUS') {
    if (data.bonusIncentiveRatio === 0)
      result = data.bonusIncentiveFixedAmount || 0;

    const { unitAmount = 0, bonusIncentiveRatio = 0 } = data;
    const tempResult = (bonusUnit * unitAmount * bonusIncentiveRatio) / 100;

    if (data.bonusIncentiveUpperLimit) {
      result =
        tempResult > data.bonusIncentiveUpperLimit
          ? data.bonusIncentiveUpperLimit
          : tempResult;
    }
  } else {
    if (data.monthlyIncentiveRatio === 0)
      result = data.monthlyIncentiveFixedAmount || 0;

    const { unitAmount = 0, monthlyIncentiveRatio = 0 } = data;
    const tempResult = (bonusUnit * unitAmount * monthlyIncentiveRatio) / 100;

    if (data.monthlyIncentiveUpperLimit) {
      result =
        tempResult > data.monthlyIncentiveUpperLimit
          ? data.monthlyIncentiveUpperLimit
          : tempResult;
    }
  }

  return result;
};

export const Calculations = {
  bonusUnitAmount: (
    associationContributions: AssociationsContribution,
    memberContributions: MemberContribution
  ): number => {
    let result = 0;
    switch (associationContributions.bonusMultiplierDesignationType) {
      case 'FIXED_MAGNIFICATION':
        if (memberContributions.bonusRecessClassification === '1')
          return result;
        result =
          Number(memberContributions.monthlyUnit) *
          Number(associationContributions.bonusMultiplierDetails);
        return result;
      default:
        result = memberContributions.bonusUnit;
        return result;
    }
  },
};

export default {};
