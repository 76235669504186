import { TitleWithDivider } from '@/components/molecules/Title/TitleWithDivider';
import { ControlledTextField } from '@/components/organisms/Association/FirstRegistration/ControlledTextField';
import { InputWithLabelAndDivider } from '@/components/organisms/Association/FirstRegistration/InputWithLabelAndDivider';
import { theme } from '@/theme';
import { UpdateAssociationsFirst } from '@/types/api/associations';

import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const switchAccountClassification = (
  status: UpdateAssociationsFirst['accountClassification']
) => {
  switch (status) {
    case 'OTHER':
      return 'ゆうちょ銀行以外';
    case 'YUCHO':
      return 'ゆうちょ銀行';
    default:
      return '';
  }
};

const switchAccountTypeLabel = (
  status: UpdateAssociationsFirst['accountType']
) => {
  switch (status) {
    case 'SAVINGS':
      return '普通';
    case 'CURRENT':
      return '当座';
    case 'OTHER':
      return '貯蓄';
    default:
      return '';
  }
};

interface AccountSectionProps {
  isConfirmed: boolean;
}

export const AccountSection: FC<AccountSectionProps> = ({ isConfirmed }) => {
  const { control, getValues, watch } =
    useFormContext<UpdateAssociationsFirst>();
  return (
    <Stack spacing={2}>
      <TitleWithDivider title="買増精算振込先口座" />
      <Typography
        variant="body-main/regular"
        sx={{
          color: theme.palette.system['text-normal'],
          whiteSpace: 'pre-wrap',
        }}
      >
        退会時の買増精算にて使用される買増分金額の振り込み先口座をご指定ください。
      </Typography>
      <Box>
        <InputWithLabelAndDivider
          label="金融機関の種類"
          sx={
            !isConfirmed
              ? { padding: theme.spacing(1, 0) }
              : { padding: theme.spacing(2, 0) }
          }
        >
          {isConfirmed ? (
            <Typography>
              {switchAccountClassification(getValues('accountClassification'))}
            </Typography>
          ) : (
            <Controller
              name="accountClassification"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field} row>
                  <FormControlLabel
                    value="OTHER"
                    control={<Radio color="secondary" />}
                    label={switchAccountClassification('OTHER')}
                  />
                  <FormControlLabel
                    value="YUCHO"
                    control={<Radio color="secondary" />}
                    label={switchAccountClassification('YUCHO')}
                  />
                </RadioGroup>
              )}
            />
          )}
        </InputWithLabelAndDivider>
        {watch('accountClassification') === 'OTHER' ? (
          <>
            <InputWithLabelAndDivider label="金融機関名">
              {isConfirmed ? (
                <Typography>{getValues('financialInstitutionName')}</Typography>
              ) : (
                <ControlledTextField
                  control={control}
                  fieldName="financialInstitutionName"
                  placeholder="例：○○銀行"
                />
              )}
            </InputWithLabelAndDivider>
            <InputWithLabelAndDivider label="支店コード">
              {isConfirmed ? (
                <Typography>{getValues('bankBranchCd')}</Typography>
              ) : (
                <Box width="160px">
                  <ControlledTextField
                    control={control}
                    fieldName="bankBranchCd"
                    placeholder="例：000"
                  />
                </Box>
              )}
            </InputWithLabelAndDivider>
            <InputWithLabelAndDivider label="支店名">
              {isConfirmed ? (
                <Typography>{getValues('bankBranchName')}</Typography>
              ) : (
                <ControlledTextField
                  control={control}
                  fieldName="bankBranchName"
                  placeholder="例：〇〇支店"
                />
              )}
            </InputWithLabelAndDivider>
            <InputWithLabelAndDivider
              label="種別"
              sx={
                !isConfirmed
                  ? { padding: theme.spacing(1, 0) }
                  : { padding: theme.spacing(2, 0) }
              }
            >
              {isConfirmed ? (
                <Typography>
                  {switchAccountTypeLabel(
                    getValues('accountType') ?? 'SAVINGS'
                  )}
                </Typography>
              ) : (
                <Controller
                  name="accountType"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      row
                      name="accountType"
                      defaultValue={getValues('accountType')}
                    >
                      <FormControlLabel
                        value="SAVINGS"
                        control={<Radio color="secondary" />}
                        label={switchAccountTypeLabel('SAVINGS')}
                      />
                      <FormControlLabel
                        value="CURRENT"
                        control={<Radio color="secondary" />}
                        label={switchAccountTypeLabel('CURRENT')}
                      />
                      <FormControlLabel
                        value="OTHER"
                        control={<Radio color="secondary" />}
                        label={switchAccountTypeLabel('OTHER')}
                      />
                    </RadioGroup>
                  )}
                />
              )}
            </InputWithLabelAndDivider>
            <InputWithLabelAndDivider label="口座番号">
              {isConfirmed ? (
                <Typography>{getValues('accountNumber')}</Typography>
              ) : (
                <Box width="160px">
                  <ControlledTextField
                    control={control}
                    fieldName="accountNumber"
                    placeholder="例：1234567"
                  />
                </Box>
              )}
            </InputWithLabelAndDivider>
          </>
        ) : (
          <InputWithLabelAndDivider label="店番・番号">
            {isConfirmed ? (
              <Typography>
                {getValues('accountYuchoSymbol')}-
                {getValues('accountYuchoNumber')}
              </Typography>
            ) : (
              <Box display="flex" gap={1}>
                <Box width="96px">
                  <Typography
                    variant="body-sub/regular"
                    sx={{ color: theme.palette.system['text-normal'] }}
                  >
                    店番
                  </Typography>
                  <ControlledTextField
                    control={control}
                    fieldName="accountYuchoSymbol"
                    placeholder="例：12345"
                  />
                </Box>
                <Box display="flex" alignItems="flex-end">
                  {/* TextFieldと同じ高さ 40px */}
                  <Box height="40px" lineHeight="40px">
                    <Typography
                      variant="body-sub/regular"
                      sx={{
                        color: theme.palette.system['text-normal'],
                      }}
                    >
                      -
                    </Typography>
                  </Box>
                </Box>
                <Box width="128px">
                  <Typography
                    variant="body-sub/regular"
                    sx={{ color: theme.palette.system['text-normal'] }}
                  >
                    番号
                  </Typography>
                  <ControlledTextField
                    control={control}
                    fieldName="accountYuchoNumber"
                    placeholder="例：12345678"
                  />
                </Box>
              </Box>
            )}
          </InputWithLabelAndDivider>
        )}
        <InputWithLabelAndDivider label="口座名義">
          {isConfirmed ? (
            <Typography>{getValues('accountName')}</Typography>
          ) : (
            <ControlledTextField
              control={control}
              fieldName="accountName"
              placeholder="例：カブシキガイシヤ〇〇"
            />
          )}
        </InputWithLabelAndDivider>
      </Box>
    </Stack>
  );
};
