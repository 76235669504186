import { Title } from '@/components/molecules/Title/Title';
import { theme } from '@/theme';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { FC } from 'react';

const Wrapper = styled(Box)(() => ({
  padding: theme.spacing(4, 5),
  background: theme.palette.system['background-light'],
  borderBottom: '1px solid',
  borderColor: theme.palette.system['separator-light'],
}));

interface TitleWithDateProps {
  title: string;
  subtitle?: string;
}

export const TitleWithDate: FC<TitleWithDateProps> = ({
  title,
  subtitle = '',
}) => (
  <Wrapper>
    <Title title={title} subtitle={subtitle} />
  </Wrapper>
);
