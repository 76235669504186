/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { useState } from 'react';
import { theme } from '@/theme';
import { Box, Typography, styled, Paper, Divider, Stack } from '@mui/material';
import { ReactComponent as Refresh } from '@/assets/refresh.svg';
import { appClient } from '@/services';
import { useRecoilValue } from 'recoil';
import { DownloadButton } from '@/components/atoms/Buttons/DownloadButton';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  position: 'relative',
  background: theme.palette.system['background-light'],
});

const HeaderSection = styled(Box)({
  padding: '32px 40px',
});

const HeaderTitle = styled(Typography)({
  ...theme.typography.h4,
  color: theme.palette.system['text-normal'],
});

const TableWrapper = styled(Box)({
  padding: '0 40px',
});

const TableContainer = styled(Box)({
  borderRadius: '4px',
  border: '1px solid',
  borderColor: theme.palette.system['separator-light'],
  width: '100%',
});

const TableHead = styled(Box)({
  width: '100%',
  backgroundColor: theme.palette.system.background,
  padding: '8px 16px',
  display: 'flex',
  gap: '16px',
  borderBottom: '1px solid',
  borderColor: theme.palette.system['separator-light'],
});

const TableHeadTitle = styled(Typography)({
  ...theme.typography['body-sub/medium'],
});

const TableBodyContainer = styled(Stack)({
  padding: '16px',
  gap: '12px',
  backgrouundColor: theme.palette.system.white,
});

const TableBodyRow = styled(Stack)({
  gap: '12px',
});

const TableContent = styled(Box)({
  display: 'flex',
  gap: '16px',
});

const Title = styled(Typography)({
  ...theme.typography['body-main/bold'],
});

const Description = styled(Typography)({
  ...theme.typography['body-sub/regular'],
  color: theme.palette.system['text-light'],
});

const NotificationContainer = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const NotificationWrapper = styled(Paper)({
  padding: '12px 16px',
  borderRadius: 8,
  backgroundColor: theme.palette.secondary.normal,
  display: 'flex',
  flexDirection: 'row',
  gap: 10,
});

const NotificationText = styled(Typography)({
  ...theme.typography['body-main/regular'],
  color: theme.palette.system.white,
});

export const Downloads = () => {
  const selectedAssociation = useRecoilValue(SelectedAssociationState);
  const [notification, setNotification] = useState(false);
  useHelmetHandler({
    title: '明細ダウンロード',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const handleDownloadReport = async (
    type:
      | 'masterdata'
      | 'memberrightallocation'
      | 'withdrawalinstructions'
      | 'memberallocationresult'
      | 'withdrawalstatus'
  ) => {
    setNotification(true);
    try {
      if (!selectedAssociation?.associationCd) return;
      const res = await appClient.csv.downloadCsv(
        selectedAssociation.associationCd,
        type
      );

      if (res) {
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        const blob = new Blob([bom, res as BlobPart], {
          type: 'text/csv; charset=utf-8',
        });
        const url = URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.download = `${type}.csv`;
        anchor.href = url;
        document.body.appendChild(anchor);
        anchor.click();
        URL.revokeObjectURL(url);
      }
    } catch (e) {
      // handle download report error
    }
    setNotification(false);
  };

  const renderNotification = () => {
    if (notification)
      return (
        <NotificationWrapper>
          <Refresh />
          <NotificationText>
            ダウンロードが開始されるまでお待ちください。
          </NotificationText>
        </NotificationWrapper>
      );
    return null;
  };

  return (
    <Wrapper>
      <NotificationContainer>{renderNotification()}</NotificationContainer>
      <HeaderSection>
        <HeaderTitle>帳票ダウンロード</HeaderTitle>
      </HeaderSection>
      <TableWrapper>
        <TableContainer>
          <TableHead>
            <TableHeadTitle width="20%">帳票名</TableHeadTitle>
            <TableHeadTitle width="65%">説明</TableHeadTitle>
            <TableHeadTitle width="15%">ダウンロード</TableHeadTitle>
          </TableHead>
          <TableBodyContainer>
            <TableBodyRow>
              <TableContent>
                <Title width="20%">マスターデータ</Title>
                <Description width="65%">
                  会員情報や会員残高の明細です。
                </Description>
                <Box width="15%">
                  <DownloadButton
                    onClick={() => handleDownloadReport('masterdata')}
                  />
                </Box>
              </TableContent>
              <Divider />
            </TableBodyRow>
            <TableBodyRow>
              <TableContent>
                <Title width="20%">会員権配</Title>
                <Description width="65%">
                  受け取った配当金の明細です。
                </Description>
                <Box width="15%">
                  <DownloadButton
                    onClick={() =>
                      handleDownloadReport('memberrightallocation')
                    }
                  />
                </Box>
              </TableContent>
              <Divider />
            </TableBodyRow>
            <TableBodyRow>
              <TableContent>
                <Title width="20%">引出指示</Title>
                <Description width="65%">引出した株式の明細です。</Description>
                <Box width="15%">
                  <DownloadButton
                    onClick={() =>
                      handleDownloadReport('withdrawalinstructions')
                    }
                  />
                </Box>
              </TableContent>

              <Divider />
            </TableBodyRow>
            <TableBodyRow>
              <TableContent>
                <Title width="20%">会員配分結果</Title>
                <Description width="65%">
                  購入した金額および株数の明細です。
                </Description>
                <Box width="15%">
                  <DownloadButton
                    onClick={() =>
                      handleDownloadReport('memberallocationresult')
                    }
                  />
                </Box>
              </TableContent>
              <Divider />
            </TableBodyRow>
            <TableBodyRow>
              <TableContent>
                <Title width="20%">退会状況</Title>
                <Description width="65%">
                  退会した際の精算金に関する明細です。
                </Description>
                <Box width="15%">
                  <DownloadButton
                    onClick={() => handleDownloadReport('withdrawalstatus')}
                  />
                </Box>
              </TableContent>
            </TableBodyRow>
          </TableBodyContainer>
        </TableContainer>
      </TableWrapper>
    </Wrapper>
  );
};
