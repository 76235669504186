import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { theme } from '@/theme';
import { MemberById } from '@/types/api/member';
import { FindApplicationByID } from '@/types/api/Applications/application';
import { PrevnextApplications } from '@/types/api/Applications/prevnextApplications';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { RecessEditInputs as Inputs } from '@/types/components/Inputs';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { SelectBox } from '@/components/atoms/Input/SelectBox';
import { reasonsForRecess } from '@/constants/SelectBox/choicesForSelectBox';
import { TextInput } from '@/components/atoms/Input/TextInput';
import { TitleHead } from '../Applications/SharedParts/TitleHead';
import { UserInfoTable } from '../Applications/SharedParts/UserInfoTable';
import { ApplicationBottom } from '../Applications/SharedParts/ApplicationBottom';
import { ApplicationTable as Table } from '../Applications/SharedParts/ApplicationTable';
import { ApplicationTableRow as Row } from '../Applications/SharedParts/ApplicationTableRow';

interface RecessProps {
  application: FindApplicationByID;
  unitType: string;
  userData: MemberById;
  bottomData: PrevnextApplications | undefined;
  onSubmit: SubmitHandler<Inputs>;
}

export const ApplicationsRecessEdit: FC<RecessProps> = ({
  application,
  unitType,
  userData,
  bottomData,
  onSubmit,
}) => {
  const switchRecessReason = (value: string) => {
    switch (value) {
      case 'LEAVE_ABSENCE':
        return '休職';
      case 'FAMILY_MATTERS':
        return '家庭の事情';
      case 'ECONOMIC':
        return '経済的理由';
      case 'OTHER':
        return 'その他（理由を記入）';
      default:
        return '';
    }
  };
  const { control, handleSubmit, watch } = useForm<Inputs>({
    defaultValues: {
      reason: switchRecessReason(application?.applicationRecess?.reason || ''),
      otherReasons: application.applicationRecess?.otherReasons || '',
    },
  });
  const watchReason = watch('reason');

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.system['background-dark'],
        height: '100%',
      }}
    >
      <Stack
        spacing={4}
        sx={{
          backgroundColor: theme.palette.system.white,
          padding: '40px 160px',
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <TitleHead
          title="休止"
          status={application.applicationStatus || ''}
          applicationDate={application.applicationDate || ''}
          applicationId={application.applicationId || ''}
          associationCd={application.associationCd || ''}
          proxyApplicantUserName={application.proxyApplicantUserName}
          unitType={unitType}
        />
        <UserInfoTable data={userData} />
        <Table title="休止理由" isRed>
          <Row>
            <Stack gap={2}>
              <Controller
                name="reason"
                control={control}
                render={({ field }) => (
                  <SelectBox
                    width="320px"
                    {...field}
                    items={reasonsForRecess}
                  />
                )}
              />
              {watchReason === 'その他（理由を記入）' && (
                <Controller
                  name="otherReasons"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      placeholder="休止理由を入力してください"
                      maxRows={4}
                      multiline
                      sx={{ width: '100%', minHeight: '130px' }}
                      inputProps={{
                        style: {
                          height: '96px',
                          resize: 'none',
                          color: theme.palette.system['text-normal'],
                          paddingLeft: '4px',
                        },
                      }}
                    />
                  )}
                />
              )}
            </Stack>
          </Row>
        </Table>
        <PrimaryButton
          sx={{ width: '96px' }}
          type="submit"
          disabled={watch('reason') === '休止理由を選択してください'}
        >
          修正完了
        </PrimaryButton>
      </Stack>
      {bottomData && (
        <Box sx={{ padding: '32px 160px' }}>
          <ApplicationBottom data={bottomData} unitType={unitType} />
        </Box>
      )}
    </Box>
  );
};
