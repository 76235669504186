import { LoginFormType, LoginMfa } from '@/components/organisms/Login/LoginMfa';
import { Path } from '@/constants/Router/path';
import { AUTH_TOKEN } from '@/constants/Storage/StorageKey';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { sessionToken } from '@/recoil/token';

// import { AssociationsListState } from '@/recoil/associations/associations';
import { appClient, OpenAPI } from '@/services';
import { FC, useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
// import { useRecoilState } from 'recoil';

type LoginState = {
  loginId: string;
  loginPassword: string;
  mfaSession: string;
};

export const Mfa: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // The data was being lost after a few renders,
  // so there was a need to store the data locally while user trying to auth via MFA
  const state = location.state as LoginState;
  const [isError, setIsError] = useState(false);
  // The auth credentials are being stored in component state temporarely
  const [loginData, setLoginData] = useState<LoginState | undefined>();
  useHelmetHandler({
    title: '多要素認証',
  });
  const [, setToken] = useRecoilState(sessionToken);

  // const [, setAssociation] = useRecoilState(AssociationsListState);

  const onSubmit: SubmitHandler<LoginFormType> = async (
    data: LoginFormType
    // eslint-disable-next-line consistent-return
  ) => {
    try {
      if (!loginData || !loginData?.loginId || !loginData.loginPassword) {
        return navigate(Path.LOGIN);
      }
      const response = await appClient.authorization.authMfaConfirm({
        loginId: loginData.loginId,
        mfaSession: loginData.mfaSession,
        mfaCode: data.mfa,
      });
      if (response && response.AuthenticationResult) {
        const token = response.AuthenticationResult;
        if (token.IdToken) {
          setToken(token);
          const jsonString = JSON.stringify(token);
          localStorage.setItem(AUTH_TOKEN, jsonString);
          const idToken = `Bearer ${token.IdToken}`;
          OpenAPI.HEADERS = { Authorization: idToken };
          // api clientのヘッダーにトークンを設定
          appClient.request.config.HEADERS = { Authorization: idToken };
        }

        // TODO エラーになるためコメントアウトします
        // Get associations List
        // const listAssociations =
        //   await appClient.associations.listAssociations();
        // if (listAssociations) {
        //   setAssociation(listAssociations);
        // }
        navigate('/', { replace: true });
      }
    } catch (e) {
      setIsError(true);
    }
  };

  // eslint-disable-next-line consistent-return
  const handleClickRetransmission = async () => {
    try {
      if (!loginData || !loginData?.loginId || !loginData.loginPassword) {
        return navigate(Path.LOGIN);
      }
      OpenAPI.HEADERS = {};
      const response = await appClient.authorization.authSignin({
        loginId: loginData.loginId,
        loginPassword: loginData.loginPassword,
      });
      setIsError(false);
      localStorage.removeItem(AUTH_TOKEN);
      if (response.Session !== undefined) {
        setLoginData({
          ...loginData,
          mfaSession: response.Session,
        });
      }
    } catch {
      // エラー
    }
  };

  useEffect(() => {
    if (state) setLoginData(state);
  }, [state]);

  return (
    <LoginMfa
      title="多要素認証"
      onSubmit={onSubmit}
      handleClickRetransmission={handleClickRetransmission}
      isError={isError}
    />
  );
};
