import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';

interface RowProps {
  borderBottom?: boolean;
  children: ReactNode;
}

export const Row: FC<RowProps> = ({ borderBottom = true, children }) => (
  <Box
    sx={{
      display: 'flex',
      width: '100%',
      borderBottom: borderBottom ? '1px solid' : '0',
      borderColor: 'system.separator-light',
    }}
  >
    {children}
  </Box>
);
