export const list = [
  {
    title: 'ダッシュボード',
    link: '/',
  },
  {
    title: '申請',
    link: '/applications/monthly',
  },
  {
    title: 'スケジュール',
    link: '/schedules',
  },
  {
    title: '残高詳細',
    link: '/balance',
  },
  {
    title: '帳票ダウンロード',
    link: '/downloads',
  },
  {
    title: '会員一覧',
    link: '/members',
  },
  {
    title: '会員向けお知らせ',
    link: '/informations',
  },
  {
    title: '持株会情報',
    link: '/association',
  },
];
