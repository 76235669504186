import { SelectedAssociationState } from '@/recoil/associations/associations';
import { useRecoilValue } from 'recoil';
import useSWR from 'swr';
import { appClient } from '..';

export const useFindAssociationsAccount = () => {
  const { associationCd } = useRecoilValue(SelectedAssociationState);
  const { data } = useSWR(
    associationCd ? `/api/associations/${associationCd}/account` : null,
    () =>
      appClient.associations.findAssociationsAccount(associationCd as string)
  );

  return { data };
};
