/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { NotificationForm } from '@/components/organisms/Notification/NotificationForm';
import { SubmitHandler } from 'react-hook-form';
import { NotidicationInputs as Inputs } from '@/types/components/Inputs';
import { useState } from 'react';
import { appClient } from '@/services';
import { useRecoilValue } from 'recoil';
import { SelectedAssociationState } from '@/recoil/associations/associations';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

export const Information = () => {
  const selectedAssociation = useRecoilValue(SelectedAssociationState);
  const [modal, setModal] = useState<string>('');
  const [isConfirming, setIsConfirming] = useState(false);
  useHelmetHandler({
    title: '会員向けお知らせ 登録',
  });

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    try {
      const res = await appClient.informations.createMemberInformation({
        associationCd: selectedAssociation.associationCd,
        displayPeriodFrom: data.startDate,
        displayPeriodTo: data.endDate,
        informationTitle: data.title,
        informationBody: data.description,
        priority: data.priority || 'LOW',
      });
      setModal('confirming');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <NotificationForm
      isGeneral
      onSubmit={onSubmit}
      modal={modal}
      setModal={setModal}
      isConfirming={isConfirming}
      setIsConfirming={setIsConfirming}
      associationName={selectedAssociation.associationName || ''}
    />
  );
};
